import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-project-details/subdisplay-tasks/ColumnTypeSettings";
import {
    ProjectDetails_Milestones_TaskListPeriodTypesSettingValue,
} from "@app/core/Api";
import {
    TaskListPeriodCell
} from "@app/pages/displays/display-project-details/subdisplay-tasks/Cells/TaskListPeriodCell";
import {
    TaskListPeriodTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/TaskListPeriodTableColumn";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-tasks/Row";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import moment from "moment";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-tasks/Filters";

export class TaskListPeriodColumn extends BaseColumn<TaskListPeriodCell> implements ColumnDataFetcherInterface, SortableColumnInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public withPeriodDataFetcher: TaskFetcher;
    public withoutPeriodDataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.withPeriodDataFetcher,
            this.withoutPeriodDataFetcher,
        ]
    }

    // </editor-fold>

    // <editor-fold desc="Sorting">

    public implementsSorting: true = true;

    public applyRowSort(row: Row, tableColumn: TaskListPeriodTableColumn): void {
        tableColumn.column.getCell(row).listConfigurations.forEach(listConfiguration => {
            listConfiguration.setOrderBy(
                this.getOrderBy(Filters.GetBaseSort(tableColumn.activeSortTypeId), tableColumn.activeSortDirection)
            );

            listConfiguration.clearSortFilters();
            switch (Filters.GetBaseSort(tableColumn.activeSortTypeId)) {
                case Filters.SortDeadlineWith:
                    listConfiguration.setSort_DeadlineValidator(task => {
                        return task.findTasksDeadlineByType(Filters.ParseSortDeadlineWith(tableColumn.activeSortTypeId))?.exists();
                    });
                    break;
                case Filters.SortCategoryType:
                    listConfiguration.setSort_CategoryTypeId(parseInt(Filters.ParseSortCategoryType(tableColumn.activeSortTypeId)));
                    break;
            }

        });
    }

    private getOrderBy(type: string, direction: string): string[][] {
        const orderBy: string[][] = [];
        switch (Filters.GetBaseSort(type)) {
            case Filters.SortTitle:
                orderBy.push(['title', direction]);
                break;
            case Filters.SortStatus:
                orderBy.push(['main_status.status_id', direction]);
                break;
            case Filters.SortDeadlines:
                orderBy.push(['tasks_deadline.deadline.date', 'null'], ['tasks_deadline.deadline.date', direction]);
                break;
            case Filters.SortPlanningDate:
                orderBy.push(['tasks_user.deadline.date', direction]);
                break;
            case Filters.SortStars:
                orderBy.push(['num_stars', (direction == 'asc') ? 'desc' : 'asc']);
                break;
            case Filters.SortHands:
                orderBy.push(['num_hand_ups', (direction == 'asc') ? 'desc' : 'asc']);
                break;
            case Filters.SortUserDefined:
                orderBy.push(['milestones_task.index_', 'asc']);
                orderBy.push(['main_status.status_id', 'asc']);
                orderBy.push(['tasks_deadline.deadline.date', 'asc']);
                orderBy.push(['title', 'asc']);
                break;
            case Filters.SortDeadlineWith:
                orderBy.push(['tasks_deadline.deadline.date', direction]);
                break;
            case Filters.SortCategoryType:
                orderBy.push(['category.name', direction]);
                break;
        }
        return orderBy;
    }

    // </editor-fold>

    static Period_BeforeThisWeek = 'before-this-week';
    static Period_ThisWeek = 'this-week';
    static Period_NextWeek = 'next-week';
    static Period_ThisAndNextWeek = 'this-and-next-week';
    static Period_ThirdAndForthWeek = 'third-and-forth-week';
    static Period_FifthAndSixthWeek = 'fifth-and-sixth-week';
    static Period_SeventhAndEightsWeek = 'seventh-and-eights-week';
    static Period_FollowingWeek = 'following-week';
    static Period_Following = 'following';
    static Period_WithoutDeadline = 'without-deadline';

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.withPeriodDataFetcher = new TaskFetcher(
            undefined, undefined, undefined,
            column.name
        );
        this.withPeriodDataFetcher.setPeriod(
            moment().subtract(10, 'year').startOf('week').toDate(),
            moment().add(10, 'year').endOf('week').toDate()
        );
        this.withoutPeriodDataFetcher = new TaskFetcher(
            undefined, undefined, undefined,
            column.name
        );
    }

    createTableColumns(): BaseTableColumn[] {
        return this.getPeriodTypes().map(periodType => {
            const tableColumn = new TaskListPeriodTableColumn(this, periodType);
            this.setTableColumnDefaults(tableColumn);
            return tableColumn;
        });
    }

    createCell(row: Row): BaseCell {
        const cell = new TaskListPeriodCell(row, this);

        cell.listConfigurations.forEach((listConfiguration, periodType) => {
            if (periodType == TaskListPeriodColumn.Period_WithoutDeadline) {
                this.withoutPeriodDataFetcher.addRequest(new TaskFetchRequest(listConfiguration));
            } else {
                this.withPeriodDataFetcher.addRequest(new TaskFetchRequest(listConfiguration));
            }
        });

        return cell;
    }

    public getPeriodTypes(): string[] {
        return this.settings.get(ColumnTypeSettings.TaskList_Period_Types)
            ?.getObject<ProjectDetails_Milestones_TaskListPeriodTypesSettingValue>()
            ?.periodTypes ?? [];
    }

    public setPeriod(start: Date, end: Date) {
        this.getTableColumns<TaskListPeriodTableColumn>().forEach(tableColumn => {
            tableColumn.setPeriod(start, end);
        });

        // Apply special rules for FollowingWeek and Following
        const tableColumns = this.getVisibleTableColumns<TaskListPeriodTableColumn>();
        const tableColumnsWithEnd = tableColumns
            .filter(tableColumn => tableColumn.end);
        if (tableColumnsWithEnd.length > 0) {
            const lastTableColumnWithEnd = tableColumnsWithEnd[tableColumnsWithEnd.length - 1];
            const followingWeekColumn = tableColumns
                .find(tableColumn => tableColumn.periodType == TaskListPeriodColumn.Period_FollowingWeek);
            const followingColumn = tableColumns
                .find(tableColumn => tableColumn.periodType == TaskListPeriodColumn.Period_Following);
            if (followingWeekColumn) {
                followingWeekColumn.start = moment(lastTableColumnWithEnd.end).add(1, 'week').startOf('isoWeek').toDate();
                followingWeekColumn.end = undefined;
            }
            if (followingColumn) {
                followingColumn.start = moment(lastTableColumnWithEnd.end).add(1, 'week').startOf('isoWeek').toDate();
                followingColumn.end = moment(lastTableColumnWithEnd.end).add(1, 'week').endOf('isoWeek').toDate();
            }
        }
    }

}
