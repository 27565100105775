import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    YearWheelTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/YearWheelTableColumn";
import {YearWheelCell} from "@app/pages/displays/display-project-details/subdisplay-top/Cells/YearWheelCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {ColumnTypeSettings} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypeSettings";
import {ProjectDetails_Top_YearWheelProjectDeadlineTypesSettingValue} from "@app/core/Api";
import {YearWheelOptions, YearWheelService} from "@app/services/year-wheel.service";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {YearWheelFetcher, YearWheelFetcherRequest} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelFetcher";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {AppInjector} from "@app/services/app-injector.service";

export class YearWheelColumn extends BaseColumn<YearWheelCell> implements ColumnDataFetcherInterface {

    public yearWheelPeriod: { start: Date, end: Date };
    public yearWheelDisplayOptions: YearWheelOptions;

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: YearWheelFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new YearWheelFetcher(column.name);

        this.yearWheelDisplayOptions = AppInjector.getInjector().get(YearWheelService).getSettings();
    }

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new YearWheelTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: Row): BaseCell {
        const cell = new YearWheelCell(row, this);
        this.dataFetcher.addRequest(new YearWheelFetcherRequest(cell.yearWheelConfiguration));
        return cell;
    }

    public getProjectDeadlineTypeStartSetting(): number {
        return this.settings.get(ColumnTypeSettings.YearWheelProjectDeadlineTypes)
            ?.getObject<ProjectDetails_Top_YearWheelProjectDeadlineTypesSettingValue>()
            ?.startProjectDeadlineTypeId ?? 0;
    }

    public getProjectDeadlineTypeEndSetting(): number {
        return this.settings.get(ColumnTypeSettings.YearWheelProjectDeadlineTypes)
            ?.getObject<ProjectDetails_Top_YearWheelProjectDeadlineTypesSettingValue>()
            ?.endProjectDeadlineTypeId ?? 0;
    }

    public setPeriod(start: Date, end: Date) {
        this.yearWheelPeriod = {
            start: start,
            end: end,
        };
    }

}
