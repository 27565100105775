import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {Roster} from '@app/core/models/Task';
import {TaskListConfiguration} from '@app/shared/_ui/lists/task-list/TaskListConfiguration';
import {User} from '@app/core/models/User';
import {TaskDeadlineTypes, TaskUserTypes} from '@app/constants';
import {TasksService} from '@app/services/tasks.service';
import {TaskPresetTypes} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/TaskPresetType";
import {Task} from "@app/core/models";
import {AppInjector} from "@app/services/app-injector.service";
import {ShellService} from "@app/services/ShellService/shell.service";

@Component({
    selector: 'app-roster-expander',
    templateUrl: './roster-expander.component.html',
    styleUrls: ['./roster-expander.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RosterExpanderComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() items: CardItem<Roster>[];
    @Input() listConfiguration: TaskListConfiguration;
    @Input() updateValuesEvent: EventEmitter<any>;
    public selectedUsers: User[] = [];

    @Output() onDelete = new EventEmitter<Roster>();

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
        this.cdr = changeDetectorRef;
    }

    ngOnInit() {
        super.ngOnInit();
        this.updateValues();
        if (this.updateValuesEvent) {
            this.subscribe(this.updateValuesEvent.subscribe((_: any) => {
                this.updateValues();
            }));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['items'] != null) {
            this.items = changes['items'].currentValue;
            this.updateValues();
        }
    }

    public createNewRosterWithUser($event: User) {
        if (!$event) {
            return;
        }

        const taskTypeId = this.listConfiguration.getTaskTypeIds()[0];
        const presets = this.listConfiguration.createPresets(taskTypeId) ?? [];
        presets.push(
            {
                type: TaskPresetTypes.User,
                payload: {
                    typeId: TaskUserTypes.Participant,
                    userId: $event.id,
                    deadline: {
                        date: this.listConfiguration.getPlannedBetween()[0],
                        isSoft: false
                    },
                }
            },
            {
                type: TaskPresetTypes.Deadline,
                payload: {
                    typeId: TaskDeadlineTypes.Normal,
                    date: this.listConfiguration.getPlannedBetween()[0],
                    isSoft: false,
                }
            },
        );

        Task.Create(taskTypeId, presets);
    }

    public deleteRoster(item: CardItem<Roster>) {
        item.item.delete(() => {
            this.selectedUsers = this.selectedUsers.filter((value) => {
                return value.id !== item.item.tasks_users[0].user_id;
            });
        });
    }

    private updateValues() {
        const result: User[] = [];
        if (this.items && this.items.length > 0) {

            let users: User[] = [];
            this.items.forEach(cardItem => {
                users = users.concat(cardItem.item.tasks_users
                    .filter(_tu => _tu.task_user_type_id != TaskUserTypes.Creator)?.map(taskUser => taskUser.user));
            });

            const map = new Map();
            for (const item of users) {
                if (item && !map.has(item.id)) {
                    map.set(item.id, true);    // set any value to Map
                    result.push(item);
                }
            }
        }
        this.selectedUsers = [...result];
        this.markChangeDetectionDirty();
    }

}
