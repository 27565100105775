import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {StatusEditorComponent} from "@app/editor/quick-editor/editors/generic/status-editor/status-editor.component";
import {TitleEditorComponent} from "@app/editor/quick-editor/editors/generic/title-editor/title-editor.component";
import {
    DeadlineListEditorComponent
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/deadline-list-editor.component";
import {
    MilestonePlanSelectEditorComponent
} from "@app/editor/quick-editor/editors/project/milestone-plan-select-editor/milestone-plan-select-editor.component";
import {CardEditorComponent} from "@app/editor/quick-editor/editors/generic/card-editor/card-editor.component";
import {
    EstimateListEditorComponent
} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/estimate-list-editor.component";
import {
    CategoryListEditorComponent
} from "@app/editor/quick-editor/editors/generic/category-list-editor/category-list-editor.component";
import {
    ReactionListEditorComponent
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/reaction-list-editor.component";
import {TextEditorComponent} from "@app/editor/quick-editor/editors/generic/text-editor/text-editor.component";
import {LinkEditorComponent} from "@app/editor/quick-editor/editors/generic/link-editor/link-editor.component";
import {
    WhoWhenEditorComponent
} from "@app/editor/quick-editor/editors/generic/who-when-editor/who-when-editor.component";
import {ActionEditorComponent} from "@app/editor/quick-editor/editors/generic/action-editor/action-editor.component";
import {
    MilestoneListEditorComponent
} from "@app/editor/quick-editor/editors/generic/milestone-list-editor/milestone-list-editor.component";
import {
    ArchivedEditorComponent
} from "@app/editor/quick-editor/editors/generic/archived-editor/archived-editor.component";
import {
    UserListEditorComponent
} from "@app/editor/quick-editor/editors/generic/user-list-editor/user-list-editor.component";
import {
    UseStatusRulesEditorComponent
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/use-status-rules-editor.component";
import {
    CategoryPickerListEditorComponent
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/category-picker-list-editor.component";
import {
    DepartmentPickerEditorComponent
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/department-picker-editor.component";
import {
    TagListEditorComponent
} from "@app/editor/quick-editor/editors/generic/tag-list-editor/tag-list-editor.component";
import {
    CanCopyEditorComponent
} from "@app/editor/quick-editor/editors/generic/can-copy-editor/can-copy-editor.component";
import {
    IsPrivateEditorComponent
} from "@app/editor/quick-editor/editors/generic/is-private-editor/is-private-editor.component";
import {NumberEditorComponent} from "@app/editor/quick-editor/editors/generic/number-editor/number-editor.component";
import {
    TodoCategoryEditorComponent
} from "@app/editor/quick-editor/editors/todo/todo-category-editor/todo-category-editor.component";
import {
    CheckboxEditorComponent
} from "@app/editor/quick-editor/editors/generic/checkbox-editor/checkbox-editor.component";

@Component({
    selector: 'app-editor-switcher',
    templateUrl: './editor-switcher.component.html',
    styleUrls: ['./editor-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditorSwitcherComponent extends BaseComponent implements OnInit {

    @Input() name: string;
    @Input() configuration: BaseEditorConfiguration;
    @Input() model: any;
    @Input() card: CardItem;
    @Input() listDragInterface: ListDragInterface;
    @Input() editorEvents: EditorEvents;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    protected readonly StatusEditorComponent = StatusEditorComponent;
    protected readonly TitleEditorComponent = TitleEditorComponent;
    protected readonly DeadlineListEditorComponent = DeadlineListEditorComponent;
    protected readonly MilestonePlanSelectEditorComponent = MilestonePlanSelectEditorComponent;
    protected readonly CardEditorComponent = CardEditorComponent;
    protected readonly EstimateListEditorComponent = EstimateListEditorComponent;
    protected readonly CategoryListEditorComponent = CategoryListEditorComponent;
    protected readonly ReactionListEditorComponent = ReactionListEditorComponent;
    protected readonly TextEditorComponent = TextEditorComponent;
    protected readonly LinkEditorComponent = LinkEditorComponent;
    protected readonly WhoWhenEditorComponent = WhoWhenEditorComponent;
    protected readonly ActionEditorComponent = ActionEditorComponent;
    protected readonly MilestoneListEditorComponent = MilestoneListEditorComponent;
    protected readonly ArchivedEditorComponent = ArchivedEditorComponent;
    protected readonly UserListEditorComponent = UserListEditorComponent;
    protected readonly UseStatusRulesEditorComponent = UseStatusRulesEditorComponent;
    protected readonly CategoryPickerListEditorComponent = CategoryPickerListEditorComponent;
    protected readonly DepartmentPickerEditorComponent = DepartmentPickerEditorComponent;
    protected readonly TagListEditorComponent = TagListEditorComponent;
    protected readonly CanCopyEditorComponent = CanCopyEditorComponent;
    protected readonly IsPrivateEditorComponent = IsPrivateEditorComponent;
    protected readonly NumberEditorComponent = NumberEditorComponent;
    protected readonly TodoCategoryEditorComponent = TodoCategoryEditorComponent;
    protected readonly CheckboxEditorComponent = CheckboxEditorComponent;
}
