import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import Globals from '@app/constants';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {ListConfiguration} from '@app/shared/_ui/lists/ListConfiguration';
import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import {Appointment, Milestone, Office365Event, Project, Task, Todo} from "@app/core/models";
import {CaseUser, Roster} from "@app/core/models/Task";
import {AppointmentForm} from "@app/shared/_modals/appointment-dialog/AppointmentForm";

@Component({
    selector: 'app-card-switcher',
    templateUrl: './card-switcher.component.html',
    styleUrls: ['./card-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardSwitcherComponent extends BaseComponent implements OnInit {

    @Input() cardItem: CardItem;
    @Input() model: AnyItem;
    @Input() configuration: CardConfiguration;
    @Input() interactive = true;
    @Input() removable = false;
    @Input() editable = false;
    @Input() listConfiguration: ListConfiguration;

    constructor() {
        super();
        if (this.cardItem) {
            if (!this.model && this.cardItem.item) {
                this.model = this.cardItem.item;
            }
            if (!this.configuration) {
                this.configuration = this.cardItem.configuration;
            }

        }
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.cardItem) {
            if (!this.model && this.cardItem.item) {
                this.model = this.cardItem.item;
            }
            if (!this.configuration) {
                this.configuration = this.cardItem.configuration;
            }
        }
    }

    public get Globals() {
        return Globals;
    }

    protected readonly Task = Task;
    protected readonly Roster = Roster;
    protected readonly CaseUser = CaseUser;
    protected readonly Project = Project;
    protected readonly Todo = Todo;
    protected readonly Milestone = Milestone;
    protected readonly Appointment = Appointment;
    protected readonly AppointmentForm = AppointmentForm;
    protected readonly Office365Event = Office365Event;
}
