import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Task} from "@app/core/models";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {EditorInterface} from "@app/editor/quick-editor/EditorInterface";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-generic-vertical-edit-collection-cell',
    templateUrl: './generic-vertical-edit-collection-cell.component.html',
    styleUrls: ['./generic-vertical-edit-collection-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GenericVerticalEditCollectionCellComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() cardItem: CardItem;
    @Input() editor: EditorInterface;
    @Input() listDragInterface: ListDragInterface;

    // Bindings to view
    public isReady = false;
    public width?: number;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.editor) {
            this.setupConfiguration();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['editor']) {
            this.setupConfiguration();
        }
    }

    private widthSubscription?: Subscription;
    private setupConfiguration() {
        if (this.editor.configuration?.widthSynchronizer !== undefined) {
            this.width = this.editor.configuration?.widthSynchronizer?.lastValue;
        } else {
            this.width = undefined;
        }

        this.isReady = true;

        this.widthSubscription?.unsubscribe();
        this.widthSubscription = this.editor.configuration?.widthSynchronizer?.onValueChangeEvent?.subscribe(width => {
            this.width = width;
            this.detectChanges();
        });

        this.detectChanges();
    }

}
