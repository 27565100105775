/**
 * Created by ModelParser
 * Date: 05-12-2019.
 * Time: 08:23.
 */
import {StaticDeadlineDefinition} from './definitions/StaticDeadlineDefinition';
import {Color} from "@app/core/models/Color";
import {Api} from '@app/core/Api';
import moment from 'moment';
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";
import {System} from "@app/constants";

export class StaticDeadline extends StaticDeadlineDefinition {

    public color?: Color;

    constructor(json?: any) {
        super(json);
    }

    private static DataCache?: ApiDataCache<StaticDeadline>;

    private static GetDataCache(): ApiDataCache<StaticDeadline> {
        if (!this.DataCache) {
            this.DataCache = new ApiDataCache<StaticDeadline>(
                System.StaticDeadlines,
                2 * 60 * 60, // 2 hours
                Api.staticDeadlines().get()
                    .include('project_type')
            );
        }
        return this.DataCache;
    }

    public static GetAll(callback: ((items: StaticDeadline[]) => void)) {
        StaticDeadline.GetDataCache().get(items => {
            callback(items.sort((a, b) => {
                const d1 = moment(a.date).toDate();
                const d2 = moment(b.date).toDate();
                return d1.getTime() - d2.getTime();
            }));
        });
    }

    public getDate(): Date {
        if (this.date) {
            const _moment: moment.Moment = moment(this.date, 'YYYY-MM-DDTHH:mm:ssZ');
            return _moment.isValid() ? _moment.toDate() : null;
        }
        return null;
    }

}
