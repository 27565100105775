import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Component, ViewChild, Input, Output, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import html2canvas from 'html2canvas';
import { of, from, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
class NgxCaptureService {
  getImage(screen, fullCapture, cropDimensions) {
    let options = {
      logging: false
    };
    if (!fullCapture && cropDimensions.width > 10 && cropDimensions.height > 10) {
      options = Object.assign(Object.assign({}, options), cropDimensions);
    } else if (!fullCapture) {
      return of(null);
    }
    return from(html2canvas(screen, options).then(canv => {
      const img = canv.toDataURL('image/png');
      return img;
    }, err => {
      throw new Error(err);
    }).catch(res => {
      throw new Error(res);
    }));
  }
  // TODO
  downloadImage(img) {
    // this.canvas.nativeElement.src = img;
    // this.downloadLink.nativeElement.href = img;
    // this.downloadLink.nativeElement.download = 'test.png';
    // this.downloadLink.nativeElement.click();
  }
}
NgxCaptureService.ɵprov = i0.ɵɵdefineInjectable({
  factory: function NgxCaptureService_Factory() {
    return new NgxCaptureService();
  },
  token: NgxCaptureService,
  providedIn: "root"
});
NgxCaptureService.decorators = [{
  type: Injectable,
  args: [{
    providedIn: 'root'
  }]
}];
class NgxCaptureComponent {
  constructor(captureService) {
    this.captureService = captureService;
    this.resultImage = new EventEmitter();
    this.isDrawing = false;
    this.mouseStart = {
      x: 0,
      y: 0
    };
    this.cropDimensions = {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };
    this.destroy$ = new Subject();
  }
  ngOnInit() {
    setTimeout(() => {
      this.rect = this.rectangle.nativeElement;
      this.captureZone = this.overlay.nativeElement;
      if (!this.captureZone) {
        console.warn('"captureZone" is not set');
        return;
      }
      this.captureZone.onmousedown = e => this.startCapture(e);
      this.captureZone.onmousemove = e => this.drawRect(e);
      this.captureZone.onmouseup = () => this.endCapture();
    }, 2000);
  }
  startCapture(e) {
    const mouse = this.setMousePosition(e, true);
    this.isDrawing = true;
    this.cropDimensions = {
      x: mouse.x,
      y: mouse.y,
      width: 0,
      height: 0
    };
    this.captureZone.style.cursor = 'crosshair';
  }
  drawRect(e) {
    if (this.isDrawing) {
      const mouse = this.setMousePosition(e, false);
      this.cropDimensions = {
        x: mouse.x - this.mouseStart.x < 0 ? mouse.x : this.mouseStart.x,
        y: mouse.y - this.mouseStart.y < 0 ? mouse.y : this.mouseStart.y,
        width: Math.abs(mouse.x - this.mouseStart.x),
        height: Math.abs(mouse.y - this.mouseStart.y)
      };
      this.setRectangle();
    }
  }
  setMousePosition(e, isStart = false) {
    const ev = e || window.event; // Moz || IE
    const mouse = {
      x: 0,
      y: 0
    };
    if (ev.pageX) {
      // Moz
      mouse.x = ev.clientX;
      mouse.y = ev.clientY;
    } else if (ev.clientX) {
      // IE
      mouse.x = ev.clientX + document.body.scrollLeft;
      mouse.y = ev.clientY + document.body.scrollTop;
    }
    if (isStart) {
      this.mouseStart.x = mouse.x;
      this.mouseStart.y = mouse.y;
    }
    return mouse;
  }
  endCapture() {
    this.captureZone.style.cursor = 'default';
    this.isDrawing = false;
    this.captureService.getImage(this.target, false, Object.assign(Object.assign({}, this.cropDimensions), {
      x: this.cropDimensions.x + window.scrollX,
      y: this.cropDimensions.y + window.scrollY
    })).pipe(take(1), tap(img => {
      this.resultImage.emit(img);
    })).subscribe();
    this.cropDimensions = {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };
    this.setRectangle();
  }
  setRectangle() {
    this.rect.style.left = this.cropDimensions.x + 'px';
    this.rect.style.top = this.cropDimensions.y + 'px';
    this.rect.style.width = this.cropDimensions.width + 'px';
    this.rect.style.height = this.cropDimensions.height + 'px';
  }
}
NgxCaptureComponent.decorators = [{
  type: Component,
  args: [{
    selector: 'ngx-capture',
    template: `
    <ng-content></ng-content>
    <div class="overlay" #over>
      <div class="rectangle" #rect></div>
    </div>
  `,
    styles: [".overlay{top:0px;left:0px;position:fixed;width:100vw;height:100vh}.rectangle{border:1px solid #ff0000;position:absolute}\n"]
  }]
}];
NgxCaptureComponent.ctorParameters = () => [{
  type: NgxCaptureService
}];
NgxCaptureComponent.propDecorators = {
  rectangle: [{
    type: ViewChild,
    args: ['rect', {
      static: true
    }]
  }],
  overlay: [{
    type: ViewChild,
    args: ['over', {
      static: true
    }]
  }],
  target: [{
    type: Input
  }],
  resultImage: [{
    type: Output
  }]
};
class NgxCaptureModule {}
NgxCaptureModule.decorators = [{
  type: NgModule,
  args: [{
    declarations: [NgxCaptureComponent],
    imports: [],
    exports: [NgxCaptureComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
  }]
}];

/*
 * Public API Surface of ngx-capture
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxCaptureComponent, NgxCaptureModule, NgxCaptureService };
