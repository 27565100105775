import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import moment from 'moment';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
    name: 'localizedDate',
    pure: false,
    standalone: false
})
export class LocalizedDatePipe extends DatePipe implements PipeTransform {

    readonly customFormats = {
        medium: 'xxx',
        short: 'xxx',
    };

    constructor(@Inject(LOCALE_ID) locale: string,
                private translateService: TranslateService) {
        super(locale);
    }

    transform(value: string | number | Date, format = 'mediumDate', timezone?: string, locale?: string): any {
        // console.warn('LocalizedDatePipe transform!');

        const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
        if (format === 'microDate') {
            const m = moment(value);
            const n = new Date();
            if (m && m.toDate().getFullYear() != n.getFullYear()) {
                format = this.translateService.instant('_ui_date_format_mediumDate')
            } else {
                format = this.translateService.instant('_ui_date_format_microDate');
            }
        }

        // ---
        // OBS: Sættes i app.module, registerLocaleData(localeNb);
        //---

        return datePipe.transform(value, format);
    }

}
