import {Component, OnInit} from '@angular/core';
import {PANEL_ANIMATION} from "@app/editor/panel.animation";
import {fadeAnimation, slideDownAnimation} from "@app/animations";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {QuillEditorConfig} from "@app/constants";
import {BaseComponent} from "@app/shared/_system/base/base.component";

@Component({
    selector: 'app-text-area-dialog',
    templateUrl: './text-area-dialog.component.html',
    styleUrls: ['./text-area-dialog.component.css'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation,
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    standalone: false
})
export class TextAreaDialogComponent extends BaseComponent implements OnInit {

    public model: string;
    public item: string;
    public title?: string;
    public isLoading = false;
    public editable = false;
    public initialized = false;
    public isOpen = false;

    quillEditorConfig = QuillEditorConfig;

    constructor(
        private activeModal: NgbActiveModal
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setItem(this.model ? this.model : '');
    }

    private setItem(item: string) {
        this.item = item;
        this.isLoading = false;
        this.editable = true;
        this.initialized = true;
        this.isOpen = true;
    }

    closeForm() {
        this.close();
        this.activeModal.close(null);
    }

    delete() {
        if (this.isLoading) {
            return;
        }

        this.activeModal.close({value: null});
    }

    saveForm(close: boolean = true) {
        if (this.isLoading) {
            return;
        }

        this.activeModal.close({value: this.model});
    }

    public get ready():boolean{
        return this.editable && !this.isLoading;
    }

    contentChanged(event:any) {
        this.model = event.html;
    }

    triggerFocus() {

    }

    triggerBlur(event: any) {
        this.model = event.html;
    }

    close() {
        if (this.isOpen) {
            this.isOpen = false;
        }
    }

}
