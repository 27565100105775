import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {ProjectsUser} from "@app/core/models";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {Projects_ProjectUserTypesSettingValue} from "@app/core/Api";

@Component({
    selector: 'app-project-user-list-cell',
    templateUrl: './project-user-list-cell.component.html',
    styleUrls: ['./project-user-list-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectUserListCellComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() row: ProjectsDisplayRow;
    @Input() column: GenericTableColumn;

    // Bindings to view
    public projectsUsers: ProjectsUser[] = [];

    // Data

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        const projectsUsers: ProjectsUser[] = [];
        this.column.column.settings.get(ColumnTypeSettings.ProjectUserTypes)
            ?.getObject<Projects_ProjectUserTypesSettingValue>()
            ?.projectUserTypeIds
            ?.forEach(projectUserTypeId => {
                projectsUsers.push(...this.row.project.item.findProjectsUsersByType(projectUserTypeId));
            });
        this.projectsUsers = projectsUsers;
        this.detectChanges();
    }

}
