import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {fadeAnimation} from '@app/animations';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Department, MilestonePlan, Project, User} from "@app/core/models";
import {Api} from "@app/core/Api";
import {TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "@app/services/snackbar.service";
import {EventService} from "@app/services/event.service";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {AppInjector} from "@app/services/app-injector.service";
import {MilestonesService} from "@app/services/milestones.service";
import {UsersService} from "@app/services/users.service";
import {UserGroups} from "@app/constants";
import {PlacementArray} from "@ng-bootstrap/ng-bootstrap/util/positioning";
import {ShellService} from "@app/services/ShellService/shell.service";

@Component({
    selector: 'app-milestone-plan-selector',
    templateUrl: './milestone-plan-selector.component.html',
    styleUrls: ['./milestone-plan-selector.component.scss'],
    animations: [fadeAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MilestonePlanSelectorComponent extends BaseComponent implements OnInit {

    // Inputs
    @Input() project: Project;
    @Input() placement: PlacementArray = ['bottom', 'left', 'auto'];
    @Input() enableAutoApply = true;
    @Input() enableEdit = true;
    @Input() enableDelete = true;
    @Input() enableConvertToTemplate = true;

    // Output events
    @Output() onMilestonePlanAppliedEvent = new EventEmitter<MilestonePlan>();
    @Output() onMilestonePlanSelectEvent = new EventEmitter<MilestonePlan>();

    // UI
    public milestonePlans: MilestonePlan[];
    public isApplying = false;
    public isLoading = false;
    public isAdmin = false;
    public user: User;

    public departments: Department[] = [];
    public visibleMilestonePlans: MilestonePlan[];

    constructor(private cd: ChangeDetectorRef,
                private snackbar: SnackbarService,
                private translateService: TranslateService,
                private dialogService: BaseDialogService,
                private usersService: UsersService,
                private eventService: EventService,
                private milestonesService: MilestonesService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.isLoading = true;
        this.subscribe(this.milestonesService.milestonePlans$.subscribe((items: MilestonePlan[]) => {
            if (items != null) {
                this.milestonePlans = items.filter(item => {
                    return item.project_type_id == this.project.project_type_id && item.isVisibleForUser();
                });
                this.filterVisibleMilestonePlans();
            }
            this.isLoading = false;
            this.detectChanges();

        }));

        this.isAdmin = AppInjector.getInjector().get(UsersService).user.user_group_id <= UserGroups.Admin;
    }

    public load() {
        MilestonePlan.GetAll(items => {
            this.milestonePlans = items.filter(item => {
                return item.project_type_id == this.project.project_type_id && item.isVisibleForUser();
            });
            this.filterVisibleMilestonePlans();
        });
    }

    public convertToTemplate_Personal() {
        Api.projects().convertToPlanGetById(this.project.id)
            .user_id(AppInjector.getInjector().get(UsersService).user.id)
            .find(_ => {
                this.snackbar.add(this.translateService.instant('_ui_project_editor_convert_to_plan_created'));
                MilestonePlan.InvalidateMilestonePlansCache();
            });
    }

    public convertToTemplate_Team() {
        Api.projects().convertToPlanGetById(this.project.id)
            .department_id(AppInjector.getInjector().get(ShellService).getPageSettings()?.departmentId)
            .find(_ => {
                this.snackbar.add(this.translateService.instant('_ui_project_editor_convert_to_plan_created'));
                MilestonePlan.InvalidateMilestonePlansCache();
            });
    }

    public convertToTemplate_Shared() {
        Api.projects().convertToPlanGetById(this.project.id).find(_ => {
            this.snackbar.add(this.translateService.instant('_ui_project_editor_convert_to_plan_created'));
            MilestonePlan.InvalidateMilestonePlansCache();
        });
    }

    public applyMilestonePlan(milestonePlan: MilestonePlan): void {
        this.onMilestonePlanSelectEvent.emit(milestonePlan);
        if (this.enableAutoApply) {
            if (this.project && this.project.milestones && this.project.milestones.length > 0 || this.project.tasks && this.project.tasks.length > 0) {
                this.dialogService.confirm(
                    this.translateService.instant('_ui_dialog_remove_existing_milestone'),
                    this.translateService.instant('_ui_dialog_remove_existing_milestone_description'),
                    this.translateService.instant('_global_ok'),
                )
                    .then((confirmed) => {
                        if (confirmed) {
                            this.copyMilestonePlan(milestonePlan);
                        }
                    })
                    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
            } else {
                this.copyMilestonePlan(milestonePlan);
            }
        }
    }

    public copyMilestonePlan(milestonePlan: MilestonePlan) {
        if (this.isApplying) {
            return;
        }
        this.isApplying = true;

        this.project.setMilestonePlan(milestonePlan, (error: string) => {
            this.dialogService.alert(
                this.translateService.instant('_ui_item_could_not_be_added', {item: this.translateService.instant('_milestone_plan')}),
                `<div class="d-flex flex-column"><p>${this.translateService.instant('_ui_item_could_not_be_added_description')}</p><code class="d-none alert alert-warning ms-auto">${error}</code></div>`,
                this.translateService.instant('_global_ok')
            )
                .then(() => {

                })
                .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
        }, () => {
            this.isApplying = false;
            this.onMilestonePlanAppliedEvent.emit(milestonePlan);
        });
    }

    public filterByDepartment($event: Department[]) {
        this.departments = $event;
        this.filterVisibleMilestonePlans();
    }

    private filterVisibleMilestonePlans() {
        if (!this.departments || this.departments.length == 0) {
            this.visibleMilestonePlans = this.milestonePlans;
        } else {
            const departmentIds = this.departments.map(d => d.id);
            this.visibleMilestonePlans = this.milestonePlans.filter(milestonePlan => {
                return milestonePlan.departments?.find(department => departmentIds.includes(department.id)) !== undefined;
            });
        }
    }

}
