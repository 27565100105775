import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Department} from "@app/core/models";
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {NgbDropdown, NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {DisplayTabOrder, DisplayTypes} from "@app/constants";

@Component({
    selector: 'app-header-title-filter-selector',
    templateUrl: './header-title-filter-selector.component.html',
    styleUrls: ['./header-title-filter-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HeaderTitleFilterSelectorComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @ViewChild('myDrop', {static: false, read: NgbDropdown}) myDrop: NgbDropdown;
    @ViewChild('popover', {static: false, read: NgbPopover}) popover: NgbPopover;

    @Input() selectedDepartment: Department;

    public title = '';
    public titleToolTip = '';
    public groups: ShellPageData[];
    public activeTab = 0;
    public showDepartment = false;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }


    ngOnInit(): void {
        super.ngOnInit();

        this.subscribe(this.shellService.onHeaderTitleChangeEvent.subscribe(data => {
            // console.log('onHeaderTitleChangeEvent : ', data)
            this.title = data.title;
            this.titleToolTip = data.tooltip;
            if(data.showDepartment !== undefined){
                this.showDepartment = data.showDepartment;
            }
            this.render();
            this.detectChanges();
        }));

        this.subscribe(this.shellService.onPageDataListChangeEvent.subscribe(filterPageDataList => {
            this.render();
        }));

        this.subscribe(this.shellService.onPrimaryPageDataChangeEvent.subscribe(pageData => {
            // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1347
            this.showDepartment = (!pageData || pageData && pageData.display?.display_type_id != DisplayTypes.UserOverview);
            this.render();
        }));

        this.render();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.render();
    }

    private render() {
        this.groups = this.shellService.getPageDataList()
            .filter(pageData => pageData.shellFilterGroup != null)
            .sort((a, b) => DisplayTabOrder.GetIndex(a.display.display_type_id) - DisplayTabOrder.GetIndex(b.display.display_type_id));
        this.detectChanges();
    }

}
