import {Injectable} from '@angular/core';
import Quill from "quill";
import {Sort} from '@app/pages/displays/filtering/Sort';

const UserTypes = {
    Developer: 'DEVELOPER',
    Admin: 'ADMIN',
    Partner: 'PARTNER',
    Customer: 'CUSTOMER'
};

const Actions = {
    Delete: 'DELETE',
    Append: 'APPEND',
};

export const Widths = {
    CardColumn: 216,
    TextColumn: 100,
    DeadlineColumn: 90,
    NumberColumn: 90,
    WeekdayColumn: 150,
    EditorPanelMinWidth: 400,
}

export class WidthHelper {
    public static CalculateNumberOfCardsPerRow(width: number) {
        return Math.floor(width / 204);
    }
}

export const StatusRuleTypes = {
    DefaultConfiguration: '',
    MilestoneConfiguration: 'milestone-configuration',
};

export const System = {
    DBCache: 'cache',
    Loading: 'Klartboard.System.Loading',
    CookieLanguageValues: 'Klartboard.LanguageValues',
    CookieDisplays: 'Klartboard.Displays',
    CookieSettings: 'Klartboard.Settings',
    CookieTheme: 'Klartboard.DarkTheme',
    CookieMobile: 'Klartboard.MoblieTheme',
    CookieDisplaysWithSettings: 'Klartboard.DisplaysWithSettings',
    DatabaseStorage: 'Klartboard.Storage',
    CookieYearWheelSettings: 'Klartboard.CookieYearWheelSettings',
    MilestonePlan: 'Klartboard.MilestonePlan',

    TaskType: 'Klartboard.System.TaskType',
    TaskTypes: 'Klartboard.System.TaskTypes',
    TaskDeadlineTypes: 'Klartboard.System.TaskDeadlineTypes',
    TaskEstimateTypes: 'Klartboard.System.TaskEstimateTypes',
    TaskUserTypes: 'Klartboard.System.TaskUserTypes',

    ProjectType: 'Klartboard.System.ProjectType',
    ProjectTypes: 'Klartboard.System.ProjectTypes',
    ProjectDeadlineTypes: 'Klartboard.System.ProjectDeadlineTypes',
    ProjectStatusTypes: 'Klartboard.System.ProjectStatusTypes',
    ProjectUserTypes: 'Klartboard.System.ProjectUserTypes',

    MilestoneFields: 'Klartboard.System.MilestoneFields',
    MilestoneReactionTypes: 'Klartboard.System.MilestoneReactionTypes',
    MilestoneCategoryTypes: 'Klartboard.System.MilestoneCategoryTypes',

    UserFields: 'Klartboard.System.UserFields',
    Users: 'Klartboard.System.Users',
    UsersByDepartment: 'Klartboard.System.UsersByDepartment',

    DisplaysWithoutSettings: 'Klartboard.System.DisplaysWithoutSettings',
    DisplaysWithSettings: 'Klartboard.System.DisplaysWithSettings',
    DisplayColumns: 'Klartboard.System.DisplayColumns',
    DisplayFilters: 'Klartboard.System.DisplayFilters',

    EstimateUnitTypes: 'Klartboard.System.EstimateUnitTypes',
    CategoryTypes: 'Klartboard.System.CategoryTypes',
    Tags: 'Klartboard.System.Tags',
    StaticDeadlines: 'Klartboard.System.StaticDeadlines',
    Phases: 'Klartboard.System.Phases',
    Departments: 'Klartboard.System.Departments',
    DepartmentGroup: 'Klartboard.System.DepartmentGroup',
    Site: 'Klartboard.System.Site',
    Translations: 'Klartboard.System.Translations',
};

export const DynamicMenuItemTypes = {
    Link: 'link',
    Category: 'category',
    Appointment: 'appointment',
    AppointmentList: 'appointment-list',
};


var Link = Quill.import('formats/link');
class MyLink extends Link {
    static create(value:any) {
        const node = super.create(value);
        node.removeAttribute('target');
        return node;
    }
}
Quill.register(MyLink, true);

export const QuillEditorConfig = {
    'toolbar': [
        ['bold', 'italic', 'underline'],
        ['blockquote', 'code-block'],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        // ['link'],    // Virker ikke ift placering, overflow og kan ikke umiddelbart ændres
        ['clean'],      // remove formatting button

    ]
};


export const DisplayTypes = {
    Department: 1,
    DepartmentOverview: 2,
    Team: 3,
    Projects: 4,                //
    Project: 5,                 // Milepælstavle / Planlægningsrum, removed in 3.0.0
    CombinedBottom: 6,
    CaseShelves: 7,             // Top-tavle, Sagshylde
    YearWheel: 8,               // Årshjul
    Office: 9,                  // // No longer in use  removed in 3.0.0
    YearWheelLarge: 10,         //
    UserOverview: 12,           // Mit overblik
    UserOverviewCalendar: 16,
    UserOverviewProjects: 17,
    UserOverviewMyWeek: 20,
    UserOverviewSidebarTaskList: 26,
    Program: 23,                // No longer in use
    StrategyGoals: 24, // Removed
    Efforts: 25, // Removed
    TeamWeekly: 27,
    LoadSummary: 28,
    Kanban: 29,
    KanbanProgress: 30,
    Category: 31,
    Kaizen: 32, // Deleted in 2.23.0
    ProjectDetails: 33,
    ProjectDetails_Top: 34,
    ProjectDetails_Milestones: 35,
    ProjectDetails_Users: 36,
    ProjectDetails_Tasks: 37,
};

export class DisplayTabOrder {

    private static Indexes = [
        DisplayTypes.ProjectDetails_Top,
        DisplayTypes.ProjectDetails_Milestones,
        DisplayTypes.ProjectDetails_Users,
        DisplayTypes.ProjectDetails_Tasks,
    ];

    public static GetIndex(type: number): number {
        const index = DisplayTabOrder.Indexes.indexOf(type);
        return index !== -1 ? index : 0;
    }
}


export const MilestoneRowViews = {
    Basic: 'milestone-row-view-basic',
    Tasks: 'milestone-row-view-tasks',
    StatusRules: 'milestone-row-view-status-rules',
    Overview: 'milestone-row-view-overview',
};

export const Tabs = {
    Basic: 'tab-basic',
    PreDefined: 'tab-pre-defined',
    Logs: 'tab-logs',
    Debug: 'tab-debug',
    Who: 'tab-who',
    AttachTo: 'tab-attach-to',
    When: 'tab-when',
    Contents: 'tab-contents',
    ProcessSteps: 'tab-process-steps',
    Filters: 'tab-filters',
    Categories: 'tab-categories',
};

export const TaskDeadlineTypes = {
    Normal: 1,
};

export const MTMilestoneDeadlineTypes = {
    NONE: 0,
    PERCENT_AFTER_START: 1,
    DAYS_AFTER_START: 2,
    DAYS_BEFORE_END: 3,
    DAYS_AFTER_END: 4,
    DAYS_AFTER_LAST_MILESTONE: 5,
    DAYS_BEFORE_LAST_MILESTONE: 6,
    DAYS_BEFORE_START: 7,
};


export const MTTaskDeadlineTypes = {
    NONE: 0,
    DAYS_BEFORE_MILESTONE: 1,
    DAYS_AFTER_MILESTONE: 2,
    PERCENT_BEFORE_MILESTONE: 3,
};

export const TaskStatusTypes = {
    Normal: 1,
};

export const ProjectStatusTypes = {
    Normal: 1,
};

export const ProjectDeadlineTypes = {
    Normal: 1,
};

export const TaskUserTypes = {
    Creator: 101,
    Participant: 102,
};

export const ProjectUserTypes = {
    Responsible: 1,
    Participant: 2,
};

export const UserGroups = {
    Uber: 1,
    SysAdmin: 10, // Supportbruger
    Admin: 20,
    Employee: 30
};

export const ListTypes = {
    CardList: 'CardList',
    SimpleList: 'SimpleList',
    EditList: 'EditList',
};


export const UserTypesValues = {
    Developer: 100,
    Admin: 200,
    Partner: 300,
    Customer: 400
};

export const StatusTypeClasses = {
    0: 'bg-primary',
    1: 'bg-secondary',
    2: 'bg-success',
    3: 'bg-warning',
    4: 'bg-danger',
};

export const StatusTypeColors = {
    0: 'bg-primary',
    1: 'bg-secondary',
    2: 'bg-success',
    3: 'bg-warning',
    4: 'bg-danger',
};

// http://www.lingoes.net/en/translator/langcode.htm
export const LanguageIds = {
    1: 'da-DK',
    2: 'en-US',
    // 3: 'de',
    4: 'nb',
    5: 'nn',
    // 5: 'sv-SE',
};

export const StatusTypes = {
    NOT_DEFINED: 0,
    GREEN: 2,
    YELLOW: 3,
    RED: 4,
    GREY: 1,
    /*
    1: 'bg-secondary',
    2: 'bg-warning',
    3: 'bg-success',
    4: 'bg-danger',
    */
};


export const StatusTypesAll = [
    StatusTypes.GREEN,
    StatusTypes.YELLOW,
    StatusTypes.RED,
    StatusTypes.GREY
];


export const TasksUserAcceptanceStatus = {
    New: 0,
    Approved: 1,
    Declined: 2
};

export const EstimateUnits = {
    Minutes: 1,
    Hours: 2,
    Days: 3,
    Weeks: 4,
    Months: 5,
    Years: 6,
    Pages: 7,
    Millions: 8,
    Thousand: 9,
    WorkDay: 10
};

export const PeriodUnits = {
    Days: 'DAYS',
    Weeks: 'WEEKS',
    Months: 'MONTHS',
};

export const DisplayFilterTypes = {

    // Teamtavlen
    AllPlanned    : 'all-planned',  // Planlagte i perioden
    ByDeadline    : 'by-deadline',  // Med frist i perioden
    Overdue       : 'overdue',
    Featured      : 'featured',     // Legacy, vi bruger nu filtreret
    ColumnsOnly   : 'filter-columns-only',

};

export const DisplayFilterPeriods = {
    SelectedWeek    : 'selected-week',
    UserDefined     : 'user-defined',
    Today           : 'today',
    TodayTomorrow   : 'today-tomorrow',
    ThisWeek        : 'this-week',
    NextWeek        : 'next-week',
    Next2Week       : 'next-2-weeks',
    Next7Days       : 'next-7-days',
    Next14Days      : 'next-14-days'
};

export const FieldTypes = {
    Editor      : 'editor',
    MiniCard    : 'minicard'
};

export const CalculatedFieldTypes = {
    RelatedTasks                    : 'related-tasks',
    Task_WeekPlanningDateChanges    : 'task-week-planning-date-changes',
    Milestone_RelatedTasksEstimateSum : 'milestone-related-tasks-estimate-sum',
};

export const ReactionTypes = {
    Emoji: 1,
    Hand: 2,
    Star: 3,
}

export class FictiveDisplaysSettings {
    public static Kanban_Phases                     = 100000;
    public static KanbanProgress_PhaseProgressType  = 200000;
    public static Categories_Category               = 300000;
    public static Kaizen_CategoryType2Category      = 400000;

    public static ConvertKanbanPhaseId2SettingId(phaseId: number): number {
        return phaseId + FictiveDisplaysSettings.Kanban_Phases;
    }
    public static ConvertSettingId2KanbanPhaseId(settingId: number): number {
        return settingId - FictiveDisplaysSettings.Kanban_Phases;
    }

    public static ConvertKanbanProgressPhaseProgressTypeId2SettingId(phaseProgressTypeId: number): number {
        return phaseProgressTypeId + FictiveDisplaysSettings.KanbanProgress_PhaseProgressType;
    }
    public static ConvertSettingId2KanbanProgressPhaseProgressTypeId(settingId: number): number {
        return settingId - FictiveDisplaysSettings.KanbanProgress_PhaseProgressType;
    }

    public static ConvertCategoriesCategoryId2SettingId(categoryId: number): number {
        return categoryId + FictiveDisplaysSettings.Categories_Category;
    }
    public static ConvertSettingId2CategoriesCategoryId(settingId: number): number {
        return settingId - FictiveDisplaysSettings.Categories_Category;
    }

    public static ConvertKaizenCategoryType2CategoryId2SettingId(categoryId: number): number {
        return categoryId + FictiveDisplaysSettings.Kaizen_CategoryType2Category;
    }
    public static ConvertSettingId2KaizenCategoryType2CategoryId(settingId: number): number {
        return settingId - FictiveDisplaysSettings.Kaizen_CategoryType2Category;
    }
}

export const Configs = {

    // Features
    Feature_Azure: 'feature-azure',
    Feature_AzureAuth: 'feature-azure-auth',
    Feature_IpRestriction: 'feature-ip-restriction',

    // Defaults
    Default_IsWaitingTeamSelection: 'default-is-waiting-team-selection',
    Default_Language: 'default-language',

    IpRestrictionList: 'ip-restriction-list',

}

export const DeletionTypes = {
    Project: 'project',
    Milestone: 'milestone',
    Task: 'task'
};


// Template Exports

export const CONSTANTS = {

    UserTypes: UserTypes,
    ReactionTypes: ReactionTypes,

    TaskDeadlineTypes: TaskDeadlineTypes,

    Tabs: Tabs,
    UserGroups: UserGroups,
    Actions: Actions
};



export class Events {
    private static prefix = '/exchange/events/';
    private static Generate(name: string): string {
        name = name
            .toLocaleLowerCase()
            .replace(new RegExp('-', 'g'), '_');
        return `${Events.prefix}${name}`;
    }

    public static UserTaskAdded(userId: number): string {
        return Events.Generate(`user.task.added.${userId}`);
    }

    public static UserTaskRemoved(userId: number): string {
        return Events.Generate(`user.task.removed.${userId}`);
    }

    // <editor-fold desc="Milestone">

    public static MilestoneCreated(): string {
        return Events.Generate(`milestone.created`);
    }

    public static MilestoneChanged(milestoneId: number, field: string): string {
        return Events.Generate(`milestone.changed.${milestoneId}.${field}`);
    }

    public static MilestoneChangedTitle(milestoneId: number): string {
        return Events.Generate(`milestone.${milestoneId}.changed.title`);
    }

    public static MilestoneChangedStatus(milestoneId: number): string {
        return Events.Generate(`milestone.${milestoneId}.changed.status`);
    }

    public static MilestoneChangedArchived(milestoneId: number): string {
        return Events.Generate(`milestone.${milestoneId}.changed.archived`);
    }

    public static MilestoneChangedResponsible(milestoneId: number): string {
        return Events.Generate(`milestone.${milestoneId}.changed.responsible`);
    }

    public static MilestoneChangedDeadline(milestoneId: number): string {
        return Events.Generate(`milestone.${milestoneId}.changed.deadline`);
    }

    public static MilestoneChangedUseStatusRules(milestoneId: number): string {
        return Events.Generate(`milestone.${milestoneId}.changed.use_status_rules`);
    }

    public static MilestoneChangedNotes(milestoneId: number): string {
        return Events.Generate(`milestone.${milestoneId}.changed.notes`);
    }

    public static MilestoneChangedRisk(milestoneId: number): string {
        return Events.Generate(`milestone.${milestoneId}.changed.risk`);
    }

    public static MilestoneChangedProjects(milestoneId: number): string {
        return Events.Generate(`milestone.${milestoneId}.changed.projects`);
    }

    public static MilestoneChangedCategories(milestoneId: number): string {
        return Events.Generate(`milestone.${milestoneId}.changed.categories`);
    }

    public static MilestoneCalculatedFieldChanged(calculatedFieldId: number, milestoneId: number): string {
        return Events.Generate(`milestone.calculated.field.changed.${calculatedFieldId}.${milestoneId}`);
    }

    public static MilestoneReactionsChanged(milestoneId: number): string {
        return Events.Generate(`milestone.reactions.changed.${milestoneId}`);
    }

    // </editor-fold>

    // <editor-fold desc="Project">

    public static ProjectCreated(): string {
        return Events.Generate(`project.created`);
    }

    public static ProjectChanged(projectId: number, field: string): string {
        return Events.Generate(`project.changed.${projectId}.${field}`);
    }

    public static ProjectChangedTitle(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.title`);
    }

    public static ProjectChangedStatuses(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.statuses`);
    }

    public static ProjectChangedArchived(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.archived`);
    }

    public static ProjectChangedUsers(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.users`);
    }

    public static ProjectChangedDeadlines(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.deadlines`);
    }

    public static ProjectChangedEstimates(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.estimates`);
    }

    public static ProjectChangedUseStatusRules(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.use_status_rules`);
    }

    public static ProjectChangedNotes(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.notes`);
    }

    public static ProjectChangedPurpose(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.purpose`);
    }

    public static ProjectChangedObs(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.obs`);
    }

    public static ProjectChangedReference(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.reference`);
    }

    public static ProjectChangedCategories(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.categories`);
    }

    public static ProjectChangedDepartments(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.departments`);
    }

    public static ProjectChangedTags(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.tags`);
    }

    public static ProjectChangedCurrentPhasesProject(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.current_phases_project`);
    }

    public static ProjectChangedNextMilestone(projectId: number): string {
        return Events.Generate(`project.${projectId}.changed.next_milestone`);
    }

    public static ProjectReactionsChanged(projectId: number): string {
        return Events.Generate(`project.reactions.changed.${projectId}`);
    }

    public static ProjectPhasesProjectsChanged(projectId: number): string {
        return Events.Generate(`project.phases.projects.changed.${projectId}`);
    }

    public static ProjectCalculatedFieldChanged(calculatedFieldId: number, projectId: number): string {
        return Events.Generate(`project.calculated.field.changed.${calculatedFieldId}.${projectId}`);
    }

    // </editor-fold>

    // <editor-fold desc="Task">

    public static TaskCreated(): string {
        return Events.Generate(`task.created`);
    }

    public static TaskChanged(taskId: number, field: string): string {
        return Events.Generate(`task.changed.${taskId}.${field}`);
    }

    public static TaskChangedTitle(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.title`);
    }

    public static TaskChangedStatuses(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.statuses`);
    }

    public static TaskChangedArchived(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.archived`);
    }

    public static TaskChangedUsers(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.users`);
    }

    public static TaskChangedDeadlines(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.deadlines`);
    }

    public static TaskChangedEstimates(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.estimates`);
    }

    public static TaskChangedUseStatusRules(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.use_status_rules`);
    }

    public static TaskChangedPurpose(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.purpose`);
    }

    public static TaskChangedDeliveryDescription(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.delivery_description`);
    }

    public static TaskChangedSuccessCriteria(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.success_criteria`);
    }

    public static TaskChangedObs(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.obs`);
    }

    public static TaskChangedCategories(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.categories`);
    }

    public static TaskChangedDepartments(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.departments`);
    }

    public static TaskChangedTags(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.tags`);
    }

    public static TaskChangedCanCopy(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.can_copy`);
    }

    public static TaskChangedIsPrivate(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.is_private`);
    }

    public static TaskChangedCasesPlanned(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.cases_planned`);
    }

    public static TaskChangedCasesReached(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.cases_reached`);
    }

    public static TaskChangedMilestones(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.milestones`);
    }

    public static TaskChangedProjects(taskId: number): string {
        return Events.Generate(`task.${taskId}.changed.projects`);
    }

    public static TaskCalculatedFieldChanged(calculatedFieldId: number, taskId: number): string {
        return Events.Generate(`task.calculated.field.changed.${calculatedFieldId}.${taskId}`);
    }

    public static TaskReactionsChanged(taskId: number): string {
        return Events.Generate(`task.reactions.changed.${taskId}`);
    }

    // </editor-fold>

    // <editor-fold desc="To-do">

    public static TodoCreated(): string {
        return Events.Generate(`todo.created`);
    }

    public static TodoChanged(todoId: number, field: string): string {
        return Events.Generate(`todo.changed.${todoId}.${field}`);
    }

    public static TodoChangedTitle(todoId: number): string {
        return Events.Generate(`todo.${todoId}.changed.title`);
    }

    public static TodoChangedCategory(todoId: number): string {
        return Events.Generate(`todo.${todoId}.changed.category`);
    }

    public static TodoChangedStatus(todoId: number): string {
        return Events.Generate(`todo.${todoId}.changed.status`);
    }

    public static TodoChangedArchived(todoId: number): string {
        return Events.Generate(`todo.${todoId}.changed.archived`);
    }

    public static TodoChangedUser(todoId: number): string {
        return Events.Generate(`todo.${todoId}.changed.user`);
    }

    public static TodoChangedDeadline(todoId: number): string {
        return Events.Generate(`todo.${todoId}.changed.deadline`);
    }

    public static TodoChangedDescription(todoId: number): string {
        return Events.Generate(`todo.${todoId}.changed.description`);
    }

    public static TodoChangedProject(todoId: number): string {
        return Events.Generate(`todo.${todoId}.changed.project`);
    }

    public static TodoChangedIsPrivate(todoId: number): string {
        return Events.Generate(`todo.${todoId}.changed.is_private`);
    }

    public static TodoChangedShowOnDisplay(todoId: number): string {
        return Events.Generate(`todo.${todoId}.changed.show_on_display`);
    }

    public static TodoReactionsChanged(todoId: number): string {
        return Events.Generate(`todo.reactions.changed.${todoId}`);
    }

    // </editor-fold>

}


Injectable();

export default class Globals {
    static LanguageIds = LanguageIds;
    static MilestoneRowViews = MilestoneRowViews;
    static ListTypes = ListTypes;
    static UserTypes = UserTypes;
    static DynamicMenuItemTypes = DynamicMenuItemTypes;
    static ColumnWidths = Widths;
    static UserTypesValues = UserTypesValues;
    static StatusTypeClasses = StatusTypeClasses;
    static StatusTypeColors = StatusTypeColors;
    static StatusTypes = StatusTypes;
    static StatusTypesAll = StatusTypesAll;
    static TaskDeadlineTypes = TaskDeadlineTypes;
    static MTMilestoneDeadlineTypes = MTMilestoneDeadlineTypes;
    static MTTaskDeadlineTypes = MTTaskDeadlineTypes;
    static TaskUserTypes = TaskUserTypes;
    static Tabs = Tabs;
    static DisplayTypes = DisplayTypes;
    static DisplayFilterPeriods = DisplayFilterPeriods;
    static DisplayFilterTypes = DisplayFilterTypes;
    static System = System;

    static UserGroups = UserGroups;
    static Actions = Actions;

    static ProjectStatusTypes = ProjectStatusTypes;
    static TasksUserAcceptanceStatus = TasksUserAcceptanceStatus;
    static EstimateUnits = EstimateUnits;
    static PeriodUnits = PeriodUnits;
    static CalculatedFieldTypes = CalculatedFieldTypes;
    static ReactionTypes = ReactionTypes;
    static  SortTypes = {
        Display: Sort.SortTypeDisplay,
        Default: Sort.SortTypeDefault,
        Column: Sort.SortTypeColumn,
    }
}
