import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {Category, CategoryType} from "@app/core/models";

@Component({
    selector: 'app-categories-selector',
    templateUrl: './categories-selector.component.html',
    styleUrls: ['./categories-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CategoriesSelectorComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() categories: Category[] = [];
    @Input() showReset = true;
    @Input() showFooter = true;
    @Input() tooltip: string;
    @Output() onCategoryAddedEvent = new EventEmitter<Category>();
    @Output() onCategoryRemovedEvent = new EventEmitter<Category>();
    @Output() onCategoriesChangeEvent = new EventEmitter<Category[]>();

    // UI
    public activeTab: number = 1;
    public categoryTypes: CategoryType[];
    public categoryMap: Map<number, Category>;
    public filtersAltered = false;

    private categoriesLoaded = false;
    private selectedDepartment: number;

    constructor(private cdr_: ChangeDetectorRef) {
        super();
        this.cdr = this.cdr_;
    }

    ngOnInit(): void {
        if (this.categories && !this.categoryTypes) {
            this.mapCategories();
        }
        if (!this.tooltip) {
            this.tooltip = this.translateService.instant('_ui_display_reaction_filter_category');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.categories) {
            this.mapCategories();
        }
    }

    private render() {
        if (this.categoryMap) {
            this.detectChanges();
        }
    }

    public toggleCategory(category: Category) {
        if (!this.categoryMap.has(category.id)) {
            this.categoryMap.set(category.id, category);
            this.categories.push(category);
            this.onCategoryAddedEvent.emit(category);
        } else {
            this.categoryMap.delete(category.id);
            this.categories.splice(this.categories.findIndex(c => c.id == category.id), 1);
            this.onCategoryRemovedEvent.emit(category);
        }

        this.filtersAltered = true;
        if (!this.showReset) {
            this.activateFilters();
        }
    }

    private mapCategories() {
        this.categoryMap = new Map<number, Category>();
        if (this.categories) {
            this.categories.forEach(category => {
                this.categoryMap.set(category.id, category);
            });
        }

        this.render();
    }

    loadCategories() {
        const pageSettings = this.shellService.getPageSettings();
        if (!this.categoriesLoaded || this.selectedDepartment != pageSettings.departmentId) {
            this.selectedDepartment = pageSettings.departmentId;
            CategoryType.GetAll(categoryTypes => {
                // Avoid mutating caches category types https://podio.com/klartboard/softwareudvikling/apps/supports/items/1041
                this.categoryTypes = categoryTypes.map(categoryType => new CategoryType(categoryType));
                Category.GetAll(categories => {
                    this.categoryTypes.forEach(ct => {
                        ct.categories = categories.filter(cat => cat.category_type_id == ct.id && cat.departments && cat.departments.find(d => d.id == this.selectedDepartment));
                    });
                })
                this.categoriesLoaded = true;
                this.mapCategories();
            });
        }
    }

    removeAllItems() {
        this.categories = [];
        this.mapCategories();
        this.filtersAltered = true;
        this.activateFilters();
    }

    activateFilters() {
        this.onCategoriesChangeEvent.emit(this.categories);
        this.filtersAltered = false;
    }

}
