/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TodoDefinition} from './definitions/TodoDefinition';
import {AppInjector} from '@app/services/app-injector.service';
import {EventService} from '@app/services/event.service';
import {Events, StatusTypes} from '@app/constants';
import {TodoStatus} from '@app/core/models/TodoStatus';
import {ReactionsApiInterface} from "@app/shared/_ui/reactions/ReactionsApiInterface";
import {Reaction} from "@app/core/models/Reaction";
import {Api, TodosCreateRequestPreset} from "@app/core/Api";
import {ReactionsSourceInterface} from "@app/shared/_ui/reactions/ReactionsSourceInterface";
import {HasTypeProperties} from "@app/interfaces/HasTypeProperties";
import {EditStatus} from "@app/editor/quick-editor/editors/generic/status-editor/EditStatus";
import {EditIsPrivate} from "@app/editor/quick-editor/editors/generic/is-private-editor/EditIsPrivate";
import {EditReactionList} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionList";
import {EditTitle} from "@app/editor/quick-editor/editors/generic/title-editor/EditTitle";
import {EditText} from "@app/editor/quick-editor/editors/generic/text-editor/EditText";
import {EditUserList} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserList";
import {EditDeadlineList} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineList";
import {EditProjectList} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectList";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {MilestoneStatus} from "@app/core/models/MilestoneStatus";
import {Archived, Deadline, Department, Project, TodoCategory, User} from "@app/core/models/index";
import {
    EditProjectListFilter
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListFilter";
import {EditArchived} from "@app/editor/quick-editor/editors/generic/archived-editor/EditArchived";
import {EditTodoCategory} from "@app/editor/quick-editor/editors/todo/todo-category-editor/EditTodoCategory";
import {EditCheckbox} from "@app/editor/quick-editor/editors/generic/checkbox-editor/EditCheckbox";

export class Todo extends TodoDefinition implements
    ReactionsApiInterface,
    ReactionsSourceInterface,

    EditArchived,
    EditStatus,
    EditIsPrivate,
    EditReactionList,
    EditTitle,
    EditText,
    EditUserList,
    EditDeadlineList,
    EditProjectList,
    EditTodoCategory,
    EditCheckbox,

    HasTypeProperties,
    HasEventGenerator {

    type = 'T';

    constructor(json?: any) {
        super(json);
    }

    public get status(): number {
        if (this.main_status && this.main_status.status_id) {
            return this.main_status.status_id;
        } else {
            return StatusTypes.GREEN;
        }
    }

    public set status(value: number) {
        if (this.main_status && this.main_status.status_id) {
            this.main_status.status_id = value;
            if (this.main_status) delete this.main_status.status;
        } else {
            this.main_status = new TodoStatus(
                {status_id: value, todo_status_type_id: 1}
            );
        }
    }

    public delete(callback?: () => void) {
        Api.todos().deleteByTodoId(this.id)
            .delete(value => {
                AppInjector.getInjector().get(EventService).emitTodo(this, EventService.Deleted);
                if (callback) {
                    callback()
                }
            });
    }

    // <editor-fold desc="Reaction interface">

    public addReaction(reaction: Reaction): void {
        Api.todos().reactionAddPutByTodoId(this.id)
            .reaction_type_id(reaction.reaction_type_id)
            .value(reaction.value)
            .user_id(reaction.user_id)
            .save(null);
    }

    public removeReaction(reaction: Reaction): void {
        Api.todos().reactionRemoveDeleteByTodoId(this.id)
            .reaction_type_id(reaction.reaction_type_id)
            .value(reaction.value)
            .user_id(reaction.user_id)
            .delete(null);
    }

    public getReactionChangedEventName(): string {
        return Events.TodoReactionsChanged(this.id);
    }

    // </editor-fold>

    // <editor-fold desc="Has Type Properties">

    getTypeDefinitionAsync(callback: (definition: string) => void): void {
        callback('T');
    }

    getTypeNameAsync(callback: (name: string) => void): void {
        callback('');
    }

    // </editor-fold>

    public static Create(presets: TodosCreateRequestPreset[], callback?: (todo: Todo) => void) {
        Api.todos()
            .createPost()
            .save({presets: presets}, todo => {
                AppInjector.getInjector().get(EventService).emitTodo(todo, EventService.Created);
                if (callback) {
                    callback(todo);
                }
            });
    }

    getEventName(field: string, options: any = {}): string {
        switch (field) {
            case 'title':
                return Events.TodoChangedTitle(this.id);
            case 'todo-category':
                return Events.TodoChangedCategory(this.id);
            case 'deadlines':
                return Events.TodoChangedDeadline(this.id);
            case 'archived':
                return Events.TodoChangedArchived(this.id);
            case 'projects':
                return Events.TodoChangedProject(this.id);
            case 'main-status':
                return Events.TodoChangedStatus(this.id);
            case 'users':
                return Events.TodoChangedUser(this.id);
            case 'note-field':
                switch (options?.prop ?? '') {
                    case 'description':
                        return Events.TodoChangedDescription(this.id);
                    default:
                        return Events.TodoChanged(this.id, field);
                }
            case 'checkbox-field':
                switch (options?.prop ?? '') {
                    case 'show_on_display':
                        return Events.TodoChangedShowOnDisplay(this.id);
                    default:
                        return Events.TodoChanged(this.id, field);
                }
            case 'is-private':
                return Events.TodoChangedIsPrivate(this.id);
            default:
                return Events.TodoChanged(this.id, field);
        }
    }

    // <editor-fold desc="EditTitle">

    getTitle(): string {
        return this.title ?? '';
    }

    setTitle(value: string): void {
        this.title = value;
        Api.todos()
            .updateTitlePutByTodoId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTodo(this, EventService.Updated, ['title']);
    }

    // </editor-fold>

    // <editor-fold desc="EditStatus">

    getStatus(): number {
        return this.main_status?.status_id ?? StatusTypes.GREEN;
    }

    setStatus(value: number): void {
        this.main_status = MilestoneStatus.CreateDefault(value);
        Api.todos()
            .updateStatusPutByTodoId(this.id)
            .value(value)
            .resetUseStatusRules(true)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTodo(this, EventService.Updated, ['main_status']);
    }

    // </editor-fold>

    // <editor-fold desc="EditArchived">

    isArchived(): boolean {
        return (this.archived_id ?? 0) > 0;
    }

    setArchived(value: boolean): void {
        if (value) {
            this.archived = Archived.create();
        } else {
            this.archived = undefined;
            this.archived_id = 0;
        }
        Api.todos()
            .updateArchivedPutByTodoId(this.id)
            .value(value)
            .save(null, milestone => {
                this.archived = milestone.archived;
                this.archived_id = milestone.archived_id;

                AppInjector.getInjector().get(EventService)
                    .emitTodo(this, EventService.Updated, ['archived', 'archived_id']);
            });
    }

    // </editor-fold>

    // <editor-fold desc="EditUserList">

    public findUsersByTypeId(typeId: number): User[] {
        const hasUser = (this.user_id ?? 0) > 0
            && this.user?.exists();
        return hasUser ? [this.user] : [];
    }

    public getUsers(): User[] {
        return [];
    }

    public addUser(typeId: number, user: User) {
        this.setUser(user);
    }

    public addUsersFromDepartment(typeId: number, department: Department, users: User[]) {
        // Not implemented
    }

    public removeUser(typeId: number, user: User) {
        this.setUser(undefined);
    }

    public removeUsers(typeId: number, users: User[]) {
        this.setUser(undefined);
    }

    public setUser(user?: User): void {
        this.user = user;
        this.user_id = user?.id ?? 0;

        Api.todos()
            .updateUserPutByTodoId(this.id)
            .userId(this.user?.id ?? 0)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTodo(this, EventService.Updated, ['user', 'user_id']);
    }

    // </editor-fold>

    // <editor-fold desc="EditDeadlineList">

    findDeadlineByTypeId(typeId: number): Deadline | undefined {
        const hasDeadline = (this.deadline_id ?? 0) > 0
            && this.deadline?.exists();
        return hasDeadline ? this.deadline : undefined;
    }

    setDeadline(typeId: number, deadline?: Deadline): void {
        this.deadline = deadline;
        this.deadline_id = null;

        Api.todos()
            .updateDeadlinePutByTodoId(this.id)
            .date(deadline?.getServerDate() ?? null)
            .isSoft(deadline?.is_soft ?? false)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTodo(this, EventService.Updated, ['deadline', 'deadline_id']);
    }

    // </editor-fold>

    // <editor-fold desc="EditProjectList">

    getProjects(filter: EditProjectListFilter): Project[] {
        return this.project?.exists() ? [this.project] : [];
    }

    addProject(project: Project, filter: EditProjectListFilter, callback: () => void): void {
        this.project = project;
        this.project_id = project.id;

        Api.todos()
            .updateProjectPutByTodoId(this.id)
            .value(project.id)
            .save(null, callback);
    }

    removeProject(project: Project, filter: EditProjectListFilter): void {
        this.project = undefined;
        this.project_id = 0;

        Api.todos()
            .updateProjectPutByTodoId(this.id)
            .value(0)
            .save(null);
    }

    // </editor-fold>

    // <editor-fold desc="EditText">

    getText(prop: string): string {
        return (this as any)[prop] ?? '';
    }

    setText(prop: string, value: string): void {
        switch (prop) {
            case 'description':
                this.setDescription(value);
                break;
        }
    }

    public setDescription(value: string): void {
        this.description = value;
        Api.todos()
            .updateDescriptionPutByTodoId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTodo(this, EventService.Updated, ['notes']);
    }

    // </editor-fold>

    // <editor-fold desc="EditCheckbox">

    public isCheckboxChecked(prop: string): boolean {
        return (this as any)[prop] ?? '';
    }

    public setCheckboxChecked(prop: string, value: boolean): void {
        switch (prop) {
            case 'show_on_display':
                this.setShowOnDisplay(value);
                break;
        }
    }

    public setShowOnDisplay(value: boolean): void {
        this.show_on_display = value;
        Api.todos()
            .updateShowOnDisplayPutByTodoId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTodo(this, EventService.Updated, ['show_on_display']);
    }

    // </editor-fold>

    // <editor-fold desc="EditIsPrivate">

    isIsPrivate(): boolean {
        return this.is_private ?? false;
    }

    setIsPrivate(value: boolean): void {
        this.is_private = value;
        Api.todos()
            .updateIsPrivatePutByTodoId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTodo(this, EventService.Updated, ['is_private']);
    }

    // </editor-fold>

    // <editor-fold desc="EditTodoCategory">

    public getTodoCategory(): TodoCategory | undefined {
        return this.todo_category;
    }

    public setTodoCategory(value?: TodoCategory): void {
        this.todo_category = value;
        this.todo_category_id = value?.id;
        Api.todos()
            .updateCategoryPutByTodoId(this.id)
            .todoCategoryId(value?.id ?? 0)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTodo(this, EventService.Updated, ['todo_category']);
    }

    // </editor-fold>

}
