import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {Display} from '@app/core/models/Display';
import {DepartmentsDisplay} from '@app/core/models/DepartmentsDisplay';
import {Department} from '@app/core/models/Department';
import {User} from '@app/core/models/User';
import Helpers from '@app/core/helpers';
import {DisplayTypes} from '@app/constants';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DisplayService} from '@app/services/display.service';
import {SnackbarService} from "@app/services/snackbar.service";
import {AppInjector} from '@app/services/app-injector.service';

export interface DisplayLink {
    icon: string;
    name: string;
    display: Display;
    department: Department;
    route: any[];
    favorite?: boolean;
    index_?:number;
    active?: boolean;
}

@Component({
    selector: 'app-display-list',
    templateUrl: './display-list.component.html',
    styleUrls: ['./display-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DisplayListComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() favorites: boolean = false;
    @Input() departmentSelection: boolean = false;
    @Input() limitToDepartment: boolean = false;
    @Input() navigate: boolean = true;
    @Input() limit: number = 50;
    @Input() className: string = 'flex-row scroll-x dark';
    @Input() displayHorizontal: boolean = false;
    @Input() activeDisplay: Display;
    @Input() activeDepartment: Department;
    @Input() department: Department;
    @Input() displayUser: User;
    @Input() showDepartment: boolean = false;
    @Input() navigateDashboard: boolean = true;
    @Output() itemSelected = new EventEmitter<DisplayLink>();

    public displayLinks: DisplayLink[] = [];
    private _displays: Display[];
    private url: string;

    constructor(private cd: ChangeDetectorRef,
                private router: Router,
                private snackbar: SnackbarService,
                private displayService: DisplayService,
                private activatedRoute: ActivatedRoute
                ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.usersService.currentUser$.subscribe(u => {
            if(u && !this._displays){
                this.displayService.getDisplaysWithoutSettings(displays => {
                    this._displays = displays.filter(display => {
                        return display.routerPath != null;
                    });
                    this.generateDisplayLinks();
                });
            }
        })

        this.router.events.subscribe((event)=>{
            if (event instanceof NavigationEnd) {
                this.updateActiveLinks()
            }
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['department'] ) {
            this.generateDisplayLinks();
        }
        this.detectChanges();
    }


    get displays(): Display[] {
        let count = 0;
        let tmpDisplays: Display[] = [];

        if (this.favorites && false) {

            this._displays.filter((display) => {
                if (display.departments_displays.find((dd) => dd.favorite && dd.visible) && display.getRoute()) {
                    if (display.departments_displays.length > 1) {
                        tmpDisplays.push(display);
                    } else {
                        tmpDisplays.push(display);
                    }
                }
            });

        } else if (this._displays) {
            this._displays.filter(display => {
                if (display.departments_displays && display.departments_displays.find(departmentsDisplay => departmentsDisplay.visible)
                    && ![DisplayTypes.ProjectDetails].includes(display.display_type_id)) {
                    tmpDisplays.push(display);
                }
            });
        }

        return tmpDisplays;
    }


    private generateDisplayLinks() {
        let displayLinks: DisplayLink[] = [];
        this.displays.forEach((display: Display) => {
            display.departments_displays
                .filter(dd => dd.visible && dd.department != null)
                .forEach((departmentDisplay: DepartmentsDisplay) => {
                    if (this.department == null || departmentDisplay.department.id == this.department.id || this.departmentSelection == true) {
                        const route: any[] = display.getRoute(departmentDisplay, null); // https://podio.com/klartboard/softwareudvikling/apps/stories/items/135
                        if (route && this.displayUser && display.display_type_id == DisplayTypes.UserOverview) {
                            route.push(this.displayUser.id);  // https://podio.com/klartboard/softwareudvikling/apps/stories/items/351
                        }
                        if (route) {
                            if (this.favorites && departmentDisplay.favorite || this.favorites == false) {
                                // Apply limit

                                displayLinks.push(
                                    {
                                        name: display.name,
                                        display: display,
                                        route: route,
                                        department: departmentDisplay.department,
                                        icon: Helpers.getIcon(display),
                                        favorite: departmentDisplay.favorite,
                                        index_: (display.index_+1)
                                    }
                                );
                            }
                        }
                    }
                });
        });
        this.displayLinks = displayLinks.sort(Helpers.sortByName);
        // https://podio.com/klartboard/softwareudvikling/apps/stories/items/510
        // Vis ikke skærme fra andre end mine afdelinger
        if (this.limitToDepartment) {
            this.displayLinks = this.displayLinks.filter((value, index) => {
                if (this.displayUser && this.displayUser.departments) {
                    return (this.displayUser.departments.findIndex(ud => ud.id === value.department.id) !== -1);
                } else {
                    return false;
                }
            }).sort(Helpers.sortByName);
        }

        // Note: Alert no departments
        if (this.displayUser && !this.displayUser.departments && this.limitToDepartment) {
            this.snackbar.add(this.translateService.instant('_ui_user_missing_department'), null, {
                duration: 5000,
                panelClass: 'alert-info'
            });
        }

        if(this.limit)
            this.displayLinks = this.displayLinks.slice(0, this.limit)

        this.updateActiveLinks();

        this.markChangeDetectionDirty();
    }

    triggerClick(displayLink: DisplayLink) {
        this.itemSelected.emit(displayLink);
        if(!this.navigateDashboard && displayLink.display.display_type_id === DisplayTypes.UserOverview){

        }else {
            if(this.navigate) {
                const activatedRoute: ActivatedRoute = AppInjector.getInjector().get(ActivatedRoute);

                var snapshot = activatedRoute.snapshot;
                const params = { ...snapshot.queryParams };
                delete params.displayFilterId;
                this.router.navigate(displayLink.route, { queryParams: params });

                // this.router.navigate(displayLink.route, {queryParamsHandling: "merge"});
            }
        }
    }

    private updateActiveLinks() {
        this.displayLinks.forEach(dl => {
            const url = dl.route.join('/')
            dl.active = window.location.toString().includes(url);
        });
        this.markChangeDetectionDirty();
        //         displayLink.active = true;
    }
}
