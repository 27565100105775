import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {
    EditMilestonePlanSelect
} from "@app/editor/quick-editor/editors/project/milestone-plan-select-editor/EditMilestonePlanSelect";
import {
    EditMilestonePlanSelectConfiguration
} from "@app/editor/quick-editor/editors/project/milestone-plan-select-editor/EditMilestonePlanSelectConfiguration";
import {MilestonePlan, Project} from "@app/core/models";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";

@Component({
    selector: 'app-milestone-plan-select-editor',
    templateUrl: './milestone-plan-select-editor.component.html',
    styleUrls: ['./milestone-plan-select-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MilestonePlanSelectEditorComponent extends BaseEditor<EditMilestonePlanSelect> {
    protected eventFieldName = 'milestone-plan';

    @Input() model: EditMilestonePlanSelect&Project&HasEventGenerator&BaseModel;
    @Input() configuration: EditMilestonePlanSelectConfiguration;
    @Input() editorEvents: EditorEvents;

    protected setup() {
    }

    protected render() {
        this.detectChanges();
    }

    public save(value: MilestonePlan) {
        this.configuration.onMilestonePlanSelectChangeEvent.emit(value);
        this.model.setMilestonePlan(value, () => {}, () => {
            this.onItemUpdated();
        });
    }

}
