import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {FollowingMilestoneListCell} from "@app/pages/displays/display-projects/Cells/FollowingMilestoneListCell";
import {
    FollowingMilestoneTableColumn
} from "@app/pages/displays/display-projects/TableColumns/FollowingMilestoneTableColumn";
import {MilestoneFetcher, MilestoneFetchRequest} from "@app/shared/_ui/lists/milestone-list/MilestoneFetcher";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters, Filters as ProjectsFilters} from "@app/pages/displays/display-projects/Filters";
import {Projects_FollowingMilestoneListDefaultSortSettingValue} from "@app/core/Api";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {CSVColumn} from "@app/export/csv/CSVColumn";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";

export class FollowingMilestoneListColumn extends GenericColumn<FollowingMilestoneListCell>
    implements ColumnDataFetcherInterface,
        SortableColumnInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: MilestoneFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    // <editor-fold desc="Sorting">

    public implementsSorting: true = true;

    public applyRowSort(row: ProjectsDisplayRow, tableColumn: BaseTableColumn) {
        row.getCell<FollowingMilestoneListCell>(this)
            .listConfiguration
            .setOrderBy(
                this.getOrderBy(tableColumn.activeSortTypeId, tableColumn.activeSortDirection)
            );
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new MilestoneFetcher(column.name);
    }

    public createTableColumns(): FollowingMilestoneTableColumn[] {
        const item = new FollowingMilestoneTableColumn(this);
        this.setTableColumnDefaults(item);

        item.sortItems = [
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortUpcomingMilestoneTitle),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortUpcomingMilestoneDeadline),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortUpcomingMilestoneResponsible),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortUpcomingMilestoneStatus),
        ];

        item.activeSortTypeId = this.getStandardSortType();
        item.activeSortDirection = this.getStandardSortDirection();

        return [item];
    }

    public createCell(row: ProjectsDisplayRow): FollowingMilestoneListCell {
        const cell = new FollowingMilestoneListCell(row, this);

        cell.listConfiguration
            .setOrderBy(this.getOrderBy(this.getStandardSortType(), this.getStandardSortDirection()));

        this.dataFetcher.addRequest(new MilestoneFetchRequest(cell.listConfiguration));
        return cell;
    }

    private getOrderBy(type: string, direction: string): any[] {
        let orderBy: any[] = [];
        switch (type) {
            case Filters.SortUpcomingMilestoneTitle:
            case 'title':
                orderBy = [['name', direction]];
                break;
            case Filters.SortUpcomingMilestoneDeadline:
            case 'deadline':
                orderBy = [['deadline.id', 'null'], ['deadline.date', direction], ['milestones_project.index_', direction]];
                break;
            case Filters.SortUpcomingMilestoneResponsible:
            case 'responsible':
                orderBy = [['responsible.first_name', direction]];
                break;
            case Filters.SortUpcomingMilestoneStatus:
            case 'status':
                orderBy = [['main_status.status_id', direction == 'asc' ? 'desc' : 'asc']];
                break;
        }
        return orderBy;
    }

    private getStandardSortType(): string {
        return this.settings.get(ColumnTypeSettings.FollowingMilestoneListDefaultSort)
                ?.getObject<Projects_FollowingMilestoneListDefaultSortSettingValue>()
                ?.type
            ?? ProjectsFilters.SortUpcomingMilestoneDeadline;
    }

    private getStandardSortDirection(): string {
        return this.settings.get(ColumnTypeSettings.FollowingMilestoneListDefaultSort)
                ?.getObject<Projects_FollowingMilestoneListDefaultSortSettingValue>()
                ?.direction
            ?? 'desc';
    }

    public implementsCSVExport = true;

    public toCSVColumn(result: (column: CSVColumn) => void): void {
        result(CSVColumn.CreatePrimary(this, [
            CSVColumn.CreateSecondary('name', this.column.name),
            CSVColumn.CreateSecondary(
                'deadline',
                `${(AppInjector.getInjector().get(TranslateService).instant('Frist'))} ${this.column.name.toLocaleLowerCase()}`
            )
        ]));
    }

}
