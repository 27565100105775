import {ErrorHandler, Inject, Injectable, Injector, NgZone} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {UsersService} from '@app/services/users.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private zone: NgZone;
    private dialogService: BaseDialogService;

    constructor(@Inject(Injector)
                private readonly injector: Injector,
                ) {

        // https://pkief.medium.com/global-error-handling-in-angular-ea395ce174b1
        this.zone = this.injector.get(NgZone);
        // this.dialogService = this.injector.get(BaseDialogService);
    }

    handleError(error: any) {
        // console.warn('handleError() : ', error);
        console.error(error);
        if (
            error.toString().includes('ExpressionChangedAfterItHasBeenCheckedError') ||
            error.toString().includes('Token has expired')
        ) {
            return;
        }
        let errorMessage: string;
        // Check if it's an error from an HTTP response

        if (error instanceof HttpErrorResponse) {
            // const httpErrorResponse = new HttpErrorResponse(error);
            // error = new Error();
            if (error.name) {
                errorMessage = error.status + ': ' + error.name;
            }

            if (error.error) {
                errorMessage = error.error.toString();
            }

            if (!errorMessage && error.message) {
                errorMessage = error.message.toString();
            }
            // error.message = httpErrorResponse.url;
        } else {
            errorMessage = error.toString();
        }
        if (!(error instanceof HttpErrorResponse) && error.rejection) {
            // error = error.rejection; // get the error object
        }

        if (error && !error.toString().includes('ASSERTION ERROR')) {
            // this.injector.get(SystemService).addError(error)
            const user = this.injector.get(UsersService).user;
            // console.warn('handleError() : user : ', user);
            if (user && user.uber) {
                this.zone.run(() =>
                    // Fixer circular dependency ved at kalde this injector i steder for at gøre brug af constructor injection!
                    this.injector.get(BaseDialogService).errorDialog(errorMessage, error?.message ? error.message : error)
                );
            }
        }

        // console.error('Error from global error handler', error);
    }
}
