import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {AnyItem} from "@app/interfaces/CustomTypes";
import {ProjectType} from '@app/core/models';

export class ProjectTypeFilter extends BaseOnScreenFilter<AnyItem> {

    // Præsenteret som "Projekttype med omvendt ikon"
    public name = '_displays_team_filter_project_type';
    public iconActive = 'fa-circle-o';
    public iconInactive = 'fa-check-circle';
    public invertIcon: boolean = false;
    public projectType: ProjectType;

    public constructor(enabled = true, projectType: ProjectType) {
        super(enabled);
        this.projectType = projectType;

        this.name = this.projectType.name;
    }

    protected validate(item: AnyItem): boolean {
        if ((item as any)['project_type_id']) {
            return (item as any).project_type_id != this.projectType.id;
        } else {
            return true;
        }
    }

}
