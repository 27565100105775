import {GenericColumn} from "@app/pages/displays/display-team/Columns/GenericColumn";
import {DaysTableColumn, WeekDay} from "@app/pages/displays/display-team/TableColumns/DaysTableColumn";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {ColumnTypeSettings} from "@app/pages/displays/display-team/Helpers/ColumnTypeSettings";
import {
    Team_DaysTaskTypesSettingValue, Team_DaysVisibleDaysSettingValue
} from "@app/core/Api";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {GenericCell} from "@app/pages/displays/display-team/Cells/GenericCell";
import {DaysCell} from "@app/pages/displays/display-team/Cells/DaysCell";
import moment from "moment";
import {DisplayTeamRow} from "@app/pages/displays/display-team/DisplayTeamRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-team/Helpers/Filters";

export class DaysColumn extends GenericColumn<DaysCell> implements ColumnDataFetcherInterface, SortableColumnInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    // <editor-fold desc="Sorting">

    public implementsSorting: true = true;

    public applyRowSort(row: DisplayTeamRow, tableColumn: BaseTableColumn) {
        row.getCell<DaysCell>(this)
            .getAllTaskListConfigurations()
            .forEach(taskListConfiguration => {
                taskListConfiguration
                    .setOrderBy(
                        this.getOrderBy(tableColumn.activeSortTypeId, tableColumn.activeSortDirection)
                    );
            });
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TaskFetcher(
            undefined, undefined, undefined,
            column.name
        );
        this.dataFetcher.setTaskTypeIds(this.getTaskTypeIds());
    }

    public createTableColumns(): DaysTableColumn[] {
        const daysSetting = this.settings.get(ColumnTypeSettings.Days_VisibleDays).getObject<Team_DaysVisibleDaysSettingValue>();

        return daysSetting.days
            ?.map(day => {
                const item = new DaysTableColumn(this, day);
                this.setTableColumnDefaults(item);

                item.sortItems = [
                    PageColumnSort.CreateWithSortId(Filters.TaskColumn_Sort_Title),
                    PageColumnSort.CreateWithSortId(Filters.TaskColumn_Sort_Status),
                    PageColumnSort.CreateWithSortId(Filters.TaskColumn_Sort_Deadline),
                    PageColumnSort.CreateWithSortId(Filters.TaskColumn_Sort_PlanningDate),
                ];

                return item;
            }) ?? [];
    }

    public createCell(row: BaseRow): GenericCell {
        const cell = new DaysCell(row, this);

        cell.getAllTaskListConfigurations().forEach(taskListConfiguration => {
            taskListConfiguration
                .setSoftDeadline(false)
                .setTaskTypeIds(this.getTaskTypeIds())
                .addOnScreenFilters(this.columnType.onScreenFilters);

            this.dataFetcher.addRequest(new TaskFetchRequest(taskListConfiguration));
        });

        return cell;
    }

    public getTaskTypeIds(): number[] {
        return this.settings.get(ColumnTypeSettings.Days_TaskTypes)
            ?.getObject<Team_DaysTaskTypesSettingValue>()
            ?.taskTypeIds ?? [];
    }

    public setDate(week: Date) {
        this.dataFetcher.setPeriod(
            moment(week).startOf('isoWeek').toDate(),
            moment(week).endOf('isoWeek').toDate()
        );
        this.getTableColumns<DaysTableColumn>().forEach(tableColumn => tableColumn.setDate(week));
    }

    public isLastVisibleDay(weekDay: WeekDay): boolean {
        const daysSetting = this.settings.get(ColumnTypeSettings.Days_VisibleDays).getObject<Team_DaysVisibleDaysSettingValue>();
        return daysSetting.days.indexOf(weekDay) == daysSetting.days.length - 1;
    }

    public getOrderBy(type: string, direction: string): any[] {
        let orderBy: any[][] = [];
        switch (type) {
            case Filters.TaskColumn_Sort_Title:
                orderBy.push(['title', direction]);
                break;
            case Filters.TaskColumn_Sort_Status:
                orderBy.push(['main_status.status_id', direction]);
                break;
            case Filters.TaskColumn_Sort_Deadline:
                orderBy.push(['tasks_deadline.deadline.date', 'null'], ['tasks_deadline.deadline.date', direction]);
                break;
            case Filters.TaskColumn_Sort_PlanningDate:
                orderBy.push(['tasks_user.deadline.date', direction]);
                break;
        }
        return orderBy;
    }

}
