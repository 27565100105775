import {GenericTableColumn} from "@app/pages/displays/display-team/TableColumns/GenericTableColumn";
import moment from "moment";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {DatePipe} from "@angular/common";
import {DaysColumn} from "@app/pages/displays/display-team/Columns/DaysColumn";

export const WeekDays = {
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday',
    Sunday: 'sunday',
}

export type WeekDay = typeof WeekDays.Monday
    | typeof WeekDays.Tuesday
    | typeof WeekDays.Wednesday
    | typeof WeekDays.Thursday
    | typeof WeekDays.Friday
    | typeof WeekDays.Saturday
    | typeof WeekDays.Sunday;

export class DaysTableColumn extends GenericTableColumn {

    public column: DaysColumn;

    public weekDay: WeekDay;

    public weekDate?: Date;

    constructor(column: BaseColumn, weekDay: WeekDay) {
        super(column);
        this.identifier = weekDay;
        this.weekDay = weekDay;
    }

    public setDate(week: Date) {
        this.weekDate = this.getDate(week);

        const name = new DatePipe('da-DK').transform(this.weekDate, 'EEEE');
        this.name = name[0].toUpperCase() + name.substring(1);
    }

    public getDate(week: Date) {
        switch (this.weekDay) {
            default:
            case WeekDays.Monday:
                return moment(week).startOf('week').add('1', 'days').toDate();
            case WeekDays.Tuesday:
                return moment(week).startOf('week').add('2', 'days').toDate();
            case WeekDays.Wednesday:
                return moment(week).startOf('week').add('3', 'days').toDate();
            case WeekDays.Thursday:
                return moment(week).startOf('week').add('4', 'days').toDate();
            case WeekDays.Friday:
                return moment(week).startOf('week').add('5', 'days').toDate();
            case WeekDays.Saturday:
                return moment(week).startOf('week').add('6', 'days').toDate();
            case WeekDays.Sunday:
                return moment(week).startOf('week').add('7', 'days').toDate();
        }
    }

}
