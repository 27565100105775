import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    Kanban_Phase_FilterPhaseSettingValue,
} from "@app/core/Api";
import {ColumnTypeSettings} from "@app/pages/displays/display-kanban/ColumnTypeSettings";
import {PhaseTableColumn} from "@app/pages/displays/display-kanban/TableColumns/PhaseTableColumn";
import {Color, Phase} from "@app/core/models";
import {GenericColumn} from "@app/pages/displays/display-kanban/Columns/GenericColumn";

export class PhaseColumn extends GenericColumn {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new PhaseTableColumn(this);
        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        throw new Error("Method not implemented.");
    }

    public setPhase(value: Phase) {
        this.phase = value;
        this.color = value.color;
    }

    public getFilterPhase(): number {
        return this.settings.get(ColumnTypeSettings.Phase_FilterPhase)
            ?.getObject<Kanban_Phase_FilterPhaseSettingValue>()
            ?.phaseId ?? 0;
    }

}
