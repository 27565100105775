import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Project} from "@app/core/models";
import {ProjectsService} from "@app/services/projects.service";
import EnabledEditorsHelper from "@app/editor/quick-editor/EnabledEditorsHelper";
import {BaseEditorConfiguration} from "@app/editor/quick-editor/editors/BaseEditorConfiguration";
import AvailableEditors from "@app/editor/quick-editor/AvailableEditors";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {EditorInterface} from "@app/editor/quick-editor/EditorInterface";

@Component({
    selector: 'app-project-edit-collection',
    templateUrl: './project-edit-collection.component.html',
    styleUrls: ['./project-edit-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectEditCollectionComponent extends BaseComponent {

    // Bindings to parent
    @Input() item: Project;
    @Input() availableEditors: string[];
    @Input() editorEvents: EditorEvents<Project>;

    // Bindings to view
    public enabledEditors: EditorInterface[];

    constructor(private cd: ChangeDetectorRef,
                private projectsService: ProjectsService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.projectsService.getProjectType(this.item.project_type_id, projectType => {
            // Find enabled editors for this project type
            const enabledEditors = EnabledEditorsHelper.ForProjectType(projectType);

            // Intersect available and enabled editors
            this.enabledEditors = enabledEditors.filter(enabledEditor => {
                return this.availableEditors.includes(enabledEditor.name);
            });

            // Order by default order (AvailableEditors::All)
            const indexMap = new Map<string, number>();
            AvailableEditors.All().forEach((name: string, index: number) => indexMap.set(name, index));
            this.enabledEditors.sort((a, b) => indexMap.get(a.name) - indexMap.get(b.name));

            this.detectChanges();
        });
    }

}
