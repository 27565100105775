import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BaseEditorComponent} from '@app/editor/base-editor/base-editor.component';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {DisplayFilterEditorForm} from '@app/editor/display-filter-editor/DisplayFilterEditorForm';
import {ProjectDeadlineType} from '@app/core/models/ProjectDeadlineType';
import {AppInjector} from '@app/services/app-injector.service';
import {UsersService} from '@app/services/users.service';
import {CategoryType, Department, Reaction, ReactionFilter, Site, TaskDeadlineType} from "@app/core/models";
import Globals, {DisplayFilterTypes, ReactionTypes, Tabs} from "@app/constants";
import {ReactionsApiInterface} from "@app/shared/_ui/reactions/ReactionsApiInterface";
import {ReactionsTypeInterface} from "@app/shared/_ui/reactions/ReactionsTypeInterface";
import {ShowDisplayFilterInterface} from "@app/services/editor-panel.service";
import {UntypedFormArray, UntypedFormGroup} from "@angular/forms";

export const FilterSaveTypes = {
    Personal: 'personal',
    Team: 'Team',
};

@Component({
    selector: 'app-display-filter-editor',
    templateUrl: './display-filter-editor.component.html',
    styleUrls: ['../base-editor/base-editor.component.scss'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DisplayFilterEditorComponent extends BaseEditorComponent implements OnInit, ReactionsApiInterface {

    // Bindings to parent
    @Input() showDisplayFilterInterface: ShowDisplayFilterInterface;

    // Bindings to view
    public isReady = false;
    public editorForm: DisplayFilterEditorForm;
    public standards: DisplayFilter[];
    public projectDeadlineTypes: ProjectDeadlineType[] = [];
    public taskDeadlineTypes: TaskDeadlineType[] = [];
    public showCategoryTypes = false;
    public showReactionTypes = false;
    public showTabs = true;
    public emojiReactionFilters: ReactionFilter[] = [];
    public reactionsTypeInterfaces: ReactionsTypeInterface[] = [
        {reaction_type_id: ReactionTypes.Emoji, visible: true}
    ];
    public editorComponent: this;
    public departmentId?: number;
    public allDepartmentsChecked: boolean = false;
    public autoGenerateName: boolean = false;
    public autoGeneratedName = '';

    public filterSaveType = FilterSaveTypes.Personal;
    public filterSaveTypes = FilterSaveTypes;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
        this.editorComponent = this;
        this.initialized = false;
        this.isLoading = true;
        this.isOpen = false;
        this.autoClose = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this.departmentId = this.shellService.getPageSettings()?.departmentId;

        Site.Get(site => {
            this.showCategoryTypes = site?.has_category_types ?? false;
            this.showReactionTypes = site?.has_reaction_types ?? false;
        });

        this.isOpen = true;
        this.initialized = true;
        this.standards = this.showDisplayFilterInterface.filterPageData.shellFilterGroup.settings
            .standards(this.showDisplayFilterInterface!.filterPageData.display);

        const settings = this.showDisplayFilterInterface.filterPageData.shellFilterGroup.settings;
        this.autoGenerateName = settings.autoGenerateNameDefaultValue ? settings.autoGenerateNameDefaultValue() : false;

        // Ensure CategoryTypes is fetched
        CategoryType.GetAll(_ => {
            this.render();
        });
    }

    private render() {
        this.editorForm = new DisplayFilterEditorForm(this.showDisplayFilterInterface.displayFilter);

        if (this.editorForm.exists()) {
            if (this.editorForm.isMine()) {
                this.filterSaveType = FilterSaveTypes.Personal;
            } else if ((!this.editorForm.isMine() || this.editorForm.user_id != 0
                && AppInjector.getInjector().get(UsersService).user.user_group_id <= Globals.UserGroups.Admin)) {
                this.filterSaveType = FilterSaveTypes.Team;
            }
        }

        this.selectedTab = Tabs.Contents;

        const projectDeadlineTypes: ProjectDeadlineType[] = [];
        if (this.showDisplayFilterInterface.config.projectTypes) {
            this.showDisplayFilterInterface.config.projectTypes.forEach(projectType => {
                if (projectType.project_deadline_types_project_types) {
                    projectType.project_deadline_types_project_types.forEach(projectDeadlineTypesProjectType => {
                        if (projectDeadlineTypes.find(item => item.id == projectDeadlineTypesProjectType.project_deadline_type_id) == null) {
                            projectDeadlineTypes.push(projectDeadlineTypesProjectType.project_deadline_type);
                        }
                    });
                }
            });
        }
        this.projectDeadlineTypes = projectDeadlineTypes;
        this.taskDeadlineTypes = this.showDisplayFilterInterface.config.taskDeadlineTypes;
        this.emojiReactionFilters = this.showDisplayFilterInterface.displayFilter.reaction_filters ? this.showDisplayFilterInterface.displayFilter.reaction_filters.filter(rf => rf.reaction_type_id == ReactionTypes.Emoji) : [];

        this.generatePlaceholderName();

        Department.GetAll(departments => {
            this.showDisplayFilterInterface.config.departments = departments;
            this.editForm = this.editorForm.toFormGroup(this.formBuilder, this.showDisplayFilterInterface);
            this.isLoading = false;
            this.isReady = true;
            this.detectChanges();
        });
    }

    // <editor-fold desc="View actions">

    set selectedStandard(displayFilter: DisplayFilter) {
        if (displayFilter instanceof DisplayFilter) {
            displayFilter.id = this.editorForm.id;
            displayFilter.user = this.editorForm.user;
            displayFilter.user_id = this.editorForm.user_id;
            displayFilter.display = this.editorForm.display;
            displayFilter.display_id = this.editorForm.display_id;

            const storedName = this._efc.name.value;

            // console.log('standardSelected : ', displayFilter, 'storedName : ',storedName)
            this.editorForm = new DisplayFilterEditorForm(displayFilter);
            const data = this.editorForm.toFormObject(this.showDisplayFilterInterface);
            if(!this.autoGenerateName){
                this.editorForm.name = data.name = storedName;
            }
            this.editForm.reset(data);

        }
    }

    get selectedStandard() {
        return null;
    }

    private createDisplayFilterFromFormObject(): DisplayFilterEditorForm {
        const result = DisplayFilterEditorForm.create(Object.assign({}, this.editForm.value));
        result.reaction_filters = [...this.emojiReactionFilters, ...this.editorForm.reaction_filters ? this.editorForm.reaction_filters.filter(rf => rf.reaction_type_id !== ReactionTypes.Emoji) : []];

        if(this.autoGenerateName)
            result.name = result.generateName(this.showDisplayFilterInterface.filterPageData.shellFilterGroup.settings);

        return result;
    }

    saveForm(close: boolean = true, departmentId: number = null, displayTypeId: number = null, departmentGroups = true) {
        if (this.isLoading) {
            return;
        }
        const result = this.createDisplayFilterFromFormObject();

        if (displayTypeId) {
            result.user_id = null;
            result.user = null;
            result.display_id = null;

            // Tjek om typen er global - hvis den er, så fjern projekt-typen
            // https://podio.com/klartboard/softwareudvikling/apps/stories/items/676
            result.project_types = null;

            // Tjek om typen er global - hvis den er, så fjern opgave-typen
            // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1558
            result.task_types = null;

        } else if (departmentId) { // Save for department
            result.user_id = null;
            result.user = null;
        } else {
            if (!result.user_id) {
                result.user_id = AppInjector.getInjector().get(UsersService).user.id;
            }
        }

        if (!departmentGroups) {
            result.departments_display_filters = [];
        }

        this.isLoading = true;
        this.showDisplayFilterInterface.filterPageData.setActiveDisplayFilter(result);
        this.saveConfirmed(result, close);
    }

    private saveConfirmed(item: DisplayFilterEditorForm, close: boolean) {
        // console.log('saveConfirmed : ', close)
        const callback = (newItem: DisplayFilter) => {
            this.isLoading = false;
            item.id = newItem.id;

            if (close) {
                this.close();
            } else {
                this.editForm.reset(this.editorForm.toFormObject(this.showDisplayFilterInterface));
            }

            this.snackbar.add(this.translateService.instant('_ui_item_saved', {item: this.editorForm.name}));
            this.detectChanges();
        };

        if (item.exists()) {
            item.patch(null, callback);
        } else {
            item.post(callback);
        }
    }

    delete() {
        if (this.isLoading) return;

        this.dialogService.confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_ui_delete_item', {name: this.editorForm.name}),
            this.translateService.instant('_global_delete'),
        )
            .then((confirmed) => {
                if (confirmed) {
                    this.isLoading = true;
                    this.editorForm.delete(() => {
                        this.isLoading = false;
                        this.close();
                    });
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    saveAndClose() {
        this.saveForm(true);
    }

    show() {
        const filter = this.createDisplayFilterFromFormObject();
        this.showDisplayFilterInterface.filterPageData.setActiveDisplayFilter(filter);
    }

    saveForType() {
        switch (this.filterSaveType) {
            case FilterSaveTypes.Personal:
                this.saveForm(true, null, null, false);
                break;
            case FilterSaveTypes.Team:
                this.saveForm(true, this.departmentId);
                break;
        }

    }

    onToggleType(type: string) {
        this.filterSaveType = type;
        this.detectChanges();
    }

    public toggleColumnGroup(group: UntypedFormGroup) {
        const checked = group.value.checked;
        (group.controls.columns as UntypedFormArray).controls.forEach(column => column.patchValue({checked: checked}));
        this.detectChanges();
    }

    public checkColumnGroupStatus(group: UntypedFormGroup) {
        group.patchValue({
            checked: (group.controls.columns as UntypedFormArray).controls
                .filter(column => !(column as UntypedFormGroup).controls.checked.value).length > 0
        });
        this.detectChanges();
    }

    toggleAllDepartments(departments: any) {
        this.allDepartmentsChecked = !this.allDepartmentsChecked;
        (departments as UntypedFormArray).controls?.forEach(department => department.patchValue({checked: this.allDepartmentsChecked}));
        this.detectChanges();
    }

    // </editor-fold>

    // <editor-fold desc="ReactionApiInterface"

    public addReaction(reaction: Reaction): void {
        this.emojiReactionFilters.push(new ReactionFilter({
                value: reaction.value,
                reaction_type_id: reaction.reaction_type_id,
                display_filter_id: this.editorForm.id != 0 ? this.editorForm.id : null
            })
        );
    }

    public removeReaction(reaction: Reaction): void {
        this.emojiReactionFilters.splice(this.emojiReactionFilters.findIndex(rf => rf.value == reaction.value), 1);
    }

    removeReactions() {
        this.emojiReactionFilters = [];
    }

    // </editor-fold>


    autoGenerateNameToggle(checked: boolean) {
        this.generatePlaceholderName();
        this.autoGenerateName = checked;
         if(this.autoGenerateName && !this._efc.name.value){
             this._efc.name.patchValue(this.autoGenerateName);
         }
        this.detectChanges();
    }

    public generatePlaceholderName(){
        if(this.editForm?.value) {
            const result = DisplayFilterEditorForm.create(Object.assign({}, this.editForm.value));
            this.autoGeneratedName = result.generateName(this.showDisplayFilterInterface.filterPageData.shellFilterGroup.settings);

            this.detectChanges();
        }
    }


    public standardSelected($event: Event) {
        console.log('standardSelected : ', $event, 'form name : ',this._efc.name.value);
    }
}
