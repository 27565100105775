import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Task} from '@app/core/models/Task';

@Component({
    selector: 'app-case-archived-dialog',
    templateUrl: './case-archived-dialog.component.html',
    styleUrls: ['./case-archived-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    standalone: false
})
export class CaseArchivedDialogComponent extends BaseModalComponent implements OnInit {

    @Input() task: Task;
    @Input() primaryClass: string = 'btn-success';

    public formGroup: UntypedFormGroup;

    constructor(private modal: NgbActiveModal,
                private formBuilder: UntypedFormBuilder) {
        super(modal);
    }

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            planned: this.task.cases_planned,
            reached: this.task.cases_reached
        });
    }


    public accept() {
        this.activeModal.close({
            planned: this.formGroup.get('planned').value,
            reached: this.formGroup.get('reached').value
        });
    }


}
