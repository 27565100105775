import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Milestone} from "@app/core/models";
import {EditorInterface} from "@app/editor/quick-editor/EditorInterface";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {StatusEditorComponent} from "@app/editor/quick-editor/editors/generic/status-editor/status-editor.component";
import {Field} from "@app/editor/milestone-editor-loader/milestone-editor.service";
import EnabledEditorsHelper from "@app/editor/quick-editor/EnabledEditorsHelper";
import {
    ArchivedEditorComponent
} from "@app/editor/quick-editor/editors/generic/archived-editor/archived-editor.component";
import {
    ReactionListEditorComponent
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/reaction-list-editor.component";
import {TranslateService} from "@ngx-translate/core";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {FocusableEntityTypes} from "@app/core/Focusable/FocusableEntityTypes";

@Component({
    selector: 'app-milestone-editor-header',
    templateUrl: './milestone-editor-header-component.html',
    styleUrls: ['./milestone-editor-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MilestoneEditorHeaderComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Milestone;
    @Input() fields: Map<number, Field>;
    @Input() validator!: ValidationErrorInterface;

    // Bindings to view
    public isReady = false;
    public cardItem: CardItem<Milestone>;
    public editors: EditorInterface[];
    public showValidationWarning = false;

    // Data

    constructor(
        private cd: ChangeDetectorRef,
        private translateService: TranslateService,
        private dialogService: BaseDialogService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.setup();
    }

    private setup() {
        this.cardItem = new CardItem<Milestone>(this.item);

        const availableEditors = [
            StatusEditorComponent.name,
            ArchivedEditorComponent.name,
            ReactionListEditorComponent.name,
        ];

        this.editors = EnabledEditorsHelper.Filter(
            availableEditors,
            EnabledEditorsHelper.ForMilestone(this.fields)
        );

        this.subscribe(this.validator.onIsValidChanged.subscribe(isValid => {
            this.renderValidation();
            this.detectChanges();
        }));
        this.renderValidation();

        this.isReady = true;
    }

    private renderValidation() {
        this.showValidationWarning = !this.validator.isValid();
    }

    // <editor-fold desc="View actions">

    public onCopyBtnClicked() {
        this.dialogService.copyMilestone(
            this.translateService.instant(`_ui_copy`) + ' ' + this.translateService.instant('_milestone').toLowerCase(),
            this.translateService.instant('_ui_copy_item', {item: this.item.title}),
            this.translateService.instant('_global_ok'),
            this.translateService.instant('_global_cancel'),
            'sm',
            this.item,
        )
            .then(confirmed => {
                // Logik afvikles i dialogen
            })
            .catch(() => {
                console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
            });
    }

    // </editor-fold>

    protected readonly FocusableEntityTypes = FocusableEntityTypes;
}
