import {Component, Input, OnInit} from '@angular/core';
import {BaseDisplayComponent} from '../../_system/base-display/base-display.component';

@Component({
    selector: 'app-item-counter',
    templateUrl: './item-counter.component.html',
    styleUrls: ['./item-counter.component.scss'],
    standalone: false
})
export class ItemCounterComponent extends BaseDisplayComponent implements OnInit {

  @Input() amount: number = 0;
  @Input() total: number = 0;

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
