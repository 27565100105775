<ng-container [ngSwitch]="name">
    <app-text-column
        class="align-self-center flex-shrink-1 d-flex w-100"
        *ngSwitchCase="TextColumnComponent.name"
        [configuration]="$any(configuration)"
    ></app-text-column>
    <app-template-column
        class="align-self-center flex-shrink-1 d-flex w-100"
        *ngSwitchCase="TemplateColumnComponent.name"
        [configuration]="$any(configuration)"
    ></app-template-column>
    <app-sortable-text-column
        class="align-self-center flex-shrink-1 d-flex w-100"
        *ngSwitchCase="SortableTextColumnComponent.name"
        [configuration]="$any(configuration)"
    ></app-sortable-text-column>
</ng-container>
