import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {AnyItem} from "@app/interfaces/CustomTypes";

export class OnlyStarredOrHandUpFilter extends BaseOnScreenFilter<AnyItem> {

    public name = '_displays_team_filter_only_starred_or_hand_ups_tasks';
    public iconActive = 'fa-check-circle';
    public iconInactive = 'fa-ban'; // fa-check-circle
    public invertIcon: boolean = true;

    protected validate(item: AnyItem): boolean {
        if (this.getEnabled() && item['num_stars'] || (item as any)['num_hand_ups']) {
            return (item as any).num_stars > 0 || (item as any).num_hand_ups > 0;
        } else {
            return this.getEnabled() ? false : true;
        }
    }

}
