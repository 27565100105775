import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProjectsService} from '@app/services/projects.service';
import {TasksService} from '@app/services/tasks.service';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {Project} from '@app/core/models/Project';
import moment from 'moment';
import {Task} from '@app/core/models/Task';
import {TaskDeadlineTypes, TaskUserTypes} from '@app/constants';
import {TasksDeadline} from '@app/core/models/TasksDeadline';
import {Deadline} from '@app/core/models/Deadline';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Milestone} from "@app/core/models/Milestone";
import {TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "@app/services/snackbar.service";
import Helpers from "@app/core/helpers";
import {EventService} from "@app/services/event.service";
import {PhasesProject} from "@app/core/models/PhasesProject";
import {DateChangeDifference} from "@app/interfaces/DateChangeDifference";
import {Api} from "@app/core/Api";

@Component({
    selector: 'app-move-project-deadline-dialog',
    templateUrl: './move-project-deadline-dialog.component.html',
    styleUrls: ['./move-project-deadline-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    standalone: false
})
export class MoveProjectDeadlineDialogComponent extends BaseModalComponent implements OnInit {

    @Input() item: Project;
    @Input() dateChangeDifference: DateChangeDifference;
    @Input() projectIds: number[] = [];

    private project: Project;
    public projects: Project[];
    public moveMilestones: boolean = false;
    public moveMilestoneTasks: boolean = false;
    public moveMilestoneTasksUsers: boolean = false;
    public isLoading: boolean = true;
    public milestones: Milestone[];

    public phasesProjects: PhasesProject[] = [];
    public disabledPhasesProjects: PhasesProject[] = [];
    public moveProjectPhases: boolean = false;
    private dataPhasesProjects: PhasesProject[];
    public allActive: boolean = false;


    constructor(private modal: NgbActiveModal,
                private projectsService: ProjectsService,
                private tasksService: TasksService,
                private snackbarservice: SnackbarService,
                private translationService: TranslateService,
                private eventsService: EventService
    ) {
        super(modal);

    }

    public toggleAll() {
        if (this.moveProjectPhases ||
            this.moveMilestones ||
            this.moveProjectPhases ||
            this.moveMilestoneTasksUsers ||
            this.moveMilestoneTasks
        ) {
            this.allActive =
                this.moveProjectPhases =
                    this.moveMilestones =
                        this.moveProjectPhases =
                            this.moveMilestoneTasksUsers =
                                this.moveMilestoneTasks = false;

        } else {
            this.allActive =
                this.moveProjectPhases =
                    this.moveMilestones =
                        this.moveProjectPhases =
                            this.moveMilestoneTasksUsers =
                                this.moveMilestoneTasks = true;
        }

    }

    private loadRelatedProjectDetails() {
        this.project = this.item;
        this.projectIds = [this.item.id];
        this.loadRelatedMilestones();
    }

    private loadRelatedMilestones() {

        /*
         1. find milepæle og deres opgaver (ikke model.id milepæl)
         2. læg dato til milepæl
         3. flyt opgaver x dage ift. milepæl

        */

        Api.milestones().get()
            .whereInArray('project.id', this.projectIds)
            .include('task')
            .include('project.phases_project?include=phase.color')
            // .include('task?include=tasks_user')
            .orderBy('deadline.date', 'ASC')
            .find((milestones: Milestone[]) => {
                milestones.map((_milestone, index) => {
                    if (!_milestone.tasks) _milestone.tasks = [];
                    _milestone.tasks.sort((a, b) => {
                        const dateA = a.findTasksDeadlineByType(TaskDeadlineTypes.Normal) || new TasksDeadline();
                        const dateB = b.findTasksDeadlineByType(TaskDeadlineTypes.Normal) || new TasksDeadline();
                        const valA: number = dateA.deadline?.getDate() ? dateA.deadline.getDate().getTime() : 0;
                        const valB: number = dateB.deadline?.getDate() ? dateB.deadline.getDate().getTime() : 0;
                        return (valA - valB);
                    });
                    return _milestone;
                });

                this.milestones = milestones.filter((_milestone, index) => {
                    return _milestone.deadline || _milestone.id == this.item.id;
                });

                if (this.item.phases_projects) {
                    this.dataPhasesProjects = this.item.phases_projects;
                }

                if (this.dataPhasesProjects) {
                    this.disabledPhasesProjects = [];
                    this.dataPhasesProjects.forEach(pp => {
                        const phasesProject = new PhasesProject(pp);
                        if (phasesProject.getStartedDate())
                            this.phasesProjects.push(phasesProject);

                        this.disabledPhasesProjects.push(phasesProject);
                    });
                }

                this.phasesProjects = this.phasesProjects.sort((a, b) => {
                    return a.getStartedDate()?.getTime() - b.getStartedDate()?.getTime();
                });

                this.disabledPhasesProjects = this.disabledPhasesProjects.sort((a, b) => {
                    return a.getStartedDate()?.getTime() - b.getStartedDate()?.getTime();
                });

                this.moveProjectPhases = this.moveProjectPhases && this.phasesProjects.length > 0;

                this.isLoading = false;
            });
    }

    public addDifference(date: Date): Date {
        if (date) {
            return moment(date).add(this.dateChangeDifference.difference, 'days').toDate();
        } else {
            return null;
        }
    }

    ngOnInit() {
        this.loadRelatedProjectDetails();
    }


    private movedTasks: Task[] = [];
    private movedMilestones: Milestone[] = [];

    public accept() {
        this.movedTasksCount = 0;
        this.movedMilestonesCount = 0;
        this.activeModal.close(true);

        if (this.moveProjectPhases) {
            this.phasesProjects.forEach(displayPhasesProjects => {
                this.item.phases_projects.find(projectPhasesProject => {
                    if (projectPhasesProject.id == displayPhasesProjects.id) {
                        this.item.updatePhasesProjectDate(
                            projectPhasesProject,
                            new Date(Helpers.updateWithDifference(this.dateChangeDifference.difference, displayPhasesProjects.start))
                        );
                    }
                });
            });
        }

        this.movedTasks = [];
        this.movedMilestones = this.milestones.filter(milestone => {

            if (this.moveMilestones && !milestone.archived) {
                if (this.moveMilestoneTasks) {
                    this.movedTasks = this.movedTasks.concat(milestone.tasks).filter(t => !t.archived);
                }

                const oldDate: Date = milestone.deadline ? milestone.deadline.getDate() : null;
                if (oldDate) {
                    const deadline = new Deadline();
                    deadline.id = 0;
                    deadline.date = Helpers.updateWithDifference(this.dateChangeDifference.difference, oldDate);
                    milestone.setDeadline(0, deadline);
                    this.movedMilestonesCount++;
                    this.taskAndMilestoneMoverCallback();
                    this.eventsService.emitMilestone(milestone, EventService.Updated, ['deadline']);
                }

                return milestone;
            }

        });

        this.movedTasks
            // Only consider tasks with deadline set
            .filter(task => task.findDeadlineByTypeId(TaskDeadlineTypes.Normal) !== undefined)
            .forEach(task => {

                task.setDeadline(
                    TaskDeadlineTypes.Normal,
                    Deadline.Create(
                        new Date(Helpers.updateWithDifference(
                            this.dateChangeDifference.difference, task.findDeadlineByTypeId(TaskDeadlineTypes.Normal).getDate()
                        ))
                    )
                );

                if (this.moveMilestoneTasksUsers) {
                    task.findEnhancedUsersByTypeId(TaskUserTypes.Participant)
                        // Only consider users with deadline set
                        .filter(enhancedUser => enhancedUser.deadline !== undefined)
                        .forEach(enhancedUser => {
                            task.setUserDeadline(
                                enhancedUser.user,
                                new Date(Helpers.updateWithDifference(this.dateChangeDifference.difference, enhancedUser.deadline.getDate()))
                            );
                        });
                }
            });
    }

    private movedTasksCount = 0;
    private movedMilestonesCount = 0;

    taskAndMilestoneMoverCallback() {
        if (this.movedMilestonesCount == this.movedMilestones.length && this.movedMilestones.length > 0) {
            this.snackbarservice.add(this.translationService.instant('_ui_x_milestones_was_updated', {count: this.movedMilestonesCount}));
            this.movedMilestonesCount = 0;
        }
        if (this.movedTasksCount == this.movedTasks.length && this.movedTasks.length > 0) {
            // this.translateService.instant('_ui_dialog_move_completion_date_description', {days: tasksDeadlineChange.event.difference}),
            this.snackbarservice.add(this.translationService.instant('_ui_x_tasks_was_updated', {count: this.movedTasksCount}));
            this.movedTasksCount = 0;
        }
    }

}
