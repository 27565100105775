import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {Milestone, Project, Task} from '@app/core/models';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DateChangeDifference} from "@app/interfaces/DateChangeDifference";
import {TaskRow} from "@app/shared/_modals/move-milestone-tasks-project-dialog/TaskRow";

@Component({
    selector: 'app-move-milestone-tasks-project-dialog',
    templateUrl: './move-milestone-tasks-project-dialog.component.html',
    styleUrls: ['./move-milestone-tasks-project-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MoveMilestoneTasksProjectDialogComponent extends BaseModalComponent implements OnInit {

    // Bindings to parent
    @Input() dateChangeDifference: DateChangeDifference;
    @Input() tasks: Task[];
    @Input() removedProject: Project;
    @Input() addedProject: Project;
    @Input() availableProjects: Project[];
    @Input() milestone: Milestone;
    @Input() action: string;

    // Bindings to view
    public targetProject: Project;
    public taskRows: TaskRow[];
    public moveAllTasks = false;
    public isLoading = true;
    public moveDisabled = false;

    constructor(private modal: NgbActiveModal,
                private _cdr: ChangeDetectorRef
    ) {
        super(modal);
        this.cdr = _cdr;
    }

    ngOnInit() {
        this.targetProject = this.removedProject ?? this.addedProject;

        const milestoneProjectIds = this.milestone.projects?.map(project => project.id) ?? [];
        const availableProjectIds = this.availableProjects?.map(project => project.id) ?? [];

        this.taskRows = this.tasks.map(task => {

            const removedProject = task.projects?.find(project => this.removedProject?.id == project.id);
            const addedProject = task.projects?.find(project => this.addedProject?.id == project.id);
            const availableProject = task.projects?.find(project => availableProjectIds.includes(project.id));

            const nextProject = addedProject ?? availableProject;

            const row = new TaskRow(
                task,
                nextProject !== undefined
            );

            row.prevProject = removedProject ?? task.projects?.find(project => milestoneProjectIds.includes(project.id));
            row.nextProject = nextProject;

            return row;
        });

        this.validateTasks();
    }

    public accept() {
        this.taskRows
            .filter(row => row.isSelectedForMove && row.hasNextProject)
            .forEach(row => {
                if (row.prevProject) {
                    row.task.removeProject(row.prevProject);
                }
                row.task.setProject(row.nextProject.project_type_id, row.nextProject);
        });

        this.activeModal.close({
            moveTasks: this.moveAllTasks,
            accepted: true
        });
    }

    private validateTasks() {
        this.moveAllTasks = !this.taskRows.some(row => !row.isSelectedForMove);
        this.moveDisabled = !this.taskRows.some(row => row.isSelectedForMove);
        this.detectChanges();
    }

    // <editor-fold desc="View actions">

    public toggleAll() {
        this.taskRows
            .filter(row => row.hasNextProject)
            .forEach(row => row.isSelectedForMove = !this.moveAllTasks);
        this.validateTasks();
    }

    public toggleTask(row: TaskRow) {
        row.isSelectedForMove = !row.isSelectedForMove;
        this.validateTasks();
    }

    public selectProject(row: TaskRow, project: Project) {
        row.nextProject = project;
        this.validateTasks();
    }

    public dismiss() {
        this.activeModal.close(false);
        this.activeModal.dismiss();
    }

    // </editor-fold>

}
