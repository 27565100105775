import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {EventEmitter} from '@angular/core';
import {Milestone, Project, Task} from "@app/core/models";
import {CardMilestoneConfiguration} from "@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration";
import {AppInjector} from "@app/services/app-injector.service";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "@app/services/snackbar.service";
import {RowEventsInterface} from "@app/pages/displays/display-project-details/subdisplay-milestones/RowEventsInterface";
import {Api} from "@app/core/Api";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";

export class Row extends BaseRow {

    project: Project;

    milestoneCard: CardItem<Milestone>;
    eventsInterface: RowEventsInterface;

    // Data
    taskEditListItems: CardItem<Task>[];

    // Events
    minimizeEvent: EventEmitter<any> = new EventEmitter();
    reloadYearWheelEvent = new EventEmitter();
    rerenderYearWheelEvent = new EventEmitter();

    constructor(project: Project, milestone: Milestone, eventsInterface: RowEventsInterface) {
        super();
        this.project = project;
        this.eventsInterface = eventsInterface;
        this.milestoneCard = new CardItem<Milestone>(milestone, new CardMilestoneConfiguration(false, true));
    }

    public reload() {
        this.reloadYearWheelEvent.emit();
    }

    // <editor-fold desc="Milestone actions">

    public allowMilestoneMoveUp: boolean;
    public allowMilestoneMoveDown: boolean;

    public deleteMilestone() {
        const dialogService = AppInjector.getInjector().get(BaseDialogService);
        const translateService = AppInjector.getInjector().get(TranslateService);
        const snackbar = AppInjector.getInjector().get(SnackbarService);
        dialogService.deleteMilestone(
            translateService.instant('_global_delete'),
            translateService.instant('_ui_delete_item', {name: this.milestoneCard.item.title}),
            translateService.instant('_global_delete'),
            translateService.instant('_global_cancel'),
            'sm',
            this.milestoneCard.item
        )
            .then((confirmed) => {
                if (confirmed) {
                    this.milestoneCard.item.delete(confirmed.cascade, () => {
                        snackbar.add(translateService.instant('_ui_item_deleted', {item: this.milestoneCard.item.name}));
                    });
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    // Called from component-html
    public archiveMilestoneToggle() {
        this.milestoneCard.item.setArchived((this.milestoneCard.item.archived_id ?? 0) == 0);
    }

    // Called from component-html
    public moveMilestoneUp() {
        this.eventsInterface.onRowMoveEvent(this, 'up');
    }

    // Called from component-html
    public moveMilestoneDown() {
        this.eventsInterface.onRowMoveEvent(this, 'down');
    }

    // </editor-fold>

    public saveTaskIndexes() {
        if (this.taskEditListItems?.length > 0) {
            Api.milestones().indexingPutById(this.milestoneCard.item.id).save({
                values: this.taskEditListItems.map(item => item.item.id),
            });
        }
    }

}
