import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnChanges,
    OnInit, Output,
    SimpleChanges
} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {YearWheelOptions, YearWheelService} from "@app/services/year-wheel.service";
import moment from "moment";
import {PeriodUnits} from "@app/constants";
import {YearWheelDisplayOptions} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration";

@Component({
    selector: 'app-year-wheel-column',
    templateUrl: './year-wheel-column.component.html',
    styleUrls: ['./year-wheel-column.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class YearWheelColumnComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() yearWheelPeriod: { start: Date, end: Date };
    @Input() yearWheelDisplayOptions: YearWheelOptions;
    @Output() onYearWheelPeriodChanged = new EventEmitter<{ start: Date, end: Date }>();
    @Output() onYearWheelUnitChanged = new EventEmitter<string>();
    @Output() onYearWheelDateChanged = new EventEmitter<{ start: Date, end: Date }>();
    @Output() onYearWheelOptionsChanged = new EventEmitter<YearWheelDisplayOptions>();

    // Bindings to view
    public yearWheelDaysRange = 0;
    public yearWheelMilestonesExpanded: boolean = false;
    public yearWheelTasksExpanded: boolean = false;

    // Data

    constructor(private cd: ChangeDetectorRef,
                private yearWheelService: YearWheelService,) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        this.detectChanges();
    }

    private refreshYearWheel() {
        if (this.yearWheelPeriod) {
            this.yearWheelDateChange(this.yearWheelPeriod);
        }
    }

    public yearWheelDateChange(period: { start: Date; end: Date }) {
        this.setYearWheelPeriod(period);
        this.updateYearWheelDateRange();
        this.onYearWheelDateChanged.emit(period);
    }

    private updateYearWheelDateRange() {
        this.yearWheelDaysRange = moment.duration(
            moment(this.yearWheelPeriod.start)
                .diff((moment(this.yearWheelPeriod.end)))
        ).abs().asDays();
        const refresh = this.yearWheelPeriod == undefined;

        if (this.yearWheelDaysRange > 250) {
            if (this.yearWheelDisplayOptions.unit != PeriodUnits.Months) {
                this.yearWheelUnitChange(PeriodUnits.Months);
            }
        } else if (this.yearWheelDaysRange > 70 && this.yearWheelDisplayOptions.unit == PeriodUnits.Days) {
            this.yearWheelUnitChange(PeriodUnits.Weeks);
        } else {
            this.yearWheelUnitChange(this.Constants.PeriodUnits.Weeks) // this.Constants.PeriodUnits.Weeks
        }

        if (refresh) {
            this.refreshYearWheel();
        }
    }

    public yearWheelUnitChange(unit: string) {
        this.yearWheelDisplayOptions.unit = unit;
        this.saveYearWheelOption();
        this.onYearWheelUnitChanged.emit(unit);
    }

    public yearWheelOptionsChange(emit = true) {
        this.saveYearWheelOption();
        this.onYearWheelOptionsChanged.emit(this.yearWheelDisplayOptions);
    }

    public setYearWheelPeriod(value: { start: Date; end: Date }) {
        if (!this.yearWheelPeriod && Math.abs(value.end.getTime() - value.start.getTime()) < 500) {
            value.start = moment().startOf('month').toDate();
            value.end = moment().endOf('month').toDate();
        }
        this.yearWheelPeriod = value;
        this.onYearWheelPeriodChanged.emit(value);
    }

    public toggleYearWheelMilestones() {
        this.yearWheelMilestonesExpanded = !this.yearWheelMilestonesExpanded;
        this.yearWheelService.expandAllMilestones(this.yearWheelMilestonesExpanded);
    }

    public toggleYearWheelTasks() {
        this.yearWheelTasksExpanded = !this.yearWheelTasksExpanded;
        this.yearWheelService.expandAllTasks(this.yearWheelTasksExpanded);
    }

    private saveYearWheelOption() {
        this.yearWheelService.saveOptions(this.yearWheelDisplayOptions);
    }

}
