import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    Renderer2,
    RendererFactory2,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {UsersService} from '@app/services/users.service';
import {AppInjector} from '@app/services/app-injector.service';
import {Subject} from 'rxjs';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {CdkDrag, CdkDragEnd, CdkDragMove, CdkDragStart} from '@angular/cdk/drag-drop';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {Tabs} from '@app/constants';
import {FormControlsService} from '@app/services/forms/form-controls.service';
import {NgbNav, NgbNavConfig} from '@ng-bootstrap/ng-bootstrap';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {Field} from '@app/editor/task-editor-loader/task-editor.service';
import {SnackbarService} from "@app/services/snackbar.service";
import {ResizeEvent} from "angular-resizable-element";
import {SettingsEditorForm} from "@app/editor/settings-editor/SettingsEditorForm";

@Component({
    selector: 'app-base-editor',
    templateUrl: './base-editor.component.html',
    styleUrls: ['./base-editor.component.scss'],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation
    ],
    providers: [NgbNavConfig], // add NgbNavConfig to the component providers
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
/**
 * @deprecated
 */
export class BaseEditorComponent extends BaseDisplayComponent implements OnInit {
    @ViewChild('container', {static: false}) container: ElementRef;
    @ViewChild(NgbNav, {static: true}) set content(content: ViewContainerRef) {
        this.tabSet = content;
    };

    @Input() options: any;
    @Input() onAfterSave?: (item: AnyItem) => void;

    @Output() editorClose = new EventEmitter<boolean>();

    protected usersService: UsersService;
    protected editorPanelService: EditorPanelService;
    protected dialogService: BaseDialogService;
    protected ngbNavConfig: NgbNavConfig;
    protected snackbar: SnackbarService;
    protected tabSet: ViewContainerRef;

    public selectedTab: string = Tabs.Basic;//'tab-debug';
    protected autoClose: boolean = false;
    protected autoPosition: boolean = true;
    public shiftEnabled: boolean = false;

    protected fields: Map<number, Field>;
    public showEmoji = false; // TO-DO Fjern afhængighed til emojis

    private closeWindow = new Subject<boolean>();
    public closeWindow$ = this.closeWindow.asObservable();
    public isOpen = true;
    public editable = false;

    public item: AnyItem | SettingsEditorForm;

    constructor() {
        super();
        const injector = AppInjector.getInjector();
        this.usersService = injector.get(UsersService);
        this.editorPanelService = injector.get(EditorPanelService);
        this.formBuilder = injector.get(UntypedFormBuilder);
        this.dialogService = injector.get(BaseDialogService);
        this.fcs = injector.get(FormControlsService);
        this.snackbar = injector.get(SnackbarService);
        this.ngbNavConfig = injector.get(NgbNavConfig);
        this.renderFactory = injector.get(RendererFactory2);
        this.renderer = this.renderFactory.createRenderer(null, null);

        this.editorPanelService.editor$.subscribe((editorEvent) => {
            this.evaluateTarget(editorEvent);
        });
    }

    ngOnInit() {
        super.ngOnInit();
    }

    protected evaluateTarget(editorEvent: any): void {

    }

    protected open(item: AnyItem, options?: any): void {
        // Paneler styres i shell.component.ts
        if (this.editorPanelService.editorPanels.length > 1) {
            this.freeDragPosition.x = -550;
        }
    }

    close(item?: AnyItem) {
        if (item) {
            this.shellService.removeFromURI(item);
        }
        if (this.isOpen) {
            this.editorPanelService.closeItem(this.item);
            this.isOpen = false;
        }
    }

    public get ready(): boolean {
        return this.editable && !this.isLoading && this.editForm !== null && this.fields != null;
    }

    // <editor-fold desc="View Helpers">

    public tabChange($event: any) {
        this.selectedTab = $event.nextId;
    }

    @HostListener('document:keyup', ['$event'])
    handleDeleteKeyboardEvent(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.close();
        }
    }

    // </editor-fold>

    // <editor-fold desc="Form Builder">

    public editForm: UntypedFormGroup;
    protected fcs: FormControlsService;

    get _efc() {
        return this.editForm.controls;
    }

    protected formBuilder: UntypedFormBuilder;

    // </editor-fold>

    // <editor-fold desc="Animation">

    private animationStateReady = false;

    animationStarted($event: any) {
        if ($event.toState === 'open') {
            this.animationStateReady = false;
            this.editable = true;
            this.editorClose.emit(this.editable);
        }
    }

    animationDone($event: any) {
        if ($event.toState === 'open') {
            this.animationStateReady = true;
        }
        if ($event.toState === 'closed') {
            this.animationStateReady = false;
            this.editable = false;
            this.editorClose.emit(this.editable);

            if (this.isDraggable) {
                this.isDraggable.reset();
            }

            this.closeWindow.next(true);
        }
    }

    // </editor-fold>

    // <editor-fold desc="DragDrop">

    private dragState: string = 'dragEnded';
    protected isDraggable: CdkDrag = null;

    dragStarted($event: CdkDragStart) {
        this.dragState = 'dragStarted';
        this.isDraggable = $event.source;
        this.renderer.setStyle(this.container.nativeElement, 'zIndex', this.maxZIndex());
    }

    dragEnded($event: CdkDragEnd) {
        this.dragState = 'dragEnded';
    }

    dragMoved($event: CdkDragMove) {

    }

    // </editor-fold>

    // <editor-fold desc="Window maximization / minimization">

    public docked: boolean = false;
    public freeDragPosition = {x: 0, y: 0};
    private windowWidth = 540;
    private windowHeight: number;
    public windowStyle: object = {
        width: `${this.windowWidth}px`
    };

    public minimized: boolean = false;
    public maximized: boolean = false;

    minimize() {
        this.maximized = false;
        this.minimized = !this.minimized;
    }

    maximize() {
        this.minimized = false;
        if (!this.maximized) {
            this.maximized = true;
            this.windowStyle = {
                height: `100vh`,
                width: `100vw`
            };
        } else {
            this.maximized = false;
            this.windowStyle = {
                width: `${this.windowWidth}px`
            };
        }
    }

    public onResizeEnd(event: any) {
        this.windowWidth = event.rectangle.width;
        this.windowHeight = event.rectangle.event.rectangle.height;
        this.windowStyle = {
            height: `${this.windowHeight}px`,
            width: `${this.windowWidth}px`
        };
    }

    public validateResize(event: ResizeEvent): boolean {
        return true;
    }

    // </editor-fold>

    // <editor-fold desc="Styling">

    protected renderer: Renderer2;
    private renderFactory: RendererFactory2;

    public maxZIndex(): number {
        return Array.from(document.querySelectorAll('body .container.editable'))
            .map(a => parseFloat(window.getComputedStyle(a).zIndex))
            .filter(a => !isNaN(a))
            .sort()
            .pop();
    }

    // </editor-fold>

}
