import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {CalculatedField} from '@app/core/models';

@Component({
    selector: 'app-calculated-field',
    templateUrl: './calculated-field.component.html',
    styleUrls: ['./calculated-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CalculatedFieldComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings
    @Input() calculatedField: CalculatedField;
    @Input() calculatedFieldType: string;
    @Input() value: number;

    // UI
    public valueText: string;
    public titleText: string;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.render();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        this.valueText = this.calculatedField?.standardizedValue(this.value, this.calculatedFieldType).toString();
        this.titleText = this.calculatedField?.name || '';
    }

}
