<div class="modal-inner-content window-size d-flex flex-column overflow-hidden"
     [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-description" >{{'_ui_add_department' | translate}}</h4>

        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="modal-body d-flex align-items-start">
        <div class="container p-0 flex-grow-1 flex-shrink-1">

            <p>{{'_ui_add_department_from_user' | translate :{user: user.first_name} }}</p>


            <div class="form-group p-2">
                <label>
                    <span >{{'_ui_add_department' | translate}}</span>
                </label>
                <app-department-picker
                    [selectedDepartments]="departments"

                    (onChangeEvent)="setDepartments($event)"
                    class="list-group-item rounded"
                    [showDepartmentGroups]="true"
                    [showAll]="true"
                ></app-department-picker>
            </div>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button" role="button" class="btn btn-secondary ellipsis" (click)="dismiss()"
                *ngIf="btnCancelText">{{ btnCancelText }}</button>
        <button type="button" role="button" class="btn btn-success ellipsis" ngbAutofocus
                (click)="accept()">{{ btnOkText }}</button>
    </div>
</div>
