import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {
    EditorWithColumnInterface
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/EditorWithColumnInterface";

@Component({
    selector: 'app-column',
    templateUrl: './column.component.html',
    styleUrls: ['./column.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ColumnComponent extends BaseComponent {

    // Bindings to parent
    @Input() editor: EditorWithColumnInterface;
    @Input() nextEditor?: EditorWithColumnInterface;
    @Input() remainingWidth: number;

    // Bindings to view
    public canResize: boolean;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.render();
    }

    private render() {
        this.canResize = this.editor.columnConfiguration.canResize && this.nextEditor !== undefined;
    }

    public onResize(width: number) {
        const current = this.editor.columnConfiguration;
        const next = this.nextEditor.columnConfiguration;

        const diff = width - current.width;
        if (diff > 0) { // Want to increase width
            this.increaseWidth(Math.min(diff, Math.max(this.remainingWidth, next.width - next.minWidth)));
        } else if (diff < 0) { // Want to decrease width
            this.decreaseWidth(Math.min(diff * -1, current.width - current.minWidth));
        }
    }

    private increaseWidth(value: number) {
        const diff = this.editor.columnConfiguration.setWidth(this.editor.columnConfiguration.width + value);
        this.nextEditor.columnConfiguration.setWidth(this.nextEditor.columnConfiguration.width - diff);
    }

    private decreaseWidth(value: number) {
        const diff = this.editor.columnConfiguration.setWidth(this.editor.columnConfiguration.width - value);
        this.nextEditor.columnConfiguration.setWidth(this.nextEditor.columnConfiguration.width - diff);
    }

    public onResizeEnd() {
        this.editor.columnConfiguration.onResizeEnd();
        this.nextEditor.columnConfiguration.onResizeEnd();
    }

}
