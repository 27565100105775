import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {fadeAnimation, fadeIn, fadeOut, listAnimation} from '@app/animations';
import {trigger} from '@angular/animations';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {ListConfiguration} from '@app/shared/_ui/lists/ListConfiguration';
import {Subscription} from 'rxjs';
import {DragAndDropService} from '@app/services/drag-and-drop.service';
import {ListDragInterface} from '@app/interfaces/ListDragInterface';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {
    CdkDrag,
    CdkDragDrop,
    CdkDragEnd,
    CdkDragEnter,
    CdkDragExit,
    CdkDragStart,
    CdkDropList
} from '@angular/cdk/drag-drop';
import {AutoScroll} from '@app/core/auto-scroll';
import {debounceTime, takeWhile} from 'rxjs/operators';
import {Appointment, Task} from '@app/core/models';
import {WidthHelper} from "@app/constants";
import {DragDropHelper} from "@app/core/helpers/DragDropHelper";
import {CaseUser, Roster} from "@app/core/models/Task";

let activeDropContainer: any;

@Component({
    selector: 'app-card-expander',
    templateUrl: './card-expander.component.html',
    styleUrls: ['./card-expander.component.scss'],
    animations: [
        fadeAnimation,
        listAnimation,
        trigger('fadeOut', fadeOut()),
        trigger('fadeIn', fadeIn()),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardExpanderComponent extends BaseComponent implements OnInit, OnChanges {

    @Input() items: CardItem[];
    @Input() itemCount: number;
    @Input() isLoadingEvent: EventEmitter<boolean>;
    @Input() listClass = 'd-flex flex-column';
    @Input() showCreateNew = false;
    @Input() draggable = false;
    @Input() allowDragEnter = true;
    @Input() listConfiguration: ListConfiguration;
    @Input() listDragInterface: ListDragInterface;
    @Input() modelClass: string;
    @Input() dataSetChanged: EventEmitter<any>;
    @Input() offset: number;

    @Output() onShowMore = new EventEmitter<boolean>();
    @Output() onShowAll = new EventEmitter<boolean>();
    @Output() onShowLess = new EventEmitter<boolean>();
    @Output() onCreateNew = new EventEmitter<Task>();
    @Output() onDragStarted = new EventEmitter<CdkDragStart>();
    @Output() onDragEnded = new EventEmitter<CdkDragEnd>();

    // Data
    public showLoader = false;
    public itemsPerRow: number;
    public isMobile = false;

    // DragDrop & scrolling
    @Input() scrollContainer: HTMLElement;
    @Output() onDrop = new EventEmitter();
    @Output() onDraw = new EventEmitter();
    draging = false;
    autoScroll: AutoScroll;
    activeDrag: CdkDrag;
    public itemEnterPredicate = this._itemEnterPredicate.bind(this);
    private dropListSubscription: Subscription;
    public dropList: string[] = [];
    public listId = '_' + Math.random().toString(36).substr(2, 9);

    constructor(private dragAndDropService: DragAndDropService,
                private componentRef: ElementRef,
                private changeDetectorRef: ChangeDetectorRef,
                private zone: NgZone,
    ) {
        super();
        this.cdr = this.changeDetectorRef;
    }

    ngOnInit() {
        super.ngOnInit();
        this.isMobile = window.matchMedia("(max-width : 480px)")?.matches;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['draggable'] !== undefined) {
            if (this.dropListSubscription) {
                this.dropListSubscription.unsubscribe();
            }
            this.dropListSubscription = this.dragAndDropService.dropLists$.subscribe(list => {
                setTimeout(() => {
                    this.dropList = list;
                });
            });
            if (this.draggable) {
                this.dragAndDropService.addDropList(this.listId);           // Global drop list
            }
        }

        if (changes['isLoadingEvent'] !== undefined) {
            if (this.isLoadingEvent) {
                this.isLoadingEvent.subscribe((isLoading: boolean) => {
                    this.showLoader = isLoading;
                    this.detectChanges();
                });
            }
        }

        if (changes['listConfiguration'] !== undefined) { // List configuration may change over time. Remember to unsubscribe
            // Wait for view to appear in dom, then calculate smart limit based on view width
            if (this.listConfiguration?.hasSmartLimit() && this.listConfiguration?.getSharedComponentResizeObserver()) {
                const observer = this.listConfiguration.getSharedComponentResizeObserver();
                observer.subscribeToWidth(this.componentRef, width => {
                    this.itemsPerRow = WidthHelper.CalculateNumberOfCardsPerRow(width);
                    this.listConfiguration.setLimit(Math.max(1, this.itemsPerRow));
                });
            } else {
                // Udfas med ComponentResizeObserver, evt. optimer med shared component resize observer
                setTimeout(() => {
                    if (this.listConfiguration?.hasSmartLimit()) {
                        // Observe view resize to trigger showMore if there is space for more items
                        const observer = new ResizeObserver(entries => {
                            this.itemsPerRow = WidthHelper.CalculateNumberOfCardsPerRow(entries[0].contentRect.width);
                            this.listConfiguration.setLimit(Math.max(1, this.itemsPerRow));
                        });
                        observer.observe(this.componentRef.nativeElement);
                    }
                });
            }
        }
    }

    // <editor-fold desc="View Actions">

    public showMore() {
        this.onShowMore.emit(true);
    }

    public showAll() {
        // expand list
        this.onShowAll.emit(true);
    }

    public showLess() {
        this.onShowLess.emit(true);
    }

    public itemDropped(event: CdkDragDrop<ListDragInterface>) {
        DragDropHelper.CardItemDropEvent(event);
    }

    public _itemEnterPredicate(item: CdkDrag<CardItem>, event: CdkDropList<ListDragInterface>) {
        const prevListDragInterface = item.dropContainer.data;
        const nextListDragInterface = event.data;
        const enableEnter = nextListDragInterface.allowCardItemEnter(item.data, prevListDragInterface.configuration);

        return enableEnter && this.allowDragEnter && (
            this.modelClass == null ||
            item.data.item.constructor.name == this.modelClass ||
            item.data.item.constructor.name == this.modelClass + 'EditorForm' // Hvis det er fra editor (ex. TaskEditorForm)
        );
    }

    public trackByFn(index: number, item: CardItem) {
        if (!item) {
            return null;
        }
        return item.item.id; // or item.id
    }

    public onScrollReachedBottom($event: any) {
        if (this.items.length < this.itemCount) {
            this.showMore();
        }
    }

    // </editor-fold>

    // <editor-fold desc="DragDrop">

    dragStarted(event: CdkDragStart) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('dragging');
        this.onDragStarted.emit(event);

        this.draging = true;
        this.activeDrag = event.source;
        // activeDropContainer = event.source.dropContainer;
        activeDropContainer = event.source._dragRef['_dropContainer'];

        if (this.scrollContainer) {

            // https://github.com/volser/cdk-drag-scroll/blob/master/src/app/my-list/my-list.component.ts
            this.zone.runOutsideAngular(() => {

                if (this.autoScroll) {
                    this.autoScroll.destroy();
                    this.autoScroll = null;
                }
                let target = this.scrollContainer;
                if (target.querySelector('.datatable-body')) {
                    target = this.scrollContainer.querySelector('.datatable-body');
                }
                this.autoScroll = new AutoScroll(
                    target,
                    ({x, y}: { x: number; y: number }) => {
                        // console.log({x, y});
                        if (activeDropContainer) {
                            const dropListRef: any = activeDropContainer._dropListRef;
                            // https://github.com/angular/material2/issues/15343
                            // To-Do: do not sync each scroll!
                            this.syncSiblings();
                            // adjust containers
                            this.adjustContainers();
                            // adjust items
                            this.adjustItems(x, y);
                            // To-Do: better condition for changed items
                            if (
                                dropListRef &&
                                dropListRef._draggables.length >
                                dropListRef._itemPositions.length
                            ) {
                                this.syncItems();
                            } else {
                                if (!dropListRef) {
                                    // console.warn('missing dropListRef :', activeDropContainer)
                                }
                            }
                        }

                    }
                );

                this.activeDrag._dragRef.moved
                    .pipe(
                        debounceTime(10),
                        takeWhile(_ => this.draging)
                    )
                    .subscribe(e => {
                        this.autoScroll.onMove(e.pointerPosition);
                    });
            });
        }
    }

    dragEnded(event: CdkDragEnd) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('dragging');
        this.draging = false;
        if (this.autoScroll) {
            this.autoScroll.destroy();
            this.autoScroll = null;
        }
        this.onDragEnded.emit(event);
    }

    cachePositions(event: any) {
        setTimeout(() => {
            const dropListRef: any = event.container._dropListRef;
            if (dropListRef) {
                dropListRef['_cacheOwnPosition'] ? dropListRef['_cacheOwnPosition']() : null;
                dropListRef['_cacheItemPositions'] ? dropListRef['_cacheItemPositions']() : null;
            }
        });
    }

    dropListEntered(event: CdkDragEnter) {
        this.cachePositions(event);
    }

    dropListExited(event: CdkDragExit) {
        this.cachePositions(event);
    }

    syncSiblings() {
        if (!activeDropContainer) {
            return;
        }
        if (activeDropContainer._dropListRef)
            activeDropContainer._dropListRef.beforeStarted.next();
    }

    syncItems() {
        if (!activeDropContainer) {
            return;
        }
        try {
            const dropListRef: any = activeDropContainer._dropListRef;

            if (dropListRef) {
                // To-Do: currently is working only if items were added to the end of list
                const oldPositions = dropListRef._itemPositions;
                dropListRef._activeDraggables = dropListRef._draggables.slice();

                dropListRef._cacheItemPositions();
                const newPositions = dropListRef._itemPositions;
                dropListRef._itemPositions = [...oldPositions];
                newPositions.forEach((p: any) => {
                    if (!oldPositions.find((p1: any) => p.drag === p1.drag)) {
                        dropListRef._itemPositions.push(p);
                    }
                });
                dropListRef._activeDraggables.push(this.activeDrag._dragRef);
            } else {
                console.warn('warning : missing dropListRef : ', activeDropContainer)
            }
        } catch (e) {
            console.warn('error : ', e, 'droplistref : ', activeDropContainer._dropListRef)
        }
    }

    adjustContainers() {
        if (!activeDropContainer) {
            return;
        }
        if (activeDropContainer && activeDropContainer._dropListRef) {
            const dropListRef: any = activeDropContainer._dropListRef;

            // console.log('adjustContainers() : dropListRef : ', dropListRef)
            // https://github.com/angular/material2/issues/15227
            if (dropListRef) {
                // dropListRef._cacheOwnPosition();
                dropListRef['_cacheOwnPosition']();
                dropListRef._siblings.forEach((sibling: any) => {
                    // sibling._cacheOwnPosition();
                    sibling['_cacheOwnPosition']();
                });
            } else {
                console.warn('missing dropListRef : ', activeDropContainer);
            }
        }
    }

    adjustItems(deltaX: number, deltaY: number) {
        if (activeDropContainer && activeDropContainer._dropListRef) {
            const dropListRef: any = activeDropContainer._dropListRef;
            if (dropListRef) {
                dropListRef._itemPositions.forEach((it: any) => {
                    it.originalRect = it.originalRect || it.clientRect;
                    it.clientRect = {
                        ...it.clientRect,
                        left: it.clientRect.left - deltaX,
                        right: it.clientRect.right - deltaX,
                        top: it.clientRect.top - deltaY,
                        bottom: it.clientRect.bottom - deltaY
                    };
                });
            } else {
                console.warn('missing dropListRef : ', activeDropContainer)
            }
        }
    }

    // </editor-fold>
    protected readonly Appointment = Appointment;
    protected readonly CaseUser = CaseUser;
    protected readonly Roster = Roster;
}
