import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {TaskEditCell} from "@app/pages/displays/display-project-details/subdisplay-users/Cells/TaskEditCell";
import {ColumnTypeSettings} from "@app/pages/displays/display-project-details/subdisplay-users/ColumnTypeSettings";
import {
    ProjectDetails_Milestones_TaskEdit_DeadlineDeadlineTypeSettingValue,
    ProjectDetails_Milestones_TaskEditFieldsSettingValue
} from "@app/core/Api";
import {
    TaskEdit_Card_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Card_TableColumn";
import {
    TaskEdit_Status_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Status_TableColumn";
import {
    TaskEdit_Title_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Title_TableColumn";
import {
    TaskEdit_Deadline_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Deadline_TableColumn";
import {
    TaskEdit_Estimate_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Estimate_TableColumn";
import {
    TaskEdit_Who_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Who_TableColumn";
import {
    TaskEdit_Categories_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Categories_TableColumn";
import {
    TaskEdit_Notes_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Notes_TableColumn";
import {
    TaskEdit_Link_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Link_TableColumn";
import {
    TaskEdit_Reactions_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Reactions_TableColumn";
import {
    TaskEdit_Actions_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Actions_TableColumn";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-users/Row";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {
    BaseEditorTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/BaseEditorTableColumn";
import {TemplateRef} from "@angular/core";
import {
    EditorWithColumnInterface
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/EditorWithColumnInterface";
import {CardEditorComponent} from "@app/editor/quick-editor/editors/generic/card-editor/card-editor.component";
import {TitleEditorComponent} from "@app/editor/quick-editor/editors/generic/title-editor/title-editor.component";
import {StatusEditorComponent} from "@app/editor/quick-editor/editors/generic/status-editor/status-editor.component";
import {
    DeadlineListEditorComponent
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/deadline-list-editor.component";
import {
    EstimateListEditorComponent
} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/estimate-list-editor.component";
import {
    CategoryListEditorComponent
} from "@app/editor/quick-editor/editors/generic/category-list-editor/category-list-editor.component";
import {
    ReactionListEditorComponent
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/reaction-list-editor.component";
import {TextEditorComponent} from "@app/editor/quick-editor/editors/generic/text-editor/text-editor.component";
import {LinkEditorComponent} from "@app/editor/quick-editor/editors/generic/link-editor/link-editor.component";
import {
    WhoWhenEditorComponent
} from "@app/editor/quick-editor/editors/generic/who-when-editor/who-when-editor.component";
import {ActionEditorComponent} from "@app/editor/quick-editor/editors/generic/action-editor/action-editor.component";
import {DisplayFilterEditorFormConfigInterface} from "@app/editor/display-filter-editor/DisplayFilterEditorForm";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-users/Filters";

export class TaskEditColumn extends BaseColumn<TaskEditCell> implements ColumnDataFetcherInterface, SortableColumnInterface {

    static Column_TaskCard = 'task-card';
    static Column_TaskTitle = 'task-title';
    static Column_TaskStatus = 'task-main-status';
    static Column_TaskDeadline = 'task-deadline';
    static Column_TaskEstimate = 'task-estimate';
    static Column_TaskParticipants = 'task-participants';
    static Column_TaskCategories = 'task-categories';
    static Column_TaskNotes = 'task-notes';
    static Column_TaskReference = 'task-reference';
    static Column_TaskHands = 'task-hands';
    static Column_TaskStars = 'task-stars';
    static Column_TaskReactions = 'task-reactions';
    static Column_ActionPositioning = 'action-positioning';
    static Column_ActionDelete = 'action-delete';
    static Column_ActionArchive = 'action-archive';

    public enabledEditors?: EditorWithColumnInterface[];

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    // <editor-fold desc="Sorting">

    public implementsSorting: true = true;

    public applyRowSort(row: Row, tableColumn: TaskEdit_Card_TableColumn): void {
        const listConfiguration = tableColumn.column.getCell(row).listConfiguration;
        listConfiguration.setOrderBy(
            this.getOrderBy(Filters.GetBaseSort(tableColumn.activeSortTypeId), tableColumn.activeSortDirection)
        );

        listConfiguration.clearSortFilters();
        switch (Filters.GetBaseSort(tableColumn.activeSortTypeId)) {
            case Filters.SortDeadlineWith:
                listConfiguration.setSort_DeadlineValidator(task => {
                    return task.findTasksDeadlineByType(Filters.ParseSortDeadlineWith(tableColumn.activeSortTypeId))?.exists();
                });
                break;
            case Filters.SortCategoryType:
                listConfiguration.setSort_CategoryTypeId(parseInt(Filters.ParseSortCategoryType(tableColumn.activeSortTypeId)));
                break;
        }
    }

    private getOrderBy(type: string, direction: string): string[][] {
        const orderBy: string[][] = [];
        switch (Filters.GetBaseSort(type)) {
            case Filters.SortTitle:
                orderBy.push(['title', direction]);
                break;
            case Filters.SortStatus:
                orderBy.push(['main_status.status_id', direction]);
                break;
            case Filters.SortDeadlines:
                orderBy.push(['tasks_deadline.deadline.date', 'null'], ['tasks_deadline.deadline.date', direction]);
                break;
            case Filters.SortPlanningDate:
                orderBy.push(['tasks_user.deadline.date', direction]);
                break;
            case Filters.SortStars:
                orderBy.push(['num_stars', (direction == 'asc') ? 'desc' : 'asc']);
                break;
            case Filters.SortHands:
                orderBy.push(['num_hand_ups', (direction == 'asc') ? 'desc' : 'asc']);
                break;
            case Filters.SortUserDefined:
                orderBy.push(['milestones_task.index_', 'asc']);
                orderBy.push(['main_status.status_id', 'asc']);
                orderBy.push(['tasks_deadline.deadline.date', 'asc']);
                orderBy.push(['title', 'asc']);
                break;
            case Filters.SortDeadlineWith:
                orderBy.push(['tasks_deadline.deadline.date', direction]);
                break;
            case Filters.SortCategoryType:
                orderBy.push(['category.name', direction]);
                break;
        }
        return orderBy;
    }

    // </editor-fold>

    private readonly shellPageData: ShellPageData;
    private readonly onColumnSortChange: (column: BaseTableColumn) => void;
    private readonly cellTaskEditListWhoWhenHeaderTemplate: TemplateRef<any>;
    private readonly isMinimized: boolean;
    private readonly config: DisplayFilterEditorFormConfigInterface;

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>,
                shellPageData: ShellPageData,
                onColumnSortChange: (column: BaseTableColumn) => void,
                cellTaskEditListWhoWhenHeaderTemplate: TemplateRef<any>,
                isMinimized: boolean,
                config: DisplayFilterEditorFormConfigInterface) {
        super(columnType, column, settings);
        this.shellPageData = shellPageData;
        this.onColumnSortChange = onColumnSortChange;
        this.cellTaskEditListWhoWhenHeaderTemplate = cellTaskEditListWhoWhenHeaderTemplate;
        this.isMinimized = isMinimized;
        this.config = config;

        this.dataFetcher = new TaskFetcher(undefined, undefined, undefined, column.name);
    }

    createTableColumns(): BaseTableColumn[] {
        const editorTableColumns: BaseEditorTableColumn[] = [];
        if (this.hasField(TaskEditColumn.Column_TaskCard)) {
            editorTableColumns.push(new TaskEdit_Card_TableColumn(this, this.shellPageData, this.onColumnSortChange, this.config));
        }
        if (this.hasField(TaskEditColumn.Column_TaskTitle)) {
            editorTableColumns.push(new TaskEdit_Title_TableColumn(this, this.isMinimized));
        }
        if (this.hasField(TaskEditColumn.Column_TaskStatus)) {
            editorTableColumns.push(new TaskEdit_Status_TableColumn(this));
        }
        if (this.hasField(TaskEditColumn.Column_TaskDeadline)) {
            editorTableColumns.push(new TaskEdit_Deadline_TableColumn(this));
        }
        if (this.hasField(TaskEditColumn.Column_TaskEstimate)) {
            editorTableColumns.push(new TaskEdit_Estimate_TableColumn(this));
        }
        if (this.hasField(TaskEditColumn.Column_TaskParticipants)) {
            editorTableColumns.push(new TaskEdit_Who_TableColumn(this, this.cellTaskEditListWhoWhenHeaderTemplate, this.isMinimized));
        }
        if (this.hasField(TaskEditColumn.Column_TaskCategories)) {
            editorTableColumns.push(new TaskEdit_Categories_TableColumn(this));
        }
        if (this.hasField(TaskEditColumn.Column_TaskNotes)) {
            editorTableColumns.push(new TaskEdit_Notes_TableColumn(this, this.isMinimized));
        }
        if (this.hasField(TaskEditColumn.Column_TaskReference)) {
            editorTableColumns.push(new TaskEdit_Link_TableColumn(this, this.isMinimized));
        }
        if (this.hasField(TaskEditColumn.Column_TaskHands)
            || this.hasField(TaskEditColumn.Column_TaskStars)
            || this.hasField(TaskEditColumn.Column_TaskReactions)) {
            editorTableColumns.push(new TaskEdit_Reactions_TableColumn(this));
        }
        if (this.hasField(TaskEditColumn.Column_ActionPositioning)
            || this.hasField(TaskEditColumn.Column_ActionDelete)
            || this.hasField(TaskEditColumn.Column_ActionArchive)) {
            editorTableColumns.push(new TaskEdit_Actions_TableColumn(this));
        }

        return editorTableColumns;
    }

    createCell(row: Row): BaseCell {
        const cell = new TaskEditCell(row, this);
        this.dataFetcher.addRequest(new TaskFetchRequest(cell.listConfiguration));
        return cell;
    }

    public getFields(): string[] {
        return this.settings.get(ColumnTypeSettings.TaskEdit_Fields)
            ?.getObject<ProjectDetails_Milestones_TaskEditFieldsSettingValue>()
            ?.fields ?? [];
    }

    public hasField(field: string): boolean {
        return this.getFields().includes(field);
    }

    public getTaskDeadlineType(): number | undefined {
        return this.settings.get(ColumnTypeSettings.TaskEdit_Deadline_DeadlineType)
            ?.getObject<ProjectDetails_Milestones_TaskEdit_DeadlineDeadlineTypeSettingValue>()
            ?.taskDeadlineTypeId;
    }

    public updateEnabledEditors() {
        const editors = this.getVisibleTableColumns<BaseEditorTableColumn>().map(editorTableColumn => editorTableColumn.editorWithColumn);

        const editorOrder = [
            CardEditorComponent.name,
            TitleEditorComponent.name,
            StatusEditorComponent.name,
            DeadlineListEditorComponent.name,
            EstimateListEditorComponent.name,
            CategoryListEditorComponent.name,
            ReactionListEditorComponent.name,
            TextEditorComponent.name,
            LinkEditorComponent.name,
            WhoWhenEditorComponent.name,
            ActionEditorComponent.name,
        ];
        editors.sort((a, b) => {
            return editorOrder.indexOf(a.name) - editorOrder.indexOf(b.name);
        });

        this.enabledEditors = editors;
    }

}
