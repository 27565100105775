/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {HolidayDefinition} from './definitions/HolidayDefinition';
import {Api} from "@app/core/Api";
import Helpers from "@app/core/helpers";

export class Holiday extends HolidayDefinition {

    constructor(json?: any) {
        super(json);
    }

    private static Cache: Holiday[];
    private static HolidaysCallbacks: ((items: Holiday[]) => void)[] = [];

    public static GetAll(callback: (items: Holiday[]) => void) {
        if (this.Cache) {
            callback(this.Cache);
        } else if (this.HolidaysCallbacks.length > 0) {
            this.HolidaysCallbacks.push(callback);
        } else {

            this.HolidaysCallbacks.push(callback);
            Api.holidays()
                .get()
                .addQueryParameter('start', Helpers.serverDateUnix(new Date(new Date().getFullYear(), 0, 1)))
                .addQueryParameter('end', Helpers.serverDateUnix(new Date(new Date().getFullYear(), 11, 31)))
                .find(items => {
                    this.Cache = items.sort((a: Holiday, b: Holiday) => a.time > b.time ? 1 : -1);
                    this.HolidaysCallbacks.forEach(callback => callback(items));
                    this.HolidaysCallbacks = [];
                });
        }
    }

}
