import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges, ViewChild
} from '@angular/core';
import {CardComponent} from '@app/shared/_ui/cards/card.component';
import {Task} from '@app/core/models/Task';
import {TasksService} from '@app/services/tasks.service';
import {TasksDeadline} from '@app/core/models/TasksDeadline';
import {CardTaskConfiguration} from '@app/shared/_ui/cards/medium/card-task/card-task-configuration';
import {TaskEstimate} from '@app/core/models/TaskEstimate';
import {TasksUser} from '@app/core/models/TasksUser';
import {TaskDeadlineTypes, TaskUserTypes} from '@app/constants';
import {MiniCardFields} from '@app/editor/task-editor-loader/Fields';
import {Deadline} from '@app/core/models/Deadline';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {CardProjectConfiguration} from '@app/shared/_ui/cards/medium/card-project/card-project-configuration';
import {Project} from '@app/core/models/Project';
import {EstimateUnit} from '@app/core/models/EstimateUnit';
import {User} from '@app/core/models/User';
import {TaskUserType} from '@app/core/models/TaskUserType';
import {AppInjector} from '@app/services/app-injector.service';
import {Field, TaskEditorService} from '@app/editor/task-editor-loader/task-editor.service';
import {ReactionsTypeInterface} from "@app/shared/_ui/reactions/ReactionsTypeInterface";
import {Category, Milestone, TaskDeadlineType} from "@app/core/models";
import {CardMilestoneConfiguration} from "@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {Api} from '@app/core/Api';
import {EventService} from '@app/services/event.service';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {Subscription} from "rxjs";

@Component({
    selector: 'app-card-task',
    templateUrl: './card-task.component.html',
    styleUrls: ['../../card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardTaskComponent extends CardComponent implements OnInit, OnChanges {

    @Input() item: CardItem<Task>;
    @Input() model: Task;
    @Output() contentClassChange = new EventEmitter<Task>();
    @Input() configuration: CardTaskConfiguration;

    @Input() mini = false;
    @Input() tooltip: string;
    @Input() listConfiguration: ListConfiguration;

    @Input() interactive: boolean = true;
    @Input() removable: boolean = false;
    @Input() editable: boolean = false;

    @Output() onRemove = new EventEmitter();
    @Output() onCardClickEvent = new EventEmitter();

    // UI
    @ViewChild('cardContainer') cardContainer: ElementRef;
    public deadline: Deadline;
    public deadlineName: string;
    public users: User[];
    public taskUserType: TaskUserType;
    public projectCard: CardItem<Project>;
    public editorFields: Map<number, Field>;
    public fields: Map<number, Field>;
    public hasVisibleReactions = false;
    public visibleCategories: Category[];
    public milestoneCards: CardItem<Milestone>[];
    public isGrayedOut = false;

    // Data
    public taskUserDeadline: TasksUser;
    public taskDeadline: TasksDeadline;
    public taskEstimate: TaskEstimate;
    private tasksService: TasksService;
    private dialogService: BaseDialogService;
    private editorService: TaskEditorService;
    public task_users: TasksUser[];
    public reactionsTypeInterfaces: ReactionsTypeInterface[];

    constructor(protected cd: ChangeDetectorRef
                ) {
        super();
        this.cdr = cd;
        this.tasksService = AppInjector.getInjector().get(TasksService);
        this.editorService = AppInjector.getInjector().get(TaskEditorService);
        this.dialogService = AppInjector.getInjector().get(BaseDialogService);
    }

    ngOnInit() {
        if (!this.model && this.item) {
            this.model = this.item.item;
        }
        this.setup();
        super.ngOnInit(); // this.render() bliver afviklet her
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        if (changes['configuration']) {
            this.setup();
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.onChangeSubscription?.unsubscribe();
    }

    private onChangeSubscription?: Subscription;
    private setup() {
        this.onChangeSubscription?.unsubscribe();
        if (this.configuration) {
            this.onChangeSubscription = this.configuration.onChangeEvent.subscribe(() => this.render());
        }
    }

    onItemUpdated(item: Task) {
        super.onItemUpdated(item);
        if (item.task_estimates) {
            this.model.task_estimates = item.task_estimates;
        }

        if (this.configuration && this.item) {
            if (this.configuration.taskEstimate) {
                if (this.item.item.task_estimates) {
                    this.configuration.taskEstimate = this.item.item.task_estimates.find(item => item.id == this.configuration.taskEstimate.id);
                } else {
                    this.configuration.taskEstimate = null;
                }
            }
            if (this.configuration.tasksUser) {
                this.configuration.tasksUser = this.item.item.tasks_users.find(item => item.id == this.configuration.tasksUser.id);
            }
            if (this.configuration.taskDeadline) {
                this.configuration.taskDeadline = this.item.item.tasks_deadlines.find(item => item.id == this.configuration.taskDeadline.id);
            }
            if (this.configuration.tasksUserDeadline) {
                this.configuration.tasksUserDeadline = this.item.item.tasks_users?.find(item => item.id == this.configuration.tasksUserDeadline.id);
            }
            if (this.configuration.tasksUsers) {
                this.configuration.tasksUsers = this.item.item.tasks_users.filter(tasksUser =>
                    tasksUser.task_user_type_id === this.taskUserType.id);
            }

            this.taskEstimate = this.getConfigurationEstimate();
        }

        this.render();
    }

    protected render() {
        super.render();

        if (this.model?.task_type_id) {
            // Fields til oversættelser
            this.editorService.getEditorFieldsForType(this.model.task_type_id, fields => {
                this.editorFields = fields;
                // this.markChangeDetectionDirty();
            });

            this.editorService.getMiniCardFieldsForType(this.model.task_type_id, fields => {
                this.fields = fields;
                // this.markChangeDetectionDirty();
            });

            this.tasksService.getTaskType(this.model.task_type_id, taskType => {
            // Clear
            this.deadline = null;
            this.deadlineName = null;
            this.users = [];
            this.taskUserType = null;

            this.model.task_type = taskType;

            this.setConfigurationTaskUsers(); // Sætter taskUserType og users til brug i app-users-with-icon
            this.taskUserDeadline = this.getConfigurationTaskUserDeadline();
            this.taskDeadline = this.getConfigurationDeadline();
            this.taskEstimate = this.getConfigurationEstimate();

            // Det meget cool med configuration, men Klart vil have "Overordnet frist"..
            let tasksDeadline = this.model && this.model.findTasksDeadlineByType ? this.model.findTasksDeadlineByType(TaskDeadlineTypes.Normal) : null;
            if (tasksDeadline && tasksDeadline.deadline) {
                this.deadline = tasksDeadline.deadline;

                // Ensure task deadline type and then add deadline name
                TaskDeadlineType.GetById(tasksDeadline.task_deadline_type_id, type => {
                    tasksDeadline.task_deadline_type = type;
                    this.deadlineName = tasksDeadline.deadlineType.name ? tasksDeadline.deadlineType.name : tasksDeadline.deadlineType.language_key;
                    // this.markChangeDetectionDirty();
                });
            }

            const projects = this.item?.item?.projects?.filter(p => p.id != 0);
            if (this.configuration && this.configuration.showProjectMiniCard && this.item && projects && projects.length) {
                this.projectCard = new CardItem<Project>(projects[0], new CardProjectConfiguration());
            } else {
                this.projectCard = null;
            }

            // https://podio.com/klartboard/softwareudvikling/apps/supports/items/1033
            const milestones = this.item?.item?.milestones?.filter(p => p.id != 0);
            if (this.configuration?.showMilestoneMiniCards && milestones && milestones.length) {
                this.milestoneCards = milestones.map(milestone => {
                    return new CardItem<Milestone>(milestone, new CardMilestoneConfiguration());
                });
            } else {
                this.milestoneCards = null;
            }

            this.reactionsTypeInterfaces = taskType.reaction_types_task_types ?? [];

            const excludedCategoryIds = this.configuration?.excludedCategoryIds ?? [];
            this.visibleCategories = this.model.categories?.filter(category => !excludedCategoryIds.includes(category.id)).sort((a, b) => {
                if (a.category_type_id !== b.category_type_id) {
                    return a.category_type_id - b.category_type_id;
                } else {
                    return a.name.localeCompare(b.name);
                }
            });

            this.isGrayedOut = this.configuration?.isGrayedOut ?? false;
            this.initialized = true;
            this.detectChanges();
        });
        }
    }

    // <editor-fold desc="View Actions">

    onArchivedToggle($event: any) {
        this.model.setArchived(!this.model.isArchived());
    }

    onStatusChange($event: number) {
        if (this.model.id != 0 && !this.editable) {
            this.model.setStatus($event);
        }
    }

    delete(model: Task) {
        // model.delete();
        this.dialogService.confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant(`_ui_delete_item`, {name: this.model.title}),
            this.translateService.instant('_global_delete'),
        )
            .then((confirmed) => {
                if (confirmed) {
                    this.model.delete(() => {});
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    copyTask(model: Task) {
        const taskName = this.translateService.instant('_task')
        this.dialogService.confirm(
            this.translateService.instant('_ui_copy'),
            this.translateService.instant('_ui_copy_item', {item: model.title || taskName}),
            this.translateService.instant('_global_ok'),
            this.translateService.instant('_global_cancel'),
            'sm',
            false,
            'btn-success'
        )
            .then((confirmed) => {
                if (confirmed) {
                    Api.tasks()
                        .copyGetById(this.model.id)
                        .find((r) => {
                            AppInjector.getInjector().get(EventService).emitTask(r[0], EventService.Created);
                        })
                }
            });
    }

    get Fields() {
        return MiniCardFields;
    }

    public onUserArchived($event: {tasksUser: TasksUser, isArchived: boolean}): void {
        $event.tasksUser.isArchived = $event.isArchived;
        this.model.setUserArchived($event.tasksUser.user, $event.isArchived);
    }

    // </editor-fold>

    // <editor-fold desc="Configuration">

    getConfigurationEstimate() {
        if (this.configuration
            && this.configuration.taskEstimate
            && this.configuration.taskEstimate.estimate
            && this.configuration.taskEstimate.estimate.value > 0) {
            if (!this.configuration.taskEstimate.estimate.estimate_unit) {
                this.configuration.taskEstimate.estimate.estimate_unit = new EstimateUnit({language_key: ''});
            }
            return this.configuration.taskEstimate;
        } else if (this.configuration?.shownEstimateTypeId) {
            return this.model.findTaskEstimateByType(this.configuration?.shownEstimateTypeId);
        } else {
            return this.model && this.model.getDefaultTaskEstimate() ? this.model.getDefaultTaskEstimate() : null;
        }
    }

    getConfigurationDeadline() {
        if (this.configuration) {
            if (this.configuration.taskDeadline) {
                return this.configuration.taskDeadline;
            }
        }
        return null;
    }

    getConfigurationTaskUserDeadline(): TasksUser {
        if (this.configuration) {
            if (this.configuration.tasksUserDeadline && this.configuration.tasksUserDeadline.deadline) {
                return this.configuration.tasksUserDeadline;
            }
        }
        return null;
    }

    setConfigurationTaskUsers() {
        if (this.configuration != null && this.configuration.tasksUsers) {
            this.task_users = this.configuration.tasksUsers;
            this.users = this.configuration.tasksUsers.map(tasksUser => tasksUser.user);
            if (this.configuration.tasksUsers.length) {
                const taskUserTypeId = this.configuration.tasksUsers[0].task_user_type_id;
                AppInjector.getInjector().get(TasksService).getTaskUserType(taskUserTypeId, item => {
                    this.taskUserType = item;
                })
            }
            return;
        }

        let taskUserTypeId = TaskUserTypes.Participant;
        if ((this.configuration == null || this.configuration.tasksUser == null) && this.model.tasks_users) {
            this.users = this.model.findTasksUsersByType(TaskUserTypes.Participant).map(tasksUser => tasksUser.user);
            this.task_users = this.model.findTasksUsersByType(TaskUserTypes.Participant);
        } else if (this.configuration) {
            if (this.configuration.tasksUser) {
                taskUserTypeId = this.configuration.tasksUser.task_user_type_id;
                this.users = [this.configuration.tasksUser.user];
                this.task_users = [this.configuration.tasksUser];
            }
        }
        AppInjector.getInjector().get(TasksService).getTaskUserType(taskUserTypeId, item => {
            this.taskUserType = item;
        })
    }

    public isFieldVisible(fieldId: number): boolean {
        if (this.fields && this.fields.get(fieldId)) {
            return this.fields.get(fieldId).visible;
        } else {
            return false;
        }
    }

    // </editor-fold>

    triggerChangeDialog(item: Task) {
        this.dialogService.changeTaskType(
            this.translateService.instant(`_ui_change_type`),
            ``,
            this.translateService.instant('_global_ok'),
            this.translateService.instant('_global_cancel'),
            'sm',
            item
        )
            .then((confirmed) => {
                if (confirmed) {
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

    }
}
