<div class="container-fluid p-0 h-100 d-flex flex-column" *ngIf="initialized"
     [attr.data-isCapturingScreenshot]="isCapturingScreenshot"
>
    <div #contentContainer class="d-flex justify-content-between w-100 flex-grow-1"
         [class.scroll-x]="!isMobileVersion"
         [class.flex-row]="!isMobileVersion"
         [class.scroll-y]="isMobileVersion"
         [class.flex-column]="isMobileVersion"
    >
        <ng-container *ngFor="let tableColumn of tableColumns">
            <div class="phase-container h-100"
                 *ngIf="tableColumn.projectListConfiguration"
                 [style.min-width.px]="!isMobileVersion ? tableColumn.width : null"
                 [style.width.px]="!isMobileVersion ? tableColumn.width : null"
                 mwlResizable
                 [enableGhostResize]="false"
                 [validateResize]="tableColumn.validateResize"
                 (resizing)="tableColumn.resize($event.rectangle.width)"
            >
                <header class=" p-1 ps-2 position-relative d-flex align-items-center">
                    <app-column-sorter [display]="display" [column]="tableColumn"
                                       [onRenderEvent]="onColumnSortRenderEventEmitter"
                                       [filterPageData]="shellPageData"
                    ></app-column-sorter>

                    <app-color-item
                        *ngIf="tableColumn.column.phase?.color"
                        class="me-1"
                        [color]="tableColumn.column.phase.color"
                    />

                    <div class="d-flex align-items-center ms-auto">
                        <!-- Matrix popover for Prioritization column -->
                        <div class="d-flex cursor-pointer me-1 border text-small align-items-center"
                             *ngIf="tableColumn.column.showMatrix"
                             triggers="mouseenter:mouseleave"
                             [autoClose]="'outside'"
                             container="body"
                             [closeDelay]="500"
                             style="transform: translateX(-8px); padding-left: 2px; padding-right: 2px;padding-top: 3px; border-radius: .25rem;z-index: 100;"
                             [ngbPopover]="matrixContent">
                            <i class="fa fas fa-th-large " style="transform: translateY(-1px);"></i>
                        </div>

                        <!-- OnScreen Filters -->
                        <app-on-screen-filter-selector
                            [showMultiColumnFilters]="false"
                            [onScreenFilters]="onScreenFilters"
                        ></app-on-screen-filter-selector>

                        <!-- Resize -->
                        <div class="resize-handle text-small ms-2 d-mobile-none"
                             [ngbTooltip]="('_ui_resize_column' | translate)"
                             [resizeEdges]="{bottom: false, right: true, top: false, left: false}"
                             mwlResizeHandle>
                            <i class="fas fa-grip-vertical"></i>
                        </div>
                    </div>
                </header>
                <aside class="p-1 scroll-y position-relative">
                    <div class="p-2 text-small position-absolute" style="z-index: 100;"
                         *ngIf="tableColumn.projectListConfiguration.dropDisabled">
                        <span class="alert alert-warning d-flex align-items-center text-center">
                            <i class="fa fa-exclamation-triangle me-1" aria-hidden="true"></i>
                            <span >{{'_ui_project_type_missing_phase_type' | translate}}</span>
                        </span>
                    </div>
                    <app-project-list
                        [class.disabled]="tableColumn.projectListConfiguration.dropDisabled"
                        [reloadEvent]="tableColumn.reloadEvent"
                        [configuration]="tableColumn.projectListConfiguration"
                        (onDragStarted)="onDragStartedEvent($event)"
                        (onDragEnded)="onDragEndedEvent($event)"
                    ></app-project-list>
                </aside>
            </div>
        </ng-container>

    </div>

    <footer class="d-flex align-items-center w-100">
        <div class="d-flex align-items-center footer-actions -ms-auto pe-2 ps-2">
            <a class="me-2 d-none"
               (click)="onCaptureScreenshotBtnClicked();"
               [ngbTooltip]="('_ui_screenshot' | translate)"
            >
                <i class="fal fa-camera-viewfinder" *ngIf="!isCapturingScreenshot"></i>
                <app-loading-indicator [showLogo]="false"
                                       *ngIf="isCapturingScreenshot"></app-loading-indicator>
            </a>

            <a class="text-center me-2 " (click)="loadData()"
               style="transform: translateY(-1px);"
               [ngbTooltip]="('_ui_reload' | translate) + '...'"
               container="body"

               placement="right"><i class="fal fa-light fa-arrow-rotate-right"></i></a>

            <app-on-screen-filter-selector
                [showMultiColumnFilters]="true"
                [onScreenFilters]="onScreenFilters"
            ></app-on-screen-filter-selector>
        </div>
    </footer>
</div>

<ng-template #matrixContent>
    <app-priority-matrix
        [color1]="prioritizationMatrixSetting.color1"
        [color2]="prioritizationMatrixSetting.color2"
        [color3]="prioritizationMatrixSetting.color3"
        [color4]="prioritizationMatrixSetting.color4"
        [text1]="prioritizationMatrixSetting.text1"
        [text2]="prioritizationMatrixSetting.text2"
        [text3]="prioritizationMatrixSetting.text3"
        [text4]="prioritizationMatrixSetting.text4"
        [axisX]="prioritizationMatrixSetting.axisX"
        [axisY]="prioritizationMatrixSetting.axisY"
    />
</ng-template>
