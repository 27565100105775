import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/dashboard/dashboard-calendar/ColumnTypeSettings";
import {
    UserOverviewCalendar_Days_FilterProjectDeadlineTypesSettingValue,
    UserOverviewCalendar_Days_IncludeMilestonesSettingValue,
    UserOverviewCalendar_Days_IncludeProjectTypesSettingValue,
    UserOverviewCalendar_Days_IncludeTaskPlanningsSettingValue,
    UserOverviewCalendar_Days_IncludeTaskTypesSettingValue,
    UserOverviewCalendar_Days_VisibleDaysSettingValue,
    UserOverviewCalendar_SoftNextWeek_IncludeTaskTypesSettingValue
} from "@app/core/Api";
import {DaysTableColumn} from "@app/pages/displays/dashboard/dashboard-calendar/TableColumns/DaysTableColumn";

export class DaysColumn extends BaseColumn {

    public createTableColumns(): BaseTableColumn[] {
        return this.settings.get(ColumnTypeSettings.Days_VisibleDays)
            ?.getObject<UserOverviewCalendar_Days_VisibleDaysSettingValue>()
            ?.days
            ?.map(day => {
                return new DaysTableColumn(this, day);
            }) ?? [];
    }

    public createCell(row: BaseRow): BaseCell {
        throw new Error("Method not implemented.");
    }

    public getIncludeTaskTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.Days_IncludeTaskTypes)
            ?.getObject<UserOverviewCalendar_Days_IncludeTaskTypesSettingValue>()
            ?.taskTypeIds ?? [];
    }

    public getIncludeProjectTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.Days_IncludeProjectTypes)
            ?.getObject<UserOverviewCalendar_Days_IncludeProjectTypesSettingValue>()
            ?.projectTypeIds ?? [];
    }

    public getIncludeTaskPlannings(): boolean {
        return this.settings.get(ColumnTypeSettings.Days_IncludeTaskPlannings)
            ?.getObject<UserOverviewCalendar_Days_IncludeTaskPlanningsSettingValue>()
            ?.value ?? false;
    }

    public getIncludeMilestones(): boolean {
        return this.settings.get(ColumnTypeSettings.Days_IncludeMilestones)
            ?.getObject<UserOverviewCalendar_Days_IncludeMilestonesSettingValue>()
            ?.value ?? false;
    }

    public getFilterProjectDeadlineTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.Days_FilterProjectDeadlineTypes)
            ?.getObject<UserOverviewCalendar_Days_FilterProjectDeadlineTypesSettingValue>()
            ?.projectDeadlineTypeIds ?? [];
    }

}
