import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {I18nService} from '@app/core';
import {TranslateService} from "@ngx-translate/core";
import {TranslationWidth} from "@angular/common";

const I18N_VALUES = {
    'da-DK': {
        weekdays: [
            '_displays_user_overview_calendar_monday',
            '_displays_user_overview_calendar_tuesday',
            '_displays_user_overview_calendar_wednesday',
            '_displays_user_overview_calendar_thursday',
            '_displays_user_overview_calendar_friday',
            '_displays_user_overview_calendar_saturday',
            '_displays_user_overview_calendar_sunday'
        ],
        months: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'],
        dateformat: "DD/MM/YY",
    },
    'sv-SE': {
        weekdays: [
            '_displays_user_overview_calendar_monday',
            '_displays_user_overview_calendar_tuesday',
            '_displays_user_overview_calendar_wednesday',
            '_displays_user_overview_calendar_thursday',
            '_displays_user_overview_calendar_friday',
            '_displays_user_overview_calendar_saturday',
            '_displays_user_overview_calendar_sunday'
        ],
        months: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
        dateformat: "DD/MM/YY",
    },
    'nb': {
        weekdays: [
            '_displays_user_overview_calendar_monday',
            '_displays_user_overview_calendar_tuesday',
            '_displays_user_overview_calendar_wednesday',
            '_displays_user_overview_calendar_thursday',
            '_displays_user_overview_calendar_friday',
            '_displays_user_overview_calendar_saturday',
            '_displays_user_overview_calendar_sunday'
        ],
        months: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'],
        dateformat: "DD/MM/YY",
    },
    'nn': {
        weekdays: [
            '_displays_user_overview_calendar_monday',
            '_displays_user_overview_calendar_tuesday',
            '_displays_user_overview_calendar_wednesday',
            '_displays_user_overview_calendar_thursday',
            '_displays_user_overview_calendar_friday',
            '_displays_user_overview_calendar_saturday',
            '_displays_user_overview_calendar_sunday'
        ],
        months: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'],
        dateformat: "DD/MM/YY",
    },
    'en-US': {
        weekdays: [
            '_displays_user_overview_calendar_monday',
            '_displays_user_overview_calendar_tuesday',
            '_displays_user_overview_calendar_wednesday',
            '_displays_user_overview_calendar_thursday',
            '_displays_user_overview_calendar_friday',
            '_displays_user_overview_calendar_saturday',
            '_displays_user_overview_calendar_sunday'
        ],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        dateformat: "MM/DD/YY",
    }
    // other languages you would support
};


// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
    language = 'da-DK';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

    constructor(private languageService: I18nService,
                private translateService: TranslateService) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return String(this.translateService.instant((I18N_VALUES as any)[this.languageService.language].weekdays[weekday - 1]))
            .substr(0, 3);
    }

    getMonthShortName(month: number): string {
        return String(this.translateService.instant((I18N_VALUES as any)[this.languageService.language].months[month - 1]))
            .substr(0, 3);
    }

    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        // Note: DD/MM/YY fra sprogvariabel
        return this.translateService.instant('_ui_date_aria_label', {day: date.day, month: date.month, year: date.year})
        // return `${date.day}-${date.month}-${date.year}`;
    }

    getWeekdayLabel(weekday: number, width?: Exclude<Intl.DateTimeFormatOptions["weekday"], undefined>): string {
        return this.getWeekdayShortName(weekday);
    }
}
