import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {Projects_ProjectDeadlineTypesSettingValue} from "@app/core/Api";
import {ProjectsDeadline} from "@app/core/models";

@Component({
    selector: 'app-project-deadline-list-cell',
    templateUrl: './project-deadline-list-cell.component.html',
    styleUrls: ['./project-deadline-list-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectDeadlineListCellComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() row: ProjectsDisplayRow;
    @Input() column: GenericTableColumn;

    // Bindings to view
    public projectsDeadlines: ProjectsDeadline[] = [];

    // Data

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        const projectsDeadlines: ProjectsDeadline[] = [];
        this.column.column.settings.get(ColumnTypeSettings.ProjectDeadlineTypes)
            ?.getObject<Projects_ProjectDeadlineTypesSettingValue>()
            ?.projectDeadlineTypeIds

            // Find ProjectDeadline
            ?.map(projectDeadlineTypeId => this.row.project.item.findProjectsDeadlineByType(projectDeadlineTypeId))

            // Filter out invalid items
            ?.filter(projectDeadline => projectDeadline?.deadline?.exists())

            // Add to view
            ?.forEach(projectDeadline => projectsDeadlines.push(projectDeadline))

        this.projectsDeadlines = projectsDeadlines;
        this.detectChanges();
    }

}
