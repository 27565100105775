import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {Project} from "@app/core/models/Project";
import {ProjectCase} from "@app/core/models/ProjectCase";
import {Task} from "@app/core/models/Task";
import {EventEmitter} from "@angular/core";
import moment from "moment";
import {EstimateUnits} from "@app/constants";
import {Api} from '@app/core/Api';
import Helpers from '@app/core/helpers';
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {WeekDay, WeekDays} from "@app/pages/displays/display-cases/TableColumns/DaysTableColumn";

export class CaseRow extends BaseRow {
    projectCard: CardItem<Project>;
    projectCase: ProjectCase;

    planned: number;
    reached: number;
    plannedEstimate: number;
    reachedEstimate: number;
    sums = new Map<WeekDay, number[]>();
    standardTime: number;

    minimizeEvent = new EventEmitter();
    reloadCasesEvent = new EventEmitter();

    constructor() {
        super();
    }

    public setProjectCard(card: CardItem<Project>) {
        this.projectCard = card;

        if (card.item.project_estimates && card.item.project_estimates.length > 0) {
            let projectsEstimate = card.item.project_estimates[0];
            if (projectsEstimate.estimate && projectsEstimate.estimate.estimate_unit_id == EstimateUnits.Hours) {
                this.standardTime = projectsEstimate.estimate.value;
            }
        }
    }

    public reload(date: Date) {
        Api.projectCases().smartGet()
            .project_ids([this.projectCard.item.id])
            .date(Helpers.serverDate(moment(date).startOf('isoWeek').toDate()))
            .find(projectCases => this.setProjectCase(projectCases[0]));
        this.reloadCasesEvent.emit();
    }

    public onTaskListDataSetChanged(weekDay: WeekDay, items: CardItem<Task>[]) {
        this.setSum(weekDay, items.map(item => item.item));
    }

    public onWeekTaskListDataSetChanged(items: CardItem<Task>[]) {
        this.setSum(WeekDays.Monday, items.map(item => item.item));
    }

    public setSum(weekDay: WeekDay, tasks: Task[]) {
        let planned = 0;
        let reached = 0;
        let plannedEstimate = 0;
        let reachedEstimate = 0;
        tasks.forEach(task => {
            planned += task.cases_planned ? task.cases_planned : 0;
            reached += task.cases_reached ? task.cases_reached : 0;

            let percentageDone = 100 / (task.cases_planned ? task.cases_planned : 0) * (task.cases_reached ? task.cases_reached : 0);
            // Estimate
            if (task.task_estimates && task.task_estimates.length > 0) {
                let tasksEstimate = task.task_estimates[0];
                if (tasksEstimate.estimate && tasksEstimate.estimate?.value && tasksEstimate.estimate?.estimate_unit_id == EstimateUnits.Hours) {
                    plannedEstimate += tasksEstimate.estimate?.value ? tasksEstimate.estimate?.value : 0;
                    reachedEstimate += (tasksEstimate.estimate?.value ? tasksEstimate.estimate?.value : 0) / 100 * percentageDone;
                }
            }
        });

        this.sums.set(weekDay, [planned, reached, plannedEstimate, reachedEstimate]);
        this.calculateSum();
    }

    public calculateSum() {
        this.planned = 0;
        this.reached = 0;
        this.plannedEstimate = 0;
        this.reachedEstimate = 0;
        this.sums.forEach(sum => {
            this.planned += sum[0];
            this.reached += sum[1];
            this.plannedEstimate += sum[2];
            this.reachedEstimate += sum[3];
        })

        // https://podio.com/klartboard/softwareudvikling/apps/stories/items/935
        this.plannedEstimate = Math.round(this.plannedEstimate);
        this.reachedEstimate = Math.round(this.reachedEstimate);
    }

    public setProjectCase(projectCase: ProjectCase) {
        if (projectCase) {
            this.projectCase = projectCase;
            this.projectCase.project = this.projectCard.item;
        }
    }

}
