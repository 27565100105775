import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PageComponent} from '@app/pages/page.component';
import {Display} from '@app/core/models/Display';
import {User} from '@app/core/models/User';
import {DisplayTypes, System} from '@app/constants';
import {DisplayLink} from '@app/shell/header/display-list/display-list.component';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {DepartmentsDisplay} from '@app/core/models';
import {ActivatedRoute} from '@angular/router';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {AuthService} from "../../../../services/auth.service";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DashboardComponent extends PageComponent implements OnInit, OnChanges {
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;

    public displays: Display[];
    public displayUser: User;
    public displayReady = false;
    public showList = false;
    public showDisplay = false;
    public navigate = true;
    public activeDisplay: Display | undefined;
    public activeDepartmentDisplay: DepartmentsDisplay | undefined;
    private redirect = true;

    constructor(private cd: ChangeDetectorRef,
                private authService: AuthService,
                private activatedRoute: ActivatedRoute,
                private dbService: NgxIndexedDBService,) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.initialized = false;
        this.shellService.setHeaderTitle(this.Constants.System.Loading);

        const params = this.activatedRoute.snapshot.params;
        if (params.displayMode) {
            // params.displayMode == 'list'
            if (params.displayMode == 'personal')
                this.showList = false;
            this.redirect = false;
        } else {
            this.redirect = true;
        }

        this.subscribe(
            this.usersService.currentUser$.subscribe(value => {
                if (value) {
                    this.displayUser = value;
                    this.render();
                }
            })
        );

        this.usersService.currentUser$.subscribe((u) => {
            if (u && !this.displays) {
                this.displayService.getDisplaysWithoutSettings(displays => {
                    this.displays = displays.filter(display => {
                        switch (display.display_type_id) {
                            case DisplayTypes.UserOverview:
                            case DisplayTypes.Projects:
                            case DisplayTypes.Team:
                            case DisplayTypes.TeamWeekly:
                            case DisplayTypes.Department:
                            case DisplayTypes.Kanban:
                            case DisplayTypes.KanbanProgress:
                            case DisplayTypes.Category:
                                return true;
                        }
                    });
                    this.render();
                });
            }
        })

    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
    }

    private render() {
        if (!this.displayUser || !this.displays) {
            return;
        }
        this.initialized = true;
        this.shellService.setHeaderTitle('Klartboard', null, false, false, false);

        if (this.displayUser?.default_displays_department_id) {
            if (this.displayUser.uber || !this.redirect) { // Kevin: Så det var muligt at reloade debug siden uden at blive redirected
                this.displayReady = true;
                this.showDisplay = true;
                this.navigate = false;
                this.getDisplayFromDepartmentDisplayId(this.displayUser.default_displays_department_id);
                this.showList = true;
            } else {

                if (!this.isMobileVersion) {
                    this.gotoDisplayId(this.displayUser.default_displays_department_id);
                    setTimeout(() => {
                        this.displayReady = true;
                        this.showDisplay = true;
                        this.showList = true;
                    }, 5000)
                }

            }
        } else {
            this.displayReady = true;
            this.showDisplay = true;
            this.showList = true;
        }

        this.detectChanges();
    }

    private getDisplayFromDepartmentDisplayId(departmentsDisplayId: number) {
        this.displayService.getDisplaysWithoutSettings(displays => {

            this.activeDisplay = displays.find(display => {
                this.activeDepartmentDisplay = display.departments_displays?.find(departmentsDisplay => departmentsDisplay.id == departmentsDisplayId);
                return this.activeDepartmentDisplay;
            });
            this.detectChanges();
        });
    }

    private getDepartmentDisplayFromDisplayId(displayId: number, departmentId: number): DepartmentsDisplay {
        const selectedDisplay = this.displays
            .find(display => {
                return display.id == displayId && display.departments_displays.find(dd => dd.department_id == departmentId);
            });
        return selectedDisplay?.departments_displays?.find(dd => dd.department_id == departmentId);
    }

    // <editor-fold desc="View Actions">

    public selectDefaultDepartmentsDisplay($event: any) {
        if ($event.target.value) {
            this.saveDefaultDepartmentsDisplay($event.target.value);
            this.gotoDisplayId($event.target.value);
        }
    }

    public clickDisplayListener(displayLink: DisplayLink) {
        const departmentDisplay = this.getDepartmentDisplayFromDisplayId(displayLink?.display?.id, displayLink.department.id);
        // console.log('clickDisplayListener : displayLink : ', displayLink, 'getDepartmentDisplayFromDisplayId : ', departmentDisplay)

        this.saveDefaultDepartmentsDisplay(departmentDisplay.id);
    }

    private saveDefaultDepartmentsDisplay(id: number) {
        this.displayUser.default_displays_department_id = id;
        this.displayUser.patch(['default_displays_department_id']);
        this.getDisplayFromDepartmentDisplayId(this.displayUser.default_displays_department_id);
    }

    public gotoDisplayId(departmentsDisplayId: number) {
        this.displayService.getDisplaysWithoutSettings(displays => {
            const display = displays.find(display => {
                const hasId = display.departments_displays?.find(departmentsDisplay => departmentsDisplay.id == departmentsDisplayId);
                const hasType = display.display_type_id != DisplayTypes.LoadSummary;
                return hasId && hasType;
            });
            this.displayReady = true;
            if (display) {
                const departmentsDisplay = display.departments_displays?.find(departmentsDisplay => departmentsDisplay.id == departmentsDisplayId);
                if (departmentsDisplay) {
                    setTimeout(() => {
                        display.navigate(this.displayUser, departmentsDisplay);
                    }, 0);
                } else {
                    this.showList = true;
                    this.displayReady = true;
                }
            } else {
                this.showList = true;
                this.displayUser.default_displays_department_id = null;
                this.saveDefaultDepartmentsDisplay(null);
            }
        });
    }

    // </editor-fold>

    logout(): void {
        this.authService.logout();
    }

    updateVersion() {
        this.dialogService.confirm(this.translateService.instant('_ui_reset_cookies'), this.translateService.instant('_ui_reset_cookies_description')).then((r) => {
            if (r) {
                this.dbService.clear(System.DBCache).subscribe((r) => {
                    document.location.reload();
                })
                localStorage.clear();

            }
        })
    }
}
