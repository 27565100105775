/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {PhaseDefinition} from './definitions/PhaseDefinition';
import {Api} from "@app/core/Api";
import {PhaseProgressType} from "@app/core/models/index";
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";
import {System} from "@app/constants";

export class Phase extends PhaseDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static createDefault() {
        let item = new Phase();
        // item.id = 0;
        item.changeable_date = false;
        return item;
    }

    // <editor-fold desc="Cache">

    private static DataCache?: ApiDataCache<Phase>;

    private static GetDataCache(): ApiDataCache<Phase> {
        if (!this.DataCache) {
            this.DataCache = new ApiDataCache<Phase>(
                System.Phases,
                2 * 60 * 60, // 2 hours
                Api.phases().get()
                    .include('phase_progress_types_phase?include=phase_progress_type')
                    .include('color')
                    .orderBy('index_', 'asc')
            );
        }
        return this.DataCache;
    }

    public static ClearCache() {
        Phase.GetDataCache().clear();
        Phase.GetDataCache().get(() => {
        });
    }

    public static GetAll(callback: (items: Phase[]) => void) {
        Phase.GetDataCache().get(callback);
    }

    public static GetSingle(phaseId: number, callback: (item: Phase) => void) {
        this.GetAll(items => callback(items.find(item => item.id == phaseId)));
    }

    // </editor-fold>

    private visiblePhaseProgressTypesCache: PhaseProgressType[];

    public getVisiblePhaseProgressTypes(): PhaseProgressType[] {
        if (!this.visiblePhaseProgressTypesCache) {
            this.visiblePhaseProgressTypesCache = this.phase_progress_types_phases
                ?.filter(phaseProgressTypesPhase => phaseProgressTypesPhase.visible && phaseProgressTypesPhase.phase_progress_type?.exists())
                ?.map(phaseProgressTypesPhase => phaseProgressTypesPhase.phase_progress_type);
        }
        return this.visiblePhaseProgressTypesCache;
    }

    public post(callback?: (response: any) => void) {
        Api.phases().post().save(this, response => {
            this.populate(response);
            if (callback) {
                callback(this);
            }
        })
    }

}
