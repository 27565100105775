import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Task, User} from "@app/core/models";
import {BaseModalComponent} from "@app/shared/_modals/base-modal.component";
import {TranslateService} from "@ngx-translate/core";
import moment from "moment";
import {TaskUserTypes} from "@app/constants";

@Component({
    selector: 'app-move-task-planning-date-multiple-users-dialog',
    templateUrl: './move-task-planning-date-multiple-users-dialog.component.html',
    styleUrls: ['./move-task-planning-date-multiple-users-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    standalone: false
})
export class MoveTaskPlanningDateMultipleUsersDialogComponent extends BaseModalComponent implements OnInit {

    // Bindings to parent
    @Input() task: Task;
    @Input() defaultUser: User;
    @Input() date: Date;
    @Output() onSaveEventEmitter = new EventEmitter<User[]>();
    @Output() onCancelEventEmitter = new EventEmitter();

    // Bindings to view
    public title = '';
    public isLoading = false;
    public users: { user: User, isSelected: boolean }[] = [];
    public isAllSelected = false;

    // Data

    constructor(private modal: NgbActiveModal,
                private translationService: TranslateService,) {
        super(modal);
    }

    ngOnInit() {
        super.ngOnInit();
        this.title = this.translationService.instant('_ui_dialog_more_participants');
        this.message = this.translationService.instant(
            '_ui_dialog_more_participants_description',
            {date: moment(this.date).format('DD/MM-YYYY')}
        );

        this.users = this.task.tasks_users
            ?.filter(tasksUser => tasksUser.task_user_type_id == TaskUserTypes.Participant)
            ?.map(tasksUser => ({
                user: tasksUser.user,
                isSelected: false,
            })) ?? [];
        this.users.find(user => user.user.id == this.defaultUser.id).isSelected = true;
    }

    closeForm() {
        this.activeModal.close();
    }

    private checkForAllSelected() {
        this.isAllSelected = !this.users.some(user => !user.isSelected);
    }

    public onToggleAllBtnClicked() {
        this.users.forEach(user => user.isSelected = !this.isAllSelected);
        this.checkForAllSelected();
    }

    public onCloseBtnClicked() {
        this.closeForm();
        this.onCancelEventEmitter.emit();
    }

    public onSaveBtnClicked() {
        this.closeForm();
        this.onSaveEventEmitter.emit(this.users
            .filter(user => user.isSelected)
            .map(user => user.user));
    }

}
