import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {TodoFetcher, TodoFetcherRequest} from "@app/shared/_ui/lists/todo-list/TodoFetcher";
import {TodoListTableColumn} from "@app/pages/displays/display-projects/TableColumns/TodoListTableColumn";
import {TodoListCell} from "@app/pages/displays/display-projects/Cells/TodoListCell";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {
    Projects_TodoListIncludeArchivedSinceSettingValue
} from "@app/core/Api";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";

export class TodoListColumn extends GenericColumn<TodoListCell> implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TodoFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TodoFetcher(column.name);
    }

    public createTableColumns(): TodoListTableColumn[] {
        const item = new TodoListTableColumn(this);
        this.setTableColumnDefaults(item);
        return [item];
    }

    public createCell(row: ProjectsDisplayRow): TodoListCell {
        const cell = new TodoListCell(row, this);

        this.dataFetcher.addRequest(new TodoFetcherRequest(cell.listConfiguration));
        return cell;
    }

    public getArchivedSinceSetting(): number {
        return this.settings.get(ColumnTypeSettings.TodoListIncludeArchivedSince)
            ?.getObject<Projects_TodoListIncludeArchivedSinceSettingValue>()
            ?.days ?? 0;
    }

    public implementsCSVExport = true;

}
