{
	"APP_NAME": "KlartBoard",

	"da-DK": "Dansk",
	"en-US": "Engelsk",
    "nb": "Bokmål",
    "nn": "Nynorsk",
	"sv-SE": "Svensk",
	"SiteNotConfigured": "Sitet er ikke konfigureret.",
	"Version": "Version",
	"development-server": "Udviklingsserver",

	"_ui_logged_out_title": "Din bruger blev logget ud",
	"_ui_logged_out_description": "Din bruger har været inaktiv i en længere periode og er derfor blevet logget ud",

	"LAST_ENTRY": ""
}
