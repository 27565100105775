import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {YearWheelCell} from "@app/pages/displays/display-projects/Cells/YearWheelCell";
import {YearWheelFetcher, YearWheelFetcherRequest} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelFetcher";
import {YearWheelTableColumn} from "@app/pages/displays/display-projects/TableColumns/YearWheelTableColumn";
import {YearWheelOptions, YearWheelService} from "@app/services/year-wheel.service";
import {AppInjector} from "@app/services/app-injector.service";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {Projects_YearWheelProjectDeadlineTypesSettingValue} from "@app/core/Api";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";

export class YearWheelColumn extends GenericColumn<YearWheelCell> implements ColumnDataFetcherInterface {

    public yearWheelPeriod: { start: Date, end: Date };
    public yearWheelDisplayOptions: YearWheelOptions;

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: YearWheelFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new YearWheelFetcher(column.name);

        this.yearWheelDisplayOptions = AppInjector.getInjector().get(YearWheelService).getSettings();
    }

    public createTableColumns(): YearWheelTableColumn[] {
        const item = new YearWheelTableColumn(this);
        this.setTableColumnDefaults(item);
        return [item];
    }

    public createCell(row: ProjectsDisplayRow): YearWheelCell {
        const cell = new YearWheelCell(row, this);

        cell.yearWheelConfiguration
            .setPeriodUnit(this.yearWheelDisplayOptions.unit)
            .setDisplayOptions(this.yearWheelDisplayOptions);
        if (this.yearWheelPeriod) {
            cell.yearWheelConfiguration
                .setStart(this.yearWheelPeriod.start)
                .setEnd(this.yearWheelPeriod.end);
        }

        this.dataFetcher.addRequest(new YearWheelFetcherRequest(cell.yearWheelConfiguration));
        return cell;
    }

    public getProjectDeadlineTypeStartSetting(): number {
        return this.settings.get(ColumnTypeSettings.YearWheelProjectDeadlineTypes)
            ?.getObject<Projects_YearWheelProjectDeadlineTypesSettingValue>()
            ?.startProjectDeadlineTypeId ?? 0;
    }

    public getProjectDeadlineTypeEndSetting(): number {
        return this.settings.get(ColumnTypeSettings.YearWheelProjectDeadlineTypes)
            ?.getObject<Projects_YearWheelProjectDeadlineTypesSettingValue>()
            ?.endProjectDeadlineTypeId ?? 0;
    }

    public setPeriod(start: Date, end: Date) {
        this.yearWheelPeriod = {
            start: start,
            end: end,
        };
    }

}
