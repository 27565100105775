import {Filters} from "@app/pages/displays/display-team/Helpers/Filters";
import {GenericColumn} from "@app/pages/displays/display-team/Columns/GenericColumn";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {ColumnTypeSettings} from "@app/pages/displays/display-team/Helpers/ColumnTypeSettings";
import {
    Team_TaskListSoftThisWeekTaskTypesSettingValue,
} from "@app/core/Api";
import {DisplayTeamRow} from "@app/pages/displays/display-team/DisplayTeamRow";
import {TaskListCell} from "@app/pages/displays/display-team/Cells/TaskListCell";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import moment from "moment";
import {
    TaskListSoftThisWeekTableColumn
} from "@app/pages/displays/display-team/TableColumns/TaskListSoftThisWeekTableColumn";
import {TaskListSoftThisWeekCell} from "@app/pages/displays/display-team/Cells/TaskListSoftThisWeekCell";

export class TaskListSoftThisWeekColumn extends GenericColumn<TaskListCell> implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [this.dataFetcher];
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TaskFetcher(
            undefined, undefined, undefined,
            column.name
        );
        this.dataFetcher.setTaskTypeIds(this.getTaskTypeIds());
    }

    public createTableColumns(): TaskListSoftThisWeekTableColumn[] {
        const item = new TaskListSoftThisWeekTableColumn(this);
        this.setTableColumnDefaults(item);
        return [item];
    }

    public createCell(row: DisplayTeamRow): TaskListSoftThisWeekCell {
        const cell = new TaskListSoftThisWeekCell(row, this);

        cell.listConfiguration
            .setSoftDeadline(true)
            .setTaskTypeIds(this.getTaskTypeIds())
            .addOnScreenFilters(this.columnType.onScreenFilters);

        this.dataFetcher.addRequest(new TaskFetchRequest(cell.listConfiguration));
        return cell;
    }

    public getOrderBy(type: string, direction: string): any[] {
        let orderBy: any[][] = [];
        switch (type) {
            case Filters.TaskColumn_Sort_Title:
                orderBy.push(['title', direction]);
                break;
            case Filters.TaskColumn_Sort_Status:
                orderBy.push(['main_status.status_id', direction]);
                break;
            case Filters.TaskColumn_Sort_Deadline:
                orderBy.push(['tasks_deadline.deadline.date', 'null'], ['tasks_deadline.deadline.date', direction]);
                break;
            case Filters.TaskColumn_Sort_PlanningDate:
                orderBy.push(['tasks_user.deadline.date', direction]);
                break;
        }
        return orderBy;
    }

    public getTaskTypeIds(): number[] {
        return this.settings.get(ColumnTypeSettings.TaskList_SoftThisWeek_TaskTypes)
            ?.getObject<Team_TaskListSoftThisWeekTaskTypesSettingValue>()
            ?.taskTypeIds ?? [];
    }

    public setPeriod(start: Date, end: Date): void {
        this.dataFetcher.setPeriod(
            moment(start).startOf('isoWeek').toDate(),
            moment(end).endOf('isoWeek').toDate()
        );
    }

}
