import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {Roster, Task} from '@app/core/models/Task';
import {Project} from '@app/core/models/Project';
import {Log} from '@app/core/models/Log';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {
    Api,
    MilestonesLogsGetByMilestoneId,
    ProjectsLogsGetByProjectId,
    TasksLogsGetByTaskId
} from "@app/core/Api";
import {Milestone} from "@app/core/models";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";

interface LogItem {
    logs: Log[];
    activeLog: Log;
    showDetails: boolean;
}

@Component({
    selector: 'app-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LogsComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() item: AnyItem;
    @Input() refresh = false;
    @Input() openEventEmitter: EventEmitter<boolean>;

    // Bindings to view
    public isLoading: boolean;
    public itemsCount: number = 0;
    public logs: Log[] = [];
    public logItemList: LogItem[] = [];

    // Data
    private offset = 0;
    private limit = 50;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        if (this.refresh) {
            this.loadData(this.offset, true);
        }

        this.subscribe(this.openEventEmitter?.subscribe(() => {
            this.logs = [];
            this.populateLogItems();
            this.loadData(0, true);
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes['refresh']) {
            if (this.refresh) {
                this.loadData(this.offset, true);
            }
        }
    }

    expand() {
        this.loadData(this.offset + this.limit);
    }

    private apiLoader?: ApiRequest;

    private loadData(offset: number, count: boolean = false) {
        if (this.isLoading) return;
        this.isLoading = true;

        this.apiLoader?.cancel();

        let api: ProjectsLogsGetByProjectId | TasksLogsGetByTaskId | MilestonesLogsGetByMilestoneId;
        switch (this.item.constructor) {
            case Project:
                api = Api.projects().logsGetByProjectId(this.item.id);
                break;
            case Task:
            case Roster:
                api = Api.tasks().logsGetByTaskId(this.item.id);
                break;
            case Milestone:
                api = Api.milestones().logsGetByMilestoneId(this.item.id);
                break;
        }

        if (api) {
            this.apiLoader = api.orderBy('id', 'desc')
                .offset(offset)
                .limit(this.limit)
                .find(logs => {
                    this.logs = this.logs.concat(logs);
                    this.populateLogItems();
                    this.offset = offset;
                    this.isLoading = false;
                    this.detectChanges();
                });

            if (count) {
                api.count(count => {
                    this.itemsCount = count;
                    this.detectChanges();
                });
            }
        } else {
            this.isLoading = false;
            this.detectChanges();
        }

    }

    private populateLogItems() {
        // loop through logs and populate log items. check log.type and group them if the next type is the same
        this.logItemList = [];
        let logItem: LogItem = {
            logs: [],
            activeLog: null,
            showDetails: false
        }
        this.logs
        .filter(l => l.description != '')
        .forEach(log => {
            this.formatLog(log);
            if (logItem.logs.length == 0) {
                logItem.logs.push(log);
            } else {
                if (logItem.logs[0].type == log.type) {
                    logItem.logs.push(log);
                } else {
                    this.logItemList.push(logItem);
                    logItem = {
                        logs: [],
                        activeLog: null,
                        showDetails: false
                    }
                    logItem.logs.push(log);
                }
            }
        });

        // loop through all logitemlist and set active log to the first log in the list
        this.logItemList.forEach(logItem => {
            logItem.activeLog = logItem.logs[0];
        });
    }

    formatLog(log: Log) {
        // log.action example: "{\"field\":\"Noter\",\"prop\":\"notes\",\"prev\":\"<p>Note med kort beskrivelse<\\/p>\",\"next\":\"<p>Note med l\\u00e6ngere beskrivelse<\\/p>\"}"
        const logData = JSON.parse(log.action);
        // console.log('logData : ', logData);
        switch (log.type) {
            case 'project-status-updated':
            case 'milestone-updated-status':
            case 'task-status-updated':
            case 'todo-status-updated':
                log.description = log.description.replace(/\b(red|green|yellow|grey)\b/g, function(match, color) {
                    var classes = {
                        'red': 'bg-danger',
                        'green': 'bg-success',
                        'yellow': 'bg-warning',
                        'grey': 'bg-secondary'
                    };
                    return '<span class="badge circle ' + (classes as any)[color] + '"></span>';
                });
                break;
        }
    }
}
