import {ApplicationRef, enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from '@app/app.module';
import {environment} from '@env/environment';
import {enableDebugTools} from '@angular/platform-browser';
import {hmrBootstrap} from './hmr';
/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

if (environment.production) {
    enableProdMode();
}


// Injector: https://blogs.msdn.microsoft.com/premier_developer/2018/06/17/angular-how-to-simplify-components-with-typescript-inheritance/

/*
platformBrowserDynamic().bootstrapModule(AppModule).then((moduleRef) => {
    AppInjector.setInjector(moduleRef.injector);
}).catch(err => console.log('Main.ts Error : ',err));
*/

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

bootstrap().then(moduleRef => {
    // https://stackoverflow.com/questions/53329924/angular-7-service-worker-not-registered
    if ('serviceWorker' in navigator && environment.production) {
        navigator.serviceWorker.register('/ngsw-worker.js');
    }

    if (!environment.production) {
        const applicationRef = moduleRef.injector.get(ApplicationRef);
        const componentRef = applicationRef.components[0];
        // allows to run `ng.profiler.timeChangeDetection();`
        enableDebugTools(componentRef); // https://blog.ninja-squad.com/2018/09/20/angular-performances-part-3/
    }

}).catch(err => console.log('Main.ts Error : ', err));

if (environment.hmr) {
    if ((module as any)['hot']) {
        hmrBootstrap(module, bootstrap);
    } else {
        // console.error('HMR is not enabled for webpack-dev-server!');
        // console.log('Are you using the --hmr flag for ng serve?');
    }
}
