/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TagDefinition} from './definitions/TagDefinition';
import {ApiDataCache} from '@app/core/DataCache/ApiDataCache';
import {System} from '@app/constants';
import {Api} from '@app/core/Api';

export class Tag extends TagDefinition {

    constructor(json?: any) {
        super(json);
    }

    private static DataCache?: ApiDataCache<Tag>;

    private static GetDataCache(): ApiDataCache<Tag> {
        if (!this.DataCache) {
            this.DataCache = new ApiDataCache<Tag>(
                System.Tags,
                2 * 60 * 60, // 2 hours
                Api.tags()
                    .get()
                    .orderBy('name', 'asc')
            );
        }
        return this.DataCache;
    }

    public static GetAll(callback: ((items: Tag[]) => void)) {
        this.GetDataCache().get(callback);
    }

    public static GetById(id: number, callback: (item: Tag) => void) {
        Tag.GetAll(items => {
            callback(items.find(item => item.id == id));
        });
    }

    public static ClearCache(finished: () => void) {
        this.GetDataCache().clear(finished);
    }

}
