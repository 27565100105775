import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LoadingIndicatorComponent implements OnInit {

    @Input() scale: number = 1.0;
    @Input() showLogo: boolean = true;

    constructor() {
    }

    ngOnInit() {
    }

}
