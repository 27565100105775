import {Filter} from '@app/pages/displays/filtering/Filter';
import {Sort} from '@app/pages/displays/filtering/Sort';
import {Period} from '@app/pages/displays/filtering/Period';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {Display} from '@app/core/models/Display';
import {AppInjector} from '@app/services/app-injector.service';
import moment from 'moment';
import {DisplayFilterEditorFormConfigInterface} from '@app/editor/display-filter-editor/DisplayFilterEditorForm';
import {TranslateService} from '@ngx-translate/core';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {CategoryType, TaskDeadlineType} from "@app/core/models";
import {CountRunner} from "@app/core/CountRunner/CountRunner";

export class Filters implements FiltersInterface {

    public autoGenerateNameDefaultValue(): boolean {
        return true;
    }

    public applyDefaults(displayFilter: DisplayFilter) {
        displayFilter.filter_type = Filters.FilterColumnsOnly;
    }

    // Filters
    public static FilterColumnsOnly = 'filter-columns-only';

    // Sorts (Tasks)
    public static SortTasksTitle = 'sort-tasks-title';
    public static SortTasksDeadline = 'sort-tasks-deadline-';

    public static SortTasksDeadlineGenerator(deadlineType: TaskDeadlineType): string {
        return `${Filters.SortTasksDeadline}${deadlineType.id}`;
    };

    public static ParseSortTasksDeadlineType(sort: string): number {
        return parseInt(sort.substr(Filters.SortTasksDeadline.length));
    };

    public static SortTasksPlanningDate = 'sort-tasks-planning-date';
    public static SortTasksStatus = 'sort-tasks-status';
    public static SortTasksStars = 'sort-tasks-stars';
    public static SortTasksHands = 'sort-tasks-hands';
    public static SortTasksCategoryType = 'sort-tasks-category-type-';

    public static SortTasksCategoryTypeGenerator(categoryType: CategoryType): string {
        return `${Filters.SortTasksCategoryType}${categoryType.id}`;
    };

    public static ParseSortTasksCategoryType(sort: string): number {
        return parseInt(sort.substr(Filters.SortTasksCategoryType.length));
    };

    public static GetBaseSort(sort: string): string {
        // Remove any identifiers from the end
        return sort.replace(/[0-9]/g, '');
    }

    // Periods
    public static PeriodUserDefined = 'user-defined';
    public static PeriodLastWeek = 'last-week';
    public static PeriodToday = 'today';
    public static PeriodThisWeek = 'this-week';
    public static PeriodNext2Week = 'next-2-weeks';
    public static PeriodNextMonth = 'next-month';

    // Year Wheel Periods
    public static YearWheelPeriodUserDefined = 'user-defined';
    public static YearWheelPeriodThisWeek = 'this-week';
    public static YearWheelPeriodNext1Week = 'next-1-week';
    public static YearWheelPeriodNext2Week = 'next-2-weeks';
    public static YearWheelPeriodNext4Week = 'next-4-weeks';
    public static YearWheelPeriodNext8Week = 'next-8-weeks';
    public static YearWheelPeriodThisMonth = 'this-month';
    public static YearWheelPeriodFollowingMonth = 'following-month';
    public static YearWheelPeriodNextMonth = 'next-month';
    public static YearWheelPeriodNext3Month = 'next-3-months';
    public static YearWheelPeriodNext6Month = 'next-6-months';
    public static YearWheelPeriodNext12Month = 'next-12-months';
    public static YearWheelPeriodThisQuarter = 'this-quarter';
    public static YearWheelPeriodNextQuarter = 'next-quarter';
    public static YearWheelPeriodThisHalfYear = 'this-half-year';
    public static YearWheelPeriodNextHalfYear = 'next-half-year';
    public static YearWheelPeriodThisYear = 'this-year';
    public static YearWheelPeriodNextYear = 'next-year';

    public filters(display: Display): Filter[] {
        const all = new Filter(
            Filters.FilterColumnsOnly,
            this.getTranslation('_ui_all'),
        );
        all.showColumns = true;


        const allFilters = [
            all,
        ];

        return allFilters;
    }

    private getTranslation(key: string, interpolateParams?: Object): string {
        return AppInjector.getInjector().get(TranslateService).instant(key, interpolateParams);
    }

    public sorts(display: Display, includePseudoTypes = false): Sort[] {
        const sorts = [];

        if (includePseudoTypes) {
            sorts.push(
                new Sort(Filters.SortTasksTitle, this.getTranslation('_task_title'), true),
                new Sort(Filters.SortTasksPlanningDate, this.getTranslation('_ui_filter_sort_planning_date'), true),
                new Sort(Filters.SortTasksStatus, this.getTranslation('_ui_status'), true),
                new Sort(Filters.SortTasksStars, this.getTranslation('_ui_filter_sort_stars'), true),
                new Sort(Filters.SortTasksHands, this.getTranslation('_ui_filter_sort_hands'), true),
            );

            const config = this.getEditorConfig(display);

            sorts.push(...config.categoryTypes?.map(categoryType => new Sort(
                Filters.SortTasksCategoryTypeGenerator(categoryType),
                `${categoryType.name} ${this.getTranslation('_filter_show_only', {name: categoryType.name.toLocaleLowerCase()})}`,
                true
            )) ?? []);

            sorts.push(...config.taskDeadlineTypes?.map(tasksDeadlineType => new Sort(
                Filters.SortTasksDeadlineGenerator(tasksDeadlineType),
                `${tasksDeadlineType.name} ${this.getTranslation('_filter_show_only', {name: tasksDeadlineType.name.toLocaleLowerCase()})}`,
                true
            )) ?? []);
        }

        return sorts;
    }

    public getSort(display: Display, sort: string): Sort {
        return this.sorts(display).find(item => item.id == sort);
    }

    public periods(): Period[] {
        return [
            new Period(Filters.PeriodUserDefined, this.getTranslation('_ui_date_userdefined'), true),
            new Period(Filters.PeriodLastWeek, this.getTranslation('_ui_date_last_week')),
            new Period(Filters.PeriodToday, this.getTranslation('_ui_date_today')),
            new Period(Filters.PeriodThisWeek, this.getTranslation('_ui_date_this_week')),
            new Period(Filters.PeriodNext2Week, this.getTranslation('_ui_date_next_2_weeks')),
            new Period(Filters.PeriodNextMonth, this.getTranslation('_ui_date_next_month')),
        ];
    }

    public yearWheelPeriods(): Period[] {
        return [
            new Period(Filters.YearWheelPeriodUserDefined, this.getTranslation('_ui_date_userdefined'), true),
            new Period(Filters.YearWheelPeriodThisWeek, this.getTranslation('_ui_date_this_week')),
            new Period(Filters.YearWheelPeriodNext1Week, this.getTranslation('_ui_date_next_1_week')),
            new Period(Filters.YearWheelPeriodNext2Week, this.getTranslation('_ui_date_next_2_weeks')),
            new Period(Filters.YearWheelPeriodNext4Week, this.getTranslation('_ui_date_next_4_weeks')),
            new Period(Filters.YearWheelPeriodNext8Week, this.getTranslation('_ui_date_next_8_weeks')),
            new Period(Filters.YearWheelPeriodThisMonth, this.getTranslation('_ui_date_this_month')),
            new Period(Filters.YearWheelPeriodFollowingMonth, this.getTranslation('_ui_date_following_month')),
            new Period(Filters.YearWheelPeriodNextMonth, this.getTranslation('_ui_date_next_month')),
            new Period(Filters.YearWheelPeriodNext3Month, this.getTranslation('_ui_date_next_3_months')),
            new Period(Filters.YearWheelPeriodNext6Month, this.getTranslation('_ui_date_next_6_months')),
            new Period(Filters.YearWheelPeriodNext12Month, this.getTranslation('_ui_date_next_12_months')),
            new Period(Filters.YearWheelPeriodThisQuarter, this.getTranslation('_ui_date_this_quarter')),
            new Period(Filters.YearWheelPeriodNextQuarter, this.getTranslation('_ui_date_next_quarter')),
            new Period(Filters.YearWheelPeriodThisHalfYear, this.getTranslation('_ui_date_this_half_year')),
            new Period(Filters.YearWheelPeriodNextHalfYear, this.getTranslation('_ui_date_next_half_year')),
            new Period(Filters.YearWheelPeriodThisYear, this.getTranslation('_ui_date_this_year')),
            new Period(Filters.YearWheelPeriodNextYear, this.getTranslation('_ui_date_next_year')),
        ];
    }

    public getPeriodStart(period: string, sourceDate?: Date): Date {
        let date = moment();
        switch (period) {
            case Filters.PeriodLastWeek:
                date.startOf('isoWeek').subtract(1, 'week');
                break;
            case Filters.PeriodToday:
                date.startOf('day');
                break;
            case Filters.PeriodThisWeek:
                date.startOf('isoWeek');
                break;
            case Filters.PeriodNext2Week:
                date.startOf('day');
                break;
            case Filters.PeriodNextMonth:
                date.startOf('day');
                break;
        }
        return date.toDate();
    }

    public getPeriodEnd(period: string, sourceDate?: Date): Date {
        let date = moment();
        switch (period) {
            case Filters.PeriodLastWeek:
                date.endOf('isoWeek').subtract(1, 'week');
                break;
            case Filters.PeriodToday:
                date.endOf('day');
                break;
            case Filters.PeriodThisWeek:
                date.endOf('isoWeek');
                break;
            case Filters.PeriodNext2Week:
                date.endOf('day').add(2, 'weeks');
                break;
            case Filters.PeriodNextMonth:
                date.endOf('day').add(1, 'month');
                break;
        }
        return date.toDate();
    }

    public getYearWheelPeriodStart(period: string, customDate?: string): Date {
        let date = moment();
        switch (period) {
            case Filters.YearWheelPeriodThisWeek:
                date.startOf('isoWeek');
                break;
            case Filters.YearWheelPeriodNext1Week:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNext2Week:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNext4Week:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNext8Week:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodThisMonth:
                date.startOf('month');
                break;
            case Filters.YearWheelPeriodFollowingMonth:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNextMonth:
                date.startOf('month').add(1, 'month');
                break;
            case Filters.YearWheelPeriodNext3Month:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNext6Month:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodNext12Month:
                date.startOf('day');
                break;
            case Filters.YearWheelPeriodThisQuarter:
                date.startOf('quarter');
                break;
            case Filters.YearWheelPeriodNextQuarter:
                date.startOf('quarter').add(1, 'quarter');
                break;
            case Filters.YearWheelPeriodThisHalfYear:
                if (date.month() <= 5) {
                    date.startOf('year');
                } else {
                    date.startOf('year').add(6, 'month');
                }
                break;
            case Filters.YearWheelPeriodNextHalfYear:
                if (date.month() <= 5) {
                    date.startOf('year').add(6, 'month');
                } else {
                    date.startOf('year').add(1, 'year');
                }
                break;
            case Filters.YearWheelPeriodThisYear:
                date.startOf('year');
                break;
            case Filters.YearWheelPeriodNextYear:
                date.startOf('year').add(1, 'year');
                break;
            case Filters.YearWheelPeriodUserDefined:
                if (customDate) {
                    return new Date(customDate);
                } else {
                    return null;
                }
        }
        return date.toDate();
    }

    public getYearWheelPeriodEnd(period: string, customDate?: string): Date {
        let date = moment();
        switch (period) {
            case Filters.YearWheelPeriodThisWeek:
                date.endOf('isoWeek');
                break;
            case Filters.YearWheelPeriodNext1Week:
                date.subtract(1, 'day').endOf('day').add(1, 'weeks');
                break;
            case Filters.YearWheelPeriodNext2Week:
                date.subtract(1, 'day').endOf('day').add(2, 'weeks');
                break;
            case Filters.YearWheelPeriodNext4Week:
                date.subtract(1, 'day').endOf('day').add(4, 'weeks');
                break;
            case Filters.YearWheelPeriodNext8Week:
                date.subtract(1, 'day').endOf('day').add(8, 'weeks');
                break;
            case Filters.YearWheelPeriodThisMonth:
                date.endOf('month');
                break;
            case Filters.YearWheelPeriodFollowingMonth:
                date.endOf('day').add(1, 'month');
                break;
            case Filters.YearWheelPeriodNextMonth:
                date.endOf('month').add(1, 'month');
                break;
            case Filters.YearWheelPeriodNext3Month:
                date.endOf('month').add(3, 'month');
                break;
            case Filters.YearWheelPeriodNext6Month:
                date.endOf('month').add(6, 'month');
                break;
            case Filters.YearWheelPeriodNext12Month:
                date.endOf('month').add(12, 'month');
                break;
            case Filters.YearWheelPeriodThisQuarter:
                date.endOf('quarter');
                break;
            case Filters.YearWheelPeriodNextQuarter:
                date.endOf('quarter').add(1, 'quarter');
                break;
            case Filters.YearWheelPeriodThisHalfYear:
                if (date.month() <= 5) {
                    date.endOf('year').subtract(6, 'month');
                } else {
                    date.endOf('year');
                }
                break;
            case Filters.YearWheelPeriodNextHalfYear:
                if (date.month() <= 5) {
                    date.endOf('year');
                } else {
                    date.endOf('year').add(6, 'month');
                }
                break;
            case Filters.YearWheelPeriodThisYear:
                date.endOf('year');
                break;
            case Filters.YearWheelPeriodNextYear:
                date.endOf('year').add(1, 'year');
                break;
            case Filters.YearWheelPeriodUserDefined:
                if (customDate) {
                    return new Date(customDate);
                } else {
                    return null;
                }
        }
        return date.toDate();
    }

    public standards(display: Display): DisplayFilter[] {
        let standards: DisplayFilter[] = [];

        let allByTitle = new DisplayFilter();
        allByTitle.display_id = display.id;
        allByTitle.display = display;
        allByTitle.filter_type = Filters.FilterColumnsOnly;
        allByTitle.statuses = [];
        allByTitle.sort_direction = 'asc';
        standards.push(allByTitle);

        standards.forEach(item => {
            item.name = item.generateName(this);
        });

        return standards;
    }

    public generateName(displayFilter: DisplayFilter): string {
        let start;
        let status;
        let period;

        switch (displayFilter.filter_type) {
            case Filters.FilterColumnsOnly:
                start = this.getTranslation('_ui_all');
                break;
        }

        let result = start;

        // Status
        const statuses = displayFilter.statuses?.map(status => status.name().toLocaleLowerCase()) ?? [];
        if (statuses.length < 4 && statuses.length > 0) {
            if (statuses.length > 1) {
                const lastStatus = statuses.pop();
                status = this.getTranslation('_ui_filter_status_many', {
                    status1: statuses.join(', '),
                    status2: lastStatus
                });
            } else if (statuses.length == 1) {
                status = this.getTranslation('_ui_filter_status_single', {status: statuses.join(', ')});
            } else {
                status = this.getTranslation('_ui_filter_status_none');
            }
            result += ' ' + status;
        }

        // Period
        switch (displayFilter.period_type) {
            case Filters.PeriodUserDefined:
                period = this.getTranslation('_ui_filter_deadline_custom', {
                    start: displayFilter.getPeriodStartString(),
                    end: displayFilter.getPeriodEndString()
                });
                break;
            case Filters.PeriodLastWeek:
                period = this.getTranslation('_ui_date_last_week').toLocaleLowerCase();
                break;
            case Filters.PeriodToday:
                period = this.getTranslation('_ui_date_today').toLocaleLowerCase();
                break;
            case Filters.PeriodThisWeek:
                period = this.getTranslation('_ui_date_this_week').toLocaleLowerCase();
                break;
            case Filters.PeriodNext2Week:
                period = this.getTranslation('_ui_date_next_2_weeks').toLocaleLowerCase();
                break;
            case Filters.PeriodNextMonth:
                period = this.getTranslation('_ui_date_next_month').toLocaleLowerCase();
                break;
        }

        if (period?.length) {
            result += ' ' + period;
        }

        // Sort

        if (displayFilter.starred) {
            result += ', ' + this.getTranslation('_ui_starred').toLocaleLowerCase();
        }
        if (displayFilter.hand_up) {
            result += ', ' + this.getTranslation('_ui_hand_up').toLocaleLowerCase();
        }

        return result;
    }

    public getEditorConfig(display: Display, result?: (config: DisplayFilterEditorFormConfigInterface) => void): DisplayFilterEditorFormConfigInterface {
        const config: DisplayFilterEditorFormConfigInterface = {};

        if (display == null) {
            // Not fit for editor config
            if (result) {
                result(config);
            }
            return config;
        }

        config.showYearWheelPeriod = true;

        const runner = new CountRunner(2);
        runner.setRunner(() => {
            if (result) {
                result(config);
            }
        });
        runner.start();

        CategoryType.GetAll(items => {
            config.categoryTypes = items;
            runner.tick();
        });

        TaskDeadlineType.GetAll(items => {
            config.taskDeadlineTypes = items;
            runner.tick();
        })

        return config;
    }

}
