import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {
    Projects_ProjectDeadlineTypeFromAndToSettingValue,
} from "@app/core/Api";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {ProjectDeadlineDifferenceCell} from "@app/pages/displays/display-projects/Cells/ProjectDeadlineDifferenceCell";

export class ProjectDeadlineDifferenceColumn extends GenericColumn {

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectDeadlineDifferenceCell(row, this);
    }

    public getFromDeadlineTypeId(): number {
        return this.settings.get(ColumnTypeSettings.ProjectDeadlineTypeFromAndTo)
            ?.getObject<Projects_ProjectDeadlineTypeFromAndToSettingValue>()
            ?.fromProjectDeadlineTypeId ?? 0;
    }

    public getToDeadlineTypeId(): number {
        return this.settings.get(ColumnTypeSettings.ProjectDeadlineTypeFromAndTo)
            ?.getObject<Projects_ProjectDeadlineTypeFromAndToSettingValue>()
            ?.toProjectDeadlineTypeId ?? 0;
    }

    public implementsCSVExport = true;

}
