import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {fadeInPlaceAnimation} from '@app/animations';

@Component({
    selector: 'app-numeric-spinner',
    templateUrl: './numeric-spinner.component.html',
    styleUrls: ['./numeric-spinner.component.scss'],
    animations: [fadeInPlaceAnimation],
    standalone: false
})
export class NumericSpinnerComponent implements OnInit {

    @Input() fullWidth: boolean = false;
    @Input() showColors: boolean = false;
    @Input() value: number;
    @Input() target: number;
    @Input() label: string;
    @Input() icon: string;
    @Output() public onChange: EventEmitter<number> = new EventEmitter<number>();

    public displayValue: number = 0;

    private focused = false;

    public oldValue: number;
    public editmode: boolean = false;

    private _valueChanged: Subject<number> = new Subject<number>();

    constructor() {
        this._valueChanged
            .pipe(debounceTime(200), distinctUntilChanged()) // wait 1 sec after the last event before emitting last event &&  only emit if value is different from previous value
            .subscribe((value: number) => {
                this.displayValue = this.value = value;
                this.oldValue = value;
                // Call your function which calls API or do anything you would like do after a lag of 1 sec
                this.onChange.emit(this.value);
            });
    }

    ngOnInit() {
        this.displayValue = this.value;
    }

    changed(query: number) {
        this._valueChanged.next(query);
    }

    triggerFocus() {
        this.focused = true;
        this.oldValue = this.value;
        // this.value = null; https://podio.com/klartboard/softwareudvikling/apps/stories/items/379
    }

    triggerBlur() {
        this.focused = false;
        this.editmode = false;
        if(this.displayValue != this.oldValue){
            this._valueChanged.next(this.displayValue);
        }
        if(!this.value && this.oldValue) {
            this.displayValue = this.value = this.oldValue;
        }

    }
}
