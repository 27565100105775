import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {
    Department, DisplaysSetting,
    MilestonePlan,
    MilestoneTemplate,
    ProjectDeadlineType,
    ProjectType,
    TaskEstimateType,
    TaskType,
    User
} from "@app/core/models";
import {Api} from "@app/core/Api";
import {MilestonesService} from "@app/services/milestones.service";
import {ProjectsService} from "@app/services/projects.service";
import {TasksService} from "@app/services/tasks.service";
import {System, Tabs} from "@app/constants";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {AppInjector} from "@app/services/app-injector.service";
import {ActivatedRoute, Router} from "@angular/router";
import Helpers from "@app/core/helpers";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {UsersService} from "@app/services/users.service";
import {SnackbarService} from '@app/services/snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {
    MilestonePlanTimelineComponent
} from '@app/shared/_ui/displays/milestone-plan/milestone-plan-timeline/milestone-plan-timeline.component';
import {ApiDataCache} from '@app/core/DataCache/ApiDataCache';
import {LocalStorageService} from '@app/services/local-storage.service';

@Component({
    selector: 'app-milestone-plan-editor',
    templateUrl: './milestone-plan-editor.component.html',
    styleUrls: ['./milestone-plan-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MilestonePlanEditorComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() id: number;
    public milestonePlan: MilestonePlan;
    public projectTypes: ProjectType[];
    public projectDeadlineTypes: ProjectDeadlineType[];
    public taskEstimateTypes: TaskEstimateType[];
    public taskTypes: TaskType[];
    public selectedProjectType: ProjectType;

    @ViewChild('timelineComponent') public timeline: MilestonePlanTimelineComponent;


    public selectedTab: string = Tabs.Basic; //Tabs.Basic;
    public subView: string = this.Constants.MilestoneRowViews.Basic;
    public allExpanded = false;

    public isPersonal = false;
    public isTeam = false;
    public isGlobal = false;

    @Output() expandAllEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() onUpdated: EventEmitter<MilestonePlan> = new EventEmitter();
    currentUser: User;
    public defaultDays: number = 30;

    constructor(
        private projectsService: ProjectsService,
        private milestoneService: MilestonesService,
        private tasksService: TasksService,
        private dialogService: BaseDialogService,
        private route: ActivatedRoute,
        public router: Router,
        private _cdr: ChangeDetectorRef,
        private localStorageService: LocalStorageService
    ) {
        super();
        this.cdr = _cdr;




    }


    ngOnInit(): void {
        if (this.id == 0) {
            this.selectedTab = Tabs.Basic;
        }

        const key = `${System.MilestonePlan}-${this.id}`;
        this.defaultDays = this.localStorageService.get(key) || 30;

        this.route.queryParams
            .subscribe(params => {
                    if(params.selectedTab)
                        this.selectedTab = params.selectedTab;
                    if(params.subView)
                        this.subView = params.subView;
                }
            );


        this.subscribe(AppInjector.getInjector().get(UsersService).currentUser$.subscribe(user => {
            this.currentUser = user;
        }));

        this.projectsService.getProjectTypes(items => {
            this.projectTypes = items ? items : [];
            if (this.milestonePlan && !this.milestonePlan.project_type_id && this.projectTypes.length > 0) {
                this.milestonePlan.project_type_id = this.projectTypes[0].id;
            }
            this.setProjectType();
        });

        this.projectsService.getProjectDeadlineTypes(items => {
            this.projectDeadlineTypes = items ? items : [];

            // Magic, find "startdato"
            let primary = this.projectDeadlineTypes[1];
            let secondary = null;
            if(this.projectDeadlineTypes.length > 1){
                const startItem = this.projectDeadlineTypes.find(pdt => pdt.name.toLowerCase().includes('start'));
                const endItem = this.projectDeadlineTypes.find(pdt => pdt.name.toLowerCase().includes('slut'));
                if(startItem && endItem) {
                    primary = startItem ;
                    secondary = endItem;
                }else{
                    primary = this.projectDeadlineTypes[1];
                    secondary = this.projectDeadlineTypes[0];
                }
            }


            //  https://podio.com/klartboard/softwareudvikling/apps/stories/items/1498
            if (this.milestonePlan && !this.milestonePlan.start_project_deadline_type_id && primary) {
                // End skal være den første
                this.milestonePlan.start_project_deadline_type_id = primary.id;
            }
            if (this.milestonePlan && !this.milestonePlan.end_project_deadline_type_id && secondary) {
                // Start skal være den sekundære
                this.milestonePlan.end_project_deadline_type_id = secondary.id;
            }

        });

        this.tasksService.getTaskEstimateTypes(items => {
            this.taskEstimateTypes = items ? items : [];
            if (this.milestonePlan && !this.milestonePlan.task_estimate_type_id && this.taskEstimateTypes.length > 0) {
                this.milestonePlan.task_estimate_type_id = this.taskEstimateTypes[0]?.id;
            }
        });

        this.tasksService.getTaskTypes(items => {
            this.taskTypes = items ? items : [];
            if (this.milestonePlan && !this.milestonePlan.default_task_type_id && this.taskTypes.length > 0) {
                this.milestonePlan.default_task_type_id = this.taskTypes[0].id;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.id !== null && changes.id.currentValue != changes.id.previousValue) {
            this.usersService.currentUser$.subscribe(()=>{
                this.loadMilestonePlan();
            }).unsubscribe();

        }
    }

    private updateOwnership() {
        this.isTeam = this.milestonePlan ? this.milestonePlan?.isTeam() : false;
        this.isGlobal = this.milestonePlan ? this.milestonePlan?.isGlobal() : false;
        this.isPersonal = this.milestonePlan ? this.milestonePlan?.isPersonal() : false;
    }

    private loadMilestonePlan() {
        if (this.id && this.id != 0) {
            // this.milestonePlan = milestonePlans ? milestonePlans.find(plan => plan.id == this.id) : null;
            let taskTemplateInclude = `user`; // user,status_rule
            let milestonePlanInclude = `status_rule,phase.color`;
            Api.milestonePlans()
                .getById(this.id)
                .include('user')
                .include('task_template?include=' + encodeURIComponent(taskTemplateInclude))
                .include('milestone_template?include=' + encodeURIComponent(milestonePlanInclude))
                .include('blocked_holiday')
                .find(mp => {
                    if (!this.milestonePlan) {
                        this.milestonePlan = mp[0];
                        this.milestonePlan.user = this.milestonePlan.user ? new User(this.milestonePlan.user) : null;
                        if (this.milestonePlan.milestone_templates && this.milestonePlan.task_templates) {
                            this.milestonePlan.milestone_templates.forEach(mt => {
                                mt.task_templates = this.milestonePlan.task_templates.filter(tt => tt.milestone_template_id == mt.id);
                                mt.task_templates.sort(Helpers.sortByIndex);
                            })
                        }
                        if (this.milestonePlan.milestone_templates) {
                            this.milestonePlan.milestone_templates = this.milestonePlan.milestone_templates.sort(Helpers.sortByIndex)
                        }

                        const key = `${System.MilestonePlan}-${this.id}`;
                        this.defaultDays = this.localStorageService.get(key) || 30;
                        this.updateOwnership();
                        this.setProjectType();
                        this.detectChanges();
                    }
                });
        } else {
            this.milestonePlan = new MilestonePlan({id: 0, user_id: 0});
            this.setDefaults();
            this.updateOwnership();
            this.detectChanges();
        }
    }

    setDefaults(){
        if (!this.milestonePlan.user_id && this.user && !this.user?.administrator) {
            this.milestonePlan.user_id = this.user.id;
        }
        if (!this.milestonePlan.task_estimate_type_id && this.taskEstimateTypes && this.taskEstimateTypes.length > 0) {
            this.milestonePlan.task_estimate_type_id = this.taskEstimateTypes[0]?.id;
        }
        if (!this.milestonePlan.default_task_type_id && this.taskTypes && this.taskTypes.length > 0) {
            this.milestonePlan.default_task_type_id = this.taskTypes[0]?.id;
        }
        if (!this.milestonePlan.project_type_id && this.projectTypes && this.projectTypes.length > 0) {
            this.milestonePlan.project_type_id = this.projectTypes[0]?.id;
        }
        if (!this.milestonePlan.start_project_deadline_type_id && this.projectDeadlineTypes && this.projectDeadlineTypes.length > 0) {
            this.milestonePlan.start_project_deadline_type_id = this.projectDeadlineTypes[0]?.id;
        }
        if (!this.milestonePlan.end_project_deadline_type_id && this.projectDeadlineTypes && this.projectDeadlineTypes.length > 1) {
            this.milestonePlan.end_project_deadline_type_id = this.projectDeadlineTypes[1]?.id;
        }
    }

    tabChange($event: NgbNavChangeEvent) {
        // Update route - urlparams
        const queryParams = {...this.route.snapshot.queryParams};
        queryParams['selectedTab'] = $event.nextId;
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                skipLocationChange: false,
                replaceUrl: true,
                queryParams: queryParams,
                queryParamsHandling: ''
            });

    }

    createMilestoneRow() {
        if (!this.milestonePlan.milestone_templates) {
            this.milestonePlan.milestone_templates = [];
        }
        this.milestonePlan.milestone_templates.push(MilestoneTemplate.createDefault());
        this.milestonePlan.milestone_templates.forEach((mt, index) => {
            mt.index_ = index;
        })
        if(this.timeline)
            this.timeline.generateItems();
    }

    milestoneTemplateDrop($event: CdkDragDrop<MilestoneTemplate[]>) {
        moveItemInArray(this.milestonePlan.milestone_templates, $event.previousIndex, $event.currentIndex);
        const indexes = this.milestonePlan.milestone_templates.map((m, index) => {
            m.index_ = index;
            return m.id;
        });
        Api.milestoneTemplates().indexingPut().save({values: indexes}, r => {

        });
    }

    createStatusRuleAll() {
        this.dialogService.createStatusRuleDialog().then(statusRule => {
            if (statusRule) {
                this.milestonePlan.milestone_templates.forEach((milestoneTemplate) => {
                    Api.milestoneTemplates()
                        .statusRuleAddPostById(milestoneTemplate.id)
                        .save(statusRule, statusRule => {
                            if (!milestoneTemplate.status_rules) {
                                milestoneTemplate.status_rules = [];
                            }
                            milestoneTemplate.status_rules.push(statusRule);
                        });
                });
            }
        });
    }

    private emitUpdated() {
        console.warn('emitUpdated() : this.milestonePlan : ', this.milestonePlan, 'this : ', this)
        if (this.milestonePlan) {
            this.onUpdated?.emit(this.milestonePlan);
            MilestonePlan.UpdateMilestonePlan(this.milestonePlan);
        }

        const translateService = AppInjector.getInjector().get(TranslateService);
        AppInjector.getInjector().get(SnackbarService).add(
            translateService.instant('_ui_item_saved', {item: translateService.instant('_milestone_plan')}),
        );
        if(this.timeline)
            this.timeline.generateItems();

        this.cdr?.detectChanges();

    }

    createMilestonePlan() {
        if (this.milestonePlan) {
            const title = this.milestonePlan?.title ? (this.milestonePlan?.title)?.trim() : null;
            if (title && title.length > 0) {
                Api.milestonePlans().post().save(this.milestonePlan, milestonePlan => {
                    this.milestonePlan = milestonePlan;
                    this.onUpdated.emit(this.milestonePlan);
                    this.id = this.milestonePlan.id;
                    MilestonePlan.UpdateMilestonePlan(this.milestonePlan);
                    AppInjector.getInjector().get(Router).navigate(['/app/administrator/templates/', milestonePlan.id]);
                })
            }
        }
    }

    saveMilestonePlanTitle() {
        if (this.milestonePlan) {
            if (this.milestonePlan.id == 0) {
                this.createMilestonePlan();
            } else {
                const title = (this.milestonePlan.title).trim();
                if (title.length > 0) {
                    Api.milestonePlans()
                        .titlePatchPatchById(this.milestonePlan.id)
                        .title(this.milestonePlan.title)
                        .save(null, (mp) => {
                            if (this.milestonePlan.id == 0) {
                                this.milestonePlan.id = mp.id;
                            }
                            this.emitUpdated();
                        });
                }
            }
        }
    }

    saveMilestonePlanProjectNotes() {
        Api.milestonePlans().projectNotesPatchPatchById(this.milestonePlan.id)
            .project_notes(this.milestonePlan.project_notes)
            .save(null, ()=>{
                this.emitUpdated();
            });
    }

    saveMilestonePlanProjectType() {
        Api.milestonePlans()
            .projectTypeIdPatchPatchById(this.milestonePlan.id)
            .project_type_id(this.milestonePlan.project_type_id)
            .save(null, ()=>{
                this.emitUpdated();
            });
        this.setProjectType();

    }

    saveMilestonePlanProjectDeadlineTypeStart() {
        Api.milestonePlans()
            .startProjectDeadlineTypeIdPatchPatchById(this.milestonePlan.id)
            .start_project_deadline_type_id(this.milestonePlan.start_project_deadline_type_id)
            .save(null, ()=>{
                this.emitUpdated();
            })
    }

    saveMilestonePlanProjectDeadlineTypeEnd() {
        Api.milestonePlans()
            .endProjectDeadlineTypeIdPatchPatchById(this.milestonePlan.id)
            .end_project_deadline_type_id(this.milestonePlan.end_project_deadline_type_id)
            .save(null, ()=>{
                this.emitUpdated();
            });
    }

    saveMilestonePlanTaskEstimateType() {
        Api.milestonePlans()
            .taskEstimateTypeIdPatchPatchById(this.milestonePlan.id)
            .task_estimate_type_id(this.milestonePlan.task_estimate_type_id)
            .save(null, ()=>{
                this.emitUpdated();
            });
    }

    saveMilestonePlanDefaultTaskType() {
        Api.milestonePlans()
            .defaultTaskTypeIdPatchPatchById(this.milestonePlan.id)
            .default_task_type_id(this.milestonePlan.default_task_type_id)
            .save(null, ()=>{
                this.emitUpdated();
            });
    }

    deleteMilestoneTemplate(milestoneTemplate: MilestoneTemplate) {
        const index = (milestoneTemplate.id != 0) ? this.milestonePlan.milestone_templates.findIndex(mt => mt.id == milestoneTemplate.id) : this.milestonePlan.milestone_templates.findIndex(mt => mt === milestoneTemplate);

        this.milestonePlan.milestone_templates.splice(index, 1);

        if (milestoneTemplate && milestoneTemplate.id != 0) {
            Api.milestoneTemplates()
                .deleteById(milestoneTemplate.id)
                .delete(() => {
                    this.emitUpdated();
                });
        } else {
            this.emitUpdated();
            this.milestonePlan.milestone_templates.splice(index, 1);
        }
    }

    saveMilestonePlanUser($event?: User) {
        this.milestonePlan.user_id = $event?.id ? $event.id : 0;
        this.milestonePlan.user = $event;
        Api.milestonePlans()
            .userIdPatchPatchById(this.milestonePlan.id)
            .user_id(this.milestonePlan.user_id)
            .save(null, (r)=>{
                this.emitUpdated();
            });
        this.updateOwnership();

        if ($event) {
            this.toggleMilestonePlanDepartment(false);
        }
    }

    removeMilestonePlanUser($event?: any) {
        this.milestonePlan.user_id = 0;
        delete this.milestonePlan.user;
        Api.milestonePlans()
            .userIdPatchPatchById(this.milestonePlan.id)
            .user_id(0).save(null, ()=>{
            this.emitUpdated()
        });
        this.updateOwnership();
    }

    departmentChange(departments: Department[]) {
        this.milestonePlan.ensureDepartments(departments);
        if (this.milestonePlan.departments?.length) {
            this.saveMilestonePlanUser(null);
        }
        this.updateOwnership();
    }

    toggleMilestonePlanDepartment(updateUser = true) {
        if (this.milestonePlan.departments?.length) {
            this.milestonePlan.ensureDepartments([]);
            this.updateOwnership();
        } else if (this.milestonePlan.user_id != 0 && updateUser) {
            this.saveMilestonePlanUser(null);
        }
    }

    toggleUseResponsibleAsParticipant() {
        this.milestonePlan.use_project_responsible = !this.milestonePlan.use_project_responsible;
        Api.milestonePlans()
            .useProjectResponsiblePatchById(this.milestonePlan.id)
            .use_project_responsible(this.milestonePlan.use_project_responsible)
            .save(this.milestonePlan, () => {
                this.emitUpdated();
            });
    }

    itemEnabled(projectDeadlineType: ProjectDeadlineType): boolean {
        if (this.projectTypes && projectDeadlineType) {
            const projectType = this.projectTypes.find(t => t.id == this.milestonePlan.project_type_id);
            if (projectType?.project_deadline_types) {
                return !(projectType.project_deadline_types.findIndex(pd => pd.id == projectDeadlineType.id) !== -1);
            }
            return false;
        }
        return false;
    }

    setSubView(view: string) {
        this.subView = view;
        const queryParams = {...this.route.snapshot.queryParams};
        queryParams['subView'] = this.subView;
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                skipLocationChange: false,
                replaceUrl: true,
                queryParams: queryParams,
                queryParamsHandling: ''
            });
    }

    updateTimeline($event: MilestoneTemplate, milestoneTemplate: MilestoneTemplate) {
        if(this.timeline)
            this.timeline.generateItems();
    }

    private setProjectType() {
        this.selectedProjectType = this.projectTypes?.find(pt => pt.id == this.milestonePlan?.project_type_id);
    }

    changeDefaultDays($event: Event) {
        const key = `${System.MilestonePlan}-${this.id}`;
        this.localStorageService.set(key, this.defaultDays);
    }
}
