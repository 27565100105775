<div class="container-fluid p-0 h-100 d-flex flex-column"
     [attr.data-isCapturingScreenshot]="isCapturingScreenshot"
>
    <div #contentContainer class="d-flex  justify-content-between  h-100"
         [class.scroll-x]="!isMobileVersion"
         [class.flex-row]="!isMobileVersion"
         [class.scroll-y]="isMobileVersion"
         [class.flex-column]="isMobileVersion"
    >
        <ng-container *ngFor="let tableColumn of tableColumns">
            <div class="phase-container h-100"
                 [style.min-width.px]="!isMobileVersion ? tableColumn.width : null"
                 [style.width.px]="!isMobileVersion ? tableColumn.width : null"
                 *ngIf="tableColumn.column.category"
                 mwlResizable
                 [enableGhostResize]="false"
                 [validateResize]="tableColumn.validateResize"
                 (resizing)="tableColumn.resize($event.rectangle.width)"
            >
                <header class=" p-1 ps-2 position-relative d-flex align-items-center">
                    <app-column-sorter [display]="display" [column]="tableColumn"
                                       [onRenderEvent]="onColumnSortRenderEventEmitter"
                                       [filterPageData]="shellPageData"
                    ></app-column-sorter>
                    <app-color-item class="me-1" [color]="tableColumn.column.category?.color" *ngIf="tableColumn.column.category?.color"></app-color-item>

                    <div class="resize-handle text-small ms-auto d-mobile-none"
                         [ngbTooltip]="('_ui_resize_column' | translate)"
                         [resizeEdges]="{bottom: false, right: true, top: false, left: false}"
                         mwlResizeHandle>
                        <i class="fas fa-grip-vertical"></i>
                    </div>
                </header>
                <aside class="p-1 scroll-y position-relative">
                    <app-multi-list
                        [class.disabled]="tableColumn.multiListConfiguration.dropDisabled"
                        [reloadEvent]="tableColumn.reloadEvent"
                        [configuration]="tableColumn.multiListConfiguration"
                        (onDragStarted)="onDragStartedEvent($event)"
                        (onDragEnded)="onDragEndedEvent($event)"
                    ></app-multi-list>
                </aside>
            </div>
        </ng-container>
    </div>
    <footer class="d-flex align-items-center w-100">
        <div class="d-flex align-items-center footer-actions -ms-auto pe-2 ps-2">
            <a class="me-2  d-none"
               (click)="onCaptureScreenshotBtnClicked();"
               [ngbTooltip]="('_ui_screenshot' | translate)"
            >
                <i class="fal fa-camera-viewfinder" *ngIf="!isCapturingScreenshot"></i>
                <app-loading-indicator [showLogo]="false"
                                       *ngIf="isCapturingScreenshot"></app-loading-indicator>
            </a>

            <a class="text-center me-2 " (click)="loadData()"
               style="transform: translateY(-1px);"
               [ngbTooltip]="('_ui_reload' | translate) + '...'"
               container="body"
               placement="right"><i class="fal fa-light fa-arrow-rotate-right"></i></a>

            <app-on-screen-filter-selector
                [onScreenFilters]="onScreenFilters"
            ></app-on-screen-filter-selector>
        </div>
    </footer>
</div>
