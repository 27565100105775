import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {debounceTime, distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {merge, Observable} from 'rxjs';
import {fadeAnimation} from '@app/animations';
import {Phase} from '@app/core/models/Phase';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseSearchComponent} from '../base-search/base-search.component';
import {Milestone, PhaseProgressType, ProjectType} from "@app/core/models";
import {Api} from "@app/core/Api";
import {AppInjector} from "@app/services/app-injector.service";
import {ShellService} from "@app/services/ShellService/shell.service";

@Component({
    selector: 'app-phase-progress-type-search',
    templateUrl: './phase-progress-type-search.component.html',
    styleUrls: ['../base-search/base-search.component.scss'],
    animations: [fadeAnimation],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhaseProgressTypeSearchComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PhaseProgressTypeSearchComponent),
            multi: true,
        }
    ],
    standalone: false
})
export class PhaseProgressTypeSearchComponent extends BaseSearchComponent implements OnInit {

    @Input() _internalValue: PhaseProgressType;
    @Input() projectTypes: ProjectType[];
    @Input() filterByActiveDepartment?: boolean;

    items: PhaseProgressType[] = [];
    result: PhaseProgressType[] = [];

    constructor() {
        super();
    }

    ngOnInit() {
        const api = Api.phaseProgressTypes().get();

        if (this.projectTypes) {
            api
                .whereIn('phase.project_type.id', this.projectTypes.map(item => item.id))
                .where('phase_progress_types_phase.visible', 1);
        }

        if (this.filterByActiveDepartment) {
            api.where('phase.department.id', AppInjector.getInjector().get(ShellService).getPageSettings().departmentId);
        }

        api.find(items => this.items = items);
    }

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            tap(() => this.searching = true),
            map(term => {
                let r = this.items
                    .filter(v => String(v.name).toLowerCase().indexOf(String(term).toLowerCase()) > -1)
                    .filter(item => this._internalValue == null || this._internalValue.id != item.id);

                this.result = r.length > 0 ? r : null;
                this.searchFailed = term !== '' ? this.result == null : null;
                return term === '' ? this.items.filter(item => this._internalValue == null || this._internalValue.id != item.id) : r;
            }),
            tap(() => this.searching = false),
        );
    };

    formatter = (x: PhaseProgressType) => x.name;

    triggerSelection($event: NgbTypeaheadSelectItemEvent, input: any) {
        this.itemSelected.emit($event.item);
        $event.preventDefault();
        input.value = '';
        this.addItem($event.item)
    }

    addItem(item: PhaseProgressType) {
        this._internalValue = item;
        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
    }

    removeItem(item: PhaseProgressType) {
        this._internalValue = null;
        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
    }

    createField() {
        this.addItem(new PhaseProgressType({id: 0}));
    }

    getItems() {
        return this._internalValue ? [this._internalValue] : [];
    }

    resultTemplateType: {
        result: PhaseProgressType,
        term: string,
    }

}
