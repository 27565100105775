import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {BaseFormControlComponent} from '@app/shared/_forms/base-form-control/base-form-control.component';
import {Color} from '@app/core/models';

@Component({
    selector: 'app-toggle-item',
    template: `
        <span (click)="toggle($event)"
              [class.underline-hover]="underline"
              [class.interactive]="interactive"
              [ngbTooltip]="tooltipLocalLabel"
              class="d-flex flex-row align-items-center justify-content-start gap-1"

        >
            <i [class]="className"></i>

            <span class="flex-fill ellipsis {{labelClass}}" *ngIf="label">{{label}}</span>

            <app-color-item [className]="colorClassName" [color]="color" *ngIf="color"
                            class="me-auto"></app-color-item>

        </span>
    `,
    styles: [
        ':host .interactive{cursor: pointer;}',
        '.fas{opacity: 0.5;}', '.fas.active{opacity: 1;}',
        ':host[disabled="true"]{opacity: 1!important;}',
        ':host span.underline-hover:hover .ellipsis{ text-decoration: underline;}'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleItemComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ToggleItemComponent),
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ToggleItemComponent extends BaseFormControlComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {

    @Input() public active = true;
    @Input() public underline = false;
    @Input() public interactive: boolean;
    @Input() public icon = 'fa-check-circle';
    @Input() public colorClassName: string;
    @Input() public iconInactive: string;
    @Input() public label: string;
    @Input() public labelClass: string ;
    @Input() public color: Color;
    @Input() public tooltipLabel: string;
    @Input() public formControl: UntypedFormControl = null;

    @Input() public iconWeightActive = 'fas';
    @Input() public iconWeightInactive = 'fal';

    @Input() public iconColorActive: string = '';
    @Input() public iconColorInactive: string = '';

    @Output() public onToggle = new EventEmitter<boolean>();

    ACTIVE_CLASS = `fas active align-self-middle`;
    INACTIVE_CLASS = `fal inactive align-self-middle`;

    public className = `${this.iconWeightActive} fa-flag`;
    public tooltipLocalLabel: string;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
        this.ACTIVE_CLASS = `${this.iconWeightActive} active align-self-middle ${this.iconColorActive}`;
        this.INACTIVE_CLASS = `${this.iconWeightInactive} inactive align-self-middle  ${this.iconColorInactive}`;

        if (this.tooltipLabel) {
            if (this.tooltipLabel != '') {
                this.tooltipLocalLabel = this.tooltipLabel;
            }
        } else {
            this.tooltipLocalLabel = this.label;
        }
    }

    public onChange: any = (event: any) => { /*Empty*/
        this.active = this.internalValue;
        this.render();
    }

    ngOnInit() {
        if (this.icon == 'fa-check-circle' && !this.iconInactive) {
            this.iconInactive = 'fa-circle';
        }
        if (this.internalValue) {
            this.active = this.internalValue;
        }
        if (this.iconInactive === undefined) {
            this.iconInactive = `${this.icon}`;
        }

        this.ACTIVE_CLASS = `${this.iconWeightActive} active align-self-middle  ${this.iconColorActive}`;
        this.INACTIVE_CLASS = `${this.iconWeightInactive} inactive align-self-middle  ${this.iconColorInactive}`;

        if (this.formControl?.valueChanges) {
            this.formControl?.valueChanges.subscribe((event: any) => {
                this.render();
            });
        }

        this.render();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.render();
    }

    toggle(mouseEvent: MouseEvent) {
        if (this.disabled) {
            mouseEvent.stopPropagation();
            return;
        }
        if (this.interactive) {
            mouseEvent.stopPropagation();
            this.active = !this.active;
            this.internalValue = this.active;
            this.render();
            this.onToggle.emit(this.active);
        }

        this.propagateChange(this.internalValue);
    }

    internalValueUpdated() {
        this.active = this.internalValue;
        this.render();
    }

    private render() {
        this.className = this.active ? `${this.ACTIVE_CLASS} ${this.icon}` : `${this.INACTIVE_CLASS} ${this.iconInactive}`;
        this.detectChanges();
    }

}
