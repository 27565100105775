import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ShellService} from "@app/services/ShellService/shell.service";
import {Todo} from "@app/core/models";
import {Api} from "@app/core/Api";
import {CountRunner} from "@app/core/CountRunner/CountRunner";
import {AppInjector} from "@app/services/app-injector.service";
import {EventService} from "@app/services/event.service";
import {EditorPanelService} from "@app/services/editor-panel.service";
import {TodosService} from "@app/services/todos.service";
import {Field, TodoEditorService} from "@app/editor/todo-editor-loader/todo-editor.service";
import {ReactionsTypeInterface} from "@app/shared/_ui/reactions/ReactionsTypeInterface";

@Component({
    selector: 'app-todo-editor-loader',
    templateUrl: './todo-editor-loader.component.html',
    styleUrls: ['../base-editor-loader.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TodoEditorLoaderComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() itemToLoad: Todo;
    @Input() options: any;
    @Output() onCloseEventEmitter = new EventEmitter();

    // Bindings to view: UI Control
    public isLoading = false;
    public showNotFound = false;
    public showEditor = false;
    // Bindings to view: Required for Editor
    public item: Todo;
    public fields: Map<number, Field>;
    public reactionsTypeInterfaces: ReactionsTypeInterface[];

    constructor(private shellService: ShellService,
                private cd: ChangeDetectorRef,
                private editorService: TodoEditorService,
                private itemService: TodosService,
                private editorPanelService: EditorPanelService) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        this.startLoading();
        this.ensureItem(item => this.loadData(item), () => this.renderWithError());
    }

    // <editor-fold desc="Load data">

    private ensureItem(onSuccess: (item: Todo) => void, onError: () => void) {
        // Fetch
        if (this.itemToLoad.exists()) {
            if (this.options?.reload === false) {
                onSuccess(this.itemToLoad);
            } else {
                Api.todos().getById(this.itemToLoad.id).find(item => {
                    if (item?.length == 1 && item[0].exists()) {
                        onSuccess(item[0]);
                    } else {
                        onError();
                    }
                });
            }
        } else {
            console.error('Should never end up here');
        }
    }

    private loadData(itemToLoad: Todo) {
        this.startLoading();

        const countRunner = new CountRunner(2);
        countRunner.setRunner(() => {
            this.item = itemToLoad;
            this.renderWithSuccess();
        });

        this.editorService.getFields(fields => {
            this.fields = fields;
            countRunner.tick();
        });

        this.itemService.getReactionTypes(reactionTypes => {
            this.reactionsTypeInterfaces = reactionTypes;
            countRunner.tick();
        });

        countRunner.start();
    }

    private startLoading() {
        this.showEditor = false;
        this.showNotFound = false;
        this.isLoading = true;
        this.detectChanges();
    }

    private stopLoading() {
        this.isLoading = false;
        this.detectChanges();
    }

    private renderWithError() {
        this.showNotFound = true;
        this.stopLoading();
    }

    private renderWithSuccess() {
        this.showEditor = true;
        this.stopLoading();

        // Subscribe to events and close if deleted
        this.subscribe(AppInjector.getInjector().get(EventService).subscribe(this.item, event => {
            switch (event.action) {
                case EventService.Updated:
                    break;
                case EventService.Deleted:
                    this.close();
                    break;
            }
        }));
    }

    // </editor-fold>

    // <editor-fold desc="View actions">

    public onLoadingIndicatorClicked() {
        this.close();
    }

    public onNotFoundClicked() {
        this.close();
    }

    public onEditorItemSaved(item: Todo) {
        Api.todos().getById(item.id).find(item => {
            this.item = item[0];
            this.renderWithSuccess();
        });
    }

    public onEditorReloadEvent() {
        Api.todos().getById(this.item.id).find(item => {
            this.item = item[0];
            this.renderWithSuccess();
        });
    }

    public onEditorCloseEvent() {
        this.close();
    }

    // </editor-fold>

    private close() {
        this.shellService.removeFromURI(this.itemToLoad);
        this.editorPanelService.closeItem(this.item);
        this.onCloseEventEmitter.emit();
    }

}
