import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {User} from '@app/core/models/User';
import {Project} from '@app/core/models/Project';
import {PeriodRange} from '@app/services/FilterGlobalService/PeriodRange';
import {ProjectType} from '@app/core/models/ProjectType';
import {ProjectsService} from '@app/services/projects.service';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import moment from 'moment';
import Helpers from '@app/core/helpers';
import {CardProjectConfiguration} from '@app/shared/_ui/cards/medium/card-project/card-project-configuration';
import {CardMilestoneConfiguration} from '@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration';
import {EventService} from '@app/services/event.service';
import {PageComponent} from "@app/pages/page.component";
import {StatusTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskMilestonePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskMilestonePresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";
import {
    MilestoneProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneProjectPresetGenerator";
import {
    TodoUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoUserPresetGenerator";
import {
    TodoProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoProjectPresetGenerator";
import {
    TodoDisplayPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoDisplayPresetGenerator";
import {
    TodoShowOnDisplayPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoShowOnDisplayPresetGenerator";
import {Filters} from "@app/pages/displays/dashboard/dashboard-projects/Filters";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {
    ProjectCardColumnType
} from "@app/pages/displays/dashboard/dashboard-projects/ColumnTypes/ProjectCardColumnType";
import {
    NextMilestoneCardColumnType
} from "@app/pages/displays/dashboard/dashboard-projects/ColumnTypes/NextMilestoneCardColumnType";
import {
    FollowingMilestoneListColumnType
} from "@app/pages/displays/dashboard/dashboard-projects/ColumnTypes/FollowingMilestoneListColumnType";
import {
    TaskListNextMilestoneColumnType
} from "@app/pages/displays/dashboard/dashboard-projects/ColumnTypes/TaskListNextMilestoneColumnType";
import {TodoListColumnType} from "@app/pages/displays/dashboard/dashboard-projects/ColumnTypes/TodoListColumnType";
import {
    AppointmentListColumnType
} from "@app/pages/displays/dashboard/dashboard-projects/ColumnTypes/AppointmentListColumnType";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {Api} from "@app/core/Api";
import {BaseApi} from "@app/core/BaseApi";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {DataFetcherCollection} from "@app/core/DataFetchers/DataFetcherCollection";
import {ProjectDisplayRow} from "@app/pages/displays/dashboard/dashboard-projects/ProjectDisplayRow";
import {TemplateTypes} from "@app/pages/displays/dashboard/dashboard-projects/TemplateTypes";
import {AppointmentListColumn} from "@app/pages/displays/dashboard/dashboard-projects/Columns/AppointmentListColumn";
import {ColumnTypes} from "@app/pages/displays/dashboard/dashboard-projects/ColumnTypes";
import {TodoListColumn} from "@app/pages/displays/dashboard/dashboard-projects/Columns/TodoListColumn";
import {
    FollowingMilestoneListColumn
} from "@app/pages/displays/dashboard/dashboard-projects/Columns/FollowingMilestoneListColumn";
import {
    TaskListNextMilestoneColumn
} from "@app/pages/displays/dashboard/dashboard-projects/Columns/TaskListNextMilestoneColumn";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";

@Component({
    selector: 'app-dashboard-projects',
    templateUrl: './dashboard-projects.component.html',
    styleUrls: ['./dashboard-projects.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DashboardProjectsComponent extends PageComponent implements OnInit {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();

    // Bindings to parent
    @Input() activeUser: User;
    @Input() isMainDisplay = true;
    @Input() displayId: number;
    @Input() enableToggle = true;
    @Input() period: PeriodRange;

    @Output() fullSize = new EventEmitter<boolean>();

    // Bindings to view
    @ViewChild('dataTable', {static: false}) table: DatatableComponent;
    @ViewChild('headerTemplate', {static: true}) headerTemplate: TemplateRef<any>;
    @ViewChild('headerTemplateProjectType', {static: true}) headerTemplateProjectType: TemplateRef<any>;
    @ViewChild('projectNameTemplate', {static: true}) projectNameTemplate: TemplateRef<any>;
    @ViewChild('nextMilestoneTemplate', {static: true}) nextMilestoneTemplate: TemplateRef<any>;
    @ViewChild('tasksTemplate', {static: true}) tasksTemplate: TemplateRef<any>;
    @ViewChild('followingMilestonesTemplate', {static: true}) followingMilestonesTemplate: TemplateRef<any>;
    @ViewChild('appointmentsTemplate', {static: true}) appointmentsTemplate: TemplateRef<any>;
    @ViewChild('todosTemplate', {static: true}) todosTemplate: TemplateRef<any>;

    public tableColumns: BaseTableColumn[] = [];
    public projectTypes: ProjectType[];
    public rows: ProjectDisplayRow[];
    public projectsCount: number;
    public fullSizeActive = true;
    public dataFetcherCollection = new DataFetcherCollection();
    public templateTypes = new TemplateTypes();

    // Data
    private limit = 5;
    private projectsOffset = 0;
    private rowsMap: Map<number, ProjectDisplayRow>; // ProjectID -> Row
    private projectsApi?: ApiRequest;
    private sortFilterValue: number;
    private _selectedProjectType: ProjectType;

    constructor(private projectsService: ProjectsService,
                private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
        this.isLoading = true;

    }

    ngOnInit() {
        super.ngOnInit();

        this.buildColumns();
        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(_ => this.loadData()));

        this.setupPush();
    }

    // <editor-fold desc="View Helpers">

    expandProjects() {
        this.loadProjects(this.projectsOffset + this.limit);
    }

    public setSortFilterValue(value: number) {
        if (value) {
            this.sortFilterValue = value;
            this.loadData();
        }
    }

    public getSortFilterValueName(): string {
        switch (this.sortFilterValue) {
            case -1:
                return this.translateService.instant('_ui_all_projects'); // 'Alle forløb';
            case -2:
                return this.translateService.instant('_project_star'); //'Stjernemarkerede';
            case -3:
                return this.translateService.instant('_ui_all_projects_sorted_by_status'); //'Forløb sorteret på status';
            case -4:
                return this.translateService.instant('_ui_all_projects_sorted_by_deadline'); //'Forløb i fristrækkefølge';
            default:
                return this.selectedProjectType.getPlural();
        }
    }

    onReachTop($event: number) {
    }

    onReachBottom($event: number) {
        if (this.rows && this.projectsCount - this.rows.length !== 0 && this.rows.length !== 0) {
            this.expandProjects();
        }
    }

    onScroll($event: number) {
    }

    public toggleSize() {
        this.fullSizeActive = !this.fullSizeActive;
        this.fullSize.emit(this.fullSizeActive);
        // Trigger resize / table height calculation
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 250);
    }

    @HostListener('window:resize', ['$event'])
    sizeChange(event: Event) {
        setTimeout((t: any) => {
            this.dataSetChanged();
        });
    }

    set selectedProjectType(value: ProjectType) {
        if (value) {
            this._selectedProjectType = value;
            this.setSortFilterValue(value.id);
        }
    }

    get selectedProjectType(): ProjectType {
        return this._selectedProjectType;
    }

    get Math() {
        return Math;
    }

    // </editor-fold>

    // <editor-fold desc="Push">

    private setupPush() {
        this.eventService.subscribeToProject(0, event => {
            switch (event.action) {
                case EventService.Created:
                    this.loadProject(event.item.id);
                    break;
                case EventService.Updated:
                    if (event.isPatch && event.fields.length === 1) { // Only one field is updated, maybe we don't need to reload row
                        const fieldsToIgnore = ['main_status', 'num_hand_ups', 'num_stars', 'archived_id'];
                        if (fieldsToIgnore.includes(event.fields[0])) {
                            break;
                        }
                    }
                    if (this.rowsMap && this.rowsMap.has(event.item.id)) { // Reload this project
                        this.loadProject(event.item.id);
                    }
                    break;
                case EventService.Deleted:
                    if (this.rowsMap && this.rowsMap.has(event.item.id)) {  // Delete row
                        this.removeRow(this.rowsMap.get(event.item.id));
                    }
                    break;
            }
        });
    }

    // </editor-fold>

    // <editor-fold desc="Load data">

    private loadData(force = false) {
        if (!this.initialized && !force) {
            return;
        }
        this.rows = [];
        this.rowsMap = new Map();
        this.isLoading = true;
        this.projectsOffset = 0;
        this.loadProjects(this.projectsOffset, true);
    }

    private loadProjects(offset: number, doCount: boolean = false) {
        this.projectsApi?.cancel();

        const api = Api.projects().get();
        this.loadAppendFilters(api);

        this.projectsApi = api
            .limit(this.limit)
            .offset(offset)
            .find(projects => {
                this.projectsOffset = offset;
                this.isLoading = false;

                this.columnController.getVisibleColumns()
                    .filter(column => column.implementsDataFetching)
                    .forEach(column => {
                        this.dataFetcherCollection.add((column as unknown as ColumnDataFetcherInterface).getDataFetchers());
                    });
                this.dataFetcherCollection.clearFetchers();

                const rows = projects.map(project => this.createRow(project));

                this.dataFetcherCollection.execute(() => {
                    rows.forEach(row => this.addRow(row));
                });
            });

        if (doCount) {
            api.count(count => {
                return this.projectsCount = count;
            });
        }
    }

    private loadProject(projectId: number) {
        this.isLoading = true;

        const api = Api.projects().getById(projectId);
        this.loadAppendFilters(api);

        api.find(projects => {
            const project = projects[0];
            this.isLoading = false;

            if (project) {
                if (!this.rowsMap.has(projectId)) {
                    this.projectsCount++;
                    this.addRow(this.createRow(project));
                }
            } else if (this.rowsMap.has(projectId)) {
                this.removeRow(this.rowsMap.get(projectId));
            }

            this.dataSetChanged();
        });
    }

    private loadAppendFilters(api: BaseApi) {
        // Always required do to default configuration (taskType.default_task_estimate_type_id) shown in medium card
        api.include('project_estimate');

        // Vis aktiv fase i minikort
        api
            .include('current_phases_project.phase.color')
            .include('current_phases_project.current_phase_progress.to_phase_progress_type');

        // .where('department.id', this.department.id) https://podio.com/klartboard/softwareudvikling/apps/stories/items/223
        api.where('projects_user.user_id', this.activeUser.id)     // "Mine" projekter
            .where('archived_id', 0);                                // Ikke-fuldførte;

        api.include('next_milestone')
            .include('next_milestone.deadline')
            .include('next_milestone.main_status')
            .include('next_milestone.responsible');

        switch (this.sortFilterValue) {
            case -2:
                api.whereGreaterThan('num_stars', 0);
                break;
            case -3:
                api.orderBy('main_status.status_id', 'desc');
                break;
            case -4:
                api.orderBy('projects_deadline.deadline.date', 'asc');
                break;
            case -1:
                // _ui_show_all
                break;
            default:
                if (this.selectedProjectType)
                    api.where('project_type_id', this.selectedProjectType?.id);
                break;
        }

        // Global Filter
        if (this.filterGlobalService.getActiveSettings().isHandUp) {
            api.whereGreaterThan('num_hand_ups', 0);

            const smartStars = [];
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListNextMilestone)) {
                smartStars.push('next_milestone_tasks');
            }
            if (smartStars.length) {
                api.whereIn('smart_hands', smartStars);
            }
        }

        if (this.filterGlobalService.getActiveSettings().isStarred) {
            api.whereGreaterThan('num_stars', 0);

            const smartStars = [];
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.NextMilestoneCard)) {
                smartStars.push('next_milestone');
            }
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListNextMilestone)) {
                smartStars.push('next_milestone_tasks');
            }
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TodoList)) {
                const archivedSince = moment(new Date())
                    .subtract(7, 'days')
                    .startOf('day');
                smartStars.push('todos');
                api.where('smart_status_todo.or_archived_since', Helpers.serverDate(archivedSince.toDate()));
                api.where('smart_status_todo.display_id', this.display.id);
            }
            if (smartStars.length) {
                api.whereIn('smart_starred', smartStars);
            }
        }

        const globalFilterStatuses = this.filterGlobalService.getActiveSettings().activeStatuses;
        if (globalFilterStatuses.length < 4 && globalFilterStatuses.length > 0) {
            api.whereInArray('main_status.status_id', globalFilterStatuses);

            const smartStatuses = [];
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.NextMilestoneCard)) {
                smartStatuses.push('next_milestone');
            }
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TaskListNextMilestone)) {
                smartStatuses.push('next_milestone_tasks');
            }
            if (this.columnController.hasVisibleTableColumn(ColumnTypes.TodoList)) {
                const archivedSince = moment(new Date())
                    .subtract(7, 'days')
                    .startOf('day');
                smartStatuses.push('todos');
                api.where('smart_status_todo.or_archived_since', Helpers.serverDate(archivedSince.toDate()));
                api.where('smart_status_todo.display_id', this.display.id);
            }
            if (smartStatuses.length) {
                api.whereIn('smart_statuses', smartStatuses);
            }
        }
    }

    private createRow(project: Project): ProjectDisplayRow {
        let row = new ProjectDisplayRow();

        const update = this.rowsMap.has(project.id);
        if (update) {
            row = this.rowsMap.get(project.id);
        }

        this.columnController.getAllColumns().forEach(column => {
            row.addCell(column.createCell(row));
        });

        const config = new CardProjectConfiguration();
        config.showPhase = true;
        config.useGlobalFilter = false;
        row.projectCard = new CardItem<Project>(project, config);
        if (project.getNextMilestone()) {
            project.getNextMilestone().projects = [new Project({
                id: project.id,
                project_type_id: project.project_type_id,
                phases_projects: [...project.phases_projects || []]
            })];
            row.nextMilestoneCard = new CardItem(project.getNextMilestone(), new CardMilestoneConfiguration(false, true));
        }

        this.columnController.getColumns<TaskListNextMilestoneColumn>(ColumnTypes.TaskListNextMilestone)
            .forEach(column => {
                const cell = column.getCell(row);
                cell.listConfiguration
                    .setProject(project)
                    .setMilestone(row.nextMilestoneCard ? row.nextMilestoneCard.item : null)
                    .setTaskTypeIds(this.getTaskTypesIds(project))
                    .setCreatePresetGenerators([
                        new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                        new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                        new TaskUseStatusRulesPresetGenerator(true),
                        new TaskProjectPresetGenerator(project.id),
                        ...row.nextMilestoneCard ? [new TaskMilestonePresetGenerator(row.nextMilestoneCard.item.id)] : [],
                        ...project.departments?.map(department => new TaskDepartmentPresetGenerator(department.id)) ?? [],
                    ]);
            });

        this.columnController.getColumns<FollowingMilestoneListColumn>(ColumnTypes.FollowingMilestoneList)
            .forEach(column => {
                const cell = column.getCell(row);
                cell.listConfiguration
                    .setProject(project)
                    .setCreatePresetGenerators([
                        // Defaults
                        new MilestoneStatusPresetGenerator(StatusTypes.GREEN),
                        new MilestoneUseStatusRulesPresetGenerator(true),

                        new MilestoneProjectPresetGenerator(project.id),
                    ]);
                if (row.nextMilestoneCard) {
                    cell.listConfiguration.setAvoidIds([row.nextMilestoneCard.item.id]);
                    if (row.nextMilestoneCard.item.deadline) {
                        cell.listConfiguration.setDeadlineDateMinOrNull(row.nextMilestoneCard.item.deadline.getDate());
                    }
                }
            });

        this.columnController.getColumns<TodoListColumn>(ColumnTypes.TodoList)
            .forEach(column => {
                const cell = column.getCell(row);
                cell.listConfiguration
                    .setProject(project)
                    .setCreatePresetGenerators([
                        // Defaults
                        new TodoUserPresetGenerator(this.activeUser.id),

                        new TodoProjectPresetGenerator(project.id),
                        new TodoDisplayPresetGenerator(this.displayId),
                        new TodoShowOnDisplayPresetGenerator(true),
                    ]);
            });

        this.columnController.getColumns<AppointmentListColumn>(ColumnTypes.AppointmentList)
            .forEach(column => {
                const cell = column.getCell(row);
                cell.listConfiguration.setProject(project);
            });

        if (update) {
            row.reload();
        }

        return row;
    }

    private addRow(row: ProjectDisplayRow) {
        if (!this.rowsMap.has(row.projectCard.item.id)) {
            this.rows.push(row);
            this.rowsMap.set(row.projectCard.item.id, row);
        }
    }

    private removeRow(row: ProjectDisplayRow) {
        if (this.rowsMap.has(row.projectCard.item.id)) {
            const rowTemp = [...this.rows];
            rowTemp.splice(this.rows.indexOf(row), 1);
            this.rows = rowTemp;
            this.rowsMap.delete(row.projectCard.item.id);
            this.projectsCount--;
            this.dataSetChanged();
        }
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    protected onAfterDisplay() {
        super.onAfterDisplay();
        this.columnController.loadColumns(this.displayId, () => {
            this.initialize();
        });
    }

    private initialize() {
        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            this.tableColumns = this.columnController.getVisibleTableColumns();
            this.loadData();
        }));

        this.projectsService.getProjectTypesByDepartmentId(this.shellService.getPageSettings()?.departmentId, projectTypes => {
            this.projectTypes = projectTypes;
            this.initialized = true;
            this.setSortFilterValue(-1);
        });
    }

    private buildColumns() {
        this.columnController.addColumnTypes([
            new ProjectCardColumnType(this.projectNameTemplate, this.headerTemplateProjectType),
            new NextMilestoneCardColumnType(this.nextMilestoneTemplate, this.headerTemplate),
            new FollowingMilestoneListColumnType(this.followingMilestonesTemplate, this.headerTemplate),
            new TaskListNextMilestoneColumnType(this.tasksTemplate, this.headerTemplate),
            new AppointmentListColumnType(this.appointmentsTemplate, this.headerTemplate),
            new TodoListColumnType(this.todosTemplate, this.headerTemplate),
        ]);
    }

    private getTaskTypesIds(project: Project): number[] {
        return this.projectTypes?.find(pt => pt.id == project.project_type_id)
            ?.project_types_task_types
            ?.filter(projectTypesTaskType => projectTypesTaskType.visible)
            ?.map(projectTypesTaskType => projectTypesTaskType.task_type_id) ?? [];
    }

    private dataSetChanged() {
        if (this.table) { // Højde på tabel skal opdateres manuelt
            this.table.recalculateColumns(this.tableColumns);
            this.rows = [...this.rows]; // This is input into <ngx-datatable>
            this.tableColumns = [...this.tableColumns]; // This is input into <ngx-datatable>
            this.table.recalculate(); // ngx-datatable reference
        }
    }

    // </editor-fold>

}
