import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Project, ProjectType} from "@app/core/models";
import {Field} from "@app/editor/project-editor-loader/project-editor.service";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {ValidatorCollection} from "@app/editor/Validation/ValidatorCollection";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {Fields} from "@app/editor/project-editor-loader/Fields";
import {MilestoneListConfiguration} from "@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration";
import {AttachedType} from "@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-attach-to/attached-types/AttachedType";
import {ProjectsService} from "@app/services/projects.service";
import {
    EditDepartmentPickerConfiguration
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/EditDepartmentPickerConfiguration";
import {
    EditDepartmentPickerFilter
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/EditDepartmentPickerFilter";
import {
    EditTagListConfiguration
} from "@app/editor/quick-editor/editors/generic/tag-list-editor/EditTagListConfiguration";

@Component({
    selector: 'app-project-editor-tabs-attach-to',
    templateUrl: './project-editor-tabs-attach-to-component.html',
    styleUrls: ['./project-editor-tabs-attach-to.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectEditorTabsAttachToComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Project;
    @Input() projectType: ProjectType;
    @Input() fields: Map<number, Field>;
    @Input() isSelectedTab: boolean;
    @Output() validatorChange = new EventEmitter<ValidationErrorInterface>();

    // Bindings to view
    public isReady = false;

    // Bindings to view: Tasks
    public showTaskList = false;
    public taskListConfiguration: TaskListConfiguration;

    // Bindings to view: Milestones
    public showMilestoneList = false;
    public milestoneListConfiguration: MilestoneListConfiguration;

    // Bindings to view: Related projects
    public showRelatedProjects = false;
    public relatedProjectsAttachedTypes: AttachedType[];

    // Bindings to view: Departments
    public showDepartmentPickerEditor = false;
    public departmentPickerEditorConfiguration: EditDepartmentPickerConfiguration;

    // Bindings to view: Tags
    public showTagListEditor = false;
    public tagListEditorConfiguration: EditTagListConfiguration;

    // Data
    private validator: ValidationErrorInterface;

    constructor(
        private cd: ChangeDetectorRef,
        private projectsService: ProjectsService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.setupEditors();
        this.setupValidator();
    }

    private setupEditors() {
        this.showTaskList = this.isFieldVisible(Fields.AttachTo.Tasks);
        this.taskListConfiguration = new TaskListConfiguration();
        this.taskListConfiguration
            .setProject(this.item)
            .setOrderBy([
                ['projects_task.index_', 'asc'],
                ['main_status.status_id', 'desc'],
                ['tasks_deadline.deadline.date', 'null'],
                ['tasks_deadline.deadline.date', 'asc'],
                ['title', 'asc']])
            .setCardDisplayModeMini(true)
            .setShowProjectMiniCard(false)
            .setAcceptanceStatus(null)
            .setShowCreateNew(false);

        this.showMilestoneList = this.isFieldVisible(Fields.AttachTo.Milestones);
        this.milestoneListConfiguration = new MilestoneListConfiguration();
        this.milestoneListConfiguration
            .setProject(this.item)
            .setIncludeRelatedTasks(true)
            .setShowProjectMiniCard(false)
            .setShowCreateNew(false);

        this.showRelatedProjects = true;
        const attachedTypesMap: Map<number, AttachedType> = new Map();
        this.projectType.ups?.forEach(projectType => {
            const attachedType = new AttachedType(Project.name);
            this.projectsService.getProjectType(projectType.id, modelType => {
                attachedType.modelType = modelType;
            });
            attachedTypesMap.set(projectType.id, attachedType);
        });
        this.relatedProjectsAttachedTypes = Array.from(attachedTypesMap.values());
        this.item.projects?.forEach(project => {
            if (attachedTypesMap.has(project.project_type_id)) {
                attachedTypesMap.get(project.project_type_id).addItem(project);
            }
        });

        this.showDepartmentPickerEditor = this.isFieldVisible(Fields.AttachTo.Departments);
        this.departmentPickerEditorConfiguration = new EditDepartmentPickerConfiguration(
            this.fields.get(Fields.AttachTo.Departments)?.smartName ?? '',
            new EditDepartmentPickerFilter(this.projectType.departments?.map(department => department.id!) ?? []),
        );

        this.showTagListEditor = this.isFieldVisible(Fields.AttachTo.Tags);
        this.tagListEditorConfiguration = new EditTagListConfiguration(
            this.fields.get(Fields.AttachTo.Tags)?.smartName ?? ''
        );
    }

    private setupValidator() {
        // Collect validators
        this.validator = new ValidatorCollection([
        ]);
        this.validatorChange.emit(this.validator);

        this.isReady = true;
    }

    private isFieldVisible(fieldId: number): boolean {
        return this.fields.has(fieldId) && this.fields.get(fieldId).visible;
    }

    // <editor-fold desc="View actions">

    // </editor-fold>

    // <editor-fold desc="Dialogs">

    // </editor-fold>

    protected readonly Fields = Fields;
}
