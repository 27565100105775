import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {PhaseProgress} from "@app/core/models";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-kanban-progress/ColumnTypeSettings";
import {KanbanProgress_PhaseProgress_FilterPhaseProgressSettingValue} from "@app/core/Api";
import {
    PhaseProgressTableColumn
} from "@app/pages/displays/display-kanban-progress/TableColumns/PhaseProgressTableColumn";

export class PhaseProgressColumn extends BaseColumn {

    public phaseProgress?: PhaseProgress;

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new PhaseProgressTableColumn(this);
        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        throw new Error("Method not implemented.");
    }

    public setPhaseProgress(value: PhaseProgress) {
        this.phaseProgress = value;
    }

    public getFilterPhaseProgress(): number {
        return this.settings.get(ColumnTypeSettings.PhaseProgress_FilterPhaseProgress)
            ?.getObject<KanbanProgress_PhaseProgress_FilterPhaseProgressSettingValue>()
            ?.phaseProgressId ?? 0;
    }

}
