import {AppointmentListConfiguration} from '@app/shared/_ui/lists/appointment-list/AppointmentListConfiguration';
import {Appointment} from '@app/core/models/Appointment';
import Helpers from '@app/core/helpers';
import {
    MultiLoaderFetcherInterface
} from "@app/shared/_ui/displays/display-multi-loader/Helpers/MultiLoaderFetcherInterface";
import {Api} from "@app/core/Api";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";

export class AppointmentFetcherRequest {

    public configuration: AppointmentListConfiguration;
    public callback: (items: Appointment[], count: number) => void;
    public result: Appointment[] = [];
    public count: number = 0;
    public finished = false;

    public constructor(configuration: AppointmentListConfiguration) {
        this.configuration = configuration;
        this.configuration.setDataSource(this);
    }

    public addItem(item: Appointment) {
        this.result.push(item);
        this.count++;
    }

    public cleanup(showAll: boolean) {
        // Order by
        if (this.configuration.getOrderBy() && this.configuration.getOrderBy().length == 2) {
            let orderBy1 = this.configuration.getOrderBy()[0];
            let orderBy2 = this.configuration.getOrderBy()[1];
            if (orderBy1[0] == 'created' && orderBy2[0] == 'updated') {
                this.result.sort((a, b) => {
                    let aCreated = a.created ? new Date(a.created).getTime() : 0;
                    let bCreated = b.created ? new Date(b.created).getTime() : 0;
                    let aUpdated = a.updated ? new Date(a.updated).getTime() : 0;
                    let bUpdated = b.updated ? new Date(b.updated).getTime() : 0;
                    return aCreated == bCreated ? bUpdated - aUpdated : bCreated - aCreated;
                });
            } else if (orderBy1[0] == 'updated' && orderBy2[0] == 'created') {
                this.result.sort((a, b) => {
                    let aCreated = a.created ? new Date(a.created).getTime() : 0;
                    let bCreated = b.created ? new Date(b.created).getTime() : 0;
                    let aUpdated = a.updated ? new Date(a.updated).getTime() : 0;
                    let bUpdated = b.updated ? new Date(b.updated).getTime() : 0;
                    return aUpdated == bUpdated ? bCreated - aCreated : bUpdated - aUpdated;
                });
            }
        }

        // Limit
        if (!showAll && this.configuration.getLimit())
            this.result = this.result.slice(0, this.configuration.getLimit());
    }

    public subscribe(callback: (items: Appointment[], count: number) => void) {
        this.callback = callback;
        if (this.finished) {
            callback(this.result, this.count);
        }
    }

    public emit() {
        this.finished = true;
        if (this.callback) {
            this.callback(this.result, this.count);
        }
    }

}

export class AppointmentFetcher extends BaseFetcher implements MultiLoaderFetcherInterface {

    private requests: AppointmentFetcherRequest[] = [];
    private userIds: number[] = [];
    private projectIds: number[] = [];
    private dynamicMenuItemIds: number[] = [];
    private api?: ApiRequest;
    public showAll = false;

    constructor(name?: string) {
        super(name ?? '');
    }

    public clear(): void {
        this.requests = [];
    }

    public addRequest(request: AppointmentFetcherRequest) {
        this.requests.push(request);
    }

    public execute(showGlobalLoadingIndicator: boolean = true) {
        if (this.requests.length == 0) {
            this.onFinishEvent.emit(true);
            return;
        }

        this.requests.forEach(request => {
            request.result = [];
            request.count = 0;

            if (request.configuration.getUser()) {
                let userId = request.configuration.getUser().id;
                if (!this.userIds.includes(userId))
                    this.userIds.push(userId);
            }

            if (request.configuration.getProject()) {
                let projectId = request.configuration.getProject().id;
                if (!this.projectIds.includes(projectId))
                    this.projectIds.push(projectId);
            }

            if (request.configuration.getDynamicMenuItem()) {
                let dynamicMenuItemId = request.configuration.getDynamicMenuItem().id;
                if (!this.dynamicMenuItemIds.includes(dynamicMenuItemId))
                    this.dynamicMenuItemIds.push(dynamicMenuItemId);
            }
        });

        let configuration = this.requests[0].configuration;

        const api = Api.appointments().get()
            .include('created_by')
            .include('updated_by');

        if (this.userIds && this.userIds.length > 0) api
            .include('appointments_user')
            .whereIn('user.id', this.userIds);
        if (this.projectIds && this.projectIds.length > 0) api.whereIn('project.id', this.projectIds);

        if (configuration.getDynamicMenuItem()) {
            api.include('dynamic_menu_item?include=role');
        }
        if (configuration.getProject()) api.include('project');

        if (configuration.getUserPeriod())
            api.whereGreaterThanOrEqual('appointments_user.start', Helpers.serverDate(configuration.getUserPeriod()[0]))
                .whereLessThan('appointments_user.start', Helpers.serverDate(configuration.getUserPeriod()[1]));

        api.setShowProgressBar(showGlobalLoadingIndicator);

        this.api = api.find(items => {
            items.forEach(item => {
                this.requests.forEach(request => {
                    if (request.configuration.validate(item))
                        request.addItem(item);
                });
            });

            this.requests.forEach(request => {
                request.cleanup(this.showAll);
                request.emit();
            });

            this.onFinishEvent.emit(true);

        });
    }


    public cancel() {
        this.api?.cancel();
    }
}
