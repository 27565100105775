import {YearWheelColumnConfiguration} from '@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration';
import {PhasesProject} from '@app/core/models/PhasesProject';
import {Milestone} from '@app/core/models/Milestone';
import {Api} from '@app/core/Api';
import {Project} from '@app/core/models/Project';
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";

export class YearWheelFetcherRequest {

    public configuration: YearWheelColumnConfiguration;
    public callback: (phasesProjects: PhasesProject[], milestones: Milestone[]) => void;
    public phasesProjects: PhasesProject[] = [];
    public milestones: Milestone[] = [];
    public finished = false;

    public constructor(configuration: YearWheelColumnConfiguration) {
        this.configuration = configuration;
        this.configuration.setDataSource(this);
    }

    public clear() {
        this.phasesProjects = [];
        this.milestones = [];
    }

    public addPhasesProject(item: PhasesProject) {
        this.phasesProjects.push(item);
    }

    public addMilestone(item: Milestone) {
        this.milestones.push(item);
    }

    public subscribe(callback: (phasesProjects: PhasesProject[], milestones: Milestone[]) => void) {
        this.callback = callback;
        if (this.finished) {
            callback(this.phasesProjects, this.milestones);
        }
    }

    public emit() {
        this.finished = true;
        if (this.callback) {
            this.callback(this.phasesProjects, this.milestones);
        }
    }

}

export class YearWheelFetcher extends BaseFetcher {

    private requests: YearWheelFetcherRequest[];
    private projectIds: number[];
    private projectApi?: ApiRequest;
    private milestonesApi?: ApiRequest;

    public constructor(name?: string) {
        super(name ?? '');
        this.requests = [];
        this.projectIds = [];
    }

    public clear(): void {
        this.requests = [];
    }

    public addRequest(request: YearWheelFetcherRequest) {
        request.clear();
        this.requests.push(request);
    }

    public execute() {
        let finishCounter = 2;
        const onFinish = () => {
            if (--finishCounter == 0) {
                this.requests.forEach(request => request.emit());
                this.onFinishEvent.emit(true);
            }
        }

        if (this.requests.length == 0) {
            onFinish();
            onFinish();
            return;
        }

        this.projectIds = [];

        this.requests.forEach(request => {
            this.projectIds.push(request.configuration.project.id);
        })

        let configuration = this.requests[0].configuration;

        this.projectApi = Api.projects()
            .get()
            .include('phases_project')
            .whereIn('id', this.projectIds)
            .find(projects => {
                projects.forEach(project => {
                    if (project.phases_projects) {
                        project.phases_projects.forEach(phasesProject => {
                            this.requests.forEach(request => {
                                if (request.configuration.validatePhasesProject(phasesProject)) {
                                    request.addPhasesProject(phasesProject);
                                }
                            });
                        });
                    }
                });

                onFinish();
            });

        const api = Api.milestones()
            .get()
            .include('responsible')
            .include('task')
            .include('main_status')
            .include('deadline')
            .include('project_ids')
            .whereIn('project.id', this.projectIds);

        // We need to include all milestones - also the ones outside period scope.
        // Because YearWheelComponent relies on knowing all milestone within a project.
        // Ex. when a milestone is moved from outside period scope to inside period scope, the component excepts the
        // milestone to be par of the storesMilestones array.
        // if (configuration.periodStart) {
        //     api.whereGreaterThanOrEqual('deadline.date', Helpers.serverDate(configuration.periodStart));
        // }
        // if (configuration.periodEnd) {
        //     api.whereLessThanOrEqual('deadline.date', Helpers.serverDate(configuration.periodEnd));
        // }

        this.milestonesApi = api.find(milestones => {
            milestones.forEach(milestone => {

                // Convert milestones_projects to project
                if (milestone.milestones_projects) {
                    milestone.projects = milestone.milestones_projects.map(value => {
                        const project = new Project();
                        project.id = value.project_id;
                        return project;
                    });
                }

                this.requests.forEach(request => {
                    if (request.configuration.validateMilestone(milestone)) {
                        request.addMilestone(milestone);
                    }
                });
            });

            onFinish();
        });
    }

    public cancel() {
        this.projectApi?.cancel();
        this.milestonesApi?.cancel();
    }

}
