import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {
    EditorWithColumnInterface
} from "@app/editor/quick-editor/collections/card-vertical-edit-collections/EditorWithColumnInterface";

@Component({
    selector: 'app-card-vertical-edit-columns',
    templateUrl: './card-vertical-edit-columns.component.html',
    styleUrls: ['./card-vertical-edit-columns.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardVerticalEditColumnsComponent extends BaseComponent {

    @Input() enabledEditors: EditorWithColumnInterface[];

    public remainingWidth: number;

    constructor(private cd: ChangeDetectorRef, private componentRef: ElementRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.enabledEditors?.forEach(editor => {
            this.subscribe(editor.columnConfiguration?.onChangeEvent?.subscribe(() => {
                let usedWidth = 0;
                this.enabledEditors.forEach(editor => usedWidth += editor.columnConfiguration.width);
                this.remainingWidth = this.lastAvailableWidth - usedWidth;
                this.detectChanges();
            }));
        });

        const observer = new ResizeObserver(entries => {
            this.updateWidth(entries[0].contentRect.width);
        });
        observer.observe(this.componentRef.nativeElement);
    }

    private lastAvailableWidth?: number;

    private updateWidth(availableWidth: number) {
        if (this.lastAvailableWidth == availableWidth) {
            return;
        }
        this.lastAvailableWidth = availableWidth;
        const columns = this.enabledEditors.map(editor => editor.columnConfiguration);

        let minWidth = 0;
        columns.forEach(column => minWidth += column.minWidth ?? 0);

        if (minWidth > availableWidth) {
            // console.warn('MinWidth > AvailableWidth', minWidth, availableWidth, 'Set all editors to use min width');
            columns.forEach(column => column.setWidth(column.minWidth));
        } else if (minWidth == availableWidth) {
            // Set all editors to use min width
            columns.forEach(column => column.setWidth(column.minWidth));
        } else {

            // Give fixed columns what they want
            const fixedWidthColumns = columns.filter(column => !column.canAutoResize);
            fixedWidthColumns.forEach(column => column.setWidth(column.minWidth));

            // Continue with resizeable columns
            let widthToAutoResize = availableWidth - minWidth;
            // console.warn(availableWidth, widthToAutoResize);

            const resizeableColumnsWithUserPreference = columns.filter(column => column.canAutoResize && column.savedWidth !== undefined);
            const resizeableColumnsWithoutUserPreference = columns.filter(column => column.canAutoResize && column.savedWidth === undefined);

            // Start with columns with user width preferences
            resizeableColumnsWithUserPreference.forEach(column => {
                const preferredIncrease = Math.max(0, column.savedWidth - column.minWidth);
                const possibleIncrease = Math.min(widthToAutoResize, preferredIncrease);
                column.setWidth(column.minWidth + possibleIncrease);
                widthToAutoResize -= (column.width - column.minWidth);
                // console.warn('Given', possibleIncrease, 'away', 'remaining:', widthToAutoResize);
            });

            // Remaining width is divided between remaining columns
            let widthPerColumn = widthToAutoResize / resizeableColumnsWithoutUserPreference.length;
            // console.warn('Dividing', widthToAutoResize, 'between', resizeableColumnsWithoutUserPreference.length, 'columns');
            resizeableColumnsWithoutUserPreference.forEach(column => {
                column.setWidth(column.minWidth + widthPerColumn);
                widthToAutoResize -= (column.width - column.minWidth);
            });

            if (widthToAutoResize > 0) {
                // Okay, we have now respected fixed columns, user-preferred columns, and we have tried to fill out remaining columns
                // But we still got more width to spare...
                // Let's try to fill up user-preferred columns beyond the requested width.
                // They will get more with than they requested, but this is probably what they want
                let widthPerColumn = widthToAutoResize / resizeableColumnsWithUserPreference.length;
                // console.warn('Dividing', widthToAutoResize, 'between', resizeableColumnsWithUserPreference.length, 'columns');
                resizeableColumnsWithUserPreference.forEach(column => {
                    column.setWidth(column.width + widthPerColumn);
                    widthToAutoResize -= (column.width - column.minWidth);
                });

                if (widthToAutoResize > 0) {
                    // Okay, now I'm giving up. We got too much space to work with. Let someone else figure out what to do with the extra space...
                }
            }
        }
    }

}
