{
	"APP_NAME": "KlartBoard",
	"da-DK": "Danish",
	"en-US": "English",
    "nb": "Bokmål",
    "nn": "Norwegian ",
    "sv-SE": "Swedish",
	"SiteNotConfigured": "Site configuration is missing.",
	"development-server": "Development server",

    "_ui_logged_out_title": "User was logget out",
    "_ui_logged_out_description": "Your user has been inactive in a longer period and has been logged out",

	"LAST_ENTRY": ""
}
