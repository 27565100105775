export class ChangeMessage<T> {
    public previous: T;
    public next: T;
    public extra?: any;

    constructor(previous: T, next: T, extra?: any) {
        this.previous = previous;
        this.next = next;
        this.extra = extra;
    }

    public static ParseRabbitMQMessage<T>(body: any): ChangeMessage<T> {
        const data = JSON.parse(body);
        return new ChangeMessage<T>(data.prev, data.next, data.extra);
    }
}
