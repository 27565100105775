import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {CalculatedFieldsProject, Project, ProjectType, StatusRule} from "@app/core/models";
import {Field} from "@app/editor/project-editor-loader/project-editor.service";
import {Fields} from "@app/editor/project-editor-loader/Fields";
import {
    EditUseStatusRulesConfiguration
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/EditUseStatusRulesConfiguration";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {ValidatorCollection} from "@app/editor/Validation/ValidatorCollection";
import {
    EditDeadlineListConfiguration
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineListConfiguration";
import {
    EditEstimateListConfiguration
} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EditEstimateListConfiguration";
import {EditLinkConfiguration} from "@app/editor/quick-editor/editors/generic/link-editor/EditLinkConfiguration";
import {EditTextConfiguration} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextConfiguration";
import {
    EditReactionListConfiguration
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionListConfiguration";
import {
    EditCategoryPickerListConfiguration
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerListConfiguration";
import {DeadlineItem} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineItem";
import {TranslateService} from "@ngx-translate/core";
import {EstimateItem} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EstimateItem";
import {EditTextValidator} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextValidator";
import {
    CategoryPickerItem
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/CategoryPickerItem";
import {
    EditStatusListConfiguration
} from "@app/editor/quick-editor/editors/generic/status-list-editor/EditStatusListConfiguration";
import {StatusItem} from "@app/editor/quick-editor/editors/generic/status-list-editor/StatusItem";
import {
    EditPhasesProjectConfiguration
} from "@app/editor/quick-editor/editors/project/phases-project-editor/EditPhasesProjectConfiguration";
import {FieldItem} from "@app/editor/base-editor-v3/helpers/FieldItem";
import {Api} from "@app/core/Api";
import moment from "moment";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {DateChangeDifference} from "@app/interfaces/DateChangeDifference";
import {
    EditDeadlineTypeValidator
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineTypeValidator";
import {
    EditCategoryPickerValidator
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerValidator";

@Component({
    selector: 'app-project-editor-tabs-basic',
    templateUrl: './project-editor-tabs-basic-component.html',
    styleUrls: ['./project-editor-tabs-basic.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectEditorTabsBasicComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Project;
    @Input() projectType: ProjectType;
    @Input() fields: Map<number, Field>;
    @Output() validatorChange = new EventEmitter<ValidationErrorInterface>();

    // Bindings to view
    public isReady = false;

    // Bindings to view: Deadlines
    public deadlineListFieldItem: FieldItem;
    public deadlineListEditorConfiguration: EditDeadlineListConfiguration;

    // Bindings to view: Use Status Rules
    public useStatusRulesEditorConfiguration: EditUseStatusRulesConfiguration;

    // Bindings to view: Estimates
    public estimateListFieldItem: FieldItem;
    public estimateListEditorConfiguration: EditEstimateListConfiguration;

    // Bindings to view: Calculated fields
    public calculatedFieldsFieldItem: FieldItem;
    public calculatedFields: CalculatedFieldsProject[];

    // Bindings to view: Reference / Link
    public linkFieldItem: FieldItem;
    public linkEditorConfiguration: EditLinkConfiguration;

    // Bindings to view: Status
    public statusListFieldItem: FieldItem;
    public statusListEditorConfiguration: EditStatusListConfiguration;

    // Bindings to view: Status rules
    public statusRulesFieldItem: FieldItem;
    public statusRules: StatusRule[];

    // Bindings to view: Notes (1..10) + purpose + obs
    public noteTextEditorFields: { fieldItem: FieldItem, configuration: EditTextConfiguration }[];

    // Bindings to view: Phase
    public phaseFieldItem: FieldItem;
    public phasesProjectEditorConfiguration: EditPhasesProjectConfiguration;

    // Bindings to view: Emoji
    public showEmojiReactionListEditor: boolean;
    public emojiReactionListEditorConfiguration: EditReactionListConfiguration;

    // Bindings to view: Categories
    public showCategoryPickerListEditor: boolean;
    public categoryPickerListEditorConfiguration: EditCategoryPickerListConfiguration;

    // Data
    private validator: ValidationErrorInterface;

    constructor(
        private cd: ChangeDetectorRef,
        private translateService: TranslateService,
        private dialogService: BaseDialogService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.setupEditors();
        this.setupValidator();
        this.isReady = true;
    }

    private setupEditors() {
        this.deadlineListFieldItem = this.getFieldItem(Fields.BasicInformation.Deadlines);
        this.deadlineListEditorConfiguration = new EditDeadlineListConfiguration(
            this.fields.get(Fields.BasicInformation.Deadlines)?.smartName,
            this.projectType.project_deadline_types_project_types
                ?.filter(projectDeadlineTypesProjectType => {
                    return projectDeadlineTypesProjectType.visible && projectDeadlineTypesProjectType.project_deadline_type?.exists();
                })
                ?.sort((a, b) => a.index_ - b.index_)
                ?.map(projectDeadlineTypesProjectType => {
                    const deadlineItem =  new DeadlineItem(
                        projectDeadlineTypesProjectType.project_deadline_type.getSmartName(),
                        projectDeadlineTypesProjectType.project_deadline_type_id,
                        false,
                        new EditDeadlineTypeValidator(projectDeadlineTypesProjectType.required)
                    );
                    deadlineItem.onDateChangedEvent.subscribe(event => {
                        this.onDeadlineDateChanged(event.before, event.after);
                    });
                    return deadlineItem;
                }) ?? []
        );

        this.useStatusRulesEditorConfiguration = new EditUseStatusRulesConfiguration();

        this.estimateListFieldItem = new FieldItem(
            true,
            this.fields.get(Fields.CalculatedFields)?.index ?? 0
        );
        this.estimateListEditorConfiguration = new EditEstimateListConfiguration(
            this.translateService.instant('_project_estimates'),
            this.projectType.project_estimate_types_project_types
                ?.filter(projectEstimateTypesProjectType => {
                    return projectEstimateTypesProjectType.visible && projectEstimateTypesProjectType.project_estimate_type?.exists();
                })
                ?.map(projectEstimateTypesProjectType => {
                    return new EstimateItem(
                        projectEstimateTypesProjectType.project_estimate_type.name,
                        projectEstimateTypesProjectType.project_estimate_type_id,
                    );
                }) ?? []
        );

        this.calculatedFieldsFieldItem = new FieldItem(
            this.fields.get(Fields.CalculatedFields)?.visible && (this.item.calculated_fields_projects?.length ?? 0) > 0,
            this.fields.get(Fields.CalculatedFields)?.index ?? 0
        );
        this.calculatedFields = this.item.calculated_fields_projects ?? [];

        this.linkFieldItem = this.getFieldItem(Fields.AttachTo.Reference);
        this.linkEditorConfiguration = new EditLinkConfiguration(
            this.fields.get(Fields.AttachTo.Reference)?.smartName ?? '',
            'standard',
        );

        this.statusListFieldItem = new FieldItem(
            true,
            this.fields.get(Fields.BasicInformation.StatusRules)?.index ?? 0
        );
        this.statusListEditorConfiguration = new EditStatusListConfiguration(
            this.translateService.instant('_ui_status_types'),
            this.projectType.project_status_types_project_types
                ?.filter(projectStatusTypesProjectType => {
                    return projectStatusTypesProjectType.visible && projectStatusTypesProjectType.project_status_type?.exists();
                })
                // Avoid Main Status
                ?.filter(projectStatusTypesProjectType => projectStatusTypesProjectType.project_status_type_id !== 1)
                ?.map(projectStatusTypesProjectType => {
                    return new StatusItem(
                        projectStatusTypesProjectType.project_status_type.name,
                        projectStatusTypesProjectType.project_status_type_id,
                        projectStatusTypesProjectType.project_status_type.statuses?.map(status => status.id!) ?? []
                    );
                }) ?? []
        );

        this.statusRulesFieldItem = this.getFieldItem(Fields.BasicInformation.StatusRules);
        this.statusRules = this.projectType.project_types_status_rules
            ?.map(value => {
                const defaultDeadlineType = this.projectType.project_deadline_types?.find(dt => dt.id === value.project_deadline_type_id);
                value.status_rule = new StatusRule(value.status_rule);
                if (defaultDeadlineType) {
                    value.status_rule.defaultDeadlineName = defaultDeadlineType.getSmartName();
                }
                return value.status_rule;
            }) ?? [];

        this.noteTextEditorFields = Fields.NoteFields
            ?.map(field => {
                return {
                    fieldItem: this.getFieldItem(field.id),
                    configuration: new EditTextConfiguration(
                        field.prop,
                        'multi',
                        this.fields.get(field.id)?.smartName ?? '',
                        'rich',
                        new EditTextValidator(this.fields.get(field.id)?.required ?? false)
                    ),
                };
            }) ?? [];

        this.noteTextEditorFields.push(
            {
                fieldItem: this.getFieldItem(Fields.BasicInformation.Purpose),
                configuration: new EditTextConfiguration(
                    'purpose',
                    'multi',
                    this.fields.get(Fields.BasicInformation.Purpose)?.smartName ?? '',
                    'rich',
                    new EditTextValidator(
                        this.fields.get(Fields.BasicInformation.Purpose)?.required ?? false
                    )
                ),
            }
        );

        this.noteTextEditorFields.push(
            {
                fieldItem: this.getFieldItem(Fields.AttachTo.Obs),
                configuration: new EditTextConfiguration(
                    'obs',
                    'multi',
                    this.fields.get(Fields.AttachTo.Obs)?.smartName ?? '',
                    'rich',
                    new EditTextValidator(
                        this.fields.get(Fields.AttachTo.Obs)?.required ?? false
                    )
                ),
            }
        );

        this.phaseFieldItem = new FieldItem(
            !(this.fields.get(Fields.ProcessSteps.Tab)?.visible ?? false) && (this.fields.get(Fields.BasicInformation.Phase)?.visible ?? false),
            this.fields.get(Fields.BasicInformation.Phase)?.index ?? 0
        );
        this.phasesProjectEditorConfiguration = new EditPhasesProjectConfiguration(
            this.fields.get(Fields.BasicInformation.Phase)?.smartName ?? '',
            true
        );

        this.showEmojiReactionListEditor = !(this.fields.get(Fields.Categories.Tab)?.visible ?? false);
        this.emojiReactionListEditorConfiguration = new EditReactionListConfiguration(
            false,
            false,
            true,
            true,
            false
        );

        this.showCategoryPickerListEditor = !(this.fields.get(Fields.Categories.Tab)?.visible ?? false);
        this.categoryPickerListEditorConfiguration = new EditCategoryPickerListConfiguration(
            '',
            this.projectType.category_types_project_types
                ?.filter(categoryTypesProjectType => categoryTypesProjectType.visible && categoryTypesProjectType.category_type?.exists())
                ?.map(categoryTypesProjectType => new CategoryPickerItem(
                    categoryTypesProjectType.category_type.name,
                    categoryTypesProjectType.category_type,
                    true,
                    new EditCategoryPickerValidator(categoryTypesProjectType.required)
                )) ?? []
        );
    }

    private setupValidator() {
        this.validator = new ValidatorCollection([
            ...this.noteTextEditorFields.map(field => field.configuration.validator),
            ...this.categoryPickerListEditorConfiguration.types.map(type => type.validator),
            ...this.deadlineListEditorConfiguration.types.map(type => type.validator),
        ]);
        this.validatorChange.emit(this.validator);
    }

    private getFieldItem(fieldId: number): FieldItem {
        return new FieldItem(
            this.fields.get(fieldId)?.visible ?? false,
            this.fields.get(fieldId)?.index ?? 0
        );
    }

    // <editor-fold desc="View actions">

    public onDeadlineDateChanged(from: Date, to: Date) {
        const diff = moment(to).diff(moment(from), 'days');
        if (!diff || diff == 0) {
            return; // Date not changed enough
        }

        this.showDeadlineChangedDialog(to, diff);
    }

    // </editor-fold>

    // <editor-fold desc="Dialogs">

    private showDeadlineChangedDialog(to: Date, difference: number) {
        const dateChangeDifference: DateChangeDifference = {
            date: to,
            difference: difference,
        };
        Api
            .milestones()
            .get()
            .whereInArray('project.id', [this.item.id])
            .setShowProgressBar(false)
            .count(count => {
                if (count > 0) {
                    this.dialogService.moveProjectDeadline(
                        this.translateService.instant(`_milestone_move_deadlines`),
                        ``,
                        this.translateService.instant('_global_ok'),
                        this.translateService.instant('_global_cancel'),
                        'lg',
                        this.item,
                        dateChangeDifference,
                    )
                        .then((confirmed) => {
                            // Sætter deadline for alle opgaver (sættes i move-project-deadline-dialog.component.ts)
                            if (confirmed) {
                            }
                        })
                        .catch(() => {
                            console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
                        });
                }
            });
    }

    // </editor-fold>

}
