import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {QuillEditorConfig} from "@app/constants";
import QuillType from "quill";
import {ContentChange, QuillEditorComponent} from "ngx-quill";
import {noop} from "rxjs";

@Component({
    selector: 'app-rich-text-editor',
    templateUrl: './rich-text-editor.component.html',
    styleUrls: ['./rich-text-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RichTextEditorComponent),
            multi: true
        }
    ],
    standalone: false
})
export class RichTextEditorComponent extends BaseDisplayComponent implements OnInit, ControlValueAccessor {

    // #quillEditorComponent
    @ViewChild(QuillEditorComponent, {static: true}) quillEditorComponent: QuillEditorComponent;

    @Input() parentFormControl: UntypedFormControl;
    @Input() label: string;
    @Input() hideLabel: boolean = false;

    @Output() changeEvent: EventEmitter<any> = new EventEmitter();

    quillEditorConfig = QuillEditorConfig;
    private quillEditor: QuillType;

    constructor() {
        super();
    }

    ngOnInit() {
    }

    onEditorCreated($event: any) {
        this.quillEditor = $event.editor;
    }

    focusQuill($event: MouseEvent) {
        // this.quillEditor.setSelection(0, 0);
        // console.log('focusQuill : ', $event);
        // this.quillEditor.focus();
    }

    modelChanged($event: any) {
        this.innerValue = this.parentFormControl.value;
        this.changeEvent.emit($event);
    }


    // ControlValueAccessor (ngModel)

    //The internal data model
    public innerValue: any = '';
    //Placeholders for the callbacks which are later providesd
    //by the Control Value Accessor
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    //get accessor
    get value(): any {
        return this.innerValue;
    };

    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChangeCallback(v);
        }
    }

    //Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        if(!this.parentFormControl){
            this.parentFormControl = new UntypedFormControl();

        }
        if(this.parentFormControl.value != value){
            this.parentFormControl.setValue(value);
        }

        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    private debounceTimeout:any = null;
    contentChanged($event: ContentChange) {
        this.value = this.parentFormControl.value; // Set model value and emit change
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(()=>{
            this.changeEvent.emit();
        }, 1000);
    }
}
