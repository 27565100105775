import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {Category, CategoryType, Project} from "@app/core/models";

@Component({
    selector: 'app-prioritization-dialog',
    templateUrl: './prioritization-dialog.component.html',
    styleUrls: ['./prioritization-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PrioritizationDialog extends BaseModalComponent implements OnInit {

    @Input() project: Project;
    @Input() categoryTypeId: number;
    @Input() primaryClass: string = 'btn-success';
    @Input() color1: string;
    @Input() color2: string;
    @Input() color3: string;
    @Input() color4: string;
    @Input() text1: string;
    @Input() text2: string;
    @Input() text3: string;
    @Input() text4: string;
    @Input() axisX: string;
    @Input() axisY: string;

    public categoryType: CategoryType;
    public presetValue?: Category[];
    public selectedCategory?: Category;

    constructor(private modal: NgbActiveModal, private cd: ChangeDetectorRef) {
        super(modal);
        this.cdr = cd;
    }

    ngOnInit() {
        this.loadData();
    }

    private loadData() {
        if (!this.project || !this.categoryTypeId) {
            return;
        }

        this.presetValue = this.project.categories?.filter(category => category.category_type_id == this.categoryTypeId) ?? [];
        if (this.presetValue?.length > 0) {
            this.selectedCategory = this.presetValue[0];
        }

        CategoryType.GetById(this.categoryTypeId, categoryType => {
            this.categoryType = categoryType;
            this.detectChanges();
        })
    }

    public setSelectedCategory(category: Category) {
        this.selectedCategory = category;
    }

    public accept() {
        if (this.selectedCategory) {
            // Remove all existing categories of this type
            this.project.categories = this.project.categories?.filter(category => category.category_type_id !== this.categoryTypeId) ?? [];
            this.project.categories.push(this.selectedCategory);
            this.project.setCategoriesForType(this.categoryTypeId, [this.selectedCategory]);
            super.accept();
        }
    }


}
