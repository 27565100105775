import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {Api} from "@app/core/Api";
import {
    Milestone,
    Phase,
    PhaseProgress,
    PhaseProgressType,
    PhasesProject,
    Project, ProjectType,
    Task
} from "@app/core/models";
import {CardMilestoneConfiguration} from "@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {CardMilestoneComponent} from "@app/shared/_ui/cards/medium/card-milestone/card-milestone.component";
import {EventService} from "@app/services/event.service";
import {AppInjector} from "@app/services/app-injector.service";
import {Events, StatusTypes, TaskStatusTypes, TaskUserTypes} from "@app/constants";
import {SnackbarService} from "@app/services/snackbar.service";
import {EditorPanelService} from '@app/services/editor-panel.service';
import {ProjectsService} from '@app/services/projects.service';
import {CreateItemSourceConfiguration} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration";
import {CreateItemPreset} from "@app/shared/_ui/create-item-dropdown/CreateItemPreset";
import {CreateItemSourceInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceInterface";
import {CreateItemInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemInterface";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    TaskMilestonePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskMilestonePresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {RxStompService} from "@app/core/rabbitmq/rx-stomp.service";
import {ChangeMessage} from "@app/core/rabbitmq/ChangeMessage";

export interface PhaseMilestoneRow {
    phasesProject: PhasesProject;
    milestones: MilestoneRow[];
    milestoneTasksComplete: Map<number, number>;
}

export interface MilestoneRow {
    card: CardItem<Milestone>;
    createItemSourceConfiguration: CreateItemSourceConfiguration;
    createItemPreset: CreateItemPreset;
}

@Component({
    selector: 'app-process-steps',
    templateUrl: './process-steps.component.html',
    styleUrls: ['./process-steps.component.scss'],
    standalone: false
})
export class ProcessStepsComponent extends BaseDisplayComponent implements OnInit {

    @Input() projectId: number;
    @Input() showAppender: boolean = false;
    @Output() onPhasesProjectChange: EventEmitter<PhasesProject> = new EventEmitter();

    public project: Project;
    private isProjectLoaded: boolean = false;
    public milestoneCards: CardItem<Milestone>[] = [];
    public phaseMilestoneRows: PhaseMilestoneRow[] = [];
    public phaseProgressTypes: PhaseProgressType[];
    private phasesProjectOriginalIndexMap: Map<number, number>;

    // Data
    private projectType?: ProjectType;

    constructor(
        private eventService: EventService,
        private projectService: ProjectsService,
        private eventsService: EventService,
        private rxStompService: RxStompService,
        private snackbar: SnackbarService,
        private editorPanelService: EditorPanelService,
        private cd: ChangeDetectorRef
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        PhaseProgressType.getAll((types: PhaseProgressType[]) => {
            this.phaseProgressTypes = types;
            this.loadProject(true);
        });

        // this.subscribe(this.eventService.subscribeToTask(0, event => {
        //     console.log('task updated : ', event)
        //     switch (event.action){
        //         case EventService.Created:
        //             this.
        //             break;
        //     }
        // }));
        //
        // this.subscribe(this.eventService.subscribeToMilestone(0, event => {
        //     console.log('milestone updated : ', event)
        // }));


    }

    public loadProject(force: boolean = false) {
        if (this.isProjectLoaded && force == false) {
            return;
        }

        // Reset view
        this.project = null;

        // Prepare query
        let taskOrdering = 'main_status.status_id:desc,tasks_deadline.deadline.date:null,tasks_deadline.deadline.date:asc,title:asc';
        let taskInclude = 'archived,task_estimate';
        let taskSubQuery = `?ordering=${encodeURIComponent(taskOrdering)}&include=${encodeURIComponent(taskInclude)}`;

        let milestoneOrdering = 'deadline.id:null,deadline.date:asc,milestones_project.index_:asc';
        let milestoneInclude = `responsible,task${taskSubQuery}`;
        let milestoneSubQuery = `?ordering=${encodeURIComponent(milestoneOrdering)}&include=${encodeURIComponent(milestoneInclude)}`;

        Api
            .projects().getById(this.projectId)
            .include(`milestone${milestoneSubQuery}`)
            .include('phases_project')
            .include('project_status?include=status')
            .find(projects => {
                if (projects?.length == 1) {
                    this.setupProject(projects[0]);
                } else {
                    console.error('Unknown project id');
                }
            });
    }

    private setupProject(project: Project) {
        this.project = project;

        this.subscribe(AppInjector.getInjector().get(EventService).subscribeToMilestone(0, event => {
            const eventMilestone: Milestone = event.item as Milestone;
            switch (event.action) {
                case EventService.Deleted:
                    this.milestoneCards = this.milestoneCards.filter(mc => mc.item.id != eventMilestone.id);
                    this.phaseMilestoneRows.forEach(phaseMilestoneRow => {
                        const index = phaseMilestoneRow.milestones?.findIndex(milestone => milestone.card.item.id == eventMilestone.id) ?? -1;
                        if (index !== -1) {
                            phaseMilestoneRow.milestones.splice(index, 1);
                        }
                    });
                    this.detectChanges();
                    break;
                case EventService.Created:
                    if (eventMilestone.projects) {
                        eventMilestone.projects.forEach(p => {
                            if (p.id == this.project.id) {
                                const mp = new Milestone(eventMilestone);
                                delete mp.projects;
                                this.addRow(mp);
                            }
                        })
                    }

                    this.detectChanges();
                    break;
            }
        }));

        this.subscribe(AppInjector.getInjector().get(EventService).subscribe(new Task(), event => {
            const eventTask: Task = event.item as Task;
            switch (event.action) {
                case EventService.Updated:
                    console.log('Task updated: ', event.item);
                    this.milestoneCards.forEach(cm => {
                        const task = cm.item.tasks.find(t => t.id == event.item.id);
                        if (task) task.title = event.item.title;
                    })
                    break;
                case EventService.Deleted:
                    console.log('Task deleted - remove row: ', event.item);
                    this.milestoneCards.forEach(cm => {
                        cm.item.tasks = cm.item.tasks.filter(t => t.id != event.item.id);
                    })
                    this.detectChanges();
                    break;

                case EventService.Created:
                    console.log('Task created - add row: ', event.item);

                    if (eventTask.milestones) {
                        eventTask.milestones.forEach(ms => {
                            const milestone = this.milestoneCards.find(m => m.item.id == ms.id);
                            if (milestone) {
                                milestone.item.tasks.push(eventTask);
                            }
                        })
                        this.detectChanges();
                    }
                    break;
            }
        }));

        this.subscribe(AppInjector.getInjector().get(EventService).subscribe(this.project, event => {
            switch (event.action) {
                case EventService.Created:
                case EventService.Updated:
                    // if (this.project && false) {
                    //     console.log('process-steps : EventService.Updated : ', event, 'this.project: ', this.project);
                    //     if(event.item['current_phases_project'] && this.phaseProgressTypes) {
                    //         event.item['current_phases_project'].current_phase_progress.to_phase_progress_type = this.phaseProgressTypes.find(ppt => {
                    //             return ppt.id == event.item['current_phases_project'].current_phase_progress.to_phase_progress_type_id;
                    //         });
                    //     }
                    //     this.project.populate(event.item, event.isPatch);
                    //
                    //     // console.log('process-steps : EventService.Updated : ', event, 'this.project: ', this.project);
                    //
                    //     this.phaseMilestoneRows = [];
                    //     this.updateProjectValues();
                    // }
                    break;
            }
        }));

        this.subscribe(this.rxStompService
            .watch(Events.ProjectPhasesProjectsChanged(this.project.id))
            .subscribe(message => {
                const change = ChangeMessage.ParseRabbitMQMessage(message.body);
                const currentPhasesProject = (change.extra as any).currentPhasesProject;
                this.project.current_phases_project = currentPhasesProject ? new PhasesProject(currentPhasesProject) : null;
                this.project.phases_projects = (change.next as any[]).map(next => new PhasesProject(next)) || [];
                // Order phases projects according to original index
                this.project.phases_projects.sort((a, b) => {
                    const aIndex = this.phasesProjectOriginalIndexMap?.get(a.id) ?? this.project.phases_projects.length;
                    const bIndex = this.phasesProjectOriginalIndexMap?.get(b.id) ?? this.project.phases_projects.length;
                    return aIndex - bIndex;
                });
                this.phaseMilestoneRows = [];
                this.updateProjectValues();
            }));

        if (!project.milestones) {
            project.milestones = [];
        }

        // Set original phases project index for later use in push
        this.phasesProjectOriginalIndexMap = new Map<number, number>();
        project?.phases_projects?.forEach((phasesProject, index) => {
            this.phasesProjectOriginalIndexMap.set(phasesProject.id, index);
        });

        this.projectService.getProjectType(this.project.project_type_id, projectType => {
            this.projectType = projectType;
        });

        this.updateProjectValues();
        this.isProjectLoaded = true;
    }

    updateProjectValues() {
        // Add phase to phases projects missing phase object
        this.project.phases_projects?.forEach(phasesProject => {
            Phase.GetSingle(phasesProject.phase_id, phase => {
                if (phase)
                    phasesProject.phase = phase;
            });
            if (this.project.current_phases_project)
                this.project.current_phases_project_id = this.project.current_phases_project.id;
        });

        this.generatePhases();
        this.detectChanges();
    }

    private generatePhases() {
        if (this.project && this.project.phases_projects) {

            this.project.phases_projects?.forEach((phasesProject) => {
                let phaseMilestoneRow = this.phaseMilestoneRows.find(pmr => pmr.phasesProject?.id == phasesProject.id);
                if (!phaseMilestoneRow) {
                    phaseMilestoneRow = {
                        phasesProject: phasesProject,
                        milestones: [],
                        milestoneTasksComplete: new Map()
                    };
                    this.phaseMilestoneRows.push(phaseMilestoneRow);
                }
            });

            this.project.milestones.forEach(milestone => {
                this.addRow(milestone);
            });
        }
    }

    private addRow(milestone: Milestone) {
        // Prepare milestone
        if (!milestone.tasks) {
            milestone.tasks = [];
        }
        milestone.projects = [this.project];

        // Add milestone card item
        const configuration = new CardMilestoneConfiguration();
        configuration.showDate = false;
        configuration.showResponsible = false;
        configuration.showReactions = false;
        configuration.showActions = false;
        configuration.inline = true;
        configuration.showProjectPhase = false;
        const card = new CardItem<Milestone>(milestone, configuration);
        this.milestoneCards.push(card);

        // Add task card items
        milestone.tasks.forEach(task => {
            if (!task.milestones) {
                task.milestones = [];
            }
            task.milestones.push(new Milestone({id: milestone.id}));
        });

        const row: MilestoneRow = {
            card: card,
            createItemSourceConfiguration: new CreateItemSourceConfiguration(),
            createItemPreset: new CreateItemPreset()
        }

        const thisClass = this;
        row.createItemSourceConfiguration.sourceInterface = new class implements CreateItemSourceInterface {
            prepareSource() {
                row.createItemSourceConfiguration.showTasks = true;
                row.createItemSourceConfiguration.showMilestone = false;
                row.createItemSourceConfiguration.showProjects = false;
                row.createItemSourceConfiguration.showTodo = false;
                row.createItemSourceConfiguration.showAppointments = false;
                row.createItemSourceConfiguration.filterTaskTypesById = thisClass.projectType?.project_types_task_types
                    ?.filter(projectTypesTaskType => projectTypesTaskType.visible)
                    ?.map(projectTypesTaskType => projectTypesTaskType.task_type_id) ?? [];
            }
        };
        row.createItemPreset.createTaskInterface = new class implements CreateItemInterface<Task> {
            createPresets(options?: any): CreatePreset[] {
                return [
                    new TaskUserPresetGenerator(TaskUserTypes.Creator, thisClass.usersService.user.id),
                    new TaskUseStatusRulesPresetGenerator(true),
                    new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                    new TaskProjectPresetGenerator(thisClass.project.id),
                    new TaskMilestonePresetGenerator(row.card.item.id),
                    ...thisClass.project.departments?.map(department => {
                        return new TaskDepartmentPresetGenerator(department.id);
                    }),
                ].map(generator => generator.generate());
            }
        };

        const date = milestone.deadline_id != 0 && milestone.deadline != null ? milestone.deadline.getDate() : null;
        if (date) {
            const phasesProject = CardMilestoneComponent.CurrentPhasesProject(date, this.project);
            if (phasesProject) {
                const phaseMilestoneRow = this.phaseMilestoneRows.find(pmr => pmr.phasesProject.id == phasesProject.id);
                if (phaseMilestoneRow) {
                    phaseMilestoneRow.milestones.push(row);
                    this.updateMilestoneRowStatus(phaseMilestoneRow, card);
                }
            }
        }
    }

    private updateMilestoneRowStatus(phaseMilestoneRow: PhaseMilestoneRow, card: CardItem<Milestone>) {
        const completed = card.item.tasks.filter(t => t.isArchived());
        phaseMilestoneRow.milestoneTasksComplete.set(card.item.id, completed.length);
    }

    private movePhasesProjectIfAllTasksComplete(phaseMilestoneRow: PhaseMilestoneRow) {
        let allPhaseMilestoneTasksComplete = true;
        phaseMilestoneRow.milestones.forEach(row => {
            if (row.card.item.tasks.length > 0 && row.card.item.tasks.filter(t => t.archived_id == 0).length != 0) {
                allPhaseMilestoneTasksComplete = false;
            }
        });
        if (allPhaseMilestoneTasksComplete) {
            // Hvis alle opgaver er fuldført, skift til næste fase såfremt der findes flere faser
            const index = this.phaseMilestoneRows.findIndex(pmr => pmr == phaseMilestoneRow);
            if (index != -1 && index < this.phaseMilestoneRows.length - 1 && this.phaseMilestoneRows.length > index + 1) {
                const nextPhasesProject = this.phaseMilestoneRows[index + 1].phasesProject;
                if (this.project.current_phases_project_id !== nextPhasesProject.id) {
                    if (nextPhasesProject) {
                        this.setProjectPhasesProject(nextPhasesProject);
                    }
                }
            }
        }
    }

    // Sætter fasefremdrift
    setPhasesProjectProgressType(phaseProgressType: PhaseProgressType,
                                 phasesProject: PhasesProject,
    ) {
        if (!this.project.current_phases_project || this.project.current_phases_project.phase_id != phasesProject.phase_id) {
            this.project.current_phases_project.phase = phasesProject.phase;
            this.project.current_phases_project.phase_id = phasesProject.phase_id;
            this.setProjectPhasesProject(phasesProject, () => {
                this.updateProjectValues();
                this.setPhasesProjectProgressType(phaseProgressType, phasesProject);
            });
            return;
        } else {
            this.project.current_phases_project.phase = phasesProject.phase;
            this.project.current_phases_project.phase_id = phasesProject.phase_id;
            this.project.current_phases_project = phasesProject;
            this.project.current_phases_project.is_current = true;
            this.project.current_phases_project_id = this.project.current_phases_project.id;
        }

        if (!phasesProject.current_phase_progress) {
            const phasesProjectProgress = new PhaseProgress();
            phasesProjectProgress.phases_project_id = phasesProject.id;
            phasesProject.current_phase_progress = phasesProjectProgress;

        }

        console.log('setPhasesProjectProgressType() : ', this.project, 'phasesProject : ', phasesProject);


        // if(!this.project.current_phases_project.current_phase_progress){
        //     this.project.current_phases_project.current_phase_progress = new PhaseProgress();
        // }


        phasesProject.current_phase_progress.to_phase_progress_type_id = phaseProgressType.id;
        phasesProject.current_phase_progress.to_phase_progress_type = this.phaseProgressTypes.find(ppt => ppt.id == phaseProgressType.id);

        if (this.project.current_phases_project && this.project.current_phases_project.current_phase_progress) {
            this.project.current_phases_project.current_phase_progress.to_phase_progress_type_id = phasesProject.current_phase_progress.to_phase_progress_type_id;
            this.project.current_phases_project.current_phase_progress.to_phase_progress_type = phasesProject.current_phase_progress.to_phase_progress_type;
        }


        Api.projects().phasesProjectProgressPutByProjectIdByPhasesProjectId(this.project.id, phasesProject.id)
            .phaseProgressTypeId(phaseProgressType.id)
            .save(null, value => {
                const p = this.generateTempProject(this.project);
                this.project.current_phases_project_id = p.current_phases_project_id = phasesProject.id;
                this.project.current_phases_project = p.current_phases_project = phasesProject;

                // Skift "aktiv" fase

                this.snackbar.add(this.translateService.instant('_ui_item_saved', {item: this.translateService.instant('_ui_filter_label_select_phase_progress_type')}));


                AppInjector.getInjector().get(EventService).emitProject(p, EventService.Updated, [
                    'current_phases_project',
                    'phases_projects',
                ]);
            });
    }

    toggleAllTasksArchived(cardMilestone: CardItem<Milestone>, phaseMilestoneRow: PhaseMilestoneRow) {
        cardMilestone.item.tasks.forEach((task) => {
            if (!task.archived_id || task.archived_id == 0) {
                this.toggleTaskArchived(task, cardMilestone, phaseMilestoneRow);
            }
        })
    }

    toggleTaskArchived(task: Task, cardMilestone: CardItem<Milestone>, phaseMilestoneRow: PhaseMilestoneRow) {
        task.setArchived(!task.isArchived())
        this.updateMilestoneRowStatus(phaseMilestoneRow, cardMilestone);
        this.movePhasesProjectIfAllTasksComplete(phaseMilestoneRow);
    }

    setProjectPhasesProject(phasesProject: PhasesProject, callback?: () => void) {
        const p = this.generateTempProject(this.project);
        this.project.setCurrentPhasesProject(phasesProject);

        this.onPhasesProjectChange.emit(phasesProject);
        this.snackbar.add(this.translateService.instant('_ui_item_saved', {item: this.translateService.instant('_display_projects_phase')}), null, null, null, false);
        if (callback) {
            callback();
        }
    }

    generateTempProject(project: Project): Project {
        const p = new Project();
        p.id = this.project.id;
        p.title = this.project.title;
        p.main_status = this.project.main_status;
        p.main_status_id = this.project.main_status_id;
        p.project_type_id = this.project.project_type_id;
        p.phases_projects = this.project.phases_projects;
        p.projects_deadlines = this.project.projects_deadlines;
        p.reactions = this.project.reactions;
        p.archived_id = this.project.archived_id;
        return p;
    }

}
