import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditLink} from "@app/editor/quick-editor/editors/generic/link-editor/EditLink";
import {EditLinkConfiguration} from "@app/editor/quick-editor/editors/generic/link-editor/EditLinkConfiguration";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";

@Component({
    selector: 'app-link-editor',
    templateUrl: './link-editor.component.html',
    styleUrls: ['./link-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LinkEditorComponent extends BaseEditor<EditLink> {
    protected eventFieldName = 'link';

    // Bindings to parent
    @Input() model: EditLink & HasEventGenerator & BaseModel;
    @Input() configuration: EditLinkConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public value: string;
    public isEditMode = false;
    public isExpanded = false;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribe(this.configuration?.onChangeEvent.subscribe(() => this.render()));
    }

    protected setup() {
    }

    protected render() {
        this.value = this.model.getLink();
        this.isExpanded = this.configuration?.isExpanded;
        this.detectChanges();
    }

    toggleExpanded() {
        this.configuration?.setIsExpanded(!this.isExpanded);
    }

    public save() {
        this.isEditMode = false;
        if (this.model.getLink().localeCompare(this.value) !== 0) {
            this.model.setLink(this.value);
            this.onItemUpdated();
        }
    }


}
