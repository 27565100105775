import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    ProjectDeadlineListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectDeadlineListTableColumn";
import {
    ProjectDeadlineListCell
} from "@app/pages/displays/display-project-details/subdisplay-top/Cells/ProjectDeadlineListCell";
import {ColumnTypeSettings} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypeSettings";
import {
    ProjectDetails_Top_ProjectDeadlineTypesSettingValue,
} from "@app/core/Api";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";

export class ProjectDeadlineListColumn extends BaseColumn<ProjectDeadlineListCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new ProjectDeadlineListTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: Row): BaseCell {
        return new ProjectDeadlineListCell(row, this);
    }

    public getDeadlineTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.ProjectDeadlineTypes)
            ?.getObject<ProjectDetails_Top_ProjectDeadlineTypesSettingValue>()
            ?.projectDeadlineTypeIds ?? [];
    }

}
