import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef, EventEmitter,
    Input,
    OnChanges,
    OnInit, Output,
    SimpleChanges, ViewChild
} from '@angular/core';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {ProjectType, Task, TaskType} from '@app/core/models';
import {BaseEditorV3Component} from '@app/editor/base-editor-v3/base-editor-v3.component';
import {Tabs} from '@app/constants';
import {TranslateService} from '@ngx-translate/core';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {ValidatorCollection} from '@app/editor/Validation/ValidatorCollection';
import {ValidationErrorInterface} from '@app/editor/Validation/ValidationErrorInterface';
import {Field} from '@app/editor/task-editor-loader/task-editor.service';
import { Fields } from '../Fields';

@Component({
    selector: 'app-task-editor-v3',
    templateUrl: './task-editor-v3.component.html',
    styleUrls: [
        '../../base-editor/base-editor.component.scss',
        './task-editor-v3.component.scss',
    ],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TaskEditorV3Component extends BaseEditorV3Component implements OnInit, OnChanges {

    // Bindings to parent
    @Input() item!: Task;
    @Input() configuration!: TaskType;
    @Input() fields: Map<number, Field>;
    @Input() projectTypes: ProjectType[];
    @Input() options: any;
    @Output() onSavedEventEmitter = new EventEmitter<Task>();
    @Output() onReloadEventEmitter = new EventEmitter();
    @Output() onCloseEventEmitter = new EventEmitter();

    // Bindings to view
    public isReady = false;
    public isOpen = true;
    public selectedTab = Tabs.Basic;
    public validator = new ValidatorCollection([]);
    public basicTabValidator: ValidationErrorInterface;
    public categoriesTabValidator: ValidationErrorInterface;
    public attachToTabValidator: ValidationErrorInterface;
    public usersTabValidator: ValidationErrorInterface;
    public logTabValidator: ValidationErrorInterface;
    public onLogTabOpenEventEmitter = new EventEmitter<boolean>();

    // </editor-fold>

    protected readonly Fields = Fields;

    // Data

    constructor(
        private cd: ChangeDetectorRef,
        private translateService: TranslateService,
        private dialogService: BaseDialogService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['item']) {
            this.open();
        }
    }

    // <editor-fold desc="View actions">

    public onCloseBtnClicked() {
        this.close();
    }

    public onDeleteBtnClicked() {
        this.dialogService.confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant(`_ui_delete_item`, {name: this.item.title}),
            this.translateService.instant('_global_delete'),
        )
            .then((confirmed) => {
                if (confirmed) {
                    this.item.delete(() => this.close());
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    public onTabChanged($event: any) {
        this.selectedTab = $event.nextId;
        switch ($event.nextId) {
            case Tabs.Logs:
                this.onLogTabOpenEventEmitter.emit(true);
                break;
        }
    }

    public onTitleValidatorChanged(validator: ValidationErrorInterface) {
        this.validator.addValidator(validator);
    }

    public onBasicTabValidatorChanged(validator: ValidationErrorInterface) {
        this.basicTabValidator = validator;
        this.validator.addValidator(validator);
    }

    public onCategoriesTabValidatorChanged(validator: ValidationErrorInterface) {
        this.categoriesTabValidator = validator;
        this.validator.addValidator(validator);
    }

    public onAttachToTabValidatorChanged(validator: ValidationErrorInterface) {
        this.attachToTabValidator = validator;
        this.validator.addValidator(validator);
    }

    public onUsersTabValidatorChanged(validator: ValidationErrorInterface) {
        this.usersTabValidator = validator;
        this.validator.addValidator(validator);
    }

    public onLogTabValidatorChanged(validator: ValidationErrorInterface) {
        this.logTabValidator = validator;
        this.validator.addValidator(validator);
    }

    protected open() {
        super.open();
        this.isReady = false;
        this.isOpen = true;

        if (this.options && this.options.selectedTab) {
            this.selectedTab = this.options.selectedTab;
        }

        this.render();
    }

    private close() {
        this.onCloseEventEmitter.emit();
    }

    private render() {
        this.isReady = true;
    }
}
