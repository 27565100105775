import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {EditProjectList} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectList";
import {
    EditProjectListConfiguration
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListConfiguration";
import {Project} from "@app/core/models";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-project-list-editor',
    templateUrl: './project-list-editor.component.html',
    styleUrls: ['./project-list-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectListEditorComponent extends BaseEditor<EditProjectList> {
    protected eventFieldName = 'projects';

    // Bindings to parent
    @Input() model: EditProjectList&HasEventGenerator&BaseModel;
    @Input() configuration: EditProjectListConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public items: Project[];

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribe(this.configuration?.onChangeEvent.subscribe(() => this.render()));
    }

    protected setup() {
    }

    protected render() {
        this.items = this.model.getProjects(this.configuration.filter);
        this.validate();
        this.detectChanges();
    }

    public onProjectAdded(item: Project) {
        this.model.addProject(item, this.configuration.filter, () => {
            this.onItemUpdated();
            this.render();
        });
        this.configuration.onProjectAddedEvent.emit(item);
    }

    public onProjectRemoved(item: Project) {
        this.model.removeProject(item, this.configuration.filter);
        this.onItemUpdated();
        this.render();
        this.configuration.onProjectRemovedEvent.emit(item);
    }

    private validate() {
        this.configuration.validator.validate(
            this.items,
            AppInjector.getInjector().get(TranslateService).instant(this.configuration.label)
        );
    }

}
