import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {MilestonePlan, MilestoneTemplate, Phase, Project, ProjectsDeadline} from '@app/core/models';
import moment from "moment";
import {Moment} from "moment";
import {MTMilestoneDeadlineTypes} from '@app/constants';
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';
import Helpers from '@app/core/helpers';
import {Api} from '@app/core/Api';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';

class TimelineItem {
    public name: string;
    public deadline: Moment;
    public columnStart: number = 1;
    public columnEnd: number = 2;
    public type: string = 'grid-item';
    public itemType: string;
    public description: string;
    public row: number = 0;
    public label: string;
    public color: string;

}

@Component({
    selector: 'app-milestone-plan-timeline',
    templateUrl: './milestone-plan-timeline.component.html',
    styleUrls: ['./milestone-plan-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MilestonePlanTimelineComponent extends BaseDisplayComponent implements OnInit, OnChanges {


    @Input() milestonePlan: MilestonePlan;
    @Input() project: Project;
    @Input() defaultDays = 30;
    public timelineItems: TimelineItem[] = [];
    public days: number;
    public start: moment.Moment;
    public loading: boolean = false;


    constructor(private _cdr: ChangeDetectorRef) {
        super();
        this.cdr = _cdr;
    }

    ngOnInit(): void {
        this.generateItems();
    }


    private updatePeriod(){
        if(this.project && this.project.projects_deadlines){
        let endDeadline = this.project?.projects_deadlines?.find(pd => pd.project_deadline_type_id == this.milestonePlan.end_project_deadline_type_id)?.deadline;
        if(endDeadline) {
            endDeadline.date = Helpers.serverDate(moment(moment().startOf('month').toDate()).add(this.defaultDays, 'days').toDate());
            this.generateItems();
        }
        }

    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['defaultDays']){
            this.updatePeriod();
        }
        if(changes['milestonePlan']){
            this.generateItems();
        }
        this.detectChanges();
    }

    private getDeadline(milestoneTemplate: MilestoneTemplate,
                        start: moment.Moment,
                        end: moment.Moment,
                        lastMilestoneTimelineItem: TimelineItem):moment.Moment{

        // deadline = new Deadline();
        // value = this->deadline_value;
        // date = 0;
        let deadline = moment();
        let value = milestoneTemplate.deadline_value;
        switch(parseInt(String(milestoneTemplate.deadline_type))) {
            case MTMilestoneDeadlineTypes.NONE:
                return null;
            case MTMilestoneDeadlineTypes.PERCENT_AFTER_START:
                let difference = Math.abs(start.diff(end, 'days')); // Total dage i periode
                let days = Math.floor((difference * (value / 100)));
                deadline = moment(start).add(days, 'days');
                break;
            case MTMilestoneDeadlineTypes.DAYS_BEFORE_START:
                deadline = moment(start).subtract(value, 'days');
                break;
            case MTMilestoneDeadlineTypes.DAYS_AFTER_START:
                deadline = moment(start).add(value, 'days');
                break;
            case MTMilestoneDeadlineTypes.DAYS_BEFORE_END:
                deadline = moment(end).subtract(value, 'days');
                break;
            case MTMilestoneDeadlineTypes.DAYS_AFTER_END:
                deadline = moment(end).add(value, 'days');
                break;
            case MTMilestoneDeadlineTypes.DAYS_AFTER_LAST_MILESTONE:
                if(lastMilestoneTimelineItem) {
                    deadline = moment(lastMilestoneTimelineItem.deadline).add(value, 'days');
                }
                break;
            case MTMilestoneDeadlineTypes.DAYS_BEFORE_LAST_MILESTONE:
                deadline = moment(lastMilestoneTimelineItem.deadline).subtract(value, 'days');
                break;

            default:
                return null;
        }
        return deadline;
    }

    public generateItems(){
        if(!this.project) {
            console.warn('generateItems() : missing project : ',  this.project, 'this.milestonePlan', this.milestonePlan);
            let startPD = ProjectsDeadline.createDefault();
            startPD.project_deadline_type_id = this.milestonePlan.start_project_deadline_type_id;
            startPD.deadline.date = Helpers.serverDate(moment().startOf('month').toDate());

            let endPD = ProjectsDeadline.createDefault();
            endPD.project_deadline_type_id = this.milestonePlan.end_project_deadline_type_id;
            endPD.deadline.date = Helpers.serverDate(moment().startOf('month').add(this.defaultDays, 'days').toDate());
            let project = new Project();
            project.projects_deadlines = [startPD, endPD];
            this.project = project;
        }

        this.timelineItems = [];
        // Backend, MilestonePlan -> copyToProject()

        let deadlineA = this.project?.projects_deadlines?.find(pd => pd.project_deadline_type_id == this.milestonePlan.start_project_deadline_type_id)?.deadline?.getDate();
        let deadlineB = this.project?.projects_deadlines?.find(pd => pd.project_deadline_type_id == this.milestonePlan.end_project_deadline_type_id)?.deadline?.getDate();

        if(!deadlineA || !deadlineB) {
            console.warn('deadlines missing on project : ', deadlineA, deadlineB, this.project);
        }

        if(this.milestonePlan?.id && this.milestonePlan?.milestone_templates == undefined && !this.loading){
            console.log('load templates : ', this.milestonePlan);
            this.loading = true;
            let taskTemplateInclude = `user`; // user,status_rule
            let milestonePlanInclude = `status_rule,phase.color`;
            Api.milestonePlans()
                .getById(this.milestonePlan.id)
                .include('user')
                .include('task_template?include=' + encodeURIComponent(taskTemplateInclude))
                .include('milestone_template?include=' + encodeURIComponent(milestonePlanInclude))
                .include('blocked_holiday')
                .find((plans)=>{
                this.milestonePlan.milestone_templates = plans[0].milestone_templates || [];
                this.loading = false;
                this.generateItems();
            })
        }

        if(deadlineA && deadlineB && this.milestonePlan?.milestone_templates) {

            let lastMilestoneTimelineItem: TimelineItem;
            let deadlines: moment.Moment[] = [];
            let phaseChanges: { phase: Phase, date: moment.Moment }[] = [];

            let start = moment(deadlineA);
            let end = moment(deadlineB);
            this.start = start;

            this.milestonePlan?.milestone_templates?.forEach((mt, index) => {
                let item = new TimelineItem();
                item.name = mt.title;
                item.itemType = AppInjector.getInjector().get(TranslateService).instant(`_milestone`)
                item.label = item.itemType.substr(0, 1);
                item.row = 2;

                let deadlineMoment = this.getDeadline(mt, start, end, lastMilestoneTimelineItem);
                if (deadlineMoment) {
                    const type = AppInjector.getInjector().get(TranslateService).instant(`_admin_milestone_plan_milestone_deadline_type_${mt.deadline_type}`);
                    item.description = `${mt.deadline_value} ${type}`;
                    item.deadline = deadlineMoment;
                    item.columnStart = Math.abs(item.deadline?.diff(start, 'days')) + 1;
                    item.columnEnd = item.columnStart + 1;

                    deadlines.push(moment(deadlineMoment).add(1, 'days'));
                    if (mt.phase) {
                        phaseChanges.push({
                            phase: mt.phase,
                            date: moment(deadlineMoment).add(1, 'days')
                        })
                    }
                }

                lastMilestoneTimelineItem = item;
                this.timelineItems.push(item);


            })

            if (deadlines.length > 0) {
                deadlines = deadlines.sort((a, b) => {
                    return a.toDate().getTime() - b.toDate().getTime();
                })

                if (start.isSameOrBefore(deadlines[0])) { // Tilføj projekets startdato såfremt det ligger først
                    deadlines = [start, ...deadlines];
                }

                let phaseItems: TimelineItem[] = [];
                phaseChanges.forEach(pc => {
                    // Find deadline lige før date (Fristen på mp over dette faseskift)
                    let lastIndex = deadlines.findIndex(d => d.toDate().getTime() == pc.date.toDate().getTime());
                    if (deadlines[lastIndex - 1]) { // Opret kun såfremt vi fandt en date før mp deadline ved faseskift
                        let phaseItem = new TimelineItem();
                        phaseItem.name = pc.phase.name;

                        phaseItem.deadline = deadlines[lastIndex - 1 ];
                        phaseItem.row = 1;
                        // phaseItem.columnStart = Math.abs(phaseItem.deadline?.diff(start, 'days'));
                        // phaseItem.columnEnd = phaseItem.columnStart + 1;
                        phaseItem.columnStart = (deadlines[lastIndex - 1].diff(start, 'days')) + 1;
                        phaseItem.columnEnd = (phaseItem.deadline?.diff(start, 'days')) + 1;
                        phaseItem.color = pc.phase?.color?.value;
                        phaseItem.itemType = AppInjector.getInjector().get(TranslateService).instant(`_phase_singular`)
                        phaseItem.label = phaseItem.itemType.substr(0, 1);
                        phaseItems.push(phaseItem);
                    }

                    phaseItems.sort((a, b) => a.deadline.toDate().getTime() - b.deadline.toDate().getTime())
                        .forEach((pi, i) => {
                        const next = phaseItems[i+1];
                        if(next){
                            pi.columnEnd = next.columnStart;
                        }
                    })
                })

                this.timelineItems = [...this.timelineItems, ...phaseItems];
            }

            let sItem = new TimelineItem();
            sItem.name = this.project?.title ? this.project?.title : AppInjector.getInjector().get(TranslateService).instant(`_project`);
            sItem.description = `${start.format('DD/MM')} - ${end.format('DD/MM')}`;
            sItem.itemType = AppInjector.getInjector().get(TranslateService).instant(`_project`)
            sItem.label = sItem.itemType.substr(0, 1);
            sItem.row = 0;
            sItem.columnStart = 1;
            sItem.deadline = start;
            sItem.columnEnd = Math.abs(start.diff(end, 'days')) + 1;
            this.timelineItems.push(sItem);

            let minValue = 0;
            let maxValue = sItem.columnEnd;
            this.timelineItems.forEach(ti => {
                minValue = Math.min(ti.columnStart, minValue);
                maxValue = Math.max(ti.columnEnd, maxValue);
            });
            for (let i = minValue; i < maxValue; i++) {
                let grid = new TimelineItem();
                grid.name = 'Gridlines';
                grid.type = 'gridline';
                grid.row = 0;
                grid.columnStart = i;
                grid.columnEnd = i + 1;
                this.timelineItems.push(grid);
            }
        }

        this.detectChanges();

    }

}
