import {BaseComponent} from "@app/shared/_system/base/base.component";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {Subscription} from "rxjs";
import {DragAndDropService} from "@app/services/drag-and-drop.service";
import {CdkDrag, CdkDragDrop, CdkDragEnter, CdkDragExit, CdkDropList} from "@angular/cdk/drag-drop";
import {DragDropHelper} from "@app/core/helpers/DragDropHelper";

@Component({
    selector: 'app-card-edit-expander',
    templateUrl: './card-edit-expander.component.html',
    styleUrls: ['./card-edit-expander.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardEditExpanderComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() items: CardItem[];
    @Input() showCreateNew = false;
    @Input() listConfiguration: ListConfiguration;
    @Input() listDragInterface: ListDragInterface;
    @Input() itemCount: number;

    // Bindings to view
    public dragListId = '_' + Math.random().toString(36).substr(2, 9);
    public dragDropList: string[] = [];
    public dragItemEnterPredicate = this._itemEnterPredicate.bind(this);
    public typeName = '';

    // Data
    private dropListSubscription: Subscription;

    constructor(private cd: ChangeDetectorRef,
                private dragAndDropService: DragAndDropService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['listConfiguration'] != null) {
            this.dropListSubscription?.unsubscribe();
            if (this.listConfiguration?.isDraggable()) {
                this.dropListSubscription = this.dragAndDropService.dropLists$.subscribe(list => {
                    this.dragDropList = list;
                    this.detectChanges();
                });
                this.dragAndDropService.addDropList(this.dragListId);           // Global drop list
            }
            this.detectChanges();
        }
    }

    public dragItemDropped(event: CdkDragDrop<ListDragInterface>) {
        DragDropHelper.CardItemDropEvent(event);
    }

    public _itemEnterPredicate(item: CdkDrag<CardItem>, event: CdkDropList<ListDragInterface>) {
        const prevListDragInterface = item.dropContainer.data;
        const nextListDragInterface = event.data;
        return nextListDragInterface.allowCardItemEnter(item.data, prevListDragInterface.configuration)
            && this.listConfiguration?.getAllowDragEnter();
    }

    public dropListEntered(event: CdkDragEnter) {
        this.cachePositions(event);
    }

    public dropListExited(event: CdkDragExit) {
        this.cachePositions(event);
    }

    private cachePositions(event: any) {
        setTimeout(() => {
            const dropListRef: any = event.container._dropListRef;
            if (dropListRef) {
                dropListRef['_cacheOwnPosition'] ? dropListRef['_cacheOwnPosition']() : null;
                dropListRef['_cacheItemPositions'] ? dropListRef['_cacheItemPositions']() : null;
            }
        });
    }

}
