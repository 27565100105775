/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {PeriodRatingDefinition} from './definitions/PeriodRatingDefinition';
import {Api} from "@app/core/Api";

export class PeriodRating extends PeriodRatingDefinition {

    constructor(json?: any) {
        super(json);
    }

    public patch(fields: string[]) {
        let data: any = {};
        for(let field of fields) {
            data[field] = (this as any)[field];
        }
        Api.periodRatings().patchById(this.id!).save(data)
    }

    public get loadInSeconds(): number {
        return (this.load_ ?? 0) / 60 / 60;
    }

}
