import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {BaseSearchComponent} from '../base-search/base-search.component';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {fadeAnimation} from '@app/animations';
import {Project, Tag} from '@app/core/models';
import {merge, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {NgbTypeaheadSelectItemEvent} from '@ng-bootstrap/ng-bootstrap';
import {Api} from "@app/core/Api";

@Component({
    selector: 'app-tag-search',
    templateUrl: './tag-search.component.html',
    styleUrls: ['../base-search/base-search.component.scss'],
    animations: [fadeAnimation],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagSearchComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TagSearchComponent),
            multi: true,
        }
    ],
    standalone: false
})
export class TagSearchComponent extends BaseSearchComponent implements OnInit {

    // Bindings to parent
    @Input() _internalValue: Tag[] = [];
    @Output() onChangeEvent: EventEmitter<Tag[]> = new EventEmitter();
    @Output() onItemAdded = new EventEmitter<Tag>();
    @Output() onItemRemoved = new EventEmitter<Tag>();

    // Bindings to view
    public tags: Tag[] = [];
    public model: any;
    public result: Tag[] = [];
    public createNewItemName = '';

    constructor() {
        super();
    }

    ngOnInit() {
        this.reloadTags();
    }

    private reloadTags() {
        Tag.GetAll((tags) => {
            this.tags = tags;
        });
    }

    search = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            tap(() => this.searching = true),
            map(term => {
                let r = this.tags
                    .filter(v => String(v.name)
                        .toLowerCase()
                        .indexOf(String(term)
                            .toLowerCase()) > -1)
                    .filter((item) => {
                        return !this.Helpers.itemExists(this.internalValue ? this.internalValue : [], item.id)
                    });

                this.result = r.length > 0 ? r : null;
                if (term !== '') {
                    this.searchFailed = this.result == null;
                } else {
                    this.searchFailed = false;
                }

                return term === '' ? this.tags.filter((item) => {
                    return !this.Helpers.itemExists(this.internalValue ? this.internalValue : [], item.id)
                }) : r;
            }),
            tap(() => this.searching = false),
        );
    };

    formatter = (x: Tag) => x.name;

    triggerSelection($event: NgbTypeaheadSelectItemEvent, input: any) {
        this.itemSelected.emit($event.item);
        $event.preventDefault();
        input.value = '';
        this.addItem($event.item)
    }

    addItem(item: Tag) {
        if (!this._internalValue) {
            this._internalValue = [];
        }
        this._internalValue.push(item);
        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
        this.model = null;
        this.onItemAdded.emit(item);
        this.onChangeEvent.emit(this._internalValue);
    }

    removeItem(item: Tag) {
        const index = this._internalValue.findIndex(p => p.id == item.id);
        if (index > -1) {
            this._internalValue.splice(index, 1);
        }
        this.onChange(this._internalValue); // Value is good!
        this.propagateChange(this.internalValue);
        this.onItemRemoved.emit(item);
        this.onChangeEvent.emit(this._internalValue);
    }

    createField() {
        const c: Tag = new Tag({id: 0});
        this.addItem(c);
    }

    public onCreateNewSaveBtnClicked() {
        if (this.createNewItemName.length == 0) {
            return;
        }
        const tag = new Tag();
        tag.name = this.createNewItemName;
        Api.tags().post().save(tag, newTag => {
            this.addItem(newTag);
            Tag.ClearCache(() => {
                this.reloadTags();
            });
        });
        this.createNewItemName = '';
    }

    resultTemplateType: {
        result: Tag,
        term: string,
    }

}
