import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {PhasesProject} from '@app/core/models/PhasesProject';
import {PhasesProjectRow} from '@app/shared/_ui/columns/phases-projects/PhasesProjectRow';
import moment from 'moment';
import {CSVCustomBinding} from '@app/export/csv/CSVCustomBinding';
import {Project} from "@app/core/models";
import {Events} from "@app/constants";
import {Api} from "@app/core/Api";
import {RxStompService} from "@app/core/rabbitmq/rx-stomp.service";
import {ChangeMessage} from "@app/core/rabbitmq/ChangeMessage";

@Component({
    selector: 'app-phases-projects',
    templateUrl: './phases-projects.component.html',
    styleUrls: ['./phases-projects.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PhasesProjectsComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    // Input
    @Input() project: Project;
    @Input() csvBinding: CSVCustomBinding;

    public currentPhasesProjectId: number;

    // Data bindings to view
    public items: PhasesProjectRow[];

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private rxStompService: RxStompService) {
        super();
        this.cdr = this.changeDetectorRef;
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.project) {
            this.subscribe(this.rxStompService
                .watch(Events.ProjectPhasesProjectsChanged(this.project.id))
                .subscribe(message => {
                    const change = ChangeMessage.ParseRabbitMQMessage(message.body);
                    const currentPhasesProject = (change.extra as any).currentPhasesProject;
                    this.project.current_phases_project = currentPhasesProject ? new PhasesProject(currentPhasesProject) : null;
                    this.project.phases_projects = (change.next as any[]).map(next => new PhasesProject(next));
                    this.render();
                }));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    // <editor-fold desc="View actions">

    public markCompleted(item: PhasesProjectRow, isCompleted: boolean) {
        item.isCompleted = isCompleted;
        if (isCompleted) {
            // Mark this one complete
            Api.projects().phasesProjectCompletePutByProjectIdByPhasesProjectId(this.project.id, item.phasesProject.id)
                .save(null);
        } else {
            // Mark this one incomplete
            Api.projects().phasesProjectIncompletePutByProjectIdByPhasesProjectId(this.project.id, item.phasesProject.id)
                .save(null);
        }
    }

    // </editor-fold>

    private render() {
        const items: PhasesProjectRow[] = [];
        let lastRow: PhasesProjectRow = null;
        this.project.phases_projects?.forEach(phasesProject => {
            const row = new PhasesProjectRow(phasesProject);
            if (row.hasDate && lastRow?.hasDate) {
                lastRow.durationInDays = Math.round(moment.duration(moment(row.date).diff(moment(lastRow.date))).asDays());
            }
            items.push(row);
            lastRow = row;
        });

        // Mark completed from top to currentPhasesProject
        this.currentPhasesProjectId = this.project.current_phases_project?.id ?? null;

        items.find(item => {
            if (item.phasesProject.id == this.currentPhasesProjectId) {
                return true; // Stop here
            } else {
                item.isCompleted = true;
                return false;
            }
        });

        this.items = items;
        this.detectChanges();

        if (this.csvBinding) {
            this.csvBinding.setExportFunction(option => this.calculateDurationInPhase(parseInt(option.value)).toString());
        }
    }

    private calculateDurationInPhase(phaseId: number): number {
        let days = 0;
        this.items.forEach(item => {
            if (item.phase.id == phaseId && item.durationInDays) {
                days += item.durationInDays;
            }
        });
        return days;
    }

}
