import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {LocalizedDatePipe} from '@app/pipes/localized-date.pipe';
import {ProjectYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/ProjectYearWheelItem";

@Component({
    selector: 'app-year-wheel-item-display-project',
    templateUrl: './year-wheel-item-display-project.component.html',
    styleUrls: ['./year-wheel-item-display-project.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalizedDatePipe,
    ],
    standalone: false
})
export class YearWheelItemDisplayProjectComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() item: ProjectYearWheelItem;

    // Bindings to view
    @ViewChild(NgbPopover, {static: false}) popover: NgbPopover;
    public title: string;
    public color: string;
    public className: string;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private localizedDate: LocalizedDatePipe
    ) {
        super();
        this.cdr = this.changeDetectorRef;
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['item'] != null) {
            let dateExtras = '';
            if (this.item.configuration && this.item.configuration.projectStartDeadline) {
                dateExtras += ': ' + (this.localizedDate.transform(this.item.configuration.projectStartDeadline.deadline?.date));
            }
            if (this.item.configuration && this.item.configuration.projectEndDeadline) {
                dateExtras += ' - ' + (this.localizedDate.transform(this.item.configuration.projectEndDeadline.deadline?.date));
            }
            this.title = this.item.item.title + dateExtras;
            this.className = 'project';
            this.detectChanges();
        }
    }

}
