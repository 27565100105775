import {ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, isDevMode} from '@angular/core';
import {SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {concat, interval, Subscription, timer} from 'rxjs';
import {environment} from '@env/environment';
import {filter, first} from 'rxjs/operators';
import {versions} from "@env/versions";
import {BaseComponent} from "@app/shared/_system/base/base.component";

@Component({
    selector: 'app-updates-notification',
    templateUrl: './updates-notification.component.html',
    styleUrls: ['./updates-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UpdatesNotificationComponent extends BaseComponent {

    version: string = environment.version;
    environment: any = environment;

    gitVersions: {
        npm_version: string;
        revision: string;
        branch: string;
        version: string;
        project_id: string
    } = versions;

    countDown: Subscription;
    counter = 120;
    tick = 1000;

    constructor(appRef: ApplicationRef,
                public updates: SwUpdate,
                private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
        if (isDevMode() || !updates.isEnabled) {
            return;
        }

        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable));
        const everyMinute = interval(60 * 1000);
        const everyMinuteOnceAppIsStable$ = concat(appIsStable$, everyMinute);
        everyMinuteOnceAppIsStable$.subscribe(async() => {
            try {
                const updateFound = await updates.checkForUpdate();
                if (updateFound) {
                    console.warn(updateFound ? 'A new version is available.' : 'Already on the latest version.');
                }
            } catch (err) {
                console.warn('Failed to check for updates:', err);
            }
        });

        updates.versionUpdates
            .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
            .subscribe((evt) => {
                this.createCountdown();
            });

        updates.unrecoverable.subscribe((event) => {
            this.createCountdown();
        });
    }

    createCountdown() {
        this.countDown = timer(0, this.tick)
            .subscribe(() => {
                --this.counter;
                if (this.counter == 0) {
                    location.reload();
                    this.countDown = null;
                }
                this.detectChanges();
            })
    }

    activateUpdate() {
        if (environment.production) {
            this.updates.activateUpdate().then(() => {
                window.location.reload();
            });
        } else {
            window.location.reload();
        }
    }

    ngOnDestroy() {
        this.countDown = null;
    }

}



