/**
 * Created by ModelParser
 * Date: 09-11-2020.
 * Time: 15:55.
 */
import {CalculatedFieldsProjectDefinition} from './definitions/CalculatedFieldsProjectDefinition';
import {RxStompService} from "@app/core/rabbitmq/rx-stomp.service";
import {ChangeMessage} from "@app/core/rabbitmq/ChangeMessage";
import {Subscription} from "rxjs";

export class CalculatedFieldsProject extends CalculatedFieldsProjectDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static CreateChangeSubscription(event: string, rxStompService: RxStompService, handler: (event: ChangeMessage<CalculatedFieldsProject>) => void): Subscription {
        return rxStompService
            .watch(event)
            .subscribe(message => handler(CalculatedFieldsProject.ParseChangeEvent(JSON.parse(message.body))));
    }

    public static ParseChangeEvent(data: any): ChangeMessage<CalculatedFieldsProject> {
        const previous = new CalculatedFieldsProject(data.previous);
        const next = new CalculatedFieldsProject(data.next);
        return new ChangeMessage<CalculatedFieldsProject>(previous, next);
    }

}
