import {GenericColumn} from "@app/pages/displays/display-team/Columns/GenericColumn";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {ColumnTypeSettings} from "@app/pages/displays/display-team/Helpers/ColumnTypeSettings";
import {Team_CaseListTaskTypesSettingValue} from "@app/core/Api";
import {DisplayTeamRow} from "@app/pages/displays/display-team/DisplayTeamRow";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {CaseListCell} from "@app/pages/displays/display-team/Cells/CaseListCell";
import {CaseListTableColumn} from "@app/pages/displays/display-team/TableColumns/CaseListTableColumn";
import moment from "moment";

export class CaseListColumn extends GenericColumn
    implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [this.dataFetcher];
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TaskFetcher(
            undefined, undefined, undefined,
            column.name
        );
        this.dataFetcher.setTaskTypeIds(this.getTaskTypeIds());
    }

    public createTableColumns(): CaseListTableColumn[] {
        const item = new CaseListTableColumn(this);
        this.setTableColumnDefaults(item);
        return [item];
    }

    public createCell(row: DisplayTeamRow): CaseListCell {
        const cell = new CaseListCell(row, this);

        cell.taskListConfiguration
            .setTaskTypeIds(this.getTaskTypeIds());

        this.dataFetcher.addRequest(new TaskFetchRequest(cell.taskListConfiguration));
        return cell;
    }

    public getTaskTypeIds(): number[] {
        return this.settings.get(ColumnTypeSettings.CaseList_TaskTypes)
            ?.getObject<Team_CaseListTaskTypesSettingValue>()
            ?.taskTypeIds ?? [];
    }

    public setPeriod(start: Date, end: Date): void {
        this.dataFetcher.setPeriod(
            moment(start).startOf('isoWeek').toDate(),
            moment(end).endOf('isoWeek').toDate()
        );
    }

}
