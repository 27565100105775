/**
 * Created by ModelParser
 * Date: 08-02-2019.
 * Time: 09:13.
 */
import {DisplayFilterDefinition} from './definitions/DisplayFilterDefinition';
import {AppInjector} from '@app/services/app-injector.service';
import {EventService} from '@app/services/event.service';
import {Display} from '@app/core/models/Display';
import {UsersService} from "@app/services/users.service";
import {Api} from "@app/core/Api";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";

export class DisplayFilter extends DisplayFilterDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static CreateDefault(display: Display): DisplayFilter {
        let item = new DisplayFilter();
        item.sort_direction = 'asc';
        item.display = display;
        item.display_id = display.id;
        return item;
    }

    public generateName(settings: FiltersInterface): string {
        return settings.generateName(this);
    }

    public getPeriodStart(settings: FiltersInterface): Date {
        const sourceDate = AppInjector.getInjector().get(FilterGlobalService).getActiveSettings().period.start;
        if (this.period_start) {
            return new Date(this.period_start);
        }
        return settings.getPeriodStart(this.period_type, sourceDate);
    }

    public getPeriodEnd(settings: FiltersInterface): Date {
        const sourceDate = AppInjector.getInjector().get(FilterGlobalService).getActiveSettings().period.end;
        if (this.period_end) {
            return new Date(this.period_end);
        }
        return settings.getPeriodEnd(this.period_type, sourceDate, this.getPeriodStart(settings));
    }

    public getIncludeArchivedSinceDate(settings: FiltersInterface): Date {
        return settings.getIncludeArchivedSinceDate(this.include_archived_since_type, this.include_archived_since_date);
    }

    public getYearWheelPeriodStart(settings: FiltersInterface): Date {
        return settings.getYearWheelPeriodStart(this.year_wheel_period_type, this.year_wheel_period_start);
    }

    public getYearWheelPeriodEnd(settings: FiltersInterface): Date {
        return settings.getYearWheelPeriodEnd(this.year_wheel_period_type, this.year_wheel_period_end);
    }

    public showSortDirection(settings: FiltersInterface): boolean {
        return settings.sorts(this.display).find(item => item.id == this.sort_type)?.hasOrderDirection ?? false;
    }

    public getPeriodStartString(): string {
        if (!this.period_start) return '-';
        let date = new Date(this.period_start);
        return date.getDate() + '/' + (date.getMonth() + 1) + '-' + date.getFullYear()
    }

    public getPeriodEndString(): string {
        if (!this.period_end) return '-';
        let date = new Date(this.period_end);
        return date.getDate() + '/' + (date.getMonth() + 1) + '-' + date.getFullYear()
    }

    public getIncludeArchivedSincePeriodString(filtersSettings: FiltersInterface): string {
        let date = this.getIncludeArchivedSinceDate(filtersSettings);
        if (!date) return '-';
        return date.getDate() + '/' + (date.getMonth() + 1) + '-' + date.getFullYear()
    }

    public statusArray(): number[] {
        return this.statuses?.map(status => status.id) ?? [];
    }


    public delete(callback?: any) {
        Api.displayFilters().deleteById(this.id!)
            .delete(item => {
                if (callback) {
                    callback(item);
                    AppInjector.getInjector().get(EventService).emitDisplayFilter(this, EventService.Deleted);
                }
            });
    }

    public post(callback?: any) {
        let data = this;
        let displayCopy = this.display;
        data = DisplayFilter.cleanUpBeforeSave(data);
        Api.displayFilters().post()
            .save(data, item => {
                this.populate(item);
                if (callback) {
                    callback(item);
                    this.display = displayCopy;
                    AppInjector.getInjector().get(EventService).emitDisplayFilter(this, EventService.Created);
                }
            });
    }

    public patch(fields?: string[], callback?: any) {
        let data: any = {};
        if (fields) {
            for (let field of fields) {
                data[field] = (this as any)[field];
            }
        } else {
            data = this;
        }
        data = DisplayFilter.cleanUpBeforeSave(data);
        Api.displayFilters().patchById(this.id)
            .setShowProgressBar(false)
            .save(data, item => {
                if (callback) {
                    callback(item);
                    AppInjector.getInjector().get(EventService).emitDisplayFilter(this, EventService.Updated, fields);
                }
            });
    }

    private static cleanUpBeforeSave(data: any) {
        let copy = {...data};
        delete copy['display'];
        return copy;
    }

    public isMine(): boolean {
        return this.user_id == AppInjector.getInjector().get(UsersService).user?.id && !this.isDepartment();
    }

    public isDepartment(): boolean {
        return this.departments_display_filters?.length > 0;
    }

    public isOwnedDepartment(): boolean{
        return !this.isMine()
    }

    public getDisplayTypeId(): number {
        if (this.display) {
            return this.display.display_type_id;
        } else {
            console.error('Trying to use displayTypeId on DisplayFilter, bur got no display');
            return 0;
        }
    }

    public getLastUsedDate(): Date | undefined {
        if (this.my_settings?.last_used) {
            return new Date(this.my_settings.last_used);
        } else {
            return undefined;
        }
    }

    public setFavorite(departmentId: number, displayId: number, displayIdentifier?: string) {
        this.my_settings!.is_favorite = true;
        Api.displayFilters().addFavoritePutById(this.id)
            .departmentId(departmentId)
            .displayId(displayId)
            .displayIdentifier(displayIdentifier ?? '')
            .save(null);
    }

    public removeFavorite(departmentId: number, displayId: number, displayIdentifier?: string) {
        this.my_settings!.is_favorite = false;
        Api.displayFilters().removeFavoriteDeleteById(this.id)
            .departmentId(departmentId)
            .displayId(displayId)
            .displayIdentifier(displayIdentifier ?? '')
            .delete();
    }

    public markLastUsed(departmentId: number, displayId: number, displayIdentifier?: string) {
        Api.displayFilters().markLastUsedPutById(this.id)
            .departmentId(departmentId)
            .displayId(displayId)
            .displayIdentifier(displayIdentifier ?? '')
            .save(null, setting => {
                this.my_settings = setting;
            });
    }

    public setIsDefault(departmentId: number, displayId: number, displayIdentifier: string, value: boolean) {
        this.my_settings!.is_default = value;
        Api.displayFilters().markDefaultPutById(this.id)
            .departmentId(departmentId)
            .displayId(displayId)
            .displayIdentifier(displayIdentifier)
            .value(value)
            .save(null);
    }

    public setIsVisible(departmentId: number, displayId: number, displayIdentifier: string, value: boolean) {
        this.my_settings!.is_visible = value;
        Api.displayFilters().markVisiblePutById(this.id)
            .departmentId(departmentId)
            .displayId(displayId)
            .displayIdentifier(displayIdentifier)
            .value(value)
            .save(null);
    }

}
