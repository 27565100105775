import {Deadline, User} from "@app/core/models";
import moment from 'moment';
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";
import {EnhancedUser} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserItem";

export class WhoWhenItem {

    public user?: User;
    public deadline?: Deadline;
    public isArchived: boolean;

    constructor(data: EnhancedUser) {
        this.user = data.user;
        this.deadline = data.deadline;
        this.isArchived = data.isArchived;
    }

    public describe(wrapper: string = null): string {
        const elements = [];
        if (this.user) {
            const completed = this.isArchived ? '<i class="fal fa-check text-success"></i>' : '<i class="fal text-disabled fa-hyphen"></i>';
            elements.push(wrapper ? `<${wrapper}>${completed}${this.user.initials}</${wrapper}>` : completed+' '+this.user.initials);
        }
        if (this.deadline?.getDate()) {
            let deadlineString = moment(this.deadline.getDate()).format('DD/MM');
            if (this.deadline.is_soft) {
                deadlineString = AppInjector.getInjector().get(TranslateService).instant(
                    '_ui_week_no',
                    {
                        no: moment(this.deadline.getDate()).isoWeek()
                    }
                );
            }
            elements.push(wrapper ? `<${wrapper}>${deadlineString}</${wrapper}>` : deadlineString);
        }
        return elements.join(wrapper ? '' : ', ');
    }

}
