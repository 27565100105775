import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    TaskOrderListTableColumn
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/TableColumns/TaskOrderListTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/ColumnTypeSettings";
import {UserOverviewItems_TaskOrderList_TaskUserTypesSettingValue} from "@app/core/Api";

export class TaskOrderListColumn extends BaseColumn {

    public createTableColumns(): BaseTableColumn[] {
        return [new TaskOrderListTableColumn(this)];
    }

    public createCell(row: BaseRow): BaseCell {
        throw new Error("Method not implemented.");
    }

    public getTaskUserTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.TaskOrderList_TaskUserTypes)
            ?.getObject<UserOverviewItems_TaskOrderList_TaskUserTypesSettingValue>()
            ?.taskUserTypeIds ?? [];
    }

}
