import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {Task} from "@app/core/models/Task";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ShellService} from "@app/services/ShellService/shell.service";
import {ProjectsService} from "@app/services/projects.service";
import {ProjectType, TaskType} from "@app/core/models";
import {Api} from "@app/core/Api";
import {CountRunner} from "@app/core/CountRunner/CountRunner";
import {Field, TaskEditorService} from "@app/editor/task-editor-loader/task-editor.service";
import {TasksService} from "@app/services/tasks.service";
import {AppInjector} from "@app/services/app-injector.service";
import {EventService} from "@app/services/event.service";
import {EditorPanelService} from "@app/services/editor-panel.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-task-editor-loader',
    templateUrl: './task-editor-loader.component.html',
    styleUrls: ['../base-editor-loader.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TaskEditorLoaderComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() itemToLoad: Task;
    @Input() options: any;
    @Output() onCloseEventEmitter = new EventEmitter();

    // Bindings to view: UI Control
    public isLoading = false;
    public showNotFound = false;
    public showEditor = false;
    // Bindings to view: Required for Editor
    public item: Task;
    public projectTypes: ProjectType[];
    public fields: Map<number, Field>;
    public configuration: TaskType;

    constructor(private shellService: ShellService,
                private projectsService: ProjectsService,
                private cd: ChangeDetectorRef,
                private tasksService: TasksService,
                private editorPanelService: EditorPanelService,
                private taskEditorService: TaskEditorService) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        this.startLoading();
        this.ensureItem(item => this.loadData(item), () => this.renderWithError());
    }

    // <editor-fold desc="Load data">

    private ensureItem(onSuccess: (item: Task) => void, onError: () => void) {
        // Fetch
        if (this.itemToLoad.exists()) {
            if (this.options?.reload === false) {
                onSuccess(this.itemToLoad);
            } else {
                Api.tasks().getById(this.itemToLoad.id).find(item => {
                    if (item?.length == 1 && item[0].exists()) {
                        onSuccess(item[0]);
                    } else {
                        onError();
                    }
                });
            }
        } else {
            console.error('Should never end up here');
        }
    }

    private loadData(itemToLoad: Task) {
        this.startLoading();

        const countRunner = new CountRunner(3);
        countRunner.setRunner(() => {
            this.item = itemToLoad;
            this.renderWithSuccess();
        });

        this.projectsService.getProjectTypesByDepartmentId(
            this.shellService.getPageSettings()?.departmentId,
            projectTypes => {
                this.projectTypes = projectTypes;
                countRunner.tick();
            });

        this.taskEditorService.getEditorFieldsForType(itemToLoad.task_type_id, fields => {
            this.fields = fields;
            countRunner.tick();
        });

        this.tasksService.getTaskType(itemToLoad.task_type_id, taskType => {
            this.configuration = taskType;
            countRunner.tick();
        });

        countRunner.start();
    }

    private startLoading() {
        this.showEditor = false;
        this.showNotFound = false;
        this.isLoading = true;
        this.detectChanges();
    }

    private stopLoading() {
        this.isLoading = false;
        this.detectChanges();
    }

    private renderWithError() {
        this.showNotFound = true;
        this.stopLoading();
    }

    private itemSubscription?: Subscription;
    private renderWithSuccess() {
        this.showEditor = true;
        this.stopLoading();

        // Subscribe to events and close if deleted
        this.itemSubscription?.unsubscribe();
        this.itemSubscription = AppInjector.getInjector().get(EventService).subscribe(this.item, event => {
            switch (event.action) {
                case EventService.Updated:
                    break;
                case EventService.Deleted:
                    this.close();
                    break;
            }
        });
    }

    // </editor-fold>

    // <editor-fold desc="View actions">

    public onLoadingIndicatorClicked() {
        this.close();
    }

    public onNotFoundClicked() {
        this.close();
    }

    public onEditorItemSaved(item: Task) {
        Api.tasks().getById(this.itemToLoad.id).find(item => {
            this.item = item[0];
            this.renderWithSuccess();
        });
    }

    public onEditorCloseEvent() {
        this.close();
    }

    // </editor-fold>

    private close() {
        this.shellService.removeFromURI(this.itemToLoad);
        this.editorPanelService.closeItem(this.item);
        this.onCloseEventEmitter.emit();
    }

}
