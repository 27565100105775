import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class ErrorComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    navigate() {
        localStorage.clear();
        document.location.reload();
    }
}
