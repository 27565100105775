import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {WeekDay, WeekDays} from "@app/pages/displays/display-team/TableColumns/DaysTableColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-team/Helpers/ColumnTypeSettings";
import {DaysColumn} from "@app/pages/displays/display-team/Columns/DaysColumn";
import moment from "moment";
import {Team_DaysVisibleDaysSettingValue} from "@app/core/Api";

export class DaysCell extends BaseCell {

    public column: DaysColumn;

    public listConfigurations = new Map<WeekDay, TaskListConfiguration>();

    constructor(row: BaseRow, column: BaseColumn) {
        super(row, column);

        column.settings.get(ColumnTypeSettings.Days_VisibleDays)
            ?.getObject<Team_DaysVisibleDaysSettingValue>()
            ?.days
            ?.forEach(day => {
                const taskListConfiguration = this.createTaskListConfiguration(day);
                this.listConfigurations.set(day, taskListConfiguration);
            });
    }

    private createTaskListConfiguration(day: WeekDay): TaskListConfiguration {
        const taskListRosterColumn = new TaskListConfiguration();

        taskListRosterColumn
            .setTaskTypeIds(this.column.getTaskTypeIds());

        return taskListRosterColumn;
    }

    public getDate(day: WeekDay, week: Date) {
        switch (day) {
            default:
            case WeekDays.Monday:
                return moment(week).startOf('isoWeek').toDate();
            case WeekDays.Tuesday:
                return moment(week).startOf('isoWeek').add('1', 'days').toDate();
            case WeekDays.Wednesday:
                return moment(week).startOf('isoWeek').add('2', 'days').toDate();
            case WeekDays.Thursday:
                return moment(week).startOf('isoWeek').add('3', 'days').toDate();
            case WeekDays.Friday:
                return moment(week).startOf('isoWeek').add('4', 'days').toDate();
            case WeekDays.Saturday:
                return moment(week).startOf('isoWeek').add('5', 'days').toDate();
            case WeekDays.Sunday:
                return moment(week).startOf('isoWeek').add('6', 'days').toDate();
        }
    }

    public getAllTaskListConfigurations(): TaskListConfiguration[] {
        return [...this.listConfigurations.values()];
    }

    public getTaskListConfiguration(day: WeekDay): TaskListConfiguration {
        return this.listConfigurations.get(day);
    }

    public getTaskListConfigurationsMap(): Map<WeekDay, TaskListConfiguration> {
        return this.listConfigurations;
    }

}
