import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {User} from '@app/core/models/User';
import {TaskListConfiguration} from '@app/shared/_ui/lists/task-list/TaskListConfiguration';
import {ProjectFetcher, ProjectFetchRequest} from "@app/shared/_ui/lists/project-list/ProjectFetcher";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {TodoFetcher, TodoFetcherRequest} from "@app/shared/_ui/lists/todo-list/TodoFetcher";
import {MilestoneFetcher, MilestoneFetchRequest} from "@app/shared/_ui/lists/milestone-list/MilestoneFetcher";
import {MilestoneListConfiguration} from "@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration";
import {Office365Fetcher, Office365FetcherRequest} from "@app/shared/_ui/lists/office365event-list/Office365Fetcher";
import {Deadline, Milestone, Task} from "@app/core/models";
import {
    Configs,
    ProjectDeadlineTypes,
    ProjectStatusTypes,
    StatusTypes,
    TaskStatusTypes,
    TaskUserTypes
} from "@app/constants";
import moment from "moment";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {CdkDrag, CdkDragDrop} from "@angular/cdk/drag-drop";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {PageComponent} from "@app/pages/page.component";
import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {NonArchivedFilter} from "@app/shared/_ui/lists/multi-list/OnScreenFilters/NonArchivedFilter";
import {NonOffice365EventFilter} from "@app/shared/_ui/lists/multi-list/OnScreenFilters/NonOffice365EventFilter";
import {DragDropHelper} from "@app/core/helpers/DragDropHelper";
import {ConfigHelper} from "@app/core/ConfigHelper";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {
    ProjectUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUserPresetGenerator";
import {ProjectUserTypes} from "@app/core/models/Project";
import {
    ProjectStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectStatusPresetGenerator";
import {
    ProjectUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUseStatusRulesPresetGenerator";
import {
    ProjectDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectDepartmentPresetGenerator";
import {
    ProjectDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectDeadlinePresetGenerator";
import {ProjectsService} from "@app/services/projects.service";
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";
import {
    MilestoneDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneDeadlinePresetGenerator";
import {
    MilestoneResponsiblePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneResponsiblePresetGenerator";
import {
    TodoUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoUserPresetGenerator";
import {
    TodoDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoDeadlinePresetGenerator";
import {Filters} from "@app/pages/displays/dashboard/dashboard-calendar/Filters";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {
    SoftThisWeekColumnType
} from "@app/pages/displays/dashboard/dashboard-calendar/ColumnTypes/SoftThisWeekColumnType";
import {DaysColumnType} from "@app/pages/displays/dashboard/dashboard-calendar/ColumnTypes/DaysColumnType";
import {
    SoftNextWeekColumnType
} from "@app/pages/displays/dashboard/dashboard-calendar/ColumnTypes/SoftNextWeekColumnType";
import {
    SoftThisWeekTableColumn
} from "@app/pages/displays/dashboard/dashboard-calendar/TableColumns/SoftThisWeekTableColumn";
import {DaysTableColumn} from "@app/pages/displays/dashboard/dashboard-calendar/TableColumns/DaysTableColumn";
import {
    SoftNextWeekTableColumn
} from "@app/pages/displays/dashboard/dashboard-calendar/TableColumns/SoftNextWeekTableColumn";
import {ColumnTypes} from "@app/pages/displays/dashboard/dashboard-calendar/ColumnTypes";

@Component({
    selector: 'app-dashboard-calendar',
    templateUrl: './dashboard-calendar.component.html',
    styleUrls: ['./dashboard-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DashboardCalendar extends PageComponent implements OnInit, OnDestroy {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();

    // Bindings to parent
    @Input() activeUser: User;
    @Input() isMainDisplay = true;
    @Input() displayId: number;
    @Output() onShellPageDataChangeEvent = new EventEmitter<ShellPageData>();

    // Bindings to view
    public softThisWeekTableColumn?: SoftThisWeekTableColumn;
    public daysTableColumns?: DaysTableColumn[];
    public softNextWeekTableColumn?: SoftNextWeekTableColumn;

    // UI: DragDrop
    public dropList: string[] = [];

    constructor(
        private cd: ChangeDetectorRef,
        private projectsService: ProjectsService,
    ) {
        super();
        this.cdr = cd;
        this.isLoading = true;

        this.addOnScreenFilter(new NonArchivedFilter(false));
        ConfigHelper.GetBool(Configs.Feature_Azure, value => {
            this.addOnScreenFilter(new NonOffice365EventFilter(false));
        });

        this.filterGlobalService.onSettingsChangeEvent.subscribe(settings => {
            this.loadData();
        });
        this.filterGlobalService.onSettingsPeriodChangeEvent.subscribe(settings => {
            this.loadData();
        });
    }

    ngOnInit() {
        this.buildColumns();
        super.ngOnInit();

        this.subscribe(this.dragAndDropService.dropLists$.subscribe(list => {
            setTimeout(() => {
                this.dropList = list;
            });
        }));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.softNextWeekTableColumn?.listConfiguration.pushUnsubscribe();
    }

    // <editor-fold desc="Load Data">

    private projectFetcher = new ProjectFetcher();
    private taskDeadlineFetcher = new TaskFetcher();
    private taskParticipantDeadlineFetcher = new TaskFetcher();
    private softTasksFetcher = new TaskFetcher();
    private todoFetcher = new TodoFetcher();
    private milestoneFetcher = new MilestoneFetcher();
    private office365Fetcher = new Office365Fetcher();

    private loadData() {
        if (!this.initialized && this.columnController.getVisibleColumns().length > 0) {
            return;
        }

        // Cancel running fetchers
        this.projectFetcher.cancel();
        this.taskDeadlineFetcher.cancel();
        this.taskParticipantDeadlineFetcher.cancel();
        this.softTasksFetcher.cancel();
        this.todoFetcher.cancel();
        this.milestoneFetcher.cancel();
        this.office365Fetcher.cancel();

        // Apply date to list configurations
        const period = this.filterGlobalService.getActiveSettings().period;
        this.daysTableColumns.forEach(day => day.setDate(period.start));
        if (this.daysTableColumns.length) {
            this.daysTableColumns[this.daysTableColumns.length - 1].dateEnd = moment(period.start).endOf('isoWeek').toDate()
        }

        // Apply date to soft weeks
        if (this.softThisWeekTableColumn) {
            let softStartThisWeek = moment(period.start).startOf('isoWeek').toDate();
            this.softThisWeekTableColumn.dateStart.setDate(softStartThisWeek.getDate());
            this.softThisWeekTableColumn.dateStart.setMonth(softStartThisWeek.getMonth());
            this.softThisWeekTableColumn.dateStart.setFullYear(softStartThisWeek.getFullYear());
            const softEndThisWeek = moment(softStartThisWeek).add(1, 'week').toDate();
            this.softThisWeekTableColumn.dateEnd.setDate(softEndThisWeek.getDate());
            this.softThisWeekTableColumn.dateEnd.setMonth(softEndThisWeek.getMonth());
            this.softThisWeekTableColumn.dateEnd.setFullYear(softEndThisWeek.getFullYear());
        }

        if (this.softNextWeekTableColumn) {
            let softStartNextWeek = moment(period.start).startOf('isoWeek').add(1, 'week').toDate();
            this.softNextWeekTableColumn.dateStart.setDate(softStartNextWeek.getDate());
            this.softNextWeekTableColumn.dateStart.setMonth(softStartNextWeek.getMonth());
            this.softNextWeekTableColumn.dateStart.setFullYear(softStartNextWeek.getFullYear());
            const softEndNextWeek = moment(softStartNextWeek).add(1, 'week').toDate();
            this.softNextWeekTableColumn.dateEnd.setDate(softEndNextWeek.getDate());
            this.softNextWeekTableColumn.dateEnd.setMonth(softEndNextWeek.getMonth());
            this.softNextWeekTableColumn.dateEnd.setFullYear(softEndNextWeek.getFullYear());
        }

        // Execute fetchers
        this.projectFetcher.executeForPeriod(period.start, period.end);
        this.taskDeadlineFetcher.executeForPeriod(period.start, period.end);
        this.taskParticipantDeadlineFetcher.executeForPeriod(period.start, period.end);
        this.softTasksFetcher.executeForPeriod(period.start, moment(period.start).add(2, 'week').subtract(1, 'second').toDate());
        this.todoFetcher.executeForPeriod(period.start, period.end);
        this.milestoneFetcher.executeForPeriod(period.start, period.end);
        this.office365Fetcher.executeForPeriod(period.start, period.end);

        this.detectChanges();
    }

    // </editor-fold>

    // <editor-fold desc="DragDrop Soft Next Week">

    public softNextWeekDropEvent(event: CdkDragDrop<ListDragInterface>) {
        DragDropHelper.CardItemDropEvent(event);
    }

    public softNextWeekDragEnterPredicate(item: CdkDrag) {
        const cardItem: CardItem = item.data;
        return cardItem.item instanceof Task;
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    private buildColumns() {
        this.columnController.addColumnTypes([
            new SoftThisWeekColumnType(),
            new DaysColumnType(),
            new SoftNextWeekColumnType(),
        ]);
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();

        this.columnController.loadColumns(this.displayId, () => {
            this.initialize();
        });
    }

    private initialize() {
        this.onShellPageDataChangeEvent.emit(this.shellPageData);

        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            const tableColumns = this.columnController.getVisibleTableColumns();
            this.softThisWeekTableColumn = (tableColumns as SoftThisWeekTableColumn[])
                .find(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.SoftThisWeek);
            this.daysTableColumns = (tableColumns as DaysTableColumn[])
                .filter(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.Days);
            this.softNextWeekTableColumn = (tableColumns as SoftNextWeekTableColumn[])
                .find(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.SoftNextWeek);

            if (this.softThisWeekTableColumn) {
                this.softThisWeekTableColumn.taskListConfiguration
                    .setPlannedValidator(deadline => {
                        const period = this.filterGlobalService.getActiveSettings().period;
                        return deadline.isBetween(period.start, period.end);
                    })
                    .setPlannedValidatorDate(() => this.filterGlobalService.getActiveSettings().period.start)
                    .setUser(this.activeUser)
                    .setCustomAllowCardItemDropFunction(this.taskAllowCardItemDrop)
                    .setCreatePresetGenerators([
                        new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                        new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                        new TaskUseStatusRulesPresetGenerator(true),
                        new TaskUserPresetGenerator(
                            TaskUserTypes.Participant,
                            this.activeUser.id,
                            () => Deadline.Create(this.softThisWeekTableColumn.dateStart, true),
                        ),
                        new TaskDepartmentPresetGenerator(this.department.id),
                    ]);

                this.softTasksFetcher.addRequest(new TaskFetchRequest(this.softThisWeekTableColumn.taskListConfiguration));
                this.softThisWeekTableColumn.listConfiguration.addOnScreenFilters(this.onScreenFilters);
            }

            this.daysTableColumns.forEach(tableColumn => {
                // Add project list configuration
                if (tableColumn.projectListConfiguration) {
                    tableColumn.projectListConfiguration
                        .setDeadlineValidator((project, deadlines) => deadlines.find(deadline => deadline.isBetween(tableColumn.dateStart, tableColumn.dateEnd)) != null)
                        .setDeadlineValidatorDate(() => tableColumn.dateStart)
                        .setUser(this.activeUser)
                        .setCreatePresetGenerators([
                            // Defaults
                            new ProjectStatusPresetGenerator(ProjectStatusTypes.Normal, StatusTypes.GREEN),
                            new ProjectUseStatusRulesPresetGenerator(true),
                            new ProjectDepartmentPresetGenerator(this.department.id),

                            new ProjectUserPresetGenerator(ProjectUserTypes.Responsible, this.activeUser.id),
                            new ProjectDeadlinePresetGenerator(
                                typeId => this.projectsService.getMemoryCachedProjectType(typeId)?.default_project_deadline_type_id ?? ProjectDeadlineTypes.Normal,
                                () => tableColumn.dateStart,
                                false
                            ),
                        ]);
                    this.projectFetcher.addRequest(new ProjectFetchRequest(tableColumn.projectListConfiguration));
                }

                // Add task list configuration (Participant deadline / Planning date)
                if (tableColumn.taskParticipantDeadlineListConfiguration) {
                    tableColumn.taskParticipantDeadlineListConfiguration
                        .setPlannedValidator(deadline => deadline.isBetween(tableColumn.dateStart, tableColumn.dateEnd))
                        .setPlannedValidatorDate(() => tableColumn.dateStart)
                        .setCustomAllowCardItemDropFunction(this.taskAllowCardItemDrop)
                        .setUser(this.activeUser)
                        .setCreatePresetGenerators([
                            new TaskUserPresetGenerator(TaskUserTypes.Creator, this.user.id),
                            new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
                            new TaskUseStatusRulesPresetGenerator(true),
                            new TaskUserPresetGenerator(
                                TaskUserTypes.Participant,
                                this.activeUser.id,
                                () => Deadline.Create(tableColumn.dateStart, false),
                            ),
                            new TaskDepartmentPresetGenerator(this.department.id),
                        ]);
                    this.taskParticipantDeadlineFetcher.addRequest(new TaskFetchRequest(tableColumn.taskParticipantDeadlineListConfiguration));
                }

                // Add to-do list configuration
                if (tableColumn.todoListConfiguration) {
                    tableColumn.todoListConfiguration
                        .setDeadlineValidator(deadline => deadline.isBetween(tableColumn.dateStart, tableColumn.dateEnd))
                        .setDeadlineValidatorDate(() => tableColumn.dateStart)
                        .setUser(this.activeUser)
                        .setCreatePresetGenerators([
                            new TodoUserPresetGenerator(this.activeUser.id),
                            new TodoDeadlinePresetGenerator(() => tableColumn.dateStart, false),
                        ]);
                    this.todoFetcher.addRequest(new TodoFetcherRequest(tableColumn.todoListConfiguration));
                }

                // Add milestone list configuration
                if (tableColumn.milestoneListConfiguration) {
                    tableColumn.milestoneListConfiguration
                        .setDeadlineValidator(deadline => deadline.isBetween(tableColumn.dateStart, tableColumn.dateEnd))
                        .setPlannedValidatorDate(() => tableColumn.dateStart)
                        .setCustomAllowCardItemDropFunction(this.milestoneAllowCardItemDrop)
                        .setResponsible(this.activeUser)
                        .setCreatePresetGenerators([
                            // Defaults
                            new MilestoneStatusPresetGenerator(StatusTypes.GREEN),
                            new MilestoneUseStatusRulesPresetGenerator(true),

                            new MilestoneDeadlinePresetGenerator(() => tableColumn.dateStart, false),
                            new MilestoneResponsiblePresetGenerator(this.activeUser.id),
                        ]);
                    this.milestoneFetcher.addRequest(new MilestoneFetchRequest(tableColumn.milestoneListConfiguration));
                }

                // Add office365event list configuration
                if (tableColumn.office365EventListConfiguration) {
                    tableColumn.office365EventListConfiguration
                        .setDeadlineValidator((start, end) => end.getTime() > tableColumn.dateStart.getTime() && start.getTime() < tableColumn.dateEnd.getTime())
                        .setUser(this.activeUser);
                    this.office365Fetcher.addRequest(new Office365FetcherRequest(tableColumn.office365EventListConfiguration));
                }

                // OnScreen Filtering
                tableColumn.listConfiguration.addOnScreenFilters(this.onScreenFilters);
            });

            if (this.softNextWeekTableColumn) {
                this.softNextWeekTableColumn.taskListConfiguration
                    .setPlannedValidator(deadline => {
                        const period = this.filterGlobalService.getActiveSettings().period;
                        return deadline.isBetween(moment(period.start).add(1, 'week').toDate(), moment(period.start).add(2, 'week').toDate());
                    })
                    .setPlannedValidatorDate(() => moment(this.filterGlobalService.getActiveSettings().period.start).add(1, 'week').toDate())
                    .setCustomAllowCardItemDropFunction(this.taskAllowCardItemDrop)
                    .setUser(this.activeUser);

                this.softTasksFetcher.addRequest(new TaskFetchRequest(this.softNextWeekTableColumn.taskListConfiguration));

                this.dragAndDropService.addDropList(this.softNextWeekTableColumn.dropListId);
            }

            this.loadData();
        }));

        this.isLoading = false;
        this.initialized = true;
    }

    private taskAllowCardItemDrop = (cardItem: CardItem<Task>, fromListConfiguration: TaskListConfiguration, result: (allow: boolean) => void) => {
        let task = cardItem.item as Task;
        if (this.getUser()) {
            // Hvis listen er konfigureret til specifik bruger, men brugeren ikke er tilkoblet opgaven, vis dialog
            const tasksUser = task.tasks_users?.find(tasksUser => tasksUser.user_id == this.getUser().id);
            if (!tasksUser) {
                const translateService = AppInjector.getInjector().get(TranslateService);
                AppInjector.getInjector().get(BaseDialogService).confirm(
                    translateService.instant('_ui_dialog_plan_task'),
                    translateService.instant('_ui_dialog_plan_task_description'))
                    .then(confirmed => {
                        result(confirmed);
                    });
                return;
            }
        }
        result(true);
    };

    private milestoneAllowCardItemDrop = (cardItem: CardItem<Milestone>, fromListConfiguration: MilestoneListConfiguration, result: (allow: boolean) => void) => {
        let milestone = cardItem.item as Milestone;
        // if(this.getUser()) {
        //     // Hvis listen er konfigureret til specifik bruger, men brugeren ikke er tilkoblet opgaven, vis dialog
        //     const tasksUser = milestone.tasks_users?.find(tasksUser => tasksUser.user_id == this.getUser().id);
        //     if(!tasksUser) {
        //         const translateService = AppInjector.getInjector().get(TranslateService);
        //         AppInjector.getInjector().get(BaseDialogService).confirm(
        //             translateService.instant('_ui_dialog_plan_task'),
        //             translateService.instant('_ui_dialog_plan_task_description'))
        //             .then(confirmed => {
        //                 result(confirmed);
        //             });
        //         return;
        //     }
        // }
        result(true);
    };

    // </editor-fold>

    // <editor-fold desc="OnScreen Filtering">

    public onScreenFilters: BaseOnScreenFilter<any>[] = [];

    private addOnScreenFilter(value: BaseOnScreenFilter<any>) {
        this.onScreenFilters.push(value);
    }

    // </editor-fold>

}
