import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {BaseDisplayComponent} from '../../../_system/base-display/base-display.component';
import {EventService} from '@app/services/event.service';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {ListDragInterface} from '@app/interfaces/ListDragInterface';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {CSVListBinding} from '@app/export/csv/CSVListBinding';
import {MultiListConfiguration} from "@app/shared/_ui/lists/multi-list/MultiListConfiguration";

@Component({
    selector: 'app-multi-list',
    templateUrl: './multi-list.component.html',
    styleUrls: ['./multi-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MultiListComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    // Inputs: UI
    @Input() listClass: string = 'grid-3';
    @Input() scrollContainer: HTMLElement;

    // Inputs: Events
    @Input() minimizeEvent: EventEmitter<any>;
    @Input() reloadEvent: EventEmitter<any>;
    @Output() dataSetChanged = new EventEmitter<CardItem[]>();
    @Output() onDragStarted = new EventEmitter();
    @Output() onDragEnded = new EventEmitter();

    // Inputs: Configuration
    @Input() configuration: MultiListConfiguration;
    @Input() listDragInterface: ListDragInterface;
    @Input() csvListBinding: CSVListBinding;

    // Data
    public items: CardItem[];
    public offset = 0;
    public itemCount = 0;

    constructor(private eventService: EventService,
                private editorPanelService: EditorPanelService,
                private ngZone: NgZone,
                private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.minimizeEvent?.subscribe(() => {
            this.loadLess();
        });

        this.reloadEvent?.subscribe(() => {
            if (this.configuration.hasSmartLimit()) {
                return;
            }
            // TO-DO Afventer MultiList V2 https://podio.com/klartboard/softwareudvikling/apps/stories/items/956
        });

        // Push
        this.configuration.pushSubscribe();

        // CSV Export
        this.csvListBinding?.setExportFunction(option => {
            const items: any[] = [];
            this.configuration.getSources().forEach(source => items.push(source.csvExport(option)));
            return items;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['configuration'] != null) {

            // Reset data
            this.isLoading = true;
            this.items = [];

            // Prepare data listeners
            const onAddItemsListener = (items: CardItem[]) => {
                this.offset = 0;
                this.items = items;
                this.itemCount = items.length;
                this.isLoading = false;
                this.dataSetChanged.emit(this.items);
                this.detectChanges();
            };
            const onAddItemListener = (item: CardItem) => {
                if (this.items.length == this.itemCount) { // All items are shown, update lists
                    onAddItemsListener(this.configuration.getAllItems());
                }
            };
            const onRemoveItemListener = (item: CardItem) => {
                onAddItemsListener(this.configuration.getAllItems());
            };
            const onCountListener = (count: number) => {
                this.itemCount = count;
                this.detectChanges();
            };

            // Add data listeners
            this.configuration.addDataListeners(onAddItemsListener, onAddItemListener, onRemoveItemListener, onCountListener);

            // If no sources found, we're done already
            if (this.configuration.getSources().length == 0) {
                this.isLoading = false;
                this.detectChanges();
            }
        }
    }

    // <editor-fold desc="View Actions">

    loadMore() {
        // TO-DO Afventer MultiList V2 https://podio.com/klartboard/softwareudvikling/apps/stories/items/956
    }

    loadLess() {
        this.offset = 0;
        this.items = this.items.slice(0, this.configuration.getLimit());
        this.configuration.getSources().forEach(source => source.intersect(this.items));
        this.dataSetChanged.emit(this.items);
        this.detectChanges();
    }

    loadAll() {
        // TO-DO Afventer MultiList V2 https://podio.com/klartboard/softwareudvikling/apps/stories/items/956
    }

    // </editor-fold>

}
