import {Filter} from '@app/pages/displays/filtering/Filter';
import {Sort} from '@app/pages/displays/filtering/Sort';
import {Period} from '@app/pages/displays/filtering/Period';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {Display} from '@app/core/models/Display';
import {AppInjector} from '@app/services/app-injector.service';
import moment from 'moment';
import {DisplayFilterEditorFormConfigInterface} from '@app/editor/display-filter-editor/DisplayFilterEditorForm';
import {TranslateService} from '@ngx-translate/core';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";

export class Filters implements FiltersInterface {

    public applyDefaults(displayFilter: DisplayFilter) {
        displayFilter.filter_type = Filters.FilterColumnsOnly;
    }

    // Filters
    public static FilterColumnsOnly = 'filter-columns-only';

    // Sorts

    public filters(display: Display): Filter[] {
        const all = new Filter(
            Filters.FilterColumnsOnly,
            this.getTranslation('_ui_all'),
        );
        all.showColumns = true;

        const allFilters = [
            all,
        ];

        return allFilters;
    }

    private getTranslation(key: string, interpolateParams?: Object): string {
        return AppInjector.getInjector().get(TranslateService).instant(key, interpolateParams);
    }

    public sorts(display: Display, includePseudoTypes = false): Sort[] {
        const sorts: Sort[] = [];

        if (includePseudoTypes) {
            // const config = this.getEditorConfig(display);
        }

        return sorts;
    }

    public getSort(display: Display, sort: string): Sort {
        return this.sorts(display).find(item => item.id == sort);
    }

    public periods(): Period[] {
        return [
        ];
    }

    public yearWheelPeriods(): Period[] {
        return [
        ];
    }

    public getPeriodStart(period: string, sourceDate?: Date): Date {
        let date = moment();
        switch (period) {
        }
        date = date
            .startOf('isoWeek')
            .startOf('day');
        return date.toDate();
    }

    public getPeriodEnd(period: string, sourceDate?: Date): Date {
        let date = moment();
        switch (period) {
        }

        date = date
            .endOf('isoWeek')
            .endOf('day');
        return date.toDate();
    }

    public getYearWheelPeriodStart(period: string, customDate?: string): Date {
        let date = moment();
        switch (period) {
        }
        return date.toDate();
    }

    public getYearWheelPeriodEnd(period: string, customDate?: string): Date {
        let date = moment();
        switch (period) {
        }
        return date.toDate();
    }

    public standards(display: Display): DisplayFilter[] {
        let standards: DisplayFilter[] = [];

        let allByTitle = new DisplayFilter();
        allByTitle.display_id = display.id;
        allByTitle.display = display;
        allByTitle.filter_type = Filters.FilterColumnsOnly;
        allByTitle.statuses = [];
        allByTitle.sort_direction = 'asc';
        standards.push(allByTitle);

        standards.forEach(item => {
            item.name = item.generateName(this);
        });

        return standards;
    }

    public generateName(displayFilter: DisplayFilter): string {
        let start;
        let status;
        let period;

        switch (displayFilter.filter_type) {
            case Filters.FilterColumnsOnly:
                start = this.getTranslation('_ui_all');
                break;
        }

        let result = start;

        // Status
        const statuses = displayFilter.statuses?.map(status => status.name().toLocaleLowerCase()) ?? [];
        if (statuses.length < 4 && statuses.length > 0) {
            if (statuses.length > 1) {
                const lastStatus = statuses.pop();
                status = this.getTranslation('_ui_filter_status_many', {
                    status1: statuses.join(', '),
                    status2: lastStatus
                });
            } else if (statuses.length == 1) {
                status = this.getTranslation('_ui_filter_status_single', {status: statuses.join(', ')});
            } else {
                status = this.getTranslation('_ui_filter_status_none');
            }
            result += ' ' + status;
        }

        // Period
        switch (displayFilter.period_type) {
        }

        // if (period?.length) {
        //     result += ' ' + period;
        // }

        // Sort

        if (displayFilter.starred) {
            result += ', ' + this.getTranslation('_ui_starred').toLocaleLowerCase();
        }
        if (displayFilter.hand_up) {
            result += ', ' + this.getTranslation('_ui_hand_up').toLocaleLowerCase();
        }

        return result;
    }

    public getEditorConfig(display: Display, result?: (config: DisplayFilterEditorFormConfigInterface) => void): DisplayFilterEditorFormConfigInterface {
        const config: DisplayFilterEditorFormConfigInterface = {};

        if (display == null) {
            // Not fit for editor config
            if (result) {
                result(config);
            }
            return config;
        }

        result(config);
        return config;
    }

}
