import {Filter} from '@app/pages/displays/filtering/Filter';
import {Sort} from '@app/pages/displays/filtering/Sort';
import {Period} from '@app/pages/displays/filtering/Period';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {Display} from '@app/core/models/Display';
import {AppInjector} from '@app/services/app-injector.service';
import moment from 'moment';
import {DisplayFilterEditorFormConfigInterface} from '@app/editor/display-filter-editor/DisplayFilterEditorForm';
import {Settings} from '@app/pages/displays/display-category/Settings';
import {ProjectsService} from '@app/services/projects.service';
import {TranslateService} from '@ngx-translate/core';
import {ProjectType} from '@app/core/models/ProjectType';
import {TaskType} from '@app/core/models/TaskType';
import {CategoryType} from '@app/core/models/CategoryType';
import {TasksService} from "@app/services/tasks.service";
import {CountRunner} from "@app/core/CountRunner/CountRunner";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";

export class Filters implements FiltersInterface {

    public applyDefaults(displayFilter: DisplayFilter) {
        displayFilter.filter_type = Filters.FilterAll;
        displayFilter.sort_type = Filters.SortTitle;
        displayFilter.period_type = Filters.PeriodToday;
        displayFilter.include_archived_since_type = Filters.IncludeArchivedSincePeriodPrev2Weeks;
    }

    // Filters
    public static FilterAll = 'filter-all';
    public static FilterDeadlineInPeriod = 'filter-deadline-in-period';
    public static FilterNonDeadline = 'filter-non-deadline';
    public static FilterOpen = 'filter-open';
    public static FilterArchivedInPeriod = 'filter-archived-in-period';

    // Sorts
    public static SortDeadline = 'sort-deadline';
    public static SortTitle = 'sort-title';
    public static SortStatus = 'sort-status';
    public static SortUsers = 'sort-users';
    public static SortStarred = 'sort-starred';
    public static SortHandUp = 'sort-hand-up';

    public static SortCategoryType = 'sort-category-type-';

    public static SortCategoryTypeGenerator(categoryType: CategoryType): string {
        return `${Filters.SortCategoryType}${categoryType.id}`;
    };

    public static ParseSortCategoryType(sort: string): number {
        return parseInt(sort.substr(Filters.SortCategoryType.length));
    };

    public static GetBaseSort(sort: string): string {
        // Remove any identifiers from the end
        return sort.replace(/[0-9]/g, '');
    }

    // Periods
    public static PeriodUserDefined = 'user-defined';
    public static PeriodLastWeek = 'last-week';
    public static PeriodToday = 'today';
    public static PeriodThisWeek = 'this-week';
    public static PeriodNext2Week = 'next-2-weeks';
    public static PeriodNextMonth = 'next-month';

    // Include Archived Since Periods
    public static IncludeArchivedSincePeriodUserDefined = 'user-defined';
    public static IncludeArchivedSincePeriodToday = 'today';
    public static IncludeArchivedSincePeriodThisWeek = 'this-week';
    public static IncludeArchivedSincePeriodPrev1Week = 'prev-1-week';
    public static IncludeArchivedSincePeriodPrev2Weeks = 'prev-2-weeks';
    public static IncludeArchivedSincePeriodThisMonth = 'this-month';
    public static IncludeArchivedSincePeriodThisYear = 'this-year';

    public filters(display: Display): Filter[] {
        const itemName = display
            ? this.getItemName(null, this.getEditorConfig(display))
            : this.getTranslation('_ui_all');

        const all = new Filter(
            Filters.FilterAll,
            this.getTranslation('_ui_all')
        );
        const deadlineInPeriod = new Filter(
            Filters.FilterDeadlineInPeriod,
            this.getTranslation('_ui_display_category_filter_deadline', {item: itemName})
        );
        deadlineInPeriod.showPeriod = true;
        const nonDeadline = new Filter(
            Filters.FilterNonDeadline,
            this.getTranslation('_ui_display_category_filter_deadline_non', {item: itemName})
        );
        const archived = new Filter(
            Filters.FilterArchivedInPeriod,
            this.getTranslation('_ui_display_category_filter_archived_in_period', {item: itemName}),
            true
        );
        const open = new Filter(
            Filters.FilterOpen,
            this.getTranslation('_ui_display_category_filter_open', {item: itemName}),
            true
        );

        const allFilters = [
            all, deadlineInPeriod, nonDeadline, archived, open,
        ];

        allFilters.forEach(filter => {
            filter.showIncludeArchivedSince = true;
        });

        return allFilters;
    }

    private getTranslation(key: string, interpolateParams?: Object): string {
        return AppInjector.getInjector().get(TranslateService).instant(key, interpolateParams);
    }

    public sorts(display: Display, includePseudoTypes = false): Sort[] {
        let deadline = new Sort(Filters.SortDeadline, this.getTranslation('_ui_display_category_sort_column_deadline'), true, null, Sort.SortTypeDisplay);
        let title = new Sort(Filters.SortTitle, this.getTranslation('_ui_title'), true, null, Sort.SortTypeDisplay);
        let status = new Sort(Filters.SortStatus, this.getTranslation('_ui_status'), true, null, Sort.SortTypeDisplay);
        status.sortDirectionTranslation = '_ui_order_direction_status';
        let sortUsers = new Sort(Filters.SortUsers, this.getTranslation('_ui_display_category_sort_users'), true, null, Sort.SortTypeDisplay);
        let sortStarred = new Sort(Filters.SortStarred, this.getTranslation('_ui_starred'), true, null, Sort.SortTypeDisplay);
        let sortHandUp = new Sort(Filters.SortHandUp, this.getTranslation('_ui_hand_up'), true, null, Sort.SortTypeDisplay);

        let sorts = [
            deadline,
            title,
            status,
            sortUsers,
            sortStarred,
            sortHandUp,
        ];

        const config = this.getEditorConfig(display);
        config.categoryTypes?.forEach(categoryType => {
            sorts.push(new Sort(
                Filters.SortCategoryTypeGenerator(categoryType),
                `${categoryType.name} ${this.getTranslation('_filter_show_only', {name: categoryType.name.toLocaleLowerCase()})}`,
                true, null, Sort.SortTypeDisplay));
        });

        return sorts;
    }

    public getSort(display: Display, sort: string): Sort {
        return this.sorts(display).find(item => item.id == sort);
    }

    public periods(): Period[] {
        return [
            new Period(Filters.PeriodUserDefined, this.getTranslation('_ui_date_userdefined'), true),
            new Period(Filters.PeriodLastWeek, this.getTranslation('_ui_date_last_week')),
            new Period(Filters.PeriodToday, this.getTranslation('_ui_date_today')),
            new Period(Filters.PeriodThisWeek, this.getTranslation('_ui_date_this_week')),
            new Period(Filters.PeriodNext2Week, this.getTranslation('_ui_date_next_2_weeks')),
            new Period(Filters.PeriodNextMonth, this.getTranslation('_ui_date_next_month')),
        ];
    }

    public includeArchivedSincePeriods(): Period[] {
        return [
            new Period(Filters.IncludeArchivedSincePeriodUserDefined, this.getTranslation('_ui_date_userdefined_start'), true),
            new Period(Filters.IncludeArchivedSincePeriodToday, this.getTranslation('_ui_date_today')),
            new Period(Filters.IncludeArchivedSincePeriodThisWeek, this.getTranslation('_ui_date_this_week')),
            new Period(Filters.IncludeArchivedSincePeriodPrev1Week, this.getTranslation('_ui_date_past_week')),
            new Period(Filters.IncludeArchivedSincePeriodPrev2Weeks, this.getTranslation('_ui_date_past_2_weeks')),
            new Period(Filters.IncludeArchivedSincePeriodThisMonth, this.getTranslation('_ui_date_this_month')),
            new Period(Filters.IncludeArchivedSincePeriodThisYear, this.getTranslation('_ui_date_this_year')),
        ];
    }

    public getPeriodStart(period: string, sourceDate?: Date): Date {
        let date = moment();
        switch (period) {
            case Filters.PeriodLastWeek:
                date.startOf('isoWeek').subtract(1, 'week');
                break;
            case Filters.PeriodToday:
                date.startOf('day');
                break;
            case Filters.PeriodThisWeek:
                date.startOf('isoWeek');
                break;
            case Filters.PeriodNext2Week:
                date.startOf('day');
                break;
            case Filters.PeriodNextMonth:
                date.startOf('day');
                break;
        }
        return date.toDate();
    }

    public getPeriodEnd(period: string, sourceDate?: Date): Date {
        let date = moment();
        switch (period) {
            case Filters.PeriodLastWeek:
                date.endOf('isoWeek').subtract(1, 'week');
                break;
            case Filters.PeriodToday:
                date.endOf('day');
                break;
            case Filters.PeriodThisWeek:
                date.endOf('isoWeek');
                break;
            case Filters.PeriodNext2Week:
                date.endOf('day').add(2, 'weeks');
                break;
            case Filters.PeriodNextMonth:
                date.endOf('day').add(1, 'month');
                break;
        }
        return date.toDate();
    }

    public getIncludeArchivedSinceDate(period: string, customDate?: string): Date {
        let date = moment();
        switch (period) {
            case Filters.IncludeArchivedSincePeriodToday:
                date.startOf('day');
                break;
            case Filters.IncludeArchivedSincePeriodThisWeek:
                date.startOf('isoWeek');
                break;
            case Filters.IncludeArchivedSincePeriodPrev1Week:
                date.startOf('day').subtract(1, 'week');
                break;
            case Filters.IncludeArchivedSincePeriodPrev2Weeks:
                date.startOf('day').subtract(2, 'week');
                break;
            case Filters.IncludeArchivedSincePeriodThisMonth:
                date.startOf('month');
                break;
            case Filters.IncludeArchivedSincePeriodThisYear:
                date.startOf('year');
                break;
            case Filters.IncludeArchivedSincePeriodUserDefined:
                if (customDate)
                    return new Date(customDate);
                else
                    return null;
        }
        return date.toDate();
    }

    public standards(display: Display): DisplayFilter[] {
        let standards: DisplayFilter[] = [];

        let allByTitle = new DisplayFilter();
        allByTitle.display_id = display.id;
        allByTitle.display = display;
        allByTitle.filter_type = Filters.FilterAll;
        allByTitle.sort_type = Filters.SortTitle;
        allByTitle.statuses = [];
        allByTitle.sort_direction = 'asc';
        standards.push(allByTitle);

        standards.forEach(item => {
            item.include_archived_since_type = Filters.IncludeArchivedSincePeriodPrev2Weeks;
            item.name = item.generateName(this);
        });

        return standards;
    }

    public generateName(displayFilter: DisplayFilter): string {
        const itemName = displayFilter.display
            ? this.getItemName(displayFilter, this.getEditorConfig(displayFilter.display))
            : this.getTranslation('_ui_all');

        let start;
        let status;
        let period;
        let sort;
        let includeArchivedSince;

        switch (displayFilter?.filter_type) {
            case Filters.FilterAll:
                start = `${this.getTranslation('_ui_display_category_filter_all', {item: itemName})}`;
                break;
            case Filters.FilterDeadlineInPeriod:
                start = `${this.getTranslation('_ui_display_category_filter_deadline', {item: itemName})}`;
                break;
            case Filters.FilterNonDeadline:
                start = `${this.getTranslation('_ui_display_category_filter_deadline_non', {item: itemName})}`;
                break;
            case Filters.FilterArchivedInPeriod:
                start = `${this.getTranslation('_ui_display_category_filter_archived', {item: itemName})}`;
                break;
            case Filters.FilterOpen:
                start = `${this.getTranslation('_ui_display_category_filter_open', {item: itemName})}`;
                break;
        }

        let result = start;

        // Status
        const statuses = displayFilter.statuses?.map(status => status.name().toLocaleLowerCase()) ?? [];
        if (statuses.length < 4 && statuses.length > 0) {
            if (statuses.length > 1) {
                const lastStatus = statuses.pop();
                status = this.getTranslation('_ui_filter_status_many', {
                    status1: statuses.join(', '),
                    status2: lastStatus
                });
            } else if (statuses.length == 1) {
                status = this.getTranslation('_ui_filter_status_single', {status: statuses.join(', ')});
            } else {
                status = this.getTranslation('_ui_filter_status_none');
            }
            result += ' ' + status;
        }

        // Period
        switch (displayFilter.period_type) {
            case Filters.PeriodUserDefined:
                period = this.getTranslation('_ui_filter_deadline_custom', {
                    start: displayFilter.getPeriodStartString(),
                    end: displayFilter.getPeriodEndString()
                });
                break;
            case Filters.PeriodLastWeek:
                period = this.getTranslation('_ui_date_last_week').toLocaleLowerCase();
                break;
            case Filters.PeriodToday:
                period = this.getTranslation('_ui_date_today').toLocaleLowerCase();
                break;
            case Filters.PeriodThisWeek:
                period = this.getTranslation('_ui_date_this_week').toLocaleLowerCase();
                break;
            case Filters.PeriodNext2Week:
                period = this.getTranslation('_ui_date_next_2_weeks').toLocaleLowerCase();
                break;
            case Filters.PeriodNextMonth:
                period = this.getTranslation('_ui_date_next_month').toLocaleLowerCase();
                break;
        }

        if ([Filters.FilterDeadlineInPeriod, Filters.FilterArchivedInPeriod].includes(displayFilter.filter_type)) {
            result += ' ' + period;
        }

        // Sort
        let sortDirection = displayFilter.sort_direction == 'asc' ? 'a-z' : 'z-a';
        switch (Filters.GetBaseSort(displayFilter.sort_type)) {
            case Filters.SortTitle:
                sort = this.getTranslation('_ui_filter_sort_title').toLocaleLowerCase() + ' ' + sortDirection;
                break;
            case Filters.SortStatus:
                if (displayFilter.sort_direction == 'asc') {
                    sort = `${this.getTranslation('_ui_status').toLocaleLowerCase()} ${this.getTranslation('_ui_filter_sort_status_az')}`;
                } else {
                    sort = `${this.getTranslation('_ui_status').toLocaleLowerCase()} ${this.getTranslation('_ui_filter_sort_status_za')}`;
                }
                break;
            case Filters.SortUsers:
            case Filters.SortDeadline:
            case Filters.SortCategoryType:
                if (displayFilter.display) {
                    const sorts = this.sorts(displayFilter.display);
                    const sortMatch = sorts.find(item => item.id == displayFilter.sort_type);
                    if (sortMatch) {
                        sort = sortMatch.name.toLocaleLowerCase();
                        if (sortMatch.hasOrderDirection) {
                            sort = sort + ' ' + sortDirection;
                        }
                    }
                }
                break;
        }

        if (displayFilter.starred) {
            result += ', ' + this.getTranslation('_ui_starred').toLocaleLowerCase();
        }
        if (displayFilter.hand_up) {
            result += ', ' + this.getTranslation('_ui_hand_up').toLocaleLowerCase();
        }

        switch (displayFilter.include_archived_since_type) {
            case Filters.IncludeArchivedSincePeriodUserDefined:
                includeArchivedSince = `d. ${displayFilter.getIncludeArchivedSincePeriodString(this)}`;
                break;
            case Filters.IncludeArchivedSincePeriodToday:
                includeArchivedSince = this.getTranslation('_ui_date_today').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodThisWeek:
                includeArchivedSince = this.getTranslation('_ui_date_this_week').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodPrev1Week:
                includeArchivedSince = this.getTranslation('_ui_date_past_week').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodPrev2Weeks:
                includeArchivedSince = this.getTranslation('_ui_date_past_2_weeks').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodThisMonth:
                includeArchivedSince = this.getTranslation('_ui_date_this_month').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodThisYear:
                includeArchivedSince = this.getTranslation('_ui_date_this_year').toLocaleLowerCase()
                break;
        }

        result += ', ' + this.getTranslation('_ui_filter_sortet_on').toLocaleLowerCase() + ' ' + sort;

        return result;
    }

    public getEditorConfig(display: Display, result?: (config: DisplayFilterEditorFormConfigInterface) => void): DisplayFilterEditorFormConfigInterface {
        const config: DisplayFilterEditorFormConfigInterface = {};

        if (display == null) {
            // Not fit for editor config
            if (result) {
                result(config);
            }
            return config;
        }

        const projectsService = AppInjector.getInjector().get(ProjectsService);
        const projectTypes: ProjectType[] = [];
        const projectTypeSettings = display.displays_settings.filter(displaySetting => {
            return displaySetting.setting_id === Settings.ProjectTypeIds;
        });
        const tasksService = AppInjector.getInjector().get(TasksService);
        const taskTypes: TaskType[] = [];
        const taskTypeSettings = display.displays_settings.filter(displaySetting => {
            return displaySetting.setting_id === Settings.TaskTypeIds;
        });

        const runner = new CountRunner(projectTypeSettings.length + taskTypeSettings.length);
        runner.setRunner(() => {
            const categoryTypesMap = new Map<number, CategoryType>();

            projectTypes.forEach(projectType => {
                projectType.category_types_project_types
                    ?.filter(categoryTypesProjectType => categoryTypesProjectType.visible)
                    ?.forEach(categoryTypesProjectType => {
                        categoryTypesMap.set(categoryTypesProjectType.category_type_id, categoryTypesProjectType.category_type);
                    });
            });
            taskTypes.forEach(taskType => {
                taskType.category_types_task_types
                    ?.filter(categoryTypesTaskType => categoryTypesTaskType.visible)
                    ?.forEach(categoryTypesTaskType => {
                        categoryTypesMap.set(categoryTypesTaskType.category_type_id, categoryTypesTaskType.category_type);
                    });
            });

            config.categoryTypes = Array.from(categoryTypesMap.values());
            config.projectTypes = projectTypes;
            config.taskTypes = taskTypes;

            if (result) {
                result(config);
            }
        });

        runner.start();

        projectTypeSettings.forEach(projectTypeSetting => {
            projectsService.getProjectType(Number(projectTypeSetting.value), projectType => {
                projectTypes.push(projectType);
                runner.tick();
            });
        });
        taskTypeSettings.forEach(taskTypeSetting => {
            tasksService.getTaskType(Number(taskTypeSetting.value), taskType => {
                taskTypes.push(taskType);
                runner.tick();
            });
        });

        return config;
    }

    public getItemName(displayFilter: DisplayFilter, config: DisplayFilterEditorFormConfigInterface): string {
        const itemTypeNames: string[] = [];

        const selectedProjectTypesIds = displayFilter?.project_types?.map(projectType => projectType.id) ?? [];
        config.projectTypes
            ?.filter(projectType => selectedProjectTypesIds.includes(projectType.id))
            ?.forEach(projectType => {
                itemTypeNames.push(projectType.getPlural());
            });

        const selectedTaskTypesIds = displayFilter?.task_types?.map(taskType => taskType.id) ?? [];
        config.taskTypes
            ?.filter(taskType => selectedTaskTypesIds.includes(taskType.id))
            ?.forEach(taskType => {
                itemTypeNames.push(taskType.name);
            });

        const isAll = itemTypeNames.length == 0 || itemTypeNames.length == ((config.projectTypes?.length ?? 0) + (config.taskTypes?.length ?? 0));
        return this.getTranslation('_ui_all') + (isAll ? '' : ` ${itemTypeNames.join(', ').toLocaleLowerCase()}`);
    }

}
