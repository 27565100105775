import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {CSVCustomBinding} from '@app/export/csv/CSVCustomBinding';
import {CategoryRow} from '@app/shared/_ui/columns/categories/CategoryRow';
import {Category} from '@app/core/models/Category';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CategoriesComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    // Input
    @Input() categories: Category[] = [];
    @Input() csvBinding: CSVCustomBinding;

    // Data bindings to view
    items: CategoryRow[];

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        super();
        this.cdr = this.changeDetectorRef;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        this.items = this.categories
            .map(category => new CategoryRow(category))
            .sort((a: CategoryRow, b: CategoryRow) => a.category.index_ - b.category.index_);
        this.detectChanges();

        if (this.csvBinding) {
            this.csvBinding.setExportFunction(option => this.items
                    .filter(item => item.category.category_type_id == parseInt(option.value))
                    .map(item => {
                        if (item.name?.length) {
                            return item.name;
                        } else if (item.color?.value?.length) {
                            return item.color.value;
                        } else {
                            return '';
                        }
                    })
                    .join(', ')
            );
        }
    }

}
