import {ChangeDetectorRef, Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {TextAreaDialogComponent} from "@app/shared/_modals/text-area-dialog/text-area-dialog.component";
import {QuillEditorConfig} from "@app/constants";

@Component({
    selector: 'app-text-area',
    templateUrl: './text-area.component.html',
    styleUrls: ['./text-area.component.scss'],
    standalone: false
})
export class TextAreaComponent extends BaseDisplayComponent implements OnInit {

    @Input() value: string = '';
    @Input() label?: string;
    @Input() dialog = false;
    @Output() public onChange: EventEmitter<string> = new EventEmitter<string>();

    public oldValue: string;

    quillEditorConfig = QuillEditorConfig;

    private _valueChanged: Subject<string> = new Subject<string>();

    constructor(
        private modalService: NgbModal,
        protected cd: ChangeDetectorRef,
        protected dialogService: BaseDialogService,
        @Inject(LOCALE_ID) private locale: string
    ) {
        super();
        this._valueChanged
            .pipe(debounceTime(5000), distinctUntilChanged()) // wait 1 sec after the last event before emitting last event &&  only emit if value is different from previous value
            .subscribe((value: string) => {
                this.value = value;
                this.oldValue = value;
                // Call your function which calls API or do anything you would like do after a lag of 1 sec
                this.onChange.emit(this.value);
            });
    }

    ngOnInit() {

    }

    contentChanged(event: any) {
        this._valueChanged.next(event.html);
    }

    triggerFocus() {
        this.oldValue = this.value;
    }

    triggerBlur() {
        if (!this.value && this.oldValue) {
            this.value = this.oldValue;
        }
    }

    openDialog() {
        const modalRef = this.modalService.open(
            TextAreaDialogComponent,
            {
                size: 'lg',
                windowClass: 'modal-holder',
                centered: true,
            });

        (modalRef.componentInstance as TextAreaDialogComponent).model = this.value;
        (modalRef.componentInstance as TextAreaDialogComponent).title = this.label;
        modalRef.result.then(result => {
            if (result) {
                if (result.value == '') {
                    result.value = null;
                }

                this.value = result.value;
                this.onChange.emit(this.value);
            } else {

            }
        }, (reason) => {
            console.log('Dismissed action: ' + reason);
        });
    }
}
