import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {BaseModel} from "@app/core/models/BaseModel";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {
    EditCheckboxConfiguration
} from "@app/editor/quick-editor/editors/generic/checkbox-editor/EditCheckboxConfiguration";
import {EditCheckbox} from "@app/editor/quick-editor/editors/generic/checkbox-editor/EditCheckbox";

@Component({
    selector: 'app-checkbox-editor',
    templateUrl: './checkbox-editor.component.html',
    styleUrls: ['./checkbox-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CheckboxEditorComponent extends BaseEditor<EditCheckbox> {
    protected eventFieldName = 'checkbox-field';

    // Bindings to parent
    @Input() model: EditCheckbox & HasEventGenerator & BaseModel;
    @Input() configuration: EditCheckboxConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public value: boolean;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected getEventNameOptions(): any {
        return {
            prop: this.configuration.prop
        };
    }

    protected render() {
        this.value = this.model.isCheckboxChecked(this.configuration.prop);
        this.detectChanges();
    }

    public onToggle() {
        this.model.setCheckboxChecked(this.configuration.prop, !this.value);
        this.onItemUpdated();
        this.render();
    }

}
