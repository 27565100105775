import {DataCacheHit} from "@app/core/DataCache/DataCacheHit";
import {AppInjector} from "@app/services/app-injector.service";
import {CacheService} from "@app/services/cache.service";
import {BaseApi} from "@app/core/BaseApi";

export class ApiDataCache<T> {

    private readonly identifier: string;
    private readonly timeToLiveInSeconds: number;
    private readonly dataProvider: BaseApi<T>;
    private readonly postDataFindHook?: (data: T[]) => void;

    private memoryCache?: DataCacheHit<T[]>;
    private queuedCallbacks: ((data: DataCacheHit<T[]>) => void)[];
    private isRefreshing = false;

    constructor(identifier: string, timeToLiveInSeconds: number, dataProvider: BaseApi<T>, postDataFindHook?: (data: T[]) => void) {
        this.identifier = identifier;
        this.timeToLiveInSeconds = timeToLiveInSeconds;
        this.dataProvider = dataProvider;
        this.postDataFindHook = postDataFindHook;
    }

    public getMemoryCache(): T[] {
        return this.memoryCache?.data ?? [];
    }

    public get(callback: (data: T[]) => void) {
        this.ensureData(dataCacheHit => {
            callback(dataCacheHit.data);

            if (!this.isRefreshing) {
                if (new Date().getTime() - dataCacheHit.storeDate.getTime() > this.timeToLiveInSeconds * 1000) {
                    this.isRefreshing = true;
                    this.refreshData(false, () => {
                        this.isRefreshing = false;
                    });
                }
            }
        });
    }

    public getFirst(callback: (data: T) => void) {
        this.get(items => callback(items[0]));
    }

    public clear(finished?: () => void) {
        this.memoryCache = undefined;
        const cacheService = AppInjector.getInjector().get(CacheService);
        cacheService.clearIndexedDBItem(this.identifier, finished);
    }

    private ensureData(onData: (data: DataCacheHit<T[]>) => void) {
        if (this.memoryCache !== undefined) {
            onData(this.memoryCache);
        } else if (this.queuedCallbacks !== undefined) {
            this.queuedCallbacks.push(onData);
        } else {
            this.queuedCallbacks = [onData];

            const returnData = (data: DataCacheHit<T[]>) => {
                const callbacks = this.queuedCallbacks;
                this.queuedCallbacks = undefined;
                callbacks.forEach(callback => callback(data));
            };

            const cacheService = AppInjector.getInjector().get(CacheService);
            cacheService.getIndexedDBItem(this.identifier, value => {
                if (value.created && value.data) {
                    const dataCacheHit = new DataCacheHit(
                        value.data.map((item: any) => this.dataProvider.constructModel(item)),
                        new Date(value.created)
                    );

                    // Store in memory cache
                    this.memoryCache = dataCacheHit;

                    returnData(dataCacheHit);
                } else {
                    this.refreshData(true, data => returnData(data));
                }
            });

        }
    }

    private refreshData(showProgressBar: boolean, onData?: (data: DataCacheHit<T[]>) => void) {
        this.dataProvider
            .setShowProgressBar(showProgressBar)
            .find(data => {

                // Optional post data find hook
                if (this.postDataFindHook) {
                    this.postDataFindHook(data);
                }

                // Create data cache hit
                const dataCacheHit = new DataCacheHit(data, new Date());

                // Store in memory cache
                this.memoryCache = dataCacheHit;

                // Store in file cache
                const cacheService = AppInjector.getInjector().get(CacheService)
                cacheService.setIndexedDBItem(this.identifier, dataCacheHit.data, dataCacheHit.storeDate, this.timeToLiveInSeconds);

                // Return data
                if (onData) {
                    onData(dataCacheHit);
                }
            });
    }

}
