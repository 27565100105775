import {
    AfterContentChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {CardComponent} from '@app/shared/_ui/cards/card.component';
import {ProjectsService} from '@app/services/projects.service';
import {Project} from '@app/core/models/Project';
import {CardProjectConfiguration} from '@app/shared/_ui/cards/medium/card-project/card-project-configuration';
import {ProjectEstimate} from '@app/core/models/ProjectEstimate';
import {ProjectsUser} from '@app/core/models/ProjectsUser';
import {ProjectsDeadline} from '@app/core/models/ProjectsDeadline';
import {Archived} from '@app/core/models/Archived';
import {Router} from '@angular/router';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {DisplayTypes, PeriodUnits} from '@app/constants';
import {Display} from '@app/core/models/Display';
import {Location} from '@angular/common';
import {Field, ProjectEditorService} from '@app/editor/project-editor-loader/project-editor.service';
import {Fields, MiniCardFields} from '@app/editor/project-editor-loader/Fields';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {Settings} from '@app/pages/displays/display-projects/Settings';
import {DisplayService} from '@app/services/display.service';
import {ReactionsTypeInterface} from "@app/shared/_ui/reactions/ReactionsTypeInterface";
import Helpers from "@app/core/helpers";
import {Category, Milestone, MilestonePlan, PhasesProject, ProjectUserType, User} from "@app/core/models";
import {CardMilestoneConfiguration} from "@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration";
import {ListConfiguration} from '../../../lists/ListConfiguration';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {YearWheelColumnConfiguration} from '@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration';
import moment from 'moment';
import {MilestoneListConfiguration} from '@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration';
import {Api} from "@app/core/Api";
import {EventService} from "@app/services/event.service";
import {AppInjector} from "@app/services/app-injector.service";

@Component({
    selector: 'app-card-project',
    templateUrl: './card-project.component.html',
    styleUrls: ['../../card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardProjectComponent extends CardComponent implements OnInit, OnChanges, AfterContentChecked {

    @Input() item: CardItem<Project>;
    @Input() showNavigationPlanning = true;
    @Input() model: Project;
    @Input() mini = false;
    @Input() configuration: CardProjectConfiguration = new CardProjectConfiguration();
    @Input() listConfiguration: ListConfiguration;
    @Input() removable = false;
    @Input() tooltip: string;
    @Input() editable = false;
    @Input() interactive = true;
    @Output() contentClassChange = new EventEmitter<Project>();
    @Output() onCardClickEvent = new EventEmitter();
    @Output() onRemove = new EventEmitter();
    @ViewChild('toolsPopover', {static: false}) toolsPopover: NgbPopover;
    @ViewChild('previewPopover', {static: false}) previewPopover: NgbPopover;
    @ViewChild('cardContainer') cardContainer: ElementRef;

    // UI
    public editorFields: Map<number, Field>;
    public fields: Map<number, Field>;
    public isPhasesProjectEmpty = true;
    public lockedMenu = false;

    public yearWheelConfiguration: YearWheelColumnConfiguration;
    public projectEstimate: ProjectEstimate;
    public projectUser: ProjectsUser;
    public projectUserUsers: ProjectsUser[];
    public projectUserUsersUserlist: User[];
    public projectUserType: ProjectUserType;
    public projectDeadline: ProjectsDeadline;
    public projectDeadlineDate?: Date;
    public projectDeadlineName?: string;
    public planningDisplays: Display[];
    public planningDisplayName: string;
    public programDisplays: Display[];
    public programDisplaysName: string;
    public microCards: CardItem<Project>[];
    public reactionsTypeInterfaces: ReactionsTypeInterface[];
    public hasVisibleReactions = false;
    public nextMilestoneCard: CardItem<Milestone>;
    public visibleCategories: Category[];
    public currentPhasesProject?: PhasesProject;
    public milestoneListConfiguration = new MilestoneListConfiguration();

    private initialUrl: string;
    public projectUserNames: string = '';
    public previewOpen: boolean = false;

    constructor(private projectService: ProjectsService,
                private router: Router,
                private location: Location,
                private editorService: ProjectEditorService,
                private cd: ChangeDetectorRef,
                private dialogService: BaseDialogService,
                private displayService: DisplayService,
    ) {
        super();
        this._isLoading = true;
        this.initialized = false;
        this.initialUrl = Helpers.encodeUri(this.location.path());
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.milestoneListConfiguration
            .setShowProjectMiniCard(false)
            .setShowCreateNew(false);
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

        this.milestoneListConfiguration
            .setProject(this.model)
            .setIncludeRelatedTasks(true);
    }

    onItemUpdated(item: Project) {
        super.onItemUpdated(item);

        if (this.configuration && this.item) {
            if (this.configuration.projectEstimate) {
                if (this.item.item.project_estimates) {
                    this.configuration.projectEstimate = this.item.item.project_estimates.find(item => item.id == this.configuration.projectEstimate.id);
                } else {
                    this.configuration.projectEstimate = null;
                }
            }
            if (this.configuration.projectUser) {
                this.configuration.projectUser = this.item.item.projects_users.find(item => item.id == this.configuration.projectUser.id);
            }
            if (this.configuration.projectDeadline) {
                this.configuration.projectDeadline = this.item.item.projects_deadlines.find(item => item.id == this.configuration.projectDeadline.id);
            }

        }

        this.render();
    }

    // <editor-fold desc="View Actions">

    onArchivedToggle($event: any) {
        this.model.setArchived((this.model.archived_id ?? 0) == 0);
    }

    onStatusChange($event: number) {
        if (this.model.id != 0 && !this.editable) {
            this.model.setStatus($event);
        }
    }

    ngAfterContentChecked() {
        // AfterContentChecked
    }

    openDoor($event: any, display: Display) {
        $event.stopImmediatePropagation();
        switch (display.display_type_id) {
            case DisplayTypes.ProjectDetails:
                this.router.navigate(
                    [
                        '/app/displays/project-details/',
                        display.id,
                        this.shellService.getPageSettings()?.departmentId,
                        0,
                        this.model.id,
                        this.shellService.getPageSettings()?.primaryDisplayId,
                    ], {queryParamsHandling: "merge"}
                );
                break;
        }
    }

    openDisplay($event: any, display: Display) {
        $event.stopImmediatePropagation();
        this.router.navigate([
                '/app/displays/projects/',
                display.id,
                this.shellService.getPageSettings()?.departmentId,
                0,
                0,
                this.model.id,
            ],
            {queryParamsHandling: "merge"}
        );

        this.configuration.onGoToProgramClickEvent.emit(display);
    }

    getConfigurationDeadline() {
        if (this.configuration && this.configuration.projectDeadline) {
            return this.configuration.projectDeadline;
        } else {
            if (this.item && this.item.item.projects_deadlines && this.model.project_type) {
                return this.item.item.findProjectsDeadlineByType(this.model.project_type.default_project_deadline_type_id);
            } else {
                return null;
            }
        }
    }

    getConfigurationEstimate() {
        if (this.configuration && this.configuration.projectEstimate) {
            return this.configuration.projectEstimate;
        } else {
            return this.model.getDefaultTaskEstimate();
        }
    }

    getConfigurationUser() {
        if (this.configuration) {
            if (this.configuration.projectUser) {
                if (!this.configuration.projectUser.project_user_type) {
                    this.projectService.getProjectUserType(this.configuration.projectUser.project_user_type_id, type => {
                        this.configuration.projectUser.project_user_type = type;
                    });
                }
                return this.configuration.projectUser;
            } else {
                return this.model.responsible;
            }
        } else {
            return this.model.responsible;
        }
    }

    public isFieldVisible(fieldId: number): boolean {
        if (this.fields && this.fields.get(fieldId)) {
            return this.fields.get(fieldId).visible;
        } else {
            return false;
        }
    }

    triggerChangeDialog(item: Project) {
        this.dialogService.changeProjectType(
            this.translateService.instant(`_ui_change_type`),
            ``,
            this.translateService.instant('_global_ok'),
            this.translateService.instant('_global_cancel'),
            'sm',
            item
        )
            .then((confirmed) => {
                if (confirmed) {
                }

            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    get Fields() {
        return MiniCardFields;
    }

    get EditorFields() {
        return Fields;
    }

    public onToolsPopoverClickOutsideEvent() {
        this.toolsPopover.isOpen() ? this.toggleToolsMenu() : null
    }

    public onPreviewPopoverClickOutsideEvent() {
        this.previewPopover.isOpen() ? this.togglePreviewMenu() : null
    }

    // </editor-fold>

    protected render() {
        super.render();
        // Fields til oversættelser
        this.editorService.getEditorFieldsForType(this.model.project_type_id, fields => {
            this.editorFields = fields;
            this.markChangeDetectionDirty();
        });
        this.editorService.getMiniCardFieldsForType(this.model.project_type_id, fields => {
            this.fields = fields;
            this.markChangeDetectionDirty();
        });

        this.projectService.getProjectType(this.model.project_type_id, projectType => {
            this.model.project_type = projectType;
            if (!projectType) {
                // Unknown project type. Ex project list can do this
                return;
            }

            this.projectEstimate = this.getConfigurationEstimate();
            this.projectUser = this.getConfigurationUser();
            if (this.projectUser && this.model.projects_users) {
                this.projectUserUsers = this.model.projects_users?.filter((pu) => {
                    return pu.project_user_type_id == this.projectUser?.project_user_type_id;
                })
                this.projectUserUsersUserlist = this.projectUserUsers.map(pu => pu.user);
                // console.log('this.projectUserUsers : ', this.projectUserUsers);

                if(this.projectUser?.project_user_type_id) {
                    this.projectService.getProjectUserType(this.projectUser?.project_user_type_id, type => {
                        this.projectUserType = type;
                    });
                }
            }


            this.projectDeadline = this.getConfigurationDeadline();
            this.projectDeadlineDate = this.projectDeadline?.deadline?.getDate();
            this.projectDeadlineName = this.projectDeadline?.deadlineType?.getSmartName();

            if(this.projectDeadline){
                let endDateMoment = moment(this.projectDeadlineDate).endOf('month');

                this.yearWheelConfiguration = new YearWheelColumnConfiguration();
                this.yearWheelConfiguration
                    .setProject(this.model)
                    .setStart(this.projectDeadlineDate)
                    .setEnd(endDateMoment.add(1,'month').toDate())
                    .setPeriodUnit(PeriodUnits.Weeks)
                    .setProjectStart(this.projectDeadline)
                    .setAutoPeriod(true);

            }

            const microCards: CardItem<Project>[] = [];
            if (this.model && this.configuration && this.configuration.showProgramMiniCard) {
                this.model.getUpperProjects(projectType).forEach(project => {
                    microCards.push(new CardItem<Project>(project, this.createMicroCardConfiguration()));
                });
            }
            this.microCards = microCards;

            this.displayService.getDisplaysWithSettings(displays => {

                this.planningDisplays = displays.filter(display => {
                    const checkDisplayType = [DisplayTypes.ProjectDetails].includes(display.display_type_id);
                    const checkDepartment = display.departments_displays?.find(departmentsDisplays =>
                        departmentsDisplays.visible &&
                        departmentsDisplays.department_id === this.shellService.getPageSettings()?.departmentId) !== undefined;
                    return checkDisplayType && checkDepartment;
                });

                const lowerProjectTypeIds = projectType.getLowerProjectTypeIds();
                // console.log('lowerProjectTypeIds : ', lowerProjectTypeIds, 'this.projectType : ', projectType , 'this.systemsService.pageSettings.departmentId: ', this.systemsService.pageSettings.departmentId);
                this.programDisplays = displays.filter(display => {
                    let checkProjectTypeIdSetting = false;
                    let checkDepartment = false;

                    const excludedByConfig = this.configuration?.excludedProgramDisplays?.map(item => item.id).includes(display.id) ?? false;

                    if (display.displays_settings) {
                        display.displays_settings
                            .filter(displaysSetting => {
                                return displaysSetting.setting_id === Settings.ProjectTypeIds;
                            })
                            .map(displaysSetting => Number(displaysSetting.value))
                            .forEach(projectTypeId => {
                                if (lowerProjectTypeIds.includes(projectTypeId)) {
                                    checkProjectTypeIdSetting = true;
                                }
                            });
                    }

                    if (display.departments_displays) {
                        display.departments_displays.forEach(departmentsDisplays => {
                            if (departmentsDisplays.department_id === this.shellService.getPageSettings()?.departmentId) {
                                if (departmentsDisplays.visible) {
                                    checkDepartment = true;
                                }
                            }
                        });
                    }
                    return checkDepartment && checkProjectTypeIdSetting && !excludedByConfig;
                });
            });

            this.planningDisplayName = this.translateService.instant('_ui_display_planning') + ' - ' + this.model.title;
            this.programDisplaysName = this.translateService.instant('_ui_overview') + ' - ' + this.model.title;

            this.reactionsTypeInterfaces = projectType.project_types_reaction_types ?? [];

            this.initialized = true;
            this.markChangeDetectionDirty();
        });

        if (this.configuration?.showNextMilestoneMicroCard && this.model.next_milestone?.exists()) {
            this.nextMilestoneCard = new CardItem<Milestone>(this.model.next_milestone, new CardMilestoneConfiguration(false, true));
        } else {
            this.nextMilestoneCard = undefined;
        }

        const excludedCategoryIds = this.configuration?.excludedCategoryIds ?? [];
        if (this.configuration?.showCategories) {
            this.visibleCategories = this.model.categories?.filter(category => !excludedCategoryIds.includes(category.id)).sort((a, b) => {
                if (a.category_type_id !== b.category_type_id) {
                    return a.category_type_id - b.category_type_id;
                } else {
                    return a.name.localeCompare(b.name);
                }
            });
        } else {
            this.visibleCategories = [];
        }

        if (this.isFieldVisible(this.Fields.Phase) && this.model?.current_phases_project?.phase) {
            if (this.configuration.showPhase) {
                this.currentPhasesProject = this.model.current_phases_project;
            } else if (this.configuration.showPhaseIfHasProgress && this.model.current_phases_project?.current_phase_progress) {
                this.currentPhasesProject = this.model.current_phases_project;
            }
        }
    }

    private createMicroCardConfiguration(): CardProjectConfiguration {
        const config = new CardProjectConfiguration();

        // Copy fields from this configuration
        config.onGoToProgramClickEvent = this.configuration.onGoToProgramClickEvent;
        return config;
    }

    delete(model: Project) {
        const eventService = AppInjector.getInjector().get(EventService);

        this.dialogService.deleteProject(
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_ui_delete_item', {name: model.title}),
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_global_cancel'),
            'sm',
            model
        )
            .then((confirmed) => {
                if (confirmed) {

                    if (confirmed.cascade) {
                        // Fetch all milestones and tasks related to project and emit delete.
                        // No need to actually delete these items. The server does it.
                        Api.projects().getById(this.item.item.id)
                            .include('task')
                            .include('milestone')
                            .find(projects => {
                                if (projects.length == 1) {
                                    const project = projects[0];
                                    if (project.tasks) {
                                        project.tasks.forEach(task => eventService.emitTask(task, EventService.Deleted));
                                    }
                                    if (project.milestones) {
                                        project.milestones.forEach(milestone => eventService.emitMilestone(milestone, EventService.Deleted));
                                    }
                                }
                            });
                    }

                    this.item.item.delete(confirmed.cascade);
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

    }

    copyProjectDialog() {
        this.dialogService.copyProject(
            this.translateService.instant(`_ui_copy`) + ' ' + this.translateService.instant(this.model.title).toLowerCase(),
            this.translateService.instant('_ui_copy_item', {item: this.model.title}),
            this.translateService.instant('_global_ok'),
            this.translateService.instant('_global_cancel'),
            'sm',
            this.model,
        )
            .then((confirmed) => {

                // Sætter deadline for alle opgaver (sættes i move-milestone-deadline-dialog.component.ts)

            })
            .catch(() => {
                console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
            });
    }

    onMilestonePlanAppliedEvent($event: MilestonePlan) {

    }

    toggleToolsMenu() {
        if (this.lockedMenu) {

        } else {
            if(this.previewOpen){
                // this.previewPopover.close();

            }else {
                if (this.toolsPopover)
                    !this.toolsPopover.isOpen() ? this.toolsPopover.open() : this.toolsPopover.close()
            }
        }
    }

    closeToolsMenu() {
        if (!this.lockedMenu && this.toolsPopover) {
            this.toolsPopover.close();
        }
    }

    togglePreviewMenu() {
        if (this.lockedMenu) {

        } else {
            console.log('togglePreviewMenu : ', this.previewPopover)
            if (this.previewPopover) {
                !this.previewPopover.isOpen() ? this.previewPopover.open() : this.previewPopover.close();
                this.previewOpen = this.previewPopover.isOpen();
            }else{
                this.previewOpen = false;
            }

        }
    }

    closePreviewMenu() {
        if (!this.lockedMenu && this.previewPopover) {
            this.previewPopover.close();
        }
    }
}
