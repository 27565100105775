import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {EditTitle} from "@app/editor/quick-editor/editors/generic/title-editor/EditTitle";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditTitleConfiguration} from "@app/editor/quick-editor/editors/generic/title-editor/EditTitleConfiguration";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {debounce} from "lodash";

@Component({
    selector: 'app-title-editor',
    templateUrl: './title-editor.component.html',
    styleUrls: ['./title-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TitleEditorComponent extends BaseEditor<EditTitle> {
    protected eventFieldName = 'title';

    // Bindings to parent
    @Input() model: EditTitle&HasEventGenerator&BaseModel;
    @Input() configuration: EditTitleConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public value: string;
    public isExpanded: boolean;

    // Date
    private isFocused = false;
    private debouncedSave = debounce(() => this.save(), 1000);

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribe(this.configuration?.onChangeEvent.subscribe(() => this.render()));
    }

    protected setup() {
    }

    protected render() {
        if (this.isFocused) {
            return;
        }
        this.value = this.model.getTitle();
        this.isExpanded = this.configuration?.isExpanded;
        this.configuration.validator.validate(this.value);
        this.detectChanges();
    }

    public onValueChanged() {
        this.debouncedSave();
    }

    public onFocusChanged(value: boolean) {
        this.isFocused = value;
        if (!value) {
            this.debouncedSave();
        }
    }

    private save() {
        if (this.model.getTitle().localeCompare(this.value) !== 0) {
            this.model.setTitle(this.value, () => this.onItemUpdated());
        }
        this.render();
    }

}
