import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypeSettings";
import {ProjectDetails_Top_ProjectDeadlineTypeFromAndToSettingValue} from "@app/core/Api";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";

export class ProjectDeadlineDifferenceCell extends BaseCell {

    public aDate?: Date;
    public bDate?: Date;
    public isVisible = false;

    public constructor(row: Row, column: BaseColumn) {
        super(row, column);

        const data = column.settings.get(ColumnTypeSettings.ProjectDeadlineTypeFromAndTo)
            ?.getObject<ProjectDetails_Top_ProjectDeadlineTypeFromAndToSettingValue>();
        this.aDate = row.projectCard.item.findDeadlineByTypeId(data.fromProjectDeadlineTypeId)?.getDate();
        this.bDate = row.projectCard.item.findDeadlineByTypeId(data.toProjectDeadlineTypeId)?.getDate();
        this.isVisible = this.aDate !== undefined && this.bDate !== undefined;
    }

}
