import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ShellColumnGroup} from "@app/services/ShellColumnService/ShellColumnGroup";
import {ColumnGroup} from "@app/services/ShellColumnService/ColumnGroup";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {FilterColumnRow} from "@app/shell/header/filters/filter-column/FilterColumnRow";

@Component({
    selector: 'app-filter-column',
    templateUrl: './filter-column.component.html',
    styleUrls: ['./filter-column.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FilterColumnComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() shellColumnGroup: ShellColumnGroup;
    @Output() onGroupUpdated = new EventEmitter();
    @Output() onGroupSaved = new EventEmitter();

    // Bindings to view
    public rows?: FilterColumnRow[];

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.subscribe(this.shellColumnGroup.onColumnChangeEvent.subscribe((columns) => {
            this.render();
        }));
        this.subscribe(this.shellColumnGroup.onColumnResetEvent.subscribe(tableColumns => {
            tableColumns.forEach(tableColumn => {
                tableColumn.isVisible = tableColumn.column.column.is_show_as_default ?? false;
                this.shellColumnGroup.markChanged();
            });
            this.render();
        }));
        this.render();
    }

    private render() {
        if (!this.shellColumnGroup.tableColumnGroups || !this.shellColumnGroup.tableColumns) {
            return;
        }

        this.rows = this.shellColumnGroup.tableColumnGroups.map(tableColumnGroup => {
            return new FilterColumnRow(tableColumnGroup);
        });

        this.rows.forEach(row => row.checkIsActive());
        this.detectChanges();
    }

    public toggleColumn(column: BaseTableColumn) {
        column.isVisible = !column.isVisible;
        column.setWidth(0);
        this.shellColumnGroup.markChanged();
        this.onGroupUpdated.emit();
        this.render();
    }

    public toggleGroup(group: ColumnGroup) {
        group.toggleActive();
        group.columns.forEach(column => {
            column.isVisible = group.active;
            column.setWidth(0);
        });
        this.shellColumnGroup.markChanged();
        this.onGroupUpdated.emit();
        this.render();
    }

}
