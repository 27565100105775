import {
    AfterViewInit,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgbDate, NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import {Deadline} from '@app/core/models/Deadline';
import {BaseFormControlComponent} from '@app/shared/_forms/base-form-control/base-form-control.component';
import {StaticDeadline} from "@app/core/models";
import {PlacementArray} from "@app/directives/positioning";

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true,
        }
    ],
    standalone: false
})
export class DatepickerComponent extends BaseFormControlComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() disabled = false;
    @Input() autoReset = false;
    @Input() required = false;
    @Input() invalid = false;
    @Input() showSoftDeadline = true;
    @Input() staticDeadlines: StaticDeadline[];
    @Input() staticDeadlineDescriptor: (staticDeadline: StaticDeadline) => [string | undefined, Date | undefined];
    @Input() placement: PlacementArray = ['left', 'top', 'bottom', 'right'];
    @Input() staticDeadlineIcon = 'fal fa-calendar-star';

    @Output() valueChanged = new EventEmitter<Deadline>();

    public date: Date = new Date();
    public previousDate = new Date();
    public isSoft: boolean;
    public dateStruct: NgbDateStruct;
    label: string;

    @ViewChild('datepicker', {static: true}) datepicker: NgbInputDatepicker;

    constructor() {
        super();
    }

    public onChange: any = () => {
        this.setDate();
        // console.log('onChange() : this.dateStruct : ', this.dateStruct)
        this.propagateChange(this.dateStruct);
    };

    ngOnInit() {
        this.setDate();
    }

    ngOnChanges(changes: any): void {
        super.ngOnChanges(changes);
        if (!this.autoReset && changes._internalvalue) {
            this.previousDate = moment(changes._internalvalue.previousValue.date).startOf('day').toDate();
            this.date = moment(changes._internalvalue.currentValue.date).startOf('day').toDate();
            this.dateStruct = {
                year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()
            };
            this.isSoft = changes._internalvalue.currentValue.is_soft;
            this.setDate();
        }
    }

    ngAfterViewInit() {

    }

    private setDate() {
        const val = this._internalValue;
        if (val) {
            this.date = moment(val.date).startOf('day').toDate();
            this.dateStruct = {
                year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()
            };
            this.isSoft = val.is_soft;
        }
    }

    @Input() set internalValue(val: any) {
        // console.log('internalValue : ', val);
        if (val === undefined || val === null || val === '' || (val && val.id == 0 && !val.date)) {
            this._internalValue = null;
            this.dateStruct = null;
            this.onChange(undefined); // Value is rubbish, so don't send back to model propper
            return;
        }

        this._internalValue = val;
        this.setDate();

        this.onChange(this._internalValue); // Value is good!
    }


    private setValue() {
        // console.log('setValue() : ');
        let value = null;
        if (this.dateStruct) {
            let mdt = moment([this.dateStruct.year, this.dateStruct.month - 1, this.dateStruct.day]);
            value = mdt.format('YYYY-MM-DDTHH:mm:ssZ');
        }

        let deadline: Deadline = new Deadline({id: 0, date: value, is_soft: this.isSoft});
        this.internalValue = deadline;
        this.propagateChange(deadline);
        this.valueChanged.emit(deadline);
        if (this.autoReset) {
            this.dateStruct = null;
        }
    }

    zeroValue() {
        this.dateStruct = null;
        this.setValue();
    }

    changeSelectedDate(ngbDate: NgbDate) {
        // console.log('changeSelectedDate() : ', ngbDate);
        this.dateStruct = ngbDate;
        this.setValue();
    }

    isSoftChangeEvent() {
        this.setValue();
    }

    setStaticDeadline(staticDeadline: StaticDeadline) {
        let mdt = moment(staticDeadline.date);
        this.date = mdt.toDate();
        this.dateStruct = {
            year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()
        };
        const value = mdt.format('YYYY-MM-DDTHH:mm:ssZ');
        this.setValue();
    }

}
