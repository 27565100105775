import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {BaseModel} from "@app/core/models/BaseModel";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {EditTodoCategory} from "@app/editor/quick-editor/editors/todo/todo-category-editor/EditTodoCategory";
import {
    EditTodoCategoryConfiguration
} from "@app/editor/quick-editor/editors/todo/todo-category-editor/EditTodoCategoryConfiguration";
import {TodoCategory} from "@app/core/models";

@Component({
    selector: 'app-todo-category-editor',
    templateUrl: './todo-category-editor.component.html',
    styleUrls: ['./todo-category-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TodoCategoryEditorComponent extends BaseEditor<EditTodoCategory> {
    protected eventFieldName = 'todo-category';

    // Bindings to parent
    @Input() model: EditTodoCategory & HasEventGenerator & BaseModel;
    @Input() configuration: EditTodoCategoryConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public selectedTodoCategory: TodoCategory;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected render() {
        this.selectedTodoCategory = this.model.getTodoCategory();
        this.detectChanges();
    }

    public onSelectedItemChanged(item?: TodoCategory) {
        this.model.setTodoCategory(item);
        this.onItemUpdated();
        this.render();
    }

}
