import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import {DisplayFilter} from "@app/core/models";
import {AppInjector} from "@app/services/app-injector.service";
import {LocalStorageService} from "@app/services/local-storage.service";
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {EditorPanelService} from "@app/services/editor-panel.service";

@Component({
    selector: 'app-header-title-filter-item',
    templateUrl: './header-title-filter-item.component.html',
    styleUrls: ['./header-title-filter-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HeaderTitleFilterItemComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() item: DisplayFilter;
    @Input() filterPageData: ShellPageData;
    @Input() canMoveUp: boolean;
    @Input() canMoveDown: boolean;
    @Input() dragAndDrop: boolean;
    @Input() actionsVisible: boolean = true;

    @Output() moveFilterUpClickEvent = new EventEmitter<DisplayFilter>();
    @Output() moveFilterDownClickEvent = new EventEmitter<DisplayFilter>();
    @Output() deleteClickEvent = new EventEmitter<DisplayFilter>();
    @Output() removeDepartmentFromFilterClickEvent = new EventEmitter<DisplayFilter>();
    @Output() setFavoriteClickEvent = new EventEmitter<DisplayFilter>();
    @Output() removeFavoriteClickEvent = new EventEmitter<DisplayFilter>();
    @Output() setDefaultClickEvent = new EventEmitter<DisplayFilter>();
    @Output() setVisibilityClickEvent = new EventEmitter<boolean>();
    @Output() closeClickEvent = new EventEmitter();

    public isActive = false;
    public canDelete = false;
    public canRemoveDepartment = false;
    public canSetInvisible = false;
    public canEdit = false;


    constructor(private editorPanelService: EditorPanelService, private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.render();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filterPageData']) {
            this.render();
        }
    }

    private render() {
        if (!this.user) {
            return;
        }

        this.isActive = this.filterPageData?.shellFilterGroup?.activeFilter?.id == this.item.id;

        this.canDelete = this.item.isMine() || (this.item.isDepartment() && this.user.administrator);

        this.canRemoveDepartment = this.item.isDepartment() && this.user.administrator;

        this.canSetInvisible = this.item.isDepartment();

        this.canEdit = this.item.isMine() || (this.item.isDepartment() && this.user.administrator);

        this.detectChanges();
    }

    changeFilter(filter: DisplayFilter) {
        const key = window.location.pathname;
        const storageService = AppInjector.getInjector().get(LocalStorageService);
        const displayStorage = storageService.get(key) || {};
        displayStorage.storedColumns = [];
        storageService.set(key, displayStorage);
        this.filterPageData.setActiveDisplayFilter(filter);
        this.closeClickEvent.emit();
    }

    moveFilterUp(filter: DisplayFilter, event: MouseEvent) {
        event.stopImmediatePropagation();
        this.moveFilterUpClickEvent.emit(filter);
    }

    moveFilterDown(filter: DisplayFilter, event: MouseEvent) {
        event.stopImmediatePropagation();
        this.moveFilterDownClickEvent.emit(filter);
    }

    editFilter(filter: DisplayFilter) { // Open for global display
        if (!filter) {
            filter = DisplayFilter.CreateDefault(this.filterPageData.display);
            this.filterPageData.shellFilterGroup.settings.applyDefaults(filter);
        }
        filter.display = this.filterPageData.display; // Add display with all its displaySettings
        this.editorPanelService.openDisplayFilterEditor(this.filterPageData, filter);
        this.closeClickEvent.emit();
    }

    deleteFilter(filter: DisplayFilter) {
        this.deleteClickEvent.emit(filter);
    }

    removeDepartmentFromFilter(filter: DisplayFilter) {
        this.removeDepartmentFromFilterClickEvent.emit(filter);
    }

    setFavorite(filter: DisplayFilter) {
        this.setFavoriteClickEvent.emit(filter);
    }

    removeFavorite(filter: DisplayFilter) {
        this.removeFavoriteClickEvent.emit(filter);
    }

    onSetDefaultBtnClicked(filter: DisplayFilter) {
        this.setDefaultClickEvent?.emit(filter);
    }

    onSetVisibilityBtnClicked(value: boolean) {
        this.setVisibilityClickEvent?.emit(value);
    }

}
