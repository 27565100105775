import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {CardInterface, CardItem} from '@app/shared/_ui/cards/CardItem';
import {Appointment} from '@app/core/models/Appointment';
import {AppointmentListConfiguration} from '@app/shared/_ui/lists/appointment-list/AppointmentListConfiguration';
import {EventService} from '@app/services/event.service';
import {Subscription} from 'rxjs';
import {CardConfiguration} from '@app/shared/_ui/cards/CardConfiguration';
import Helpers from '@app/core/helpers';
import {CSVListBinding} from '@app/export/csv/CSVListBinding';
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {CdkDropEvent} from "@app/interfaces/CdkDropEvent";
import {Api} from "@app/core/Api";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";

@Component({
    selector: 'app-appointment-list',
    templateUrl: './appointment-list.component.html',
    styleUrls: ['./appointment-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppointmentListComponent extends BaseDisplayComponent implements OnInit, OnChanges, CardInterface<Appointment> {

    @Output() dataSetChanged = new EventEmitter<CardItem<Appointment>[]>();
    @Input() listClass: string = 'w-100 d-flex flex-row';
    @Input() configuration: AppointmentListConfiguration;
    @Input() minimizeEvent: EventEmitter<any>;
    @Input() loadAllEvent: EventEmitter<any>;
    @Input() reloadEvent: EventEmitter<any>;
    @Input() scrollContainer: HTMLElement;
    @Input() csvListBinding: CSVListBinding;

    // Data
    public items: CardItem<Appointment>[];
    private itemsMap: Map<number, CardItem<Appointment>>;
    public offset = 0;
    public itemCount = 0;
    private loadAllEventSubscription?: Subscription;

    constructor(private eventService: EventService, private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        if (!this.configuration) {
            this.configuration = new AppointmentListConfiguration();
        }

        if (this.minimizeEvent) {
            this.minimizeEvent.subscribe(() => {
                if (this.items) {
                    this.items.splice(this.configuration.getLimit());
                }
                this.itemsMap.clear();
                this.items?.forEach(item => this.itemsMap.set(item.item.id, item));
                this.offset = 0;
                this.items = [...this.items ?? []];
                this.detectChanges();
            });
        }

        if (this.reloadEvent) {
            this.reloadEvent.subscribe(() => this.loadData());
        }

        // Push
        this.eventService.subscribeToAppointment(0, event => {
            switch (event.action) {
                case EventService.Created:
                    this.appointmentCreated(event.item, event.fields);
                    break;
                case EventService.Updated:
                    this.appointmentUpdated(event.item, event.fields);
                    break;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['loadAllEvent']) {
            if (this.loadAllEvent) {
                this.loadAllEventSubscription?.unsubscribe();
                this.loadAllEventSubscription = this.loadAllEvent.subscribe(() => {
                    this.loadAll();
                });
            }
        }
        if (changes['configuration'] != null) {
            if (this.configuration.getDataSource()) {
                this.isLoading = true;
                this.items = [];
                this.itemsMap = new Map();
                this.itemCount = 0;
                this.configuration.getDataSource().subscribe((appointments, count) => {
                    this.offset = 0;
                    this.items = [];
                    this.itemsMap = new Map();
                    this.itemCount = 0;
                    appointments.forEach(appointment => this.addItem(appointment));
                    this.isLoading = false;
                    this.dataSetChanged.emit(this.items);
                    this.itemCount = count;
                    this.markChangeDetectionDirty();
                });
            } else {
                this.loadData();
            }
        }

        // CSV Export
        if (this.csvListBinding)
            this.csvListBinding.setExportFunction(() => {
                return this.items?.map(appointment => {
                    return `${appointment.item.getDateString()}: ${appointment.item.getCleanDescription()}`;
                }) ?? [];
            });
    }

    // <editor-fold desc="View Actions">

    loadMore() {
        this.loadItems(this.offset + this.configuration.getLimit());
    }

    loadLess() {
        this.offset = 0;
        this.items = this.items?.slice(0, this.configuration.getLimit()) ?? [];
        this.dataSetChanged.emit(this.items);
        this.detectChanges();
    }

    loadAll() {
        this.loadItems(this.offset + this.configuration.getLimit(), false, true);
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private loadData() {
        this.items = [];
        this.itemsMap = new Map();
        this.itemCount = 0;
        this.loadItems(0, true)
    }

    private apiRequest?: ApiRequest;

    private loadItems(offset: number, doCount: boolean = false, loadAll: boolean = false) {
        this.apiRequest?.cancel();

        let api = Api.appointments().get()
            .include('created_by')
            .include('updated_by');

        if (this.configuration.getDynamicMenuItem()) {
            api
                .include('dynamic_menu_item?include=role')
                .where('dynamic_menu_item.id', this.configuration.getDynamicMenuItem().id);
        }
        if (this.configuration.getProject()) api.include('project').where('project.id', this.configuration.getProject().id);
        if (this.configuration.getUser()) {
            api.include('appointments_user').where('user.id', this.configuration.getUser().id);
            if (this.configuration.getUserPeriod())
                api.whereGreaterThanOrEqual('appointments_user.start', Helpers.serverDate(this.configuration.getUserPeriod()[0]))
                    .whereLessThan('appointments_user.start', Helpers.serverDate(this.configuration.getUserPeriod()[1]));
        }
        if (this.configuration.getAvoidIds()) api.whereNotIn('id', this.configuration.getAvoidIds());

        this.configuration.getOrderBy().forEach(orderBy => api.orderBy(orderBy[0], orderBy[1]));
        this.apiRequest = api
            .offset(offset)
            .limit(loadAll ? 100 : this.configuration.getLimit())
            .find(appointments => {
                this.offset = offset;
                appointments.forEach(appointment => this.addItem(appointment));
                this.items = [...this.items];
                this.dataSetChanged.emit(this.items);
                this.detectChanges();
            });

        if (doCount) {
            api.count(count => {
                this.itemCount = count;
                this.detectChanges();
            });
        }
    }

    private addItem(item: Appointment, appendToEnd: boolean = true) {
        let cardItem = new CardItem<Appointment>(item, new CardConfiguration());
        cardItem.setListener(this);
        if (appendToEnd) {
            this.items.push(cardItem);
        } else {
            this.items.unshift(cardItem);
        }
        this.itemsMap.set(item.id, cardItem);
        this.markChangeDetectionDirty();
    }

    private removeItem(item: Appointment) {
        if (this.itemsMap.has(item.id)) {
            this.items.splice(this.items.indexOf(this.itemsMap.get(item.id)), 1);
            this.itemsMap.delete(item.id);
            this.itemCount--;
            this.detectChanges();
        }
    }

    // </editor-fold>

    // <editor-fold desc="Push">

    private appointmentCreated(appointment: Appointment, fields: string[]) {
        if (this.validateAppointment(appointment)) {
            if (!appointment.created_by) {
                appointment.created_by = this.usersService.user;
            }
            this.addItem(appointment, false);
            this.dataSetChanged.emit(this.items);
            this.itemCount++;
            this.offset++;
        }
    }

    private appointmentUpdated(appointment: Appointment, fields: string[]) {
        if (!this.itemsMap.has(appointment.id))
            this.appointmentCreated(appointment, fields);
        else if (!this.validateAppointment(appointment)) {
            this.removeItem(appointment);
            this.detectChanges();
        }
    }

    private validateAppointment(appointment: Appointment): boolean {
        return this.configuration.validate(appointment);
    }

    onCardItemDeleted(cardItem: CardItem<Appointment>): void {
        this.removeItem(cardItem.item);
        this.detectChanges();
    }

    onCardItemDragged(cardItem: CardItem<Appointment>, listConfiguration: ListConfiguration, toContainerId: string, fromContainerId: string, cdkDropEvent: CdkDropEvent<Appointment>): void {

    }

    onCardItemUpdated(cardItem: CardItem<Appointment>): void {

    }

    // </editor-fold>

}
