import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class UnauthorizedComponent implements OnInit {

    constructor(private authService: AuthService) {
    }

    ngOnInit() {

    }

    onLoginBtnClicked(): void {
        this.authService.logout();
    }

    onGotoHomePageBtnClicked() {
        window.location.href = '/';
    }

}
