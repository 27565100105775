import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnChanges,
    OnInit, Output,
    SimpleChanges
} from '@angular/core';
import {PANEL_ANIMATION} from '@app/editor/panel.animation';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {Milestone} from '@app/core/models';
import {BaseEditorV3Component} from '@app/editor/base-editor-v3/base-editor-v3.component';
import {Tabs} from '@app/constants';
import {TranslateService} from '@ngx-translate/core';
import {MilestonesService} from '@app/services/milestones.service';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {Field} from '@app/editor/milestone-editor-loader/milestone-editor.service';
import {EventService} from '@app/services/event.service';
import {ValidationErrorInterface} from '@app/editor/Validation/ValidationErrorInterface';
import {ValidatorCollection} from '@app/editor/Validation/ValidatorCollection';
import { Fields } from '../Fields';
import {AppInjector} from "@app/services/app-injector.service";
import {SnackbarService} from "@app/services/snackbar.service";

@Component({
    selector: 'app-milestone-editor-v3',
    templateUrl: './milestone-editor-v3.component.html',
    styleUrls: [
        '../../base-editor/base-editor.component.scss',
        'milestone-editor-v3.component.scss',
    ],
    animations: [
        PANEL_ANIMATION,
        fadeAnimation,
        slideDownAnimation
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MilestoneEditorV3Component extends BaseEditorV3Component implements OnInit, OnChanges {

    // Bindings to parent
    @Input() item: Milestone;
    @Input() options: any;
    @Input() fields: Map<number, Field>;
    @Output() onSavedEventEmitter = new EventEmitter<Milestone>();
    @Output() onReloadEventEmitter = new EventEmitter();
    @Output() onCloseEventEmitter = new EventEmitter();

    // Bindings to view
    public isReady = false;
    public isOpen = true;
    public selectedTab = Tabs.Basic;
    public validator = new ValidatorCollection([]);
    public basicTabValidator: ValidationErrorInterface;
    public logTabValidator: ValidationErrorInterface;
    public onLogTabOpenEventEmitter = new EventEmitter<boolean>();

    // </editor-fold>
    protected readonly Fields = Fields;

    // Data

    constructor(
        private cd: ChangeDetectorRef,
        private milestonesService: MilestonesService,
        private translateService: TranslateService,
        private dialogService: BaseDialogService,
        private eventService: EventService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['item']) {
            this.open();
        }
    }

    // <editor-fold desc="View actions">

    public onCloseBtnClicked() {
        this.close();
    }

    public onDeleteBtnClicked() {
        const snackbar = AppInjector.getInjector().get(SnackbarService);
        this.dialogService.deleteMilestone(
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_ui_delete_item', {name: this.item.title}),
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_global_cancel'),
            'sm',
            this.item
        )
            .then((confirmed) => {
                if (confirmed) {
                    this.item.delete(confirmed.cascade, () => {
                        snackbar.add(this.translateService.instant('_ui_item_deleted', {item: this.item.name}));
                        this.close();
                    });
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    public onTabChanged($event: any) {
        this.selectedTab = $event.nextId;
        switch ($event.nextId) {
            case Tabs.Logs:
                this.onLogTabOpenEventEmitter.emit(true);
                break;
        }
    }

    public onTitleValidatorChanged($event: ValidationErrorInterface) {
        this.validator.addValidator($event);
    }

    public onBasicValidatorChanged(validator: ValidationErrorInterface) {
        this.basicTabValidator = validator;
        this.validator.addValidator(validator);
    }

    public onLogTabValidatorChanged(validator: ValidationErrorInterface) {
        this.logTabValidator = validator;
        this.validator.addValidator(validator);
    }

    protected open() {
        super.open();
        this.isReady = false;
        this.isOpen = true;

        if (this.options && this.options.selectedTab) {
            this.selectedTab = this.options.selectedTab;
        }

        this.render();
    }

    private close() {
        this.onCloseEventEmitter.emit();
    }

    private render() {
        this.isReady = true;
        this.subscribe(this.eventService.subscribeToMilestone(this.item.id, event => {
            switch (event.action) {
                case EventService.Updated:
                    // TODO Show something @kevin
                    break;
            }
        }));
    }
}
