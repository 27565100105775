import {Injectable} from '@angular/core';
import {BaseService} from '@app/services/base.service';
import {from, Observable} from 'rxjs';
import {TaskType} from '@app/core/models/TaskType';
import {Task} from '@app/core/models/Task';
import {TaskUserType} from '@app/core/models/TaskUserType';
import {Api} from '@app/core/Api';
import {TaskEstimateType} from "@app/core/models";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {System} from '@app/constants';
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";

@Injectable({
    providedIn: 'root'
})
export class TasksService extends BaseService {

    constructor(private filterGlobalService: FilterGlobalService) {
        super();
    }

    // <editor-fold desc="Task Type (Queue & Cache)">

    private taskTypeDataCaches: Map<number, ApiDataCache<TaskType>> = new Map();

    public getTaskType(id: number, callback: (taskType: TaskType) => void): any {
        if (!this.taskTypeDataCaches.has(id)) {
            const dataCache = new ApiDataCache<TaskType>(
                `${System.TaskType}-${id}`,
                2 * 60 * 60, // 2 hours
                Api.taskTypes().getById(id)
                    .include('task_types_status_rule?include=status_rule')
                    .include('task_deadline_type')
            );
            this.taskTypeDataCaches.set(id, dataCache);
        }
        this.taskTypeDataCaches.get(id).getFirst(callback);
    }

    // </editor-fold>

    // <editor-fold desc="Task Estimate Type (Queue & Cache)">

    private taskEstimateTypeDataCache = new ApiDataCache<TaskEstimateType>(
        System.TaskEstimateTypes,
        2 * 60 * 60, // 2 hours
        Api.taskEstimateTypes().get()
    );

    public getTaskEstimateTypes(callback: (items: TaskEstimateType[]) => void) {
        this.taskEstimateTypeDataCache.get(callback);
    }

    // </editor-fold>

    // <editor-fold desc="Task Types Simple version (Queue & Cache)">

    private taskTypesDataCache = new ApiDataCache<TaskType>(
        System.TaskTypes,
        2 * 60 * 60, // 2 hours
        Api.taskTypes().get()
            .setShowProgressBar(false)
            .include('department')
    );

    public getTaskTypes(callback: (items: TaskType[]) => void) {
        this.taskTypesDataCache.get(callback);
    }

    // </editor-fold>

    // <editor-fold desc="Task User Type (Queue & Cache)">

    private taskUserTypesDataCache = new ApiDataCache<TaskUserType>(
        System.TaskUserTypes,
        2 * 60 * 60, // 2 hours
        Api.taskUserTypes().get()
    );

    public getTaskUserTypes(callback: (items: TaskUserType[]) => void) {
        this.taskUserTypesDataCache.get(callback);
    }

    public getTaskUserType(id: number, callback: (item: TaskUserType) => void) {
        this.getTaskUserTypes(items => callback(items.find(item => item.id == id)));
    }

    // </editor-fold>

    search(term: string): Observable<Task[]> {
        const api = Api.tasks().get();

        if (term !== '') {
            api
                .search('title', term)
                .orderDesc('created');
        }

        api
            .orderBy('title', this.filterGlobalService.getActiveSettings().activeSortDirection)
            .limit(25);

        return from(new Promise<Task[]>(resolve => {
            api.find(items => {
                resolve(items);
            });
        }));
    }

}
