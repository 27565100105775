import {Component, Inject, Injector, Input, OnInit} from '@angular/core';
import {BaseModalComponent} from "@app/shared/_modals/base-modal.component";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ProjectsService} from "@app/services/projects.service";
import {TasksService} from "@app/services/tasks.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {TranslateService} from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    standalone: false
})
export class ErrorDialogComponent extends BaseModalComponent implements OnInit{
    @Input() message: any;
    @Input() stack: HttpErrorResponse;

    private translateService: TranslateService;

    public showErrorLog = true;
    public description = '';

    constructor(@Inject(Injector)
                private readonly injector: Injector,
                private modal: NgbActiveModal,
                private projectsService: ProjectsService,
                private tasksService: TasksService,


                ) {
        super(modal);
        // console.log('ErrorDialogComponent : error : ', this.message);
        // console.log('ErrorDialogComponent : error : ', this.stack);
        this.translateService = this.injector.get(TranslateService);
        this.title = this.translateService.instant('_admin_system_message');
        // this.description = this.translateService.instant('_ui_system_error_description');


    }

    ngOnInit() {
        // console.log('ErrorDialogComponent : error : ', this.message, 'stack : ', this.stack);
        if(this.stack && this.stack.error){
            if(this.stack.error.error == 'invalid_token'){
                this.showErrorLog = false;
            }
        }
        // if(this.stack && this.stack.)
    }

}
