import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {EventEmitter} from '@angular/core';
import {Project, Task, User} from "@app/core/models";
import {CardUserConfiguration} from "@app/shared/_ui/cards/medium/card-user/card-user-configuration";
import moment from "moment";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";

export class Row extends BaseRow {

    project: Project;

    userCard: CardItem<User>;

    // Data
    taskEditListItems: CardItem<Task>[];

    // Events
    minimizeEvent: EventEmitter<any> = new EventEmitter();

    constructor(project: Project, user: User) {
        super();
        this.project = project;
        const config = new CardUserConfiguration();
        config.showProjectCount = false;
        config.showLoads = false;
        config.showPeriodRating = true;
        config.setPeriod(moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate());
        this.userCard = new CardItem<User>(user, config);
    }

    public reload() {

    }

}
