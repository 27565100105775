import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {TodoCategory} from '@app/core/models/TodoCategory';
import {BaseService} from '@app/services/base.service';
import {Todo} from '@app/core/models/Todo';
import {TodoField} from "@app/core/models/TodoField";
import {FieldTypes} from "@app/constants";
import {Api} from '@app/core/Api';
import {ReactionTypesTodo} from "@app/core/models";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";

@Injectable({
    providedIn: 'root'
})
export class TodosService extends BaseService {

    constructor(private filterGlobalService: FilterGlobalService) {
        super();
    }

    // <editor-fold desc="Fields (Queue & Cache)">

    // Cache
    private fieldsCache: TodoField[];

    // Queue
    private fieldsCallbacks: ((fields: TodoField[]) => void)[] = [];
    private fireFieldsCallback(fields: TodoField[]) {
        const callbacks = this.fieldsCallbacks;
        this.fieldsCallbacks = [];
        for(let callback of callbacks) {
            callback(fields);
        }
    }

    // Entry point
    private getFields(callback: (fields: TodoField[]) => void) {
        if(this.fieldsCache) // Use cache
            callback(this.fieldsCache);
        else if(this.fieldsCallbacks.length) // Add to queue
            this.fieldsCallbacks.push(callback);
        else { // Get from API
            this.fieldsCallbacks.push(callback);
            Api.todoFields().get()
                .find(fields => {
                    this.fieldsCache = fields;
                    this.fireFieldsCallback(fields);
                });
        }
    }

    public getEditorFields(callback: (fields: TodoField[]) => void) {
        this.getFields(fields => {
            callback(fields.filter(field => field.type == FieldTypes.Editor))
        });
    }

    public getMiniCardFields(callback: (fields: TodoField[]) => void) {
        this.getFields(fields => callback(fields.filter(field => field.type == FieldTypes.MiniCard)));
    }

    // </editor-fold>

    // <editor-fold desc="Reaction types (Queue & Cache)">

    // Cache
    private reactionTypesCache: ReactionTypesTodo[];

    // Queue
    private reactionTypesCallbacks: ((fields: ReactionTypesTodo[]) => void)[] = [];
    private fireReactionTypesCallback(fields: ReactionTypesTodo[]) {
        const callbacks = this.reactionTypesCallbacks;
        this.reactionTypesCallbacks = [];
        for (let callback of callbacks) {
            callback(fields);
        }
    }

    // Entry point
    public getReactionTypes(callback: (fields: ReactionTypesTodo[]) => void) {
        if(this.reactionTypesCache) // Use cache
            callback(this.reactionTypesCache);
        else if(this.reactionTypesCallbacks.length) // Add to queue
            this.reactionTypesCallbacks.push(callback);
        else { // Get from API
            this.reactionTypesCallbacks.push(callback);
            Api.reactionTypesTodos().get().find(items => {
                this.reactionTypesCache = items;
                this.fireReactionTypesCallback(items);
            });
        }
    }

    // </editor-fold>

    // <editor-fold desc="Categories (Queue & Cache)">

    private categoriesCache: TodoCategory[];
    private categoriesCallbacks: ((items: TodoCategory[]) => void)[] = [];
    public getCategories(callback: (items: TodoCategory[]) => void) {
        if (this.categoriesCache) {
            callback(this.categoriesCache);
        } else if (this.categoriesCallbacks.length) {
            this.categoriesCallbacks.push(callback);
        } else {
            this.categoriesCallbacks.push(callback);
            Api.todoCategories().get()
                .orderAsc('name')
                .find(items => {
                    this.categoriesCache = items;
                    this.categoriesCallbacks.forEach(callback => callback(items));
                    this.categoriesCallbacks = [];
                });
        }
    }

    // </editor-fold>

    search(term: string): Observable<Todo[]> {
        const api = Api.todos().get();

        if (term !== '') {
            api
                .search('title', term)
                .search('smart_search', ['user'])
                .orderDesc('created');
        }

        api
            .orderBy('title', this.filterGlobalService.getActiveSettings().activeSortDirection)
            .limit(25);

        return from(new Promise<Todo[]>(resolve => {
            api.find(items => {
                resolve(items);
            });
        }));
    }

}
