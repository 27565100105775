import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {ValidationErrorInterface} from '@app/editor/Validation/ValidationErrorInterface';

@Component({
    selector: 'app-validation-warning-list',
    templateUrl: './validation-warning-list.component.html',
    styleUrls: ['./validation-warning-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ValidationWarningListComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() validatorError!: ValidationErrorInterface;
    @Input() inline!: boolean;

    // Bindings to view
    public showWarning = false;
    public errorDescriptions: string[] = [];

    constructor(
        private cd: ChangeDetectorRef,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.render();
    }

    private render() {
        this.renderValidation();

        this.subscribe(this.validatorError.onIsValidChanged.subscribe(isValid => {
            this.renderValidation();
            this.detectChanges();
        }));
    }

    private renderValidation() {
        this.showWarning = !this.validatorError.isValid();
        this.errorDescriptions = this.validatorError.getErrorDescriptions();
    }

}
