import {ApiFilter} from '@app/core/http/Api/ApiFilter';
import {ApiInclude} from '@app/core/http/Api/ApiInclude';
import {ApiOrdering} from '@app/core/http/Api/ApiOrdering';
import {Observable} from 'rxjs';
import {AppInjector} from '@app/services/app-injector.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {ApiRequest} from "@app/core/http/Api/ApiRequest";

export class BaseApi<T = any> {

    protected uri: string;
    protected topic: string;

    protected method: string;
    protected limitValue: number;
    protected offsetValue: number;
    private apiFilter: ApiFilter;
    private apiInclude: ApiInclude;
    private apiOrdering: ApiOrdering;
    private apiQueryParams: any[] = [];
    protected showProgressBar = true;
    public errorHandler?: (response: any) => void;

    public setErrorHandler(handler: (response: any) => void): BaseApi<T> {
        this.errorHandler = handler;
        return this;
    }

    public addQueryParameter(key: string, value: any): BaseApi {
        this.apiQueryParams.push({key, value});
        return this;
    }

    protected filter(): ApiFilter {
        if (!this.apiFilter) {
            this.apiFilter = new ApiFilter();
        }
        return this.apiFilter;
    }

    protected getInclude(): ApiInclude {
        if (!this.apiInclude) {
            this.apiInclude = new ApiInclude();
        }
        return this.apiInclude;
    }

    protected ordering(): ApiOrdering {
        if (!this.apiOrdering) {
            this.apiOrdering = new ApiOrdering();
        }
        return this.apiOrdering;
    }

    public where(name: string, value: any): BaseApi<T> {
        this.filter().where(name, value);
        return this;
    }

    public whereEquals(name: string, value: any): BaseApi<T> {
        this.filter().whereEquals(name, value);
        return this;
    }

    public whereIn(name: string, value: any[]): BaseApi<T> {
        this.filter().whereIn(name, value);
        return this;
    }

    public whereInArray(name: string, value: any[]): BaseApi<T> {
        this.filter().whereInArray(name, value);
        return this;
    }

    public whereNot(name: string, value: any): BaseApi<T> {
        this.filter().whereNot(name, value);
        return this;
    }

    public whereNotIn(name: string, value: any[]): BaseApi<T> {
        this.filter().whereNotIn(name, value);
        return this;
    }

    public whereGreaterThan(name: string, value: any): BaseApi<T> {
        this.filter().whereGreaterThan(name, value);
        return this;
    }

    public whereGreaterThanOrEqual(name: string, value: any): BaseApi<T> {
        this.filter().whereGreaterThanOrEqual(name, value);
        return this;
    }

    public whereLessThan(name: string, value: any): BaseApi<T> {
        this.filter().whereLessThan(name, value);
        return this;
    }

    public whereLessThanOrEqual(name: string, value: any): BaseApi<T> {
        this.filter().whereLessThanOrEqual(name, value);
        return this;
    }

    public search(name: string, value: any): BaseApi<T> {
        this.filter().search(name, value);
        return this;
    }

    public include(name: string): BaseApi<T> {
        this.getInclude().include(name);
        return this;
    }

    public orderBy(name: string, direction: string): BaseApi<T> {
        this.ordering().orderBy(name, direction);
        return this;
    }

    public orderAsc(name: string): BaseApi<T> {
        this.ordering().orderAsc(name);
        return this;
    }

    public orderDesc(name: string): BaseApi<T> {
        this.ordering().orderDesc(name);
        return this;
    }

    public limit(value: number): BaseApi<T> {
        this.limitValue = value;
        return this;
    }

    public offset(value: number): BaseApi<T> {
        this.offsetValue = value;
        return this;
    }

    protected convertToResource(data: any): T {
        return data;
    }

    public constructModel(data: any): T {
        return this.convertToResource(data);
    }

    protected executeFind(next?: (value: T[]) => void): ApiRequest {
        return this.get(next);
    }

    public count(next?: (value: number) => void): ApiRequest {
        return this.executeCount(next);
    }

    public find(next?: (value: any) => void): ApiRequest {
        return this.executeFind(next);
    }

    public save(data: any, next?: (value: T) => void): ApiRequest {
        return this.executeSave(data, next);
    }

    protected executeClientGet(): Observable<any | T[] | any[]> {
        return this.getClient();
    }

    protected executeSave(data: any, next?: (value: T) => void): ApiRequest {
        switch (this.method) {
            case 'post':
            case 'restore':
                return this.post(data, next);
            case 'put':
                return this.put(data, next);
            case 'patch':
                return this.patch(data, next);
            default:
                console.warn('missing executeSave() method : ', this);
                break;
        }
    }

    private getParams(): HttpParams {
        let params = new HttpParams();

        /*
         * Query Parameters
         */
        if (this.apiQueryParams && this.apiQueryParams.length > 0) {
            this.apiQueryParams.forEach(value => {
                params = params.append(value.key, value.value);
            });
        }

        /*
         * Filtering
         */
        if (this.apiFilter) {
            params = params.append('filter', this.apiFilter.toString());
        }

        /*
         * Include
         */
        if (this.apiInclude) {
            params = params.append('include', this.apiInclude.toString());
        }

        /*
         * Ordering
         */
        if (this.apiOrdering) {
            params = params.append('ordering', this.apiOrdering.toString());
        }

        /*
         * Offset & Limit
         */
        if (this.limitValue != null && this.limitValue >= 0) {
            params = params.append('limit', this.limitValue.toString());
        }
        if (this.offsetValue != null) {
            params = params.append('offset', this.offsetValue.toString());
        }

        return params;
    }

    protected getClient(): Observable<any | T[] | any[]> {
        const httpClient = AppInjector.getInjector().get(HttpClient);
        return httpClient.get<T[]>(`${environment.apiPath}${this.uri}`, this.generateOptions())
            .pipe(
                map((response: any) => {
                    if (response.resources) {
                        return response.resources.map((resource: any) => this.convertToResource(resource));
                    } else if (response.resource) {
                        return [this.convertToResource(response.resource)];
                    } else {
                        return [];
                    }
                })
            );
    }

    private generateOptions(): {params: HttpParams, headers: HttpHeaders} {
        const options: {
            headers: HttpHeaders;
            params: HttpParams;
        } = {
            headers: new HttpHeaders(),
            params: this.getParams(),
        };
        if (!this.showProgressBar) {
            options.headers = options.headers.set('ignoreProgressBar', '');
        }
        return options;
    }

    public getUri(): string {
        return `${environment.apiPath}${this.uri}`;
    }

    private get(next?: (value: T[]) => void): ApiRequest {
        const request = new ApiRequest(
            this.getUri(),
            'get',
            this.generateOptions(),
            null,
            response => {
                if (next) {
                    if (response != null && response.resources) {
                        next(
                            response.resources.map((resource: any) =>
                                this.convertToResource(resource)
                            )
                        );
                    } else if (response != null && response.resource) {
                        next([this.convertToResource(response.resource)]);
                    } else {
                        if (this.errorHandler) {
                            this.errorHandler(response);
                        }
                        next([]);
                    }
                }
            });
        request.execute();
        return request;
    }

    protected executeCount(next?: (value: number) => void): ApiRequest {
        const options = this.generateOptions();
        options.params = options.params.append('count', '1');
        const request = new ApiRequest(
            this.getUri(),
            'get',
            options,
            null,
            response => {
                if (next) {
                    if (response.count) {
                        next(response.count);
                    } else {
                        if (this.errorHandler) {
                            this.errorHandler(response);
                        }
                        next(0);
                    }
                }
            });
        request.execute();
        return request;
    }

    private post(data: any, next?: (value: T) => void): ApiRequest {
        const request = new ApiRequest(
            this.getUri(),
            'post',
            this.generateOptions(),
            data,
            response => {
                if (next) {
                    if (response.resources) {
                        next(
                            response.resources.map((resource: any) =>
                                this.convertToResource(resource)
                            )
                        );
                    } else if (response.resource) {
                        next(this.convertToResource(response.resource));
                    } else {
                        if (this.errorHandler) {
                            this.errorHandler(response);
                        }
                        next(null!);
                    }
                }
            });
        request.execute();
        return request;
    }

    private put(data: any, next?: (value: T) => void): ApiRequest {
        const request = new ApiRequest(
            this.getUri(),
            'put',
            this.generateOptions(),
            data,
            response => {
                if (next) {
                    if (response.resources) {
                        next(
                            response.resources.map((resource: any) =>
                                this.convertToResource(resource)
                            )
                        );
                    } else if (response.resource) {
                        next(this.convertToResource(response.resource));
                    } else {
                        if (this.errorHandler) {
                            this.errorHandler(response);
                        }
                        next(null!);
                    }
                }
            });
        request.execute();
        return request;
    }

    private patch(data: any, next?: (value: T) => void): ApiRequest {
        const request = new ApiRequest(
            this.getUri(),
            'patch',
            this.generateOptions(),
            data,
            response => {
                if (next) {
                    if (response.resources) {
                        next(
                            response.resources.map((resource: any) =>
                                this.convertToResource(resource)
                            )
                        );
                    } else if (response.resource) {
                        next(this.convertToResource(response.resource));
                    } else {
                        if (this.errorHandler) {
                            this.errorHandler(response);
                        }
                        next(null!);
                    }
                }
            });
        request.execute();
        return request;
    }

    protected executeDelete(next?: (value: T) => void): ApiRequest {
        const request = new ApiRequest(
            this.getUri(),
            'delete',
            this.generateOptions(),
            null,
            response => {
                if (next) {
                    if (response.resources) {
                        next(
                            response.resources.map((resource: any) =>
                                this.convertToResource(resource)
                            )
                        );
                    } else if (response.resource) {
                        next(this.convertToResource(response.resource));
                    } else {
                        if (this.errorHandler) {
                            this.errorHandler(response);
                        }
                        next(null!);
                    }
                }
            });
        request.execute();
        return request;
    }

    public setShowProgressBar(visible = true): BaseApi<T> {
        this.showProgressBar = visible;
        return this;
    }

}
