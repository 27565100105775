import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {SnackbarService} from "@app/services/snackbar.service";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor(private router: Router,
                private snackbar: SnackbarService,
    ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
    }

    // Customize the default error handler here if needed
    private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<any>> {
        switch (response.status) {

            case 404:
                console.warn('Not found : ', response);
                this.snackbar.add(`(${response.status}) Ups! Vi kunne ikke finde siden. (${response.message})`, null, {
                    duration: 5000,
                    panelClass: 'alert-info'
                });
                break;

            case 401:
                // Handled by ApiRequest
                // console.warn('Server response 401 :: User logged out : ', response);
                // if (this.router.url != '/unauthorized') {
                //     this.router.navigate(['/unauthorized']);
                // }
                break;

            case 500:
            case 503:
                this.snackbar.add(`(${response.status}) Ups! Der skete en fejl i vores ende. (${response.message})`, 'Luk', {
                    duration: 8000,
                    panelClass: 'alert-danger'
                });
                break;

            default:
                console.warn('unknown status : ', response.status);
                break;
        }

        throw response;
    }

}
