/**
 * Created by ModelParser
 * Date: 24-06-2021.
 * Time: 14:01.
 */
import {CalculatedFieldsMilestoneDefinition} from './definitions/CalculatedFieldsMilestoneDefinition';
import {RxStompService} from "@app/core/rabbitmq/rx-stomp.service";
import {ChangeMessage} from "@app/core/rabbitmq/ChangeMessage";
import {Subscription} from "rxjs";

export class CalculatedFieldsMilestone extends CalculatedFieldsMilestoneDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static CreateChangeSubscription(event: string, rxStompService: RxStompService, handler: (event: ChangeMessage<CalculatedFieldsMilestone>) => void): Subscription {
        return rxStompService
            .watch(event)
            .subscribe(message => handler(CalculatedFieldsMilestone.ParseChangeEvent(JSON.parse(message.body))));
    }

    public static ParseChangeEvent(data: any): ChangeMessage<CalculatedFieldsMilestone> {
        const previous = new CalculatedFieldsMilestone(data.previous);
        const next = new CalculatedFieldsMilestone(data.next);
        return new ChangeMessage<CalculatedFieldsMilestone>(previous, next);
    }

}
