import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {
    EditMilestoneListFilter
} from "@app/editor/quick-editor/editors/generic/milestone-list-editor/EditMilestoneListFilter";
import {Api} from "@app/core/Api";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {Milestone} from "@app/core/models";
import {CardMilestoneConfiguration} from "@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration";

@Component({
    selector: 'app-select-project-milestone-dialog',
    templateUrl: './select-project-milestone-dialog.component.html',
    styleUrls: ['./select-project-milestone-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SelectProjectMilestoneDialogComponent extends BaseModalComponent implements OnInit {

    // Bindings to parent
    @Input() model: EditMilestoneListFilter;
    @Input() primaryClass: string = 'btn-success';

    // Bindings to view
    public items?: CardItem<Milestone>[];
    public isLoading = true;

    constructor(private modal: NgbActiveModal, private cd: ChangeDetectorRef) {
        super(modal);
        this.cdr = cd;
    }

    ngOnInit() {
        Api.milestones().get()
            .include('project')
            .where('project.id', this.model.project.id)
            .find(milestones => {
                this.items = milestones.map(milestone => {
                    return new CardItem<Milestone>(milestone, new CardMilestoneConfiguration());
                }) ?? [];
                this.isLoading = false;
                this.detectChanges();
            });
    }

    public onItemClick(item: CardItem<Milestone>) {
        this.activeModal.close(item.item);
    }

}
