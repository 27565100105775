import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {Projects_RosterDaysSettingValue, Projects_TaskTypesSettingValue} from "@app/core/Api";
import {TaskListRosterTableColumn} from "@app/pages/displays/display-projects/TableColumns/TaskListRosterTableColumn";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {TaskListRosterCell} from "@app/pages/displays/display-projects/Cells/TaskListRosterCell";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {AppInjector} from "@app/services/app-injector.service";
import {TranslateService} from "@ngx-translate/core";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import moment from "moment";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";

export class TaskListRosterColumn extends GenericColumn<TaskListRosterCell> implements ColumnDataFetcherInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TaskFetcher(
            undefined, undefined, undefined,
            AppInjector.getInjector().get(TranslateService).instant('_ui_roster')
        );
        this.dataFetcher.setTaskTypeIds(this.getTaskTypeIds());
    }

    public createTableColumns(): TaskListRosterTableColumn[] {
        return this.getDaysSetting().map(day => {
            const item = new TaskListRosterTableColumn(this, day);
            this.setTableColumnDefaults(item);
            return item;
        }) ?? [];
    }

    public getDaysSetting(): string[] {
        return this.settings.get(ColumnTypeSettings.RosterDays).getObject<Projects_RosterDaysSettingValue>()
            ?.days ?? [];
    }

    public setRosterDate(week: Date) {
        this.dataFetcher.setPeriod(
            moment(week).startOf('isoWeek').toDate(),
            moment(week).endOf('isoWeek').toDate()
        );
        this.getTableColumns<TaskListRosterTableColumn>().forEach(tableColumn => tableColumn.setDate(week));
    }

    public createCell(row: ProjectsDisplayRow): TaskListRosterCell {
        const cell = new TaskListRosterCell(row, this);

        cell.getAllTaskListConfigurations().forEach(taskListConfiguration => {
            this.dataFetcher.addRequest(new TaskFetchRequest(taskListConfiguration));
        });

        return cell;
    }

    public getTaskTypeIds(): number[] {
        return this.settings.get(ColumnTypeSettings.TaskTypes)
            ?.getObject<Projects_TaskTypesSettingValue>()
            ?.taskTypeIds ?? [];
    }

    public implementsCSVExport = false;

    /*public toCSVColumn(result: (column: CSVColumn) => void): void {
        const translateService = AppInjector.getInjector().get(TranslateService);
        const column = CSVColumn.CreatePrimary(
            this,
            this.getDaysSetting().map(day => translateService.instant(day))
        );
        result(column);
    }*/

}
