import {BlockedPeriod} from "@app/core/models";
import {EventEmitter} from "@angular/core";
import Helpers from "@app/core/helpers";
import moment from "moment";

export class BlockedPeriodItem {

    public blockedPeriod: BlockedPeriod;
    private _disabled = false;
    public start = new Date();
    public end = new Date();

    public changeEmitter = new EventEmitter();

    constructor(blockedPeriod?: BlockedPeriod) {
        this.blockedPeriod = blockedPeriod || new BlockedPeriod({
            start: Helpers.serverDate(moment().startOf('day').toDate()),
            end: Helpers.serverDate(moment().endOf('week').toDate()),
        });
        this.start = moment(this.blockedPeriod.start).toDate();
        this.end = moment(this.blockedPeriod.end).toDate();
    }

    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = value;
        this.changeEmitter.emit(this);
    }
}
