import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {I18nService} from '@app/core';
import {User} from '@app/core/models/User';
import {FieldTypes, System} from '@app/constants';
import {UserField} from '@app/core/models/UserField';
import {Api} from '@app/core/Api';
import {AppInjector} from '@app/services/app-injector.service';
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";
import {Department, Display} from '@app/core/models';
import Helpers from '@app/core/helpers';
import {DisplayService} from '@app/services/display.service';
import {AuthService} from "../../services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    private initialized: boolean = false;

    private _currentUser: BehaviorSubject<User>;
    public currentUser$: Observable<User>;

    private dataStore: {
        currentUser: User
    };

    constructor(private authService: AuthService) {
        this.dataStore = {
            currentUser: null
        };

        this._currentUser = <BehaviorSubject<User>>new BehaviorSubject(null);
        this.currentUser$ = this._currentUser.asObservable();

        this.authService.onNewAccessTokenReceived.subscribe(() => this.init());
    }

    public init() {
        if (!this.initialized) {
            // console.log('users.service.ts : init() : ', this.initialized);
            this.me();
            this.initialized = true;
        }
    }

    public get user(): User {
        return this.dataStore.currentUser;
    }

    public me() {
        const api = Api.users().meGet();
        api.include('department')
            .include('role');
        api.find(users => {
            this.dataStore.currentUser = users[0];
            this._currentUser.next(Object.assign({}, this.dataStore).currentUser);
            AppInjector.getInjector().get(I18nService).language = I18nService.languageIdToLanguageCode(users[0].language_id);
        });
    }

    getUserDepartment(callback: ((department: Department) => void)) {
        AppInjector.getInjector().get(DisplayService).getDisplaysWithoutSettings(displaysResponse => {
            let displays: Display[] = displaysResponse.filter(display => display.routerPath);
            let selectedDepartment: Department;
            if (this.user?.departments) { // Vælg den første afdeling, at brugeren er tilknyttet
                displays.forEach(display => {
                    if (display.departments_displays) {
                        display.departments_displays.find(dd => {
                            if (Helpers.itemExists(this.user.departments, dd.department_id)) {
                                selectedDepartment = this.user.departments.find(value => value.id == dd.department_id);
                                return true;
                            }
                        });
                    }
                });
            }
            callback(selectedDepartment);
        });
    }

    // <editor-fold desc="Users by department">

    private byDepartmentDataCache: Map<number, ApiDataCache<User>> = new Map();

    public getUsersByDepartment(departmentId: number, callback: (items: User[]) => void) {
        if (!this.byDepartmentDataCache.has(departmentId)) {
            this.byDepartmentDataCache.set(departmentId, new ApiDataCache<User>(
                `${System.UsersByDepartment}-${departmentId}`,
                2 * 60 * 60, // 2 hours
                Api.users().get()
                    .include('role')
                    .where('hidden', false)
                    .where('department.id', departmentId)
                    .orderAsc('name')
            ));
        }
        this.byDepartmentDataCache.get(departmentId).get(callback);
    }

    // </editor-fold>

    // <editor-fold desc="User cache">

    private dataCache = new ApiDataCache<User>(
        System.Users,
        2 * 60 * 60, // 2 hours
        Api.users()
            .get()
            .include('department')
    );

    public getUsers(callback: (items: User[]) => void) {
        this.dataCache.get(callback);
    }

    public getUsersById(userId: number, callback: (user: User) => void) {
        this.getUsers((users) => {
            callback(users.find(u => u.id == userId));
        })
    }

    // </editor-fold>

    // <editor-fold desc="Fields (Queue & Cache)">

    private fieldsDataCache = new ApiDataCache<UserField>(
        System.UserFields,
        2 * 60 * 60, // 2 hours
        Api.userFields().get()
    );

    // Entry point
    private getFields(callback: (fields: UserField[]) => void) {
        this.fieldsDataCache.get(callback);
    }

    public getEditorFields(callback: (fields: UserField[]) => void) {
        this.getFields(fields => {
            callback(fields.filter(field => field.type == FieldTypes.Editor));
        });
    }

    public getMiniCardFields(callback: (fields: UserField[]) => void) {
        this.getFields(fields => callback(fields.filter(field => field.type == FieldTypes.MiniCard)));
    }

    // </editor-fold>

}
