import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges, OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {ProjectStatus} from '@app/core/models/ProjectStatus';
import {ProjectStatusChangeEvent} from '@app/shared/_ui/columns/project-statuses/ProjectStatusChangeEvent';
import {ProjectStatusesRow} from '@app/shared/_ui/columns/project-statuses/ProjectStatusesRow';
import {Project} from '@app/core/models/Project';
import {ProjectsService} from '@app/services/projects.service';
import {ProjectType} from "@app/core/models";
import {Subscription} from "rxjs";
import {RxStompService} from "@app/core/rabbitmq/rx-stomp.service";
import {ChangeMessage} from "@app/core/rabbitmq/ChangeMessage";

@Component({
    selector: 'app-project-statuses',
    templateUrl: './project-statuses.component.html',
    styleUrls: ['./project-statuses.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectStatusesComponent extends BaseDisplayComponent implements OnInit, OnChanges, OnDestroy {

    // Input
    @Input() project: Project;
    @Input() visibleStatusTypes?: number[];

    // Bindings to view
    public items: ProjectStatusesRow[];

    // Data
    private projectType?: ProjectType;
    private changeEventSubscription?: Subscription;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private rxStompService: RxStompService,
                private projectsService: ProjectsService) {
        super();
        this.cdr = this.changeDetectorRef;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setup();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.changeEventSubscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.item) {
            this.setup();
        }
    }

    private setup() {
        if (this.project == null) {
            return;
        }

        this.projectsService.getProjectType(this.project.project_type_id, projectType => {
            this.projectType = projectType;
            this.render();

            this.changeEventSubscription?.unsubscribe();
            this.changeEventSubscription = this.rxStompService
                .watch(this.project.getEventName('status'))
                .subscribe(message => {
                    this.project.populate(ChangeMessage.ParseRabbitMQMessage(message.body).next, true);
                    this.render();
                }
            );
        });
    }

    private render() {
        if (this.project == null) {
            return;
        }

        // Prepare view items
        const items: ProjectStatusesRow[] = [];
        const mainStatus = this.project.main_status;

        // Add empty project statuses if not already set on project AND add extra data
        this.projectType.project_status_types_project_types
            ?.filter(projectStatusTypesProjectType => {
                return this.visibleStatusTypes === undefined || this.visibleStatusTypes.includes(projectStatusTypesProjectType.project_status_type_id);
            })
            ?.forEach(projectStatusTypesProjectType => {
                const projectStatusType = projectStatusTypesProjectType.project_status_type;

                if (projectStatusTypesProjectType.visible) {
                    let projectStatus = this.project.findProjectStatusByType(projectStatusType.id);

                    if (mainStatus?.project_status_type_id == projectStatusType.id) {
                        projectStatus = mainStatus;
                    } else if (!projectStatus) {
                        projectStatus = new ProjectStatus();
                        projectStatus.project_id = this.project.id;
                        projectStatus.project_status_type_id = projectStatusType.id;
                        projectStatus.status_id = projectStatusType.statuses[0].id;
                    }

                    projectStatus.project_status_type = projectStatusType;
                    items.push(new ProjectStatusesRow(projectStatus, (event => this.onProjectStatusChange(event))));
                }

            });

        this.items = items;
        this.detectChanges();
    }

    private onProjectStatusChange(event: ProjectStatusChangeEvent) {
        if (this.project.id) {
            this.project.setStatusByType(event.projectStatusTypeId, event.statusId);
        }
    }

}
