import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Task} from "@app/core/models";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";

@Component({
    selector: 'app-task-editor-footer',
    templateUrl: './task-editor-footer-component.html',
    styleUrls: ['./task-editor-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TaskEditorFooterComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item!: Task;
    @Input() validator!: ValidationErrorInterface;
    @Output() close = new EventEmitter();
    @Output() delete = new EventEmitter();

    // Bindings to view
    public showDeleteBtn = false;
    public isCloseBtnDisabled = false;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.render();
    }

    private render() {
        this.showDeleteBtn = this.item.exists();

        this.subscribe(this.validator.onIsValidChanged.subscribe(isValid => {
            this.renderValidation();
            this.detectChanges();
        }));
        this.renderValidation();
    }

    private renderValidation() {
        this.isCloseBtnDisabled = !this.validator.isValid();
    }

    // <editor-fold desc="View actions">

    public onDeleteBtnClicked() {
        this.delete.emit();
    }

    public onCloseBtnClicked() {
        this.close.emit();
    }

    // </editor-fold>

}
