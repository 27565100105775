import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {Task} from '@app/core/models/Task';
import {BaseDisplayComponent} from '../../../_system/base-display/base-display.component';
import {TaskListConfiguration} from '@app/shared/_ui/lists/task-list/TaskListConfiguration';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import Helpers from '@app/core/helpers';
import {TaskUserTypes} from '@app/constants';
import {Subscription} from 'rxjs';
import {CSVListBinding} from '@app/export/csv/CSVListBinding';
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {TaskFetcher} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";
import {Api} from "@app/core/Api";

@Component({
    selector: 'app-task-list',
    templateUrl: './task-list.component.html',
    styleUrls: ['./task-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TaskListComponent extends BaseDisplayComponent implements OnInit, OnChanges, OnDestroy {

    // Bindings to parent
    @Input() listClass: string = 'grid-3';
    @Input() configuration = new TaskListConfiguration();
    @Input() minimizeEvent: EventEmitter<any>;
    @Input() loadAllEvent: EventEmitter<any>;
    @Input() reloadEvent: EventEmitter<any>;
    @Input() scrollContainer: HTMLElement;
    @Input() csvListBinding: CSVListBinding;
    @Output() dataSetChanged = new EventEmitter<CardItem<Task>[]>();

    // Bindings to view
    public items: CardItem<Task>[];
    public offset = 0;
    public itemCount = 0;

    // Data
    private itemsMap: Map<number, CardItem<Task>>;
    private reloadEventSubscription?: Subscription;
    private loadAllEventSubscription?: Subscription;

    constructor(private ngZone: NgZone,
                private cd: ChangeDetectorRef,
                private filterGlobalService: FilterGlobalService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        if (this.minimizeEvent) {
            this.minimizeEvent.subscribe(() => {
                this.items.splice(this.configuration.getLimit());
                this.itemsMap.clear();
                this.items.forEach(item => this.itemsMap.set(item.item.id, item));
                this.offset = 0;
                this.items = [...this.items];
                this.detectChanges();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['reloadEvent']) {
            if (this.reloadEvent) {
                this.reloadEventSubscription?.unsubscribe();
                this.reloadEventSubscription = this.reloadEvent.subscribe(() => {
                    if (this.configuration.hasSmartLimit()) {
                        return;
                    }
                    this.loadData();
                });
            }
        }
        if (changes['loadAllEvent']) {
            if (this.loadAllEvent) {
                this.loadAllEventSubscription?.unsubscribe();
                this.loadAllEventSubscription = this.loadAllEvent.subscribe(() => {
                    this.loadAll();
                });
            }
        }
        if (changes['configuration'] != undefined && this.configuration) {
            changes['configuration'].previousValue?.pushUnsubscribe();

            const onAddItemsListener = (items: CardItem<Task>[]) => {
                // Reset
                this.offset = 0;
                this.itemCount = 0;
                this.itemsMap = new Map();

                // Add items
                this.items = items;
                items.forEach(item => this.itemsMap.set(item.item.id, item));
                this.configuration.setLastItemCount(items.length);

                this.isLoading = false;
                this.detectChanges();
                this.dataSetChanged.emit(this.items);
            };
            const onAddItemListener = (item: CardItem<Task>, index?: number) => {
                if (!this.itemsMap?.has(item.item.id)) { // Add if not found
                    if (!this.items) {
                        this.items = [];
                    }
                    if (index !== undefined) {
                        this.items.splice(index, 0, item);
                    } else {
                        this.items.push(item);
                    }
                    this.itemCount++;
                } else { // Replace existing card
                    this.items[this.items.indexOf(this.itemsMap.get(item.item.id))] = item;
                }
                if (!this.itemsMap) {
                    this.itemsMap = new Map();
                }
                this.itemsMap.set(item.item.id, item);
                this.configuration.setLastItemCount(this.items.length);
                this.detectChanges();
                this.dataSetChanged.emit(this.items);
            };
            const onRemoveItemListener = (item: CardItem<Task>) => {
                this.removeItem(item.item);
                this.detectChanges();
            };
            const onCountListener = (count: number) => {
                this.itemCount = count;
                this.detectChanges();
            };
            this.configuration.addDataListeners(onAddItemsListener, onAddItemListener, onRemoveItemListener, onCountListener);

            this.configuration.pushUnsubscribe();
            this.configuration.pushSubscribe();

            if (this.configuration.getDataSource()) {
                this.apiRequest?.cancel();
            } else if (this.configuration.hasSmartLimit()) { // Wait for SmartLimit
                this.subscribe(this.configuration.onLimitSetEvent.subscribe((limit: number) => this.loadData()));
            } else {
                this.loadData();
            }
        }

        // CSV Export
        if (this.csvListBinding) {
            this.csvListBinding.setExportFunction(options => {
                return this.items.map(item => this.configuration.csvExport(item, options));
            });
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.configuration?.pushUnsubscribe();
    }

    // <editor-fold desc="View Actions">

    loadMore(limit?: number) {
        if (!limit) {
            limit = this.configuration.getLimit();
        }
        if (this.configuration.getDataSource()) {
            this.configuration.getDataSource().requestSlice(0, this.offset + limit);
        } else {
            this.loadItems(this.offset + limit);
        }
    }

    loadLess() {
        this.offset = 0;
        this.items = this.items.slice(0, this.configuration.getLimit());
        this.dataSetChanged.emit(this.items);
        this.detectChanges();
    }

    loadAll() {
        if (this.configuration.getDataSource()) {
            this.configuration.getDataSource().requestSlice(0, this.itemCount);
        } else {
            this.loadItems(this.offset + this.configuration.getLimit(), false, true);
        }
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private loadData() {
        this.items = [];
        this.itemsMap = new Map();
        this.itemCount = 0;

        if (this.configuration?.getDataSource()) {
            const fetcher = new TaskFetcher();
            fetcher.addRequest(this.configuration.getDataSource());
            fetcher.execute();
        } else {
            this.loadItems(0, true);
        }
    }

    private apiRequest?: ApiRequest;

    private loadItems(offset: number, doCount: boolean = false, loadAll: boolean = false) {
        console.warn('TaskList loadItems - still in use?');
        // !!!
        // Bruger også TaskFetcher til at loade data. Konfiguration skal sættes op dobbelt!
        // !!!
        if (!this.configuration.isEnabled()) {
            return;
        }
        this.apiRequest?.cancel();
        this.isLoading = true;

        let api = Api.tasks().get();

        // Always required do to default configuration (taskType.default_task_estimate_type_id) shown in medium card
        api.include('task_estimate');

        if (this.configuration.getShowProjectMiniCard()) {
            api.include('project_micro');
        }
        if (this.configuration.getShowMilestoneMiniCard()) {
            api.include('milestone');
        }

        if (this.configuration.getTaskType()) {
            api.where('task_type_id', this.configuration.getTaskType().id);
        }
        if (this.configuration.getTaskTypeIds()) {
            api.whereIn('task_type_id', this.configuration.getTaskTypeIds());
        }
        if (this.configuration.getAvoidTaskTypeIds()) {
            api.whereNotIn('task_type_id', this.configuration.getAvoidTaskTypeIds());
        }
        if (this.configuration.getMilestone()) {
            api
                .include('milestone')
                .where('milestone.id', this.configuration.getMilestone().id);
        }
        if (this.configuration.getProject()) {
            api
                .include('project')
                .where('project.id', this.configuration.getProject().id);
        }
        if (this.configuration.getUser()) {
            api.where('tasks_user.user_id', this.configuration.getUser().id);
            if (this.configuration.getPlannedBetween()) {
                api.where('tasks_user.task_user_type_id', TaskUserTypes.Participant)
                    .whereGreaterThanOrEqual('tasks_user.deadline.date', Helpers.serverDate(this.configuration.getPlannedBetween()[0]))
                    .whereLessThanOrEqual('tasks_user.deadline.date', Helpers.serverDate(this.configuration.getPlannedBetween()[1]));
            }
            if (this.configuration.isSoftDeadline() !== null) {
                api
                    .where('tasks_user.task_user_type_id', TaskUserTypes.Participant)
                    .whereGreaterThan('tasks_user.deadline_id', 0)
                    .where('tasks_user.deadline.is_soft', this.configuration.isSoftDeadline() ? 1 : 0);
            }
        }
        if (this.configuration.getArchived() != null) {
            if (this.configuration.getArchived() == true) {
                api.whereGreaterThan('archived_id', 0);
            } else {
                api.where('archived_id', 0);
            }
        }
        if (this.configuration.getNotArchivedForUserId()) {
            api.where('not_archived_for_user_id', this.configuration.getNotArchivedForUserId());
        }
        if (this.configuration.getOrArchivedSince()) {
            api.where('or_archived_since', Helpers.serverDate(this.configuration.getOrArchivedSince()));
        }
        if (this.configuration.getAvoidIds()) {
            api.whereNotIn('id', this.configuration.getAvoidIds());
        }
        if (this.configuration.getDeadlineBetween()) {
            api.whereGreaterThanOrEqual('tasks_deadline.deadline.date', Helpers.serverDate(this.configuration.getDeadlineBetween()[0]))
                .whereLessThanOrEqual('tasks_deadline.deadline.date', Helpers.serverDate(this.configuration.getDeadlineBetween()[1]));
        }
        if (this.configuration.getArchivedBetween()) {
            api.include('archived')
                .whereGreaterThanOrEqual('archived.created', Helpers.serverDate(this.configuration.getArchivedBetween()[0]))
                .whereLessThanOrEqual('archived.created', Helpers.serverDate(this.configuration.getArchivedBetween()[1]));
        }
        if (this.configuration.getCreatedBetween()) {
            api.whereGreaterThanOrEqual('created', Helpers.serverDate(this.configuration.getCreatedBetween()[0]))
                .whereLessThanOrEqual('created', Helpers.serverDate(this.configuration.getCreatedBetween()[1]));
        }
        if (this.configuration.getPlannedBetween()) {
            api.where('tasks_user.task_user_type_id', TaskUserTypes.Participant)
                .whereGreaterThanOrEqual('tasks_user.deadline.date', Helpers.serverDate(this.configuration.getPlannedBetween()[0]))
                .whereLessThanOrEqual('tasks_user.deadline.date', Helpers.serverDate(this.configuration.getPlannedBetween()[1]));
        }
        if (this.configuration.getDepartment()) {
            api.where('department.id', this.configuration.getDepartment().id);
        }
        if (this.configuration.getUseGlobalFilter()) {
            let filter = this.filterGlobalService.getActiveSettings();
            if (this.configuration.getUseGlobalSearchFilter() && filter.search?.length > 0) api.search('title', filter.search);
            if (filter.isHandUp) api.whereGreaterThan('num_hand_ups', 0);
            if (filter.isStarred) api.whereGreaterThan('num_stars', 0);
            if (filter.activeStatuses.length < 4 && filter.activeStatuses.length > 0) {
                api.whereIn('main_status.status_id', filter.activeStatuses);
            }
            if (filter.activeReactionFilters?.length > 0) {
                filter.activeReactionFilters.forEach(reactionFilter => {
                    api.whereIn('reaction_filters', [reactionFilter.reaction_type_id, reactionFilter.value]);
                });
            }
            if (filter.activeCategories?.length) {
                api.whereIn('category.id', filter.activeCategories?.map(category => category.id));
            }
        }
        if (this.configuration.getUserTypeId()) {
            api.where('tasks_user.task_user_type_id', this.configuration.getUserTypeId());
        }
        if (this.configuration.getAcceptanceStatus()) {
            api.whereIn('tasks_user.acceptance_status', this.configuration.getAcceptanceStatus());
        }
        if (this.configuration.getPlanningDeadlineId() != null) {
            api.where('tasks_user.deadline_id', this.configuration.getPlanningDeadlineId());
        }
        if (this.configuration.getSearch() && this.configuration.getSearch().length > 0) {
            api.search('title', this.configuration.getSearch());
        }
        if (this.configuration.getCanCopy() !== null) {
            api.where('can_copy', this.configuration.getCanCopy() ? 1 : 0);
        }
        if (this.configuration.getHasMilestone() !== null) {
            if (this.configuration.getHasMilestone() === true) {
                api.whereGreaterThan('milestone.id', 0);
            } else if (this.configuration.getHasMilestone() === false) {
                api.where('milestone.id', null);
            }
        }
        if (this.configuration.getHasNotes() !== null) {
            api.where('has_delivery_description', this.configuration.getHasNotes());
        }
        if (this.configuration.getTaskDeadlineTypes()) {
            api.whereIn('tasks_deadline.task_deadline_type_id', this.configuration.getTaskDeadlineTypes());
        }
        if (this.configuration.getHasNonDeadline() !== null && this.configuration.getHasNonDeadline() !== undefined) {
            if (this.configuration.getHasNonDeadline()) {
                api.where('tasks_deadline.deadline_id', null);
            } else {
                api.whereNot('tasks_deadline.deadline_id', null);
            }
        }
        if (this.configuration.getOpen() !== null) {
            api.where('open', this.configuration.getOpen() ? 1 : 0);
        }
        if (this.configuration.getMainStatusStatusIds()) {
            api.whereIn('main_status.status_id', this.configuration.getMainStatusStatusIds());
        }
        if (this.configuration.getSort_CategoryTypeId()) {
            api.where('category.category_type_id', this.configuration.getSort_CategoryTypeId());
        }
        if (this.configuration.getSort_DeadlineTypeId()) {
            api.where('tasks_deadline.task_deadline_type_id', this.configuration.getSort_DeadlineTypeId());
        }

        this.configuration.getOrderBy().forEach(orderBy => api.orderBy(orderBy[0], orderBy[1]));
        api
            .offset(offset)
            .limit(loadAll ? 100 : this.configuration.getLimit());

        this.apiRequest = api
            .find(tasks => {
                this.offset = offset;
                tasks.forEach(task => this.addItem(task));
                this.items = [...this.items];
                this.dataSetChanged.emit(this.items);
                this.isLoading = false;
                this.detectChanges();
            });

        if (doCount) {
            api.count(count => {
                this.itemCount = count;
                this.detectChanges();
            });
        }
    }

    private addItem(item: Task) {
        const cardItem = this.configuration.createCard(item);
        this.items.push(cardItem);
        this.itemsMap.set(item.id, cardItem);
        this.configuration.setLastItemCount(this.items.length);
    }

    private removeItem(item: Task) {
        if (this.itemsMap?.has(item.id)) {
            this.items.splice(this.items.indexOf(this.itemsMap.get(item.id)), 1);
            this.itemsMap.delete(item.id);
            this.itemCount--;
            this.dataSetChanged.emit(this.items);
            this.configuration.setLastItemCount(this.items.length);
            this.offset = Math.max(0, this.offset - 1);
            if (this.itemCount > this.items.length) {
                this.loadMore(this.items.length + 1);
            }
        }
    }

    // </editor-fold>

}
