import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseFormControlComponent} from '@app/shared/_forms/base-form-control/base-form-control.component';
import {StatusRule} from '@app/core/models/StatusRule';

@Component({
    selector: 'app-status-rule-appender',
    templateUrl: './status-rule-appender.component.html',
    styleUrls: ['./status-rule-appender.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StatusRuleAppenderComponent),
            multi: true, // multi-casting
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => StatusRuleAppenderComponent),
            multi: true,
        }
    ],
    standalone: false
})
export class StatusRuleAppenderComponent extends BaseFormControlComponent implements OnInit {

    @Input() _internalValue: StatusRule[] = [];
    @Input() readonly = false;
    constructor() {
        super();
    }

    ngOnInit() {
        // console.log('status_rules : ', this.internalValue);
    }



    appendRule(rule: UntypedFormGroup) {
        // let rule = ProjectsUser.createDefault(projectUserTypeForm.value.id);
        // status_rules.controls.projects_users.value.push(projectsUser);
    }

    createRule() {
        let rule = StatusRule.CreateDefault();
        if(!this._internalValue){
            this.internalValue = [];
        }
        this.internalValue.push(rule);
        // console.log('createRule : ', rule, this.internalValue);
        // status_rules.controls.projects_users.value.push(projectsUser);
    }

    removeItem($event: StatusRule) {
        if(this.internalValue) {
            this.internalValue = this.internalValue.filter((value:StatusRule) => value.created !== $event.created && value.id !== $event.id);
        }
    }
}
