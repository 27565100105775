import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Task, TaskType, StatusRule} from "@app/core/models";
import {Field} from "@app/editor/task-editor-loader/task-editor.service";
import {Fields} from "@app/editor/task-editor-loader/Fields";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {ValidatorCollection} from "@app/editor/Validation/ValidatorCollection";
import {
    EditEstimateListConfiguration
} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EditEstimateListConfiguration";
import {EditLinkConfiguration} from "@app/editor/quick-editor/editors/generic/link-editor/EditLinkConfiguration";
import {EditTextConfiguration} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextConfiguration";
import {
    EditReactionListConfiguration
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionListConfiguration";
import {
    EditCategoryPickerListConfiguration
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerListConfiguration";
import {TranslateService} from "@ngx-translate/core";
import {EstimateItem} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EstimateItem";
import {
    CategoryPickerItem
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/CategoryPickerItem";
import {
    EditStatusListConfiguration
} from "@app/editor/quick-editor/editors/generic/status-list-editor/EditStatusListConfiguration";
import {StatusItem} from "@app/editor/quick-editor/editors/generic/status-list-editor/StatusItem";
import {FieldItem} from "@app/editor/base-editor-v3/helpers/FieldItem";
import {EditTextValidator} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextValidator";
import {SnackbarService} from "@app/services/snackbar.service";
import {TaskStatusTypes, TasksUserAcceptanceStatus} from "@app/constants";
import {EditNumberConfiguration} from "@app/editor/quick-editor/editors/generic/number-editor/EditNumberConfiguration";
import {EditNumberValidator} from "@app/editor/quick-editor/editors/generic/number-editor/EditNumberValidator";
import {
    EditCategoryPickerValidator
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerValidator";

@Component({
    selector: 'app-task-editor-tabs-basic',
    templateUrl: './task-editor-tabs-basic-component.html',
    styleUrls: ['./task-editor-tabs-basic.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TaskEditorTabsBasicComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Task;
    @Input() taskType: TaskType;
    @Input() fields: Map<number, Field>;
    @Output() validatorChange = new EventEmitter<ValidationErrorInterface>();

    // Bindings to view
    public isReady = false;

    // Bindings to view: Acceptance
    public showAcceptanceButtons = false;

    // Bindings to view: Estimates
    public estimateListFieldItem: FieldItem;
    public estimateListEditorConfiguration: EditEstimateListConfiguration;

    // Bindings to view: Reference / Link
    public linkFieldItem: FieldItem;
    public linkEditorConfiguration: EditLinkConfiguration;

    // Bindings to view: Status
    public statusListFieldItem: FieldItem;
    public statusListEditorConfiguration: EditStatusListConfiguration;

    // Bindings to view: Status rules
    public statusRulesFieldItem: FieldItem;
    public statusRules: StatusRule[];

    // Bindings to view: Delivery Description
    public deliveryDescriptionTextEditorField: FieldItem;
    public deliveryDescriptionTextEditorConfiguration: EditTextConfiguration;

    // Bindings to view: Success Criteria
    public successCriteriaTextEditorField: FieldItem;
    public successCriteriaTextEditorConfiguration: EditTextConfiguration;

    // Bindings to view: Obs
    public obsTextEditorField: FieldItem;
    public obsTextEditorConfiguration: EditTextConfiguration;

    public caseValuesNumberField: FieldItem;
    public casesPlannedNumberEditorConfiguration: EditNumberConfiguration;
    public casesReachedNumberEditorConfiguration: EditNumberConfiguration;

    // Bindings to view: Emoji
    public showEmojiReactionListEditor: boolean;
    public emojiReactionListEditorConfiguration: EditReactionListConfiguration;

    // Bindings to view: Categories
    public showCategoryPickerListEditor: boolean;
    public categoryPickerListEditorConfiguration: EditCategoryPickerListConfiguration;

    // Data
    private validator: ValidationErrorInterface;

    constructor(
        private cd: ChangeDetectorRef,
        private translateService: TranslateService,
        private snackbar: SnackbarService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.setupEditors();
        this.setupValidator();
        this.isReady = true;
    }

    private setupEditors() {
        this.showAcceptanceButtons = this.item.acceptance_status == TasksUserAcceptanceStatus.New;

        this.estimateListFieldItem = new FieldItem(
            true,
            this.fields.get(Fields.CalculatedFields)?.index ?? 0
        );
        this.estimateListEditorConfiguration = new EditEstimateListConfiguration(
            this.translateService.instant('_task_estimates'),
            this.taskType.task_estimate_types_task_types
                ?.filter(taskEstimateTypesTaskType => {
                    return taskEstimateTypesTaskType.visible && taskEstimateTypesTaskType.task_estimate_type?.exists();
                })
                ?.map(taskEstimateTypesTaskType => {
                    return new EstimateItem(
                        taskEstimateTypesTaskType.task_estimate_type.name,
                        taskEstimateTypesTaskType.task_estimate_type_id,
                    );
                }) ?? []
        );

        this.linkFieldItem = this.getFieldItem(Fields.AttachTo.Reference);
        this.linkEditorConfiguration = new EditLinkConfiguration(
            this.fields.get(Fields.AttachTo.Reference)?.smartName ?? '',
            'standard',
        );

        this.statusListEditorConfiguration = new EditStatusListConfiguration(
            this.translateService.instant('_ui_status_types'),
            this.taskType.task_status_types_task_types
                ?.filter(taskStatusTypesTaskType => {
                    return taskStatusTypesTaskType.visible && taskStatusTypesTaskType.task_status_type?.exists();
                })
                // Avoid Main Status
                ?.filter(taskStatusTypesTaskType => taskStatusTypesTaskType.task_status_type_id !== TaskStatusTypes.Normal)
                ?.map(taskStatusTypesTaskType => {
                    return new StatusItem(
                        taskStatusTypesTaskType.task_status_type.name,
                        taskStatusTypesTaskType.task_status_type_id,
                        taskStatusTypesTaskType.task_status_type.statuses?.map(status => status.id!) ?? []
                    );
                }) ?? []
        );
        this.statusListFieldItem = new FieldItem(
            this.statusListEditorConfiguration.types.length > 0,
            this.fields.get(Fields.BasicInformation.StatusRules)?.index ?? 0
        );

        this.statusRulesFieldItem = this.getFieldItem(Fields.BasicInformation.StatusRules);
        this.statusRules = this.taskType.task_types_status_rules
            ?.map(value => {
                const defaultDeadlineType = this.taskType.task_deadline_types?.find(dt => dt.id === value.task_deadline_type_id);
                value.status_rule = new StatusRule(value.status_rule);
                if (defaultDeadlineType) {
                    value.status_rule.defaultDeadlineName = defaultDeadlineType.getSmartName();
                }
                return value.status_rule;
            }) ?? [];

        this.deliveryDescriptionTextEditorField = this.getFieldItem(Fields.BasicInformation.Notes1);
        this.deliveryDescriptionTextEditorConfiguration = new EditTextConfiguration(
            'delivery_description',
            'multi',
            this.fields.get(Fields.BasicInformation.Notes1)?.smartName ?? '',
            'rich',
            new EditTextValidator(
                this.fields.get(Fields.BasicInformation.Notes1)?.required ?? false,
            ),
        );

        this.successCriteriaTextEditorField = this.getFieldItem(Fields.BasicInformation.Notes2);
        this.successCriteriaTextEditorConfiguration = new EditTextConfiguration(
            'success_criteria',
            'multi',
            this.fields.get(Fields.BasicInformation.Notes2)?.smartName ?? '',
            'rich',
            new EditTextValidator(
                this.fields.get(Fields.BasicInformation.Notes2)?.required ?? false,
            ),
        );

        this.obsTextEditorField = this.getFieldItem(Fields.AttachTo.Obs);
        this.obsTextEditorConfiguration = new EditTextConfiguration(
            'obs',
            'multi',
            this.fields.get(Fields.AttachTo.Obs)?.smartName ?? '',
            'rich',
            new EditTextValidator(
                this.fields.get(Fields.AttachTo.Obs)?.required ?? false,
            ),
        );

        this.caseValuesNumberField = this.getFieldItem(Fields.BasicInformation.CaseValues);
        this.casesPlannedNumberEditorConfiguration = new EditNumberConfiguration(
            'cases_planned',
            this.translateService.instant('_ui_case_planned'),
            new EditNumberValidator(false),
        );
        this.casesReachedNumberEditorConfiguration = new EditNumberConfiguration(
            'cases_reached',
            this.translateService.instant('_ui_case_reached'),
            new EditNumberValidator(false),
        );

        this.showEmojiReactionListEditor = !(this.fields.get(Fields.Categories.Tab)?.visible ?? false);
        this.emojiReactionListEditorConfiguration = new EditReactionListConfiguration(
            false,
            false,
            true,
            true,
            false
        );

        this.showCategoryPickerListEditor = !(this.fields.get(Fields.Categories.Tab)?.visible ?? false);
        this.categoryPickerListEditorConfiguration = new EditCategoryPickerListConfiguration(
            '',
            this.taskType.category_types_task_types
                ?.filter(categoryTypesTaskType => categoryTypesTaskType.visible && categoryTypesTaskType.category_type?.exists())
                ?.map(categoryTypesTaskType => new CategoryPickerItem(
                    categoryTypesTaskType.category_type.name,
                    categoryTypesTaskType.category_type,
                    true,
                    new EditCategoryPickerValidator(categoryTypesTaskType.required)
                )) ?? []
        );
    }

    private setupValidator() {
        this.validator = new ValidatorCollection([
            this.deliveryDescriptionTextEditorConfiguration.validator,
            this.successCriteriaTextEditorConfiguration.validator,
            this.obsTextEditorConfiguration.validator,
            ...this.categoryPickerListEditorConfiguration.types.map(type => type.validator),
        ]);
        this.validatorChange.emit(this.validator);
    }

    private getFieldItem(fieldId: number): FieldItem {
        return new FieldItem(
            this.fields.get(fieldId)?.visible ?? false,
            this.fields.get(fieldId)?.index ?? 0
        );
    }

    // <editor-fold desc="View actions">

    public onAcceptBtnClicked() {
        this.item.accept(() => {
            this.snackbar.add(`${this.item.title} GODKENDT...`);
        });
        this.showAcceptanceButtons = false;
    }

    public onRejectBtnClicked() {
        this.item.decline(() => {
            this.snackbar.add(`${this.item.title} AFVIST...`);
        });
        this.showAcceptanceButtons = false;
    }

    // </editor-fold>

    // <editor-fold desc="Dialogs">

    // </editor-fold>

}
