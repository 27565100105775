import {Filter} from '@app/pages/displays/filtering/Filter';
import {Sort} from '@app/pages/displays/filtering/Sort';
import {Period} from '@app/pages/displays/filtering/Period';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {Display} from '@app/core/models/Display';
import {AppInjector} from '@app/services/app-injector.service';
import moment from 'moment';
import {DisplayFilterEditorFormConfigInterface} from '@app/editor/display-filter-editor/DisplayFilterEditorForm';
import {Settings} from '@app/pages/displays/display-kanban-progress/Settings';
import {ProjectsService} from '@app/services/projects.service';
import {TranslateService} from '@ngx-translate/core';
import {ProjectType} from '@app/core/models/ProjectType';
import {CategoryType, ProjectDeadlineType} from '@app/core/models';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";

export class Filters implements FiltersInterface {

    public applyDefaults(displayFilter: DisplayFilter) {
        displayFilter.filter_type = Filters.FilterAll;
        displayFilter.sort_type = Filters.SortTitle;
        displayFilter.period_type = Filters.PeriodToday;
        displayFilter.include_archived_since_type = Filters.IncludeArchivedSincePeriodPrev2Weeks;
    }

    // Filters
    public static FilterAll = 'filter-all';
    public static FilterDeadlineInPeriod = 'filter-deadline-in-period';
    public static FilterNonDeadline = 'filter-non-deadline';
    public static FilterArchivedInPeriod = 'filter-archived-in-period';
    public static FilterPhase               = 'filter-phase';

    // Sorts
    public static SortTitle = 'sort-title';
    public static SortResponsible = 'sort-responsible';
    public static SortStatus = 'sort-status';
    public static SortStarred = 'sort-starred';
    public static SortHandUp = 'sort-hand-up';

    public static SortCategoryType = 'sort-category-type-';

    public static SortCategoryTypeGenerator(categoryType: CategoryType): string {
        return `${Filters.SortCategoryType}${categoryType.id}`;
    };

    public static ParseSortCategoryType(sort: string): string {
        return sort.substr(Filters.SortCategoryType.length);
    };

    public static SortDeadline = 'sort-deadline-';

    public static SortDeadlineGenerator(projectDeadlineType: ProjectDeadlineType): string {
        return `${Filters.SortDeadline}${projectDeadlineType.id}`;
    };

    public static ParseSortDeadline(sort: string): number {
        return parseInt(sort.substr(Filters.SortDeadline.length));
    };

    public static GetBaseSort(sort: string): string {
        // Remove any identifiers from the end
        return sort.replace(/[0-9]/g, '');
    }

    // Periods
    public static PeriodUserDefined = 'user-defined';
    public static PeriodLastWeek = 'last-week';
    public static PeriodToday = 'today';
    public static PeriodThisWeek = 'this-week';
    public static PeriodNext2Week = 'next-2-weeks';
    public static PeriodNextMonth = 'next-month';

    // Include Archived Since Periods
    public static IncludeArchivedSincePeriodUserDefined = 'user-defined';
    public static IncludeArchivedSincePeriodToday = 'today';
    public static IncludeArchivedSincePeriodThisWeek = 'this-week';
    public static IncludeArchivedSincePeriodPrev1Week = 'prev-1-week';
    public static IncludeArchivedSincePeriodPrev2Weeks = 'prev-2-weeks';
    public static IncludeArchivedSincePeriodThisMonth = 'this-month';
    public static IncludeArchivedSincePeriodThisYear = 'this-year';

    public filters(display: Display): Filter[] {
        const config = this.getEditorConfig(display);
        const projectTypeName = this.getProjectName(null, config);

        const all = new Filter(Filters.FilterAll, this.getTranslation('_ui_all_item', {item: projectTypeName.toLocaleLowerCase()}));
        const deadlineInPeriod =
            new Filter(Filters.FilterDeadlineInPeriod,
                this.getTranslation('_ui_filter_type_with_deadline_type',
                    {
                        type: projectTypeName,
                        deadline_type: config.projectDeadlineTypeName ? config.projectDeadlineTypeName : this.getTranslation('_project_deadline')
                    })
            );
        deadlineInPeriod.showPeriod = true;
        deadlineInPeriod.showProjectDeadlineType = true;
        const nonDeadline =
            new Filter(Filters.FilterNonDeadline,
                this.getTranslation('_ui_filter_type_non_deadline',
                    {
                        type: projectTypeName,
                        deadline_type: config.projectDeadlineTypeName ? config.projectDeadlineTypeName : this.getTranslation('_project_deadline')
                    })
            );
        const archived = new Filter(Filters.FilterArchivedInPeriod,
            this.getTranslation('_ui_filter_completed_in_period', {item: projectTypeName}), true);
        const phase = new Filter(Filters.FilterPhase, this.getTranslation('_ui_filter_type_in_phase',
            {
                type: projectTypeName,
                phase: config.phaseName ? config.phaseName : this.getTranslation('_phase_singular')
            }
        ));
        phase.showPhase = true;

        const allFilters = [
            all, deadlineInPeriod, nonDeadline, archived, phase,
        ];

        allFilters.forEach(filter => {
            filter.showIncludeArchivedSince = true;
        });

        return allFilters;
    }

    private getTranslation(key: string, interpolateParams?: Object): string {
        return AppInjector.getInjector().get(TranslateService).instant(key, interpolateParams);
    }

    public sorts(display: Display, includePseudoTypes = false): Sort[] {
        let title = new Sort(Filters.SortTitle, this.getTranslation('_ui_title'), true);
        let responsible = new Sort(Filters.SortResponsible, this.getTranslation('_ui_project_leader'), true);
        let status = new Sort(Filters.SortStatus, this.getTranslation('_ui_status'), true); // V2.0.0 https://podio.com/klartboard/softwareudvikling/apps/stories/items/62
        let sortStarred = new Sort(Filters.SortStarred, this.getTranslation('_ui_starred'), true);
        let sortHandUp = new Sort(Filters.SortHandUp, this.getTranslation('_ui_hand_up'), true);

        let sorts = [
            title,
            responsible,
            status,
            sortStarred,
            sortHandUp,
        ];

        const config = this.getEditorConfig(display);
        config.categoryTypes?.forEach(categoryType => {
            sorts.push(new Sort(
                Filters.SortCategoryTypeGenerator(categoryType),
                `${categoryType.name} ${this.getTranslation('_filter_show_only', {name: categoryType.name.toLocaleLowerCase()})}`,
                true));
        });
        config.projectDeadlineTypes?.forEach(projectDeadlineType => {
            sorts.push(new Sort(
                Filters.SortDeadlineGenerator(projectDeadlineType),
                `${projectDeadlineType.name} ${this.getTranslation('_filter_show_only', {name: projectDeadlineType.name.toLocaleLowerCase()})}`,
                true));
        });

        return sorts;
    }

    public getSort(display: Display, sort: string): Sort {
        return this.sorts(display).find(item => item.id == sort);
    }

    public periods(): Period[] {
        return [
            new Period(Filters.PeriodUserDefined, this.getTranslation('_ui_date_userdefined'), true),
            new Period(Filters.PeriodLastWeek, this.getTranslation('_ui_date_last_week')),
            new Period(Filters.PeriodToday, this.getTranslation('_ui_date_today')),
            new Period(Filters.PeriodThisWeek, this.getTranslation('_ui_date_this_week')),
            new Period(Filters.PeriodNext2Week, this.getTranslation('_ui_date_next_2_weeks')),
            new Period(Filters.PeriodNextMonth, this.getTranslation('_ui_date_next_month')),
        ];
    }

    public includeArchivedSincePeriods(): Period[] {
        return [
            new Period(Filters.IncludeArchivedSincePeriodUserDefined, this.getTranslation('_ui_date_userdefined_start'), true),
            new Period(Filters.IncludeArchivedSincePeriodToday, this.getTranslation('_ui_date_today')),
            new Period(Filters.IncludeArchivedSincePeriodThisWeek, this.getTranslation('_ui_date_this_week')),
            new Period(Filters.IncludeArchivedSincePeriodPrev1Week, this.getTranslation('_ui_date_past_week')),
            new Period(Filters.IncludeArchivedSincePeriodPrev2Weeks, this.getTranslation('_ui_date_past_2_weeks')),
            new Period(Filters.IncludeArchivedSincePeriodThisMonth, this.getTranslation('_ui_date_this_month')),
            new Period(Filters.IncludeArchivedSincePeriodThisYear, this.getTranslation('_ui_date_this_year')),
        ];
    }

    public getPeriodStart(period: string, sourceDate?: Date): Date {
        let date = moment(sourceDate);
        switch(period) {
            case Filters.PeriodLastWeek:
                date.startOf('isoWeek').subtract(1, 'week');
                break;
            case Filters.PeriodToday:
                date.startOf('day');
                break;
            case Filters.PeriodThisWeek:
                date.startOf('isoWeek');
                break;
            case Filters.PeriodNext2Week:
                date.startOf('day');
                break;
            case Filters.PeriodNextMonth:
                date.startOf('day');
                break;
        }
        return date.toDate();
    }

    public getPeriodEnd(period: string, sourceDate?: Date): Date {
        let date = moment(sourceDate);
        switch(period) {
            case Filters.PeriodLastWeek:
                date.endOf('isoWeek').subtract(1, 'week');
                break;
            case Filters.PeriodToday:
                date.endOf('day');
                break;
            case Filters.PeriodThisWeek:
                date.endOf('isoWeek');
                break;
            case Filters.PeriodNext2Week:
                date.endOf('day').add(2, 'weeks');
                break;
            case Filters.PeriodNextMonth:
                date.endOf('day').add(1, 'month');
                break;
        }
        return date.toDate();
    }

    public getIncludeArchivedSinceDate(period: string, customDate?: string): Date {
        let date = moment();
        switch(period) {
            case Filters.IncludeArchivedSincePeriodToday:
                date.startOf('day');
                break;
            case Filters.IncludeArchivedSincePeriodThisWeek:
                date.startOf('isoWeek');
                break;
            case Filters.IncludeArchivedSincePeriodPrev1Week:
                date.startOf('day').subtract(1, 'week');
                break;
            case Filters.IncludeArchivedSincePeriodPrev2Weeks:
                date.startOf('day').subtract(2, 'week');
                break;
            case Filters.IncludeArchivedSincePeriodThisMonth:
                date.startOf('month');
                break;
            case Filters.IncludeArchivedSincePeriodThisYear:
                date.startOf('year');
                break;
            case Filters.IncludeArchivedSincePeriodUserDefined:
                if(customDate)
                    return new Date(customDate);
                else
                    return null;
        }
        return date.toDate();
    }

    public standards(display: Display): DisplayFilter[] {
        let standards: DisplayFilter[] = [];

        let allByTitle = new DisplayFilter();
        allByTitle.display_id = display.id;
        allByTitle.display = display;
        allByTitle.filter_type = Filters.FilterAll;
        allByTitle.sort_type = Filters.SortTitle;
        allByTitle.statuses = [];
        allByTitle.sort_direction = 'asc';
        standards.push(allByTitle);

        let allByResponsible = new DisplayFilter();
        allByResponsible.display_id = display.id;
        allByResponsible.display = display;
        allByResponsible.filter_type = Filters.FilterAll;
        allByResponsible.sort_type = Filters.SortResponsible;
        allByResponsible.statuses = [];
        allByResponsible.sort_direction = 'asc';
        standards.push(allByResponsible);

        let allByStatus = new DisplayFilter();
        allByStatus.display_id = display.id;
        allByStatus.display = display;
        allByStatus.filter_type = Filters.FilterAll;
        allByStatus.sort_type = Filters.SortStatus;
        allByStatus.statuses = [];
        allByStatus.sort_direction = 'asc';
        standards.push(allByStatus);

        standards.forEach(item => {
            item.include_archived_since_type = Filters.IncludeArchivedSincePeriodPrev2Weeks;
            item.name = item.generateName(this);
        });

        return standards;
    }

    public generateName(displayFilter: DisplayFilter): string {
        // <start> <phase?> <status> <period-description?> <period?> sorteret på <sort>
        let projectTypeName = this.getTranslation('_project'); // forløb
        let phaseName = this.getTranslation('_phase_singular');
        let projectDeadlineTypeName = this.getTranslation('_static_deadline_deadline');
        if(displayFilter.display) {
            const config = this.getEditorConfig(displayFilter.display);
            projectTypeName = this.getProjectName(displayFilter, config);
            if(config.projectDeadlineTypeName) {
                projectDeadlineTypeName = config.projectDeadlineTypeName;
            }
        }

        let start;
        let status;
        let periodDescription;
        let period;
        let sort;
        let includeArchivedSince;

        switch(displayFilter?.filter_type) {
            case Filters.FilterAll:
                start = `${this.getTranslation('_ui_all_item', {item: projectTypeName.toLocaleLowerCase()})}`;
                break;
            case Filters.FilterDeadlineInPeriod:
                start = `${projectTypeName} med ${projectDeadlineTypeName}`;
                if(displayFilter.project_deadline_type) start += ' "' + displayFilter.project_deadline_type.getSmartName().toLocaleLowerCase() + '"';
                break;
            case Filters.FilterNonDeadline:
                start = `${projectTypeName} uden ${projectDeadlineTypeName}`;
                if(displayFilter.project_deadline_type) start += ' "' + displayFilter.project_deadline_type.getSmartName().toLocaleLowerCase() + '"';
                break;
            case Filters.FilterArchivedInPeriod:
                start = `${projectTypeName}`;
                periodDescription = this.getTranslation('_ui_completed');
                break;
            case Filters.FilterPhase:
                start = `${projectTypeName} i ${phaseName}`;
                if(displayFilter.phase) start += ' "' + displayFilter.phase.name.toLocaleLowerCase() + '"';
                break;
        }

        let result = start;

        // Status
        if(!displayFilter.statuses) displayFilter.statuses = [];
        let statuses = displayFilter.statuses.map(status => status.name().toLocaleLowerCase());
        if(statuses.length < 4 && statuses.length > 0) {
            if(statuses.length > 1) {
                let lastStatus = statuses.pop();
                status = 'i ' + statuses.join(', ') + ' og ' + lastStatus;
            } else if(statuses.length == 1)
                status = 'i ' + statuses[0];
            else
                status = 'uden status';
            result += ' ' + status;
        }

        // Period
        switch(displayFilter.period_type) {
            case Filters.PeriodUserDefined:
                period = 'fra ' + displayFilter.getPeriodStartString() + ' til ' + displayFilter.getPeriodEndString();
                break;
            case Filters.PeriodLastWeek:
                period = this.getTranslation('_ui_date_last_week').toLocaleLowerCase();
                break;
            case Filters.PeriodToday:
                period = this.getTranslation('_ui_date_today').toLocaleLowerCase();
                break;
            case Filters.PeriodThisWeek:
                period = this.getTranslation('_ui_date_this_week').toLocaleLowerCase();
                break;
            case Filters.PeriodNext2Week:
                period = this.getTranslation('_ui_date_next_2_weeks').toLocaleLowerCase();
                break;
            case Filters.PeriodNextMonth:
                period = this.getTranslation('_ui_date_next_month').toLocaleLowerCase();
                break;
        }

        if(periodDescription) result += ' ' + periodDescription + ' ' + period;

        // Sort
        let sortDirection = displayFilter.sort_direction == 'asc' ? 'a-z' : 'z-a';
        switch(Filters.GetBaseSort(displayFilter.sort_type)) {
            case Filters.SortTitle:
                sort = this.getTranslation('_project_title').toLocaleLowerCase() + ' ' + sortDirection;
                break;
            case Filters.SortResponsible:
                sort = this.getTranslation('_ui_project_leader').toLocaleLowerCase() + ' ' + sortDirection;
                break;
            case Filters.SortStatus:
                sort = this.getTranslation('_ui_status').toLocaleLowerCase()
                break;
            case Filters.SortDeadline:
            case Filters.SortCategoryType:
                if(displayFilter.display) {
                    const sorts = this.sorts(displayFilter.display);
                    const sortMatch = sorts.find(item => item.id == displayFilter.sort_type);
                    if(sortMatch) {
                        sort = sortMatch.name.toLocaleLowerCase();
                        if(sortMatch.hasOrderDirection) {
                            sort = sort + ' ' + sortDirection;
                        }
                    }
                }
                break;
        }

        if(displayFilter.starred) result += ', ' + this.getTranslation('_ui_starred').toLocaleLowerCase();
        if(displayFilter.hand_up) result += ', ' + this.getTranslation('_ui_hand_up').toLocaleLowerCase();

        switch(displayFilter.include_archived_since_type) {
            case Filters.IncludeArchivedSincePeriodUserDefined:
                includeArchivedSince = `d. ${displayFilter.getIncludeArchivedSincePeriodString(this)}`;
                break;
            case Filters.IncludeArchivedSincePeriodToday:
                includeArchivedSince = this.getTranslation('_ui_date_today').toLocaleLowerCase(); //'i dag';
                break;
            case Filters.IncludeArchivedSincePeriodThisWeek:
                includeArchivedSince = this.getTranslation('_ui_date_this_week').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodPrev1Week:
                includeArchivedSince = this.getTranslation('_ui_date_past_week').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodPrev2Weeks:
                includeArchivedSince = this.getTranslation('_ui_date_past_2_weeks').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodThisMonth:
                includeArchivedSince = this.getTranslation('_ui_date_this_month').toLocaleLowerCase();
                break;
            case Filters.IncludeArchivedSincePeriodThisYear:
                includeArchivedSince = this.getTranslation('_ui_date_this_year').toLocaleLowerCase()
                break;
        }

        result += ', ' + this.getTranslation('_ui_filter_sortet_on').toLocaleLowerCase() + ' ' + sort;

        return result;
    }

    public getEditorConfig(display: Display, result?: (config: DisplayFilterEditorFormConfigInterface) => void): DisplayFilterEditorFormConfigInterface {
        const config: DisplayFilterEditorFormConfigInterface = {};

        if(display == null) {
            // Not fit for editor config
            //console.warn('Filters.GetEditorConfig: Not fit, missing display or displays_settings');
            if(result) {
                result(config);
            }
            return config;
        }

        config.projectDeadlineTypeName = this.getTranslation('_project_deadline').toLocaleLowerCase();

        const projectTypeSettings = display.displays_settings.filter(displaySetting => {
            return displaySetting.setting_id === Settings.ProjectTypeIds;
        });
        if(projectTypeSettings.length) {
            const projectsService = AppInjector.getInjector().get(ProjectsService);
            const projectTypes: ProjectType[] = [];
            let callbackCounter = projectTypeSettings.length;
            const projectTypeCallback = (projectType: ProjectType) => {
                projectTypes.push(projectType);
                callbackCounter--;
                if(callbackCounter === 0) {
                    config.projectTypes = projectTypes;

                    // Find category types visible for project types
                    const categoryTypesMap = new Map<number, CategoryType>();
                    config.projectTypes?.forEach(projectType => {
                        projectType.category_types_project_types
                            ?.filter(categoryTypesProjectType => categoryTypesProjectType.visible)
                            ?.forEach(categoryTypesProjectType => {
                                categoryTypesMap.set(categoryTypesProjectType.category_type_id, categoryTypesProjectType.category_type);
                            });
                    });
                    config.categoryTypes = Array.from(categoryTypesMap.values());

                    // Find project deadline types visible for project types
                    const projectDeadlineTypesMap = new Map<number, ProjectDeadlineType>();
                    config.projectTypes?.forEach(projectType => {
                        projectType.project_deadline_types_project_types
                            ?.filter(projectDeadlineTypesProjectType => projectDeadlineTypesProjectType.visible)
                            ?.forEach(projectDeadlineTypesProjectType => {
                                projectDeadlineTypesMap.set(projectDeadlineTypesProjectType.project_deadline_type_id, projectDeadlineTypesProjectType.project_deadline_type);
                            });
                    });
                    config.projectDeadlineTypes = Array.from(projectDeadlineTypesMap.values());

                    if(result) {
                        result(config);
                    }
                }
            };
            projectTypeSettings.forEach(projectTypeSetting => {
                projectsService.getProjectType(Number(projectTypeSetting.value), projectTypeCallback);
            });
        }

        return config;
    }

    public getProjectName(displayFilter: DisplayFilter, config: DisplayFilterEditorFormConfigInterface): string {
        let projectTypeName = this.getTranslation('_projects');

        let projectTypes = config.projectTypes;

        // Check for project types in display filter and use them as reference to filter config project types
        if(displayFilter && displayFilter.project_types && displayFilter.project_types.length) {
            const selectedProjectTypeIds = displayFilter.project_types.map(value => value.id);
            projectTypes = projectTypes.filter(value => selectedProjectTypeIds.includes(value.id));
        }

        if(projectTypes) {
            projectTypeName = projectTypes.map(projectType => {
                return projectType.getPlural();
            }).join(', ');
        }
        return projectTypeName;
    }

}
