import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges, ViewChild
} from '@angular/core';
import {CardComponent} from '@app/shared/_ui/cards/card.component';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {User} from '@app/core/models/User';
import {CardUserConfiguration} from '@app/shared/_ui/cards/medium/card-user/card-user-configuration';
import {ProjectType} from '@app/core/models/ProjectType';
import {ProjectsService} from '@app/services/projects.service';
import Globals, {Events, StatusTypes} from '@app/constants';
import {ProjectUserTypes} from '@app/core/models/Project';
import Helpers from '@app/core/helpers';
import {PeriodRating} from '@app/core/models/PeriodRating';
import {UserField} from "@app/core/models/UserField";
import {MiniCardFields} from "@app/editor/user-editor/Fields";
import {Subscription} from "rxjs";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {CountRunner} from "@app/core/CountRunner/CountRunner";
import {Api, LoadCalculationResponse} from "@app/core/Api";
import {ProjectCountResponse} from "@app/core/http/Api/ProjectCountResponse";
import {RxStompService} from "@app/core/rabbitmq/rx-stomp.service";

class ProjectBadgeCount {
    count: number = null;
    statusId: number;
    colorClass: string;

    constructor(statusId: number) {
        this.statusId = statusId;
        this.colorClass = (Globals.StatusTypeClasses as any)[statusId];
    }
}

@Component({
    selector: 'app-card-user',
    templateUrl: './card-user.component.html',
    styleUrls: ['../../card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardUserComponent extends CardComponent implements OnInit, OnChanges {

    @Input() item: CardItem<User>;
    @Input() model: User;
    @Input() configuration: CardUserConfiguration;
    @Input() removable = false;
    @Input() tooltip: string;
    @Input() editable = false;
    @Input() interactive = true;
    @Input() listConfiguration: ListConfiguration;
    @Output() onCardClickEvent = new EventEmitter();
    @Output() onRemove = new EventEmitter();
    @Output() projectTypeClicked = new EventEmitter<ProjectType>();
    @ViewChild('cardContainer') cardContainer: ElementRef;

    public selectedProjectType: ProjectType;
    public projectTypes: ProjectType[];
    public projectCounter: ProjectBadgeCount[] = [
        new ProjectBadgeCount(StatusTypes.GREY),
        new ProjectBadgeCount(StatusTypes.GREEN),
        new ProjectBadgeCount(StatusTypes.YELLOW),
        new ProjectBadgeCount(StatusTypes.RED)
    ];
    public loadCalculateResponse: LoadCalculationResponse;
    public periodRating: PeriodRating;
    public fields: Map<number, UserField>;

    constructor(private projectsService: ProjectsService,
                private cd: ChangeDetectorRef,
                private rxStompService: RxStompService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.projectTypes = [
            new ProjectType({id: 0, name: this.translateService.instant('_admin_milestone_plan_all')}),
        ];
        this.selectedProjectType = this.projectTypes[0];

        this.projectsService.getProjectTypesByDepartmentId(this.shellService.getPageSettings()?.departmentId, projectTypes => {
            this.projectTypes = this.projectTypes.concat(projectTypes);
            this.markChangeDetectionDirty();
        });

        this.handleConfigurationSubscriptions();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        if (changes['item'] != undefined) {
            this.handleConfigurationSubscriptions();
        }
    }

    private configurationSubscriptions?: Subscription;

    private handleConfigurationSubscriptions() {
        this.configurationSubscriptions?.unsubscribe();
        this.configurationSubscriptions = new Subscription();

        if (!this.configuration) {
            return;
        }

        if (this.configuration.getLoadCalculationDataSource()) {
            if (this.configuration.getLoadCalculationDataSource().hasResult()) {
                this.loadCalculateResponse = this.configuration.getLoadCalculationDataSource().getResult();
                this.markChangeDetectionDirty();
            }
            this.configurationSubscriptions.add(this.configuration.getLoadCalculationDataSource().onLoadCalculationFetchedEvent.subscribe((loadCalculation: LoadCalculationResponse) => {
                this.loadCalculateResponse = loadCalculation;
                this.markChangeDetectionDirty();
            }));
            this.loadCalculateResponse = this.configuration.getLoadCalculationDataSource().getResult();
        }

        if (this.configuration.getPeriodRatingDataSource()) {
            if (this.configuration.getPeriodRatingDataSource().hasResult()) {
                this.periodRating = this.configuration.getPeriodRatingDataSource().getResult();
                this.markChangeDetectionDirty();
            }
            this.configurationSubscriptions.add(this.configuration.getPeriodRatingDataSource().onPeriodFetchedEvent.subscribe((periodRating: PeriodRating) => {
                this.periodRating = periodRating;
                this.markChangeDetectionDirty();
            }));
        }

        if (this.configuration.getProjectCountFetchRequest()) {
            if (this.configuration.getProjectCountFetchRequest().hasResult()) {
                const projectCountResponse = this.configuration.getProjectCountFetchRequest().getResult();
                projectCountResponse.statuses.forEach(value => {
                    this.projectCounter[value.status_id - 1].count = value.count;
                });
                this.markChangeDetectionDirty();
            }
            this.configurationSubscriptions.add(this.configuration.getProjectCountFetchRequest().onProjectCountFetchedEvent.subscribe((projectCountResponse: ProjectCountResponse) => {
                projectCountResponse.statuses.forEach(value => {
                    this.projectCounter[value.status_id - 1].count = value.count;
                });
                this.markChangeDetectionDirty();
            }));
        }

        // Listen for new or removed tasks and update load
        this.configurationSubscriptions.add(this.rxStompService
            .watch(Events.UserTaskAdded(this.item.item.id))
            .subscribe(message => {
                this.loadLoad();
            }));
        this.configurationSubscriptions.add(this.rxStompService
            .watch(Events.UserTaskRemoved(this.item.item.id))
            .subscribe(message => {
                this.loadLoad();
            }));
    }

    protected render() {
        super.render();

        this.usersService.getMiniCardFields(fields => {
            this.fields = new Map();
            fields.forEach(field => this.fields.set(field.id, field));
            this.markChangeDetectionDirty();
        });

        if (!this.configuration.getLoadCalculationDataSource() && this.configuration?.showLoads) {
            this.loadLoad();
        }

        if (!this.configuration.getProjectCountFetchRequest() && this.configuration?.showProjectCount) {
            this.loadCounts();
        }
    }

    // <editor-fold desc="Load Data">

    private loadCounts() {
        if (!this.selectedProjectType) {
            return;
        }

        const countRunner = new CountRunner(this.projectCounter.length);
        countRunner.setRunner(() => {
            this.markChangeDetectionDirty();
        });

        this.projectCounter.forEach((projectCounter, i) => {
            let api = Api.projects().get()
                .where('projects_user.user_id', this.model.id)
                .where('projects_user.project_user_type_id', ProjectUserTypes.Responsible)
                .where('archived_id', 0)
                .where('main_status.status_id', projectCounter.statusId);

            // https://podio.com/klartboard/softwareudvikling/apps/stories/items/376
            // if(this.systemsService.pageSettings.departmentId)
            //     api.where('department.id', this.systemsService.pageSettings.departmentId);

            if (this.selectedProjectType.exists()) {
                api.where('project_type_id', this.selectedProjectType.id);
            }
            api
                .setShowProgressBar(false)
                .count(count => {
                    this.projectCounter[i].count = count;
                    countRunner.tick();
                });
        });

        countRunner.start();
    }

    private loadLoad() {
        if (this.configuration.getLoadId() && this.configuration.getPeriod()) {
            Api.loads().calculateGetByLoadId(this.configuration.getLoadId())
                .user_ids([this.model.id])
                .start(Helpers.serverDate(this.configuration.getPeriod()[0]))
                .end(Helpers.serverDate(this.configuration.getPeriod()[1]))
                .find(value => {
                    if (value.length > 0) {
                        this.loadCalculateResponse = value[0];
                        this.markChangeDetectionDirty();
                    }
                });
        }
    }

    // </editor-fold>

    // <editor-fold desc="View Actions">

    onStatusChange(statusId: number) {
        if (this.periodRating) {
            this.periodRating.status_id = statusId;
            this.periodRating.patch(['status_id']);
            this.markChangeDetectionDirty();
        }
    }

    selectProjectType($event: any, projectType: ProjectType) {
        $event.stopImmediatePropagation();

        this.selectedProjectType = projectType;
        this.projectTypeClicked.emit(projectType.exists() ? projectType : null);
        this.loadCounts();
    }

    trackByProjectTypeId(index: number, item: ProjectType) {
        if (!item) return null;
        return item.id; // or item.id
    }

    trackByProjectCoundIndex(index: number, item: ProjectBadgeCount) {
        return index; // or item.id
    }

    public isFieldVisible(fieldId: number): boolean {
        if (fieldId && this.fields && this.fields.get(fieldId)) {
            return this.fields.get(fieldId).visible;
        } else {
            return false;
        }
    }

    get Fields() {
        return MiniCardFields;
    }

    // </editor-fold>

}
