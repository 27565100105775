import {BaseComponent} from '@app/shared/_system/base/base.component';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {DisplayFilter} from '@app/core/models';
import {ShellPageData} from '@app/services/ShellService/ShellPageData';
import {ShellService} from '@app/services/ShellService/shell.service';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {Api} from '@app/core/Api';
import {CdkDragDrop} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-header-title-filter-group',
    templateUrl: './header-title-filter-group.component.html',
    styleUrls: ['./header-title-filter-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HeaderTitleFilterGroupComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() shellPageData: ShellPageData;
    @Output() onCloseEvent = new EventEmitter();

    // Bindings to view
    public filterFavorites: DisplayFilter[];
    public filterNonFavorites: DisplayFilter[];
    public editMode: boolean = false;

    constructor(private shellService: ShellService,
                private editorPanelService: EditorPanelService,
                private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }


    dropFavorite(dragEvent: CdkDragDrop<DisplayFilter>) {
        this.filterFavorites.splice(dragEvent.currentIndex, 0, this.filterFavorites.splice(dragEvent.previousIndex, 1)[0]);
        this.saveFavoritePositions();

    }

    moveFavoriteUp(filter: DisplayFilter) {
        const oldIndex = this.filterFavorites.findIndex(item => item.id == filter.id);
        const newIndex = Math.max(0, oldIndex - 1);
        this.filterFavorites.splice(newIndex, 0, this.filterFavorites.splice(oldIndex, 1)[0]);
        this.saveFavoritePositions();
    }

    moveFavoriteDown(filter: DisplayFilter) {
        const oldIndex = this.filterFavorites.findIndex(item => item.id == filter.id);
        const newIndex = Math.min(this.filterFavorites.length - 1, oldIndex + 1);
        this.filterFavorites.splice(newIndex, 0, this.filterFavorites.splice(oldIndex, 1)[0]);
        this.saveFavoritePositions();
    }

    dropNonFavorite(dragEvent: CdkDragDrop<DisplayFilter>) {
        this.filterNonFavorites.splice(dragEvent.currentIndex, 0, this.filterNonFavorites.splice(dragEvent.previousIndex, 1)[0]);
        this.saveNonFavoritePositions();
    }

    moveFilterUp(filter: DisplayFilter) {
        const oldIndex = this.filterNonFavorites.findIndex(item => item.id == filter.id);
        const newIndex = Math.max(0, oldIndex - 1);
        this.filterNonFavorites.splice(newIndex, 0, this.filterNonFavorites.splice(oldIndex, 1)[0]);
        this.saveNonFavoritePositions();
    }

    moveFilterDown(filter: DisplayFilter) {
        const oldIndex = this.filterNonFavorites.findIndex(item => item.id == filter.id);
        const newIndex = Math.min(this.filterNonFavorites.length - 1, oldIndex + 1);
        this.filterNonFavorites.splice(newIndex, 0, this.filterNonFavorites.splice(oldIndex, 1)[0]);
        this.saveNonFavoritePositions();
    }

    deleteFilter(filter: DisplayFilter) {
        filter.delete();
        this.shellPageData.shellFilterGroup.filters.splice(this.shellPageData.shellFilterGroup.filters.findIndex(item => item.id == filter.id), 1);
        this.render();
    }

    removeDepartmentFromFilter(filter: DisplayFilter) {
        filter.departments_display_filters = filter.departments_display_filters.filter(departmentsDisplayFilter => {
            return departmentsDisplayFilter.department_id !== this.shellService.getPageSettings().departmentId;
        });
        filter.save();
        this.shellPageData.shellFilterGroup.filters.splice(this.shellPageData.shellFilterGroup.filters.findIndex(item => item.id == filter.id), 1);
        this.render();
    }

    editFilter(filter: DisplayFilter) { // Open for global display
        if (!filter) {
            filter = DisplayFilter.CreateDefault(this.shellPageData.display);
            this.shellPageData.shellFilterGroup.settings.applyDefaults(filter);
        }
        filter.display = this.shellPageData.display; // Add display with all its displaySettings
        this.editorPanelService.openDisplayFilterEditor(this.shellPageData, filter);
    }

    setFavorite(filter: DisplayFilter) {
        filter.setFavorite(this.shellPageData.departmentId, this.shellPageData.display.id, this.shellPageData.displayFilterExtra);
        this.render();
    }

    removeFavorite(filter: DisplayFilter) {
        filter.removeFavorite(this.shellPageData.departmentId, this.shellPageData.display.id, this.shellPageData.displayFilterExtra);
        this.render();
    }

    setDefault(filter: DisplayFilter) {
        // Remove "is-default" from all other filters
        this.shellPageData.shellFilterGroup.filters
            .filter(filter => filter.my_settings?.is_default)
            .forEach(filter => {
                filter.setIsDefault(this.shellPageData.departmentId, this.shellPageData.display.id, this.shellPageData.displayFilterExtra ?? '', false);
        });
        // Mark this filter "is-default"
        filter.setIsDefault(this.shellPageData.departmentId, this.shellPageData.display.id, this.shellPageData.displayFilterExtra ?? '', true);
        // Mark this filter "is-favorite" if not already
        if (!filter.my_settings.is_favorite) {
            this.setFavorite(filter);
        }
        this.render();
    }

    setVisibility(filter: DisplayFilter, value: boolean) {
        if (!value) {
            this.shellPageData.shellFilterGroup.filters.splice(this.shellPageData.shellFilterGroup.filters.findIndex(item => item.id == filter.id), 1);
        }
        filter.setIsVisible(this.shellPageData.departmentId, this.shellPageData.display.id, this.shellPageData.displayFilterExtra ?? '', value);
        this.render();
    }

    private render() {
        // Sort by position / id
        const allFilters = this.shellPageData.shellFilterGroup.filters
            ?.sort((a, b) => {
                const aIndex = a.my_settings.position ?? 0;
                const bIndex = b.my_settings.position ?? 0;
                if (aIndex == bIndex) {
                    return a.id - b.id;
                } else {
                    return aIndex > bIndex ? 1 : -1;
                }
            }) ?? [];

        this.filterFavorites = allFilters.filter(displayFilter => {
            return displayFilter.my_settings?.is_favorite ?? false;
        }) ?? [];

        this.filterNonFavorites = allFilters.filter(displayFilter => {
            return !(displayFilter.my_settings?.is_favorite ?? false);
        }) ?? [];

        this.detectChanges();
    }

    private saveFavoritePositions() {
        this.filterFavorites.forEach((filter, i) => filter.my_settings.position = i);
        Api.displayFilters().indexingPut()
            .departmentId(this.shellPageData.departmentId)
            .displayId(this.shellPageData.display.id!)
            .displayIdentifier(this.shellPageData.displayFilterExtra ?? '')
            .save(
                {
                    values: this.filterFavorites.map(item => item.id)
                }
            );
        this.render();
    }

    private saveNonFavoritePositions() {
        this.filterNonFavorites.forEach((filter, i) => filter.my_settings.position = i);
        Api.displayFilters().indexingPut()
            .departmentId(this.shellPageData.departmentId)
            .displayId(this.shellPageData.display.id!)
            .displayIdentifier(this.shellPageData.displayFilterExtra ?? '')
            .save(
                {
                    values: this.filterNonFavorites.map(item => item.id)
                }
            );
        this.render();
    }


    toggleEditMode() {
        this.editMode = !this.editMode;
        this.render();
    }
}
