import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {User} from '@app/core/models/User';
import {Task} from '@app/core/models/Task';
import {Todo} from '@app/core/models/Todo';
import {TaskDeadlineTypes, TaskUserTypes} from '@app/constants';
import {TasksService} from '@app/services/tasks.service';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {CardTaskConfiguration} from '@app/shared/_ui/cards/medium/card-task/card-task-configuration';
import {listAnimation} from '@app/animations';
import {PageComponent} from "@app/pages/page.component";
import {NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {
    TodoUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoUserPresetGenerator";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {
    TaskListTableColumn
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/TableColumns/TaskListTableColumn";
import {
    TodoListTableColumn
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/TableColumns/TodoListTableColumn";
import {
    TaskOrderListTableColumn
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/TableColumns/TaskOrderListTableColumn";
import {
    TaskCanCopyListTableColumn
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/TableColumns/TaskCanCopyListTableColumn";
import {
    MilestoneListTableColumn
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/TableColumns/MilestoneListTableColumn";
import {
    MilestoneListColumnType
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/ColumnTypes/MilestoneListColumnType";
import {
    TaskCanCopyListColumnType
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/ColumnTypes/TaskCanCopyListColumnType";
import {
    TaskListColumnType
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/ColumnTypes/TaskListColumnType";
import {
    TaskOrderListColumnType
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/ColumnTypes/TaskOrderListColumnType";
import {
    TodoListColumnType
} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/ColumnTypes/TodoListColumnType";
import {ColumnTypes} from "@app/pages/displays/dashboard/dashboard-sidebar-task-list/ColumnTypes";
import {Api} from "@app/core/Api";

@Component({
    selector: 'app-dashboard-sidebar-task-list',
    templateUrl: './dashboard-sidebar-task-list.component.html',
    styleUrls: ['./dashboard-sidebar-task-list.component.scss'],
    animations: [
        listAnimation
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DashboardSidebarTaskListComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController = new ColumnController();

    private static Tabs = {
        Tasks: 'tab-tasks',
        Todos: 'tab-todos',
        TaskOrders: 'tab-task-orders',
        TasksCanBeCopied: 'tab-task-can-be-copied',
        Milestones: 'tab-milestones',
    };

    private static SortFilter = {
        DeadlineDate: 'deadline-date',
        PlanningDate: 'planning-date',
        Status: 'status',
        Starred: 'starred',
        HandUp: 'hand-up',
        WithoutDeadlineAndPlan: 'without-deadline-and-plan',
        Project: 'project',
        CanCopy: 'can-copy',
    };

    // Bindings to parent
    @Input() activeUser: User;
    @Input() isMainDisplay = true;
    @Input() displayId: number;

    // Bindings to view
    public selectedTab: string = DashboardSidebarTaskListComponent.Tabs.Tasks;
    public sortFilterValue: string = DashboardSidebarTaskListComponent.SortFilter.PlanningDate;
    public sortFilterName = '';
    public sortFilter = DashboardSidebarTaskListComponent.SortFilter;
    public tabs = DashboardSidebarTaskListComponent.Tabs;
    public taskListTableColumn?: TaskListTableColumn;
    public todoListTableColumn?: TodoListTableColumn;
    public taskOrderListTableColumn?: TaskOrderListTableColumn;
    public taskCanCopyListTableColumn?: TaskCanCopyListTableColumn;
    public milestoneListTableColumn?: MilestoneListTableColumn;
    public taskOrderList: CardItem<Task>[];

    // Data
    private limit = 10;
    private tasks: CardItem<Task>[];
    private todos: CardItem<Todo>[];

    constructor(private tasksService: TasksService,
                private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
        this.isLoading = true;
        this.sortFilterName = this.translateService.instant('_ui_planned_date');
    }

    ngOnInit() {
        this.buildColumns();
        super.ngOnInit();
        this.isLoading = true;

        this.taskOrderList = [];
        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(_ => this.gotoTab()));
        this.subscribe(this.filterGlobalService.onSettingsPeriodChangeEvent.subscribe(_ => {
            this.render();
            this.detectChanges();
        }));
    }

    // <editor-fold desc="View Helpers">

    tabChange($event: NgbNavChangeEvent) {
        this.gotoTab($event.nextId);
    }

    sortBy(value: string, title: string) {
        this.sortFilterName = title;
        this.sortFilterValue = value;
        this.gotoTab(this.selectedTab);
    }

    tasksListDataSetChanged(tasks: CardItem<Task>[]) {
        this.tasks = tasks;
    }

    todoListDataSetChanged(items: CardItem<Todo>[]) {
        this.todos = items;
    }

    private gotoTab(tab: string = this.selectedTab) {
        this.selectedTab = tab;

        switch (tab) {
            case DashboardSidebarTaskListComponent.Tabs.TasksCanBeCopied:
                this.loadTasksCanCopy();
                break;
            case DashboardSidebarTaskListComponent.Tabs.Tasks:
                this.loadTasks();
                break;
            case DashboardSidebarTaskListComponent.Tabs.Todos:
                this.loadTodos();
                break;
            case DashboardSidebarTaskListComponent.Tabs.TaskOrders:
                this.loadTaskOrders();
                break;
            case DashboardSidebarTaskListComponent.Tabs.Milestones:
                this.loadMilestones();
                break;
        }
    }

    // </editor-fold>

    // <editor-fold desc="Load Items">

    private loadTasks() {
        if (!this.initialized) {
            console.warn('skip cause of no init')
            return;
        }
        if (!this.taskListTableColumn) {
            console.warn('skip cause of no column')
            return;
        }

        this.taskListTableColumn.listConfiguration
            // .setDepartment(this.department) https://podio.com/klartboard/softwareudvikling/apps/stories/items/223
            .setUser(this.activeUser)
            .setNotArchivedForUserId(this.activeUser.id)
            .setLimit(this.limit)
            .setBeforeAddHook(item => this.renderTask(item))
            .setCustomOnCardItemDragAddFunction((cardItem, configuration) => {
                const participant = cardItem.item.findParticipant(this.activeUser.id);
                if (participant) {
                    cardItem.item.setUserDeadline(participant.user);
                }
            });

        switch (this.sortFilterValue) {
            case DashboardSidebarTaskListComponent.SortFilter.DeadlineDate:
                this.taskListTableColumn.listConfiguration.setOrderBy([['tasks_deadline.deadline.date', 'null'], ['tasks_deadline.deadline.date', 'asc'], ['id', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.PlanningDate:
                this.taskListTableColumn.listConfiguration.setOrderBy([['tasks_user.deadline.date', 'null'], ['tasks_user.deadline.date', 'asc'], ['id', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Status:
                this.taskListTableColumn.listConfiguration.setOrderBy([['main_status.status_id', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Starred:
                this.taskListTableColumn.listConfiguration.setOrderBy([['num_stars', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.HandUp:
                this.taskListTableColumn.listConfiguration.setOrderBy([['num_hand_ups', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.WithoutDeadlineAndPlan:
                this.taskListTableColumn.listConfiguration.setPlanningDeadlineId(0);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Project:
                this.taskListTableColumn.listConfiguration.setOrderBy([['project.title', 'asc']]);
                break;
        }

        this.taskListTableColumn.reloadEvent.emit();
    }

    private loadTasksCanCopy() {
        if (!this.initialized) {
            return;
        }
        if (!this.taskCanCopyListTableColumn) {
            return;
        }

        this.taskCanCopyListTableColumn.listConfiguration
            // .setDepartment(this.department) https://podio.com/klartboard/softwareudvikling/apps/stories/items/223
            .setUser(this.activeUser)
            .setNotArchivedForUserId(this.activeUser.id)
            .setLimit(this.limit)
            .setBeforeAddHook(item => this.renderTask(item))
            .setCustomOnCardItemDragAddFunction((cardItem, configuration) => {
                const participant = cardItem.item.findParticipant(this.activeUser.id);
                if (participant) {
                    cardItem.item.setUserDeadline(participant.user);
                }
            });

        switch (this.sortFilterValue) {
            case DashboardSidebarTaskListComponent.SortFilter.DeadlineDate:
                this.taskCanCopyListTableColumn.listConfiguration.setOrderBy([['tasks_deadline.deadline.date', 'null'], ['tasks_deadline.deadline.date', 'asc'], ['id', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.PlanningDate:
                this.taskCanCopyListTableColumn.listConfiguration.setOrderBy([['tasks_user.deadline.date', 'null'], ['tasks_user.deadline.date', 'asc'], ['id', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Status:
                this.taskCanCopyListTableColumn.listConfiguration.setOrderBy([['main_status.status_id', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Starred:
                this.taskCanCopyListTableColumn.listConfiguration.setOrderBy([['num_stars', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.HandUp:
                this.taskCanCopyListTableColumn.listConfiguration.setOrderBy([['num_hand_ups', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.WithoutDeadlineAndPlan:
                this.taskCanCopyListTableColumn.listConfiguration.setPlanningDeadlineId(0);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Project:
                this.taskCanCopyListTableColumn.listConfiguration.setOrderBy([['project.title', 'asc']]);
                break;
        }

        this.taskCanCopyListTableColumn.reloadEvent.emit();
    }

    private loadTodos() {
        if (!this.todoListTableColumn) {
            return;
        }

        this.todoListTableColumn.listConfiguration
            .setUser(this.activeUser)
            .setBeforeAddHook(item => this.renderTodo(item))
            .setLimit(this.limit)
            .setCreatePresetGenerators([
                new TodoUserPresetGenerator(this.activeUser.id),
            ]);

        switch (this.sortFilterValue) {
            case DashboardSidebarTaskListComponent.SortFilter.DeadlineDate:
            case DashboardSidebarTaskListComponent.SortFilter.PlanningDate:
                this.todoListTableColumn.listConfiguration.setOrderBy([['deadline.date', 'asc'], ['id', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Status:
                this.todoListTableColumn.listConfiguration.setOrderBy([['main_status.status_id', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Starred:
                this.todoListTableColumn.listConfiguration.setStarred(true);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.WithoutDeadlineAndPlan:
                this.todoListTableColumn.listConfiguration.setHasDeadline(true);
                break;
            // Not supported
            case DashboardSidebarTaskListComponent.SortFilter.HandUp:
            case DashboardSidebarTaskListComponent.SortFilter.CanCopy:
            case DashboardSidebarTaskListComponent.SortFilter.Project:
                this.todoListTableColumn.listConfiguration.setOrderBy([['title', 'asc']]);
                break;
        }

        this.todoListTableColumn.reloadEvent.emit();
    }

    private loadTaskOrders() {
        if (!this.taskOrderListTableColumn) {
            return;
        }

        const api = Api.tasks().get()
            // .where('department.id', this.department.id) https://podio.com/klartboard/softwareudvikling/apps/stories/items/223
            .where('tasks_user.user_id', this.activeUser.id)            // "Mine" task orders
            .whereIn('tasks_user.task_user_type_id', this.taskOrderListTableColumn.column.getTaskUserTypes())
            .where('archived_id', 0);

        switch (this.sortFilterValue) {
            case DashboardSidebarTaskListComponent.SortFilter.DeadlineDate:
                api.orderBy('tasks_deadline.deadline.date', 'asc').orderBy('id', 'desc');
                break;
            case DashboardSidebarTaskListComponent.SortFilter.PlanningDate:
                api.orderBy('tasks_user.deadline.date', 'asc').orderBy('id', 'desc');
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Status:
                api.orderBy('main_status.status_id', 'desc');
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Starred:
                api.where('starred', true);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.HandUp:
                api.where('hand_up', true);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.WithoutDeadlineAndPlan:
                api.where('tasks_user.deadline_id', 0);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Project:
                api.orderBy('project.title', 'asc');
                break;
            case DashboardSidebarTaskListComponent.SortFilter.CanCopy:
                api.where('can_copy', 1);
                break;
        }

        // Global Filter
        if (this.filterGlobalService.getActiveSettings().isHandUp) {
            api.where('hand_up', true);
        }
        if (this.filterGlobalService.getActiveSettings().isStarred) {
            api.where('starred', true);
        }
        if (this.filterGlobalService.getActiveSettings().activeStatuses.length > 0) {
            api.whereInArray('main_status.status_id', this.filterGlobalService.getActiveSettings().activeStatuses);
        }

        api.find(items => {
            this.taskOrderList = [];

            for (const task of items) {
                this.tasksService.getTaskType(task.task_type_id, taskType => { // Set task type config
                    task.task_type = taskType;

                    const myLevel = task.findMyLevel(this.activeUser.id, this.taskOrderListTableColumn.column.getTaskUserTypes());

                    if (myLevel === -1) {
                        return;
                    }
                    const nextLevel = task.findNextLevel(myLevel);
                    if (myLevel < nextLevel) {
                        const tasksUsers = task.findUsersByLevel(nextLevel);
                        if (tasksUsers && tasksUsers.length) {
                            const config = new CardTaskConfiguration();
                            config.tasksUsers = tasksUsers;
                            this.taskOrderList.push(new CardItem(task, config));
                        }
                    }
                });
            }

            this.render();
        });
    }

    private loadMilestones() {
        if (!this.milestoneListTableColumn) {
            return;
        }

        this.milestoneListTableColumn.listConfiguration
            .setResponsible(this.activeUser)
            .setLimit(this.limit);

        switch (this.sortFilterValue) {
            case DashboardSidebarTaskListComponent.SortFilter.DeadlineDate:
                this.milestoneListTableColumn.listConfiguration.setOrderBy([['deadline.date', 'null'], ['deadline.date', 'asc'], ['id', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.PlanningDate: // Not implemented
                this.milestoneListTableColumn.listConfiguration.setOrderBy([['name', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Status:
                this.milestoneListTableColumn.listConfiguration.setOrderBy([['main_status.status_id', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Starred:
                this.milestoneListTableColumn.listConfiguration.setOrderBy([['num_stars', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.HandUp:
                this.milestoneListTableColumn.listConfiguration.setOrderBy([['num_hand_ups', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.WithoutDeadlineAndPlan: // Not implemented
                this.milestoneListTableColumn.listConfiguration.setOrderBy([['name', 'desc']]);
                break;
            case DashboardSidebarTaskListComponent.SortFilter.Project:
                this.milestoneListTableColumn.listConfiguration.setOrderBy([['project.title', 'asc']]);
                break;
        }

        this.milestoneListTableColumn.reloadEvent.emit();
    }

    private render() {
        switch (this.selectedTab) {
            case DashboardSidebarTaskListComponent.Tabs.Tasks:
                this.renderTasks();
                break;
            case DashboardSidebarTaskListComponent.Tabs.Todos:
                this.renderTodos();
                break;
            case DashboardSidebarTaskListComponent.Tabs.TaskOrders:
                this.renderTaskOrders();
                break;
        }
    }

    private renderTasks() {
        this.tasks?.forEach(task => this.renderTask(task));
    }

    private renderTask(item: CardItem<Task>) {
        const config: CardTaskConfiguration = item.configuration as CardTaskConfiguration;

        switch (this.sortFilterValue) {
            default:
            case DashboardSidebarTaskListComponent.SortFilter.DeadlineDate:
                config.taskDeadline = item.item.findTasksDeadlineByType(TaskDeadlineTypes.Normal);
                config.isGrayedOut = false;
                if (config.taskDeadline && config.taskDeadline.deadline) {
                    const date = config.taskDeadline.deadline.getDate();
                    config.isGrayedOut = date.getTime() >= this.filterGlobalService.getActiveSettings().period.start.getTime() && date.getTime() <= this.filterGlobalService.getActiveSettings().period.end.getTime();
                }
                break;
            case DashboardSidebarTaskListComponent.SortFilter.PlanningDate:
                config.tasksUserDeadline = item.item.tasks_users ? item.item.tasks_users.find(tasksUser => {
                    return tasksUser.task_user_type_id == TaskUserTypes.Participant && tasksUser.user_id == this.activeUser.id;
                }) : null;
                config.isGrayedOut = false;
                if (config.tasksUserDeadline && config.tasksUserDeadline.deadline && config.tasksUserDeadline.deadline.date) {
                    const date = config.tasksUserDeadline.deadline.getDate();
                    config.isGrayedOut = date.getTime() >= this.filterGlobalService.getActiveSettings().period.start.getTime() && date.getTime() <= this.filterGlobalService.getActiveSettings().period.end.getTime();
                }
                break;
        }

        config.emitChange();
    }

    private renderTodos() {
        this.todos?.forEach(item => this.renderTodo(item));
    }

    private renderTodo(item: CardItem) {
        const config: CardTaskConfiguration = item.configuration as CardTaskConfiguration;
        const todo: Todo = item.item as Todo;
        config.isGrayedOut = false;
        if (todo.deadline) {
            const date = todo.deadline.getDate();
            config.isGrayedOut = date.getTime() >= this.filterGlobalService.getActiveSettings().period.start.getTime() && date.getTime() <= this.filterGlobalService.getActiveSettings().period.end.getTime();
        }
    }

    private renderTaskOrders() {
        this.taskOrderList?.forEach(item => this.renderTaskOrder(item));
    }

    private renderTaskOrder(item: CardItem) {
        const config: CardTaskConfiguration = item.configuration as CardTaskConfiguration;
        config.isGrayedOut = false;
        if (config.tasksUserDeadline && config.tasksUserDeadline.deadline) {
            const date = config.tasksUserDeadline.deadline.getDate();
            config.isGrayedOut = date.getTime() >= this.filterGlobalService.getActiveSettings().period.start.getTime() && date.getTime() <= this.filterGlobalService.getActiveSettings().period.end.getTime();
        }
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    private buildColumns() {
        this.columnController.addColumnTypes([
            new MilestoneListColumnType(),
            new TaskCanCopyListColumnType(),
            new TaskListColumnType(),
            new TaskOrderListColumnType(),
            new TodoListColumnType(),
        ]);
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();
        this.columnController.loadColumns(this.displayId, () => {
            this.setColumns();
            this.initialize();
        });
    }

    private initialize() {
        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            this.setColumns();
        }));

        this.initialized = true;
        this.gotoTab(DashboardSidebarTaskListComponent.Tabs.Tasks);
    }

    private setColumns() {
        const tableColumns = this.columnController.getVisibleTableColumns();
        this.milestoneListTableColumn = (tableColumns as MilestoneListTableColumn[])
            .find(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.MilestoneList);
        this.taskListTableColumn = (tableColumns as TaskListTableColumn[])
            .find(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.TaskList);
        this.taskOrderListTableColumn = (tableColumns as TaskOrderListTableColumn[])
            .find(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.TaskOrderList);
        this.taskCanCopyListTableColumn = (tableColumns as TaskCanCopyListTableColumn[])
            .find(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.TaskCanCopyList);
        this.todoListTableColumn = (tableColumns as TodoListTableColumn[])
            .find(tableColumn => tableColumn.column.columnType.identifier == ColumnTypes.TodoList);

    }

    // </editor-fold>

}
