import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Todo} from "@app/core/models";
import {TranslateService} from "@ngx-translate/core";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {EditStatusConfiguration} from "@app/editor/quick-editor/editors/generic/status-editor/EditStatusConfiguration";
import {
    EditIsPrivateConfiguration
} from "@app/editor/quick-editor/editors/generic/is-private-editor/EditIsPrivateConfiguration";
import {
    EditReactionListConfiguration
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionListConfiguration";
import {ReactionTypes} from "@app/constants";
import {TodosService} from "@app/services/todos.service";
import {Api} from "@app/core/Api";
import {AppInjector} from "@app/services/app-injector.service";
import {EventService} from "@app/services/event.service";
import {EditorPanelService} from "@app/services/editor-panel.service";
import {Field} from "@app/editor/todo-editor-loader/todo-editor.service";
import {Fields} from "@app/editor/todo-editor-loader/Fields";
import {FocusableEntityTypes} from "@app/core/Focusable/FocusableEntityTypes";

@Component({
    selector: 'app-todo-editor-header',
    templateUrl: './todo-editor-header-component.html',
    styleUrls: ['./todo-editor-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TodoEditorHeaderComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Todo;
    @Input() fields: Map<number, Field>;
    @Input() validator!: ValidationErrorInterface;

    // Bindings to view
    public isReady = false;
    public showValidationWarning = false;

    // Bindings to view: Status Editor
    public showStatusEditor = false;
    public statusEditorConfiguration: EditStatusConfiguration;

    // Bindings to view: Private
    public showIsPrivateEditor = false;
    public isPrivateEditorConfiguration: EditIsPrivateConfiguration;

    // Bindings to view: Reaction list editor
    public showReactionListEditor = false;
    public reactionListEditorConfiguration: EditReactionListConfiguration;

    // Data

    constructor(
        private cd: ChangeDetectorRef,
        private translateService: TranslateService,
        private dialogService: BaseDialogService,
        private todosService: TodosService,
        private editorPanelService: EditorPanelService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.setup();
    }

    private setup() {
        this.showStatusEditor = true;
        this.statusEditorConfiguration = new EditStatusConfiguration();

        this.showIsPrivateEditor = this.isFieldVisible(Fields.IsPrivate);
        this.isPrivateEditorConfiguration = new EditIsPrivateConfiguration();

        this.showReactionListEditor = true;
        this.reactionListEditorConfiguration = new EditReactionListConfiguration(
            false,
            true,
            false,
            false,
            true
        );

        this.todosService.getReactionTypes(reactionTypes => {
            this.reactionListEditorConfiguration.showReactionTypes = reactionTypes?.find(rt => rt.reaction_type_id == ReactionTypes.Emoji)?.visible ?? false;
            this.detectChanges();
        })

        this.subscribe(this.validator.onIsValidChanged.subscribe(isValid => {
            this.renderValidation();
            this.detectChanges();
        }));
        this.renderValidation();

        this.isReady = true;
    }

    private renderValidation() {
        this.showValidationWarning = !this.validator.isValid();
    }

    private isFieldVisible(fieldId: number): boolean {
        return this.fields.has(fieldId) && this.fields.get(fieldId).visible;
    }

    // <editor-fold desc="View actions">

    public onCopyBtnClicked() {
        this.dialogService.confirm(
            this.translateService.instant('_ui_copy'),
            this.translateService.instant('_ui_copy_item', {item: this.item.title}),
            this.translateService.instant('_global_ok'),
            this.translateService.instant('_global_cancel'),
            'sm',
            false,
            'btn-success'
        )
            .then((confirmed) => {
                if (confirmed) {
                    Api.todos()
                        .copyGetById(this.item.id)
                        .find((r) => {
                            this.editorPanelService.open(r[0]);
                            AppInjector.getInjector().get(EventService).emitTodo(new Todo(r[0]), EventService.Created);
                        });
                }
            })
    }

    // </editor-fold>

    protected readonly FocusableEntityTypes = FocusableEntityTypes;
}
