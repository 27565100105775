import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {BaseModel} from "@app/core/models/BaseModel";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {EditCanCopy} from "@app/editor/quick-editor/editors/generic/can-copy-editor/EditCanCopy";
import {
    EditCanCopyConfiguration
} from "@app/editor/quick-editor/editors/generic/can-copy-editor/EditCanCopyConfiguration";

@Component({
    selector: 'app-can-copy-editor',
    templateUrl: './can-copy-editor.component.html',
    styleUrls: ['./can-copy-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CanCopyEditorComponent extends BaseEditor<EditCanCopy> {
    protected eventFieldName = 'can_copy';

    // Bindings to parent
    @Input() model: EditCanCopy & HasEventGenerator & BaseModel;
    @Input() configuration: EditCanCopyConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public value: boolean;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected render() {
        this.value = this.model.isCanCopy();
        this.detectChanges();
    }

    public onToggle() {
        this.model.setCanCopy(!this.value);
        this.onItemUpdated();
        this.render();
    }

}
