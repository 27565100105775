import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {Router} from '@angular/router';
import {I18nService} from '@app/core';
import {User} from '@app/core/models/User';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {Department} from '@app/core/models/Department';
import {Display} from '@app/core/models/Display';
import {DisplayTypes, System} from '@app/constants';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SettingsEditorComponent} from '@app/editor/settings-editor/settings-editor.component';
import {environment} from '@env/environment';
import {versions} from '@env/versions';
import Helpers from '@app/core/helpers';
import {DepartmentGroup} from '@app/core/models/DepartmentGroup';
import {DisplayService} from '@app/services/display.service';
import {DisplayLink} from "@app/shell/header/display-list/display-list.component";
import {AppInjector} from "@app/services/app-injector.service";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {Api} from "@app/core/Api";
import {MilestonePlan} from "@app/core/models";
import {ExportService} from "@app/services/ExportService/export.service";
import {PageSettings} from "@app/services/ShellService/PageSettings";
import {SnackbarService} from '@app/services/snackbar.service';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {LocalStorageService} from '@app/services/local-storage.service';
import {AuthService} from "../../../services/auth.service";

export enum NavigationItems {
    Dashboard = 'dashboard',
    Displays = 'displays',
    DepartmentGroups = 'department_groups',
    Departments = 'departments',
    Projects = 'projects',
    Tasks = 'departments',
    Milestones = 'milestones',
    TeamRules = 'team_rules',
    Tools = 'tools',
    Search = 'search',
    MilestonePlans = 'milestone_plans',
    Mobile = 'mobile',
}

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SidebarComponent extends BaseDisplayComponent implements OnInit {

    @Output() navToggle = new EventEmitter<boolean>();
    @Output() navClose = new EventEmitter();
    @Input() public menuHidden = true;

    public NavigationItems = NavigationItems;
    private pageSettings: PageSettings;

    private displays: Display[] = [];
    public navigationItem: string = this.NavigationItems.Displays;
    public departmentGroups: DepartmentGroup[];
    public departments: Department[] = [];
    public visibleDepartments: Department[];
    public user: User;
    public showPurgeHands: boolean = false;
    public showExportButton = false;

    public selectedUser: User;
    public selectedDisplay: Display;
    public selectedDepartment: Department;
    public selectedDepartmentGroup: DepartmentGroup;
    public prefersDark: boolean;

    public autoClose = true;

    public environment: any = environment;
    public gitVersions: {
        npm_version: string;
        revision: string;
        branch: string;
        version: string;
        project_id: string
    } = versions;

    constructor(private router: Router,
                private i18nService: I18nService,
                private modalService: NgbModal,
                private displayService: DisplayService,
                private exportService: ExportService,
                private cd: ChangeDetectorRef,
                private dbService: NgxIndexedDBService,
                private snackbar: SnackbarService,
                private dialogService: BaseDialogService,
                private localStorageService: LocalStorageService,
                private authService: AuthService,

                ) {
        super();
        this.cdr = cd;
    }



    ngOnInit() {

        const themeSelection = this.localStorageService.get(System.CookieTheme);
        this.prefersDark = window.matchMedia("(prefers-color-scheme: dark)")?.matches;
        if(themeSelection != undefined){
            this.prefersDark = themeSelection;
        }

        const mobileMode = this.localStorageService.get(System.CookieMobile);
        if(mobileMode){
            this.navigationItem = this.NavigationItems.Mobile;
        }


        this.pageSettings = this.shellService.getPageSettings();
        this.selectedUser = this.user = this.usersService.user;
        this.subscribe(this.usersService.currentUser$.subscribe(user => {
            this.user = this.selectedUser = user;

            if (this.user) {
                this.showPurgeHands = (this.user?.administrator || this.user?.uber || this.user?.sysadmin) && this.selectedDepartment !== null;
            } else {
                this.showPurgeHands = false;
            }

            // console.log('currentUser$ : ', this.user)
            if(this.user?.id){
                // Liste af displays
                this.displayService.getDisplaysWithoutSettings(displays => {
                    this.displays = displays.filter(display => display.routerPath);

                    // Vi skal ikke tjekke for brugers afdelinger: https://podio.com/klartboard/softwareudvikling/apps/stories/items/135
                    if (this.selectedDepartment == null) {
                        if (this.user?.departments) { // Vælg den første afdeling, at brugeren er tilknyttet
                            this.displays.forEach(display => {
                                if (display.departments_displays) {
                                    display.departments_displays.find(dd => {
                                        if (Helpers.itemExists(this.user.departments, dd.department_id)) {
                                            this.selectedDepartment = this.user.departments.find(value => value.id == dd.department_id);
                                            return true;
                                        }
                                    });
                                }
                            });
                        } else if (this.visibleDepartments && this.visibleDepartments.length) { // Blot vælg en første afdeling
                            this.selectedDepartment = this.visibleDepartments[0];
                        }
                    }

                    Department.GetAll(departments => {
                        this.departments = departments;
                        this.visibleDepartments = this.departments.filter(department => department.department_group == null);
                        this.findSelectedDepartmentGroup();


                        DepartmentGroup.GetAll(departmentGroups => {
                            this.departmentGroups = departmentGroups;
                            departmentGroups.forEach(group => {
                                group.department = this.departments.filter(department => department.department_group_id == group.id);
                            })
                            this.findSelectedDepartment();
                        });

                    });

                });
            }
        }));






        this.subscribe(this.shellService.onPageSettingsChangeEvent.subscribe(pageSettings => {
            this.pageSettings = pageSettings;
            this.findSelectedDepartment();
        }));

        this.subscribe(this.exportService.onShowExportButtonChangeEvent.subscribe(value => {
            this.showExportButton = value;
        }))
    }

    private findSelectedDepartmentGroup() {
        if(this.departmentGroups && !this.selectedDepartmentGroup && this.selectedDepartment?.department_group_id){
            this.selectedDepartmentGroup = this.departmentGroups.find(dg => dg.id == this.selectedDepartment?.department_group_id);
            this.detectChanges();
        }
    }
    private findSelectedDepartment() {
        if (this.pageSettings && this.pageSettings.departmentId && this.departments) {
            this.selectedDepartment = this.departments.find(department => department.id == this.pageSettings.departmentId);
        }
        this.findSelectedDepartmentGroup();

    }

    public csvExport(value: MouseEvent) {
        this.exportService.onExportButtonClickEvent.emit(value);
    }

    clearStorage() {
        this.dialogService.confirm(this.translateService.instant('_ui_reset_cookies'), this.translateService.instant('_ui_reset_cookies_description')).then((r) => {
            if (r) {
                localStorage.clear();
                this.dbService.clear(System.DBCache).subscribe((r)=>{
                    document.location.reload();
                    this.snackbar.add(this.translateService.instant('_ui_reset_cookies_complete'));
                })
            }
        })
    }


    public getDisplays(department: Department, displayTypeId?: number): Display[] {
        // Udlæsning skal ignorere brugerens afdeling! https://podio.com/klartboard/softwareudvikling/apps/stories/items/135

        let _displays = this.displays.filter((d: Display) => {
            if (department && d.departments_displays) {
                return d.departments_displays.filter(depd => depd.department_id == department.id).length > 0;
            }
        });

        if (displayTypeId) {
            _displays = _displays.filter((d: Display) => {
                return d.display_type_id == displayTypeId;
            });
        }

        return _displays ? _displays : [];
    }

    // <editor-fold desc="View Actions>

    public setNavigation(item: string, selectedDisplay?: Display) {
        this.autoClose = item == NavigationItems.TeamRules;

        this.selectedDisplay = selectedDisplay;
        this.navigationItem = item;
        this.changeNavigationItem(item)
    }

    public changeDepartment(event: MouseEvent, department: Department) {
        event.stopPropagation();
        this.selectedDepartment = department;
        this.navigationItem = this.NavigationItems.Displays;
    }

    public changeNavigationItem(navigationItem: string) {
        this.navigationItem = navigationItem;
        this.render();
    }

    private render(){
        this.cdr.detectChanges();
    }

    public changeDepartmentGroup(event: MouseEvent, group: DepartmentGroup) {
        event.stopPropagation();
        this.selectedDepartmentGroup = group;
        this.navigationItem = this.NavigationItems.Departments;
        if (group) {
            this.visibleDepartments = this.departments.filter(department => department.department_group_id == group.id);
        } else {
            this.visibleDepartments = this.departments.filter(department => department.department_group == null);
        }
    }

    // </editor-fold>

    // <editor-fold desc="View helpers">

    public open() {
        if (this.menuHidden) {
            this.menuHidden = false;
            this.navToggle.emit(this.menuHidden);
        }
    }

    public close() {
        if (!this.menuHidden) {
            this.menuHidden = true;
            this.navToggle.emit(this.menuHidden);
        }
    }

    public toggle() {
        this.menuHidden = !this.menuHidden;
        this.navToggle.emit(this.menuHidden);
    }

    toggleMenu() {
        this.menuHidden = !this.menuHidden;
        this.navToggle.emit(this.menuHidden);
    }

    setLanguage(language: string) {
        this.i18nService.language = language;
    }

    logout() {
        this.authService.logout();
    }

    get currentLanguage(): string {
        return this.i18nService.language;
    }

    get languages(): string[] {
        return this.i18nService.supportedLanguages;
    }

    public openSettingsPanel() {
        this.toggleMenu();
        this.modalService.open(SettingsEditorComponent, {
            size: 'lg',
            windowClass: 'modal-holder',
            centered: true,
            backdrop: 'static',
            keyboard: true
        });
    }

    closeIfOpen() {
        if(this.autoClose)
            this.close();
    }

    closeMenu() {
        this.close();
        // this.navClose.emit();
    }

    itemSelected($event: DisplayLink) {
        if($event.display.display_type_id == DisplayTypes.UserOverview){
            this.setNavigation(this.NavigationItems.Dashboard, $event.display);
        }else{
            this.closeMenu();
        }

    }

    removeAllHandUps() {
        AppInjector.getInjector().get(BaseDialogService).confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_confirm_delete_hand_up', {item: this.selectedDepartment.name}),
        )
            .then(confirmed => {
                if (confirmed) {
                    Api.departments().handUpDeleteDeleteById(this.selectedDepartment.id).delete(value => {
                        this.shellService.onReloadAllClickEvent.emit();
                    });
                }
            });
    }

    changeShowNonPlanned($event: boolean) {
        this.shellService.onShowNonPlannedSidebarChangeEvent.emit($event);
        this.close();
    }

    onSelectMilestonePlan(milestonePlan: MilestonePlan) {
        if(milestonePlan) {
            this.router.navigate(['/app', 'administrator', 'templates', milestonePlan.id], {queryParamsHandling: "merge"}); // Change route
        }
        this.closeMenu();
    }

    sendMail() {
        // window.location.assign("mailto:support@klartboard.dk");
    }

    // </editor-fold>
    toggleDarkMode() {
        this.prefersDark = !this.prefersDark;
        document.body.classList.toggle('dark-theme', this.prefersDark);
        document.body.classList.toggle('light-theme', !this.prefersDark);
        this.localStorageService.set(System.CookieTheme, this.prefersDark);
    }

    toggleMobile() {

        this.isMobile = !this.isMobile;
        document.body.classList.toggle('mobile-theme', this.isMobile);
        this.localStorageService.set(System.CookieMobile, this.isMobile);
        console.log('toggleMobile() : ', this.isMobile, 'cookie : ', this.localStorageService.get(System.CookieMobile))
    }

    setMobile(b: boolean) {
        this.isMobile = b;
        document.body.classList.toggle('mobile-theme', this.isMobile);
        this.localStorageService.set(System.CookieMobile, this.isMobile);
    }
}
