import {Injectable} from '@angular/core';
import {ConfirmationDialogComponent} from '@app/shared/_modals/confirmation-dialog/confirmation-dialog.component';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {User} from '@app/core/models/User';
import {
    MoveMilestoneDeadlineDialogComponent
} from '@app/shared/_modals/move-milestone-deadline-dialog/move-milestone-deadline-dialog.component';
import {Project} from '@app/core/models/Project';
import {
    ChangeProjectTypeDialogComponent
} from '@app/shared/_modals/change-project-type-dialog/change-project-type-dialog.component';
import {TranslateService} from "@ngx-translate/core";
import {
    MoveProjectDeadlineDialogComponent
} from "@app/shared/_modals/move-project-deadline-dialog/move-project-deadline-dialog.component";
import {CopyProjectDialogComponent} from '@app/shared/_modals/copy-project-dialog/copy-project-dialog.component';
import {Milestone} from "@app/core/models/Milestone";
import {CopyMilestoneDialogComponent} from "@app/shared/_modals/copy-milestone-dialog/copy-milestone-dialog.component";
import {Department, PhasesProject, StatusRule, Task} from "@app/core/models";
import {
    CreateStatusRuleDialogComponent
} from "@app/shared/_modals/create-status-rule-dialog/create-status-rule-dialog.component";
import {ErrorDialogComponent} from "@app/shared/_modals/error-dialog/error-dialog.component";
import {
    RichTextEditorDialogComponent
} from '@app/shared/_modals/rich-text-editor-dialog/rich-text-editor-dialog.component';
import {
    CopyProjectCasesDialogComponent
} from '@app/shared/_modals/copy-project-cases-dialog/copy-project-cases-dialog.component';
import {CaseRow} from '@app/pages/displays/display-cases/CaseRow';
import {ProjectCasesCopyResponse} from '@app/core/Api';
import {
    CreateProjectRedirectPlanningDialogComponent
} from '@app/shared/_modals/create-project-redirect-planning-dialog/create-project-redirect-planning-dialog.component';
import {
    CreateProjectTemplateDialogComponent
} from '@app/shared/_modals/create-project-template-dialog/create-project-template-dialog.component';
import {CreatePhaseDialogComponent} from '@app/shared/_modals/create-phase-dialog/create-phase-dialog.component';
import {
    MissingDepartmentsDialogComponent
} from '@app/shared/_modals/missing-departments-dialog/missing-departments-dialog.component';
import {
    MoveMilestoneTasksProjectDialogComponent
} from '@app/shared/_modals/move-milestone-tasks-project-dialog/move-milestone-tasks-project-dialog.component';
import {
    ChangeTaskTypeDialogComponent
} from '@app/shared/_modals/change-task-type-dialog/change-task-type-dialog.component';
import { DateChangeDifference } from '@app/interfaces/DateChangeDifference';
import {ProjectDeleteDialogComponent} from "@app/shared/_modals/project-delete-dialog/project-delete-dialog.component";
import {RestoreItem} from "@app/pages/lists/restore/RestoreItem";
import {RestoreItemDialogComponent} from "@app/shared/_modals/retore-item-dialog/restore-item-dialog.component";
import {
    MilestoneDeleteDialogComponent
} from "@app/shared/_modals/milestone-delete-dialog/milestone-delete-dialog.component";

interface CopyProjectCaseDialogResponse {
    caseRows: CaseRow[],
    copyResponse: ProjectCasesCopyResponse
}

@Injectable({
    providedIn: 'root'
})
export class BaseDialogService {

    public static DefaultOptions: NgbModalOptions = {
        size: 'lg',
        windowClass: 'z-index-2000',
        centered: true,
        backdrop: 'static',
        keyboard: false,
    };

    // https://itnext.io/creating-forms-inside-modals-with-ng-bootstrap-221e4f1f5648

    constructor(private modalService: NgbModal,
                private translateService: TranslateService
    ) {
    }

    public confirm(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = this.translateService.instant('_global_cancel'),
        dialogSize: 'sm' | 'lg' | 'md' = 'sm',
        showTextInput: boolean = false,
        primaryClass: 'btn-success' | 'btn-danger' = 'btn-danger',
        escClose: boolean = true
    ): Promise<any> {
        const modalRef = this.modalService.open(
            ConfirmationDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: escClose

            });

        modalRef.componentInstance.showTextInput = showTextInput;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.primaryClass = 'btn-success';
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;
        modalRef.componentInstance.escClose = escClose;


        // https://github.com/ng-bootstrap/ng-bootstrap/issues/894
        /*
        let instance = (modalRef as any)._windowCmptRef.instance;
        setImmediate(() => {
            instance.windowClass = 'custom-show'
        })

        let fx = (modalRef as any)._removeModalElements.bind(modalRef);
        (modalRef as any)._removeModalElements = () => {
            instance.windowClass = ''
            setTimeout(fx, 250)
        }
        */


        /*
        let instance = (modalRef as any)._windowCmptRef.instance
        setImmediate(() => {
            instance.windowClass = 'custom-show'
        })

        let fx = (modalRef as any)._removeModalElements.bind(modalRef);
        (modalRef as any)._removeModalElements = () => {
            instance.windowClass = ''
            setTimeout(fx, 250)
        }
        */
        return modalRef.result;
    }

    public alert(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = null,
        dialogSize: 'sm' | 'lg' = 'sm'
    ): Promise<boolean> {
        const modalRef = this.modalService.open(
            ConfirmationDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;
        modalRef.componentInstance.primaryClass = 'btn-success';

        /*
        let instance = (modalRef as any)._windowCmptRef.instance
        setImmediate(() => {
            instance.windowClass = 'custom-show'
        })

        let fx = (modalRef as any)._removeModalElements.bind(modalRef);
        (modalRef as any)._removeModalElements = () => {
            instance.windowClass = ''
            setTimeout(fx, 250)
        }
        */
        return modalRef.result;
    }

    private errorOpened = false;


    public errorDialog(message: any,
                       stack?: any,
                       btnOkText: string = this.translateService.instant('_global_ok'),
                       btnCancelText: string = null,
                       dialogSize: 'sm' | 'lg' | 'md' = 'md',
                       description?: string,
                       callback?: (r: any) => void
    ): void {
        console.log('errorDialog : stack : ', stack);
        if (!this.errorOpened && message) {

            let showStack = true;
            if(stack && stack.error) {
                if (stack.error.error == 'invalid_token') {
                    dialogSize = 'sm';
                }
            }
            if(stack?.statusText == 'Unauthorized'){
                message = this.translateService.instant('_ui_logged_out_title');
                description = this.translateService.instant('_ui_logged_out_description');
                dialogSize = 'sm';
            }

            this.errorOpened = true;
            const dialogRef = this.modalService.open(ErrorDialogComponent, {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });


            dialogRef.componentInstance.title = 'Klartboard';
            dialogRef.componentInstance.description = description;
            dialogRef.componentInstance.message = message;
            dialogRef.componentInstance.stack = showStack && stack ? stack : null;
            dialogRef.componentInstance.btnOkText = btnOkText;
            dialogRef.componentInstance.btnCancelText = btnCancelText;

            dialogRef.closed.subscribe((r) => {
                this.errorOpened = false;
                if (callback)
                    callback(r);
            });

        }
    }

    public moveMilestoneDeadline(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = this.translateService.instant('_global_cancel'),
        dialogSize: 'sm' | 'lg' = 'sm',
        item: AnyItem,
        difference?: number,
        phasesProjectItem?: PhasesProject
    ): Promise<any> {
        const modalRef = this.modalService.open(
            MoveMilestoneDeadlineDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        modalRef.componentInstance.difference = difference;
        modalRef.componentInstance.event = event;
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.phasesProjectItem = phasesProjectItem;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;


        return modalRef.result;
    }

    public moveProjectDeadline(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = this.translateService.instant('_global_cancel'),
        dialogSize: 'sm' | 'lg' = 'sm',
        item: AnyItem,
        dateChangeDifference?: DateChangeDifference,
    ): Promise<any> {
        const modalRef = this.modalService.open(
            MoveProjectDeadlineDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        // modalRef.componentInstance.difference = difference;
        modalRef.componentInstance.dateChangeDifference = dateChangeDifference;
        modalRef.componentInstance.event = event;
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;


        return modalRef.result;
    }

    public moveMilestoneTasksProject(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = this.translateService.instant('_global_cancel'),
        dialogSize: 'sm' | 'lg' = 'lg',
        item: Milestone,
        tasks: Task[],
        removedProject: Project,
        addedProject: Project,
        availableProjects: Project[],
        action: string
    ): Promise<any> {
        const modalRef = this.modalService.open(
            MoveMilestoneTasksProjectDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });

        modalRef.componentInstance.item = item;
        modalRef.componentInstance.milestone = item;
        modalRef.componentInstance.tasks = tasks;
        modalRef.componentInstance.removedProject = removedProject;
        modalRef.componentInstance.addedProject = addedProject;
        modalRef.componentInstance.availableProjects = availableProjects;
        modalRef.componentInstance.action = action;
        modalRef.componentInstance.event = event;

        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;


        return modalRef.result;
    }

    public changeProjectType(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = this.translateService.instant('_global_cancel'),
        dialogSize: 'sm' | 'lg' = 'sm',
        item: Project
    ): Promise<any> {
        const modalRef = this.modalService.open(
            ChangeProjectTypeDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;


        return modalRef.result;
    }

    public changeTaskType(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = this.translateService.instant('_global_cancel'),
        dialogSize: 'sm' | 'lg' = 'sm',
        item: Task
    ): Promise<any> {
        const modalRef = this.modalService.open(
            ChangeTaskTypeDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;


        return modalRef.result;
    }


    public copyProject(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = this.translateService.instant('_global_cancel'),
        dialogSize: 'sm' | 'lg' = 'sm',
        item: Project
    ): Promise<any> {
        const modalRef = this.modalService.open(
            CopyProjectDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;


        return modalRef.result;
    }

    public copyMilestone(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = this.translateService.instant('_global_cancel'),
        dialogSize: 'sm' | 'lg' = 'sm',
        item: Milestone
    ): Promise<any> {
        const modalRef = this.modalService.open(
            CopyMilestoneDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;


        return modalRef.result;
    }

    public createStatusRuleDialog(): Promise<StatusRule> {
        const modalRef = this.modalService.open(
            CreateStatusRuleDialogComponent,
            {
                size: 'lg',
                windowClass: 'z-index-2000',
                centered: true,
                backdrop: 'static',
                keyboard: true
            });

        modalRef.componentInstance.event = event;
        modalRef.componentInstance.btnOkText = this.translateService.instant('_global_ok');
        modalRef.componentInstance.btnCancelText = this.translateService.instant('_global_cancel');
        return modalRef.result;
    }


    public richTextEditor(value: string): Promise<any> {
        const modalRef = this.modalService.open(
            RichTextEditorDialogComponent,
            {
                size: 'lg',
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        modalRef.componentInstance.btnOkText = this.translateService.instant('_global_ok');
        modalRef.componentInstance.btnCancelText = this.translateService.instant('_global_cancel');
        modalRef.componentInstance.text = value;

        return modalRef.result;
    }


    public createProjectRedirectPlanningDialog(project: Project): Promise<any> {
        const modalRef = this.modalService.open(
            CreateProjectRedirectPlanningDialogComponent,
            {
                size: 'md',
                windowClass: 'z-index-2000',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        modalRef.componentInstance.btnOkText = this.translateService.instant('_global_ok');
        modalRef.componentInstance.btnCancelText = this.translateService.instant('_global_cancel');
        modalRef.componentInstance.model = project;

        return modalRef.result;
    }

    public createProjectRedirectTemplateDialog(project: Project): Promise<any> {
        const modalRef = this.modalService.open(
            CreateProjectTemplateDialogComponent,
            {
                size: 'md',
                windowClass: 'z-index-2000',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        modalRef.componentInstance.btnOkText = this.translateService.instant('_global_ok');
        modalRef.componentInstance.btnCancelText = this.translateService.instant('_global_cancel');
        modalRef.componentInstance.model = project;

        return modalRef.result;
    }


    public copyProjectCaseDialog(caseRows: CaseRow[], department: Department, start: string, end: string): Promise<CopyProjectCaseDialogResponse> {
        const modalRef = this.modalService.open(
            CopyProjectCasesDialogComponent,
            {
                size: 'md',
                windowClass: 'z-index-2000',
                centered: true,
                backdrop: 'static',
                keyboard: true
            });

        modalRef.componentInstance.title = this.translateService.instant('_ui_transfer_items_from_last_week_title');
        modalRef.componentInstance.start = start;
        modalRef.componentInstance.end = end;
        modalRef.componentInstance.department = department;
        modalRef.componentInstance.caseRows = caseRows;
        modalRef.componentInstance.event = event;
        modalRef.componentInstance.btnOkText = this.translateService.instant('_global_ok');
        modalRef.componentInstance.btnCancelText = this.translateService.instant('_global_cancel');
        return modalRef.result;
    }


    public createPhaseDialog(departments: Department[], projectTypeIDs: number[]): Promise<any> {
        const modalRef = this.modalService.open(
            CreatePhaseDialogComponent,
            {
                size: 'md',
                windowClass: '',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        modalRef.componentInstance.btnOkText = this.translateService.instant('_global_ok');
        modalRef.componentInstance.btnCancelText = this.translateService.instant('_global_cancel');
        modalRef.componentInstance.departments = departments;
        modalRef.componentInstance.projectTypeIDs = projectTypeIDs;

        return modalRef.result;
    }

    public missingDepartmentsDialog(departments: Department[],
                                    user: User
    ): Promise<any> {
        const modalRef = this.modalService.open(
            MissingDepartmentsDialogComponent,
            {
                size: 'md',
                windowClass: '',
                centered: true,
                backdrop: 'static',
                keyboard: true

            });
        modalRef.componentInstance.btnOkText = this.translateService.instant('_global_ok');
        modalRef.componentInstance.btnCancelText = this.translateService.instant('_global_cancel');
        modalRef.componentInstance.departments = departments;
        modalRef.componentInstance.user = user;

        // this.translateService.instant(`_ui_add_department`),
        //     this.translateService.instant('_ui_add_department_from_user', {user: user.first_name}),
        //     this.translateService.instant('_global_yes'),
        //     this.translateService.instant('_global_no'),

        return modalRef.result;
    }

    public deleteProject(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = this.translateService.instant('_global_cancel'),
        dialogSize: 'sm' | 'lg' = 'sm',
        item: Project
    ): Promise<any> {
        const modalRef = this.modalService.open(
            ProjectDeleteDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true
            });
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;
        return modalRef.result;
    }

    public deleteMilestone(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = this.translateService.instant('_global_cancel'),
        dialogSize: 'sm' | 'lg' = 'sm',
        item: Milestone
    ): Promise<any> {
        const modalRef = this.modalService.open(
            MilestoneDeleteDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true
            });
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;
        return modalRef.result;
    }

    public restoreItem(
        title: string,
        message: string,
        btnOkText: string = this.translateService.instant('_global_ok'),
        btnCancelText: string = this.translateService.instant('_global_cancel'),
        dialogSize: 'sm' | 'lg' = 'sm',
        item: RestoreItem
    ): Promise<any> {
        const modalRef = this.modalService.open(
            RestoreItemDialogComponent,
            {
                size: dialogSize,
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true
            });
        modalRef.componentInstance.item = item;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;
        return modalRef.result;
    }

}
