import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PhaseMilestoneRow} from "@app/shared/_elements/process-steps/process-steps.component";
import {
    Milestone,
    Phase,
    PhaseProgressType,
    PhaseProgressTypesPhase,
    PhasesProject,
    Project,
    Task
} from "@app/core/models";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {StatusTypes} from "@app/constants";
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {Api} from '@app/core/Api';
import Helpers from '@app/core/helpers';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {MilestonesService} from '@app/services/milestones.service';
import moment from 'moment';
import {
    SmallCardMilestoneConfiguration
} from "@app/shared/_ui/cards/small/small-card-milestone/small-card-milestone-configuration";
import {CreateItemSourceConfiguration} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration";
import {CreateItemPreset} from "@app/shared/_ui/create-item-dropdown/CreateItemPreset";
import {CreateItemSourceInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceInterface";
import {CreateItemInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemInterface";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {
    MilestoneProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneProjectPresetGenerator";
import {
    MilestoneDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneDeadlinePresetGenerator";
import {AppInjector} from "@app/services/app-injector.service";
import {SnackbarService} from "@app/services/snackbar.service";

@Component({
    selector: 'app-project-phase',
    templateUrl: './project-phase.component.html',
    styleUrls: ['./project-phase.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class ProjectPhaseComponent extends BaseDisplayComponent implements OnInit {

    // Bindings to parent
    @Input() phaseMilestoneRow: PhaseMilestoneRow;
    @Input() project: Project;

    @Output() selectPhasesProject = new EventEmitter<PhasesProject>();
    @Output() selectPhasesProjectProgressType: EventEmitter<{
        phaseProgressType: PhaseProgressType,
        phasesProject: PhasesProject
    }> = new EventEmitter();
    @Output() toggleTaskArchived: EventEmitter<{
        task: Task,
        cardMilestone: CardItem<Milestone>,
        phaseMilestoneRow: PhaseMilestoneRow
    }> = new EventEmitter();
    @Output() toggleAllTasksArchived: EventEmitter<{
        cardMilestone: CardItem<Milestone>,
        phaseMilestoneRow: PhaseMilestoneRow
    }> = new EventEmitter();

    // Bindings to view
    public cardMilestoneConfiguration = new SmallCardMilestoneConfiguration();
    public createItemSourceConfiguration = new CreateItemSourceConfiguration();
    public createItemPreset = new CreateItemPreset();

    constructor(private dialogService: BaseDialogService,
                private editorPanelService: EditorPanelService,
                private milestoneService: MilestonesService) {
        super();
    }

    ngOnInit(): void {
        if (this.phaseMilestoneRow) {
            Phase.GetSingle(this.phaseMilestoneRow.phasesProject.phase_id, (phase: Phase) => {
            })
        }

        const thisClass = this;
        this.createItemSourceConfiguration.sourceInterface = new class implements CreateItemSourceInterface {
            prepareSource() {
                thisClass.createItemSourceConfiguration.showTasks = false;
                thisClass.createItemSourceConfiguration.showMilestone = true;
                thisClass.createItemSourceConfiguration.showProjects = false;
                thisClass.createItemSourceConfiguration.showTodo = false;
                thisClass.createItemSourceConfiguration.showAppointments = false;
            }
        };
        this.createItemPreset.createMilestoneInterface = new class implements CreateItemInterface<Milestone> {
            createPresets(options?: any): CreatePreset[] {
                return [
                    new MilestoneUseStatusRulesPresetGenerator(true),
                    new MilestoneStatusPresetGenerator(StatusTypes.GREEN),
                    new MilestoneProjectPresetGenerator(thisClass.project.id),
                    ...thisClass.phaseMilestoneRow.phasesProject?.getStartedDate()
                        ? [new MilestoneDeadlinePresetGenerator(thisClass.phaseMilestoneRow.phasesProject.getStartedDate(), false)]
                        : [],
                ].map(generator => generator.generate());
            }
        };
    }

    phasesExist(phase_progress_types_phases: PhaseProgressTypesPhase[] | undefined) {
        return phase_progress_types_phases.filter(pptp => pptp.visible).length > 0;
    }

    deleteMilestone(cardMilestone: CardItem<Milestone>) {
        const snackbar = AppInjector.getInjector().get(SnackbarService);
        this.dialogService.deleteMilestone(
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_ui_delete_item', {name: cardMilestone.item.title}),
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_global_cancel'),
            'sm',
            cardMilestone.item
        )
            .then((confirmed) => {
                if (confirmed) {
                    this.phaseMilestoneRow.milestones = this.phaseMilestoneRow.milestones.filter(mp => mp.card.item.id !== cardMilestone.item.id);
                    cardMilestone.item.delete(confirmed.cascade, () => {
                        snackbar.add(this.translateService.instant('_ui_item_deleted', {item: cardMilestone.item.name}));
                    });
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    updatePhasesProjectDate($event: Date, phaseMilestoneRow: PhaseMilestoneRow) {
        // Move other deadlines
        const milestone: Milestone = this.project.milestones.filter((m) => {
            return m.archived_id == 0 && m.deadline?.getDate() >= $event
        })[0];
        if (milestone && phaseMilestoneRow?.phasesProject) {
            let days: number = (moment(moment($event)).diff(phaseMilestoneRow?.phasesProject?.start, 'days'));
            console.log('move x days : ', $event, phaseMilestoneRow.phasesProject.start, days)
            if ($event && !isNaN(days) && days != 0) {
                // Udlæs "følgende milepæle" - og spørg om de også skal skifte dato
                this.dialogService.moveMilestoneDeadline(
                    this.translateService.instant(`_milestone_move_deadlines`),
                    ``,
                    this.translateService.instant('_global_ok'),
                    this.translateService.instant('_global_cancel'),
                    'lg',
                    milestone,
                    days,
                    phaseMilestoneRow.phasesProject
                )
                    .then((confirmed) => {
                        // Sætter deadline for alle opgaver (sættes i move-milestone-deadline-dialog.component.ts)
                        if (confirmed) {
                        }
                    })
                    .catch(() => {
                        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
                    });
            }
        }


        phaseMilestoneRow.phasesProject.start = Helpers.serverDate($event);
        Api.projects().phasesProjectUpdateStartPutByProjectIdByPhasesProjectId(this.project.id, phaseMilestoneRow.phasesProject.id)
            .start(phaseMilestoneRow.phasesProject.start)
            .save(null, (phase) => {
                console.log('updatePhasesProjectDate : ', phase)
            })
    }

    editTask(task: Task) {
        this.editorPanelService.open(task)
    }

    deleteTask(task: Task, phaseMilestoneRow: PhaseMilestoneRow, cardMilestone: CardItem<Milestone>) {
        this.dialogService.confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant(`_ui_delete_item`, {name: task.title}),
            this.translateService.instant('_global_delete'),
        )
            .then((confirmed) => {
                if (confirmed) {
                    cardMilestone.item.tasks = cardMilestone.item.tasks.filter(t => t.id != task.id);
                    Api.tasks().deleteById(task.id).delete(() => {
                        this.detectChanges();
                    });
                }

            })

    }

    deletePhaseMilestoneRow(phaseMilestoneRow: PhaseMilestoneRow) {
        this.dialogService.confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant(`_ui_delete_item`, {name: phaseMilestoneRow.phasesProject?.phase?.name}),
            this.translateService.instant('_global_delete'),
        )
            .then((confirmed) => {
                if (confirmed) {
                    Api.projects().phasesProjectDeleteDeleteByProjectIdByPhasesProjectId(this.project.id, phaseMilestoneRow.phasesProject.id).delete((response) => {
                        console.log('deleted : ', response);
                    })
                }

            })
    }
}
