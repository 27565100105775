import {inject} from "@angular/core";
import {OAuthService} from "angular-oauth2-oidc";
import Helpers from "@app/core/helpers";
import {AuthService} from "../../../services/auth.service";
import { RxStompService } from "./rx-stomp.service";

export function rxStompServiceFactory() {
    const oAuthService = inject(OAuthService);
    const authService = inject(AuthService);

    const rxStomp = new RxStompService();

    const connectHeaders = {
        login: 'ignored',
        passcode: '', // Added in beforeConnect
        host: 'realm1',
    };

    rxStomp.configure({

        // Which server?
        brokerURL: Helpers.socketUrl(),

        beforeConnect: async client => {
            if (authService.hasValidAccessToken()) {
                connectHeaders.passcode = oAuthService.getAccessToken();
                return Promise.resolve();
            } else {
                await authService.refreshToken();
                connectHeaders.passcode = oAuthService.getAccessToken();
            }
        },

        // Headers
        // Typical keys: login, passcode, host
        connectHeaders: connectHeaders,

        // How often to heartbeat?
        // Interval in milliseconds, set to 0 to disable
        heartbeatIncoming: 0, // Typical value 0 - disabled
        heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds

        // Wait in milliseconds before attempting auto reconnect
        // Set to 0 to disable
        // Typical value 500 (500 milliseconds)
        reconnectDelay: 5000,

        // Will log diagnostics on console
        // It can be quite verbose, not recommended in production
        // Skip this key to stop logging to console
        debug: (msg: string): void => {
            // console.warn(msg);
        },

    });

    rxStomp.activate();

    return rxStomp;
}
