import {Injectable, NgZone} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {finalize} from "rxjs/operators";
import {CoreService} from "../../../services/core.service";
import {NgProgress, NgProgressRef} from "ngx-progressbar";
import Helpers from "@app/core/helpers";
import {OAuthService} from "angular-oauth2-oidc";

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

    private progressRef: NgProgressRef;
    private inProgressCount = 0;
    private progressStarted = false;
    private progressRefCompleted?: Date;

    pendingRequestsCount = 0;

    constructor(ngProgress: NgProgress,
                private ngZone: NgZone,
                private oauthService: OAuthService,
                private coreService: CoreService) {
        this.progressRef = ngProgress.ref('root');

        this.progressRef.state.subscribe(state => {
            if (state.value == 0 && this.progressRefCompleted) {
                // console.warn('ProgressBar finished with delay', (new Date()).getTime() - this.progressRefCompleted.getTime(), 'ms');
            }
        });
    }

    public static getApiUrl(append?: string): string {
        const host = window.location.origin.split('://')[1];
        const isLocalHost = host === 'localhost:4300';

        let url: string;

        if (isLocalHost) {
            url = environment.serverUrl;
        } else {
            url = host;
        }

        if (environment.environment != 'Local network') {
            url = Helpers.apiUrl();
        }

        if (append) {
            url += append;
        }

        return url
            .replace('//displays', '/displays')
            .replace('//phase_progress_types', '/phase_progress_types')
            .replace('//sites', '/sites')
            .replace('//loads', '/loads')
            .replace('//department', '/department')
            .replace('//project', '/project');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isOauthRequest: boolean = request.url.indexOf(this.oauthService.issuer) !== -1;

        this.pendingRequestsCount++;
        this.coreService.addRequest();

        if (isOauthRequest) {
            request = request.clone({withCredentials: true}); // Set to save token on /token endpoint
            return next.handle(request).pipe(finalize(() => {
                this.pendingRequestsCount--;
                this.coreService.completeRequest();
            }));
        } else {

            request = request.clone({url: ApiPrefixInterceptor.getApiUrl(request.url)});

            if (request.url.indexOf('language_values') !== -1) {
                return next.handle(request).pipe(finalize(() => {
                    this.pendingRequestsCount--;
                    this.coreService.completeRequest();
                }));
            }

            if (request.headers.has('ignoreProgressBar')) {
                request.headers.delete('ignoreProgressBar');
                return next.handle(request).pipe(finalize(() => {
                    this.pendingRequestsCount--;
                    this.coreService.completeRequest();
                }));
            }

            this.inProgressCount++;
            if (!this.progressStarted) {
                this.progressStarted = true;
                this.ngZone.run(_ => {
                    if (!this.progressRef.isStarted) {
                        // console.warn('http intercept start');
                        this.progressRef.start();
                    }
                });
            }

            return next.handle(request).pipe(
                finalize(() => {
                    this.finalize();
                }),
            );
        }
    }

    private finalizeDelay?: Date;
    private finalize() {
        this.inProgressCount--;
        this.pendingRequestsCount--;

        this.coreService.completeRequest();
        if (this.inProgressCount === 0) {
            this.finalizeDelay = new Date();
            setTimeout(() => {
                // console.warn('http intercept finalized', (new Date()).getTime() - this.finalizeDelay.getTime(), 'ms');
                this.progressStarted = false;
                this.progressRefCompleted = new Date();
                this.progressRef.complete();
            });
        }
    }
}
