import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    SoftNextWeekTableColumn
} from "@app/pages/displays/dashboard/dashboard-calendar/TableColumns/SoftNextWeekTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/dashboard/dashboard-calendar/ColumnTypeSettings";
import {
    UserOverviewCalendar_SoftNextWeek_IncludeTaskTypesSettingValue,
} from "@app/core/Api";

export class SoftNextWeekColumn extends BaseColumn {

    public createTableColumns(): BaseTableColumn[] {
        return [
            new SoftNextWeekTableColumn(this),
        ];
    }

    public createCell(row: BaseRow): BaseCell {
        throw new Error("Method not implemented.");
    }

    public getIncludeTaskTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.SoftNextWeek_IncludeTaskTypes)
            ?.getObject<UserOverviewCalendar_SoftNextWeek_IncludeTaskTypesSettingValue>()
            ?.taskTypeIds ?? [];
    }

}
