import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";


@Component({
    selector: 'app-base-form-control',
    templateUrl: './base-form-control.component.html',
    styleUrls: ['./base-form-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseFormControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => BaseFormControlComponent),
            multi: true,
        }
    ],
    standalone: false
})
export class BaseFormControlComponent extends BaseDisplayComponent implements OnInit, ControlValueAccessor {

    // https://netbasal.com/angular-custom-form-controls-made-easy-4f963341c8e2
    // https://stackblitz.com/edit/angular-custom-formcontrols?file=src%2Fapp%2Fjson-input.component.ts

    @Input()
    protected _internalValue: any = null;

    @Input()
    parentGroup: UntypedFormGroup = null;

    @Input()
    disabled = false;

    protected parseError: boolean;
    protected baseValue: any = null;
    private data: any;

    constructor() {
        super();
    }

    ngOnInit() {
    }

    // Form control stuff
    public registerOnChange(fn: any) {
        //log.debug('registerOnChange() : ', fn);
        this.onChange = fn;
    }

    public onChange: any = (event: any) => { /*Empty*/

        //let newValue = event.target.value;
        this.parseError = typeof this.internalValue === typeof this.baseValue;

        try {
            /*
            let newValue = event.target.value;
            this.parseError = typeof newValue === typeof this.baseValue;
             */
            // parse it to json

            this.parseError = false;
        } catch (ex) {
            // set parse error if it fails
            this.parseError = true;
        }


    };
    public onTouched: any = () => { /*Empty*/
    };

    get internalValues(){
        return this._internalValue;
    }
    @Input() set internalValues(value:any){
        this.internalValue = value;
        this.internalValueUpdated();
    }

    protected get internalValue() {
        return this._internalValue;
    }

    @Input() protected set internalValue(val: any) {
        if (val === undefined || val === null || val === '') {
            this._internalValue = null;
            this.onChange(undefined); // Value is rubbish, so don't send back to model propper
            return;
        }

        this._internalValue = val;
        this.onChange(this._internalValue); // Value is good!
    }

    // this is the initial value set to the component
    public writeValue(obj: any) {
        //log.debug('writeValue() : ', obj, this.baseValue);
        if (obj !== null) {
            this.internalValue = obj;
        } else {
            this.internalValue = this.baseValue;
        }
        this.internalValueUpdated();
    }


    // validates the form, returns null when valid else the validation object
    // in this case we're checking if the json parsing has passed or failed from the onChange method
    public validate(c: UntypedFormControl) {
        return (!this.parseError) ? null : {
            jsonParseError: {
                valid: false,
            },
        };
    }

    // not used, used for touch input
    public registerOnTouched() {

    }

    // the method set in registerOnChange to emit changes back to the form
    protected propagateChange = (_: any) => {

    };

    ngOnChanges(changes: any): void {
        this.propagateChange(this.internalValue);
        this.internalValueUpdated();
        /*
        if (changes["options"] && this.areas) {
            this.options.subscribe(val => this.optionsAsValue = val);
        }
        */
    }

    internalValueUpdated() {
        // Override / overskriv
        // console.log('internalValueUpdated() : super() : ', this.internalValue);
    }
}
