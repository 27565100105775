import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {
    Projects_ProjectStatusTypesSettingValue,
} from "@app/core/Api";
import {ProjectStatusListCell} from "@app/pages/displays/display-projects/Cells/ProjectStatusListCell";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVColumn} from "@app/export/csv/CSVColumn";
import {CountRunner} from "@app/core/CountRunner/CountRunner";
import {AppInjector} from "@app/services/app-injector.service";
import {ProjectsService} from "@app/services/projects.service";
import {TranslateService} from "@ngx-translate/core";

export class ProjectStatusListColumn extends GenericColumn<ProjectStatusListCell> {

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectStatusListCell(row, this);
    }

    public getProjectStatusTypeIds(): number[] {
        return this.settings.get(ColumnTypeSettings.ProjectStatusTypes)
            ?.getObject<Projects_ProjectStatusTypesSettingValue>()
            ?.projectStatusTypeIds ?? [];
    }

    public implementsCSVExport = true;

    public toCSVColumn(result: (column: CSVColumn) => void): void {
        const column = CSVColumn.CreatePrimary(this, []);

        const countRunner = new CountRunner(this.getProjectStatusTypeIds().length);
        countRunner.setRunner(() => result(column));
        const projectsService = AppInjector.getInjector().get(ProjectsService);
        this.getProjectStatusTypeIds().forEach(projectStatusTypeId => {
            projectsService.getProjectStatusType(projectStatusTypeId, projectUserType => {
                column.subColumns.push(CSVColumn.CreateSecondary(
                    projectStatusTypeId,
                    AppInjector.getInjector().get(TranslateService).instant(projectUserType.name)
                ));
                countRunner.tick();
            });
        });
        countRunner.start();
    }

}
