import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {BaseDisplayComponent} from '../../_system/base-display/base-display.component';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {Project} from '@app/core/models/Project';
import {Roster, Task} from '@app/core/models/Task';
import {EventService} from '@app/services/event.service';
import {User} from '@app/core/models/User';
import {AppInjector} from '@app/services/app-injector.service';
import {UsersService} from '@app/services/users.service';
import {Favorite} from '@app/core/models/Favorite';
import {Milestone} from '@app/core/models/Milestone';
import {PlacementArray} from '@app/directives/positioning';
import {BaseApi} from "@app/core/BaseApi";
import {HandUp} from "@app/core/models";
import {Api} from "@app/core/Api";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";

@Component({
    selector: 'app-star-selector',
    templateUrl: './star-selector.component.html',
    styleUrls: ['./star-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StarSelectorComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() model: AnyItem; // TO-DO Refactor til EditStarred (Interface)
    @Input() showUsers = true;
    @Input() placement: PlacementArray = ['bottom', 'right', 'left', 'auto'];
    @Input() tooltipPlacement: PlacementArray = ['left', 'bottom', 'right', 'auto'];
    @Output() onToggle: EventEmitter<any> = new EventEmitter<any>();

    public stars: Favorite[] = [];
    private activated: boolean = false;
    public active: boolean = false;
    // Hvilke brugere skal kunne vælges?
    public user: User;

    constructor(private eventService: EventService,
                private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.subscribe(this.eventService.subscribe(this.model, (event) => {
            switch (event.action) {
                case EventService.Created:
                case EventService.Updated:
                case EventService.Deleted:
                    // this.stars = [];

                    if (event.action == EventService.Deleted && this.stars) {
                        this.stars = this.stars.filter(s => s.id != event.item.id);
                    }
                    this.detectChanges();
                    this.updateActive();
                    break;
            }
        }));
        this.user = AppInjector.getInjector().get(UsersService).user;
    }

    public clickEvent() {
        if (!this.showUsers && this.user) {
            console.log('Toggle user : ', this.activated, this.user);
            this.toggleActive(!(this.stars.filter(s => s.user_id = this.user.id).length > 0), this.stars.find(s => s.user_id = this.user.id));
        }

    }

    private updateActive() {
        if (this.stars) {
            const found: Favorite = this.stars.find((hu: Favorite) => {
                return hu.user.id == this.user.id;
            });
            this.active = !!found;
        } else {
            this.active = false;
        }
        this.cd.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['model']) {
        }
    }

    private apiLoader?: ApiRequest;

    private loadData() {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;

        this.apiLoader?.cancel();

        let api: BaseApi<Favorite>;
        switch (this.model.constructor) {
            case Project:
                api = Api.projects().starGetByProjectId(this.model.id)
                break;
            case Task:
            case Roster:
                api = Api.tasks().starGetByTaskId(this.model.id)
                break;
            case Milestone:
                api = Api.milestones().starGetByMilestoneId(this.model.id)
                break;
        }

        if (api) {
            this.apiLoader = api.orderBy('created', 'desc')
                .find(handsUp => {
                    this.stars = this.stars.concat(handsUp.filter((ha: HandUp) => {
                        return ha.user_id != 0;
                    }));
                    this.isLoading = false;
                    this.updateActive();
                    this.clickEvent();
                });
        } else {
            this.isLoading = false;
        }
    }

    public preventPropagation(mouseEvent: MouseEvent) {
        this.activated = !this.activated;
        if (this.activated && this.stars.length == 0) {
            this.loadData();
        } else {
            this.clickEvent();
        }
        mouseEvent.stopPropagation();
    }

    toggleActive(active: boolean, fav: Favorite) {
        this.onToggle.emit({active, fav});
        this.detectChanges();
        if (!fav) {
            fav = new Favorite({user_id: this.user.id});
        }

        switch (this.model.constructor.name) {
            case Project.name:
                if (!active) {
                    Api.projects().starDeleteByProjectId(this.model.id!)
                        .user_id(fav.user_id)
                        .delete(value => {
                            (this.model as Project).num_stars--;
                            this.eventService.emitProject(this.model as Project, EventService.Updated, ['num_stars']);
                            this.detectChanges();
                        });
                } else {
                    fav.project_id = this.model.id;
                    Api.projects().starPutByProjectId(this.model.id!)
                        .user_id(fav.user_id)
                        .save(null, value => {
                            (this.model as Project).num_stars++;
                            this.eventService.emitProject(this.model as Project, EventService.Updated, ['num_stars']);
                            this.detectChanges();
                        });
                }
                break;
            case Task.name:
            case Roster.name:
                if (!active) {
                    Api.tasks().starDeleteByTaskId(this.model.id!)
                        .user_id(fav.user_id)
                        .delete(value => {
                            (this.model as Task).num_stars--;
                            this.eventService.emitTask(this.model as Task, EventService.Updated, ['num_stars']);
                            this.detectChanges();
                        });
                } else {
                    fav.task_id = this.model.id;
                    Api.tasks().starPutByTaskId(this.model.id!)
                        .user_id(fav.user_id)
                        .save(null, value => {
                            (this.model as Task).num_stars++;
                            this.eventService.emitTask(this.model as Task, EventService.Updated, ['num_stars']);
                            this.detectChanges();
                        });
                }
                break;
            case Milestone.name:
                if (!active) {
                    Api.milestones().starDeleteByMilestoneId(this.model.id!)
                        .user_id(fav.user_id)
                        .delete(value => {
                            (this.model as Milestone).num_stars--;
                            this.eventService.emitMilestone(this.model as Milestone, EventService.Updated, ['num_stars']);
                            this.detectChanges();
                        });
                } else {
                    fav.milestone_id = this.model.id;
                    Api.milestones().starPutByMilestoneId(this.model.id!)
                        .user_id(fav.user_id)
                        .save(null, value => {
                            (this.model as Milestone).num_stars++;
                            this.eventService.emitMilestone(this.model as Milestone, EventService.Updated, ['num_stars']);
                            this.detectChanges();
                        });
                }
                break;
        }
    }
}
