import {ProjectsDeadline} from '@app/core/models/ProjectsDeadline';
import {Project} from '@app/core/models/Project';
import {EventEmitter} from '@angular/core';
import {YearWheelFetcherRequest} from '@app/shared/_ui/columns/year-wheel/Helpers/YearWheelFetcher';
import {Milestone} from '@app/core/models/Milestone';
import {PhasesProject} from '@app/core/models/PhasesProject';
import {PeriodUnits} from "@app/constants";
import moment from 'moment';

export interface YearWheelDisplayOptions {
    showProject: boolean;
    showPhase: boolean;
    showMilestone: boolean;
    showTask: boolean;
}

export class YearWheelColumnConfiguration {

    public periodUnit:string = PeriodUnits.Weeks;

    public displayOptions:YearWheelDisplayOptions = {showProject: true, showPhase: true, showMilestone: true, showTask: true};

    public periodStart: Date = moment().startOf('month').toDate();   /* Periode konfiguration tages fra filterindstillinger */
    public periodEnd: Date = moment().endOf('month').toDate();     /* Periode konfiguration tages fra filterindstillinger */

    public projectStartDeadline: ProjectsDeadline;
    public projectEndDeadline: ProjectsDeadline;
    public project: Project;
    public milestone: Milestone;
    public autoPeriod = false;

    public scrollPosition = {top: 0, left: 0};
    public onResizeEvent: EventEmitter<{ top: number, left: number }> = new EventEmitter<{ top: number, left: number }>();

    public dataSource: YearWheelFetcherRequest;
    public onScrollEvent: EventEmitter<YearWheelColumnConfiguration> = new EventEmitter<YearWheelColumnConfiguration>();
    public onUpdateEvent: EventEmitter<YearWheelColumnConfiguration> = new EventEmitter<YearWheelColumnConfiguration>();

    public setDisplayOptions(value: YearWheelDisplayOptions ): YearWheelColumnConfiguration {
        this.displayOptions = value;
        return this;
    }

    public setStart(value: Date): YearWheelColumnConfiguration {
        this.periodStart = value;
        return this;
    }

    public setAutoPeriod(value: boolean): YearWheelColumnConfiguration {
        this.autoPeriod = value;
        return this;
    }

    public setPeriodUnit(value: string): YearWheelColumnConfiguration {
        this.periodUnit = value;
        return this;
    }

    public setEnd(value: Date): YearWheelColumnConfiguration {
        this.periodEnd = value;
        return this;
    }

    public setProjectStart(value: ProjectsDeadline): YearWheelColumnConfiguration {
        this.projectStartDeadline = value;
        return this;
    }

    public setProjectEnd(value: ProjectsDeadline): YearWheelColumnConfiguration {
        this.projectEndDeadline = value;
        return this;
    }

    public setScrollPosition(position: { top: number; left: number }, trigger: boolean = true): YearWheelColumnConfiguration {
        this.scrollPosition = position;
        if (trigger) {
            this.onScrollEvent.emit(this);
        } else {
            this.onUpdateEvent.emit(this);
        }
        return this;
    }

    public updateScrollPosition(config: YearWheelColumnConfiguration) {
        this.scrollPosition = config.scrollPosition;
        this.onUpdateEvent.emit(this);
    }

    public setProject(project: Project): YearWheelColumnConfiguration {
        this.project = project;
        return this;
    }

    public setMilestone(milestone: Milestone): YearWheelColumnConfiguration {
        this.milestone = milestone;
        return this;
    }

    public validate(): boolean {
        return this.project !== null
            && this.periodStart !== null
            && this.periodEnd !== null
            && this.periodStart.getTime() < this.periodEnd.getTime();
    }

    public validateMilestone(milestone: Milestone): boolean {
        return milestone.projects.find(project => project.id == this.project.id) != null;
    }

    public validatePhasesProject(phasesProject: PhasesProject): boolean {
        return phasesProject.project_id == this.project.id;
    }

    public setDataSource(value: YearWheelFetcherRequest): YearWheelColumnConfiguration {
        this.dataSource = value;
        return this;
    }
}
