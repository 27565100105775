import {PageComponent} from "@app/pages/page.component";
import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from "@angular/core";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-users/Filters";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-users/Row";
import {DatatableComponent} from "@swimlane/ngx-datatable";
import {Deadline, Project, Task, User} from "@app/core/models";
import {ComponentVisibilityObserver} from "@app/shared/_system/base/ComponentVisibilityObserver";
import {Api} from "@app/core/Api";
import {
    Events,
    ProjectUserTypes,
    TaskDeadlineTypes,
    TaskUserTypes,
} from "@app/constants";
import {EditTitleConfiguration} from "@app/editor/quick-editor/editors/generic/title-editor/EditTitleConfiguration";
import {TitleEditorComponent} from "@app/editor/quick-editor/editors/generic/title-editor/title-editor.component";
import {EditTextConfiguration} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextConfiguration";
import {TextEditorComponent} from "@app/editor/quick-editor/editors/generic/text-editor/text-editor.component";
import {EditLinkConfiguration} from "@app/editor/quick-editor/editors/generic/link-editor/EditLinkConfiguration";
import {LinkEditorComponent} from "@app/editor/quick-editor/editors/generic/link-editor/link-editor.component";
import {
    EditWhoWhenConfiguration
} from "@app/editor/quick-editor/editors/generic/who-when-editor/EditWhoWhenConfiguration";
import {
    WhoWhenEditorComponent
} from "@app/editor/quick-editor/editors/generic/who-when-editor/who-when-editor.component";
import {ProjectsService} from "@app/services/projects.service";
import {
    NonArchivedFilter as MilestoneNonArchivedFilter
} from "@app/shared/_ui/lists/milestone-list/OnScreenFilters/NonArchivedFilter";
import {
    UsersWithTasks
} from "@app/pages/displays/display-project-details/subdisplay-users/OnScreenFilters/UsersWithTasks";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {EventService} from "@app/services/event.service";
import {
    NonArchivedFilter
} from "@app/pages/displays/display-project-details/subdisplay-users/OnScreenFilters/NonArchivedFilter";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {ScreenshotHelper} from "@app/core/ScreenshotHelper/ScreenshotHelper";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDeadlinePresetGenerator";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {TemplateTypes} from "@app/pages/displays/display-project-details/subdisplay-users/TemplateTypes";
import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns/GenericTableColumn";
import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/TaskEditColumn";
import {ColumnTypes} from "@app/pages/displays/display-project-details/subdisplay-users/ColumnTypes";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {
    TaskListPeriodColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/TaskListPeriodColumn";
import {DataFetcherCollection} from "@app/core/DataFetchers/DataFetcherCollection";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {
    TaskListStatusColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/TaskListStatusColumn";
import {
    TaskListPeriodTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns/TaskListPeriodTableColumn";
import {
    TaskListAllColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/TaskListAllColumn";
import {
    MilestoneListUserColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/MilestoneListUserColumn";
import {
    UserCardColumnType
} from "@app/pages/displays/display-project-details/subdisplay-users/ColumnTypes/UserCardColumnType";
import {
    MilestoneListUserColumnType
} from "@app/pages/displays/display-project-details/subdisplay-users/ColumnTypes/MilestoneListUserColumnType";
import {
    TaskEditColumnType
} from "@app/pages/displays/display-project-details/subdisplay-users/ColumnTypes/TaskEditColumnType";
import {
    TaskListStatusColumnType
} from "@app/pages/displays/display-project-details/subdisplay-users/ColumnTypes/TaskListStatusColumnType";
import {
    TaskListPeriodColumnType
} from "@app/pages/displays/display-project-details/subdisplay-users/ColumnTypes/TaskListPeriodColumnType";
import {
    TaskListAllColumnType
} from "@app/pages/displays/display-project-details/subdisplay-users/ColumnTypes/TaskListAllColumnType";
import {
    TaskListAllTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns/TaskListAllTableColumn";
import {
    TaskListStatusTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns/TaskListStatusTableColumn";
import {UserCardColumn} from "@app/pages/displays/display-project-details/subdisplay-users/Columns/UserCardColumn";
import {
    TaskEditTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/TableColumns/TaskEditTableColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    TaskEdit_Card_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/TaskEdit_Card_TableColumn";
import {
    BaseEditorTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-users/EditorTableColumns/BaseEditorTableColumn";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";
import {RxStompService} from "@app/core/rabbitmq/rx-stomp.service";
import {ChangeMessage} from "@app/core/rabbitmq/ChangeMessage";

@Component({
    selector: 'app-display-project-details-users',
    templateUrl: './display-project-details-users.component.html',
    styleUrls: ['./display-project-details-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DisplayProjectDetailsUsersComponent extends PageComponent implements OnInit, AfterViewChecked {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();

    // Bindings
    @Input() displayId: number;
    @Input() projectId: number;
    @Input() project: Project;
    @Input() taskEdit_taskDeadlineTypeId: number;
    @Input() taskEdit_taskEstimateTypeId: number;
    @Input() onReloadEventEmitter: EventEmitter<any>;
    @Output() onShellPageDataChangeEvent = new EventEmitter<ShellPageData>();

    // Bindings to view
    public templateTypes = new TemplateTypes();
    public tableRows: Row[];
    public tableColumns: GenericTableColumn[];
    public onScreenFilters = [new UsersWithTasks(false)];
    public showPeriodColumnWarning: boolean;
    public isMinimized = true;
    public milestoneListOnScreenFilters = [new MilestoneNonArchivedFilter(false)];
    public taskListsOnScreenFilters = [new NonArchivedFilter(false)];
    public taskUserParticipants?: User[];
    public onStatusColumnSortRenderEventEmitter = new EventEmitter();
    public onPeriodColumnSortRenderEventEmitter = new EventEmitter();
    public dataFetcherCollection = new DataFetcherCollection();
    public projectUserParticipants: User[];

    // UI: DataTable
    @ViewChild('dataTableContainer', {static: false}) dataTableContainerElement: any;
    @ViewChild('dataTable', {static: false}) table: DatatableComponent;
    @ViewChild('headerTemplate', {static: true}) headerTemplate: TemplateRef<any>;
    @ViewChild('headerUserCardTemplate', {static: true}) headerUserCardTemplate: TemplateRef<any>;
    @ViewChild('headerWithPeriodTemplate', {static: true}) headerWithPeriodTemplate: TemplateRef<any>;
    @ViewChild('headerWithStatusTemplate', {static: true}) headerWithStatusTemplate: TemplateRef<any>;
    @ViewChild('headerTaskEditTemplate', {static: true}) headerTaskEditTemplate: TemplateRef<any>;
    @ViewChild('headerMilestoneListTemplate', {static: true}) headerMilestoneListTemplate: TemplateRef<any>;
    @ViewChild('cellUserCardTemplate', {static: true}) cellUserCardTemplate: TemplateRef<any>;
    @ViewChild('cellTaskListTemplate', {static: true}) cellTaskListTemplate: TemplateRef<any>;
    @ViewChild('cellTaskEditListTemplate', {static: true}) cellTaskEditListTemplate: TemplateRef<any>;
    @ViewChild('cellTaskListStatusTemplate', {static: true}) cellTaskListStatusTemplate: TemplateRef<any>;
    @ViewChild('cellTaskListPeriodTemplate', {static: true}) cellTaskListPeriodTemplate: TemplateRef<any>;
    @ViewChild('cellTaskEditListWhoWhenHeaderTemplate', {static: true}) cellTaskEditListWhoWhenHeaderTemplate: TemplateRef<any>;
    @ViewChild('cellMilestoneListTemplate', {static: true}) cellMilestoneListTemplate: TemplateRef<any>;

    // Data
    private rows?: Row[];
    private rowsMap?: Map<number, Row>;
    private userTasksMap?: Map<number, Task[]>; // user_id -> Task[]

    constructor(
        private cd: ChangeDetectorRef,
        private projectsService: ProjectsService,
        private rxStompService: RxStompService,
    ) {
        super();
        this.cdr = cd;
        this.isMainDisplay = false;
        this.initialized = false;
    }

    ngOnInit() {
        super.ngOnInit();

        this.onScreenFilters.forEach(onScreenFilter => {
            this.subscribe(onScreenFilter.onEnabledChangeEvent.subscribe(() => {
                this.renderRows();
            }));
        });

        if (this.onReloadEventEmitter) {
            this.subscribe(this.onReloadEventEmitter.subscribe(() => this.reload()));
        }
    }

    private tableVisibilityObserver: ComponentVisibilityObserver;

    ngAfterViewChecked() {
        if (!this.tableVisibilityObserver && this.table?.element) {
            this.tableVisibilityObserver = new ComponentVisibilityObserver(this.table.element);
            this.subscribe(this.tableVisibilityObserver.onVisibilityChangeEvent.subscribe(isVisible => {
                if (isVisible && this.rows) {
                    this.renderRows();
                }
            }));
            this.addComponentVisibilityObserver(this.tableVisibilityObserver);
        }
    }

    // <editor-fold desc="View Actions">

    public reload() {
        this.loadData();
    }

    public addParticipantToAll(user: User) {
        this.tableRows.forEach(row => {
            row.taskEditListItems?.forEach(cardItem => {
                if (!cardItem.item.findParticipant(user.id)) {
                    cardItem.item.addUser(TaskUserTypes.Participant, user);
                }
            });
        });
    }

    public removeParticipantFromAll(user: User) {
        this.tableRows.forEach(row => {
            row.taskEditListItems?.forEach(cardItem => {
                if (cardItem.item.findParticipant(user.id)) {
                    cardItem.item.removeUser(TaskUserTypes.Participant, user);
                }
            });
        });
    }

    public toggleIsMinimized() {
        this.isMinimized = !this.isMinimized;
        this.columnController.getColumns<TaskEditColumn>(ColumnTypes.TaskEdit).forEach(column => {
            column.enabledEditors.forEach(editor => {
                switch (editor.name) {
                    case TitleEditorComponent.name: // Titel
                        (editor.configuration as EditTitleConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                    case TextEditorComponent.name: // Noter
                        (editor.configuration as EditTextConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                    case LinkEditorComponent.name: // Link
                        (editor.configuration as EditLinkConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                    case WhoWhenEditorComponent.name: // Hvem & Hvornår,
                        (editor.configuration as EditWhoWhenConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                }
            });
        });
    }

    public addProjectParticipant(user: User) {
        this.project.addUser(ProjectUserTypes.Participant, user);
        this.loadData();
    }

    public setRowTaskEditListItems(row: Row, items: CardItem<Task>[]) {
        row.taskEditListItems = items;
        const selectedParticipants = new Map<number, User>();
        this.rows.forEach(row => {
            row.taskEditListItems?.forEach(card => {
                card.item.findTasksUsersByType(TaskUserTypes.Participant)?.forEach(tasksUser => {
                    selectedParticipants.set(tasksUser.user_id, tasksUser.user);
                });
            });
        })
        this.taskUserParticipants = Array.from(selectedParticipants.values());
    }

    public onCaptureScreenshotBtnClicked() {
        if (this.isCapturingScreenshot) {
            return;
        }
        this.isCapturingScreenshot = true;
        ScreenshotHelper.Capture(this.dataTableContainerElement.nativeElement, () => {
            this.isCapturingScreenshot = false;
            this.detectChanges();
        })
    }

    public onColumnSortChanged(tableColumn: BaseTableColumn) {
        if (tableColumn.column.implementsSorting) {
            const sortableColumn = tableColumn.column as unknown as SortableColumnInterface;
            this.tableRows.forEach(row => {
                sortableColumn.applyRowSort(row, tableColumn);
            });
        }

        if (tableColumn.column.implementsDataFetching) {
            const column = tableColumn.column as unknown as ColumnDataFetcherInterface;
            column.getDataFetchers().forEach(fetcher => fetcher.execute());
        }

        if (tableColumn instanceof TaskListStatusTableColumn) {
            tableColumn.column.getTableColumns<TaskListStatusTableColumn>().forEach(statusTableColumn => {
                statusTableColumn.activeSortItem = tableColumn.activeSortItem;
                statusTableColumn.activeSortTypeId = tableColumn.activeSortTypeId;
                statusTableColumn.activeSortDirection = tableColumn.activeSortDirection;
            });
            this.onStatusColumnSortRenderEventEmitter.emit();
        }

        if (tableColumn instanceof TaskListPeriodTableColumn) {
            tableColumn.column.getTableColumns<TaskListPeriodTableColumn>().forEach(periodTableColumn => {
                periodTableColumn.activeSortItem = tableColumn.activeSortItem;
                periodTableColumn.activeSortTypeId = tableColumn.activeSortTypeId;
                periodTableColumn.activeSortDirection = tableColumn.activeSortDirection;
            });
            this.onPeriodColumnSortRenderEventEmitter.emit();
        }
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private apiSubscription?: ApiRequest;

    public loadData() {
        if (!this.project?.project_type || !this.shellFilterGroup.activeFilter) {
            return;
        }

        // Cancel previous load
        this.apiSubscription?.cancel();
        this.dataFetcherCollection.cancel();

        // Clear data
        this.rows = [];
        this.rowsMap = new Map();

        // Update columns
        const periodStart = this.shellFilterGroup.activeFilter.getPeriodStart(this.filtersSettings);
        const periodEnd = this.shellFilterGroup.activeFilter.getPeriodEnd(this.filtersSettings);
        this.columnController.getColumns<TaskListPeriodColumn>(ColumnTypes.TaskList_Period).forEach(column => {
            column.setPeriod(periodStart, periodEnd);
        });

        const api = Api.users().get()
            .where('projects_user.project_id', this.projectId)
            .where('hidden', false)
            .orderBy('name', this.filterGlobalService.getActiveSettings().activeSortDirection);

        if (this.filterGlobalService.getActiveSettings().search?.length > 0) {
            api.search('name', this.filterGlobalService.getActiveSettings().search);
        }

        if (this.filterGlobalService.getActiveSettings().activeUsers?.length > 0) {
            // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1632
            api.search('users', this.filterGlobalService.getActiveSettings().activeUsers.map(user => user.id));
        }

        this.apiSubscription = api.find(users => {
            const usersTasksApiCall = Api.tasks().get()
                .where('project.id', this.projectId)
                .whereIn('tasks_user.user_id', users.map(user => user.id));

            // if (this.filterGlobalService.getActiveSettings().activeUsers?.length > 0) {
            //     api.search('users', this.filterGlobalService.getActiveSettings().activeUsers.map(user => user.id));
            // }

            usersTasksApiCall.find(tasks => {

                this.columnController.getVisibleColumns()
                    .filter(column => column.implementsDataFetching)
                    .forEach(column => {
                        this.dataFetcherCollection.add((column as unknown as ColumnDataFetcherInterface).getDataFetchers());
                    });
                this.dataFetcherCollection.clearFetchers();

                const rows = users.map(user => this.createRow(user));

                this.dataFetcherCollection.execute(() => {
                    rows.forEach(row => this.rowsMap.set(row.userCard.item.id, row));

                    // Add task reference to users (For OnScreenFilter)
                    this.userTasksMap = new Map();
                    tasks.forEach(task => {
                        task.findTasksUsersByType(TaskUserTypes.Participant)?.forEach(tasksUser => {
                            if (this.rowsMap.has(tasksUser.user_id)) {
                                const user = this.rowsMap.get(tasksUser.user_id).userCard.item;
                                if (!this.userTasksMap.has(user.id)) {
                                    this.userTasksMap.set(user.id, []);
                                }
                                this.userTasksMap.get(user.id).push(task);
                            }
                        });
                    });

                    this.rows = rows;

                    this.renderRows();
                });
            });
        });

        this.projectUserParticipants = this.project.findProjectsUsersByType(ProjectUserTypes.Participant)
            .map(projectsUser => projectsUser.user);
    }

    private createRow(user: User): Row {
        const row = new Row(this.project, user);

        this.columnController.getAllColumns().forEach(column => {
            if (column instanceof UserCardColumn) {
                row.addCell(column.createCell(row, row.userCard));
            } else {
                row.addCell(column.createCell(row));
            }
        });

        // Used across task columns (status + period + edit)
        const setTaskListFilterConfiguration = (listConfiguration: TaskListConfiguration) => {
            const filter = this.shellFilterGroup.activeFilter;
            switch (filter.filter_type) {
                case Filters.FilterAll:
                    // No filters.
                    break;
                case Filters.FilterWithDeadlineInPeriod:
                    listConfiguration
                        .setTaskDeadlineTypes([filter.task_deadline_type_id])
                        .setDeadlineBetween(
                            filter.getPeriodStart(this.filtersSettings),
                            filter.getPeriodEnd(this.filtersSettings)
                        );
                    break;
                case Filters.FilterWithoutDeadline:
                    listConfiguration
                        .setTaskDeadlineTypes([filter.task_deadline_type_id])
                        .setHasNonDeadline(true);
                    break;
                case Filters.FilterPlannedInPeriod:
                    listConfiguration.setPlannedBetween(
                        filter.getPeriodStart(this.filtersSettings),
                        filter.getPeriodEnd(this.filtersSettings)
                    );
                    break;
                case Filters.FilterWithoutPlanningDate:
                    listConfiguration.setPlanningDeadlineId(0);
                    break;
                case Filters.FilterOpen:
                    listConfiguration.setOpen(true);
                    break;
                case Filters.FilterWithNotes:
                    listConfiguration.setHasNotes(true); // task.delivery_description
                    break;
                case Filters.FilterArchivedInPeriod:
                    listConfiguration.setArchivedBetween(
                        filter.getPeriodStart(this.filtersSettings),
                        filter.getPeriodEnd(this.filtersSettings)
                    );
                    break;
            }
        };

        this.columnController.getColumns<TaskListStatusColumn>(ColumnTypes.TaskList_Status).forEach(column => {
            column.getCell(row).listConfigurations.forEach(listConfiguration => {
                listConfiguration
                    .setCustomAllowCardItemEnterFunction((cardItem, fromListConfiguration) => {
                        // Only allow from fellow taskStatusListConfigurations
                        return this.tableRows.some(row => {
                            return this.columnController
                                .getColumns<TaskListStatusColumn>(ColumnTypes.TaskList_Status)
                                .some(column => {
                                    return [...column.getCell(row).listConfigurations.values()].includes(fromListConfiguration);
                                });
                        });
                    })
                    .setOnScreenFilters(this.taskListsOnScreenFilters);
                setTaskListFilterConfiguration(listConfiguration);
            });
        });

        this.columnController.getColumns<TaskListPeriodColumn>(ColumnTypes.TaskList_Period).forEach(column => {
            const cell = column.getCell(row);
            const tableColumns = column.getTableColumns<TaskListPeriodTableColumn>();

            // Configure table columns with deadline
            tableColumns.filter(tableColumn => tableColumn.periodType !== TaskListPeriodColumn.Period_WithoutDeadline)
                .forEach(tableColumn => {
                    const listConfiguration = cell.listConfigurations.get(tableColumn.periodType);
                    listConfiguration
                        .setCustomAllowCardItemEnterFunction((cardItem, fromListConfiguration) => {
                            if (listConfiguration.getDeadlineBetween() || listConfiguration.getPlannedBetween()) { // ArchivedBetween is not part of drag drop
                                // Allow from fellow taskPeriodListConfigurations
                                if (listConfiguration.getDeadlineBetween() && fromListConfiguration.getHasNonDeadline()) {
                                    return true; // Allow from Top display "Tasks without deadline"
                                }
                                // Only allow from fellow taskPeriodListConfigurations
                                return this.tableRows.some(row => {
                                    return this.columnController
                                        .getColumns<TaskListPeriodColumn>(ColumnTypes.TaskList_Period)
                                        .some(column => {
                                            return [...column.getCell(row).listConfigurations.values()].includes(fromListConfiguration);
                                        });
                                });
                            } else {
                                return false;
                            }
                        })
                        .setOnScreenFilters(this.taskListsOnScreenFilters)
                        .setShowCreateNew(this.shellFilterGroup.activeFilter.filter_type != Filters.FilterArchivedInPeriod);


                    // Prepare preset generators for task lists
                    switch (this.shellFilterGroup.activeFilter.filter_type) {
                        case Filters.FilterWithDeadlineInPeriod:
                            listConfiguration.addCreatePresetGenerator(new TaskDeadlinePresetGenerator(
                                TaskDeadlineTypes.Normal,
                                () => tableColumn.getStartDateForTaskPreset(),
                                false
                            ));
                            break;
                        case Filters.FilterPlannedInPeriod:
                            listConfiguration.addCreatePresetGenerator(new TaskUserPresetGenerator(
                                TaskUserTypes.Participant,
                                this.user.id,
                                () => Deadline.Create(tableColumn.getStartDateForTaskPreset(), false),
                            ));
                            break;
                        case Filters.FilterArchivedInPeriod:
                            // Create New is not visible
                            break;
                    }
                });

            // Configure table columns without deadline
            tableColumns
                .filter(tableColumn => tableColumn.periodType == TaskListPeriodColumn.Period_WithoutDeadline)
                .forEach(tableColumn => {
                    const listConfiguration = cell.listConfigurations.get(tableColumn.periodType);
                    listConfiguration.setOnScreenFilters(this.taskListsOnScreenFilters);
                });

            cell.listConfigurations.forEach(listConfiguration => {
                setTaskListFilterConfiguration(listConfiguration);
            });

            const filter = this.shellFilterGroup.activeFilter;
            tableColumns
                .forEach(tableColumn => {
                    const listConfiguration = cell.listConfigurations.get(tableColumn.periodType);

                    switch (filter.filter_type) {
                        case Filters.FilterWithDeadlineInPeriod:
                            listConfiguration
                                .setTaskDeadlineTypes([filter.task_deadline_type_id])
                                .setDeadlineBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                        case Filters.FilterPlannedInPeriod:
                            listConfiguration.setPlannedBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                        case Filters.FilterArchivedInPeriod:
                            listConfiguration
                                .setArchivedBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                        default:
                            listConfiguration
                                .setDeadlineValidator(task => false) // We can't show tasks if filter does not specify period
                                .setDeadlineBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                    }
                });
        });

        this.columnController.getColumns<TaskListAllColumn>(ColumnTypes.TaskList_All).forEach(column => {
            const listConfiguration = column.getCell(row).listConfiguration;
            listConfiguration
                .setCustomAllowCardItemEnterFunction((cardItem, fromListConfiguration) => {
                    // Only allow from fellow taskEditListConfigurations
                    return fromListConfiguration.getOpen() || this.tableRows.find(row => listConfiguration == fromListConfiguration) !== undefined;
                })
                .setOnScreenFilters(this.taskListsOnScreenFilters);
            setTaskListFilterConfiguration(listConfiguration);
        });

        this.columnController.getColumns<TaskEditColumn>(ColumnTypes.TaskEdit).forEach(column => {
            const listConfiguration = column.getCell(row).listConfiguration;
            listConfiguration
                .setIsSortingEnabled(this.shellFilterGroup.activeFilter.sort_type == Filters.SortUserDefined)
                .setCustomAllowCardItemEnterFunction((cardItem, fromListConfiguration) => {
                    // Only allow from fellow taskEditListConfigurations
                    return this.tableRows.find(row => listConfiguration == fromListConfiguration) !== undefined;
                })
                .setOnScreenFilters(this.taskListsOnScreenFilters);

            setTaskListFilterConfiguration(listConfiguration);
        });

        this.columnController.getColumns<MilestoneListUserColumn>(ColumnTypes.MilestoneListUser).forEach(column => {
            column.getCell(row).listConfiguration.addOnScreenFilters(this.milestoneListOnScreenFilters);
        });

        return row;
    }

    private renderRows() {
        this.tableRows = this.rows.filter(row => {
            const user = new User();
            user.tasks = this.userTasksMap.get(row.userCard.item.id);
            return this.onScreenFilters.length == 0 || this.onScreenFilters.find(filter => filter.isValid(user)) !== undefined;
        });
        this.detectChanges();
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    protected initializeShellPageData() {
        this.shellPageData.displayFilterExtra = JSON.stringify({
            projectId: this.projectId
        });
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();

        this.filtersSettings.getEditorConfig(this.display, config => {
            this.columnController.addColumnTypes([
                new UserCardColumnType(this.cellUserCardTemplate, this.headerUserCardTemplate),
                new MilestoneListUserColumnType(this.cellMilestoneListTemplate, this.headerMilestoneListTemplate),
                new TaskEditColumnType(
                    this.shellPageData,
                    column => this.onColumnSortChanged(column),
                    this.cellTaskEditListWhoWhenHeaderTemplate,
                    this.isMinimized,
                    config
                ),
                new TaskListStatusColumnType(this.cellTaskListStatusTemplate, this.headerWithStatusTemplate),
                new TaskListPeriodColumnType(this.cellTaskListPeriodTemplate, this.headerWithPeriodTemplate),
                new TaskListAllColumnType(this.cellTaskListTemplate, this.headerTemplate),
            ]);

            this.columnController.loadColumns(this.displayId, () => {

                this.initialize();
            });
        });
    }

    private initialize() {
        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            let tableColumns = this.columnController.getVisibleTableColumns() as GenericTableColumn[];

            // Combine TaskEdit-table columns into one single table column
            this.columnController.getColumns<TaskEditColumn>(ColumnTypes.TaskEdit).forEach(column => {
                if (column.isVisible) {
                    const firstTaskEditTableColumn = tableColumns.findIndex(tableColumn => tableColumn.column == column);
                    const taskEditTableColumn = new TaskEditTableColumn(column, this.cellTaskEditListTemplate, this.headerTaskEditTemplate);
                    tableColumns[firstTaskEditTableColumn] = taskEditTableColumn;
                    tableColumns = tableColumns.filter(tableColumn => !column.getTableColumns().includes(tableColumn));

                    column.updateEnabledEditors();

                    // Update this table column minWidth
                    let minWidth = 0;
                    column.getVisibleTableColumns<BaseEditorTableColumn>()
                        .forEach(editor => minWidth += editor.columnConfiguration.minWidth ?? 0);
                    taskEditTableColumn.minWidth = minWidth;

                    if (column.getVisibleTableColumns().some(tableColumn => tableColumn instanceof TaskEdit_Card_TableColumn)) {
                        // Auto-expand
                        if (this.isMinimized) {
                            this.toggleIsMinimized();
                        }
                    }
                }
            });

            this.tableColumns = tableColumns;

            this.loadData();
        }));

        this.onShellPageDataChangeEvent.emit(this.shellPageData);

        this.subscribe(this.shellFilterGroup.onActiveFilterChangeEventSubscribe(filter => {
            this.showPeriodColumnWarning = [
                Filters.FilterWithDeadlineInPeriod,
                Filters.FilterPlannedInPeriod,
                Filters.FilterArchivedInPeriod,
            ].includes(filter.filter_type) == false;
            this.loadData();
        }));

        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(() => this.loadData()));

        this.subscribe(this.eventService.subscribeToProject(this.projectId, event => {
            this.loadData();
        }));
        this.subscribe(this.eventService.subscribeToTask(0, event => {
            const task = event.item;
            switch (event.action) {
                case EventService.Created:
                    // Add new references
                    task.findTasksUsersByType(TaskUserTypes.Participant)?.forEach(tasksUser => {
                        if (this.rowsMap.has(tasksUser.user_id)) {
                            const user = this.rowsMap.get(tasksUser.user_id).userCard.item;
                            if (!this.userTasksMap.has(user.id)) {
                                this.userTasksMap.set(user.id, []);
                            }
                            this.userTasksMap.get(user.id).push(task);
                        }
                    });
                    break;
                case EventService.Updated:
                    // Remove existing references to this task
                    this.userTasksMap.forEach((tasks: Task[], i: number) => {
                        const index = tasks.findIndex(t => t.id == task.id);
                        if (index !== -1) {
                            tasks.splice(index, 1);
                        }
                    });
                    // Add new references
                    task.findTasksUsersByType(TaskUserTypes.Participant)?.forEach(tasksUser => {
                        if (this.rowsMap.has(tasksUser.user_id)) {
                            const user = this.rowsMap.get(tasksUser.user_id).userCard.item;
                            if (!this.userTasksMap.has(user.id)) {
                                this.userTasksMap.set(user.id, []);
                            }
                            this.userTasksMap.get(user.id).push(task);
                        }
                    });
                    break;
                case EventService.Deleted:
                    // Remove existing references to this task
                    this.userTasksMap.forEach(tasks => {
                        const index = tasks.findIndex(t => t.id == task.id);
                        if (index) {
                            tasks.splice(index, 1);
                        }
                    });
                    break;
            }
        }));

        this.subscribe(this.rxStompService
            .watch(Events.ProjectChangedUsers(this.projectId))
            .subscribe(message => {
                this.project.populate(ChangeMessage.ParseRabbitMQMessage(message.body).next, true);
                this.loadData();
            }));

        this.setupColumnSortItems();

        this.initialized = true;

        this.projectsService.getProjectType(this.project.project_type_id, projectType => {
            this.project.project_type = projectType;
            this.loadData();
        });
    }

    private setupColumnSortItems() {
        this.filtersSettings.getEditorConfig(this.display, config => {

            this.columnController.getColumns<TaskListAllColumn>(ColumnTypes.TaskList_All).forEach(column => {
                column.getTableColumns<TaskListAllTableColumn>().forEach(tableColumn => {
                    tableColumn.sortItems.push(
                        ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                            Filters.SortCategoryTypeGenerator(categoryType)
                        )),
                        ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                            Filters.SortDeadlineWithGenerator(deadlineType)
                        )),
                    );
                });
            });

            this.columnController.getColumns<TaskListPeriodColumn>(ColumnTypes.TaskList_Period).forEach(column => {
                column.getTableColumns<TaskListPeriodTableColumn>().forEach(tableColumn => {
                    switch (tableColumn.periodType) {
                        case TaskListPeriodColumn.Period_WithoutDeadline:
                            tableColumn.sortItems.push(
                                ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                                    Filters.SortCategoryTypeGenerator(categoryType)
                                )),
                            );
                            break;
                        default:
                            tableColumn.sortItems.push(
                                ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                                    Filters.SortCategoryTypeGenerator(categoryType)
                                )),
                                ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                                    Filters.SortDeadlineWithGenerator(deadlineType)
                                )),
                            );
                            break;
                    }
                });
            });

            this.columnController.getColumns<TaskListStatusColumn>(ColumnTypes.TaskList_Status).forEach(column => {
                column.getTableColumns<TaskListStatusTableColumn>().forEach(tableColumn => {
                    tableColumn.sortItems.push(
                        ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                            Filters.SortCategoryTypeGenerator(categoryType)
                        )),
                        ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                            Filters.SortDeadlineWithGenerator(deadlineType)
                        )),
                    );
                });
            });

        });
    }

    // </editor-fold>

}
