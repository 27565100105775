import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseColumn} from "@app/editor/quick-editor/columns/BaseColumn";
import {
    SortableTextColumnConfiguration
} from "@app/editor/quick-editor/columns/generic/sortable-text-column/SortableTextColumnConfiguration";
import {Display} from "@app/core/models";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

@Component({
    selector: 'app-sortable-text-column',
    templateUrl: './sortable-text-column.component.html',
    styleUrls: ['./sortable-text-column.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SortableTextColumnComponent extends BaseColumn {

    // Bindings to parent
    @Input() configuration: SortableTextColumnConfiguration;

    // Bindings to view
    public column: BaseTableColumn;
    public display: Display;
    public shellPageData: ShellPageData;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.subscribe(this.configuration.column.onSortItemsChangeEvent.subscribe(() => this.render()));
    }

    protected render() {
        this.column = this.configuration.column;
        this.display = this.configuration.shellPageData.display;
        this.shellPageData = this.configuration.shellPageData;
    }

    public onColumnSortChange(column: BaseTableColumn) {
        this.configuration.onColumnSortChange(column);
    }

}
