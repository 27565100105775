import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {Display} from '@app/core/models/Display';
import {Sort} from '@app/pages/displays/filtering/Sort';
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {DefaultDirections} from "@app/core/ColumnControl/DefaultDirections";

@Component({
    selector: 'app-column-sorter',
    templateUrl: './column-sorter.component.html',
    styleUrls: ['./column-sorter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ColumnSorterComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() display: Display;
    @Input() column: BaseTableColumn;
    @Input() filterPageData: ShellPageData;
    @Input() onRenderEvent: EventEmitter<any>;

    @Output() columnSortChange = new EventEmitter<BaseTableColumn>();

    public filterSortTypes: Sort[];
    public activeFilterSortTypes: Sort[] = [];
    public isSortActive: boolean = false;
    public activeSort: Sort;
    public sortDirection: string = null;
    public initialSort = true;
    public sortTypeColumn: boolean;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.filterPageData?.shellFilterGroup?.onActiveFilterChangeEventSubscribe(filter => {
            this.findActiveSort();
        });

        this.onRenderEvent?.subscribe(() => {
            this.initialSort = false;
            this.render();
        });

        this.subscribe(this.column?.onSortItemsChangeEvent.subscribe(() => this.render()));
    }


    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        this.filterSortTypes = this.filterPageData?.shellFilterGroup?.settings?.sorts(this.display, true);

        this.column?.sortItems?.forEach((sortItem: PageColumnSort) => {
            const sort = this.filterSortTypes?.find(type => type.id === sortItem.sortId);
            if (sort && this.activeFilterSortTypes.findIndex(s => s.id === sort.id) === -1) {
                this.activeFilterSortTypes.push(sort);
            }
        });

        this.findActiveSort();

        if (this.isSortActive) {
            if (this.column.isList) {
                this.sortDirection = this.column.activeSortDirection;
            } else {
                this.sortDirection = this.filterPageData?.shellFilterGroup?.activeFilter?.sort_direction;
            }
        } else {
            this.sortDirection = null;
        }

        this.detectChanges();
    }

    findActiveSort() {
        if (this.column.activeSortItem != null && this.filterPageData.activePageColumnSort == this.column.activeSortItem) {
            this.activeSort = this.filterSortTypes.find(st => st.id === this.column.activeSortItem.sortId);
            this.isSortActive = true;
        } else if (this.column.activeSortItem && this.column.isList) {
            this.activeSort = this.filterSortTypes.find(st => st.id === this.column.activeSortItem.sortId);
            this.isSortActive = true;
        } else {
            this.activeSort = null;
            this.isSortActive = false;
        }
    }

    triggerClick(sortId: string) {
        const sortItem = this.column.sortItems?.find((sortItem: PageColumnSort) => sortItem.sortId == sortId);
        let emitFilterConfiguration = false;

        if (this.column.isList) {
            if (this.column.activeSortTypeId == sortId) {
                this.column.activeSortDirection = this.column.activeSortDirection == 'asc' ? 'desc' : 'asc';
            } else {
                // Rækkefølge skal ændres tilbage hver gang man vælger en ny sortering
                this.column.activeSortDirection = 'asc';
            }
            this.column.activeSortTypeId = sortId;
            this.filterPageData.activePageColumnSort = sortItem;
        } else {
            if (this.column.activeSortItem?.sortId == sortItem.sortId) { // Toggle if same sort item
                this.filterPageData.shellFilterGroup.activeFilter.sort_direction = this.filterPageData.shellFilterGroup.activeFilter.sort_direction == 'asc' ? 'desc' : 'asc';
            } else {
                switch (sortItem.defaultDirection) {
                    case DefaultDirections.FollowGlobalSetting:
                        // Do not change
                        break;
                    case DefaultDirections.Ascending:
                        this.column.activeSortDirection = 'asc';
                        break;
                    case DefaultDirections.Descending:
                        this.column.activeSortDirection = 'desc';
                        break;
                }
            }

            this.filterPageData.shellFilterGroup.activeFilter.sort_type = sortId;
            this.filterPageData.shellFilterGroup.activeFilter.project_deadline_type_id = sortItem.projectDeadlineTypeId;
            this.filterPageData.shellFilterGroup.activeFilter.sort_direction = this.column.activeSortDirection;
            this.filterPageData.activePageColumnSort = sortItem;
            emitFilterConfiguration = true;
        }

        this.column.activeSortItem = sortItem;
        this.columnSortChange.emit(this.column);

        this.initialSort = false;
        this.render();

        if (emitFilterConfiguration) {
            this.filterPageData.setActiveDisplayFilter(this.filterPageData.shellFilterGroup.activeFilter);
        }
    }

    changeSortDirection() {
        if (this.column.isList) {
            this.column.activeSortDirection = this.column.activeSortDirection == 'asc' ? 'desc' : 'asc';
        } else {
            this.filterPageData.shellFilterGroup.activeFilter.sort_direction = this.filterPageData.shellFilterGroup.activeFilter.sort_direction == 'asc' ? 'desc' : 'asc';
            this.filterPageData.setActiveDisplayFilter(this.filterPageData.shellFilterGroup.activeFilter);
        }
        this.columnSortChange.emit(this.column);
        this.render();
    }
}
