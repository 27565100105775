import {HttpContext, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import {of, Subscription} from "rxjs";
import { AppInjector } from "@app/services/app-injector.service";
import { ApiService } from "@app/core/http/api.service";
import { RetryResponse } from "./RetryResponse";
import {AuthService} from "../../../../services/auth.service";
import {SnackbarService} from "@app/services/snackbar.service";
import {OAuthService} from "angular-oauth2-oidc";

export class ApiRequest {

    private url: string;
    private readonly method: 'get' | 'post' | 'patch' | 'put' | 'delete' | 'download' | 'upload';
    private options: {
        headers?: HttpHeaders;
        context?: HttpContext;
        observe?: 'body';
        params?: HttpParams;
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
        transferCache?: {
            includeHeaders?: string[];
        } | boolean;
    };
    private body: any;
    private readonly callback: (data: any) => void;
    private readonly filename?: string;
    private apiSubscription?: Subscription;

    constructor(url: string, method: 'get' | 'post' | 'patch' | 'put' | 'delete' | 'download' | 'upload',
                options: {
                    headers?: HttpHeaders;
                    context?: HttpContext;
                    observe?: 'body';
                    params?: HttpParams;
                    reportProgress?: boolean;
                    responseType?: 'json';
                    withCredentials?: boolean;
                    transferCache?: {
                        includeHeaders?: string[];
                    } | boolean;
                },
                body: any,
                callback: (date: any) => void,
                filename?: string) {
        this.url = url;
        this.method = method;
        this.options = options;
        this.body = body;
        this.callback = callback;
        this.filename = filename;
    }

    public respond(response: any) {
        this.callback(response);
    }

    public execute(retry = 3): void {
        const apiService = AppInjector.getInjector().get(ApiService);

        const token = AppInjector.getInjector().get(OAuthService).getAccessToken();
        if (token) {
            this.options.headers = this.options.headers.set('Authorization', `Bearer ${token}`);
        }

        switch (this.method) {
            case 'get':
                this.apiSubscription = apiService
                    .get(this.url, this.options)
                    .pipe(catchError(err => this.handleError(err, retry)))
                    .subscribe((res: any) => this.handleResponse(res));
                break;
            case 'post':
                this.apiSubscription = apiService
                    .post(this.url, this.body, this.options)
                    .pipe(catchError(err => this.handleError(err, retry)))
                    .subscribe((res: any) => this.handleResponse(res));
                break;
            case 'patch':
                this.apiSubscription = apiService
                    .patch(this.url, this.body, this.options)
                    .pipe(catchError(err => this.handleError(err, retry)))
                    .subscribe((res: any) => this.handleResponse(res));
                break;
            case 'put':
                this.apiSubscription = apiService
                    .put(this.url, this.body, this.options)
                    .pipe(catchError(err => this.handleError(err, retry)))
                    .subscribe((res: any) => this.handleResponse(res));
                break;
            case 'delete':
                this.apiSubscription = apiService
                    .delete(this.url, this.options)
                    .pipe(catchError(err => this.handleError(err, retry)))
                    .subscribe((res: any) => this.handleResponse(res));
                break;
            case 'download':
                this.apiSubscription = apiService
                    .download(this.url, {...this.options, responseType: 'blob'})
                    .pipe(catchError(err => this.handleError(err, retry)))
                    .subscribe((response: any) => {
                        let dataType = response.type;
                        let binaryData = [];
                        binaryData.push(response);
                        const downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                        downloadLink.setAttribute('download', this.filename ?? 'unknown-file');
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        this.respond(response)
                    });
                break;
            case 'upload':
                this.apiSubscription = apiService
                    .upload(this.url, this.body, this.options)
                    .pipe(catchError(err => this.handleError(err, retry)))
                    .subscribe((res: any) => this.handleResponse(res));
                break;
        }
    }

    public cancel(): void {
        this.apiSubscription?.unsubscribe();
    }

    private handleResponse(response: any) {
        if (!(response instanceof RetryResponse)) {
            this.respond(response);
        }
    }

    private handleError(error: HttpErrorResponse, retry: number) {
        switch (error.status) {
            case 500:
                return this.handle500Error(error);
            case 401:
                return this.handle401Error(error, retry);
            default:
                return of(error);
        }
    }

    private handle401Error(response: HttpErrorResponse, retry: number) {
        // console.warn('handle401Error', response.error, response);
        const error = response.error.error_code ?? '';
        if (error == 'UnAuthorized') {
            if (retry > 1) {
                AppInjector.getInjector().get(AuthService).lockAndRefreshToken(refreshed => {
                    if (refreshed) {
                        this.execute(--retry);
                    } else {
                        AppInjector.getInjector().get(AuthService).logout();
                    }
                });
                return of(new RetryResponse());
            } else {
                AppInjector.getInjector().get(AuthService).logout();
                return of(response);
            }
        } else {
            this.showError(error);
            this.respond(response.error);
            return of(response);
        }
    }

    private handle500Error(response: HttpErrorResponse) {
        if (response.error.message) {
            this.showError(response.error.message);
        } else if (response.error.type) {
            // 500 error, formatted by CodeIgniter
            this.showError(response.error.message);
        }
        this.respond(response.error);
        return of(response);
    }

    private showError(error: string) {
        AppInjector.getInjector().get(SnackbarService).add(error, 'Luk', {
            duration: 8000,
            panelClass: 'alert-danger'
        });
    }

}
