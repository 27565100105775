import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {extract} from '@app/core';
import {DisplayDebugComponent} from '@app/pages/displays/display-debug/display-debug.component';
import {LoginRoutingModule} from "@app/pages/login/login-routing.module";
import {DisplaysRoutingModule} from "@app/pages/displays/displays-routing.module";
import {AboutRoutingModule} from "@app/pages/about/about-routing.module";
import {AdministratorRoutingModule} from "@app/pages/administrator/administrator-routing.module";
import {ErrorRoutingModule} from "@app/pages/error/error-routing.module";
import {HomeRoutingModule} from "@app/pages/home/home-routing.module";
import {ListsRoutingModule} from "@app/pages/lists/lists-routing.module";
import {ProjectsRoutingModule} from "@app/pages/lists/projects/projects-routing.module";
import {UnauthorizedRoutingModule} from "@app/pages/unauthorized/unauthorized-routing.module";
import {RestoreRoutingModule} from "@app/pages/lists/restore/restore-routing.module";

const routes: Routes = [
    // Fallback when no prior activatedRoute is matched

    {
        path: 'debug',
        component: DisplayDebugComponent,
        data: {
            title: extract('Debug'),
            animation: 'Page'
        }
    },
    {
        path: 'debug/:projectPlanningId',
        component: DisplayDebugComponent,
        data: {
            title: extract('Debug: Planning'),
            animation: 'Page'
        }
    },

    {
        path: '**',
        redirectTo: 'app/home/dashboard',
        pathMatch: 'full',
        data: {
            title: extract('Home'),
            animation: 'Page'
        }
    },

];

@NgModule({
    imports: [
        LoginRoutingModule,
        HomeRoutingModule,
        AboutRoutingModule,
        AdministratorRoutingModule,
        DisplaysRoutingModule,
        ErrorRoutingModule,
        ListsRoutingModule,
        ProjectsRoutingModule,
        UnauthorizedRoutingModule,
        RestoreRoutingModule,

        // Must be the last import!
        RouterModule.forRoot(routes, { enableTracing: false }),
    ],
    exports: [
        RouterModule
    ],
    providers: [],

})
export class AppRoutingModule {
}
