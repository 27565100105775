import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {BaseModel} from "@app/core/models/BaseModel";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {
    EditUseStatusRulesConfiguration
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/EditUseStatusRulesConfiguration";
import {EditUseStatusRules} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/EditUseStatusRules";

@Component({
    selector: 'app-use-status-rules-editor',
    templateUrl: './use-status-rules-editor.component.html',
    styleUrls: ['./use-status-rules-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UseStatusRulesEditorComponent extends BaseEditor<EditUseStatusRules> {
    protected eventFieldName = 'use_status_rules';

    // Bindings to parent
    @Input() model: EditUseStatusRules & HasEventGenerator & BaseModel;
    @Input() configuration: EditUseStatusRulesConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public value: boolean;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected render() {
        this.value = this.model.isUseStatusRules();
        this.detectChanges();
    }

    public onToggle() {
        this.model.setUseStatusRules(!this.value);
        this.onItemUpdated();
        this.render();
    }

}
