import {ChangeDetectionStrategy, Component, EventEmitter, OnInit} from '@angular/core';
import {TodoListConfiguration} from '@app/shared/_ui/lists/todo-list/TodoListConfiguration';
import {PageComponent} from '@app/pages/page.component';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {
    TodoUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoUserPresetGenerator";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

@Component({
    selector: 'app-to-dos',
    templateUrl: './to-dos.component.html',
    styleUrls: ['./to-dos.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class ToDosComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;

    public configuration = new TodoListConfiguration();
    public searchValue: string;
    public reloadEmitter = new EventEmitter();
    placeholder: string = '_ui_search_placeholder';

    txtQueryChanged: Subject<string> = new Subject<string>();

    constructor() {
        super();
        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe((filters) => {
            this.search();
        }));

        this.txtQueryChanged
            .pipe(debounceTime(1000), distinctUntilChanged()) // wait 1 sec after the last event before emitting last event &&  only emit if value is different from previous value
            .subscribe((value: string) => {
                this.search();
            });
    }

    ngOnInit() {
        if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1)
            this.placeholder = 'Find...';
        this.configuration.setOrderBy([['title', 'asc']]);
        this.configuration.setLimit(50);
        this.configuration
            .setUseGlobalFilter(true)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                new TodoUserPresetGenerator(AppInjector.getInjector().get(UsersService).user.id),
            ]);
        this.configuration.listClassName = 'table-mode';
        this.shellService.setHeaderTitle('To-dos', null, false, false, false);
    }

    public search() {
        // TO-DO: debounce
        this.configuration.setSearch(this.searchValue);
        this.reloadEmitter.emit();
    }

    public reset() {
        this.searchValue = null;
        this.search();
    }

    changed(query: string) {
        this.txtQueryChanged.next(query);
    }

}
