import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TodoCategory} from "@app/core/models";
import {TodosService} from "@app/services/todos.service";
import {BaseComponent} from "@app/shared/_system/base/base.component";

@Component({
    selector: 'app-todo-category-picker',
    templateUrl: './todo-category-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TodoCategoryPickerComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TodoCategoryPickerComponent),
            multi: true,
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TodoCategoryPickerComponent extends BaseComponent implements OnInit {

    @Input() todoCategory: TodoCategory;
    @Output() selected = new EventEmitter<TodoCategory | undefined>();

    public items: TodoCategory[];

    constructor(
        private cd: ChangeDetectorRef,
        private todosService: TodosService
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.todosService.getCategories(items => {
            this.items = items;
            this.detectChanges();
        });
    }

    compareFn(a: TodoCategory, b: TodoCategory): boolean {
        return (a != null ? a.id : 0) === (b != null ? b.id : 0);
    }

    onValueChange($event: any) {
        this.selected.emit($event.id > 0 ? $event : undefined);
    }

}
