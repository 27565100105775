import {EventEmitter, Injectable} from '@angular/core';
import {Todo} from '@app/core/models/Todo';
import {CaseUser, Roster, Task} from '@app/core/models/Task';
import {Project} from '@app/core/models/Project';
import {Milestone} from '@app/core/models/Milestone';
import {Appointment} from '@app/core/models/Appointment';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {DisplayFilter} from '@app/core/models/DisplayFilter';
import {BaseModel} from '@app/core/models/BaseModel';

export class Event<T> {
    public item: T;
    public action: string;
    public fields: string[];
    public isPatch: boolean;
    public changes: any;
    constructor(item: T, action: string, fields?: string[]) {
        this.item = item;
        this.action = action;
        this.fields = fields;
        this.isPatch = fields != null && fields.length > 0;

        let entity: any = {};
        if (this.isPatch && this.fields?.length)  {
            this.fields.forEach(field => entity[field] = (this.item as any)[field]);
        } else {
            entity = {...this.item};
        }
        this.changes = entity;
    }
}

@Injectable({
  providedIn: 'root'
})
export class EventService {

    // Action types
    public static Created = 'created';
    public static Updated = 'updated';
    public static Deleted = 'deleted';

    // Event Emitters
    private taskEmitters: Map<number, EventEmitter<Event<Task>>> = new Map();
    private projectEmitters: Map<number, EventEmitter<Event<Project>>> = new Map();
    private todoEmitters: Map<number, EventEmitter<Event<Todo>>> = new Map();
    private milestoneEmitters: Map<number, EventEmitter<Event<Milestone>>> = new Map();
    private appointmentEmitters: Map<number, EventEmitter<Event<Appointment>>> = new Map();
    private displayFilterEmitters: Map<number, EventEmitter<Event<DisplayFilter>>> = new Map();

    constructor() {

    }

    // <editor-fold desc="Subscribe">

    public subscribe(anyItem: AnyItem, complete: (event: Event<AnyItem>) => void) {
        if (anyItem instanceof Appointment) {
            return this.subscribeToAppointment(anyItem.id, complete);
        } else {
            switch (anyItem.constructor.name) {
                case CaseUser.name:
                case Roster.name:
                case Task.name:
                    return this.subscribeToTask(anyItem.id, complete);
                case Project.name:
                    return this.subscribeToProject(anyItem.id, complete);
                case Todo.name:
                    return this.subscribeToTodo(anyItem.id, complete);
                case Milestone.name:
                    return this.subscribeToMilestone(anyItem.id, complete);
            }
        }
    }

    public subscribeToTask(id: number, complete: (event: Event<Task>) => void) {
        if(!this.taskEmitters.has(id)) this.taskEmitters.set(id, new EventEmitter());
        return this.taskEmitters.get(id).subscribe(complete);
    }

    public subscribeToProject(id: number, complete: (event: Event<Project>) => void) {
        if(!this.projectEmitters.has(id)) this.projectEmitters.set(id, new EventEmitter());
        return this.projectEmitters.get(id).subscribe(complete);
    }

    public subscribeToTodo(id: number, complete: (event: Event<Todo>) => void) {
        if(!this.todoEmitters.has(id)) this.todoEmitters.set(id, new EventEmitter());
        return this.todoEmitters.get(id).subscribe(complete);
    }

    public subscribeToMilestone(id: number, complete: (event: Event<Milestone>) => void) {
        if(!this.milestoneEmitters.has(id)) this.milestoneEmitters.set(id, new EventEmitter());
        return this.milestoneEmitters.get(id).subscribe(complete);
    }

    public subscribeToAppointment(id: number, complete: (event: Event<Appointment>) => void) {
        if(!this.appointmentEmitters.has(id)) this.appointmentEmitters.set(id, new EventEmitter());
        return this.appointmentEmitters.get(id).subscribe(complete);
    }

    public subscribeToDisplayFilters(id: number, complete: (event: Event<DisplayFilter>) => void) {
        if(!this.displayFilterEmitters.has(id)) this.displayFilterEmitters.set(id, new EventEmitter());
        return this.displayFilterEmitters.get(id).subscribe(complete);
    }

    // </editor-fold>

    // <editor-fold desc="Emit">

    public emit(item: BaseModel, action: string, fields?: string[]) {
        if (item instanceof Appointment) {
            return this.emitAppointment(item as Appointment, action, fields);
        } else {
            switch (item.constructor.name) {
                case CaseUser.name:
                case Roster.name:
                case Task.name:
                    return this.emitTask(item as Task, action, fields);
                case Project.name:
                    return this.emitProject(item as Project, action, fields);
                case Todo.name:
                    return this.emitTodo(item as Todo, action, fields);
                case Milestone.name:
                    return this.emitMilestone(item as Milestone, action, fields);
                case DisplayFilter.name:
                    return this.emitDisplayFilter(item as DisplayFilter, action, fields);
            }
        }
    }

    public emitTask(item: Task, action: string, fields?: string[]) {
        const event = new Event(item, action, fields);
        if (action == EventService.Updated || action == EventService.Deleted) {
            let globalEmitters = this.taskEmitters.get(0);
            if(globalEmitters) {
                globalEmitters.emit(event);
            }
        }
        let emitters = this.taskEmitters.get(action == EventService.Created ? 0 : item.id);
        if (emitters) {
            // if(item instanceof CaseUser) {
            //     this.emitTask(new Task(item), action, fields);
            // }
            emitters.emit(event);
        }
    }

    public emitProject(item: Project, action: string, fields?: string[]) {
        let event = new Event(item, action, fields);
        if(action == EventService.Updated || action == EventService.Deleted) {
            let globalEmitters = this.projectEmitters.get(0);
            if(globalEmitters) globalEmitters.emit(event);
        }
        let emitters = this.projectEmitters.get(action == EventService.Created ? 0 : item.id);
        if(emitters) emitters.emit(event);
    }

    public emitTodo(item: Todo, action: string, fields?: string[]) {
        let event = new Event(item, action, fields);
        if(action == EventService.Updated || action == EventService.Deleted) {
            let globalEmitters = this.todoEmitters.get(0);
            if(globalEmitters) globalEmitters.emit(event);
        }
        let emitters = this.todoEmitters.get(action == EventService.Created ? 0 : item.id);
        if(emitters) emitters.emit(event);
    }

    public emitMilestone(item: Milestone, action: string, fields?: string[]) {
        let event = new Event(item, action, fields);
        if(action == EventService.Updated || action == EventService.Deleted) {
            let globalEmitters = this.milestoneEmitters.get(0);
            if(globalEmitters) globalEmitters.emit(event);
        }
        let emitters = this.milestoneEmitters.get(action == EventService.Created ? 0 : item.id);
        if(emitters) emitters.emit(event);
    }

    public emitAppointment(item: Appointment, action: string, fields?: string[]) {
        let event = new Event(item, action, fields);
        if(action == EventService.Updated || action == EventService.Deleted) {
            let globalEmitters = this.appointmentEmitters.get(0);
            if(globalEmitters) globalEmitters.emit(event);
        }
        let emitters = this.appointmentEmitters.get(action == EventService.Created ? 0 : item.id);
        if(emitters) emitters.emit(event);
    }

    public emitDisplayFilter(item: DisplayFilter, action: string, fields?: string[]) {
        let event = new Event(item, action, fields);
        if(action == EventService.Updated || action == EventService.Deleted) {
            let globalEmitters = this.displayFilterEmitters.get(0);
            if(globalEmitters) globalEmitters.emit(event);
        }
        let emitters = this.displayFilterEmitters.get(action == EventService.Created ? 0 : item.id);
        if(emitters) emitters.emit(event);
    }

    // </editor-fold>

}
