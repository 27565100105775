export class BaseModel {

    id?: number;
    type?: string;

    public exists(): boolean {
        return this.id && this.id != 0;
    }

    public clone(): any {
        var cloneObj = new (<any>this.constructor());
        for (var attribut in this) {
            if (typeof this[attribut] === "object") {
                cloneObj[attribut] = this.clone();
            } else {
                cloneObj[attribut] = this[attribut];
            }
        }
        return cloneObj;
    }

    public convertToObject(fields?: string[]): any {
        if (fields) {
            let data: any = {};
            for (let field of fields) {
                data[field] = (this as any)[field];
            }
            return data;
        } else {
            return this;
        }
    }

    public save(callback?: (response?: any) => void, fields?: string[]) {
        if (this.exists()) {
            this.patch(fields, callback);
        } else {
            this.post(callback);
        }
    }

    public post(callback?: () => void) {
        console.warn('BaseModel.ts : post() -> Not yet implemented');
    }

    public patch(fields?: string[], callback?: () => void) {
        console.warn('BaseModel.ts : patch() -> Not yet implemented');
    }

    public patchProperties(data: any, properties: string[]) {
        // We trust data knows about these properties. So remove them and then populate as usual.
        properties.forEach(property => (this as any)[property] = undefined);
        this.populate(data, true);
    }

    public populate(data?: any, patch: boolean = false) {
        if(!data) {
            return;
        }
    }

    // <editor-fold desc="Resource Action / Object Relational Mapping">

    private _touchedFields?: string[];
    public touch(value: string) {
        if (!this._touchedFields) {
            this._touchedFields = [];
        }
        this._touchedFields.push(value);
    }
    public sync(): string[]|undefined {
        const tmp = this._touchedFields;
        this._touchedFields = undefined;
        return tmp;
    }

    // </editor-fold>

}
