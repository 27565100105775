<div
    class="container container-fluid shadow p-0 narrow-editor modal-content base-editor"
    [ngStyle]="windowStyle"

    [class.minimized]="minimized"
    [class.maximized]="maximized"
    [class.un-docked]="!docked"
    [class.loading]="!isReady"
    [class.editable]="editable"

    cdkDrag
    [cdkDragDisabled]="docked"
    [cdkDragFreeDragPosition]="freeDragPosition"

    [@changeState]="isOpen ? 'open' : 'closed'"
    (@changeState.start)="animationStarted($event)"
    (@changeState.done)="animationDone($event)"
>

    <div
        class="h-100"
    >

        <div
            class="d-flex flex-column h-100"
        >
            <div
                class="pt-2 flex-shrink-1 card-header flex-row"
            >
                <div
                    cdkDragHandle
                    class="w-100 mb-0 d-flex justify-content-start w-100 flex-row align-items-center header-row"
                >

                    <app-todo-editor-header
                        class="d-flex align-items-center flex-grow-0 icon-row justify-content-start"
                        [item]="item"
                        [fields]="fields"
                        [validator]="validator"
                    />

                    <app-window-action-buttons
                        class="ms-auto d-mobile-none"
                        (minimize)="onWindowMinimizeClicked()"
                        (maximize)="onWindowMaximizeClicked()"
                        (close)="onCloseBtnClicked()"
                        [validator]="validator"
                    />

                </div>

                <app-todo-editor-title
                    [item]="item"
                    [fields]="fields"
                    (onValidatorChange)="onTitleValidatorChanged($event)"
                />
            </div>

            <div
                class="scroll-y align-self-stretch card-content"
            >
                <ul ngbNav
                    class="nav-tabs tabs-editor"
                    (navChange)="onTabChanged($event)"
                    #nav="ngbNav"
                    [(activeId)]="selectedTab"
                    [destroyOnHide]="false"
                >
                    <li
                        [ngbNavItem]="Globals.Tabs.Basic"
                        [class.active]="nav.activeId == Globals.Tabs.Basic"
                    >
                        <a ngbNavLink class="">
                            <span>
                                <span>{{'_task_basis_information' | translate}}</span>
                                <app-validation-warning-list
                                    [inline]="false"
                                    *ngIf="basicTabValidator"
                                    class="tab-validation-warning-list"
                                    [validatorError]="basicTabValidator"
                                />
                            </span>
                        </a>

                        <ng-template ngbNavContent>
                            <app-todo-editor-tabs-basic
                                [item]="item"
                                [fields]="fields"
                                (validatorChange)="onBasicValidatorChanged($event)"
                            />
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="categories ngb-tabs-content"></div>
            </div>
        </div>

        <app-todo-editor-footer
            class="position-absolute bg-light border-top w-100 p-2 nav nav-pills"
            [item]="item"
            (close)="onCloseBtnClicked()"
            (delete)="onDeleteBtnClicked()"
            [validator]="validator"
        />

    </div>

</div>
