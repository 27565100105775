import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {Phase} from '@app/core/models/Phase';
import moment from 'moment';
import {Deadline} from '@app/core/models/Deadline';
import {Milestone} from '@app/core/models/Milestone';
import {PhasesProject} from '@app/core/models/PhasesProject';
import {Api} from '@app/core/Api';
import {YearWheelColumnConfiguration} from '@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration';
import {YearWheelItem} from '@app/shared/_ui/columns/year-wheel/Helpers/YearWheelItem';
import {PeriodUnits} from '@app/constants';
import {Project, Task} from '@app/core/models';
import {EventService} from '@app/services/event.service';
import {LocalizedDatePipe} from '@app/pipes/localized-date.pipe';
import {BaseYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/BaseYearWheelItem";
import {ProjectYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/ProjectYearWheelItem";
import {PhasesProjectYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/PhasesProjectYearWheelItem";
import {MilestoneYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/MilestoneYearWheelItem";
import {TaskYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/TaskYearWheelItem";

@Component({
    selector: 'app-year-wheel',
    templateUrl: './year-wheel.component.html',
    styleUrls: ['./year-wheel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalizedDatePipe,
    ],
    standalone: false
})
export class YearWheelComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() configuration: YearWheelColumnConfiguration;
    @Input() reloadEvent: EventEmitter<any>;
    @Input() rerenderEvent: EventEmitter<any>;

    @Output() onScrollEvent: EventEmitter<YearWheelColumnConfiguration> = new EventEmitter<YearWheelColumnConfiguration>();

    // Bindings to view
    @ViewChild('container', {static: false}) container: ElementRef;
    @ViewChild('wrapperContainer', {static: false}) wrapperContainer: ElementRef;

    @HostListener('window:resize', ['$event'])
    sizeChange(event: Event) {
        setTimeout((t: any) => {
            this.renderView();
        });
    };

    public allItems: BaseYearWheelItem[];
    public projectItem: ProjectYearWheelItem;
    public phaseItems: PhasesProjectYearWheelItem[];
    public milestoneItems: MilestoneYearWheelItem[];
    public taskItems: TaskYearWheelItem[];
    public periodDaysArray: string[] = [];
    public unitWidth = 200;
    public containerWidth: number;
    public wrapperContainerWidth: number;
    public periodLength = 0; // days, weeks, months,

    // Data
    private periodStartMoment: moment.Moment;
    private periodEndMoment: moment.Moment;
    private minimumDayWidth = 25;
    private maximumDayWidth = 200;
    private periodDatesArray: Date[] = [];
    private disableScrollEvent = false;
    private usedTasks: Map<number, TaskYearWheelItem> = new Map();
    private storedPhasesProjects: PhasesProject[];
    private storedMilestones: Milestone[];
    private updateTimeout: number = null;
    private periodWidths: Map<number, number> = new Map<number, number>();

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private eventsService: EventService) {
        super();
        this.cdr = this.changeDetectorRef;
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.reloadEvent) {
            this.subscribe(this.reloadEvent.subscribe(() => {
                this.renderView();
                this.loadData();
            }));
        }

        if (this.rerenderEvent) {
            this.subscribe(this.rerenderEvent.subscribe(() => {
                this.renderView();
            }));
        }

        this.setupPush();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['configuration'] != null) {
            setTimeout(() => { // To ensure component width
                if (this.configuration && this.configuration.validate()) {
                    if (this.configuration.dataSource) {
                        this.configuration.dataSource.subscribe(((phasesProjects, milestones) => {
                            this.updateItemsAndRender(phasesProjects, milestones);
                        }));

                        this.configuration.onUpdateEvent.subscribe((scrollEvent: YearWheelColumnConfiguration) => {
                            // TO-DO: Set timeout, no trigger scroll
                            if (this.configuration.project.id !== scrollEvent.project.id) {
                                this.disableScrollEvent = true;
                                setTimeout(() => {
                                    this.disableScrollEvent = false;
                                }, 25);
                            }
                            this.wrapperContainer.nativeElement.scrollLeft = scrollEvent.scrollPosition.left;
                        });
                    } else {
                        this.renderView();
                        this.loadData();
                    }
                }
            });
        }
    }

    // <editor-fold desc="View helpers">

    public getColumnWidth(index: number): number {
        if (this.periodWidths.get(index)) {
            return this.periodWidths.get(index);
        }
        if (index !== 0 || this.configuration.periodUnit !== this.Constants.PeriodUnits.Weeks) {
            this.periodWidths.set(index, this.unitWidth);
            return this.unitWidth;
        } else {

            const dateMoment = moment(this.periodDatesArray[index]);
            const startOfWeek = moment(dateMoment).startOf('week');
            const diff = moment.duration(dateMoment.diff(startOfWeek));
            const diffDays = (7 - diff.abs().asDays() + 1);
            const w = this.unitWidth * Math.abs(((diffDays > 7 ? 1 : diffDays) / 7));

            this.periodWidths.set(index, w);
            return w;
        }
    }

    public updateScroll($event: any) {
        if (this.disableScrollEvent) {
            this.configuration.scrollPosition.left = $event.scrollLeft;
            this.configuration.scrollPosition.top = $event.scrollTop;
            this.configuration.updateScrollPosition(this.configuration);
        } else {
            this.configuration.setScrollPosition({top: $event.scrollTop, left: $event.scrollLeft}, true);
        }

        if (!this.disableScrollEvent) {
            this.onScrollEvent.emit(this.configuration);
        }
    }

    // </editor-fold>

    // <editor-fold desc="Render">

    private updateItemsAndRender(phasesProjects: PhasesProject[], milestones: Milestone[]) {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = window.setTimeout(() => {
            this.renderView();
            this.allItems = this.generateItems(phasesProjects, milestones);
            this.detectChanges();
        }, 500);
    }

    private renderView() {
        // Create period days
        if (this.configuration && this.configuration.validate()) {
            this.periodStartMoment = moment(this.configuration.periodStart);
            this.periodEndMoment = moment(this.configuration.periodEnd);
        } else {
            this.periodStartMoment = null;
            this.periodEndMoment = null;
        }

        const periodWeekTranslation = this.translateService.instant('_ui_period_week').substr(0, 1);
        if (this.periodStartMoment && this.periodEndMoment) {

            // Difference in number of units
            this.periodLength = Math.ceil(this.getPeriodInUnits(this.getDiff(this.periodStartMoment, this.periodEndMoment).abs()));

            this.periodWidths = new Map<number, number>();
            this.periodDaysArray = [];
            this.periodDatesArray = [];
            const p = this.periodLength ? Math.ceil(this.periodLength) : 1;
            for (let i = 0; i < p; i++) {
                const date = moment(this.configuration.periodStart);
                let dateString = 'Dato';

                switch (this.configuration.periodUnit) {
                    case PeriodUnits.Days:
                        date.add(i, 'days');
                        dateString = date.format('DD.');
                        break;
                    case PeriodUnits.Weeks:
                        date.add(i, 'weeks');
                        dateString = periodWeekTranslation + '' + date.format('W');
                        break;
                    case PeriodUnits.Months:
                        date.add(i, 'months');

                        // dateString = this.unitWidth <= 12 ? date.format('MM') : date.format('MMMM');
                        dateString = this.unitWidth <= this.minimumDayWidth ? date.format('MMM') : date.format('MMM'); // date.format('MMMM')
                        break;

                }

                this.periodDatesArray.push(date.toDate());
                this.periodDaysArray.push(dateString);
            }

            // Calculate day width based on component width and number of days
            const componentWidth = this.container ? this.container.nativeElement.offsetWidth : 800;
            this.containerWidth = (componentWidth) - 20;
            this.wrapperContainerWidth = (this.wrapperContainer ? this.wrapperContainer.nativeElement.offsetWidth : 800);
            this.unitWidth = this.wrapperContainerWidth / this.periodLength;
            if (this.unitWidth < this.minimumDayWidth) {
                this.unitWidth = this.minimumDayWidth;
            }
            if (this.unitWidth > this.maximumDayWidth && this.periodDaysArray.length > 4) {
                this.unitWidth = this.maximumDayWidth;
            }

            if (this.periodDaysArray.length <= 4) {
                this.unitWidth = this.wrapperContainerWidth / this.periodDaysArray.length;
            }

            if (this.unitWidth * this.periodDaysArray.length < this.wrapperContainerWidth) {
                this.unitWidth = this.wrapperContainerWidth / this.periodDaysArray.length;
            }

            if (this.unitWidth < this.minimumDayWidth) {
                this.unitWidth = this.minimumDayWidth;
            }
            this.unitWidth = Math.round(this.unitWidth);
        } else {
            console.warn('Warning: Not able to renderView() : ', this.container, this.periodStartMoment, this.periodEndMoment);
        }
    }

    // </editor-fold>

    // <editor-fold desc="Load data">

    private loadData() {
        if (this.configuration && this.configuration.validate()) {

            const API =
                Api
                    .projects().getById(this.configuration.project.id)
                    .include('milestone.deadline')
                    .include('milestone.main_status')
                    .include('phases_project');
            if (this.configuration.milestone?.id) {
                API
                    .where('milestone.id', this.configuration.milestone.id)
                    .include('milestone.task');
            }
            if (this.configuration.autoPeriod) {
                API.include('milestone.task');
            }

            API
                .find(projects => {
                    const project = projects[0];

                    // Headless; show all elements in a project (Used in card-project)
                    if (this.configuration.autoPeriod) {
                        let startDateMoment = moment(this.configuration.periodStart);
                        let endDateMoment = moment(this.configuration.periodEnd);
                        if (project.milestones) {
                            project.milestones.forEach(m => {
                                if (m.deadline && m.deadline.getDate() > endDateMoment.toDate()) {
                                    endDateMoment = moment(m.deadline.getDate());
                                }

                                if (m.deadline && m.deadline.getDate() < startDateMoment.toDate()) {
                                    startDateMoment = moment(m.deadline.getDate()).startOf('day').subtract(1, 'day');
                                }

                                m.tasks?.forEach(t => {
                                    const deadline = t.findDeadlineByTypeId(t.getMiniCardDeadlineTypeId());
                                    if (deadline && deadline.getDate() > endDateMoment.toDate()) {
                                        endDateMoment = moment(deadline.getDate());
                                    }

                                    if (deadline && deadline.getDate() < startDateMoment.toDate()) {
                                        startDateMoment = moment(deadline.getDate()).startOf('day').subtract(1, 'day');
                                    }
                                });

                            });
                        }
                        this.configuration.periodStart = startDateMoment.toDate();
                        this.configuration.periodEnd = endDateMoment.toDate();
                        this.configuration.periodUnit = PeriodUnits.Months;
                        this.renderView();
                    }

                    this.usedTasks = new Map<number, TaskYearWheelItem>();

                    project.phases_projects = project.phases_projects?.filter(value => value.phase_id != 0);

                    this.allItems = this.generateItems(project.phases_projects ?? [], project.milestones ?? []);
                    this.detectChanges();
                });
        }
    }

    private generateItems(phasesProjects: PhasesProject[], milestones: Milestone[]): BaseYearWheelItem[] {
        phasesProjects = phasesProjects.reverse().filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        );

        milestones = milestones.reverse().filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
        );

        this.storedPhasesProjects = phasesProjects; // used for push updates
        this.storedMilestones = milestones;

        let items: BaseYearWheelItem[] = [];

        const periodStart = this.configuration.periodStart;
        const periodEnd = this.configuration.periodEnd;

        // Period / Project
        if (this.configuration.displayOptions.showProject) {
            let projectPeriodEnd: Date;
            if (this.configuration.projectStartDeadline && this.configuration.projectEndDeadline && this.configuration.projectStartDeadline.deadline) {
                projectPeriodEnd = this.configuration.projectEndDeadline.deadline.getDate();
            } else {
                projectPeriodEnd = this.configuration.periodEnd;
            }
            if (projectPeriodEnd.getTime() >= this.configuration.periodStart.getTime()) {
                this.projectItem = this.generateProject(this.configuration.project);
                items.push(this.projectItem);
            }
        }

        // Phases
        if (this.configuration.displayOptions.showPhase) {
            if (phasesProjects.length) {
                const filter1 = phasesProjects
                    // Only allow phases with changeable date and date set
                    .filter(phasesProject => phasesProject.phase?.changeable_date && phasesProject.getStartedDate() != undefined);
                const filter2 = filter1
                    .sort((a, b) => {
                        return (a.start ? a.getStartedDate().getTime() : 0) - (b.start ? b.getStartedDate().getTime() : 0);
                    })
                    // Date must be inside visible period (Or have the following phase inside period)
                    .filter((phasesProject, index) => {
                        const isInsidePeriod = phasesProject.getStartedDate() >= this.periodStartMoment.toDate()
                            && phasesProject.getStartedDate() <= this.periodEndMoment.toDate();
                        const nextPhaseWithStartDate = filter1.find((nextPhasesProject, nextIndex) => {
                            return nextIndex > index && nextPhasesProject.getStartedDate() != undefined;
                        });
                        const isNextPhaseInsidePeriod = nextPhaseWithStartDate != undefined
                            && nextPhaseWithStartDate.getStartedDate() >= this.periodStartMoment.toDate()
                            && nextPhaseWithStartDate.getStartedDate() <= this.periodEndMoment.toDate();
                        return isInsidePeriod || isNextPhaseInsidePeriod;
                    });
                filter2.forEach((phaseProject, index) => {
                    const nextPhaseWithStartDate = filter2.find((nextPhasesProject, nextIndex) => {
                        return nextIndex > index && nextPhasesProject.getStartedDate() != undefined;
                    });
                    const phaseItem = this.generatePhasesProject(
                        phaseProject,
                        nextPhaseWithStartDate?.getStartedDate()
                    );
                    items.push(phaseItem);
                });
            }
        }

        // Milestones
        if (this.configuration.displayOptions.showMilestone || this.configuration.displayOptions.showTask) {
            milestones
                // Add optional filter by specific milestone id
                .filter(milestone => !this.configuration.milestone || this.configuration.milestone.id == milestone.id)
                // Must have deadline in visible period
                .filter(milestone => {
                    return milestone.deadline?.getDate() >= periodStart && milestone.deadline?.getDate() <= periodEnd
                })
                .forEach(milestone => {
                    items.push(this.generateMilestone(milestone));
                });
        }

        // Tasks
        if (this.configuration.displayOptions.showTask) {
            // Add optional filter by specific milestone id
            milestones
                .filter(milestone => !this.configuration.milestone || this.configuration.milestone.id == milestone.id)
                .forEach(milestone => {
                    milestone.tasks?.forEach(task => {
                        const deadlineType = task.getMiniCardDeadlineTypeId();
                        const deadline = task.findDeadlineByTypeId(deadlineType);
                        if (deadline) {
                            items.push(this.generateTask(task));
                        }
                    });
                });
        }


        // Split items into types
        this.projectItem = undefined;
        this.phaseItems = [];
        this.milestoneItems = [];
        this.taskItems = [];
        items.forEach(item => {
            switch (item.constructor) {
                case ProjectYearWheelItem:
                    this.projectItem = item as ProjectYearWheelItem;
                    break;
                case PhasesProjectYearWheelItem:
                    this.phaseItems.push(item as PhasesProjectYearWheelItem);
                    break;
                case MilestoneYearWheelItem:
                    this.milestoneItems.push(item as MilestoneYearWheelItem);
                    break;
                case TaskYearWheelItem:
                    this.taskItems.push(item as TaskYearWheelItem);
                    break;
            }
        })

        this.usedTasks = new Map<number, TaskYearWheelItem>();

        // Find overlaps
        items = items.sort((a, b) => {
            return (a.startDate ? a.startDate.getTime() : 0) - (b.startDate ? b.startDate.getTime() : 0);
        });

        this.milestoneItems.forEach((item, index) => {
            item.relatedMilestones = this.milestoneItems
                .filter(related => {

                    const withinDateRange =
                        related.unitStart == item.unitStart &&
                        related.unitEnd == item.unitEnd;

                    let ignoreWeekOverlap = true;

                    if (this.configuration.periodUnit === PeriodUnits.Weeks) {
                        // Hvis det er ugevisning; tjek perioderne
                        const w1 = moment(related.startDate).startOf('isoWeek').toDate().getTime();
                        const w2 = moment(item.startDate).startOf('isoWeek').toDate().getTime();
                        ignoreWeekOverlap = w1 == w2;
                    }

                    if (this.configuration.periodUnit === PeriodUnits.Months) {
                        // Hvis det er ugevisning; tjek perioderne
                        const w1 = moment(related.startDate).startOf('isoWeek').format('M');
                        const w2 = moment(item.startDate).startOf('isoWeek').format('M');
                        ignoreWeekOverlap = w1 == w2;
                    }

                    const duplicates = item.relatedMilestones.findIndex(d => d.item.id == related.item.id && item.item.constructor == related.item.constructor) !== -1;

                    const allGood = related.item.id != item.item.id
                        && withinDateRange && !duplicates && ignoreWeekOverlap;

                    return allGood;
                });
        });

        this.taskItems.forEach(item => {
            item.relatedTasks = this.taskItems
                .filter(related => {

                    let withinDateRangeT = false;

                    if (this.configuration.periodUnit === PeriodUnits.Days) {
                        // Hvis det er samme dag; tjek perioderne
                        const w1 = moment(related.startDate).toDate().getTime();
                        const w2 = moment(item.startDate).toDate().getTime();
                        withinDateRangeT = w1 == w2;
                    }

                    if (this.configuration.periodUnit === PeriodUnits.Weeks) {
                        // Hvis det er ugevisning; tjek perioderne
                        const w1 = moment(related.startDate).startOf('isoWeek').toDate().getTime();
                        const w2 = moment(item.startDate).startOf('isoWeek').toDate().getTime();
                        withinDateRangeT = w1 == w2;
                        withinDateRangeT = (this.getPeriodInUnits(this.getDiff(moment(related.startDate), moment(item.startDate)).abs())) < 1;
                    }

                    if (this.configuration.periodUnit === PeriodUnits.Months) {
                        withinDateRangeT = related.startDate.getMonth() == item.startDate.getMonth();
                    }

                    const allGoodT = related.relatedTasks.length == 0
                        && related.item.id != item.item.id && withinDateRangeT
                        && !this.usedTasks.has(related.item.id);

                    return allGoodT;
                });
        });

        const usedMilestones: number[] = [];

        this.milestoneItems.forEach((item, index) => {
            item.relatedMilestones.forEach(related => {
                switch (this.configuration.periodUnit) {
                    case PeriodUnits.Days:
                        if (related.startDate > item.startDate) {
                            related.visible = false;
                        }
                        break;
                    case PeriodUnits.Weeks:
                        if (usedMilestones.findIndex(d => d == related.item.id) == -1) {
                            related.visible = true;
                            usedMilestones.push(related.item.id);
                        }
                        if (related.startDate > item.startDate) {
                            related.visible = false;
                        }
                        break;
                    case PeriodUnits.Months:
                        if (usedMilestones.findIndex(d => d == related.item.id) == -1) {
                            related.visible = true;
                            usedMilestones.push(related.item.id);
                        }
                        if (related.startDate > item.startDate) {
                            related.visible = false;
                        }
                        break;
                    default:
                        break;
                }
            });
            item.sortedMilestones = [item, ...item.relatedMilestones].sort((a, b) => {
                if (!a.startDate) {
                    return -1;
                }
                return (a.startDate ? a.startDate.getTime() : 0) - (b.startDate ? b.startDate.getTime() : 0);
            });
        });
        this.taskItems.forEach((item, index) => {
            item.relatedTasks.forEach(related => {
                related.visible = true;
                switch (this.configuration.periodUnit) {
                    case PeriodUnits.Days:
                        if (related.startDate >= item.startDate) {
                            related.visible = false;
                        }
                        break;
                    case PeriodUnits.Weeks:
                        if (related.startDate >= item.startDate) {
                            related.visible = false;
                        }
                        break;
                    case PeriodUnits.Months:
                        if (related.startDate.getMonth() == item.startDate.getMonth()) {
                            related.visible = false;
                        }
                        break;
                    default:
                        break;
                }
            });
            item.sortedTasks = [item, ...item.relatedTasks].sort((a, b) => {
                if (!a.startDate) {
                    return -1;
                }
                return (a.startDate ? a.startDate.getTime() : 0) - (b.startDate ? b.startDate.getTime() : 0);
            });
        });

        return items.sort((a, b) => {
            if (!a.startDate) {
                return -1;
            }
            return (a.startDate ? a.startDate.getTime() : 0) - (b.startDate ? b.startDate.getTime() : 0);
        });
    }

    private getPeriodInUnits(item: moment.Duration) {
        switch (this.configuration.periodUnit) {
            case PeriodUnits.Days:
                return item.asDays();
            case PeriodUnits.Weeks:
                return item.asWeeks();
            case PeriodUnits.Months:
                return item.asMonths();
            default:
                console.warn('missing type in configuration: ', this.configuration);
                break;
        }
    }

    private generateProject(item: Project): ProjectYearWheelItem {
        let projectStartDate = this.configuration.projectStartDeadline?.deadline?.getDate() ?? this.configuration.periodStart;
        let projectEndDate = this.configuration.projectEndDeadline?.deadline?.getDate() ?? this.configuration.periodEnd;

        const isProjectStartingBeforeVisiblePeriod = projectStartDate <= this.configuration.periodStart;
        const isProjectEndingAfterVisiblePeriod = this.configuration.periodEnd < projectEndDate;

        // Trim project to visible period
        if (isProjectStartingBeforeVisiblePeriod) {
            projectStartDate = this.configuration.periodStart;
        }
        if (isProjectEndingAfterVisiblePeriod) {
            projectEndDate = this.configuration.periodEnd;
        }

        const xPosInUnits = this.getPeriodInUnits(
            moment
                .duration(this.periodStartMoment.diff(moment(projectStartDate)))
                .abs()
        );
        const unitsInProjectPeriod = this.getPeriodInUnits(this.getDiff(moment(projectStartDate), moment(projectEndDate)).abs());

        return new ProjectYearWheelItem(
            this.configuration,
            item,

            // x-position in pixels
            xPosInUnits * this.unitWidth,

            // Width
            unitsInProjectPeriod * this.unitWidth,

            // Start & end Date
            projectStartDate,
            projectEndDate
        );
    }

    private generatePhasesProject(item: PhasesProject, nextItemStartDate?: Date): PhasesProjectYearWheelItem {
        let itemStartDate = item.getStartedDate() ?? this.configuration.periodStart;
        let itemEndDate = nextItemStartDate ?? this.configuration.projectEndDeadline?.deadline?.getDate();

        const isItemStartingBeforeVisiblePeriod = itemStartDate < this.configuration.periodStart;
        const isItemEndingAfterVisiblePeriod = this.configuration.periodEnd < itemEndDate;
        const isItemStartAfterProjectEnd = this.projectItem && (itemStartDate > this.projectItem.endDate);
        const hasItemEndDate = itemEndDate != undefined;

        // Trim item to visible period
        if (isItemStartingBeforeVisiblePeriod) {
            itemStartDate = this.configuration.periodStart;
        }
        if (isItemEndingAfterVisiblePeriod) {
            itemEndDate = this.configuration.periodEnd;
        }

        // Fix end to period if
        // 1) No following phase is available
        // 2) The project does not have an end date
        // 3) Phase start date is after project end
        if (!itemEndDate || isItemStartAfterProjectEnd) {
            itemEndDate = this.periodEndMoment.toDate();
        }

        const xPosInUnits = this.getPeriodInUnits(
            moment
                .duration(this.periodStartMoment.diff(moment(itemStartDate)))
                .abs()
        );
        const unitsInProjectPeriod = this.getPeriodInUnits(this.getDiff(moment(itemStartDate), moment(itemEndDate)).abs());

        return new PhasesProjectYearWheelItem(
            this.configuration,
            item,

            item.getStartedDate() != undefined,
            hasItemEndDate && !isItemStartAfterProjectEnd,

            // x-position in pixels
            xPosInUnits * this.unitWidth,

            // Width
            unitsInProjectPeriod * this.unitWidth,

            // Start & end Date
            itemStartDate,
            itemEndDate
        );
    }

    private generateMilestone(item: Milestone): MilestoneYearWheelItem {
        let itemStartDate = item.deadline.getDate();
        let itemEndDate = item.deadline.getDate();

        // Adjust start & end to fit day/week/month units
        switch (this.configuration.periodUnit) {
            case PeriodUnits.Days:
                itemStartDate = moment(itemStartDate).startOf('day').toDate();
                itemEndDate = moment(itemEndDate).endOf('day').toDate();
                break;
            case PeriodUnits.Weeks:
                itemStartDate = moment(itemStartDate).startOf('day').toDate();
                itemEndDate = moment(itemEndDate).endOf('day').toDate();
                break;
            case PeriodUnits.Months:
                itemStartDate = moment(itemStartDate).startOf('day').toDate();
                itemEndDate = moment(itemEndDate).endOf('day').toDate();
                break;
        }

        let periodUnits = 0;
        switch (this.configuration.periodUnit) {
            case PeriodUnits.Days:
                periodUnits = 1;
                break;
            case PeriodUnits.Weeks:
                periodUnits = 1;
                break;
            case PeriodUnits.Months:
                periodUnits = .25;
                break;
        }

        let xPosInUnits = this.getPeriodInUnits(
            moment
                .duration(this.periodStartMoment.diff(moment(itemStartDate)))
                .abs()
        );
        let width = periodUnits * this.unitWidth;

        // Apply last minute adjustments
        let xPosInPixelsPush = 0;
        switch (this.configuration.periodUnit) {
            case PeriodUnits.Months:
                xPosInUnits = 10;
                width = Math.max(((width / 4) - 10), 20);
                break;
            default:
                width = Math.max((width / 4), 20);
        }

        return new MilestoneYearWheelItem(
            this.configuration,
            item,

            // x-position in pixels
            xPosInUnits * this.unitWidth + xPosInPixelsPush,

            width,

            item.deadline.getDate()
        );
    }

    private generateTask(item: Task): TaskYearWheelItem {
        const itemStartDate = item.findDeadlineByTypeId(item.getMiniCardDeadlineTypeId()).getDate();

        let periodUnits = 0;
        switch (this.configuration.periodUnit) {
            case PeriodUnits.Days:
                periodUnits = 1;
                break;
            case PeriodUnits.Weeks:
                periodUnits = 1;
                break;
            case PeriodUnits.Months:
                periodUnits = .25;
                break;
        }

        let xPosInUnits = this.getPeriodInUnits(
            moment
                .duration(this.periodStartMoment.diff(moment(itemStartDate)))
                .abs()
        );
        let width = periodUnits * this.unitWidth;

        // Apply last minute adjustments
        let xPosInPixelsPush = 0;
        switch (this.configuration.periodUnit) {
            case PeriodUnits.Months:
                xPosInUnits = 10;
                width = Math.max(((width / 4) - 10), 20);
                break;
            default:
                width = Math.max((width / 4), 20);
        }

        return new TaskYearWheelItem(
            this.configuration,
            item,

            // x-position in pixels
            xPosInUnits * this.unitWidth + xPosInPixelsPush,

            width,

            itemStartDate
        );
    }

    private getDiff(periodStartMoment: moment.Moment, periodEndMoment: moment.Moment) {
        switch (this.configuration.periodUnit) {
            case this.Constants.PeriodUnits.Days:
                return moment.duration(periodEndMoment.diff(periodStartMoment));
            case this.Constants.PeriodUnits.Weeks:
                return moment.duration(periodEndMoment.diff(periodStartMoment));
            case this.Constants.PeriodUnits.Months:
                return moment.duration(periodEndMoment.diff(periodStartMoment));
        }
        return undefined;
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    private setupPush() {
        this.subscribe(this.eventsService.subscribeToTask(0, (event) => {
            const task = new Task(event.item);
            switch (event.action) {
                case EventService.Updated:
                    this.storedMilestones.forEach(m => {
                        if (m.tasks) {
                            if (m.tasks.findIndex(t => t.id === task.id) !== -1) {
                                m.tasks = m.tasks?.filter(t => t.id !== task.id);
                                m.tasks.push(task);
                            }
                        }
                    });
                    break;
                case EventService.Deleted:
                    this.storedMilestones.forEach(m => {
                        m.tasks = m.tasks?.filter(t => t.id !== task.id);
                    });
                    break;
            }

            this.updateItemsAndRender(this.storedPhasesProjects, this.storedMilestones);
        }));

        this.subscribe(this.eventsService.subscribeToMilestone(0, (event) => {
            const milestone = new Milestone(event.item);
            switch (event.action) {
                case EventService.Created:
                    this.renderView();
                    this.loadData();
                    break;
                case EventService.Updated:
                    if (this.storedMilestones.findIndex(m => m.id === milestone.id) !== -1) {
                        milestone.tasks = this.storedMilestones?.find(m => m.id === milestone.id)?.tasks;
                        this.storedMilestones = this.storedMilestones?.filter(m => m.id !== milestone.id);
                        this.storedMilestones.push(milestone);
                    }
                    break;
                case EventService.Deleted:
                    this.storedMilestones = this.storedMilestones?.filter(t => t.id !== milestone.id);
                    break;
            }

            this.updateItemsAndRender(this.storedPhasesProjects, this.storedMilestones);
        }));
    }

    // </editor-fold>

    protected readonly Milestone = Milestone;
    protected readonly PhasesProject = PhasesProject;
    protected readonly Project = Project;
    protected readonly Task = Task;
}
