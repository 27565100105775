import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {CategoryTableColumn} from "@app/pages/displays/display-category/TableColumns/CategoryTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-category/ColumnTypeSettings";
import {Category_Category_FilterCategorySettingValue} from "@app/core/Api";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-category/Filters";
import {Category, Color} from "@app/core/models";

export class CategoryColumn extends BaseColumn {

    public category: Category;
    public color?: Color;

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new CategoryTableColumn(this);

        // Prepare static column sorts. Dynamic column sorts are added later
        tableColumn.sortItems = [
            PageColumnSort.CreateWithSortId(Filters.SortDeadline),
            PageColumnSort.CreateWithSortId(Filters.SortTitle),
            PageColumnSort.CreateWithSortId(Filters.SortUsers),
            PageColumnSort.CreateWithSortId(Filters.SortStatus),
            PageColumnSort.CreateWithSortId(Filters.SortStarred),
            PageColumnSort.CreateWithSortId(Filters.SortHandUp),
        ];

        return [tableColumn];
    }

    public createCell(row: BaseRow): BaseCell {
        throw new Error("Method not implemented.");
    }

    public setCategory(category: Category): void {
        this.category = category;
        this.color = category.color;
    }

    public getFilterCategory(): number {
        return parseInt(String(this.settings.get(ColumnTypeSettings.Category_FilterCategory)
            ?.getObject<Category_Category_FilterCategorySettingValue>()
            ?.categoryId ?? 0));
    }

}
