import {PageComponent} from "@app/pages/page.component";
import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from "@angular/core";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-milestones/Filters";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-milestones/Row";
import {StatusTypes, TaskDeadlineTypes, TaskUserTypes,} from "@app/constants";
import {Api} from "@app/core/Api";
import {Deadline, Milestone, Project, Task, User} from "@app/core/models";
import {ProjectsService} from "@app/services/projects.service";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {CreateItemSourceConfiguration} from "@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration";
import {CreateItemPreset} from "@app/shared/_ui/create-item-dropdown/CreateItemPreset";
import {CreateItemInterface} from "@app/shared/_ui/create-item-dropdown/CreateItemInterface";
import {EventService} from "@app/services/event.service";
import {DatatableComponent} from "@swimlane/ngx-datatable";
import {ComponentVisibilityObserver} from "@app/shared/_system/base/ComponentVisibilityObserver";
import {RowEventsInterface} from "@app/pages/displays/display-project-details/subdisplay-milestones/RowEventsInterface";
import {SubDisplayHelper} from "@app/pages/displays/display-project-details/SubDisplayHelper";
import {TitleEditorComponent} from "@app/editor/quick-editor/editors/generic/title-editor/title-editor.component";
import {TextEditorComponent} from "@app/editor/quick-editor/editors/generic/text-editor/text-editor.component";
import {EditTextConfiguration} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextConfiguration";
import {LinkEditorComponent} from "@app/editor/quick-editor/editors/generic/link-editor/link-editor.component";
import {
    WhoWhenEditorComponent
} from "@app/editor/quick-editor/editors/generic/who-when-editor/who-when-editor.component";
import {EditLinkConfiguration} from "@app/editor/quick-editor/editors/generic/link-editor/EditLinkConfiguration";
import {EditTitleConfiguration} from "@app/editor/quick-editor/editors/generic/title-editor/EditTitleConfiguration";
import {
    EditWhoWhenConfiguration
} from "@app/editor/quick-editor/editors/generic/who-when-editor/EditWhoWhenConfiguration";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {
    NonArchivedFilter
} from "@app/pages/displays/display-project-details/subdisplay-milestones/OnScreenFilters/NonArchivedFilter";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {ScreenshotHelper} from "@app/core/ScreenshotHelper/ScreenshotHelper";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {
    TaskDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDeadlinePresetGenerator";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {
    MilestoneProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneProjectPresetGenerator";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {TemplateTypes} from "@app/pages/displays/display-project-details/subdisplay-milestones/TemplateTypes";
import {
    MilestoneCardColumnType
} from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypes/MilestoneCardColumnType";
import {
    TaskEditColumnType
} from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypes/TaskEditColumnType";
import {
    TaskListStatusColumnType
} from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypes/TaskListStatusColumnType";
import {
    TaskListPeriodColumnType
} from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypes/TaskListPeriodColumnType";
import {
    TaskListAllColumnType
} from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypes/TaskListAllColumnType";
import {
    MilestoneEditColumnType
} from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypes/MilestoneEditColumnType";
import {
    MilestoneYearWheelColumnType
} from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypes/MilestoneYearWheelColumnType";
import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/GenericTableColumn";
import {
    TaskListStatusColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/TaskListStatusColumn";
import {ColumnTypes} from "@app/pages/displays/display-project-details/subdisplay-milestones/ColumnTypes";
import {DataFetcherCollection} from "@app/core/DataFetchers/DataFetcherCollection";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {
    TaskListPeriodColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/TaskListPeriodColumn";
import {
    TaskListPeriodTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/TaskListPeriodTableColumn";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {
    TaskListAllColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/TaskListAllColumn";
import {YearWheelDisplayOptions} from "@app/shared/_ui/columns/year-wheel/Helpers/YearWheelColumnConfiguration";
import {
    MilestoneYearWheelColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/MilestoneYearWheelColumn";
import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-milestones/Columns/TaskEditColumn";
import {
    TaskListAllTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/TaskListAllTableColumn";
import {
    TaskListStatusTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/TaskListStatusTableColumn";
import {
    TaskEditTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/TableColumns/TaskEditTableColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    TaskEdit_Card_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/EditorTableColumns/TaskEdit_Card_TableColumn";
import {
    BaseEditorTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-milestones/EditorTableColumns/BaseEditorTableColumn";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";

@Component({
    selector: 'app-display-project-details-milestones',
    templateUrl: './display-project-details-milestones.component.html',
    styleUrls: ['./display-project-details-milestones.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DisplayProjectDetailsMilestonesComponent extends PageComponent implements OnInit, AfterViewChecked,
    CreateItemInterface<Milestone>, RowEventsInterface {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();

    // Bindings to parent
    @Input() displayId: number;
    @Input() projectId: number;
    @Input() taskEdit_taskDeadlineTypeId: number;
    @Input() taskEdit_taskEstimateTypeId: number;
    @Input() onReloadEventEmitter: EventEmitter<any>;
    @Output() onShellPageDataChangeEvent = new EventEmitter<ShellPageData>();

    // Bindings to view
    public templateTypes = new TemplateTypes();
    public tableRows: Row[];
    public tableColumns: GenericTableColumn[];
    public createItemSourceConfiguration: CreateItemSourceConfiguration;
    public createItemPreset: CreateItemPreset;
    public onScreenFilters = [new NonArchivedFilter(false)];
    public showPeriodColumnWarning: boolean;
    public isMinimized = true;
    public selectedParticipants?: User[]; // Brugere på opgaver
    public selectedMilestonesResponsible?: User[]; // Brugere på milepæle
    public onStatusColumnSortRenderEventEmitter = new EventEmitter();
    public onPeriodColumnSortRenderEventEmitter = new EventEmitter();
    public dataFetcherCollection = new DataFetcherCollection();

    // UI: DataTable
    @ViewChild('dataTableContainer', {static: false}) dataTableContainerElement: any;
    @ViewChild('dataTable', {static: false}) table: DatatableComponent;
    @ViewChild('headerTemplate', {static: true}) headerTemplate: TemplateRef<any>;
    @ViewChild('headerMilestoneCardTemplate', {static: true}) headerMilestoneCardTemplate: TemplateRef<any>;
    @ViewChild('headerWithPeriodTemplate', {static: true}) headerWithPeriodTemplate: TemplateRef<any>;
    @ViewChild('headerWithStatusTemplate', {static: true}) headerWithStatusTemplate: TemplateRef<any>;
    @ViewChild('headerTemplateYearWheel', {static: true}) headerTemplateYearWheel: TemplateRef<any>;
    @ViewChild('headerTaskEditTemplate', {static: true}) headerTaskEditTemplate: TemplateRef<any>;
    @ViewChild('cellMilestoneCardTemplate', {static: true}) cellMilestoneCardTemplate: TemplateRef<any>;
    @ViewChild('cellTaskListTemplate', {static: true}) cellTaskListTemplate: TemplateRef<any>;
    @ViewChild('cellTaskEditListTemplate', {static: true}) cellTaskEditListTemplate: TemplateRef<any>;
    @ViewChild('cellMilestoneEditListTemplate', {static: true}) cellMilestoneEditListTemplate: TemplateRef<any>;
    @ViewChild('cellTaskListStatusTemplate', {static: true}) cellTaskListStatusTemplate: TemplateRef<any>;
    @ViewChild('cellTaskListPeriodTemplate', {static: true}) cellTaskListPeriodTemplate: TemplateRef<any>;
    @ViewChild('cellYearWheelTemplate', {static: true}) cellYearWheelTemplate: TemplateRef<any>;
    @ViewChild('cellTaskEditListWhoWhenHeaderTemplate', {static: true}) cellTaskEditListWhoWhenHeaderTemplate: TemplateRef<any>;

    // Data
    private project?: Project;
    private rows?: Row[];
    private rowsMap?: Map<number, Row>;

    constructor(private cd: ChangeDetectorRef,
                private projectsService: ProjectsService
    ) {
        super();
        this.cdr = cd;
        this.isMainDisplay = false;
        this.initialized = false;
    }

    ngOnInit() {
        super.ngOnInit();
        this.createItemSourceConfiguration = CreateItemSourceConfiguration.Create(); // Bruges i header for rækkerne (På tværs af milepæle)
        this.createItemSourceConfiguration.showProjects = false;
        this.createItemSourceConfiguration.showTasks = false;
        this.createItemSourceConfiguration.showTodo = false;
        this.createItemSourceConfiguration.showMilestone = true;
        this.createItemSourceConfiguration.showEditor = true;

        // Create new milestones configuration
        // Create task, is set in row.taskEditListConfiguration?.createItemPreset => Row.ts
        this.createItemPreset = new CreateItemPreset();
        this.createItemPreset.createMilestoneInterface = this;

        this.onScreenFilters.forEach(onScreenFilter => {
            this.subscribe(onScreenFilter.onEnabledChangeEvent.subscribe(() => {
                this.renderRows();
            }));
        });

        if (this.onReloadEventEmitter) {
            this.subscribe(this.onReloadEventEmitter.subscribe(() => this.reload()));
        }
    }

    private tableVisibilityObserver: ComponentVisibilityObserver;

    ngAfterViewChecked() {
        if (!this.tableVisibilityObserver && this.table?.element) {
            this.tableVisibilityObserver = new ComponentVisibilityObserver(this.table.element);
            this.subscribe(this.tableVisibilityObserver.onVisibilityChangeEvent.subscribe(isVisible => {
                if (isVisible && this.rows) {
                    this.renderRows();
                }
            }));
            this.addComponentVisibilityObserver(this.tableVisibilityObserver);
        }
    }

    // <editor-fold desc="View Actions">

    public reload() {
        this.loadData();
    }

    public updateRows() {
        this.renderRows();
    }

    public createPresets(options?: any): CreatePreset[] {
        return [
            // Defaults
            new MilestoneUseStatusRulesPresetGenerator(true),
            new MilestoneStatusPresetGenerator(StatusTypes.GREEN),

            new MilestoneProjectPresetGenerator(this.project.id),
        ].map(generator => generator.generate());
    }

    public addParticipantToAllTasks(user: User) {
        this.tableRows.forEach(row => {
            row.taskEditListItems?.forEach(cardItem => {
                if (!cardItem.item.findParticipant(user.id)) {
                    cardItem.item.addUser(TaskUserTypes.Participant, user);
                }
            });
        });
    }

    public removeParticipantFromAllTasks(user: User) {
        this.tableRows.forEach(row => {
            row.taskEditListItems?.forEach(cardItem => {
                if (cardItem.item.findParticipant(user.id)) {
                    cardItem.item.removeUser(TaskUserTypes.Participant, user);
                }
            });
        });
    }

    public removeResponsibleFromAllMilestones(user: User) {
        this.tableRows.forEach(row => {
            const milestone = row.milestoneCard.item;
            milestone.setResponsible(null);
        });
    }

    public addResponsibleToAllMilestones(user: User) {
        this.tableRows.forEach(row => {
            const milestone = row.milestoneCard.item;
            milestone.setResponsible(user);
        });
    }

    public toggleIsMinimized() {
        this.isMinimized = !this.isMinimized;
        this.columnController.getColumns<TaskEditColumn>(ColumnTypes.TaskEdit).forEach(column => {
            column.enabledEditors.forEach(editor => {
                switch (editor.name) {
                    case TitleEditorComponent.name: // Titel
                        (editor.configuration as EditTitleConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                    case TextEditorComponent.name: // Noter
                        (editor.configuration as EditTextConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                    case LinkEditorComponent.name: // Link
                        (editor.configuration as EditLinkConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                    case WhoWhenEditorComponent.name: // Hvem & Hvornår,
                        (editor.configuration as EditWhoWhenConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                }
            });
        });
    }

    public setRowTaskEditListItems(row: Row, items: CardItem<Task>[]) {
        row.taskEditListItems = items;
        const selectedParticipants = new Map<number, User>();
        const selectedMilestonesResponsible = new Map<number, User>();
        this.rows.forEach(row => {
            if (row.milestoneCard.item?.responsible?.id) {
                selectedMilestonesResponsible.set(row.milestoneCard.item.responsible.id, row.milestoneCard.item.responsible);
            }
            row.taskEditListItems?.forEach(card => {
                card.item.findTasksUsersByType(TaskUserTypes.Participant)?.forEach(tasksUser => {
                    selectedParticipants.set(tasksUser.user_id, tasksUser.user);
                });
            });
        })
        this.selectedParticipants = Array.from(selectedParticipants.values());
        this.selectedMilestonesResponsible = Array.from(selectedMilestonesResponsible.values());

        this.detectChanges();
    }

    public onCaptureScreenshotBtnClicked() {
        if (this.isCapturingScreenshot) {
            return;
        }
        this.isCapturingScreenshot = true;
        ScreenshotHelper.Capture(this.dataTableContainerElement.nativeElement, () => {
            this.isCapturingScreenshot = false;
            this.detectChanges();
        })
    }

    public onColumnSortChanged(tableColumn: BaseTableColumn) {
        if (tableColumn.column.implementsSorting) {
            const sortableColumn = tableColumn.column as unknown as SortableColumnInterface;
            this.tableRows.forEach(row => {
                sortableColumn.applyRowSort(row, tableColumn);
            });
        }

        if (tableColumn.column.implementsDataFetching) {
            const column = tableColumn.column as unknown as ColumnDataFetcherInterface;
            column.getDataFetchers().forEach(fetcher => fetcher.execute());
        }

        if (tableColumn instanceof TaskListStatusTableColumn) {
            tableColumn.column.getTableColumns<TaskListStatusTableColumn>().forEach(statusTableColumn => {
                statusTableColumn.activeSortItem = tableColumn.activeSortItem;
                statusTableColumn.activeSortTypeId = tableColumn.activeSortTypeId;
                statusTableColumn.activeSortDirection = tableColumn.activeSortDirection;
            });
            this.onStatusColumnSortRenderEventEmitter.emit();
        }

        if (tableColumn instanceof TaskListPeriodTableColumn) {
            tableColumn.column.getTableColumns<TaskListPeriodTableColumn>().forEach(periodTableColumn => {
                periodTableColumn.activeSortItem = tableColumn.activeSortItem;
                periodTableColumn.activeSortTypeId = tableColumn.activeSortTypeId;
                periodTableColumn.activeSortDirection = tableColumn.activeSortDirection;
            });
            this.onPeriodColumnSortRenderEventEmitter.emit();
        }
    }

    public onYearWheelUnitChanged(unit: string) {
        this.rows?.forEach(row => {
            this.columnController.getColumns<MilestoneYearWheelColumn>(ColumnTypes.MilestoneYearWheel).forEach(column => {
                const yearWheelConfiguration = column.getCell(row).yearWheelConfiguration;
                if (yearWheelConfiguration.validate()) {
                    yearWheelConfiguration.setPeriodUnit(unit);
                }
                column.dataFetcher.execute();
            });
        });
    }

    public onYearWheelDateChanged(period: { start: Date; end: Date }) {
        this.rows?.forEach(row => {
            this.columnController.getColumns<MilestoneYearWheelColumn>(ColumnTypes.MilestoneYearWheel).forEach(column => {
                const yearWheelConfiguration = column.getCell(row).yearWheelConfiguration;
                yearWheelConfiguration
                    .setPeriodUnit(column.yearWheelDisplayOptions.unit)
                    .setStart(period.start)
                    .setEnd(period.end);
                column.dataFetcher.execute();
            });
        });
    }

    public onYearWheelOptionsChanged(value: YearWheelDisplayOptions) {
        this.rows?.forEach(row => {
            this.columnController.getColumns<MilestoneYearWheelColumn>(ColumnTypes.MilestoneYearWheel).forEach(column => {
                const yearWheelConfiguration = column.getCell(row).yearWheelConfiguration;
                yearWheelConfiguration.setDisplayOptions(value);
                column.dataFetcher.execute();
            });
        });
    }

    // </editor-fold>

    // <editor-fold desc="Row events">

    public onRowMoveEvent(row: Row, direction: "up" | "down") {
        const fromIndex = this.rows.indexOf(row);
        const toIndex = direction == 'up' ? fromIndex - 1 : fromIndex + 1;
        this.rows.splice(fromIndex, 1);
        this.rows.splice(toIndex, 0, row);
        SubDisplayHelper.ApplyMilestoneMoveDirections(this.rows);
        this.renderRows();

        this.project.setMilestoneSequence(this.rows.map(row => row.milestoneCard.item));
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private apiSubscription?: ApiRequest;

    loadData() {
        if (!this.project?.project_type || !this.shellFilterGroup.activeFilter || (this.tableColumns?.length ?? 0) == 0) {
            return;
        }

        // Cancel previous load
        this.apiSubscription?.cancel();
        this.dataFetcherCollection.cancel();

        // Clear data
        this.rows = [];
        this.rowsMap = new Map();

        // Update columns
        const periodStart = this.shellFilterGroup.activeFilter.getPeriodStart(this.filtersSettings);
        const periodEnd = this.shellFilterGroup.activeFilter.getPeriodEnd(this.filtersSettings);
        this.columnController.getColumns<TaskListPeriodColumn>(ColumnTypes.TaskList_Period).forEach(column => {
            column.setPeriod(periodStart, periodEnd);
        });

        const api = Api.milestones().get()
            .include('responsible')
            .where('project.id', this.projectId);

        if (this.filterGlobalService.getActiveSettings().activeUsers?.length > 0) {
            // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1632
            api.search('milestone.users', this.filterGlobalService.getActiveSettings().activeUsers.map(user => user.id));
        }

        const milestoneCardColumn = this.columnController.getFirstVisibleTableColumn(ColumnTypes.MilestoneCard);
        const overwriteSort = milestoneCardColumn?.activeSortItem !== undefined;
        const sortType = overwriteSort
            ? milestoneCardColumn.activeSortItem.sortId
            : this.shellFilterGroup.activeFilter.sort_type;
        const sortDirection = this.shellFilterGroup.activeFilter.sort_direction;
        switch (Filters.GetBaseSort(sortType)) {
            default:
            case Filters.SortMilestoneUserDefined:
                api.orderBy('deadline.id', 'null')
                    .orderBy('deadline.date', 'asc')
                    .orderBy('milestones_project.index_', 'asc')
                break;
            case Filters.SortMilestoneTitle:
                api.orderBy('name', sortDirection);
                break;
            case Filters.SortMilestoneDeadline:
                api.orderBy('deadline.id', 'null')
                    .orderBy('deadline.date', 'asc');
                break;
            case Filters.SortMilestoneStatus:
                api.orderBy('main_status.status_id', sortDirection == 'asc' ? 'desc' : 'asc');
                break;
            case Filters.SortMilestoneResponsible:
                api.orderBy('responsible.first_name', sortDirection);
                break;
            case Filters.SortMilestoneStars:
                api.orderBy('num_stars', sortDirection);
                break;
            case Filters.SortMilestoneHands:
                api.orderBy('num_hand_ups', sortDirection);
                break;
        }
        this.apiSubscription = api
            .find(milestones => {

                this.columnController.getVisibleColumns()
                    .filter(column => column.implementsDataFetching)
                    .map(column => column as unknown as ColumnDataFetcherInterface)
                    .forEach(column => {
                        this.dataFetcherCollection.add(column.getDataFetchers());
                    });
                this.dataFetcherCollection.clearFetchers();

                const rows = milestones.map(milestone => this.createRow(milestone));

                this.dataFetcherCollection.execute(() => {
                    rows.forEach(row => this.rowsMap.set(row.milestoneCard.item.id, row));
                    this.rows = rows;

                    if (sortType == Filters.SortMilestoneUserDefined) {
                        SubDisplayHelper.ApplyMilestoneMoveDirections(rows);
                    }

                    this.renderRows();
                });
            });
    }

    private loadItem(milestone: Milestone): void {
        this.addRow(this.createRow(milestone));
        this.renderRows();
    }

    private createRow(milestone: Milestone): Row {
        milestone.projects = [this.project];

        const row = new Row(this.project, milestone, this);

        this.columnController.getAllColumns().forEach(column => {
            row.addCell(column.createCell(row));
        });

        // Used across task columns (status + period + edit)
        const setTaskListFilterConfiguration = (listConfiguration: TaskListConfiguration) => {
            const filter = this.shellFilterGroup.activeFilter;
            switch (filter.filter_type) {
                case Filters.FilterAll:
                    // No filters.
                    break;
                case Filters.FilterWithDeadlineInPeriod:
                    listConfiguration
                        .setTaskDeadlineTypes([filter.task_deadline_type_id])
                        .setDeadlineBetween(
                            filter.getPeriodStart(this.filtersSettings),
                            filter.getPeriodEnd(this.filtersSettings)
                        );
                    break;
                case Filters.FilterWithoutDeadline:
                    listConfiguration
                        .setTaskDeadlineTypes([filter.task_deadline_type_id])
                        .setHasNonDeadline(true);
                    break;
                case Filters.FilterPlannedInPeriod:
                    listConfiguration.setPlannedBetween(
                        filter.getPeriodStart(this.filtersSettings),
                        filter.getPeriodEnd(this.filtersSettings)
                    );
                    break;
                case Filters.FilterWithoutPlanningDate:
                    listConfiguration.setPlanningDeadlineId(0);
                    break;
                case Filters.FilterOpen:
                    listConfiguration.setOpen(true);
                    break;
                case Filters.FilterWithNotes:
                    listConfiguration.setHasNotes(true); // task.delivery_description
                    break;
                case Filters.FilterArchivedInPeriod:
                    listConfiguration.setArchivedBetween(
                        filter.getPeriodStart(this.filtersSettings),
                        filter.getPeriodEnd(this.filtersSettings)
                    );
                    break;
            }

            listConfiguration.setHasHands(filter.hand_up ?? null);
            listConfiguration.setHasStars(filter.starred ?? null);
        };

        this.columnController.getColumns<TaskListStatusColumn>(ColumnTypes.TaskList_Status).forEach(column => {
            column.getCell(row).listConfigurations.forEach(listConfiguration => {
                listConfiguration
                    .setCustomAllowCardItemEnterFunction((cardItem, fromListConfiguration) => {
                        // Only allow from fellow taskStatusListConfigurations
                        return this.tableRows.some(row => {
                            return this.columnController
                                .getColumns<TaskListStatusColumn>(ColumnTypes.TaskList_Status)
                                .some(column => {
                                    return [...column.getCell(row).listConfigurations.values()].includes(fromListConfiguration);
                                });
                        });
                    })
                    .setOnScreenFilters(this.onScreenFilters);
                setTaskListFilterConfiguration(listConfiguration);
            });
        });

        this.columnController.getColumns<TaskListPeriodColumn>(ColumnTypes.TaskList_Period).forEach(column => {
            const cell = column.getCell(row);
            const tableColumns = column.getTableColumns<TaskListPeriodTableColumn>();

            // Configure table columns with deadline
            tableColumns.filter(tableColumn => tableColumn.periodType !== TaskListPeriodColumn.Period_WithoutDeadline)
                .forEach(tableColumn => {
                    const listConfiguration = cell.listConfigurations.get(tableColumn.periodType);
                    listConfiguration
                        .setCustomAllowCardItemEnterFunction((cardItem, fromListConfiguration) => {
                            if (listConfiguration.getDeadlineBetween() || listConfiguration.getPlannedBetween()) { // ArchivedBetween is not part of drag drop
                                // Allow from fellow taskPeriodListConfigurations
                                if (listConfiguration.getDeadlineBetween() && fromListConfiguration.getHasNonDeadline()) {
                                    return true; // Allow from Top display "Tasks without deadline"
                                }
                                // Only allow from fellow taskPeriodListConfigurations
                                return this.tableRows.some(row => {
                                    return this.columnController
                                        .getColumns<TaskListPeriodColumn>(ColumnTypes.TaskList_Period)
                                        .some(column => {
                                            return [...column.getCell(row).listConfigurations.values()].includes(fromListConfiguration);
                                        });
                                });
                            } else {
                                return false;
                            }
                        })
                        .setOnScreenFilters(this.onScreenFilters)
                        .setShowCreateNew(this.shellFilterGroup.activeFilter.filter_type != Filters.FilterArchivedInPeriod);


                    // Prepare preset generators for task lists
                    switch (this.shellFilterGroup.activeFilter.filter_type) {
                        case Filters.FilterWithDeadlineInPeriod:
                            listConfiguration.addCreatePresetGenerator(new TaskDeadlinePresetGenerator(
                                TaskDeadlineTypes.Normal,
                                () => tableColumn.getStartDateForTaskPreset(),
                                false
                            ));
                            break;
                        case Filters.FilterPlannedInPeriod:
                            listConfiguration.addCreatePresetGenerator(new TaskUserPresetGenerator(
                                TaskUserTypes.Participant,
                                this.user.id,
                                () => Deadline.Create(tableColumn.getStartDateForTaskPreset(), false),
                            ));
                            break;
                        case Filters.FilterArchivedInPeriod:
                            // Create New is not visible
                            break;
                    }
                });

            // Configure table columns without deadline
            tableColumns
                .filter(tableColumn => tableColumn.periodType == TaskListPeriodColumn.Period_WithoutDeadline)
                .forEach(tableColumn => {
                    const listConfiguration = cell.listConfigurations.get(tableColumn.periodType);
                    listConfiguration.setOnScreenFilters(this.onScreenFilters);
                });

            cell.listConfigurations.forEach(listConfiguration => {
                setTaskListFilterConfiguration(listConfiguration);
            });

            const filter = this.shellFilterGroup.activeFilter;
            tableColumns
                .forEach(tableColumn => {
                    const listConfiguration = cell.listConfigurations.get(tableColumn.periodType);

                    switch (filter.filter_type) {
                        case Filters.FilterWithDeadlineInPeriod:
                            listConfiguration
                                .setTaskDeadlineTypes([filter.task_deadline_type_id])
                                .setDeadlineBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                        case Filters.FilterPlannedInPeriod:
                            listConfiguration.setPlannedBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                        case Filters.FilterArchivedInPeriod:
                            listConfiguration
                                .setArchivedBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                        default:
                            listConfiguration
                                .setDeadlineValidator(task => false) // We can't show tasks if filter does not specify period
                                .setDeadlineBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                    }
                });
        });

        this.columnController.getColumns<TaskListAllColumn>(ColumnTypes.TaskList_All).forEach(column => {
            const listConfiguration = column.getCell(row).listConfiguration;
            listConfiguration
                .setCustomAllowCardItemEnterFunction((cardItem, fromListConfiguration) => {
                    // Only allow from fellow taskEditListConfigurations
                    return this.tableRows.find(row => listConfiguration == fromListConfiguration) !== undefined;
                })
                .setOnScreenFilters(this.onScreenFilters);
            setTaskListFilterConfiguration(listConfiguration);
        });

        this.columnController.getColumns<TaskEditColumn>(ColumnTypes.TaskEdit).forEach(column => {
            const listConfiguration = column.getCell(row).listConfiguration;
            listConfiguration
                .setIsSortingEnabled(this.shellFilterGroup.activeFilter.sort_type == Filters.SortUserDefined)
                .setCustomAllowCardItemEnterFunction((cardItem, fromListConfiguration) => {
                    // Only allow from fellow taskEditListConfigurations
                    return this.tableRows.find(row => listConfiguration == fromListConfiguration) !== undefined;
                })
                .setOnScreenFilters(this.onScreenFilters);

            setTaskListFilterConfiguration(listConfiguration);
        });

        return row;
    }

    private addRow(row: Row): void {
        this.rowsMap.set(row.milestoneCard.item.id, row);
        this.rows.push(row);
    }

    private renderRows() {
        this.tableRows = this.rows.filter(row => this.onScreenFilters.find(filter => filter.isValid(row.milestoneCard.item)) !== undefined);
        this.detectChanges();
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    protected initializeShellPageData() {
        this.shellPageData.displayFilterExtra = JSON.stringify({
            projectId: this.projectId
        });
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();

        this.filtersSettings.getEditorConfig(this.display, config => {

            this.columnController.addColumnTypes([
                new MilestoneCardColumnType(this.cellMilestoneCardTemplate, this.headerMilestoneCardTemplate),
                new TaskEditColumnType(
                    this.shellPageData,
                    column => this.onColumnSortChanged(column),
                    this.cellTaskEditListWhoWhenHeaderTemplate,
                    this.isMinimized,
                    config
                ),
                new TaskListStatusColumnType(this.cellTaskListStatusTemplate, this.headerWithStatusTemplate),
                new TaskListPeriodColumnType(this.cellTaskListPeriodTemplate, this.headerWithPeriodTemplate),
                new TaskListAllColumnType(this.cellTaskListTemplate, this.headerTemplate),
                new MilestoneEditColumnType(this.cellMilestoneEditListTemplate, this.headerTemplate),
                new MilestoneYearWheelColumnType(this.cellYearWheelTemplate, this.headerTemplateYearWheel),
            ]);

            this.columnController.loadColumns(this.displayId, () => {
                this.initialize();
            });
        });
    }

    private initialize() {
        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            let tableColumns = this.columnController.getVisibleTableColumns() as GenericTableColumn[];

            // Combine TaskEdit-table columns into one single table column
            this.columnController.getColumns<TaskEditColumn>(ColumnTypes.TaskEdit).forEach(column => {
                if (column.isVisible) {
                    const firstTaskEditTableColumn = tableColumns.findIndex(tableColumn => tableColumn.column == column);
                    const taskEditTableColumn = new TaskEditTableColumn(column, this.cellTaskEditListTemplate, this.headerTaskEditTemplate);
                    tableColumns[firstTaskEditTableColumn] = taskEditTableColumn;
                    tableColumns = tableColumns.filter(tableColumn => !column.getTableColumns().includes(tableColumn));

                    column.updateEnabledEditors();

                    // Update this table column minWidth
                    let minWidth = 0;
                    column.getVisibleTableColumns<BaseEditorTableColumn>()
                        .forEach(editor => minWidth += editor.columnConfiguration.minWidth ?? 0);
                    taskEditTableColumn.minWidth = minWidth;

                    if (column.getVisibleTableColumns().some(tableColumn => tableColumn instanceof TaskEdit_Card_TableColumn)) {
                        // Auto-expand
                        if (this.isMinimized) {
                            this.toggleIsMinimized();
                        }
                    }
                }
            });

            this.tableColumns = tableColumns;

            this.loadData();
        }));

        this.onShellPageDataChangeEvent.emit(this.shellPageData);

        this.subscribe(this.shellFilterGroup.onActiveFilterChangeEventSubscribe(filter => {
            this.columnController.getColumns<MilestoneYearWheelColumn>(ColumnTypes.MilestoneYearWheel)
                .forEach(column => column.setPeriod(
                    filter.getYearWheelPeriodStart(this.filtersSettings),
                    filter.getYearWheelPeriodEnd(this.filtersSettings)
                ));

            this.showPeriodColumnWarning = [
                Filters.FilterWithDeadlineInPeriod,
                Filters.FilterPlannedInPeriod,
                Filters.FilterArchivedInPeriod,
            ].includes(filter.filter_type) == false;
            this.loadData();
        }));

        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe((event) => {
            this.loadData();
        }));

        this.setupColumnSortItems();

        this.initialized = true;

        Api.projects().get()
            .where('id', this.projectId)
            .include('department')
            .find(projects => {
                if (projects.length == 1) {
                    this.project = projects[0];
                    this.projectsService.getProjectType(this.project.project_type_id, projectType => {
                        this.project.project_type = projectType;
                        this.loadData();
                    });
                }
            });

        this.setupPush();
    }

    private setupColumnSortItems() {
        this.filtersSettings.getEditorConfig(this.display, config => {

            this.columnController.getColumns<TaskListAllColumn>(ColumnTypes.TaskList_All).forEach(column => {
                column.getTableColumns<TaskListAllTableColumn>().forEach(tableColumn => {
                    tableColumn.sortItems.push(
                        ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                            Filters.SortCategoryTypeGenerator(categoryType)
                        )),
                        ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                            Filters.SortDeadlineWithGenerator(deadlineType)
                        )),
                    );
                });
            });

            this.columnController.getColumns<TaskListPeriodColumn>(ColumnTypes.TaskList_Period).forEach(column => {
                column.getTableColumns<TaskListPeriodTableColumn>().forEach(tableColumn => {
                    switch (tableColumn.periodType) {
                        case TaskListPeriodColumn.Period_WithoutDeadline:
                            tableColumn.sortItems.push(
                                ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                                    Filters.SortCategoryTypeGenerator(categoryType)
                                )),
                            );
                            break;
                        default:
                            tableColumn.sortItems.push(
                                ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                                    Filters.SortCategoryTypeGenerator(categoryType)
                                )),
                                ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                                    Filters.SortDeadlineWithGenerator(deadlineType)
                                )),
                            );
                            break;
                    }
                });
            });

            this.columnController.getColumns<TaskListStatusColumn>(ColumnTypes.TaskList_Status).forEach(column => {
                column.getTableColumns<TaskListStatusTableColumn>().forEach(tableColumn => {
                    tableColumn.sortItems.push(
                        ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                            Filters.SortCategoryTypeGenerator(categoryType)
                        )),
                        ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                            Filters.SortDeadlineWithGenerator(deadlineType)
                        )),
                    );
                });
            });

        });
    }

    private setupPush() {
        this.eventService.subscribeToMilestone(0, event => {
            switch (event.action) {
                case EventService.Created:
                    if (this.validateMilestone(event.item)) {
                        this.loadItem(event.item);
                    }
                    break;
                case EventService.Updated:
                    if (this.rowsMap.has(event.item.id) && !this.validateMilestone(event.item)) {
                        this.loadData(); // Delete row
                    } else if (!this.rowsMap.has(event.item.id) && this.validateMilestone(event.item)) {
                        this.loadData(); // Add row
                    }
                    break;
                case EventService.Deleted:
                    if (this.rowsMap.has(event.item.id)) {
                        this.loadData(); // Delete row
                    }
                    break;
            }
        });
    }

    private validateMilestone(milestone: Milestone): boolean {
        return milestone.projects?.find(project => project.id == this.projectId) !== undefined;
    }

    // </editor-fold>

}
