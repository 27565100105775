import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {Subscription} from 'rxjs';
import {BaseDisplayComponent} from '../../../_system/base-display/base-display.component';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import Helpers from '@app/core/helpers';
import {Project, ProjectUserTypes} from '@app/core/models/Project';
import {ProjectListConfiguration} from '@app/shared/_ui/lists/project-list/ProjectListConfiguration';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {Api} from "@app/core/Api";
import {CdkDragEnd, CdkDragStart} from "@angular/cdk/drag-drop";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {ProjectFetcher} from "@app/shared/_ui/lists/project-list/ProjectFetcher";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectListComponent extends BaseDisplayComponent implements OnInit, OnChanges, OnDestroy {

    @Output() dataSetChanged = new EventEmitter<CardItem<Project>[]>();
    @Input() listClass: string = 'grid-3';
    @Input() configuration: ProjectListConfiguration;
    @Input() minimizeEvent: EventEmitter<any>;
    @Input() loadAllEvent: EventEmitter<any>;
    @Input() reloadEvent: EventEmitter<any>;
    @Input() scrollContainer: HTMLElement;
    @Output() onDragStarted = new EventEmitter();
    @Output() onDragEnded = new EventEmitter();

    // Data
    public items: CardItem<Project>[];
    private itemsMap: Map<number, CardItem<Project>>;
    public offset = 0;
    public itemCount = 0;
    private loadAllEventSubscription?: Subscription;

    constructor(private editorPanelService: EditorPanelService,
                private cd: ChangeDetectorRef,
                private filterGlobalService: FilterGlobalService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.items = [];
        if (!this.configuration) {
            this.configuration = new ProjectListConfiguration();
        }

        if (this.minimizeEvent) {
            this.minimizeEvent.subscribe(() => {
                this.items.splice(this.configuration.getLimit());
                this.itemsMap.clear();
                this.items.forEach(item => this.itemsMap.set(item.item.id, item));
                this.offset = 0;
                this.items = [...this.items];
                this.detectChanges();
            });
        }

        if (this.reloadEvent) {
            this.reloadEvent.subscribe(() => this.loadData());
        }

        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(() => this.loadData()));

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['loadAllEvent']) {
            if (this.loadAllEvent) {
                this.loadAllEventSubscription?.unsubscribe();
                this.loadAllEventSubscription = this.loadAllEvent.subscribe(() => {
                    this.loadAll();
                });
            }
        }
        if (changes['configuration'] != null) {
            changes['configuration'].previousValue?.pushUnsubscribe();

            const onAddItemsListener = (items: CardItem<Project>[]) => {
                // Reset
                this.offset = 0;
                this.itemCount = 0;
                this.itemsMap = new Map();

                // Add items
                this.items = items;
                items.forEach(item => this.itemsMap.set(item.item.id, item));
                this.configuration.setLastItemCount(items.length);

                this.isLoading = false;
                this.detectChanges();
                this.dataSetChanged.emit(this.items);
            };
            const onAddItemListener = (item: CardItem<Project>) => {
                if (!this.itemsMap?.has(item.item.id)) { // Add of not found
                    this.items.push(item);
                    this.itemCount++;
                } else { // Replace existing card
                    this.items[this.items.indexOf(this.itemsMap.get(item.item.id))] = item;
                }
                this.itemsMap.set(item.item.id, item);
                this.configuration.setLastItemCount(this.items.length);
                this.detectChanges();
                this.dataSetChanged.emit(this.items);
            };
            const onRemoveItemListener = (item: CardItem<Project>) => {
                this.removeItem(item.item);
                this.detectChanges();
            };
            const onCountListener = (count: number) => {
                this.itemCount = count;
                this.detectChanges();
            };
            this.configuration.addDataListeners(onAddItemsListener, onAddItemListener, onRemoveItemListener, onCountListener);

            this.configuration.pushUnsubscribe();
            this.configuration.pushSubscribe();

            if (this.configuration.getDataSource()) {
                this.apiRequest?.cancel();
            } else if (this.configuration.hasSmartLimit()) { // Wait for SmartLimit
                this.subscribe(this.configuration.onLimitSetEvent.subscribe((limit: number) => this.loadData()));
            } else {
                this.loadData();
            }
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.configuration?.pushUnsubscribe();
    }

    // <editor-fold desc="View Actions">

    loadMore(limit?: number) {
        if (!limit) {
            limit = this.configuration.getLimit();
        }
        if (this.configuration.getDataSource()) {
            this.configuration.getDataSource().requestSlice(0, this.offset + limit);
        } else {
            this.loadItems(this.offset + limit);
        }
    }

    loadLess() {
        this.offset = 0;
        this.items = this.items.slice(0, this.configuration.getLimit());
        this.dataSetChanged.emit(this.items);
        this.detectChanges();
    }

    loadAll() {
        if (this.configuration.getDataSource()) {
            this.configuration.getDataSource().requestSlice(0, this.itemCount);
        } else {
            this.loadItems(this.offset + this.configuration.getLimit(), false, true);
        }
    }

    emitOnDragStarted($event: CdkDragStart) {
        this.onDragStarted.emit($event)
    }

    emitOnDragEnded($event: CdkDragEnd) {
        this.onDragEnded.emit($event)
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private loadData() {
        this.items = [];
        this.itemsMap = new Map();
        this.itemCount = 0;

        if (this.configuration?.getDataSource()) {
            const fetcher = new ProjectFetcher();
            fetcher.addRequest(this.configuration.getDataSource());
            fetcher.execute();
        } else {
            this.loadItems(0, true)
        }
    }

    private apiRequest?: ApiRequest;

    private loadItems(offset: number, doCount: boolean = false, loadAll: boolean = false) {
        this.apiRequest?.cancel();

        this.isLoading = true;

        let api = Api.projects().get();

        // Always required do to default configuration (taskType.default_task_estimate_type_id) shown in medium card
        api.include('project_estimate');

        if (this.configuration.getShowMiniCard_NextMilestone()) {
            api.include('next_milestone.main_status');
        }

        api
            .include('current_phases_project.phase.color')
            .include('current_phases_project.current_phase_progress.to_phase_progress_type');

        if (this.configuration.getProjectType()) api.where('project_type_id', this.configuration.getProjectType().id);
        if (this.configuration.getUser()) api
            .where('projects_user.user_id', this.configuration.getUser().id)
            .where('projects_user.project_user_type_id', ProjectUserTypes.Responsible);
        if (this.configuration.getArchived() != null) {
            if (this.configuration.getArchived() == true)
                api.whereGreaterThan('archived_id', 0);
            else
                api.where('archived_id', 0);
        }
        if (this.configuration.getOrArchivedSince()) api.where('or_archived_since', Helpers.serverDate(this.configuration.getOrArchivedSince()));
        if (this.configuration.getAvoidIds()) api.whereNotIn('id', this.configuration.getAvoidIds());
        if (this.configuration.getPhaseIds()) {
            api.whereIn('phases_project.phase_id', this.configuration.getPhaseIds());
        }
        if (this.configuration.getCurrentPhaseId()) {
            api.where('current_phases_project.phase_id', this.configuration.getCurrentPhaseId());
        }
        if (this.configuration.getCurrentPhaseProgressTypeId()) {
            api.where('current_phases_project.current_phase_progress.to_phase_progress_type_id', this.configuration.getCurrentPhaseProgressTypeId());
        }
        if (this.configuration.getDepartment()) api.where('department.id', this.configuration.getDepartment().id);
        if (this.configuration.getProjectDeadlineTypeIds()?.length > 0) {
            api.whereIn('projects_deadline.project_deadline_type_id', this.configuration.getProjectDeadlineTypeIds());
        }
        if (this.configuration.getDeadlineBetween()) {
            api.whereGreaterThanOrEqual('projects_deadline.deadline.date', Helpers.serverDate(this.configuration.getDeadlineBetween()[0]))
                .whereLessThanOrEqual('projects_deadline.deadline.date', Helpers.serverDate(this.configuration.getDeadlineBetween()[1]));
        }
        if (this.configuration.getHasNonDeadline() !== undefined && this.configuration.getHasNonDeadline() !== null) {
            if (this.configuration.getHasNonDeadline()) {
                api.where('projects_deadline.deadline_id', null);
            } else {
                api.whereNot('projects_deadline.deadline_id', null);
            }
        }
        if (this.configuration.getOpen() !== null) {
            api.where('open', this.configuration.getOpen() ? 1 : 0);
        }
        if (this.configuration.getArchivedBetween()) {
            api.include('archived')
                .whereGreaterThanOrEqual('archived.created', Helpers.serverDate(this.configuration.getArchivedBetween()[0]))
                .whereLessThanOrEqual('archived.created', Helpers.serverDate(this.configuration.getArchivedBetween()[1]));
        }

        // ***** *** *** *** *** *** *** *** *****
        // * HUSK AT OPDATERE ProjectFetcher.ts  *
        // ***** *** *** *** *** *** *** *** *****

        if (this.configuration.getUseGlobalFilter()) {
            const filter = this.filterGlobalService.getActiveSettings();

            if (filter.search?.length > 0) {
                api.search('title', filter.search);
            }
            if (filter.isHandUp) {
                api.whereGreaterThan('num_hand_ups', 0);
            }
            if (filter.isStarred) {
                api.whereGreaterThan('num_stars', 0);
            }
            if (filter.activeStatuses.length < 4 && filter.activeStatuses.length > 0) {
                api.whereIn('main_status.status_id', filter.activeStatuses);
            }
            if (filter.activeReactionFilters?.length > 0) {
                filter.activeReactionFilters.forEach(reactionFilter => {
                    api.whereIn('reaction_filters', [reactionFilter.reaction_type_id, reactionFilter.value]);
                });
            }
            if (filter.activeCategories?.length) {
                api.whereIn('category.id', filter.activeCategories?.map(category => category.id));
            }
        }
        if (this.configuration.getSearch() && this.configuration.getSearch().length > 0) {
            api.search('title', this.configuration.getSearch());
        }

        this.configuration.getOrderBy().forEach(orderBy => api.orderBy(orderBy[0], orderBy[1]));
        this.configuration.applySortFiltersToApi(api);

        this.apiRequest = api
            .offset(offset)
            .limit(loadAll ? 100 : this.configuration.getLimit())
            .find(projects => {
                this.offset = offset;
                projects.forEach(project => this.addItem(project));
                this.dataSetChanged.emit(this.items);
                this.isLoading = false;
                this.detectChanges();
            });

        if (doCount) {
            api.count(count => {
                this.itemCount = count;
                if (count == 0) {
                    this.isLoading = false;
                }
                this.detectChanges();
            });
        }
    }

    private addItem(item: Project) {
        if (this.items.findIndex(ci => ci.item.id == item.id) === -1) {
            const cardItem = this.configuration.createCard(item);
            this.items.push(cardItem);
            this.itemsMap.set(item.id, cardItem);
        }
    }

    private removeItem(item: Project) {
        if (this.itemsMap?.has(item.id)) {
            this.items.splice(this.items.indexOf(this.itemsMap.get(item.id)), 1);
            this.itemsMap.delete(item.id);
            this.itemCount--;
            this.dataSetChanged.emit(this.items);
            this.configuration.setLastItemCount(this.items.length);
            this.offset = Math.max(0, this.offset - 1);
            if (this.itemCount > this.items.length) {
                this.loadMore(this.items.length + 1);
            }
        }
    }

    // </editor-fold>
}
