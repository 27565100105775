import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {EditPhasesProject} from "@app/editor/quick-editor/editors/project/phases-project-editor/EditPhasesProject";
import {
    EditPhasesProjectConfiguration
} from "@app/editor/quick-editor/editors/project/phases-project-editor/EditPhasesProjectConfiguration";
import {Department, Phase, PhasesProject, Project} from "@app/core/models";
import {Row} from "@app/editor/quick-editor/editors/project/phases-project-editor/Row";
import {NgbDateStruct, NgbTypeahead, NgbTypeaheadSelectItemEvent} from "@ng-bootstrap/ng-bootstrap";
import {merge, Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map, tap} from "rxjs/operators";
import {ProjectEditorService} from "@app/editor/project-editor-loader/project-editor.service";
import {TranslateService} from "@ngx-translate/core";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {fadeAnimation} from "@app/animations";

@Component({
    selector: 'app-phases-project-editor',
    templateUrl: './phases-project-editor.component.html',
    styleUrls: ['./phases-project-editor.component.scss'],
    animations: [fadeAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PhasesProjectEditorComponent extends BaseEditor<EditPhasesProject> {
    protected eventFieldName = 'phases-project';

    // Bindings to parent
    @Input() model!: EditPhasesProject & HasEventGenerator & BaseModel;
    @Input() configuration!: EditPhasesProjectConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public rows: Row[] = [];

    // Data
    private phases: Phase[];

    constructor(private cd: ChangeDetectorRef,
                private translateService: TranslateService,
                private dialogService: BaseDialogService,
                private projectEditorService: ProjectEditorService,) {
        super();
        this.cdr = cd;
        if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
            this.searchPlaceholder = 'Find...';
        }
    }

    protected setup() {
    }

    protected render() {
        this.projectEditorService.getPhasesForType(this.model.project_type_id, phases => {
            this.phases = phases;
        });

        const currentPhasesProject = this.model.getCurrentPhasesProject();
        this.rows = this.model.getPhasesProjects()
            .map(phasesProject => {
                return new Row(phasesProject, phasesProject.id === currentPhasesProject?.id);
            });
    }

    // <editor-fold desc="Search">

    public searchPlaceholder = this.translateService.instant('_ui_search') + '...';
    public searchFailed = false;
    public isSearching = false;
    public searchResult: Phase[] = [];

    @ViewChild('instance') instance: NgbTypeahead;
    public focus$ = new Subject<string>();
    public click$ = new Subject<string>();

    public searchFunction = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(
            debounceTime(200),
            distinctUntilChanged()
        );
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            tap(() => this.isSearching = true),
            map(term => {
                let r = this.phases
                    .filter(v => String(v.name).toLowerCase().indexOf(String(term).toLowerCase()) > -1)
                this.searchResult = r.length > 0 ? r : null;
                this.searchFailed = term !== '' ? this.searchResult == null : null;
                let items = term === '' ? this.phases.filter(item => true) : r;

                const departments = this.model.departments ?? [];
                if (departments.length > 0) {
                    const allowedDepartmentIds = departments.map(department => department.id);
                    items = items.filter(item => {
                        if (item.departments) {
                            const match = item.departments.filter(department => allowedDepartmentIds.includes(department.id));
                            if (match.length > 0) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
                return items;
            }),
            tap(() => this.isSearching = false),
        );
    };

    public formatter = (x: Phase) => x.name;

    // </editor-fold>

    // <editor-fold desc="View actions">

    public onPhasesProjectAdded(phasesProject: PhasesProject) {
        this.model.addPhasesProject(phasesProject);
        this.render();
    }

    public onRowRemoveBtnClicked(row: Row) {
        this.rows.splice(this.rows.indexOf(row), 1);
        this.model.removePhasesProject(row.phasesProject);
        this.render();
    }

    public onCurrentPhasesProjectChanged(value: Row) {
        this.rows.forEach(row => row.isCurrent = row == value);
        this.model.setCurrentPhasesProject(value.phasesProject);
        this.render();
    }

    public onRowDateChanged(row: Row, date: NgbDateStruct) {
        row.phasesProject.setStart(date);
        this.model.updatePhasesProjectDate(row.phasesProject, row.phasesProject.getStartedDate());
        this.render();
    }

    public onCreateBtnClicked() {
        const departments: Department[] = this.model.departments;
        const projectTypeIDs = [this.model.project_type_id];
        this.dialogService.createPhaseDialog(departments, projectTypeIDs).then((response) => {
            if (response.phase) {
                this.projectEditorService.appendPhase(this.model.project_type_id, response.phase);

                const phasesProject = new PhasesProject();
                phasesProject.phase = response.phase;
                phasesProject.phase_id = response.phase.id;
                phasesProject.phase.changeable_date = true;

                this.onPhasesProjectAdded(phasesProject);
            }
        }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking'));
    }

    public onPhaseSelected($event: NgbTypeaheadSelectItemEvent, input: any) {
        $event.preventDefault();
        input.value = '';
        const phase: Phase = $event.item;

        const phasesProject = new PhasesProject();
        phasesProject.phase = phase;
        phasesProject.phase_id = phase.id;

        this.onPhasesProjectAdded(phasesProject);
    }

    // </editor-fold>

    resultTemplate: {
        result: Phase,
        term: string,
    }

}
