/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {ProjectCaseDefinition} from './definitions/ProjectCaseDefinition';
import {Api} from "@app/core/Api";
import {isNull} from "lodash";

export class ProjectCase extends ProjectCaseDefinition {

    constructor(json?: any) {
        super(json);
    }

    public setCases(value: number): void {
        this.cases = value;
        Api.projectCases().updateCasesPutById(this.id)
            .value(value)
            .save(null);
    }

    public setGoal(value: number): void {
        this.goal = value;
        Api.projectCases().updateGoalPutById(this.id)
            .value(value)
            .save(null);
    }

    public setCasesText1(value: string): void {
        this.cases_text2 = isNull(value) ? '' : value;
        Api.projectCases().updateCasesText1PutById(this.id)
            .value(this.cases_text2)
            .save(null);
    }

    public setCasesText2(value: string): void {
        this.cases_text2 = isNull(value) ? '' : value;
        Api.projectCases().updateCasesText2PutById(this.id)
            .value(this.cases_text2)
            .save(null);
    }

    public setCasesText3(value: string): void {
        this.cases_text3 = isNull(value) ? '' : value;
        Api.projectCases().updateCasesText3PutById(this.id)
            .value(this.cases_text3)
            .save(null);
    }

    public setGoalText1(value: string): void {
        this.goal_text1 = isNull(value) ? '' : value;
        Api.projectCases().updateGoalText1PutById(this.id)
            .value(this.goal_text1)
            .save(null);
    }

    public setGoalText2(value: string): void {
        this.goal_text2 = isNull(value) ? '' : value
        Api.projectCases().updateGoalText2PutById(this.id)
            .value(this.goal_text2)
            .save(null);
    }

    public setGoalText3(value: string): void {
        this.goal_text3 = isNull(value) ? '' : value;
        Api.projectCases().updateGoalText3PutById(this.id)
            .value(this.goal_text3)
            .save(null);
    }

}
