import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {ReactionsApiInterface} from "@app/shared/_ui/reactions/ReactionsApiInterface";
import {Reaction} from "@app/core/models";
import {ReactionTypes} from "@app/constants";
import {EmojiItem} from "@app/shared/_elements/emoji-picker/emoji-picker.component";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {UsersService} from "@app/services/users.service";
import {ReactionsSourceInterface} from "@app/shared/_ui/reactions/ReactionsSourceInterface";
import {ReactionsTypeInterface} from "@app/shared/_ui/reactions/ReactionsTypeInterface";
import {RxStompService} from "@app/core/rabbitmq/rx-stomp.service";
import {ChangeMessage} from "@app/core/rabbitmq/ChangeMessage";

@Component({
    selector: 'app-reactions',
    templateUrl: './reactions.component.html',
    styleUrls: ['./reactions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ReactionsComponent extends BaseComponent implements OnInit, OnChanges {

    // Interfaces
    @Input() reactionsApiInterface: ReactionsApiInterface; // Input model (Task, Project, Milestone, To dos)
    @Input() reactionsSourceInterface: ReactionsSourceInterface;

    // Data
    @Input() reactions: Reaction[];

    // Events
    @Output() onDataSetChanged = new EventEmitter<Reaction[]>();
    @Output() onToggle: EventEmitter<any> = new EventEmitter<Reaction[]>();

    // Configuration
    @Input() reactionsEditable = true;
    @Input() reactionsVisible = true;
    @Input() reactionsToggleUsers = true;
    @Input() reactionsShowUserCounter = true;
    @Input() reactionsPickerVisible = true;
    @Input() reactionTypeIds: number[];
    @Input() reactionShowBadge = false;
    @Input() reactionsTypeInterfaces: ReactionsTypeInterface[];

    // Emoji-specific
    public showEmojiPicker = false;
    public emojiItems: EmojiItem[] = [];

    constructor(private cd: ChangeDetectorRef,
                private usersService: UsersService,
                private rxStompService: RxStompService) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        this.sortReactions();

        if (this.reactionsSourceInterface) {
            this.subscribe(this.rxStompService
                .watch(this.reactionsSourceInterface?.getReactionChangedEventName())
                .subscribe(message => {
                    this.reactions = (ChangeMessage.ParseRabbitMQMessage(message.body).next as any[]).map(item => new Reaction(item));
                    this.sortReactions();
                }));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.reactions || changes.reactionsTypeInterfaces) {
            this.sortReactions();
        }
    }

    private sortReactions() {
        // Collect visible reactions
        const visibleReactions: Reaction[] = [];

        // Arrange reactions by reactionTypeId
        const reactionsMap: Map<number, Reaction[]> = new Map<number, Reaction[]>();
        this.reactions?.forEach(reaction => {
            reaction.value = reaction.value.replace(/%2B/gi, '+');

            if (!reactionsMap.has(reaction.reaction_type_id)) {
                reactionsMap.set(reaction.reaction_type_id, []);
            }
            reactionsMap.get(reaction.reaction_type_id).push(reaction);
        });

        // Prepare reactions by type. Only the types given by input.
        if (this.reactionsTypeInterfaces) {
            const reactionTypes: ReactionsTypeInterface[] = this.reactionsTypeInterfaces?.filter(reactionsType => reactionsType.visible) || [];
            reactionTypes.forEach(reactionsType => {
                switch (reactionsType?.reaction_type_id) {
                    case ReactionTypes.Emoji:
                        const items = reactionsMap.get(reactionsType.reaction_type_id);
                        if (items) {
                            visibleReactions.push(...items);
                        }
                        this.prepareEmojiItems(items ?? []);
                        break;
                }
            });
        }

        // Trigger output event and update this view.
        this.onDataSetChanged.emit(visibleReactions);
        this.detectChanges();
    }

    // <editor-fold desc="Emoji">

    public addEmojiReaction(value: string) {
        this.reactionsApiInterface.addReaction(this.createEmojiReaction(value));
        this.onToggle.emit(this.reactions);
    }

    public removeEmojiReaction(value: string) {
        this.reactionsApiInterface.removeReaction(this.createEmojiReaction(value));
        this.onToggle.emit(this.reactions);
    }

    private createEmojiReaction(value: string): Reaction {
        const reaction = new Reaction();
        reaction.reaction_type_id = ReactionTypes.Emoji;
        reaction.value = value.replace(/\+/gi, '%2B'); // https://github.com/angular/angular/issues/11058 // +1 reaction
        reaction.user_id = this.usersService.user.id;
        return reaction;
    }

    private prepareEmojiItems(reactions: Reaction[]) {
        const reactionsByValue: Map<string, EmojiItem> = new Map();
        reactions.forEach(reaction => {
            const reactionValue = reaction.value.replace(/%2B/gi, '+');  // https://github.com/angular/angular/issues/11058 // +1 reaction
            if (!reactionsByValue.has(reactionValue)) {
                reactionsByValue.set(reactionValue, {
                    emojiId: reactionValue,
                    userIds: [],
                    count: 0,
                });
            }
            const emojiItem = reactionsByValue.get(reactionValue);
            emojiItem.userIds.push(reaction.user_id);
            emojiItem.count++;
        });
        this.emojiItems = Array.from(reactionsByValue.values());
        this.showEmojiPicker = true;
    }

    // </editor-fold>
}
