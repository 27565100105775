import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {TaskListRosterColumn} from "@app/pages/displays/display-projects/Columns/TaskListRosterColumn";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import moment from "moment";
import {RosterDay, RosterDays} from "@app/pages/displays/display-projects/TableColumns/TaskListRosterTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {Projects_RosterDaysSettingValue} from "@app/core/Api";
import {Roster} from "@app/core/models/Task";
import {
    TaskTitlePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskTitlePresetGenerator";
import {
    TaskUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUseStatusRulesPresetGenerator";
import {
    TaskStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskStatusPresetGenerator";
import {EstimateUnits, StatusTypes, TaskStatusTypes} from "@app/constants";
import {
    TaskDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDepartmentPresetGenerator";
import {
    TaskProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskProjectPresetGenerator";
import {
    TaskEstimatePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskEstimatePresetGenerator";
import {AppInjector} from "@app/services/app-injector.service";
import {TasksService} from "@app/services/tasks.service";

export class TaskListRosterCell extends GenericCell {

    public column: TaskListRosterColumn;

    private listConfigurations = new Map<RosterDay, TaskListConfiguration>();

    constructor(row: ProjectsDisplayRow, column: TaskListRosterColumn) {
        super(row, column);

        column.settings.get(ColumnTypeSettings.RosterDays)
            ?.getObject<Projects_RosterDaysSettingValue>()
            ?.days
            ?.forEach(day => {
                const taskListConfiguration = this.createTaskListConfiguration(row, day);
                this.listConfigurations.set(day, taskListConfiguration);
            });
    }

    private createTaskListConfiguration(row: ProjectsDisplayRow, day: RosterDay): TaskListConfiguration {
        const taskListRosterColumn = new TaskListConfiguration();

        const presetGenerators = [
            new TaskTitlePresetGenerator(() => row.project.item.title),
            new TaskUseStatusRulesPresetGenerator(true),
            new TaskStatusPresetGenerator(TaskStatusTypes.Normal, StatusTypes.GREEN),
            new TaskDepartmentPresetGenerator(row.department.id),
            new TaskProjectPresetGenerator(row.project.item.id),
        ];

        const firstProjectEstimateWithHourUnit = row.project.item.project_estimates
            ?.find(projectsEstimate => projectsEstimate.estimate?.estimate_unit_id == EstimateUnits.Hours);
        if (firstProjectEstimateWithHourUnit) {
            const taskTypeMap = new Map<number, number>();
            AppInjector.getInjector().get(TasksService)
                .getTaskTypes(taskTypes => taskTypes
                    .forEach(taskType => taskTypeMap.set(taskType.id, taskType.default_task_estimate_type_id))
                );
            presetGenerators.push(new TaskEstimatePresetGenerator(
                typeId => taskTypeMap.get(typeId) ?? 0,
                firstProjectEstimateWithHourUnit.estimate.value,
                EstimateUnits.Hours,
            ));
        }

        taskListRosterColumn
            .setTaskTypeIds(this.column.getTaskTypeIds())
            .setDepartment(row.department)
            .setProject(row.project.item)
            .setShowCreateNew(false)
            .setCreatePresetGenerators(presetGenerators)
            .setModelClass(Roster.name)
            .setLimit(5);

        return taskListRosterColumn;
    }

    public setDate(week: Date) {
        this.listConfigurations.forEach((value, key) => {
            const date = this.getDate(key, week);
            value.setPlannedBetween(
                moment(date).startOf('day').toDate(),
                moment(date).endOf('day').toDate()
            );
        });
    }

    public getDate(day: RosterDay, week: Date) {
        switch (day) {
            default:
            case RosterDays.Monday:
                return moment(week).startOf('week').add('1', 'days').toDate();
            case RosterDays.Tuesday:
                return moment(week).startOf('week').add('2', 'days').toDate();
            case RosterDays.Wednesday:
                return moment(week).startOf('week').add('3', 'days').toDate();
            case RosterDays.Thursday:
                return moment(week).startOf('week').add('4', 'days').toDate();
            case RosterDays.Friday:
                return moment(week).startOf('week').add('5', 'days').toDate();
            case RosterDays.Saturday:
                return moment(week).startOf('week').add('6', 'days').toDate();
            case RosterDays.Sunday:
                return moment(week).startOf('week').add('7', 'days').toDate();
        }
    }

    public getAllTaskListConfigurations(): TaskListConfiguration[] {
        return [...this.listConfigurations.values()];
    }

    public getTaskListConfiguration(day: RosterDay): TaskListConfiguration {
        return this.listConfigurations.get(day);
    }

}
