import {AfterViewInit, Component, Injectable, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BaseComponent} from "@app/shared/_system/base/base.component";

@Injectable()
export class BaseModalComponent extends BaseComponent implements OnInit, AfterViewInit {

    @Input() primaryClass: string = 'btn-danger';
    protected activeModal: NgbActiveModal;

    isOpen = true;

    @Input() title: string;
    @Input() message: string;
    @Input() btnOkText: string;
    @Input() btnCancelText: string;

    constructor(private _modal: NgbActiveModal) {
        super();
        this.activeModal = _modal;
    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {

    }

    public decline() {
        this.activeModal.close(false);
    }

    public accept() {
        this.activeModal.close(true);
    }

    public dismiss() {
        this.activeModal.dismiss();
        this.activeModal.close(false);
    }

}
