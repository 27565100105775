import {NgModule} from "@angular/core";
import {CardMilestoneComponent} from "@app/shared/_ui/cards/medium/card-milestone/card-milestone.component";
import {CardAppointmentComponent} from "@app/shared/_ui/cards/medium/card-appointment/card-appointment.component";
import {CardCaseUserComponent} from "@app/shared/_ui/cards/medium/card-case-user/card-case-user.component";
import {
    CardOffice365EventComponent
} from "@app/shared/_ui/cards/medium/card-office-365-event/card-office-365-event.component";
import {CardProjectComponent} from "@app/shared";
import {CardRosterComponent} from "@app/shared/_ui/cards/medium/card-roster/card-roster.component";
import {CardTaskComponent} from "@app/shared/_ui/cards/medium/card-task/card-task.component";
import {CardTodoComponent} from "@app/shared/_ui/cards/medium/card-todo/card-todo.component";
import {CardUserComponent} from "@app/shared/_ui/cards/medium/card-user/card-user.component";
import {CardSwitcherComponent} from "@app/shared/_ui/cards/card-switcher/card-switcher.component";
import {CommonModule} from "@angular/common";
import {ColorLabelModule} from "@app/shared/_elements/color-label/ColorLabel.module";
import {TranslateModule} from "@ngx-translate/core";
import {NgbDropdownModule, NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {PlaceholderPipeModule} from "@app/pipes/PlaceholderPipe.module";
import {ToggleItemModule} from "@app/shared/_elements/toggle-item/ToggleItem.module";
import {HandUpSelectorModule} from "@app/shared/_elements/hand-up-selector/HandUpSelector.module";
import {StarSelectorModule} from "@app/shared/_elements/star-selector/StarSelector.module";
import {ReactionsModule} from "@app/shared/_ui/reactions/Reactions.module";
import {UrlLinkModule} from "@app/shared/_ui/url-link/UrlLink.module";
import {UsersWithIconModule} from "@app/shared/_elements/users-with-icon/UsersWithIcon.module";
import {TaskCalculatedFieldModule} from "@app/shared/_elements/task-calculated-field/TaskCalculatedField.module";
import {ItemDateModule} from "@app/shared/_elements/item-date/ItemDate.module";
import {CategoryModule} from "@app/shared/_elements/category/Category.module";
import {UserWithIconModule} from "@app/shared/_elements/user-with-icon/UserWithIcon.module";
import {ValueEvaluatorModule} from "@app/shared/_elements/value-evaluator/ValueEvaluator.module";
import {
    MilestoneCalculatedFieldModule
} from "@app/shared/_elements/milestone-calculated-field/MilestoneCalculatedField.module";
import {PhasesProjectPickerModule} from "@app/shared/_elements/phases-project-picker/PhasesProjectPicker.module";
import {ColorItemModule} from "@app/shared/_ui/color-item/ColorItem.module";
import {ProjectPhaseSearchModule} from "@app/shared/_elements/project-phase-search/ProjectPhaseSearch.module";
import {DropdownListItemModule} from "@app/shared/_ui/displays/dropdown-list-item/DropdownListItem.module";
import {
    MilestonePlanSelectorModule
} from "@app/shared/_elements/milestone-plan-selector/MilestonePlanSelector.module";
import {
    ProjectCalculatedFieldModule
} from "@app/shared/_elements/project-calculated-field/ProjectCalculatedField.module";
import {CurrentPhasesProjectModule} from "@app/shared/_elements/current-phases-project/CurrentPhasesProject.module";
import {ProcessStepsModule} from "@app/shared/_elements/process-steps/ProcessSteps.module";
import {TimeCalculatorModule} from "@app/shared/_elements/time-calculator/TimeCalculator.module";
import {CardPreviewModule} from "@app/shared/_ui/cards/card-preview/CardPreview.module";
import {
    MilestonePlanTimelineModule
} from '@app/shared/_ui/displays/milestone-plan/milestone-plan-timeline/MilestonePlanTimeline.module';
import {YearWheelModule} from '@app/shared/_ui/columns/year-wheel/YearWheel.module';
import {NgClickOutsideDirective} from "ng-click-outside2";

@NgModule({
    imports: [
        CommonModule,
        ColorLabelModule,
        TranslateModule,
        NgbTooltipModule,
        FormsModule,
        PlaceholderPipeModule,
        NgbPopoverModule,
        ToggleItemModule,
        HandUpSelectorModule,
        StarSelectorModule,
        ReactionsModule,
        UrlLinkModule,
        UsersWithIconModule,
        TaskCalculatedFieldModule,
        ItemDateModule,
        CategoryModule,
        UserWithIconModule,
        ValueEvaluatorModule,
        MilestoneCalculatedFieldModule,
        PhasesProjectPickerModule,
        ColorItemModule,
        ProjectPhaseSearchModule,
        NgbDropdownModule,
        DropdownListItemModule,
        MilestonePlanSelectorModule,
        ProjectCalculatedFieldModule,
        CurrentPhasesProjectModule,
        ProcessStepsModule,
        TimeCalculatorModule,
        CardPreviewModule,
        NgClickOutsideDirective,
        MilestonePlanTimelineModule,
        YearWheelModule,
        // MilestoneListModule, // TODO

    ],
    declarations: [
        CardAppointmentComponent,
        CardCaseUserComponent,
        CardMilestoneComponent,
        CardOffice365EventComponent,
        CardProjectComponent,
        CardRosterComponent,
        CardTaskComponent,
        CardTodoComponent,
        CardUserComponent,

        CardSwitcherComponent,
    ],
    exports: [
        CardAppointmentComponent,
        CardCaseUserComponent,
        CardMilestoneComponent,
        CardOffice365EventComponent,
        CardProjectComponent,
        CardRosterComponent,
        CardTaskComponent,
        CardTodoComponent,
        CardUserComponent,

        CardSwitcherComponent,
    ],
    providers: [

    ],
})
export class CardsModule {}
