import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseRow} from "@app/core/ColumnControl/BaseRow";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    SoftThisWeekTableColumn
} from "@app/pages/displays/dashboard/dashboard-calendar/TableColumns/SoftThisWeekTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/dashboard/dashboard-calendar/ColumnTypeSettings";
import {
    UserOverviewCalendar_SoftThisWeek_IncludeTaskTypesSettingValue
} from "@app/core/Api";

export class SoftThisWeekColumn extends BaseColumn {

    public createTableColumns(): BaseTableColumn[] {
        return [
            new SoftThisWeekTableColumn(this)
        ];
    }

    public createCell(row: BaseRow): BaseCell {
        throw new Error("Method not implemented.");
    }

    public getIncludeTaskTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.SoftThisWeek_IncludeTaskTypes)
            ?.getObject<UserOverviewCalendar_SoftThisWeek_IncludeTaskTypesSettingValue>()
            ?.taskTypeIds ?? [];
    }

}
