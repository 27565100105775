import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BaseModalComponent} from '@app/shared/_modals/base-modal.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Project} from '@app/core/models/Project';
import {Api} from "@app/core/Api";
import {EditorPanelService} from "@app/services/editor-panel.service";
import {AppInjector} from "@app/services/app-injector.service";
import {EventService} from "@app/services/event.service";
import {Task, TaskType} from '@app/core/models';
import {TasksService} from '@app/services/tasks.service';

export interface TasksTaskType {
    tasks: Task[];
    taskType: TaskType;
}


@Component({
    selector: 'app-copy-project-dialog',
    templateUrl: './copy-project-dialog.component.html',
    styleUrls: ['./copy-project-dialog.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1
            })),
            state('closed', style({
                opacity: 0
            })),
            transition('open => closed', [
                animate('1s')
            ]),
            transition('closed => open', [
                animate('0.5s')
            ]),
        ])
    ],
    standalone: false
})
export class CopyProjectDialogComponent extends BaseModalComponent implements OnInit {

    @Input() primaryClass: string = 'btn-success';
    @Input() item: Project;

    public project: Project;

    cascade: boolean = true;

    public copyMilestones:boolean = true;
    public copyTasks:boolean = true;
    public tasksTaskTypes:TasksTaskType[];

    constructor(private modal: NgbActiveModal,
                private tasksService:TasksService,
                private editorPanelService: EditorPanelService,) {
        super(modal);
    }

    ngOnInit() {
        if(this.item){
            Api.projects()
                .getById(this.item.id)
                .include('milestone')
                .include('task')
                .find((project)=>{
                    this.project = project[0];
                    const tasks = this.project.tasks;
                    if(tasks){
                        this.tasksService.getTaskTypes((tt)=>{
                            this.tasksTaskTypes = [];
                            tt.forEach(tt => {
                                this.tasksTaskTypes.push({
                                    taskType: tt,
                                    tasks: tasks.filter(t => t.task_type_id == tt.id)
                                })
                            })

                        })

                    }
                });
        }
    }

    public accept() {
        const api = Api.projects().copyGetById(this.item.id);
        if (this.copyMilestones) {
            api.copy_milestones(true);
        }
        if (this.copyTasks) {
            api.copy_tasks(true);
        }
        api.find( (r)=>{
            this.editorPanelService.open(r[0]);
            AppInjector.getInjector().get(EventService).emitProject(new Project(r[0]), EventService.Created);
        });

        this.activeModal.close({cascade: this.cascade});
    }

}
