import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {BaseColumnConfiguration} from "@app/editor/quick-editor/columns/BaseColumnConfiguration";
import {TextColumnComponent} from "@app/editor/quick-editor/columns/generic/text-column/text-column.component";
import {
    TemplateColumnComponent
} from "@app/editor/quick-editor/columns/generic/template-column/template-column.component";
import {
    SortableTextColumnComponent
} from "@app/editor/quick-editor/columns/generic/sortable-text-column/sortable-text-column.component";

@Component({
    selector: 'app-column-switcher',
    templateUrl: './column-switcher.component.html',
    styleUrls: ['./column-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ColumnSwitcherComponent extends BaseComponent {

    @Input() name: string;
    @Input() configuration: BaseColumnConfiguration;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected readonly TextColumnComponent = TextColumnComponent;
    protected readonly TemplateColumnComponent = TemplateColumnComponent;
    protected readonly SortableTextColumnComponent = SortableTextColumnComponent;
}
