import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {NgbDate, NgbDatepicker, NgbDatepickerI18n, NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {CustomDatepickerI18n} from '@app/datepicker-i18n';
import {LocalizedDatePipe} from "@app/pipes/localized-date.pipe";

@Component({
    selector: 'app-item-date',
    templateUrl: './item-date.component.html',
    styleUrls: ['./item-date.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        LocalizedDatePipe
    ] // define custom NgbDatepickerI18n provider
    ,
    standalone: false
})
export class ItemDateComponent extends BaseComponent implements OnInit, OnChanges {
    @ViewChild('datepickerReference') datepickerReference: NgbDatepicker;

    @Input() date: Date;
    @Input() label: string;
    @Input() alignClass = 'justify-content-end';
    @Input() showDatepicker: boolean;
    @Input() showClear: boolean = false;
    @Input() ellipsis = false;
    @Input() autofocus: boolean = false;
    @Input() showIcon: boolean = true;
    @Output() public onChangeDate: EventEmitter<Date> = new EventEmitter<Date>();

    @Output() public active: boolean = false;

    public displayDate: string;

    datePickerVisible: boolean = false;
    dateModel: NgbDateStruct;

    constructor(private cd: ChangeDetectorRef, private localizedDatePipe: LocalizedDatePipe) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        if (this.date) {
            this.dateModel = {year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDate()};
        }else{
            const date = new Date();
            this.dateModel = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
        }
        this.render();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const date: SimpleChange = changes.date;
        if (date && date.previousValue !== date.currentValue) {
            if (date.previousValue == null || date.currentValue == null
                || (date.previousValue as Date).getTime() != (date.currentValue as Date).getTime()) {
                this.date = date.currentValue;
                this.render();
            }
        }
    }

    private modelUpdateTimeout: number;

    private render() {
        this.displayDate = this.localizedDatePipe.transform(this.date, 'microDate');
        if (this.date) {
            clearTimeout(this.modelUpdateTimeout);
            this.modelUpdateTimeout = window.setTimeout(() => {
                this.dateModel = {
                    year: this.date.getFullYear(),
                    month: this.date.getMonth() + 1,
                    day: this.date.getDate(),
                };
                this.detectChanges();
            }, 100);
        } else {
            this.dateModel = null;
        }
    }

    changeSelectedDate(ngbDate: NgbDate) {
        this.datePickerVisible = false;
        const date: Date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
        this.onChangeDate.emit(date);
        this.active = false;
    }

    zeroValue() {
        this.datePickerVisible = false;
        this.onChangeDate.emit(null);
        this.active = false;
    }

    toggleCalendar($event: MouseEvent) {
        $event.stopPropagation();
        //console.log('toggle date : ', this.datePickerVisible);
        if (this.showDatepicker) {
            this.datePickerVisible = !this.datePickerVisible;
        }
    }

    toggleHide() {
        if (this.datePickerVisible) {
            this.datePickerVisible = false;
        }
    }

    stopPropagation($event: any) {
        $event.stopPropagation();

    }


    dpAutofocus(autofocus: boolean, datepickerReference: NgbInputDatepicker) {
        console.log('dpAutofocus() : ', autofocus, datepickerReference);

        if (autofocus && !datepickerReference.isOpen()) {
            this.active = true;
            datepickerReference.open();
        }


    }

    toggleDatepicker(datepickerReference: NgbInputDatepicker) {
        datepickerReference.toggle();
        this.active = datepickerReference.isOpen();

    }

    onDatepickerClose() {
        this.active = false;
    }
}
