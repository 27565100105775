import {BaseCell} from "@app/core/ColumnControl/BaseCell";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {TodoListConfiguration} from "@app/shared/_ui/lists/todo-list/TodoListConfiguration";
import {TodoListColumn} from "@app/pages/displays/display-projects/Columns/TodoListColumn";
import moment from "moment";
import {
    TodoUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoUserPresetGenerator";
import {AppInjector} from "@app/services/app-injector.service";
import {UsersService} from "@app/services/users.service";
import {
    TodoProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoProjectPresetGenerator";
import {
    TodoDisplayPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoDisplayPresetGenerator";
import {
    TodoShowOnDisplayPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TodoPresets/Generators/TodoShowOnDisplayPresetGenerator";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {CSVListBinding} from "@app/export/csv/CSVListBinding";
import {CSVCellValue} from "@app/export/csv/CSVCellValue";

export class TodoListCell extends GenericCell {

    public listConfiguration = new TodoListConfiguration();
    public csvListBinding = new CSVListBinding();

    constructor(row: ProjectsDisplayRow, column: TodoListColumn) {
        super(row, column);

        const archivedSince = moment(new Date())
            .subtract(column.getArchivedSinceSetting(), 'days')
            .startOf('day');
        this.listConfiguration
            .setOrArchivedSince(archivedSince.toDate());


        this.listConfiguration
            .setLimit(2)
            .setArchived(false)
            .setShowProjectMiniCard(false)
            .setProject(row.project.item)
            .setShowOnDisplay(true)
            .setDisplay(row.display)
            .setShowCreateNew(true)
            .setCreatePresetGenerators([
                // Defaults
                new TodoUserPresetGenerator(AppInjector.getInjector().get(UsersService).user.id),

                new TodoProjectPresetGenerator(row.project.item.id),
                new TodoDisplayPresetGenerator(row.display.id),
                new TodoShowOnDisplayPresetGenerator(true),
            ]);
    }

    public exportCSV(secondary?: any): CSVCellValue {
        return this.csvListBinding.export();
    }

}
