import {Component, Input, OnInit} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {AttachedType} from '@app/editor/project-editor-loader/project-editor-v3/components/project-editor-tabs/project-editor-tab-attach-to/attached-types/AttachedType';
import {Project} from '@app/core/models/Project';
import {fadeAnimation, slideDownAnimation} from '@app/animations';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-attached-types',
    templateUrl: './attached-types.component.html',
    styleUrls: ['./attached-types.component.scss'],
    animations: [
        fadeAnimation,
        slideDownAnimation,
        trigger('slide', [
            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({ opacity: 1, transform: 'translateY(0px)' })),
            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(-5px)' }),
                animate(150)
            ]),
            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave', animate(400, style({ opacity: 0, transform: 'translateY(5px)' })))
        ])
    ],
    standalone: false
})
export class AttachedTypesComponent extends BaseDisplayComponent implements OnInit {

    @Input() item!: Project;
    @Input() types: AttachedType[];

    constructor() {
        super();
    }

    ngOnInit() {

    }

    appendProject(model: Project) {
        const attachedType = this.getAttachedTypeByTypeId(model.project_type_id);
        attachedType.items.push(model);
        this.item.addProject(model);
    }

    removeProject(model: Project) {
        const attachedType = this.getAttachedTypeByTypeId(model.project_type_id);
        attachedType.removeItem(model);
        this.item.removeProject(model);
    }

    private getAttachedTypeByTypeId(typeId: number): AttachedType {
        return this.types.find((at:AttachedType) => {
            return at.modelType.id == typeId
        });
    }
}
