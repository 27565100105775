import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-warning-label',
    templateUrl: './warning-label.component.html',
    styleUrls: ['./warning-label.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WarningLabelComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
