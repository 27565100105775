import {Injectable} from '@angular/core';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {Milestone} from '@app/core/models/Milestone';
import {BaseService} from '@app/services/base.service';
import {EventService} from '@app/services/event.service';
import {MilestonePlan} from '@app/core/models/MilestonePlan';
import {MilestoneField} from "@app/core/models/MilestoneField";
import {FieldTypes, System} from "@app/constants";
import {Api} from '@app/core/Api';
import {CategoryType, CategoryTypesMilestone, MilestoneReactionType, Project} from "@app/core/models";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
    MilestoneArchiveDialogComponent
} from "@app/shared/_modals/milestone-archive-dialog/milestone-archive-dialog.component";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {ApiDataCache} from "@app/core/DataCache/ApiDataCache";

@Injectable({
    providedIn: 'root'
})
export class MilestonesService extends BaseService {

    // Updated in MilestonePlan.ts
    public milestonePlans$: Observable<MilestonePlan[]>; // Make observables so we can subscribe
    public _milestonePlans: BehaviorSubject<MilestonePlan[]>;

    constructor(private filterGlobalService: FilterGlobalService,
                private eventService: EventService,
                private modalService: NgbModal,) {
        super();

        this._milestonePlans = <BehaviorSubject<MilestonePlan[]>>new BehaviorSubject(null);
        this.milestonePlans$ = this._milestonePlans.asObservable();

        // Listen for archived patch and check for related project phase completion https://podio.com/klartboard/softwareudvikling/apps/stories/items/929
        this.eventService.subscribeToMilestone(0, event => {
            if (event.fields?.includes('archived') && (event.item.archived?.exists() ?? false)) {
                const milestone = event.item;
                // console.log('this.eventService.subscribeToMilestone : ', milestone);

                if (milestone.archived_id && milestone.archived_id != 0) {
                    const milestoneDeadline = event.item.deadline?.getDate();
                    // Get milestone, with tasks
                    Api.milestones().getById(event.item.id)
                        .find(milestones => {
                            const milestoneItem = milestones[0];

                            if (milestoneItem.deadline) {
                                // Find ud af om vi skal vise faser da vi har en frist, den skal samle alle faser og opgaver
                                Api.projects().get()
                                    .include('phases_project')
                                    .where('milestone.id', event.item.id)
                                    .find(projects => {
                                        const archiveProjects = projects.filter(p => p.phases_projects?.length > 0).filter(p => {
                                            const phasesProject = p.getPhasesProjectByDate(milestoneDeadline);
                                            if (phasesProject) {
                                                return true;
                                            }
                                        });
                                        if (archiveProjects && archiveProjects.length > 0 || milestoneItem.tasks && milestoneItem.tasks.filter(t => t.archived_id == 0).length > 0) {
                                            this.showDialog(milestoneItem, archiveProjects);
                                        }
                                    });
                            } else {
                                if (milestoneItem.tasks && milestoneItem.tasks.filter(t => t.archived_id == 0).length > 0) {
                                    this.showDialog(milestoneItem, null);
                                }
                            }
                        });
                }
            }
        });
    }

    private showDialog(milestoneItem: Milestone, projects: Project[]) {
        const modalRef = this.modalService.open(
            MilestoneArchiveDialogComponent,
            {
                size: 'md',
                windowClass: 'modal-holder',
                centered: true,
                backdrop: false
            });
        const componentRef = (modalRef.componentInstance as MilestoneArchiveDialogComponent);
        componentRef.item = milestoneItem;
        // componentRef.phasesProjects = phasesProjects;
        componentRef.projects = projects;
    }

    // <editor-fold desc="Fields (Queue & Cache)">

    private fieldsDataCache = new ApiDataCache<MilestoneField>(
        System.MilestoneFields,
        2 * 60 * 60, // 2 hours
        Api.milestoneFields().get()
    );

    private getFields(callback: (fields: MilestoneField[]) => void) {
        this.fieldsDataCache.get(callback);
    }

    public getEditorFields(callback: (fields: MilestoneField[]) => void) {
        this.getFields(fields => {
            callback(fields.filter(field => field.type == FieldTypes.Editor))
        });
    }

    public getMiniCardFields(callback: (fields: MilestoneField[]) => void) {
        this.getFields(fields => callback(fields.filter(field => field.type == FieldTypes.MiniCard)));
    }

    // </editor-fold>

    // <editor-fold desc="Reaction types (Queue & Cache)">

    private reactionTypesDataCache = new ApiDataCache<MilestoneReactionType>(
        System.MilestoneReactionTypes,
        2 * 60 * 60, // 2 hours
        Api.milestoneReactionTypes().get()
    );

    // Entry point
    public getReactionTypes(callback: (fields: MilestoneReactionType[]) => void) {
        this.reactionTypesDataCache.get(callback);
    }

    // </editor-fold>

    // <editor-fold desc="Category types (Queue & Cache)">

    private categoryTypesDataCache = new ApiDataCache<CategoryType>(
        System.MilestoneCategoryTypes,
        2 * 60 * 60, // 2 hours
        Api.categoryTypesMilestones().get()
            .include('category_type')
    );

    // Entry point
    public getCategoryTypesMilestones(callback: (categoryTypesMilestones: CategoryTypesMilestone[]) => void) {
        this.categoryTypesDataCache.get(callback);
    }

    // </editor-fold>

    search(term: string, projectId?: number): Observable<Milestone[]> {
        const api = Api.milestones().get()
            .include('project');

        if (projectId) {
            api.where('project.id', projectId);
        }

        if (term !== '') {
            api
                .search('name', term)
                .search('smart_search', ['responsible'])
                .orderDesc('created');
        }

        api
            .orderBy('name', this.filterGlobalService.getActiveSettings().activeSortDirection)
            .limit(25);

        return from(new Promise<Milestone[]>(resolve => {
            api.find(items => {
                resolve(items);
            });
        }));
    }

}
