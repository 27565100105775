import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges
} from '@angular/core';
import {DisplayTypes} from '@app/constants';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Display} from '@app/core/models/Display';
import {ResizeEvent} from 'angular-resizable-element';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {CasesRowHeightData} from '@app/pages/displays/display-cases/display-cases.component';
import {MultiDisplayVisibilityObserver} from "@app/core/MultiDisplayHelpers/MultiDisplayVisibilityObserver";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";

class DisplayVisibility {
    casesDisplayId: number = null;
    goalsDisplayId: number = null;
    loadSummaryDisplayId: number = null;
    loadOfficeDisplayId: number = null;
}

@Component({
    selector: 'app-sub-display-wrapper',
    templateUrl: './sub-display-wrapper.component.html',
    styleUrls: ['./sub-display-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SubDisplayWrapperComponent extends BaseDisplayComponent implements OnInit, OnChanges, OnDestroy {

    // Bindings to parent
    @Input() placement: Placement = 'top';
    @Input() multiDisplayVisibilityObserver: MultiDisplayVisibilityObserver;
    @Input() isExpanded = false;
    @Output() isExpandedChangeEvent = new EventEmitter<boolean>();

    // Bindings to view
    public visibleSubDisplays: number;
    public height: number = 35;
    public styleTop: object = {
        height: `${this.height}px`,
        minHeight: `10%`
    };
    public resizeStyle: object = {
        height: `${80}px`,
    };
    public displayVisibility: DisplayVisibility = {
        casesDisplayId: null,
        goalsDisplayId: null,
        loadSummaryDisplayId: null,
        loadOfficeDisplayId: null,
    };
    public resizing: boolean = false;
    public visible = false;

    // Data
    private destroy$ = new Subject<boolean>(); // https://alligator.io/angular/takeuntil-rxjs-unsubscribe/
    private previousHeight: number = null;
    private rowData: CasesRowHeightData;
    private fixedHeight: boolean = false;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribe(this.shellService.onPrimaryPageDataChangeEvent
            .pipe(takeUntil(this.destroy$))
            .subscribe(pageData => {
                this.visible = false;
                if (pageData?.display?.relateddisplays) {
                    this.findSubDisplay(pageData.display.relateddisplays);
                }
            }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['isExpanded']) {
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy$.next(true);
        // Now let's also unsubscribe from the subject itself:
        this.destroy$.unsubscribe();
    }

    validate(event: ResizeEvent): boolean {
        return true;
    }

    onResizeEnd($event: any) {
        const headerFooterHeight = 30 + (this.displayVisibility.loadSummaryDisplayId ? 50 : 50)
        this.height = Math.max($event.rectangle.height, headerFooterHeight);
        this.resizing = false;

        this.fixedHeight = this.height > 200;
        // console.log('onResizeEnd : ', $event.rectangle.height, this.height, this.fixedHeight);
        this.applySize();
    }

    private applySize() {
        if (this.visibleSubDisplays <= 1 || this.isExpanded) {
            this.styleTop = {
                height: `100%`,
                minHeight: this.displayVisibility?.loadSummaryDisplayId ? `50px` : `258px`
            };
        } else {
            this.styleTop = {
                height: `${this.height}px`
            };
            this.resizeStyle = {
                height: `${this.height}px`,
            };
        }
        window.dispatchEvent(new Event('resize'));
    }

    private findSubDisplay(displays: Display[]) {
        this.displayVisibility.casesDisplayId = null;
        this.displayVisibility.goalsDisplayId = null;
        this.displayVisibility.loadSummaryDisplayId = null;
        this.displayVisibility.loadOfficeDisplayId = null;
        this.visible = false;

        // TO-DO: Tjek om den fejler eller køres flere gange her: https://podio.com/klartboard/klartboard-input/apps/support/items/1472
        displays.forEach(displayRelatedDisplay => {
            switch (displayRelatedDisplay.display_type_id) {
                case DisplayTypes.CaseShelves:
                    // console.log('findSubdisplay : ', displays, this.placement, 'pageSettings : ', this.pageSettings);
                    if (this.placement == 'top') {
                        this.displayVisibility.casesDisplayId = displayRelatedDisplay.id;
                        this.visible = true;

                    }
                    break;
                case DisplayTypes.LoadSummary:
                    if (this.placement == 'bottom') {
                        this.displayVisibility.loadSummaryDisplayId = displayRelatedDisplay.id;
                        this.visible = true;
                        if (this.rowData)
                            this.updateCasesHeight(this.rowData, true)
                    }
                    break;
                default:
                    break;

            }
        });

        if (this.visible) {
            this.subscribe(this.multiDisplayVisibilityObserver.subscribeAsDisplay(visibleDisplays => {
                this.visibleSubDisplays = visibleDisplays;
                this.detectChanges();
            }));
            this.applySize();
        }
    }

    public isExpandedChanged($event: boolean) {
        this.isExpandedChangeEvent.emit($event);
        this.isExpanded = $event;
        if (this.isExpanded) {
            this.previousHeight = this.height;
            this.styleTop = {
                height: `100%`,
                minHeight: this.displayVisibility?.loadSummaryDisplayId ? `50px` : `258px`
            };
            window.dispatchEvent(new Event('resize'));
        } else {
            this.height = this.previousHeight ? this.previousHeight : 30;
            this.applySize();
        }
    }

    updateCasesHeight(data: CasesRowHeightData, force = false) {
        console.log('updateCasesHeight : ', data);
        if (this.visible) {
            const headerFooterHeight = 43 + (this.displayVisibility.loadSummaryDisplayId ? 60 : 60)
            if (!this.rowData || this.rowData?.height != data.height || this.rowData?.rows != data.rows || force) {
                this.rowData = data;
                const itemHeight = data.height || 100;

                if (this.isExpanded) {
                    this.previousHeight = data.rows <= 3 ? (data.rows * itemHeight) + headerFooterHeight : 350;
                } else {

                    let element = document.getElementById('display-cases');
                    let height = 0;
                    if (element) {
                        let table = element.getElementsByTagName('datatable-selection');
                        if (table && table.length > 0) {
                            // rows are element swith class datatable-row-wrapper
                            let rows = table[0].getElementsByClassName('datatable-row-wrapper');
                            for (let i = 0; i < Math.min(3, rows.length); i++) {
                                height += rows[i].clientHeight;
                            }
                        }
                    }

                    if (!this.fixedHeight || this.height < height) {
                        // this.height = (Math.min(data.rows, 3) * itemHeight) + headerFooterHeight; // Max 3 rækker
                        // find height of the first 3 rows
                        // get element with id display-cases, find datatable and get the first 3 rows height

                        this.height = height + headerFooterHeight;

                    }
                    console.log('updateCasesHeight : ', this.height, 'this.fixedHeight : ', this.fixedHeight);
                    this.applySize();
                }
            }
        }
    }

    onResizeStart($event: ResizeEvent) {
        this.resizing = true;
    }

    onResize($event: ResizeEvent) {
        this.resizeStyle = {
            height: `${$event.rectangle.height}px`,
        };
        this.height = $event.rectangle.height;
    }

    onSortChange($event: BaseTableColumn) {
        console.log('onSortChange : ', $event);
        // update height
        this.updateCasesHeight(this.rowData, true);

    }
}

export type Placement = 'top' | 'bottom';
