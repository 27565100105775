import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {AnyItem} from "@app/interfaces/CustomTypes";

export class NonArchivedFilter extends BaseOnScreenFilter<AnyItem> {

    // Præsenteret som "Fuldført med omvendt ikon"
    public name = '_displays_project_details_filter_archived_tasks';
    public iconActive = 'fa-circle-o';
    public iconInactive = 'fa-check-circle';
    public invertIcon: boolean = false;

    protected validate(item: AnyItem): boolean {
        if ((item as any)['archived_id']) {
            return (item as any).archived_id == 0;
        } else {
            return true;
        }
    }

}
