import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-header-spacer',
    templateUrl: './header-spacer.component.html',
    styleUrls: ['./header-spacer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HeaderSpacerComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {

    }

}
