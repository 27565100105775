/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {MilestonePlanDefinition} from './definitions/MilestonePlanDefinition';
import {Api} from "@app/core/Api";
import {AppInjector} from "@app/services/app-injector.service";
import {MilestonesService} from "@app/services/milestones.service";
import {UsersService} from "@app/services/users.service";
import Globals from "@app/constants";
import {Department} from "@app/core/models/Department";
import {ShellService} from "@app/services/ShellService/shell.service";

export class MilestonePlan extends MilestonePlanDefinition {

    constructor(json?: any) {
        super(json);
    }

    public isGlobal(): boolean {
        return this.user_id == 0 && (this.departments?.length ?? 0) == 0;
    }

    public isTeam(): boolean {
        return this.departments?.length > 0;
    }

    public isPersonal(): boolean {
        return this.user_id > 0;
    }

    public isVisibleForUser(): boolean {
        const user = AppInjector.getInjector().get(UsersService)?.user;

        if (this.isGlobal()) {
            return true;
        }

        if (this.isTeam()) {
            if (user?.uber) {
                return true;
            }

            if(this.created_by_id == user?.id)
                return true;
            return this.departments?.find(department => {
                return department.id == AppInjector.getInjector().get(ShellService).getPageSettings().departmentId;
            }) !== undefined;
        }

        if (this.isPersonal()) {
            return this.user_id == user?.id;
        }
        return false;
    }

    public isEditableForUser(): boolean {
        const user = AppInjector.getInjector().get(UsersService)?.user;
        if (!user)
            return false;

        if (this.id == 0) {
            return true;
        }

        if (user?.uber) {
            return true;
        }

        if (user?.administrator) {
            return true;
        }

        if (this.isPersonal()) {
            return this.user_id == user.id;
        }
        if (this.isGlobal() || this.isTeam()) {
            return user.user_group_id <= Globals.UserGroups.Admin;
        }
    }

    // <editor-fold desc="Api">

    public addDepartment(department: Department) {
        if (!this.departments) {
            this.departments = [];
        }
        this.departments.push(department);
        Api.milestonePlans().departmentAddPostByIdByDepartmentId(this.id, department.id)
            .save(null);
    }

    public removeDepartment(department: Department) {
        this.departments.splice(this.departments.findIndex(d => d.id == department.id), 1);
        Api.milestonePlans().departmentRemoveDeleteByIdByDepartmentId(this.id, department.id)
            .delete();
    }

    public ensureDepartments(departments: Department[]) {
        this.departments = departments;
        Api.milestonePlans().departmentsEnsurePutById(this.id)
            .departmentIds(departments.map(department => department.id))
            .save(null);
    }

    // </editor-fold>

    // <editor-fold desc="Milestone Plans (Queue & Cache)">

    private static MilestonePlansCache: MilestonePlan[];
    private static MilestonePlansCallbacks: ((items: MilestonePlan[]) => void)[] = [];

    public static GetAll(callback: (items: MilestonePlan[]) => void) {
        if (this.MilestonePlansCache) {
            callback(this.MilestonePlansCache);
        } else if (this.MilestonePlansCallbacks.length) {
            this.MilestonePlansCallbacks.push(callback);
        } else {
            this.MilestonePlansCallbacks.push(callback);
            Api.milestonePlans().get()
                .include('department')
                .orderAsc('index_')
                .find(items => {
                    this.MilestonePlansCache = items;
                    this.UpdateCallbacks();
                });
        }
    }

    public static InvalidateMilestonePlansCache() {
        delete this.MilestonePlansCache;
        this.GetAll(() => {
        }); // Trigger push
    }

    public static UpdateMilestonePlan(milestonePlan: MilestonePlan) {
        // console.log('UpdateMilestonePlan() : milestonePlan : ', milestonePlan)
        if (!this.MilestonePlansCache) return;
        const index = this.MilestonePlansCache.findIndex(mp => mp.id == milestonePlan.id);

        if (index == -1) {
            this.MilestonePlansCache.push(milestonePlan);
        } else {
            this.MilestonePlansCache[index] = milestonePlan;
        }
        this.UpdateCallbacks();

    }

    private static UpdateCallbacks() {
        AppInjector.getInjector().get(MilestonesService)._milestonePlans.next(this.MilestonePlansCache);
        this.MilestonePlansCallbacks.forEach(callback => callback(this.MilestonePlansCache));
        this.MilestonePlansCallbacks = [];
    }

    public static DeleteMilestonePlan(milestonePlan: MilestonePlan) {
        if (!this.MilestonePlansCache) return;

        const index = this.MilestonePlansCache.findIndex(mp => mp.id == milestonePlan.id);
        if (index !== -1) {
            this.MilestonePlansCache.splice(index, 1);
            this.UpdateCallbacks();
        }
    }

    // </editor-fold>

}
