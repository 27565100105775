import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {Phase, PhaseProgressType, PhasesProject, Project} from '@app/core/models';
import {Events} from "@app/constants";
import {RxStompService} from "@app/core/rabbitmq/rx-stomp.service";
import {ChangeMessage} from "@app/core/rabbitmq/ChangeMessage";

@Component({
    selector: 'app-current-phases-project',
    templateUrl: './current-phases-project.component.html',
    styleUrls: ['./current-phases-project.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CurrentPhasesProjectComponent extends BaseComponent implements OnInit, OnChanges {

    // Source
    @Input() project: Project;

    // Events
    @Output() onIsEmptyChangeEvent = new EventEmitter<boolean>();

    // Data
    private phasesProject: PhasesProject;

    // UI
    public phase: Phase;
    public hasProgress: boolean;
    public progressType: PhaseProgressType;

    constructor(private cd: ChangeDetectorRef, private rxStompService: RxStompService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.render();

        if (this.project) {
            this.subscribe(this.rxStompService
                .watch(Events.ProjectPhasesProjectsChanged(this.project.id))
                .subscribe(message => {
                    const currentPhasesProject = (JSON.parse(message.body).extra as any).currentPhasesProject;
                    this.project.current_phases_project = currentPhasesProject ? new PhasesProject(currentPhasesProject) : null;
                    this.project.current_phases_project_id = this.project.current_phases_project?.id ?? null;
                    this.render();
                }));
            this.subscribe(this.rxStompService
                .watch(Events.ProjectChangedCurrentPhasesProject(this.project.id))
                .subscribe(message => {
                    this.project.populate(ChangeMessage.ParseRabbitMQMessage(message.body).next, true);
                    this.render();
                }));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        this.phasesProject = this.project?.current_phases_project ?? null;
        this.phase = this.phasesProject?.phase ?? null;
        this.onIsEmptyChangeEvent.emit(this.phase == null);
        this.hasProgress = this.phasesProject?.current_phase_progress?.to_phase_progress_type?.exists();
        this.progressType = this.phasesProject?.current_phase_progress?.to_phase_progress_type;
        if (this.hasProgress) {
            // Tjek om tilknytningen mellem fasen og fremdriften stadig er gyldig https://podio.com/klartboard/softwareudvikling/apps/supports/items/584
            Phase.GetSingle(this.phase.id, phase => {
                const hasPhaseProgressType = phase.getVisiblePhaseProgressTypes().find(phaseProgressType => phaseProgressType.id == this.progressType?.id);
                if (!hasPhaseProgressType) {
                    this.hasProgress = false;
                    this.progressType = undefined;
                    this.detectChanges();
                }
            });
        }
        this.detectChanges();
    }

}
