import {AppInjector} from '@app/services/app-injector.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Injector} from '@angular/core';
import {OAuthService} from "angular-oauth2-oidc";

export class BaseService {

    protected httpClient: HttpClient;
    protected oAuthService: OAuthService;
    protected translateService: TranslateService;
    protected injector: Injector;

    constructor() {
        this.injector = AppInjector.getInjector();
        if (this.injector) {
            this.httpClient = this.injector.get(HttpClient);
            this.oAuthService = this.injector.get(OAuthService);
            this.translateService = this.injector.get(TranslateService);
        } else {
            console.error('Injector missing!', this.injector)
        }
    }

    public getAuthorizationHeader(): HttpHeaders {
        // Creates header for the auth requests.
        let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.append('Accept', 'application/json');

        const token: string = this.oAuthService.getAccessToken();
        if (token !== '') {
            const tokenValue: string = 'Bearer ' + token;
            headers = headers.append('Authorization', tokenValue);
        }
        return headers;
    }

}
