import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";

@Component({
    selector: 'app-window-action-buttons',
    templateUrl: './window-action-buttons-component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WindowActionButtonsComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() validator: ValidationErrorInterface;

    @Output() close = new EventEmitter();
    @Output() maximize = new EventEmitter();
    @Output() minimize = new EventEmitter();

    // Bindings to view
    public isCloseBtnDisabled = false;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.validator) {
            this.renderValidation();
            this.subscribe(this.validator.onIsValidChanged.subscribe(() => {
                this.renderValidation();
                this.detectChanges();
            }));
        }
    }

    private renderValidation() {
        this.isCloseBtnDisabled = !this.validator.isValid();
    }

    // <editor-fold desc="View actions">

    public onMinimizeBtnClicked() {
        this.minimize.emit();
    }

    public onMaximizeBtnClicked() {
        this.maximize.emit();
    }

    public onCloseBtnClicked() {
        if (!this.isCloseBtnDisabled) {
            this.close.emit();
        }
    }

    // </editor-fold>

}
