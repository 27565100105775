/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {TaskDefinition} from './definitions/TaskDefinition';
import {
    Events, ProjectStatusTypes,
    StatusTypes,
    TaskDeadlineTypes,
    TaskStatusTypes,
    TasksUserAcceptanceStatus,
    TaskUserTypes
} from '@app/constants';
import {AppInjector} from '@app/services/app-injector.service';
import {EventService} from '@app/services/event.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CaseArchivedDialogComponent} from '@app/shared/_modals/case-archived-dialog/case-archived-dialog.component';
import {TasksDeadline} from '@app/core/models/TasksDeadline';
import {TaskStatus} from '@app/core/models/TaskStatus';
import {Archived} from '@app/core/models/Archived';
import {TaskEstimate} from '@app/core/models/TaskEstimate';
import {TasksUser} from '@app/core/models/TasksUser';
import {Project} from '@app/core/models/Project';
import {TranslateService} from "@ngx-translate/core";
import {Milestone} from '@app/core/models/Milestone';
import {Api, TasksCreateRequestPreset} from '@app/core/Api';
import {Deadline} from "@app/core/models/Deadline";
import {ReactionsApiInterface} from "@app/shared/_ui/reactions/ReactionsApiInterface";
import {Reaction} from "@app/core/models/Reaction";
import {ReactionsSourceInterface} from "@app/shared/_ui/reactions/ReactionsSourceInterface";
import {HasDeadlines} from "@app/interfaces/HasDeadlines";
import {HasConfigurations} from "@app/interfaces/HasConfigurations";
import {HasTitle} from "@app/interfaces/HasTitle";
import {HasMainStatus} from "@app/interfaces/HasMainStatus";
import {HasEnhancedUsers} from "@app/interfaces/HasUsers";
import {User} from "@app/core/models/User";
import {HasHansUps} from "@app/interfaces/HasHansUps";
import {HasStars} from "@app/interfaces/HasStars";
import {EditTitle} from "@app/editor/quick-editor/editors/generic/title-editor/EditTitle";
import {HasTypeProperties} from "@app/interfaces/HasTypeProperties";
import {TasksService} from "@app/services/tasks.service";
import {EditStatus} from "@app/editor/quick-editor/editors/generic/status-editor/EditStatus";
import {EditDeadlineList} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineList";
import {EditEstimateList} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EditEstimateList";
import {Estimate} from "@app/core/models/Estimate";
import {Category} from "@app/core/models/Category";
import {EditText} from "@app/editor/quick-editor/editors/generic/text-editor/EditText";
import {EditAction} from "@app/editor/quick-editor/editors/generic/action-editor/EditAction";
import {ConfirmationDialogComponent} from "@app/shared/_modals/confirmation-dialog/confirmation-dialog.component";
import {EditMilestoneList} from "@app/editor/quick-editor/editors/generic/milestone-list-editor/EditMilestoneList";
import {
    EditMilestoneListFilter
} from "@app/editor/quick-editor/editors/generic/milestone-list-editor/EditMilestoneListFilter";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {Department, Tag} from "@app/core/models/index";
import {EditArchived} from "@app/editor/quick-editor/editors/generic/archived-editor/EditArchived";
import {EditReactionList} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionList";
import {EditLink} from "@app/editor/quick-editor/editors/generic/link-editor/EditLink";
import {EditStatusList} from "@app/editor/quick-editor/editors/generic/status-list-editor/EditStatusList";
import {EditUseStatusRules} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/EditUseStatusRules";
import {
    EditCategoryPickerList
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerList";
import {
    EditEnhancedUserList,
} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserList";
import {EditTagList} from "@app/editor/quick-editor/editors/generic/tag-list-editor/EditTagList";
import {
    EditDepartmentPicker
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/EditDepartmentPicker";
import {EditCanCopy} from "@app/editor/quick-editor/editors/generic/can-copy-editor/EditCanCopy";
import {EditIsPrivate} from "@app/editor/quick-editor/editors/generic/is-private-editor/EditIsPrivate";
import {EditNumber} from "@app/editor/quick-editor/editors/generic/number-editor/EditNumber";
import Helpers from "@app/core/helpers";
import {EnhancedUser} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserItem";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";
import {
    MilestoneProjectPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneProjectPresetGenerator";
import {
    MilestoneTaskPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneTaskPresetGenerator";
import {ProjectsService} from "@app/services/projects.service";

export class Task extends TaskDefinition implements
    ReactionsApiInterface,
    ReactionsSourceInterface,

    HasDeadlines,
    HasConfigurations,
    HasTitle,
    HasMainStatus,
    HasHansUps,
    HasStars,
    HasEnhancedUsers,
    HasTypeProperties,
    HasEventGenerator,

    EditTitle,
    EditArchived,
    EditReactionList,
    EditText,
    EditNumber,
    EditLink,
    EditDeadlineList,
    EditEstimateList,
    EditStatusList,
    EditStatus,
    EditUseStatusRules,
    EditCategoryPickerList,
    EditEnhancedUserList,
    EditTagList,
    EditDepartmentPicker,
    EditAction,
    EditCanCopy,
    EditIsPrivate,
    EditMilestoneList {

    constructor(json?: any) {
        super(json);
    }

    public get status(): number {
        if (this.main_status && this.main_status.status_id) {
            return this.main_status.status_id;
        } else {
            return StatusTypes.GREEN;
        }
    }

    public set status(value: number) {
        if (this.main_status && this.main_status.status_id) {
            this.main_status.status_id = value;
            if (this.main_status) delete this.main_status.status;
        } else {
            this.main_status = new TaskStatus(
                {status_id: value, task_status_type_id: TaskStatusTypes.Normal}
            );
        }
    }

    public checkArchivedDialog() {
        if (!this.archived_id || this.archived_id == 0) {
            // If Case: Show dialog "Du er nået x ud af y planlagt..." https://podio.com/klartboard/softwareudvikling/apps/stories/items/204
            if (this.cases_planned > 0 || this.cases_reached > 0) {
                const translateService = AppInjector.getInjector().get(TranslateService);
                const modalRef = AppInjector.getInjector().get(NgbModal).open(
                    CaseArchivedDialogComponent,
                    {
                        size: "sm",
                        windowClass: 'z-index-2000',
                        centered: true
                    });
                modalRef.componentInstance.title = translateService.instant('_ui_archive_task_dialog_cases_planned', {
                    planned: this.cases_planned ?? 0,
                    reached: this.cases_reached ?? 0,
                });
                modalRef.componentInstance.message = translateService.instant('_ui_archive_task_dialog_cases_message');
                modalRef.componentInstance.btnOkText = translateService.instant('_global_save');
                modalRef.componentInstance.btnCancelText = translateService.instant('_global_cancel');
                modalRef.componentInstance.task = this;
                modalRef.result.then(value => {
                    if (value) {
                        if (this.cases_planned != value.planned) {
                            this.setCasesPlanned(value.planned);
                        }
                        if (this.cases_reached != value.reached) {
                            this.setCasesReached(value.planned);
                        }
                    }
                });
            }
        }
    }

    public getDefaultTaskEstimate(): TaskEstimate {
        if (this.task_estimates?.length > 0 && this.task_type?.default_task_estimate_type_id) {
            return this.findTaskEstimateByType(this.task_type.default_task_estimate_type_id);
        }
    }

    public findTaskEstimateByType(taskEstimateTypeId: number): TaskEstimate {
        if (this.task_estimates) {
            return this.task_estimates.find(taskEstimate => taskEstimate.task_estimate_type_id == taskEstimateTypeId);
        } else {
            return null;
        }
    }

    public findTasksDeadlineByType(taskDeadlineTypeId: number): TasksDeadline {
        if (this.tasks_deadlines) {
            return this.tasks_deadlines.find(tasksDeadline => tasksDeadline.task_deadline_type_id == taskDeadlineTypeId);
        } else {
            return null;
        }
    }

    public findTasksUsersByType(taskUserTypeId: number, userId?: number): TasksUser[] {
        if (this.tasks_users)
            return this.tasks_users.filter(tasksUser => tasksUser.task_user_type_id == taskUserTypeId && (userId ? tasksUser.user_id == userId : true));
        else
            return [];
    }

    public findParticipant(userId: number): TasksUser {
        let tasksUsers = this.findTasksUsersByType(TaskUserTypes.Participant, userId);
        return tasksUsers && tasksUsers.length > 0 ? tasksUsers[0] : null;
    }

    // <editor-fold desc="HasDeadlines">

    public findDeadlineByTypeId(typeId: number): Deadline | undefined {
        const deadlines = this.tasks_deadlines
            ?.filter(tasksDeadline => tasksDeadline.task_deadline_type_id == typeId)
            ?.map(tasksDeadline => tasksDeadline.deadline);
        return deadlines?.length > 0 ? deadlines[0] : undefined;
    }

    // </editor-fold>

    // <editor-fold desc="HasConfigurations">

    public getMiniCardDeadlineTypeId(): number {
        return TaskDeadlineTypes.Normal;
    }

    // </editor-fold>

    // <editor-fold desc="HasUsers">

    public getUsers(): User[] {
        return this.tasks_users?.map(tasksUser => tasksUser.user) ?? [];
    }

    public findUsersByTypeId(typeId: number): User[] {
        return this.tasks_users
            ?.filter(tasksUser => tasksUser.task_user_type_id == typeId)
            ?.map(tasksUser => tasksUser.user) ?? [];
    }

    public findEnhancedUsersByTypeId(typeId: number): EnhancedUser[] {
        return this.tasks_users
            ?.filter(tasksUser => tasksUser.task_user_type_id == typeId)
            ?.map(tasksUser => new EnhancedUser(
                tasksUser.user,
                tasksUser.is_order ? tasksUser.acceptance_status : undefined,
                tasksUser.deadline,
                tasksUser.archived
            )) ?? [];
    }

    // </editor-fold>

    // <editor-fold desc="Has Type Properties">

    getTypeDefinitionAsync(callback: (definition: string) => void): void {
        if (this.task_type_id) {
            AppInjector.getInjector().get(TasksService).getTaskType(this.task_type_id, taskType => {
                callback(taskType.definition);
            });
        } else {
            callback('');
        }
    }

    getTypeNameAsync(callback: (name: string) => void): void {
        if (this.task_type_id) {
            AppInjector.getInjector().get(TasksService).getTaskType(this.task_type_id, taskType => {
                callback(taskType.name);
            });
        } else {
            callback('');
        }
    }

    // </editor-fold>

    // <editor-fold desc="Logic for defining Bestillinger">

    public findMyLevel(userId: number, allowedUserTypes?: number[]): number {
        let index = -1;
        for (let tasksUser of this.tasks_users) {
            if (tasksUser.user_id == userId) { // Korrekt userId

                // Tjek for tillads userType, hvis angivet
                if (allowedUserTypes && !allowedUserTypes.includes(tasksUser.task_user_type_id)) continue; // User type not allowed

                let taskTypesTaskUserType = this.task_type.getTaskTypesTaskUserType(tasksUser.task_user_type_id);
                if (!taskTypesTaskUserType) continue; // User type not configured

                index = (index == -1) ? taskTypesTaskUserType.index_ : Math.min(index, taskTypesTaskUserType.index_); // Sæt index if not set, else update is this is lower
            }
        }
        return index;
    }

    public findNextLevel(myLevel: number): number {
        let index = -1;
        for (let tasksUser of this.tasks_users) {
            let taskTypesTaskUserType = this.task_type.getTaskTypesTaskUserType(tasksUser.task_user_type_id);
            if (!taskTypesTaskUserType) continue; // User type not configured
            if (taskTypesTaskUserType.index_ > myLevel)
                index = index == -1 ? taskTypesTaskUserType.index_ : Math.min(index, taskTypesTaskUserType.index_);
        }
        return index;
    }

    public findUsersByLevel(level: number): TasksUser[] {
        let tasksUsers = [];
        for (let tasksUser of this.tasks_users) {
            let taskTypesTaskUserType = this.task_type.getTaskTypesTaskUserType(tasksUser.task_user_type_id);
            if (!taskTypesTaskUserType) continue; // User type not configured
            if (taskTypesTaskUserType.index_ == level) tasksUsers.push(tasksUser);
        }
        return tasksUsers;
    }

    public accept(callback?: () => void) {
        this.acceptance_status = TasksUserAcceptanceStatus.Approved;
        Api.tasks()
            .updateUserAcceptanceStatusPutByTaskId(this.id)
            .value(TasksUserAcceptanceStatus.Approved)
            .save(null, task => {
                if (callback) {
                    callback();
                }
            });
        AppInjector.getInjector().get(EventService).emitTask(this, EventService.Updated, ['acceptance_status']);
    }

    public decline(callback?: () => void) {
        this.acceptance_status = TasksUserAcceptanceStatus.Declined;
        Api.tasks()
            .updateUserAcceptanceStatusPutByTaskId(this.id)
            .value(TasksUserAcceptanceStatus.Declined)
            .save(null, task => {
                if (callback) {
                    callback();
                }
            });
        AppInjector.getInjector().get(EventService).emitTask(this, EventService.Deleted);
    }

    // </editor-fold>

    public static Create(typeId: number, presets: TasksCreateRequestPreset[], callback?: (task: Task) => void) {
        Api.tasks()
            .createPost()
            .typeId(typeId)
            .save({presets: presets}, task => {
                AppInjector.getInjector().get(EventService).emitTask(task, EventService.Created);
                if (callback) {
                    callback(task);
                }
            });
    }

    public delete(callback?: () => void) {
        Api.tasks()
            .deleteById(this.id)
            .delete(task => {
                AppInjector.getInjector().get(EventService).emitTask(this, EventService.Deleted);
                if (callback) {
                    callback();
                }
            });
    }

    getEventName(field: string, options: any = {}): string {
        switch (field) {
            case 'title':
                return Events.TaskChangedTitle(this.id);
            case 'archived':
                return Events.TaskChangedArchived(this.id);
            case 'category-list':
            case 'categories':
                return Events.TaskChangedCategories(this.id);
            case 'deadlines':
                return Events.TaskChangedDeadlines(this.id);
            case 'main-status':
            case 'status':
                return Events.TaskChangedStatuses(this.id);
            case 'use_status_rules':
                return Events.TaskChangedUseStatusRules(this.id);
            case 'users':
                return Events.TaskChangedUsers(this.id);
            case 'note-field':
                switch (options?.prop ?? '') {
                    case 'delivery_description':
                        return Events.TaskChangedDeliveryDescription(this.id);
                    case 'success_criteria':
                        return Events.TaskChangedSuccessCriteria(this.id);
                    case 'obs':
                        return Events.TaskChangedObs(this.id);
                    default:
                        return Events.TaskChanged(this.id, field);
                }
            case 'number-field':
                switch (options?.prop ?? '') {
                    case 'cases_planned':
                        return Events.TaskChangedCasesPlanned(this.id);
                    case 'cases_reached':
                        return Events.TaskChangedCasesReached(this.id);
                    default:
                        return Events.TaskChanged(this.id, field);
                }
            case 'link':
                return Events.TaskChangedPurpose(this.id);
            case 'estimates':
                return Events.TaskChangedEstimates(this.id);
            case 'departments':
                return Events.TaskChangedDepartments(this.id);
            case 'tags':
                return Events.TaskChangedTags(this.id);
            case 'can_copy':
                return Events.TaskChangedCanCopy(this.id);
            case 'is_private':
                return Events.TaskChangedIsPrivate(this.id);
            case 'milestones':
                return Events.TaskChangedMilestones(this.id);
            case 'projects':
                return Events.TaskChangedProjects(this.id);
            default:
                return Events.TaskChanged(this.id, field);
        }
    }

    // <editor-fold desc="EditTitle">

    getTitle(): string {
        return this.title ?? '';
    }

    setTitle(value: string): void {
        this.title = value;
        Api.tasks()
            .updateTitlePutByTaskId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['title']);
    }

    // </editor-fold>

    // <editor-fold desc="EditUserList / EditEnhancedUserList">

    public addUser(typeId: number, user: User, callback?: (() => void)): void {
        if (!this.tasks_users) {
            this.tasks_users = [];
        }
        if (this.tasks_users.some(tasksUser => tasksUser.task_user_type_id == typeId
            && tasksUser.user_id == user.id)) {
            return;
        }

        this.tasks_users.push(TasksUser.Create(typeId, user.id, user));

        Api.tasks()
            .addUserPutByTaskId(this.id)
            .taskUserTypeId(typeId)
            .userId(user.id)
            .save(null, callback);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['tasks_users']);
    }

    public addUsersFromDepartment(typeId: number, department: Department, users: User[]): void {
        if (!this.tasks_users) {
            this.tasks_users = [];
        }

        const newUsers = users
            .filter(user => {
                return !this.tasks_users
                    .some(tasksUser => tasksUser.task_user_type_id == typeId
                        && tasksUser.user_id == user.id);
            });

        if (newUsers.length == 0) {
            return;
        }

        newUsers.forEach(user => {
            this.tasks_users.push(TasksUser.Create(typeId, user.id, user));
        });

        Api.tasks()
            .addUserFromDepartmentPutByTaskId(this.id)
            .taskUserTypeId(typeId)
            .departmentId(department.id)
            .userIds(newUsers.map(user => user.id))
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['tasks_users']);
    }

    public removeUser(typeId: number, user: User): void {
        this.tasks_users = this.tasks_users
            ?.filter(tasksUser => {
                return !(tasksUser.task_user_type_id == typeId && tasksUser.user_id == user.id);
            });

        Api.tasks()
            .removeUserPutByTaskId(this.id)
            .taskUserTypeId(typeId)
            .userId(user.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['tasks_users']);
    }

    public removeUsers(typeId: number, users: User[]): void {
        const userIds = users.map(user => user.id);

        this.tasks_users = this.tasks_users
            ?.filter(tasksUser => {
                return !(tasksUser.task_user_type_id == typeId && userIds.includes(tasksUser.user_id));
            });

        Api.tasks()
            .removeUsersPutByTaskId(this.id)
            .taskUserTypeId(typeId)
            .userIds(userIds)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['tasks_users']);
    }

    public replaceUser(typeId: number, from: User, to: User, callback?: () => void): void {
        const fromIndex = this.tasks_users?.findIndex(tasksUser => {
            return tasksUser.task_user_type_id == typeId && tasksUser.user_id == from.id;
        }) ?? -1;
        if (fromIndex == -1) {
            return;
        }
        this.tasks_users[fromIndex] = TasksUser.Create(typeId, to.id, to);

        Api.tasks()
            .replaceUserPutByTaskId(this.id)
            .taskUserTypeId(typeId)
            .fromUserId(from.id)
            .toUserId(to.id)
            .save(null, callback);
        AppInjector.getInjector().get(EventService).emitTask(this, EventService.Updated, [
            'tasks_users',
        ]);
    }

    public setUserDeadline(user: User, date?: Date, isSoft?: boolean): void {
        const participant = this.findParticipant(user.id);
        if (!participant) {
            return;
        }

        const previousDate = participant.deadline?.getDate()?.getTime();
        const nextDate = date?.getTime();
        if (previousDate === nextDate && participant.deadline?.is_soft === isSoft) {
            return;
        }

        participant.deadline = date ? Deadline.Create(date, isSoft) : undefined;

        Api.tasks()
            .updateUserDeadlinePutByTaskId(this.id)
            .userId(user.id)
            .date(date ? Helpers.serverDate(date) : null)
            .isSoft(isSoft ?? false)
            .save(null, task => {
                this.tasks_users = task.tasks_users;
                this.open = task.open;
                AppInjector.getInjector().get(EventService)
                    .emitTask(this, EventService.Updated, ['tasks_users', 'open',]);
            });
    }

    public setUserArchived(user: User, isArchived: boolean): void {
        const participant = this.findParticipant(user.id);
        if (!participant) {
            return;
        }

        participant.archived = isArchived ? Archived.create() : undefined;

        Api.tasks()
            .updateUserArchivedPutByTaskId(this.id)
            .userId(user.id)
            .isArchived(isArchived)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['tasks_users']);
    }

    // </editor-fold>

    // <editor-fold desc="EditDeadlineList">

    setDeadline(typeId: number, deadline?: Deadline): void {
        // Remove existing deadlines with this typeId
        this.tasks_deadlines = this.tasks_deadlines
            ?.filter(taskDeadline => taskDeadline.task_deadline_type_id !== typeId) ?? [];
        if (deadline) { // No deadline = remove it
            this.tasks_deadlines.push(TasksDeadline.Create(typeId, deadline));
        }
        Api.tasks()
            .updateDeadlinePutByTaskId(this.id)
            .taskDeadlineTypeId(typeId)
            .date(deadline?.getServerDate() ?? null)
            .isSoft(deadline?.is_soft ?? false)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['tasks_deadlines']);
    }

    // </editor-fold>

    // <editor-fold desc="EditEstimateList">

    getDefaultEstimateTypeId(result: (typeId?: number) => void) {
        AppInjector.getInjector().get(TasksService).getTaskType(this.task_type_id, taskType => {
            result(taskType.default_task_estimate_type_id);
        });
    }

    findEstimateByTypeId(typeId: number): Estimate {
        const estimates = this.task_estimates
            ?.filter(taskEstimate => taskEstimate.task_estimate_type_id == typeId)
            ?.map(taskEstimate => taskEstimate.estimate);
        return estimates?.length > 0 ? estimates[0] : undefined;
    }

    setEstimate(typeId: number, estimate: Estimate): void {
        // Remove existing estimates with this typeId
        this.task_estimates = this.task_estimates
            ?.filter(taskEstimate => taskEstimate.task_estimate_type_id !== typeId) ?? [];
        if (estimate) { // No estimate = remove it
            this.task_estimates.push(TaskEstimate.Create(typeId, estimate));
        }
        Api.tasks()
            .updateEstimatePutByTaskId(this.id)
            .taskEstimateTypeId(typeId)
            .value(estimate?.value ?? null)
            .unitId(estimate?.estimate_unit_id ?? 0)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['task_estimates']);
    }

    // </editor-fold>

    // <editor-fold desc="EditText">

    getText(prop: string): string {
        return (this as any)[prop] ?? '';
    }

    setText(prop: string, value: string): void {
        switch (prop) {
            case 'delivery_description':
                this.setDeliveryDescription(value);
                break;
            case 'success_criteria':
                this.setSuccessCriteria(value);
                break;
            case 'obs':
                this.setObs(value);
                break;
        }
    }

    public setDeliveryDescription(value: string): void {
        this.delivery_description = value;
        Api.tasks()
            .updateDeliveryDescriptionPutByTaskId(this.id)
            .save({value: value});
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['delivery_description']);
    }

    public setSuccessCriteria(value: string): void {
        this.success_criteria = value;
        Api.tasks()
            .updateSuccessCriteriaPutByTaskId(this.id)
            .save({value: value});
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['success_criteria']);
    }

    public setObs(value: string): void {
        this.obs = value;
        Api.tasks()
            .updateObsPutByTaskId(this.id)
            .save({value: value});
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['obs']);
    }

    // </editor-fold>

    // <editor-fold desc="EditNumber">

    getNumber(prop: string): number | undefined {
        return (this as any)[prop] ?? undefined;
    }

    setNumber(prop: string, value: number | undefined): void {
        switch (prop) {
            case 'cases_planned':
                this.setCasesPlanned(value);
                break;
            case 'cases_reached':
                this.setCasesReached(value);
                break;
        }
    }

    public setCasesPlanned(value: number | undefined): void {
        this.cases_planned = value;
        Api.tasks()
            .updateCasesPlannedPutByTaskId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['cases_planned']);
    }

    public setCasesReached(value: number | undefined): void {
        this.cases_reached = value;
        Api.tasks()
            .updateCasesReachedPutByTaskId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['cases_reached']);
    }

    // </editor-fold>

    // <editor-fold desc="EditLink">

    getLink(): string {
        return this.purpose ?? '';
    }

    setLink(value: string): void {
        this.purpose = value;
        Api.tasks()
            .updatePurposePutByTaskId(this.id)
            .save({value: value});
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['purpose']);
    }

    // </editor-fold>

    // <editor-fold desc="EditStatus">

    getStatus(): number {
        return this.main_status?.status_id ?? StatusTypes.GREEN;
    }

    setStatus(value: number): void {
        this.main_status = TaskStatus.Create(TaskStatusTypes.Normal, value);
        this.use_status_rules = false;

        Api.tasks()
            .updateStatusPutByTaskId(this.id)
            .taskStatusTypeId(ProjectStatusTypes.Normal)
            .resetUseStatusRules(true)
            .status(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['main_status', 'use_status_rules']);
    }

    // </editor-fold>

    // <editor-fold desc="EditStatusList">

    findStatusByTypeId(typeId: number): number | undefined {
        const status = this.task_statuses
            ?.find(taskStatus => taskStatus.task_status_type_id == typeId);
        return status?.status_id ?? undefined;
    }

    setStatusByType(typeId: number, status: number): void {
        // Remove existing status with this typeId
        this.task_statuses = this.task_statuses
            ?.filter(taskStatus => taskStatus.task_status_type_id !== typeId) ?? [];
        if (status) { // No status = remove it
            const taskStatus = TaskStatus.Create(typeId, status);
            this.task_statuses.push(taskStatus);
            if (typeId == TaskStatusTypes.Normal) {
                this.main_status = taskStatus;
                this.use_status_rules = false;
            }
        }
        Api.tasks()
            .updateStatusPutByTaskId(this.id)
            .taskStatusTypeId(typeId)
            .status(status)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['task_statuses']);
    }

    // </editor-fold>

    // <editor-fold desc="EditUseStatusRules">

    isUseStatusRules(): boolean {
        return this.use_status_rules ?? false;
    }

    setUseStatusRules(value: boolean): void {
        this.use_status_rules = value;
        Api.tasks()
            .updateUseStatusRulesPutByTaskId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['use_status_rules']);
    }

    // </editor-fold>

    // <editor-fold desc="EditCategoryPickerList">

    getCategories(): Category[] {
        return this.categories ?? [];
    }

    findCategoriesByTypeId(typeId: number): Category[] {
        return this.categories
            ?.filter(category => category.category_type_id == typeId) ?? [];
    }

    addCategory(value: Category): void {
        if (!this.categories) {
            this.categories = [];
        }
        if (this.categories.some(category => category.id == value.id)) {
            return;
        }
        this.categories.push(value);

        Api.tasks()
            .addCategoryPutByTaskId(this.id)
            .value(value.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['categories']);
    }

    setCategoriesForType(typeId: number, categories: Category[]): void {
        if (!this.categories) {
            this.categories = [];
        }

        // Remove existing categories with this type
        this.categories = this.categories.filter(category => category.category_type_id !== typeId);

        // Add given categories
        this.categories.push(...categories);

        Api.tasks()
            .setCategoriesForTypePutByTaskId(this.id)
            .categoryTypeId(typeId)
            .categoryIds(categories.map(category => category.id))
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['categories']);
    }

    replaceCategories(removedCategories: Category[], addedCategories: Category[]): void {
        const categoryIdsToRemove = removedCategories.map(category => category.id);
        const categoryIdsToAdd = addedCategories.map(category => category.id);

        if (!this.categories) {
            this.categories = [];
        }

        // Remove
        this.categories = this.categories.filter(category => !categoryIdsToRemove.includes(category.id));

        // Add
        this.categories.push(...addedCategories);

        Api.tasks()
            .replaceCategoriesPutByTaskId(this.id)
            .removedCategoryIds(categoryIdsToRemove)
            .addedCategoryIds(categoryIdsToAdd)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['categories']);
    }

    removeCategory(category: Category): void {
        const index = this.categories.indexOf(category);
        if (index !== -1) {
            this.categories.splice(index, 1);
        }

        Api.tasks()
            .removeCategoryPutByTaskId(this.id)
            .value(category.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['categories']);
    }

    // </editor-fold>

    // <editor-fold desc="EditDepartmentPicker">

    public addDepartment(value: Department): void {
        if (!this.departments) {
            this.departments = [];
        }
        if (this.departments.some(department => department.id == value.id)) {
            return;
        }
        this.departments.push(value);

        Api.tasks()
            .addDepartmentPutByTaskId(this.id)
            .value(value.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['departments']);
    }

    public removeDepartment(value: Department): void {
        const index = this.departments.indexOf(value);
        if (index !== -1) {
            this.departments.splice(index, 1);
        }

        Api.tasks()
            .removeDepartmentPutByTaskId(this.id)
            .value(value.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['departments']);
    }

    // </editor-fold>

    // <editor-fold desc="EditTagList">

    public addTag(value: Tag): void {
        if (!this.tags) {
            this.tags = [];
        }
        if (this.tags.some(tag => tag.id == value.id)) {
            return;
        }
        this.tags.push(value);

        Api.tasks()
            .addTagPutByTaskId(this.id)
            .value(value.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['tags']);
    }

    public removeTag(value: Tag): void {
        const index = this.tags.indexOf(value);
        if (index !== -1) {
            this.tags.splice(index, 1);
        }

        Api.tasks()
            .removeTagPutByTaskId(this.id)
            .value(value.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['tags']);
    }

    // </editor-fold>

    // <editor-fold desc="EditAction">

    isArchived(): boolean {
        return (this.archived_id ?? 0) > 0 || this.archived !== undefined;
    }

    setArchived(value: boolean): void {
        if (value) {
            this.archived = Archived.create();
            this.checkArchivedDialog();
        } else {
            this.archived = undefined;
            this.archived_id = 0;
        }
        Api.tasks()
            .updateArchivedPutByTaskId(this.id)
            .value(value)
            .save(null, task => {
                this.archived = task.archived;
                this.archived_id = task.archived_id;

                AppInjector.getInjector().get(EventService)
                    .emitTask(this, EventService.Updated, ['archived', 'archived_id']);
            });
    }

    setDeleted(): void {
        const translateService = AppInjector.getInjector().get(TranslateService);
        const modalRef = AppInjector.getInjector().get(NgbModal).open(
            ConfirmationDialogComponent,
            {
                size: 'sm',
                windowClass: 'modal-holder',
                centered: true,
                backdrop: 'static',
                keyboard: true,
            });
        modalRef.componentInstance.showTextInput = false;
        modalRef.componentInstance.title = translateService.instant('_global_delete');
        modalRef.componentInstance.message = translateService.instant(
            '_ui_delete_item',
            {name: this.title ?? translateService.instant('_ui_no_title')}
        );
        modalRef.componentInstance.btnOkText = translateService.instant('_global_ok');
        modalRef.componentInstance.btnCancelText = translateService.instant('_global_cancel');
        modalRef.result.then(confirmed => {
            if (confirmed) {
                this.delete();
            }
        }).catch(reason => {
        });
    }

    // </editor-fold>

    // <editor-fold desc="EditMilestoneList">

    getMilestones(filter: EditMilestoneListFilter): Milestone[] {
        return this.milestones?.filter(milestone => milestone.projects?.find(project => project.id == filter.project.id) !== undefined) ?? [];
    }

    prepareNewMilestone(filter: EditMilestoneListFilter): CreatePreset[] {
        const items = [
            // Defaults
            new MilestoneStatusPresetGenerator(StatusTypes.GREEN),
            new MilestoneUseStatusRulesPresetGenerator(true),

            new MilestoneTaskPresetGenerator(this.id),
        ];
        if (filter.project) {
            items.push(new MilestoneProjectPresetGenerator(filter.project.id));
        }
        return items.map(item => item.generate());
    }

    addMilestone(milestone: Milestone): void {
        this.milestones.push(milestone);
        Api.tasks()
            .addMilestonePutByTaskId(this.id)
            .value(milestone.id)
            .save(null);
        AppInjector.getInjector().get(EventService).emitTask(this, EventService.Updated, [
            'milestones',
        ]);
    }

    removeMilestone(milestone: Milestone): void {
        this.milestones.splice(this.milestones.indexOf(milestone), 1);
        const milestonesTaskIndex = this.milestones_tasks?.findIndex(milestonesTask => milestonesTask.milestone_id == milestone.id) ?? -1;
        if (milestonesTaskIndex !== -1) {
            this.milestones_tasks.splice(milestonesTaskIndex, 1);
        }
        Api.tasks()
            .removeMilestonePutByTaskId(this.id)
            .value(milestone.id)
            .save(null);
        AppInjector.getInjector().get(EventService).emitTask(this, EventService.Updated, [
            'milestones', 'milestones_tasks',
        ]);
    }

    replaceMilestone(from: Milestone, to: Milestone): void {
        const fromIndex = this.milestones?.findIndex(milestone => milestone.id == from.id) ?? -1;
        if (fromIndex == -1) {
            return;
        }
        this.milestones[fromIndex] = to;

        Api.tasks()
            .replaceMilestonePutByTaskId(this.id)
            .fromMilestoneId(from.id)
            .toMilestoneId(to.id)
            .save(null);
        AppInjector.getInjector().get(EventService).emitTask(this, EventService.Updated, [
            'milestones',
        ]);
    }

    // </editor-fold>

    // <editor-fold desc="EditCanCopy">

    isCanCopy(): boolean {
        return this.can_copy ?? false;
    }

    setCanCopy(value: boolean): void {
        this.can_copy = value;
        Api.tasks()
            .updateCanCopyPutByTaskId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['can_copy']);
    }

    // </editor-fold>

    // <editor-fold desc="EditIsPrivate">

    isIsPrivate(): boolean {
        return this.is_private ?? false;
    }

    setIsPrivate(value: boolean): void {
        this.is_private = value;
        Api.tasks()
            .updateIsPrivatePutByTaskId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['is_private']);
    }

    // </editor-fold>

    // <editor-fold desc="EditProjects">

    addProject(value: Project): void {
        this.projects.push(value);
        Api.tasks()
            .addProjectPutByTaskId(this.id)
            .value(value.id)
            .save(null);
        AppInjector.getInjector().get(EventService).emitTask(this, EventService.Updated, [
            'projects',
        ]);
    }

    removeProject(value: Project): void {
        this.projects.splice(this.projects.indexOf(value), 1);
        const projectsTaskIndex = this.projects_tasks?.findIndex(projectsTask => projectsTask.project_id == value.id) ?? -1;
        if (projectsTaskIndex !== -1) {
            this.projects_tasks.splice(projectsTaskIndex, 1);
        }
        Api.tasks()
            .removeProjectPutByTaskId(this.id)
            .value(value.id)
            .save(null);
        AppInjector.getInjector().get(EventService).emitTask(this, EventService.Updated, [
            'projects', 'projects_tasks',
        ]);
    }

    setProject(typeId: number, value: Project): void {
        // Remove projects of this type
        this.projects = this.projects?.filter(project => project.project_type_id != typeId) ?? [];

        // Add project if not already added
        if (!this.projects.some(project => project.id == value.id)) {
            this.projects.push(value);
        }

        Api.tasks()
            .setProjectForTypePutByTaskId(this.id)
            .projectTypeId(typeId)
            .projectId(value.id)
            .save(null);

        AppInjector.getInjector().get(EventService).emitTask(this, EventService.Updated, [
            'projects',
        ]);
    }

    replaceProject(from: Project, to: Project): void {
        const fromIndex = this.projects?.findIndex(project => project.id == from.id) ?? -1;
        if (fromIndex == -1) {
            return;
        }
        this.projects[fromIndex] = to;

        Api.tasks()
            .replaceProjectPutByTaskId(this.id)
            .fromProjectId(from.id)
            .toProjectId(to.id)
            .save(null);
        AppInjector.getInjector().get(EventService).emitTask(this, EventService.Updated, [
            'projects',
        ]);
    }

    // </editor-fold>

    // <editor-fold desc="EditType">

    setType(value: number): void {
        this.task_type_id = value;
        Api.tasks()
            .updateTypePutByTaskId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitTask(this, EventService.Updated, ['task_type_id']);
    }

    // </editor-fold>

    // <editor-fold desc="Reaction interface">

    public addReaction(reaction: Reaction): void {
        Api.tasks().reactionAddPutByTaskId(this.id)
            .reaction_type_id(reaction.reaction_type_id)
            .value(reaction.value)
            .user_id(reaction.user_id)
            .save(null);
    }

    public removeReaction(reaction: Reaction): void {
        Api.tasks().reactionRemoveDeleteByTaskId(this.id)
            .reaction_type_id(reaction.reaction_type_id)
            .value(reaction.value)
            .user_id(reaction.user_id)
            .delete(null);
    }

    public getReactionChangedEventName(): string {
        return Events.TaskReactionsChanged(this.id);
    }

    // </editor-fold>

}

export class CaseUser extends Task {

    constructor(json?: any) {
        super(json);
    }

}

export class Roster extends Task {

    constructor(json?: any) {
        super(json);
    }

}
