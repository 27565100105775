<div *ngIf="milestonePlan" style="min-height: 30vh;max-height: calc(100vh - 100px);"
     [class.tabs-container]="!(milestonePlan && !milestonePlan.isEditableForUser())"
     class="outline">

<!--    <pre>{{milestonePlan.id}}</pre>-->

    <ng-container *ngIf="milestonePlan && milestonePlan.id == 0 && false">

        <div class="d-flex align-items-center w-100">
            <div class="form-group flex-grow-1 align-items-center -d-flex">
                <label [for]="'title'+milestonePlan.id">
                    <span >{{'_milestone_plan_name' | translate}}</span>

                    <pre>{{milestonePlan.id}}</pre>
                </label>
                <input
                    [(ngModel)]="milestonePlan.title"
                    placeholder=""
                    class="form-control"
                    name="title"
                    [id]="'title'+milestonePlan.id"
                    autocomplete="on"
                    [autofocusAfterInit]="!milestonePlan.title"
                    (blur)="createMilestonePlan()"
                    [class.is-invalid]="!milestonePlan.title"
                >


            </div>
            <div class="fal fa-arrow-circle-right cursor-pointer ms-2 mt-3"></div>
        </div>
    </ng-container>

    <ng-container *ngIf="milestonePlan.isEditableForUser()">
        <div class="d-flex flex-column" >
            <ul ngbNav
                class="nav-tabs categories tabs-editor"
                #nav="ngbNav"
                (navChange)="tabChange($event)"
                [(activeId)]="selectedTab"
                [destroyOnHide]="false">

                <!-- Base Information -->
                <li [ngbNavItem]="Globals.Tabs.Basic"
                    [class.active]="selectedTab == Globals.Tabs.Basic"
                >
                    <a ngbNavLink><span >{{'_ui_milestoneplan_tab_basic' | translate}}</span></a>
                    <ng-template ngbNavContent>
                        <div class="container p-2 list-scroll scroll-y bg-light">
                            <!-- name -->
                            <div class="form-group">
                                <label [for]="'_ui_milestoneplan_title'+milestonePlan.id">
                                    <span >{{'_milestone_plan_name' | translate}}</span>
                                </label>
                                <input
                                    [(ngModel)]="milestonePlan.title"
                                    placeholder=""
                                    class="form-control"
                                    name="title"
                                    [id]="'title'+milestonePlan.id"
                                    autocomplete="on"
                                    [autofocusAfterInit]="!milestonePlan.title"
                                    (blur)="saveMilestonePlanTitle()"
                                >
                            </div>

                            <div class="details" [class.disabled]="!milestonePlan.exists()">
                            <!-- Ejerskab -->
                            <div class="form-group"
                                 *ngIf="currentUser && (currentUser?.administrator || currentUser?.uber || currentUser?.sysadmin)">

                                <label class="d-flex align-items-center justify-content-start" *ngIf="false">

                                <span class="me-1">
                                <i *ngIf="isGlobal"
                                   class="fal fa-globe-stand" style="opacity: .5;"
                                   [ngbTooltip]="('_ui_milestone_plan_selector_global' | translate)"
                                   [container]="'body'"
                                ></i>
                                <i *ngIf="isTeam"
                                   class="fal fa-users" style="opacity: .5;"
                                   [ngbTooltip]="('_ui_milestone_plan_selector_team' | translate)"
                                   [container]="'body'"
                                ></i>
                                <i *ngIf="isPersonal"
                                   class="fal fa-user" style="opacity: .5;"
                                   [ngbTooltip]="('_ui_milestone_plan_selector_personal' | translate)"
                                   [container]="'body'"
                                ></i>
                                </span>

                                    <span >{{'_ui_template_owner' | translate}}</span>

                                </label>

                                <label>{{'_ui_select_milestoneplan_ownership' | translate}}</label>

                                <div
                                    class="d-flex justify-content-between align-items-start flex-row ownership-wrapper">

                                    <div class="d-flex flex-column flex-grow-1 me-1 border rounded p-2">
                                        <label>
                                            <i class="fal fa-globe me-1"></i>
                                            <span >{{'_ui_milestone_plan_selector_global' | translate}}</span>
                                        </label>
                                        <div (click)="toggleMilestonePlanDepartment();"
                                             class="list-group-item rounded d-flex align-items-center w-100 mb-1  cursor-pointer">
                                            <i class="fa fa-check-circle text-success"
                                               *ngIf="(!(milestonePlan.departments?.length ?? 0) && (milestonePlan.user_id == 0 || !milestonePlan.user)) && isGlobal"></i>
                                            <i class="fal fa-circle text-black-50"
                                               *ngIf="(milestonePlan.departments?.length || milestonePlan.user_id != 0 || milestonePlan.user) && !isGlobal"
                                            ></i>
                                            <span
                                                class="ms-1">{{'_ui_milestone_plan_selector_global' | translate}}</span>
                                        </div>
                                    </div>

                                    <!-- Afdeling -->
                                    <div class="d-flex flex-column  flex-grow-1 me-1 border rounded p-2">
                                        <label>
                                            <i class="fal fa-users me-1"></i>
                                            <span >{{'_admin_milestone_plan_department' | translate}}</span>
                                        </label>

                                        <div class="d-flex flex-column align-items-start">
                                            <app-department-picker
                                                [selectedDepartments]="milestonePlan.departments ?? []"
                                                (onChangeEvent)="departmentChange($event)"
                                                [showDepartmentGroups]="true"
                                                [selectFirstDepartment]="true"
                                                [showAll]="true"
                                                [multiple]="true"
                                                class="w-100"
                                            ></app-department-picker>
                                        </div>
                                    </div>

                                    <!-- Brugere -->
                                    <div class="d-flex flex-column flex-grow-1  border rounded p-2">
                                        <label>
                                            <i class="fal fa-user me-1"></i>
                                            <span >{{'_admin_milestone_plan_user_description' | translate}}</span>
                                        </label>
                                        <div class="d-flex flex-column align-items-start">
                                            <app-user-search
                                                [selectedUsers]="milestonePlan.user ? [milestonePlan.user] : null"
                                                (itemSelected)="saveMilestonePlanUser($event)"
                                                (itemRemoved)="removeMilestonePlanUser($event)"
                                                [showDeleteButton]="true"
                                                [multiple]="false"
                                                [clearInputOnBlur]="false"
                                                [toggle]="true"
                                                [autoReset]="false"
                                                [removeOnBlur]="false"
                                                class="flex-fill align-self-start w-100 flex-grow-1"
                                            ></app-user-search>
                                        </div>
                                    </div>


                                </div>


                            </div>

                            <div class="d-flex justify-content-between align-items-start flex-row option-wrapper flex-wrap">
                                <!-- projekt type -->
                                <div class="form-group"
                                     *ngIf="projectTypes"
                                >
                                    <label>
                                        <span>{{'_ui_milestoneplan_select_project_type' | translate}}</span>
                                    </label>


                                    <select name="project_type_id" class="form-control"
                                            *ngIf="projectTypes"
                                            [(ngModel)]="milestonePlan.project_type_id"
                                            (ngModelChange)="saveMilestonePlanProjectType()"
                                    >
                                        <option [value]="0" disabled  >{{'_ui_not_selected' | translate}}</option>
                                        <option *ngFor="let projectType of projectTypes"
                                                [value]="projectType.id"
                                        >{{projectType.name ? projectType.name : ('_project_type_' + projectType?.id | translate)}}</option>
                                    </select>
                                </div>

                                <!-- projekt deadlinetype start -->
                                <div class="form-group"
                                     *ngIf="projectDeadlineTypes && milestonePlan.project_type_id"
                                >
                                    <label>
                                        <span>{{'_ui_milestoneplan_select_task_deadline_start' | translate}}</span>
                                    </label>

                                    <select name="start_project_deadline_type_id" class="form-control"
                                            [(ngModel)]="milestonePlan.start_project_deadline_type_id"
                                            (ngModelChange)="saveMilestonePlanProjectDeadlineTypeStart()"
                                    >
                                        <option [value]="0" disabled *ngIf="!milestonePlan.start_project_deadline_type_id || milestonePlan.start_project_deadline_type_id == 0">{{'_ui_not_selected' | translate}}</option>
                                        <option *ngFor="let projectDeadlineType of projectDeadlineTypes"
                                                [value]="projectDeadlineType.id"
                                                [disabled]="itemEnabled(projectDeadlineType)"
                                        >
                                            {{projectDeadlineType.language_key && projectDeadlineType.language_key != '' ? (projectDeadlineType.language_key | translate) : projectDeadlineType.name}}
                                        </option>
                                    </select>
                                </div>

                                <!-- projekt deadlinetype slut -->
                                <div class="form-group"
                                     *ngIf="projectDeadlineTypes && milestonePlan.project_type_id"
                                >
                                    <label>
                                        <span>{{'_ui_milestoneplan_select_task_deadline_end' | translate}}</span>
                                    </label>

                                    <select name="end_project_deadline_type_id" class="form-control"
                                            [(ngModel)]="milestonePlan.end_project_deadline_type_id"
                                            (ngModelChange)="saveMilestonePlanProjectDeadlineTypeEnd()"

                                    >
                                        <option [value]="0" disabled *ngIf="!milestonePlan.end_project_deadline_type_id || milestonePlan.end_project_deadline_type_id == 0">{{'_ui_not_selected' | translate}}</option>
                                        <option *ngFor="let projectDeadlineType of projectDeadlineTypes"
                                                [value]="projectDeadlineType.id"
                                                [disabled]="itemEnabled(projectDeadlineType)"
                                        >
                                            {{projectDeadlineType.language_key && projectDeadlineType.language_key != '' ? (projectDeadlineType.language_key | translate) : projectDeadlineType.name}}
                                        </option>
                                    </select>
                                </div>

                                <!-- opgave estimattype -->
                                <div class="form-group"
                                     *ngIf="taskEstimateTypes"
                                >
                                    <label>
                                        <span>{{'_ui_milestoneplan_select_task_estimate_type' | translate}}</span>
                                    </label>

                                    <select name="task_estimate_type_id" class="form-control"
                                            [(ngModel)]="milestonePlan.task_estimate_type_id"
                                            (ngModelChange)="saveMilestonePlanTaskEstimateType()"
                                    >
                                        <option [value]="0" disabled *ngIf="!milestonePlan.task_estimate_type_id || milestonePlan.task_estimate_type_id == 0">{{'_ui_not_selected' | translate}}</option>
                                        <option *ngFor="let taskEstimateType of taskEstimateTypes"
                                                [value]="taskEstimateType.id"
                                        >{{taskEstimateType.name}}</option>
                                    </select>
                                </div>

                                <!-- opgavetype -->
                                <div class="form-group"
                                     *ngIf="taskTypes"
                                >
                                    <label>
                                        <span>{{'_ui_milestoneplan_select_task_type' | translate}}</span>
                                    </label>

                                    <select name="default_task_type_id" class="form-control"
                                            [(ngModel)]="milestonePlan.default_task_type_id"
                                            (ngModelChange)="saveMilestonePlanDefaultTaskType()"
                                    >
                                        <option [value]="0" disabled  *ngIf="milestonePlan.default_task_type_id === 0">{{'_ui_not_selected' | translate}}</option>
                                        <option [value]="null" disabled  *ngIf="milestonePlan.default_task_type_id === null">{{'_ui_not_selected' | translate}}</option>
                                        <option [value]="''" disabled  *ngIf="$any(milestonePlan.default_task_type_id) == ''">{{'_ui_not_selected' | translate}}</option>
                                        <option [value]="undefined" disabled  *ngIf="milestonePlan.default_task_type_id === undefined">{{'_ui_not_selected' | translate}}</option>
                                        <option *ngFor="let taskType of taskTypes"
                                                [value]="taskType.id"
                                        >{{taskType.name}}</option>
                                    </select>
                                </div>

                                <div class="w-100" *ngIf="selectedProjectType">
                                    <div (click)="toggleUseResponsibleAsParticipant()"
                                         class="list-group-item rounded d-flex align-items-start w-100 mb-1 cursor-pointer mt-1">
                                        <div class="mt-0">
                                            <i class="fa fa-check-circle text-success"
                                               *ngIf="milestonePlan.use_project_responsible"></i>
                                            <i class="fal fa-circle text-black-50"
                                               *ngIf="!milestonePlan.use_project_responsible"></i>
                                        </div>

                                        <span
                                            class="ms-1"
                                            [innerHTML]="('_ui_use_project_responsible_as_participant' | translate:{type: (selectedProjectType.name)})"></span>
                                    </div>
                                </div>

                                <!-- Project notes -->
                                <div class="form-group">
                                    <app-rich-text-editor
                                        [label]="('_milestone_plan_project_notes' | translate)"
                                        [(ngModel)]="milestonePlan.project_notes"
                                        (changeEvent)="saveMilestonePlanProjectNotes()"
                                    ></app-rich-text-editor>
                                </div>

                            </div>
                            </div>
                        </div>

                    </ng-template>
                </li>

                <!-- Datoer der ikke på anvendes -->
                <li [ngbNavItem]="Globals.Tabs.When"
                    [class.disabled]="!milestonePlan.exists()"
                    [class.active]="selectedTab == Globals.Tabs.When"
                >
                    <a ngbNavLink><span >{{'_ui_milestoneplan_tab_periods' | translate}}</span></a>
                    <ng-template ngbNavContent>
                        <div class="container p-2 list-scroll scroll-y bg-light">

                            <!-- Lukkedage, helligdage -->
                            <app-milestone-plan-period-selection
                                [milestonePlan]="milestonePlan" *ngIf="milestonePlan"
                            ></app-milestone-plan-period-selection>

                        </div>
                    </ng-template>
                </li>

                <!-- Opgaver og milepæle -->
                <li [ngbNavItem]="Globals.Tabs.Contents"
                    [class.active]="selectedTab == Globals.Tabs.Contents"
                    [class.disabled]="!milestonePlan.exists()"
                >
                    <a ngbNavLink><span >{{'_ui_milestoneplan_tab_contents' | translate}}</span></a>
                    <ng-template ngbNavContent>

                        <div class="p-2">

                            <app-milestone-plan-timeline
                                #timelineComponent
                                [milestonePlan]="milestonePlan"
                                [defaultDays]="defaultDays"
                            ></app-milestone-plan-timeline>
                        </div>
                        <div class="container p-2 d-flex flex-row align-items-start">
                            <div
                                class="d-flex align-items-start justify-content-start flex-row me-2 flex-column pe-2 flex-shrink-1 h-100"
                                style="width: 210px;">
                                <ul class="m-0 list-unstyled d-flex align-items-start flex-column list-scroll scroll-y bg-light w-100">

                                    <li class=" border-bottom pb-2 mb-2">
                                        <div class="form-group mb-0">

                                            <label for="default-days"
                                                   [ngbPopover]="popoverDescription"
                                                   triggers="mouseenter:mouseleave"
                                                   container="body"
                                                   class="popover-title"

                                            >
                                                {{'_ui_project_duration' | translate}}
                                            </label>

                                            <ng-template #popoverDescription>
                                                <div class="p-2 callout" style="max-width: 300px;">
                                                    <p>{{'_ui_duration_description' | translate}}</p>
                                                </div>
                                            </ng-template>

                                            <input
                                                [(ngModel)]="defaultDays"
                                                (change)="changeDefaultDays($event)"
                                                placeholder=""
                                                class="form-control"
                                                id="default-days"
                                                name="defaultdays"
                                                type="number"
                                                [max]="730"
                                                [id]="'default-days'+milestonePlan.id"
                                            >
                                        </div>
                                    </li>

                                    <li (click)="setSubView(Constants.MilestoneRowViews.Basic)"
                                        class="cursor-pointer d-block me-1">
                                <span class="fal me-1"

                                      [class.fa-arrow-circle-right]="subView == Constants.MilestoneRowViews.Basic"
                                      [class.fa-circle]="subView != Constants.MilestoneRowViews.Basic"
                                ></span>
                                        {{'_milestones' | translate}}
                                    </li>
                                    <li (click)="milestonePlan.milestone_templates && milestonePlan.milestone_templates.length > 0 ? setSubView(Constants.MilestoneRowViews.Tasks) : null"
                                        [class.disabled]="!milestonePlan.milestone_templates || milestonePlan.milestone_templates.length == 0"
                                        class="cursor-pointer d-block me-1 ps-4">
                                <span class="fal me-1"
                                      [class.fa-arrow-circle-right]="subView == Constants.MilestoneRowViews.Tasks"
                                      [class.fa-circle]="subView != Constants.MilestoneRowViews.Tasks"></span>
                                        {{'_tasks' | translate}}
                                    </li>
                                    <li
                                        (click)="milestonePlan.milestone_templates && milestonePlan.milestone_templates.length > 0 ? setSubView(Constants.MilestoneRowViews.StatusRules) : null"
                                        [class.disabled]="!milestonePlan.milestone_templates || milestonePlan.milestone_templates.length == 0"
                                        class="cursor-pointer d-block me-1 ps-4">
                                <span class="fal me-1"

                                      [class.fa-arrow-circle-right]="subView == Constants.MilestoneRowViews.StatusRules"
                                      [class.fa-circle]="subView != Constants.MilestoneRowViews.StatusRules"></span>
                                        {{'_status_rules' | translate}}

                                    </li>

                                    <li
                                        (click)="setSubView(Constants.MilestoneRowViews.Overview)"
                                        class="cursor-pointer d-block me-1">
                                <span class="fal me-1"

                                      [class.fa-arrow-circle-right]="subView == Constants.MilestoneRowViews.Overview"
                                      [class.fa-circle]="subView != Constants.MilestoneRowViews.Overview"></span>
                                        {{'_ui_adjust_order' | translate}}

                                    </li>

                                </ul>
                            </div>

                            <div class="d-flex flex-column flex-grow-1 ps-2 list-scroll scroll-y bg-light">

                                <ul
                                    cdkDropList
                                    (cdkDropListDropped)="milestoneTemplateDrop($event)"
                                    class="example-list rounded m-0 p-0 -border w-100 -mt-1"
                                    cdkDropListGroup
                                >

                                    <li
                                        *ngIf="subView == Constants.MilestoneRowViews.StatusRules"
                                        class="d-flex align-items-center list-group-item cursor-default justify-content-between  align-items-center p-2">
                                        <a
                                            class="ms-auto"

                                            style="max-width: unset;"
                                            (click)="createStatusRuleAll()">

                                            <span>{{'_admin_milestone_plan_create_status_rule_for_all' | translate}}</span>
                                            <span class="fal fa-plus-circle ms-1"></span>
                                        </a>
                                    </li>

                                    <!-- opret milepæl (top) -->
                                    <li
                                        *ngIf="subView == Constants.MilestoneRowViews.Basic"
                                        class="d-flex align-items-center list-group-item cursor-default justify-content-between  align-items-center p-2"
                                    >
                                        <div class="ms-auto d-flex">
                                            <a
                                                class="me-1"
                                                *ngIf="subView == Constants.MilestoneRowViews.Basic && milestonePlan?.milestone_templates?.length > 0"
                                                (click)="allExpanded = !allExpanded"
                                            >
                                                <span>{{'_ui_add_deadline_rules' | translate}}</span>
                                                <i class="fal cursor-pointer ms-1"
                                                   [class.fa-circle-arrow-up]="allExpanded"
                                                   [class.fa-circle-arrow-down]="!allExpanded"></i>
                                            </a>

                                            <a
                                                *ngIf="subView == Constants.MilestoneRowViews.Basic"
                                                (click)="createMilestoneRow()"
                                                [ngbTooltip]="('_milestone' | translate)"
                                            >
                                                <span>{{'_global_create' | translate}}</span>
                                                <span class="fal fa-plus-circle ms-1"></span>
                                            </a>
                                        </div>
                                    </li>

                                    <li
                                        class="d-flex align-items-center list-group-item cursor-default   align-items-center p-2"
                                        *ngIf="subView == Constants.MilestoneRowViews.Overview"
                                    >
                                        <span class="fal fa-pencil-ruler me-1"></span>
                                        <span>{{milestonePlan.title}}</span>
                                    </li>

                                    <li *ngFor="let milestoneTemplate of milestonePlan?.milestone_templates"
                                        cdkDrag
                                        [cdkDragDisabled]="subView != Constants.MilestoneRowViews.Overview"
                                        class="d-flex align-items-center list-group-item cursor-default justify-content-between  align-items-center p-0 zebra">
                                        <app-template-milestone-row #milestoneRow
                                                                    [milestoneTemplate]="milestoneTemplate"
                                                                    [milestonePlan]="milestonePlan"
                                                                    [projectTypeId]="milestonePlan.project_type_id"
                                                                    [expanded]="allExpanded"
                                                                    [view]="subView"
                                                                    (changed)="updateTimeline($event, milestoneTemplate)"
                                                                    class="d-flex w-100 p-0"></app-template-milestone-row>

                                        <div
                                            *ngIf="subView == Constants.MilestoneRowViews.Basic || subView == Constants.MilestoneRowViews.Overview"
                                            class="ms-auto ps-2 pe-2 -border-start align-self-stretch pt-2 d-flex flex-column align-items-center"
                                        >

                                        <span class=" fal fa-bars curser-drag mt-1"
                                              [ngbTooltip]="('_ui_drag_to_change_order' | translate)"
                                              *ngIf="subView == Constants.MilestoneRowViews.Overview"
                                              cdkDragHandle></span>

                                            <span
                                                class=" fal fa-trash-alt curser-pointer text-danger mt-1 cursor-pointer"
                                                [ngbTooltip]="('_global_delete' | translate)"
                                                *ngIf="subView == Constants.MilestoneRowViews.Basic"
                                                (click)="deleteMilestoneTemplate(milestoneTemplate)"
                                            ></span>

                                            <span class="fal mt-2 cursor-pointer" *ngIf="false"
                                                  [class.fa-arrow-circle-right]="!milestoneRow.expanded"
                                                  [class.fa-arrow-circle-down]="milestoneRow.expanded"
                                                  (click)="milestoneRow.expanded = !milestoneRow.expanded">

                                    </span>

                                        </div>
                                    </li>

                                    <!-- opret milepæl (bund) -->
                                    <ng-container *ngIf="milestonePlan?.milestone_templates && milestonePlan?.milestone_templates.length > 0">
                                        <li
                                            *ngIf="subView == Constants.MilestoneRowViews.Basic"
                                            class="d-flex align-items-center list-group-item cursor-default justify-content-between  align-items-center p-2"
                                        >
                                            <a
                                                class="ms-auto"
                                                *ngIf="subView == Constants.MilestoneRowViews.Basic"
                                                (click)="createMilestoneRow()"
                                                [ngbTooltip]="('_milestone' | translate)"
                                            >
                                                <span>{{'_global_create' | translate}}</span>
                                                <span class="fal fa-plus-circle ms-1"></span>
                                            </a>
                                        </li>
                                    </ng-container>

                                </ul>
                            </div>

                        </div>
                    </ng-template>
                </li>

            </ul>
            <div [ngbNavOutlet]="nav" class="categories ngb-tabs-content -scroll-y"></div>
        </div>
    </ng-container>

    <ng-container *ngIf="milestonePlan && !milestonePlan.isEditableForUser()">
        <div class="bg-warning rounded p-2"><span>{{'_ui_templates_rights' | translate}}</span></div>
        <pre class="debug" *ngIf="user?.administrator">{{milestonePlan | json}}</pre>
    </ng-container>

</div>
<div class="d-flex align-items-center justify-content-center" style="min-height: 30vh; max-height: calc(100vh - 100px);"
     *ngIf="!milestonePlan">
    <app-loading-indicator [showLogo]="false"></app-loading-indicator>
</div>
