import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient) {
    }

    public get(url: string, options: any): Observable<Object> {
        return this.http.get(url, options);
    }

    public post(url: string, data: any, options: any): Observable<Object> {
        return this.http.post(url, data, options);
    }

    public put(url: string, data: any, options: any): Observable<Object> {
        return this.http.put(url, data, options);
    }

    public patch(url: string, data: any, options: any): Observable<Object> {
        return this.http.patch(url, data, options);
    }

    public delete(url: string, options: any): Observable<Object> {
        return this.http.delete(url, options);
    }

    public download(url: string, options: any): Observable<Object> {
        return this.http.get(url, options);
    }

    public upload(url: string, data: FormData, options: any): Observable<Object> {
        return this.http.post(url, data, options);
    }

}
