/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:21.
 */
import {ProjectDefinition} from './definitions/ProjectDefinition';
import {Events, ProjectStatusTypes, StatusTypes} from '@app/constants';
import {AppInjector} from '@app/services/app-injector.service';
import {EventService} from '@app/services/event.service';
import {ProjectStatus} from '@app/core/models/ProjectStatus';
import {Archived} from '@app/core/models/Archived';
import {Milestone} from '@app/core/models/Milestone';
import {ProjectEstimate} from '@app/core/models/ProjectEstimate';
import {ProjectsDeadline} from '@app/core/models/ProjectsDeadline';
import {ProjectsUser} from '@app/core/models/ProjectsUser';
import {ProjectType} from '@app/core/models/ProjectType';
import {PhasesProject} from '@app/core/models/PhasesProject';
import moment from 'moment';
import {Api, ProjectsCreateRequestPreset, TasksCreateRequestPreset} from '@app/core/Api';
import {ReactionsApiInterface} from "@app/shared/_ui/reactions/ReactionsApiInterface";
import {Reaction} from "@app/core/models/Reaction";
import {ReactionsSourceInterface} from "@app/shared/_ui/reactions/ReactionsSourceInterface";
import {Deadline} from "@app/core/models/Deadline";
import {HasDeadlines} from "@app/interfaces/HasDeadlines";
import {HasConfigurations} from "@app/interfaces/HasConfigurations";
import {ProjectsService} from "@app/services/projects.service";
import {HasTitle} from "@app/interfaces/HasTitle";
import {HasMainStatus} from "@app/interfaces/HasMainStatus";
import {HasUsers} from "@app/interfaces/HasUsers";
import {User} from "@app/core/models/User";
import {HasHansUps} from "@app/interfaces/HasHansUps";
import {HasStars} from "@app/interfaces/HasStars";
import {EditTitle} from "@app/editor/quick-editor/editors/generic/title-editor/EditTitle";
import {EditDeadlineList} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineList";
import {
    EditMilestonePlanSelect
} from "@app/editor/quick-editor/editors/project/milestone-plan-select-editor/EditMilestonePlanSelect";
import {Category, Department, Estimate, MilestonePlan, StaticDeadline, Tag} from "@app/core/models/index";
import {SnackbarService} from "@app/services/snackbar.service";
import {TranslateService} from "@ngx-translate/core";
import {HasTypeProperties} from "@app/interfaces/HasTypeProperties";
import {EditEstimateList} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EditEstimateList";
import {EditText} from "@app/editor/quick-editor/editors/generic/text-editor/EditText";
import {EditLink} from "@app/editor/quick-editor/editors/generic/link-editor/EditLink";
import {HasStatuses} from "@app/interfaces/HasStatuses";
import {EditStatusList} from "@app/editor/quick-editor/editors/generic/status-list-editor/EditStatusList";
import {HasDepartments} from "@app/interfaces/HasDepartments";
import {EditPhasesProject} from "@app/editor/quick-editor/editors/project/phases-project-editor/EditPhasesProject";
import {EditUseStatusRules} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/EditUseStatusRules";
import {
    EditCategoryPickerList
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerList";
import {EditUserList} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserList";
import {
    EditDepartmentPicker
} from "@app/editor/quick-editor/editors/generic/department-picker-editor/EditDepartmentPicker";
import {EditTagList} from "@app/editor/quick-editor/editors/generic/tag-list-editor/EditTagList";
import {EditArchived} from "@app/editor/quick-editor/editors/generic/archived-editor/EditArchived";
import {EditReactionList} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionList";
import Helpers from "@app/core/helpers";
import {EditStatus} from "@app/editor/quick-editor/editors/generic/status-editor/EditStatus";

export const ProjectUserTypes = {
    Responsible: 1,
    Participants: 2
};

export class Project extends ProjectDefinition implements ReactionsSourceInterface,

    HasDeadlines,
    HasConfigurations,
    HasTitle,
    HasMainStatus,
    HasHansUps,
    HasStars,
    HasUsers,
    HasTypeProperties,
    HasStatuses,
    HasDepartments,

    EditTitle,
    EditArchived,
    EditReactionList,
    EditText,
    EditLink,
    EditDeadlineList,
    EditEstimateList,
    EditStatusList,
    EditStatus,
    EditPhasesProject,
    EditUseStatusRules,
    EditCategoryPickerList,
    EditUserList,
    EditTagList,
    EditDepartmentPicker,
    EditMilestonePlanSelect {

    constructor(json?: any) {
        super(json);
        delete this.updated;

        if (!this.id) {
            this.id = 0;
            this.use_status_rules = true;
        }
    }

    /**
     * Clone this project only using safe fields.
     * Meaning: this copy is safe to send to the server as a relation.
     */
    public safeClone(): Project {
        const project: any = new Project();
        for (const key in this) {
            if (this[key] instanceof Object
                || this[key] instanceof Array
                || this[key] instanceof Function) {
                // Ignore it!
            } else {
                project[key] = this[key];
            }
        }
        return project;
    }

    public get status(): number {
        if (this.main_status && this.main_status.status_id) {
            return this.main_status.status_id;
        } else {
            return StatusTypes.GREEN;
        }
    }

    public set status(value: number) {
        if (this.main_status && this.main_status.status_id) {
            if (this.main_status.project) {
                delete this.main_status.project;
            }
            this.main_status.status_id = value;
            if (this.main_status) delete this.main_status.status;
        } else {
            this.main_status = new ProjectStatus(
                {status_id: value}
            );
        }
    }

    public get completed(): boolean {
        return this.archived_id && this.archived_id != 0 && this.archived !== null;
    }

    public getNextMilestone(): Milestone {
        return this.next_milestone;
    }

    public findProjectEstimateByType(projectEstimateTypeId: number): ProjectEstimate {
        if (this.project_estimates) {
            return this.project_estimates.find(projectEstimate => projectEstimate.project_estimate_type_id == projectEstimateTypeId);
        } else {
            return null;
        }
    }

    public getDefaultTaskEstimate(): ProjectEstimate {
        if (this.project_estimates?.length > 0 && this.project_type?.default_project_estimate_type_id) {
            return this.findProjectEstimateByType(this.project_type.default_project_estimate_type_id);
        }
    }

    public findProjectsDeadlineByType(projectDeadlineTypeId: number): ProjectsDeadline {
        if (this.projects_deadlines) {
            return this.projects_deadlines.find(projectsDeadline => projectsDeadline.project_deadline_type_id == projectDeadlineTypeId);
        } else {
            return null;
        }
    }

    public findProjectsUsersByType(projectUserTypeId: number): ProjectsUser[] {
        if (this.projects_users) {
            return this.projects_users.filter(projectsUser => projectsUser.project_user_type_id == projectUserTypeId);
        } else {
            return [];
        }
    }

    public findProjectStatusByType(typeId: number): ProjectStatus {
        if (typeId == ProjectStatusTypes.Normal) return this.main_status;
        if (this.project_statuses) {
            return this.project_statuses.find(projectStatus => projectStatus.project_status_type_id == typeId);
        } else {
            return null;
        }
    }

    public getUpperProjects(projectType: ProjectType): Project[] {
        return this.projects ? this.projects.filter(item => projectType.getUpperProjectTypeIds().includes(item.project_type_id)) : [];
    }

    public getPhasesProjectByDate(date: Date): PhasesProject {
        let phasesProject: PhasesProject = null;
        if (this.phases_projects) {
            this.phases_projects.forEach(item => {
                // Only consider items with start date
                if (item.start) {

                    // Check if date is after this phase start
                    const start = moment(item.getStartedDate());
                    if (moment(date).isSameOrAfter(start)) {

                        // If no selected, select this one
                        if (!phasesProject) {
                            phasesProject = item;
                        } else {

                            // Compare start, use this if better choice
                            if (moment(date).isAfter(moment(phasesProject.getStartedDate()))) {
                                phasesProject = item;
                            }

                        }

                    }
                }
            });
        }
        return phasesProject;
    }

    public getAllPhasesProjectByDate(date: Date, includeNext = false): PhasesProject[] {
        const phasesProjects: PhasesProject[] = [];
        let lastIndex;
        this.phases_projects?.forEach((item, index) => {
            // Only consider items with start date
            if (item.start) {
                // Check if date is after this phase start
                const start = moment(item.getStartedDate());
                if (moment(date).isSameOrAfter(start)) {
                    phasesProjects.push(item);
                    lastIndex = index;
                }
            }
        });
        if (phasesProjects.length > 0 && this.phases_projects.length != phasesProjects.length && includeNext) {
            phasesProjects.push(this.phases_projects[(lastIndex as number) + 1]);
        }
        return phasesProjects;
    }

    // <editor-fold desc="Save Helpers">

    public delete(cascade?: boolean, callback?: () => void) {
        if (this.exists()) {
            Api.projects().deleteDeleteById(this.id)
                .cascade(cascade)
                .delete(value => {
                    AppInjector.getInjector().get(EventService).emitProject(this, EventService.Deleted);
                    if (callback) {
                        callback();
                    }
                });
        }
    }

    // </editor-fold>

    // <editor-fold desc="HasTypeProperties">

    getTypeDefinitionAsync(callback: (definition: string) => void): void {
        if (this.project_type_id) {
            AppInjector.getInjector().get(ProjectsService).getProjectType(this.project_type_id, projectType => {
                callback(projectType.definition);
            });
        } else {
            callback('');
        }
    }

    getTypeNameAsync(callback: (name: string) => void): void {
        if (this.project_type_id) {
            AppInjector.getInjector().get(ProjectsService).getProjectType(this.project_type_id, projectType => {
                callback(projectType.name);
            });
        } else {
            callback('');
        }
    }

    // </editor-fold>

    // <editor-fold desc="HasDeadlines">

    public findDeadlineByTypeId(typeId: number): Deadline | undefined {
        const deadlines = this.projects_deadlines
            ?.filter(projectsDeadline => projectsDeadline.project_deadline_type_id == typeId)
            ?.map(projectsDeadline => projectsDeadline.deadline);
        return deadlines?.length > 0 ? deadlines[0] : undefined;
    }

    // </editor-fold>

    // <editor-fold desc="HasUsers">

    public getUsers(): User[] {
        return this.projects_users?.map(projectsUser => projectsUser.user) ?? [];
    }

    public findUsersByTypeId(typeId: number): User[] {
        return this.projects_users
            ?.filter(projectsUser => projectsUser.project_user_type_id == typeId)
            ?.map(projectsUser => projectsUser.user) ?? [];
    }

    // </editor-fold>

    // <editor-fold desc="HasConfigurations">

    public getMiniCardDeadlineTypeId(): number {
        if (!this.project_type) {
            AppInjector.getInjector().get(ProjectsService).getProjectType(this.project_type_id, projectType => {
                this.project_type = projectType;
            });
        }
        return this.project_type?.default_project_deadline_type_id ?? 0;
    }

    // </editor-fold>

    // <editor-fold desc="Reaction interface">

    public addReaction(reaction: Reaction): void {
        Api.projects().reactionAddPutByProjectId(this.id)
            .reaction_type_id(reaction.reaction_type_id)
            .value(reaction.value)
            .user_id(reaction.user_id)
            .save(null);
    }

    public removeReaction(reaction: Reaction): void {
        Api.projects().reactionRemoveDeleteByProjectId(this.id)
            .reaction_type_id(reaction.reaction_type_id)
            .value(reaction.value)
            .user_id(reaction.user_id)
            .delete(null);
    }

    public getReactionChangedEventName(): string {
        return Events.ProjectReactionsChanged(this.id);
    }

    // </editor-fold>

    public static Create(typeId: number, presets: ProjectsCreateRequestPreset[], callback?: (project: Project) => void) {
        Api.projects()
            .createPost()
            .typeId(typeId)
            .save({presets: presets}, project => {
                AppInjector.getInjector().get(EventService).emitProject(project, EventService.Created);
                if (callback) {
                    callback(project);
                }
            });
    }

    getEventName(field: string, options: any = {}): string {
        switch (field) {
            case 'title':
                return Events.ProjectChangedTitle(this.id);
            case 'archived':
                return Events.ProjectChangedArchived(this.id);
            case 'category-list':
            case 'categories':
                return Events.ProjectChangedCategories(this.id);
            case 'deadlines':
                return Events.ProjectChangedDeadlines(this.id);
            case 'main-status':
            case 'status':
                return Events.ProjectChangedStatuses(this.id);
            case 'use_status_rules':
                return Events.ProjectChangedUseStatusRules(this.id);
            case 'users':
                return Events.ProjectChangedUsers(this.id);
            case 'note-field':
                switch (options?.prop ?? '') {
                    case 'purpose':
                        return Events.ProjectChangedPurpose(this.id);
                    case 'obs':
                        return Events.ProjectChangedObs(this.id);
                    default:
                        return Events.ProjectChangedNotes(this.id);
                }
            case 'link':
                return Events.ProjectChangedReference(this.id);
            case 'estimates':
                return Events.ProjectChangedEstimates(this.id);
            case 'departments':
                return Events.ProjectChangedDepartments(this.id);
            case 'tags':
                return Events.ProjectChangedTags(this.id);
            case 'phases-project':
                return Events.ProjectChangedCurrentPhasesProject(this.id);
            default:
                return Events.ProjectChanged(this.id, field);
        }
    }

    // <editor-fold desc="EditTitle">

    getTitle(): string {
        return this.title ?? '';
    }

    setTitle(value: string): void {
        this.title = value;
        Api.projects()
            .updateTitlePutByProjectId(this.id)
            .value(value)
            .save(null, project => {
                this.title = project.title;
                AppInjector.getInjector().get(EventService).emitProject(this, EventService.Updated, ['title']);
            });
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['title']);
    }

    // </editor-fold>

    // <editor-fold desc="EditArchived">

    isArchived(): boolean {
        return (this.archived_id ?? 0) > 0;
    }

    setArchived(value: boolean): void {
        if (value) {
            this.archived = Archived.create();
        } else {
            this.archived = undefined;
            this.archived_id = 0;
        }
        Api.projects()
            .updateArchivedPutByProjectId(this.id)
            .value(value)
            .save(null, project => {
                this.archived = project.archived;
                this.archived_id = project.archived_id;

                AppInjector.getInjector().get(EventService)
                    .emitProject(this, EventService.Updated, ['archived', 'archived_id']);
            });
    }

    // </editor-fold>

    // <editor-fold desc="EditUserList">

    public addUser(typeId: number, user: User): void {
        if (!this.projects_users) {
            this.projects_users = [];
        }
        if (this.projects_users.some(projectsUser => projectsUser.project_user_type_id == typeId
            && projectsUser.user_id == user.id)) {
            return;
        }

        this.projects_users.push(ProjectsUser.Create(typeId, user.id, user));

        Api.projects()
            .addUserPutByProjectId(this.id)
            .projectUserTypeId(typeId)
            .userId(user.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['projects_users']);
    }

    public addUsersFromDepartment(typeId: number, department: Department, users: User[]): void {
        if (!this.projects_users) {
            this.projects_users = [];
        }

        const newUsers = users
            .filter(user => {
                return !this.projects_users
                    .some(projectsUser => projectsUser.project_user_type_id == typeId
                        && projectsUser.user_id == user.id);
            });

        if (newUsers.length == 0) {
            return;
        }

        newUsers.forEach(user => {
            this.projects_users.push(ProjectsUser.Create(typeId, user.id, user));
        });

        Api.projects()
            .addUserFromDepartmentPutByProjectId(this.id)
            .projectUserTypeId(typeId)
            .departmentId(department.id)
            .userIds(newUsers.map(user => user.id))
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['projects_users']);
    }

    public removeUser(typeId: number, user: User): void {
        this.projects_users = this.projects_users
            ?.filter(projectsUser => {
                return !(projectsUser.project_user_type_id == typeId && projectsUser.user_id == user.id);
            });

        Api.projects()
            .removeUserPutByProjectId(this.id)
            .projectUserTypeId(typeId)
            .userId(user.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['projects_users']);
    }

    public removeUsers(typeId: number, users: User[]): void {
        const userIds = users.map(user => user.id);

        this.projects_users = this.projects_users
            ?.filter(projectsUser => {
                return !(projectsUser.project_user_type_id == typeId && userIds.includes(projectsUser.user_id));
            });

        Api.projects()
            .removeUsersPutByProjectId(this.id)
            .projectUserTypeId(typeId)
            .userIds(userIds)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['projects_users']);
    }

    // </editor-fold>

    // <editor-fold desc="EditDeadlineList">

    setDeadline(typeId: number, deadline?: Deadline): void {
        // Remove existing deadlines with this typeId
        this.projects_deadlines = this.projects_deadlines
            ?.filter(projectsDeadline => projectsDeadline.project_deadline_type_id !== typeId) ?? [];
        if (deadline) { // No deadline = remove it
            this.projects_deadlines.push(ProjectsDeadline.Create(typeId, deadline));
        }
        Api.projects()
            .updateDeadlinePutByProjectId(this.id)
            .projectDeadlineTypeId(typeId)
            .date(deadline?.getServerDate() ?? null)
            .isSoft(deadline?.is_soft ?? false)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['projects_deadlines']);
    }

    getStaticDeadlines(deadlineTypeId: number, callback: (staticDeadlines: StaticDeadline[]) => void) {
        StaticDeadline.GetAll(items => {
            callback(items.filter(item => {
                return item.project_types
                        ?.find(projectType => projectType.id == this.project_type_id)
                    !== undefined;
            }));
        });
    }

    // </editor-fold>

    // <editor-fold desc="EditEstimateList">

    getDefaultEstimateTypeId(result: (typeId?: number) => void) {
        AppInjector.getInjector().get(ProjectsService).getProjectType(this.project_type_id, projectType => {
            result(projectType.default_project_estimate_type_id);
        });
    }

    findEstimateByTypeId(typeId: number): Estimate {
        const estimates = this.project_estimates
            ?.filter(projectEstimate => projectEstimate.project_estimate_type_id == typeId)
            ?.map(projectEstimate => projectEstimate.estimate);
        return estimates?.length > 0 ? estimates[0] : undefined;
    }

    setEstimate(typeId: number, estimate?: Estimate): void {
        // Remove existing estimates with this typeId
        this.project_estimates = this.project_estimates
            ?.filter(projectEstimate => projectEstimate.project_estimate_type_id !== typeId) ?? [];
        if (estimate) { // No estimate = remove it
            this.project_estimates.push(ProjectEstimate.Create(typeId, estimate));
        }
        Api.projects()
            .updateEstimatePutByProjectId(this.id)
            .projectEstimateTypeId(typeId)
            .value(estimate?.value ?? null)
            .unitId(estimate?.estimate_unit_id ?? 0)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['project_estimates']);
    }

    // </editor-fold>

    // <editor-fold desc="EditMilestonePlanSelect">

    setMilestonePlan(value: MilestonePlan, onError: (error: string) => void, onFinish: () => void): void {
        const injector = AppInjector.getInjector();
        const snackbar = injector.get(SnackbarService);
        const translateService = injector.get(TranslateService);
        const eventService = injector.get(EventService);
        Api.milestonePlans().copyGetById(value.id)
            .project_id(this.id)
            .find(response => {
                const error = response[0].error;
                if (error) {
                    onError(error);
                } else {

                    // Update project
                    const project = new Project(response[0].project);
                    eventService.emitProject(project, EventService.Updated, [
                        'milestones',
                        'current_phases_project',
                        'phases_projects',
                    ]);

                    // Update milestones and tasks
                    const milestones = response[0].milestones?.map(milestone => {
                        return new Milestone(milestone);
                    });
                    // console.log('milestones created (Api.milestonePlans().copyGetById): ', response[0]);
                    const projectClone = {...project} as Project;
                    delete projectClone.milestones;
                    milestones.forEach(milestone => {
                        let m = new Milestone(milestone);
                        m.projects = [projectClone];
                        eventService.emitMilestone(m, EventService.Created);
                    });

                    snackbar.add(translateService.instant('_ui_dialog_milestone_plan_copied'));
                    onFinish();
                }

            });
    }

    // </editor-fold>

    // <editor-fold desc="EditText">

    getText(prop: string): string {
        return (this as any)[prop] ?? '';
    }

    setText(prop: string, value: string): void {
        switch (prop) {
            case 'purpose':
                this.setPurpose(value);
                break;
            case 'obs':
                this.setObs(value);
                break;
            default:
                this.setNotes(prop, value);
                break;
        }
    }

    public setNotes(prop: string, value: string): void {
        (this as any)[prop] = value;
        Api.projects()
            .updateNotesPutByProjectId(this.id)
            .prop(prop)
            .save({value: value});
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, [prop]);
    }

    public setPurpose(value: string): void {
        this.purpose = value;
        Api.projects()
            .updatePurposePutByProjectId(this.id)
            .save({value: value});
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['purpose']);
    }

    public setObs(value: string): void {
        this.obs = value;
        Api.projects()
            .updateObsPutByProjectId(this.id)
            .save({value: value});
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['obs']);
    }

    // </editor-fold>

    // <editor-fold desc="EditLink">

    getLink(): string {
        return this.reference ?? '';
    }

    setLink(value: string): void {
        this.reference = value;
        Api.projects()
            .updateReferencePutByProjectId(this.id)
            .save({value: value});
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['reference']);
    }

    // </editor-fold>

    // <editor-fold desc="EditStatus">

    getStatus(): number {
        return this.main_status?.status_id ?? StatusTypes.GREEN;
    }

    setStatus(value: number): void {
        this.main_status = ProjectStatus.Create(ProjectStatusTypes.Normal, value);
        this.use_status_rules = false;

        Api.projects()
            .updateStatusPutByProjectId(this.id)
            .projectStatusTypeId(ProjectStatusTypes.Normal)
            .resetUseStatusRules(true)
            .status(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['main_status', 'use_status_rules']);
    }

    // </editor-fold>

    // <editor-fold desc="EditStatusList">

    findStatusByTypeId(typeId: number): number | undefined {
        const status = this.project_statuses
            ?.find(projectStatus => projectStatus.project_status_type_id == typeId);
        return status?.status_id ?? undefined;
    }

    setStatusByType(typeId: number, status: number): void {
        // Remove existing status with this typeId
        this.project_statuses = this.project_statuses
            ?.filter(projectStatus => projectStatus.project_status_type_id !== typeId) ?? [];
        if (status) { // No status = remove it
            const projectsStatus = ProjectStatus.Create(typeId, status);
            this.project_statuses.push(projectsStatus);
            if (typeId == ProjectStatusTypes.Normal) {
                this.main_status = projectsStatus;
                this.use_status_rules = false;
            }
        }
        Api.projects()
            .updateStatusPutByProjectId(this.id)
            .projectStatusTypeId(typeId)
            .status(status)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['project_statuses']);
    }

    // </editor-fold>

    // <editor-fold desc="EditPhasesProject">

    getPhasesProjects(): PhasesProject[] {
        return this.phases_projects ?? [];
    }

    addPhasesProject(value: PhasesProject, callback?: () => void): void {
        if (!this.phases_projects) {
            this.phases_projects = [];
        }

        // If this is the first phases project, set date to first project deadline
        if (this.phases_projects.length == 0 && this.projects_deadlines?.length) {
            value.start = this.projects_deadlines[0].deadline.date;
        }

        const api = Api.projects()
            .addPhasesProjectPutByProjectId(this.id)
            .phaseId(value.phase_id);
        if (value.start) {
            api.start(Helpers.serverDate(value.getStartedDate()));
        }
        api.save(null, newPhasesProject => {
            value.id = newPhasesProject.id;
            if (callback) {
                callback();
            }
        });
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['phases_projects']);
    }

    removePhasesProject(value: PhasesProject): void {
        const index = this.phases_projects?.findIndex(phasesProject => phasesProject.id == value.id) ?? -1;
        if (index === -1) {
            return;
        }

        this.phases_projects.splice(index, 1);
        Api.projects()
            .phasesProjectDeleteDeleteByProjectIdByPhasesProjectId(this.id, value.id)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['phases_projects']);
    }

    updatePhasesProjectDate(phasesProject: PhasesProject, date: Date): void {
        phasesProject.start = Helpers.serverDate(date);

        Api.projects()
            .phasesProjectUpdateStartPutByProjectIdByPhasesProjectId(this.id, phasesProject.id)
            .start(phasesProject.start)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['phases_projects']);
    }

    getCurrentPhasesProject(): PhasesProject | undefined {
        return this.current_phases_project;
    }

    setCurrentPhasesProject(value?: PhasesProject): void {
        this.current_phases_project = value;
        this.current_phases_project_id = value?.id ?? 0;

        Api.projects()
            .updateCurrentPhasesProjectPutByProjectId(this.id)
            .value(value?.id ?? 0)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['current_phases_project', 'current_phases_project_id']);
    }

    // </editor-fold>

    // <editor-fold desc="EditUseStatusRules">

    isUseStatusRules(): boolean {
        return this.use_status_rules ?? false;
    }

    setUseStatusRules(value: boolean): void {
        this.use_status_rules = value;
        Api.projects()
            .updateUseStatusRulesPutByProjectId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['use_status_rules']);
    }

    // </editor-fold>

    // <editor-fold desc="EditCategoryPickerList">

    getCategories(): Category[] {
        return this.categories ?? [];
    }

    findCategoriesByTypeId(typeId: number): Category[] {
        return this.categories
            ?.filter(category => category.category_type_id == typeId) ?? [];
    }

    addCategory(value: Category): void {
        if (!this.categories) {
            this.categories = [];
        }
        if (this.categories.some(category => category.id == value.id)) {
            return;
        }
        this.categories.push(value);

        Api.projects()
            .addCategoryPutByProjectId(this.id)
            .value(value.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['categories']);
    }

    setCategoriesForType(typeId: number, categories: Category[]): void {
        if (!this.categories) {
            this.categories = [];
        }

        // Remove existing categories with this type
        this.categories = this.categories.filter(category => category.category_type_id !== typeId);

        // Add given categories
        this.categories.push(...categories);

        Api.projects()
            .setCategoriesForTypePutByProjectId(this.id)
            .categoryTypeId(typeId)
            .categoryIds(categories.map(category => category.id))
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['categories']);
    }

    replaceCategories(removedCategories: Category[], addedCategories: Category[]): void {
        const categoryIdsToRemove = removedCategories.map(category => category.id);
        const categoryIdsToAdd = addedCategories.map(category => category.id);

        if (!this.categories) {
            this.categories = [];
        }

        // Remove
        this.categories = this.categories.filter(category => !categoryIdsToRemove.includes(category.id));

        // Add
        this.categories.push(...addedCategories);

        Api.projects()
            .replaceCategoriesPutByProjectId(this.id)
            .removedCategoryIds(categoryIdsToRemove)
            .addedCategoryIds(categoryIdsToAdd)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['categories']);
    }

    removeCategory(category: Category): void {
        const index = this.categories.indexOf(category);
        if (index !== -1) {
            this.categories.splice(index, 1);
        }

        Api.projects()
            .removeCategoryPutByProjectId(this.id)
            .value(category.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['categories']);
    }

    // </editor-fold>

    // <editor-fold desc="EditDepartmentPicker">

    public addDepartment(value: Department): void {
        if (!this.departments) {
            this.departments = [];
        }
        if (this.departments.some(department => department.id == value.id)) {
            return;
        }
        this.departments.push(value);

        Api.projects()
            .addDepartmentPutByProjectId(this.id)
            .value(value.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['departments']);
    }

    public removeDepartment(value: Department): void {
        const index = this.departments.indexOf(value);
        if (index !== -1) {
            this.departments.splice(index, 1);
        }

        Api.projects()
            .removeDepartmentPutByProjectId(this.id)
            .value(value.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['departments']);
    }

    // </editor-fold>

    // <editor-fold desc="EditTagList">

    public addTag(value: Tag): void {
        if (!this.tags) {
            this.tags = [];
        }
        if (this.tags.some(tag => tag.id == value.id)) {
            return;
        }
        this.tags.push(value);

        Api.projects()
            .addTagPutByProjectId(this.id)
            .value(value.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['tags']);
    }

    public removeTag(value: Tag): void {
        const index = this.tags.indexOf(value);
        if (index !== -1) {
            this.tags.splice(index, 1);
        }

        Api.projects()
            .removeTagPutByProjectId(this.id)
            .value(value.id)
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['tags']);
    }

    // </editor-fold>

    // <editor-fold desc="EditType">

    setType(value: number): void {
        this.project_type_id = value;
        Api.projects()
            .updateTypePutByProjectId(this.id)
            .value(value)
            .save(null);
        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['project_type_id']);
    }

    // </editor-fold>

    // <editor-fold desc="EditProjects">

    public addProject(value: Project): void {
        if (!this.projects) {
            this.projects = [];
        }
        this.projects.push(value);
        Api.projects()
            .addProjectPutByProjectId(this.id)
            .value(value.id)
            .save(null);
        AppInjector.getInjector().get(EventService).emitProject(this, EventService.Updated, [
            'projects',
        ]);
    }

    public removeProject(value: Project): void {
        const index = this.projects?.indexOf(value) ?? -1;
        if (index !== -1) {
            this.projects.splice(index, 1);
        }
        Api.projects()
            .removeProjectPutByProjectId(this.id)
            .value(value.id)
            .save(null);
        AppInjector.getInjector().get(EventService).emitProject(this, EventService.Updated, [
            'projects',
        ]);
    }

    // </editor-fold>

    public setMilestoneSequence(milestones: Milestone[]): void {
        this.milestones_projects?.forEach(milestonesProject => {
            milestonesProject.index_ = milestones.findIndex(milestone => milestone.id == milestonesProject.milestone_id);
        });

        Api.projects()
            .updateMilestoneSequencePutByProjectId(this.id)
            .milestoneIds(milestones.map(milestone => milestone.id))
            .save(null);

        AppInjector.getInjector().get(EventService)
            .emitProject(this, EventService.Updated, ['milestones_projects']);
    }

}
