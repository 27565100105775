import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-ghost-container',
    templateUrl: './ghost-container.component.html',
    styleUrls: ['./ghost-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class GhostContainerComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
