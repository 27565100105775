import {ChangeDetectionStrategy, Component, EventEmitter, OnInit} from '@angular/core';
import {MilestoneListConfiguration} from '@app/shared/_ui/lists/milestone-list/MilestoneListConfiguration';
import {PageComponent} from '@app/pages/page.component';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {ActivatedRoute} from '@angular/router';
import {
    MilestoneStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneStatusPresetGenerator";
import {StatusTypes} from "@app/constants";
import {
    MilestoneUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneUseStatusRulesPresetGenerator";
import {
    MilestoneResponsiblePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneResponsiblePresetGenerator";
import {
    MilestoneDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneDeadlinePresetGenerator";
import {
    MilestoneTitlePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/MilestonePresets/Generators/MilestoneTitlePresetGenerator";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

@Component({
    selector: 'app-milestones',
    templateUrl: './milestones.component.html',
    styleUrls: ['./milestones.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class MilestonesComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;

    public configuration = new MilestoneListConfiguration();
    public searchValue: string;
    public reloadEmitter = new EventEmitter();

    txtQueryChanged: Subject<string> = new Subject<string>();
    placeholder: string = '_ui_search_placeholder';

    constructor(private activatedRoute: ActivatedRoute) {
        super();
        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe((f) => {
            this.search();
        }));

        this.txtQueryChanged
            .pipe(debounceTime(1000), distinctUntilChanged()) // wait 1 sec after the last event before emitting last event &&  only emit if value is different from previous value
            .subscribe((value: string) => {
                this.search();
            });
    }

    ngOnInit() {
        this.usersService.currentUser$.subscribe((activeUser)=>{
            if(activeUser) {
                this.shellService.setHeaderTitle(this.translateService.instant('_milestones'), null, false, false, false);

                if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
                    this.placeholder = 'Find...';
                }

                this.configuration.listClassName = 'table-mode';
                this.configuration.setOrderBy([['name', 'asc']]);
                this.configuration.setLimit(50);
                this.configuration
                    .setUseGlobalFilter(true)
                    .setShowCreateNew(false);

                const params = this.activatedRoute.snapshot.params;
                switch (params?.displayMode) {
                    case 'personal':
                        // console.log('personal - set user : ', activeUser)
                        this.configuration
                            .setResponsible(activeUser)
                            .setArchived(false)
                            .setUseGlobalFilter(true)
                            .setShowProjectMiniCard(true)
                        break;
                }
            }
        })



    }

    public search() {
        this.configuration.setSearch(this.searchValue);
        this.reloadEmitter.emit();
    }

    public reset() {
        this.searchValue = null;
        this.search();
    }

    changed(query: string) {
        this.txtQueryChanged.next(query);
    }

}
