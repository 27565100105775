<ng-container [ngSwitch]="name">
    <app-title-editor
        class="flex-shrink-1"
        *ngSwitchCase="TitleEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-deadline-list-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="DeadlineListEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-milestone-plan-select-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="MilestonePlanSelectEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-card-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="CardEditorComponent.name"
        [model]="$any(card)"
        [cardItem]="card"
        [configuration]="$any(configuration)"
        [listDragInterface]="listDragInterface"
        [editorEvents]="editorEvents"
    />
    <app-status-editor
        class="flex-grow-0 flex-shrink-0"
        *ngSwitchCase="StatusEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-estimate-list-editor
        *ngSwitchCase="EstimateListEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-category-list-editor
        *ngSwitchCase="CategoryListEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-reaction-list-editor
        *ngSwitchCase="ReactionListEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-text-editor
        *ngSwitchCase="TextEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-link-editor
        *ngSwitchCase="LinkEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-who-when-editor
        *ngSwitchCase="WhoWhenEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-action-editor
        *ngSwitchCase="ActionEditorComponent.name"
        [model]="model"
        [cardItem]="card"
        [configuration]="$any(configuration)"
        [listDragInterface]="listDragInterface"
        [editorEvents]="editorEvents"
    />
    <app-milestone-list-editor
        *ngSwitchCase="MilestoneListEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-archived-editor
        *ngSwitchCase="ArchivedEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-user-list-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="UserListEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-use-status-rules-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="UseStatusRulesEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-category-picker-list-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="CategoryPickerListEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-department-picker-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="DepartmentPickerEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-tag-list-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="TagListEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-can-copy-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="CanCopyEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-is-private-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="IsPrivateEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-number-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="NumberEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-todo-category-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="TodoCategoryEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
    <app-checkbox-editor
        class="flex-grow-1 flex-shrink-1"
        *ngSwitchCase="CheckboxEditorComponent.name"
        [model]="model"
        [configuration]="$any(configuration)"
        [editorEvents]="editorEvents"
    />
</ng-container>
