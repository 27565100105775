import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    ViewChild
} from '@angular/core';
import {PageComponent} from "@app/pages/page.component";
import {Category, Phase, PhasesProject, Project} from "@app/core/models";
import {Api, Kanban_Prioritization_MatrixSettingValue} from "@app/core/Api";
import {Settings} from "@app/pages/displays/display-kanban/Settings";
import {ProjectStatusTypes, StatusTypes} from "@app/constants";
import {CdkDragEnd, CdkDragStart} from "@angular/cdk/drag-drop";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters} from "@app/pages/displays/display-kanban/Filters";
import {ProjectFetcher, ProjectFetchRequest} from "@app/shared/_ui/lists/project-list/ProjectFetcher";
import {ListConfiguration} from "@app/shared/_ui/lists/ListConfiguration";
import {BaseOnScreenFilter} from "@app/shared/_ui/lists/BaseOnScreenFilter";
import {AvoidDeadlineTypeFilter} from "@app/shared/_ui/lists/project-list/OnScreenFilters/AvoidDeadlineTypeFilter";
import {NonArchivedFilter} from '@app/pages/displays/display-kanban/OnScreenFilters/NonArchivedFilter';
import {ScreenshotHelper} from "@app/core/ScreenshotHelper/ScreenshotHelper";
import {
    ProjectStatusPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectStatusPresetGenerator";
import {
    ProjectUseStatusRulesPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUseStatusRulesPresetGenerator";
import {
    ProjectDepartmentPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectDepartmentPresetGenerator";
import {
    ProjectUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectUserPresetGenerator";
import {ProjectUserTypes} from "@app/core/models/Project";
import {
    ProjectCategoryPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectCategoryPresetGenerator";
import {
    ProjectPhasePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/ProjectPresets/Generators/ProjectPhasePresetGenerator";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {GenericTableColumn} from "@app/pages/displays/display-kanban/TableColumns/GenericTableColumn";
import {PrioritizationColumnType} from "@app/pages/displays/display-kanban/ColumnTypes/PrioritizationColumnType";
import {PhaseColumnType} from "@app/pages/displays/display-kanban/ColumnTypes/PhaseColumnType";
import {PhaseColumn} from "@app/pages/displays/display-kanban/Columns/PhaseColumn";
import {ColumnTypes} from "@app/pages/displays/display-kanban/ColumnTypes";
import {PrioritizationColumn} from "@app/pages/displays/display-kanban/Columns/PrioritizationColumn";
import {PrioritizationTableColumn} from "@app/pages/displays/display-kanban/TableColumns/PrioritizationTableColumn";
import {PhaseTableColumn} from "@app/pages/displays/display-kanban/TableColumns/PhaseTableColumn";

@Component({
    selector: 'app-display-kanban',
    templateUrl: './display-kanban.component.html',
    styleUrls: ['./display-kanban.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DisplayKanbanComponent extends PageComponent implements OnInit {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();

    // View bindings
    @ViewChild('contentContainer', {static: false}) contentContainer: ElementRef;
    public tableColumns: GenericTableColumn[] = [];
    public onColumnSortRenderEventEmitter = new EventEmitter();
    public onScreenFilters: BaseOnScreenFilter<Project>[] = [];
    public prioritizationMatrixSetting?: Kanban_Prioritization_MatrixSettingValue;

    // Data
    private phases: Map<number, Phase>;
    private priorityCategories: Category[];

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
        this.shellService.setHeaderTitle(this.Constants.System.Loading);
        this.initialized = false;

        let multiColumnFilter = new NonArchivedFilter(false)
        multiColumnFilter.multiColumn = true;
        this.onScreenFilters.push(multiColumnFilter);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.buildColumns();
        this.filterGlobalService.setShowSearch(true);
        this.filterGlobalService.setShowUserSearch(true);
    }

    // <editor-fold desc="View Actions">

    public onDragStartedEvent($event: CdkDragStart<CardItem<Project>>) {
        const item = $event.source.data;
        this.tableColumns.forEach(column => {
            column.projectListConfiguration.allowCardItemDrop(item, null, (allow) => {
                column.projectListConfiguration.dropDisabled = !allow;
            });
        });
        this.detectChanges();
    }

    public onDragEndedEvent($event: CdkDragEnd) {
        this.tableColumns.forEach(column => {
            column.projectListConfiguration.dropDisabled = false;
        });
        this.detectChanges();
    }

    public onCaptureScreenshotBtnClicked() {
        if (this.isCapturingScreenshot) {
            return;
        }
        this.isCapturingScreenshot = true;
        ScreenshotHelper.Capture(this.contentContainer?.nativeElement, () => {
            this.isCapturingScreenshot = false;
            this.detectChanges();
        }, {selector: document.getElementById('klartboard')});
    }

    // </editor-fold>

    // <editor-fold desc="Load data">

    private projectFetcher: ProjectFetcher;

    public loadData() {
        // Validate method executable
        if (!this.phases || !this.shellPageData.shellFilterGroup.activeFilter) {
            return;
        }

        // Cancel running fetchers
        this.projectFetcher.cancel();

        // Apply the latest filter and sort to columns
        this.tableColumns.forEach(column => {
            if (this.shellPageData.activePageColumnSort) {
                column.applySortItem(
                    this.shellPageData.activePageColumnSort,
                    this.filterGlobalService.getActiveSettings().activeSortDirection
                );
            } else if (this.shellPageData.shellFilterGroup.activeFilter.sort_type) {
                column.applySortItem(
                    new PageColumnSort(this.shellPageData.shellFilterGroup.activeFilter.sort_type),
                    this.shellPageData.shellFilterGroup.activeFilter.sort_direction
                );
            }
            column.applyFilter(this.shellPageData.shellFilterGroup.activeFilter, this.filtersSettings);
        })

        // Execute fetchers
        this.projectFetcher.execute();
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    private buildColumns() {
        this.columnController.addColumnTypes([
            new PrioritizationColumnType(),
            new PhaseColumnType(),
        ]);
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();
        this.columnController.loadColumns(this.displayId, () => {
            const phaseIds = this.columnController.getColumns<PhaseColumn>(ColumnTypes.Phase)
                .map(column => column.getFilterPhase());

            Api.phases().get()
                .include('color')
                .whereIn('id', phaseIds)
                .find(phases => {

                    // Save phases to class
                    this.phases = new Map();
                    phases.forEach(phase => this.phases.set(phase.id, phase));

                    // Remove columns without category
                    this.columnController.getColumns<PhaseColumn>(ColumnTypes.Phase)
                        .filter(column => !this.phases.has(column.getFilterPhase()))
                        .forEach(column => this.columnController.removeColumn(column));

                    this.initialize();
                });
        });
    }

    private initialize() {
        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            this.tableColumns = this.columnController.getVisibleTableColumns() as GenericTableColumn[];
            this.setupColumns();
            this.loadData();
        }));

        // Use filter editor config to find category types for sorting
        this.filtersSettings.getEditorConfig(this.display, config => {
            // Convert category types to page column sort
            const categoryTypeColumns = config.categoryTypes?.map(categoryType => {
                return PageColumnSort.CreateWithSortId(Filters.SortCategoryTypeGenerator(categoryType));
            });

            // Convert project deadline types to page column sort
            const projectDeadlineTypeColumnsWith = config.projectDeadlineTypes?.map(projectDeadlineType => {
                return PageColumnSort.CreateWithSortId(Filters.SortDeadlineWithGenerator(projectDeadlineType));
            });

            this.columnController.getColumns<PhaseColumn>(ColumnTypes.Phase)
                .forEach(column => {
                    column.setPhase(this.phases.get(column.getFilterPhase()));

                    // Append column sorts to columns
                    column
                        .getTableColumns()
                        .forEach(tableColumn => {
                            tableColumn.sortItems.push(...categoryTypeColumns, ...projectDeadlineTypeColumnsWith);
                        });
                });

            // Add OnScreenFilters for "Without <deadline-type>"
            config.projectDeadlineTypes?.map(projectDeadlineType => {
                let filter = new AvoidDeadlineTypeFilter(false, projectDeadlineType);
                filter.visible = false;
                filter.multiColumn = false;
                this.onScreenFilters.push(filter);
            });

            // Get category for Priority column
            const prioritizationColumn = this.columnController.getColumns<PrioritizationColumn>(ColumnTypes.Prioritization)[0];
            if (prioritizationColumn) {
                this.prioritizationMatrixSetting = prioritizationColumn.getMatrixSetting();
                Category.GetByCategoryTypeId(prioritizationColumn.getCategoryType(), categories => {
                    this.priorityCategories = categories;
                    // Load data
                    this.tableColumns = this.columnController.getVisibleTableColumns() as GenericTableColumn[];
                    this.setupColumns();
                    this.loadData();
                    this.initialized = true;

                    // Subscribe to global listeners
                    this.setupSubscriptions();
                });
            } else {
                this.tableColumns = this.columnController.getVisibleTableColumns() as GenericTableColumn[];
                this.setupColumns();
                this.loadData();
                this.initialized = true;

                // Subscribe to global listeners
                this.setupSubscriptions();
            }
        });
    }

    private setupColumns() {
        // Reset fetchers
        this.projectFetcher = new ProjectFetcher();
        this.projectFetcher.showAll = true; // MultiLists understøtter ikke limits
        this.subscribe(this.projectFetcher.onFinishEvent.subscribe(() => this.detectChanges()));

        this.setupPrioritizationColumn();
        this.setupPhaseColumns();

        this.shellService.setPageSettingsTaskTypeIds([]);
        this.shellService.setPageSettingsProjectTypeIds(Settings.GetProjectTypeIds(this.settingsMap));
    }

    private setupPrioritizationColumn() {
        if (!this.columnController.hasVisibleTableColumn(ColumnTypes.Prioritization)) {
            return;
        }

        const projectTypeIds = Settings.GetProjectTypeIds(this.settingsMap);

        // Prepare data list configurations
        this.columnController.getColumns<PrioritizationColumn>(ColumnTypes.Prioritization)
            .forEach(column => {
                column.getTableColumns<PrioritizationTableColumn>().forEach(tableColumn => {
                    tableColumn.projectListConfiguration
                        .setProjectTypeIds(projectTypeIds)
                        .setDepartment(this.department)
                        .setUseGlobalFilter(true)
                        .setDraggable(true)
                        .setAllowDragEnter(true)
                        .setLimit(ListConfiguration.SmartLimit)
                        .addOnScreenFilters(this.onScreenFilters)
                        .setShowCreateNew(true)
                        .setCreatePresetGenerators([
                            // Defaults
                            new ProjectStatusPresetGenerator(ProjectStatusTypes.Normal, StatusTypes.GREEN),
                            new ProjectUseStatusRulesPresetGenerator(true),
                            new ProjectDepartmentPresetGenerator(this.department.id),
                            new ProjectUserPresetGenerator(ProjectUserTypes.Responsible, this.user.id),

                            new ProjectCategoryPresetGenerator(this.priorityCategories[0].id),
                        ]);

                    // Add sources to individual data fetchers
                    this.projectFetcher.addRequest(new ProjectFetchRequest(tableColumn.projectListConfiguration));
                });
            });
    }

    private setupPhaseColumns() {
        const projectTypeIds = Settings.GetProjectTypeIds(this.settingsMap);

        this.columnController.getColumns<PhaseColumn>(ColumnTypes.Phase)
            .forEach(column => {
                column.getTableColumns<PhaseTableColumn>().forEach(tableColumn => {
                    tableColumn.projectListConfiguration
                        .setProjectTypeIds(projectTypeIds)
                        .setDepartment(this.department)
                        .setCustomAllowCardItemDropFunction((cardItem, fromListConfiguration, result) => {
                            const hasEnteringPhase = cardItem.item.phases_projects?.find(phasesProject => phasesProject.phase_id == column.getFilterPhase());
                            result(Settings.ShouldAddPhaseAtDrop(this.settingsMap) || hasEnteringPhase != null);
                        })
                        .setCustomDragAndDropFunction((cardItem, fromListConfiguration) => {
                            // 1) Hvis tavlen er konfigureret til det, så sæt automatisk fasen, hvis ikke allerede tilføjet
                            if (Settings.ShouldAddPhaseAtDrop(this.settingsMap)) {
                                if (!cardItem.item.phases_projects?.some(phasesProject => phasesProject.phase_id == column.getFilterPhase())) {
                                    const phasesProject = PhasesProject.Create(cardItem.item.id, column.getFilterPhase());
                                    phasesProject.is_current = true; // Sæt samtidig denne til aktiv
                                    const today = new Date();
                                    today.setHours(0, 0, 0, 0);
                                    phasesProject.start = this.Helpers.serverDate(today);

                                    cardItem.item.addPhasesProject(phasesProject, () => {
                                        cardItem.item.setCurrentPhasesProject(phasesProject);
                                    });
                                }
                            }

                            // 2) Sæt fasen som aktiv
                            const currentPhasesProject = cardItem.item.phases_projects.find(phasesProject => phasesProject.phase_id == column.getFilterPhase());
                            if (currentPhasesProject) {
                                cardItem.item.setCurrentPhasesProject(currentPhasesProject);
                            }
                        })
                        .addOnScreenFilters(this.onScreenFilters)
                        .setCreatePresetGenerators([
                            // Defaults
                            new ProjectStatusPresetGenerator(ProjectStatusTypes.Normal, StatusTypes.GREEN),
                            new ProjectUseStatusRulesPresetGenerator(true),
                            new ProjectDepartmentPresetGenerator(this.department.id),
                            new ProjectUserPresetGenerator(ProjectUserTypes.Responsible, this.user.id),

                            new ProjectPhasePresetGenerator(column.getFilterPhase(), undefined, true, undefined),
                        ]);

                    // Add sources to individual data fetchers
                    this.projectFetcher.addRequest(new ProjectFetchRequest(tableColumn.projectListConfiguration));
                });
            });
    }

    private setupSubscriptions() {
        this.subscribe(this.shellFilterGroup.onActiveFilterChangeEventSubscribe(activeDisplayFilter => {
            // Ensure all columns has synchronized active sort item
            this.tableColumns.forEach(column => column.activeSortItem = this.shellPageData.activePageColumnSort);
            this.onColumnSortRenderEventEmitter.emit();
            this.loadData();
        }));
        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(() => this.loadData()));
    }

    // </editor-fold>

}
