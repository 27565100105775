import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {FilterGlobalSettings} from "@app/services/FilterGlobalService/FilterGlobalSettings";
import {Category, ReactionFilter, User} from "@app/core/models";
import {FilterGlobalVisibility} from "@app/services/FilterGlobalService/FilterGlobalVisibility";
import {EmojiItem, EmojiPickerComponent} from "@app/shared/_elements/emoji-picker/emoji-picker.component";
import {ReactionTypes} from "@app/constants";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-filter-globals',
    templateUrl: './filter-globals.component.html',
    styleUrls: ['./filter-globals.component.scss'],
    animations: [
        trigger('simpleFadeAnimation', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [
                style({ opacity: 0 }),
                animate(600)
            ]),
            transition(':leave', animate(600, style({ opacity: 0 })))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FilterGlobalsComponent extends BaseComponent implements OnInit, OnChanges {

    public settings: FilterGlobalSettings;
    public visibility: FilterGlobalVisibility;
    public isAltered: boolean;
    public isDefaultValues: boolean;
    public emojiItems: EmojiItem[];

    constructor(private cd: ChangeDetectorRef,
                private filterGlobalService: FilterGlobalService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(settings => this.render()));
        this.subscribe(this.filterGlobalService.onVisibilityChangeEvent.subscribe(settings => this.render()));
        this.render();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        this.settings = this.filterGlobalService.getActiveSettings();
        // console.log('settings : ', this.settings)
        this.visibility = this.filterGlobalService.getVisibilitySettings();
        this.isAltered = this.filterGlobalService.isSettingsAltered();
        this.isDefaultValues = this.filterGlobalService.isSettingsDefaultValues();
        this.emojiItems = this.settings.activeReactionFilters
            ?.filter(reactionFilter => reactionFilter.reaction_type_id == ReactionTypes.Emoji)
            ?.map(reactionFilter => {
                return {
                    emojiId: reactionFilter.value.replace(/%2B/gi, '+'),
                    userIds: [],
                    count: 0
                } as EmojiItem;
            }) ?? [];
        this.detectChanges();
    }

    // <editor-fold desc="View Helpers">

    public revertFilter() {
        this.filterGlobalService.revertToLastApplied();
    }

    public setCategories(values: Category[]) {
        this.filterGlobalService.setActiveCategories(values);
    }

    public toggleEmojiFilter(value: string) {
        this.toggleReactionFilter(ReactionTypes.Emoji, value);
    }

    public toggleReactionFilter(reactionFilterTypeId: number, value: string) {
        const activeReactionFilters = this.settings.activeReactionFilters;
        const reactionFilter = activeReactionFilters.find(reactionFilter => {
            return reactionFilter.reaction_type_id == reactionFilterTypeId && reactionFilter.value == String(value);
        });
        if (reactionFilter === undefined) { // Add
            activeReactionFilters.push(ReactionFilter.Create(reactionFilterTypeId, value));
        } else { // Remove
            activeReactionFilters.splice(activeReactionFilters.indexOf(reactionFilter), 1);
        }
        this.filterGlobalService.setActiveReactionFilters(activeReactionFilters);
    }

    // </editor-fold>

    userSelected($event: Event) {
        console.log('user-selected : ', $event)

    }

    setUsers(users: User[]) {
        this.filterGlobalService.setActiveUsers(users);
    }

    applyEmojiFilters(emojis: EmojiItem[]) {
        console.log('applyEmojiFilters : ',emojis)
        const reactionFilterTypeId = ReactionTypes.Emoji;

        const activeReactionFilters = this.settings.activeReactionFilters.filter(arf => arf.reaction_type_id != reactionFilterTypeId);

        emojis.forEach(emoji => {
            const value = EmojiPickerComponent.encodeEmoji(emoji.emojiId);
            const reactionFilter = activeReactionFilters.find(reactionFilter => {
                return reactionFilter.reaction_type_id == reactionFilterTypeId && reactionFilter.value == String(value);
            });
            if (reactionFilter === undefined) { // Add
                activeReactionFilters.push(ReactionFilter.Create(reactionFilterTypeId, value));
            } else { // Remove
                activeReactionFilters.splice(activeReactionFilters.indexOf(reactionFilter), 1);
            }
        })

        this.filterGlobalService.setActiveReactionFilters(activeReactionFilters);

    }
}
