import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {CalculatedFieldsMilestone, Milestone, Project, StatusRule, Task} from "@app/core/models";
import {EditorInterface} from "@app/editor/quick-editor/EditorInterface";
import {Field} from "@app/editor/milestone-editor-loader/milestone-editor.service";
import {Fields as MilestoneFields, Fields} from "@app/editor/milestone-editor-loader/Fields";
import {
    EditUserListConfiguration
} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserListConfiguration";
import {UserTypeItem} from "@app/editor/quick-editor/editors/generic/user-list-editor/UserTypeItem";
import {
    EditDeadlineListConfiguration
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineListConfiguration";
import {DeadlineItem} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/DeadlineItem";
import {CONSTANTS, ReactionTypes, StatusRuleTypes} from "@app/constants";
import {
    EditProjectListConfiguration
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListConfiguration";
import {
    EditProjectListFilter
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListFilter";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {EditTextConfiguration} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextConfiguration";
import {
    EditReactionListConfiguration
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionListConfiguration";
import {MilestonesService} from "@app/services/milestones.service";
import {
    EditCategoryPickerListConfiguration
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerListConfiguration";
import {
    CategoryPickerItem
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/CategoryPickerItem";
import {
    EditUseStatusRulesConfiguration
} from "@app/editor/quick-editor/editors/generic/use-status-rules-editor/EditUseStatusRulesConfiguration";
import moment from "moment";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {EditTextValidator} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextValidator";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {ValidatorCollection} from "@app/editor/Validation/ValidatorCollection";
import {EditUserTypeValidator} from "@app/editor/quick-editor/editors/generic/user-list-editor/EditUserTypeValidator";
import {
    EditDeadlineTypeValidator
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineTypeValidator";
import {
    EditProjectListValidator
} from "@app/editor/quick-editor/editors/generic/project-list-editor/EditProjectListValidator";
import {
    EditCategoryPickerValidator
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerValidator";

@Component({
    selector: 'app-milestone-editor-tabs-basic',
    templateUrl: './milestone-editor-tabs-basic-component.html',
    styleUrls: ['./milestone-editor-tabs-basic.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MilestoneEditorTabsBasicComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Milestone;
    @Input() fields: Map<number, Field>;
    @Output() validatorChange = new EventEmitter<ValidationErrorInterface>();

    // Bindings to view
    public isReady = false;
    public editors: EditorInterface[];
    public showCalculatedFields = false;
    public calculatedFields: CalculatedFieldsMilestone[];

    // Bindings to view: Responsible
    public showResponsibleEditor = false;
    public responsibleEditorConfiguration: EditUserListConfiguration;

    // Bindings to view: Deadline
    public showDeadlineEditor = false;
    public deadlineEditorConfiguration: EditDeadlineListConfiguration;

    // Bindings to view: Status rules
    public showStatusRulesEditor = false;
    public useStatusRulesEditorConfiguration: EditUseStatusRulesConfiguration;
    public statusRules: StatusRule[];

    // Bindings to view: Projects
    public showProjectListEditor = false;
    public projectListEditorConfiguration: EditProjectListConfiguration;

    // Bindings to view: Tasks
    public showTaskList = false;
    public taskListConfiguration: TaskListConfiguration;

    // Bindings to view: Notes
    public showNotesTextEditor = false;
    public notesTextEditorConfiguration: EditTextConfiguration;

    // Bindings to view: Risk
    public showRiskTextEditor = false;
    public riskTextEditorConfiguration: EditTextConfiguration;

    // Bindings to view: Emoji
    public showEmojiReactionEditor = false;
    public emojiReactionEditorConfiguration: EditReactionListConfiguration;

    // Bindings to view: Categories
    public showCategoryListEditor = false;
    public categoryListEditorConfiguration: EditCategoryPickerListConfiguration;

    // Data
    private taskCardItems: CardItem<Task>[];
    private validator: ValidationErrorInterface;

    constructor(
        private cd: ChangeDetectorRef,
        private milestonesService: MilestonesService,
        private dialogService: BaseDialogService,
        private translateService: TranslateService,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        StatusRule.GetAll(items => {
            this.statusRules = items.filter(value => value.type === StatusRuleTypes.MilestoneConfiguration);
        });

        this.setupEditors();
        this.setupValidators();
        this.isReady = true;
    }

    private setupEditors() {
        this.showCalculatedFields = this.isFieldVisible(Fields.CalculatedFields)
            && (this.item.calculated_fields_milestones?.length ?? 0) > 0;
        this.calculatedFields = this.item.calculated_fields_milestones ?? [];

        this.showResponsibleEditor = this.isFieldVisible(Fields.Responsible);
        this.responsibleEditorConfiguration = new EditUserListConfiguration(
            '',
            [
                new UserTypeItem(
                    this.fields.get(Fields.Responsible)?.smartName ?? '',
                    0,
                    false,
                    false,
                    false,
                    false,
                    false,
                    new EditUserTypeValidator(this.fields.get(Fields.Responsible)?.required ?? false)
                )
            ]
        );

        this.showDeadlineEditor = this.isFieldVisible(Fields.Deadline);
        const editDeadlineItem = new DeadlineItem(
            this.fields.get(Fields.Deadline)?.smartName ?? '',
            0,
            true,
            new EditDeadlineTypeValidator(this.fields.get(Fields.Deadline)?.required ?? false)
        );
        this.subscribe(editDeadlineItem.onDateChangedEvent.subscribe(event => {
            this.onDeadlineChanged(event.before, event.after);
        }));
        this.deadlineEditorConfiguration = new EditDeadlineListConfiguration('', [editDeadlineItem]);

        this.showStatusRulesEditor = this.isFieldVisible(Fields.StatusRules);
        this.useStatusRulesEditorConfiguration = new EditUseStatusRulesConfiguration();

        this.showProjectListEditor = this.isFieldVisible(Fields.Projects);
        this.projectListEditorConfiguration = new EditProjectListConfiguration(
            this.fields.get(Fields.Projects)?.smartName ?? '',
            new EditProjectListFilter(),
            new EditProjectListValidator(this.fields.get(Fields.Projects)?.required ?? false)
        );
        this.subscribe(this.projectListEditorConfiguration.onProjectAddedEvent.subscribe(project => {
            this.onProjectAdded(project);
        }));
        this.subscribe(this.projectListEditorConfiguration.onProjectRemovedEvent.subscribe(project => {
            this.onProjectRemoved(project);
        }));

        this.showTaskList = this.isFieldVisible(Fields.Projects);
        this.taskListConfiguration = new TaskListConfiguration<Task>();
        this.taskListConfiguration
            .setMilestone(this.item)
            .setOrderBy([
                ['projects_task.index_', 'asc'],
                ['main_status.status_id', 'desc'],
                ['tasks_deadline.deadline.date', 'null'],
                ['tasks_deadline.deadline.date', 'asc'],
                ['title', 'asc']])
            .setCardDisplayModeMini(true)
            .setShowProjectMiniCard(true)
            .setAcceptanceStatus(null)
            .setRequireRelatedMilestone(true)
            .setShowCreateNew(false);

        this.showNotesTextEditor = this.isFieldVisible(Fields.Note);
        this.notesTextEditorConfiguration = new EditTextConfiguration(
            'notes',
            'multi',
            this.fields.get(MilestoneFields.Note).smartName,
            'rich',
            new EditTextValidator(this.fields.get(MilestoneFields.Note).required),
        );

        this.showRiskTextEditor = this.isFieldVisible(Fields.Risk);
        this.riskTextEditorConfiguration = new EditTextConfiguration(
            'risk',
            'multi',
            this.fields.get(MilestoneFields.Risk).smartName,
            'rich',
            new EditTextValidator(this.fields.get(MilestoneFields.Note).required),
        );

        this.milestonesService.getReactionTypes(reactionsTypes => {
            this.showEmojiReactionEditor = reactionsTypes
                ?.find(reactionType => {
                    return reactionType.reaction_type_id == ReactionTypes.Emoji;
                })
                ?.visible;
        });
        this.emojiReactionEditorConfiguration = new EditReactionListConfiguration(
            false,
            false,
            true,
            true,
            false
        );

        this.milestonesService.getCategoryTypesMilestones(categoryTypesMilestones => {
            const categoryTypes = categoryTypesMilestones
                ?.filter(categoryTypesMilestone => categoryTypesMilestone.visible && categoryTypesMilestone.category_type?.exists());
            this.showCategoryListEditor = categoryTypes.length > 0;
            this.categoryListEditorConfiguration = new EditCategoryPickerListConfiguration(
                '',
                categoryTypes.map(categoryType => new CategoryPickerItem(
                    categoryType.category_type.name,
                    categoryType.category_type,
                    true,
                    new EditCategoryPickerValidator(false)
                ))
            );
        });
    }

    private setupValidators() {
        this.validator = new ValidatorCollection([
            this.notesTextEditorConfiguration.validator,
            this.riskTextEditorConfiguration.validator,
            this.responsibleEditorConfiguration.types[0].validator,
            this.deadlineEditorConfiguration.types[0].validator,
            this.projectListEditorConfiguration.validator,
        ]);
        this.validatorChange.emit(this.validator);
    }

    private isFieldVisible(fieldId: number): boolean {
        return this.fields.has(fieldId) && this.fields.get(fieldId).visible;
    }

    // <editor-fold desc="View actions">

    private onDeadlineChanged(before?: Date, after?: Date) {
        if (before && after) {
            const days = moment(after).diff(moment(before), 'days');
            if (days != 0) {
                this.showMoveMilestoneDeadlineDialog(days);
            }
        }
    }

    private onProjectAdded(project: Project) {
        this.showMoveMilestoneTasksDialog(project, CONSTANTS.Actions.Append, cancelled => {
            if (cancelled) {
                this.item.removeProject(project, null);
            }
        });
    }

    private onProjectRemoved(project: Project) {
        this.showMoveMilestoneTasksDialog(project, CONSTANTS.Actions.Delete);
    }

    public onTaskListTasksChanged(items: CardItem<Task>[]) {
        this.taskCardItems = items;
    }

    // </editor-fold>

    // <editor-fold desc="Dialogs">

    private showMoveMilestoneDeadlineDialog(days: number) {
        // Udlæs "følgende milepæle" - og spørg om de også skal skifte dato
        this.dialogService.moveMilestoneDeadline(
            this.translateService.instant(`_milestone_move_deadlines`),
            ``,
            this.translateService.instant('_global_ok'),
            this.translateService.instant('_global_cancel'),
            'lg',
            this.item,
            days
        )
            .then((confirmed) => {
                // Sætter deadline for alle opgaver (sættes i move-milestone-deadline-dialog.component.ts)
                if (confirmed) {
                }
            })
            .catch(() => {
                console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
            });
    }

    private showMoveMilestoneTasksDialog(projectChanged: Project, action: string, callback?: (cancelled: boolean) => void) {
        if (this.taskCardItems) {
            const tasksWithProjects = this.taskCardItems
                .map(card => card.item)
                .filter(task => task.milestones?.filter(milestone => milestone.id == this.item.id))
                .filter(task => {
                    return task.milestones.filter(milestone => milestone.projects?.length > 0).length > 0
                        || task.projects?.length > 0;
                });

            let showDialog = tasksWithProjects.length > 0 && this.item.projects.length > 0;
            if (this.item.projects.length == 1) {
                const project = this.item.projects[0];
                const tasksWithDifferentProjects = tasksWithProjects
                    .filter(task => task.projects && task.projects[0].id != project.id);
                if (tasksWithDifferentProjects.length == 0) {
                    showDialog = false;
                }
            }

            if (showDialog) {
                this.dialogService.moveMilestoneTasksProject(
                    this.translateService.instant(`_ui_move_milestone_tasks_project`),
                    null,
                    this.translateService.instant('_global_ok'),
                    this.translateService.instant('_global_cancel'),
                    'lg',
                    this.item,
                    tasksWithProjects,
                    action == CONSTANTS.Actions.Delete ? projectChanged : undefined,
                    action == CONSTANTS.Actions.Append ? projectChanged : undefined,
                    this.item.projects,
                    action
                )
                    .then(confirmed => {
                        // Patch foregår i move-milestone-tasks-project-dialog
                        if (callback) {
                            callback(confirmed === false);
                        }
                    })
                    .catch(() => {
                        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
                    });
            }
        }
    }

    // </editor-fold>

}
