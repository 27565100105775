import {PageComponent} from "@app/pages/page.component";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import {Filters} from "@app/pages/displays/display-project-details/subdisplay-tasks/Filters";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import {
    TaskDeadlineTypes,
    TaskUserTypes,
} from "@app/constants";
import {Deadline, Project, Task, User} from "@app/core/models";
import {EditTitleConfiguration} from "@app/editor/quick-editor/editors/generic/title-editor/EditTitleConfiguration";
import {TitleEditorComponent} from "@app/editor/quick-editor/editors/generic/title-editor/title-editor.component";
import {EditTextConfiguration} from "@app/editor/quick-editor/editors/generic/text-editor/EditTextConfiguration";
import {TextEditorComponent} from "@app/editor/quick-editor/editors/generic/text-editor/text-editor.component";
import {EditLinkConfiguration} from "@app/editor/quick-editor/editors/generic/link-editor/EditLinkConfiguration";
import {LinkEditorComponent} from "@app/editor/quick-editor/editors/generic/link-editor/link-editor.component";
import {
    EditWhoWhenConfiguration
} from "@app/editor/quick-editor/editors/generic/who-when-editor/EditWhoWhenConfiguration";
import {
    WhoWhenEditorComponent
} from "@app/editor/quick-editor/editors/generic/who-when-editor/who-when-editor.component";
import {ProjectsService} from "@app/services/projects.service";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-tasks/Row";
import {DatatableComponent} from "@swimlane/ngx-datatable";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {
    NonArchivedFilter
} from "@app/pages/displays/display-project-details/subdisplay-tasks/OnScreenFilters/NonArchivedFilter";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {ScreenshotHelper} from "@app/core/ScreenshotHelper/ScreenshotHelper";
import {
    TaskUserPresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskUserPresetGenerator";
import {
    TaskDeadlinePresetGenerator
} from "@app/shared/_ui/create-item-dropdown/Presets/TaskPresets/Generators/TaskDeadlinePresetGenerator";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";
import {TemplateTypes} from "@app/pages/displays/display-project-details/subdisplay-tasks/TemplateTypes";
import {
    GenericTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/GenericTableColumn";
import {DataFetcherCollection} from "@app/core/DataFetchers/DataFetcherCollection";
import {TaskEditColumn} from "@app/pages/displays/display-project-details/subdisplay-tasks/Columns/TaskEditColumn";
import {ColumnTypes} from "@app/pages/displays/display-project-details/subdisplay-tasks/ColumnTypes";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {
    TaskListPeriodColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/Columns/TaskListPeriodColumn";
import {TaskListConfiguration} from "@app/shared/_ui/lists/task-list/TaskListConfiguration";
import {
    TaskListStatusColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/Columns/TaskListStatusColumn";
import {
    TaskListPeriodTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/TaskListPeriodTableColumn";
import {
    TaskListAllColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/Columns/TaskListAllColumn";
import {
    TaskEditColumnType
} from "@app/pages/displays/display-project-details/subdisplay-tasks/ColumnTypes/TaskEditColumnType";
import {
    TaskListStatusColumnType
} from "@app/pages/displays/display-project-details/subdisplay-tasks/ColumnTypes/TaskListStatusColumnType";
import {
    TaskListPeriodColumnType
} from "@app/pages/displays/display-project-details/subdisplay-tasks/ColumnTypes/TaskListPeriodColumnType";
import {
    TaskListAllColumnType
} from "@app/pages/displays/display-project-details/subdisplay-tasks/ColumnTypes/TaskListAllColumnType";
import {
    TaskListAllTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/TaskListAllTableColumn";
import {
    TaskListStatusTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/TaskListStatusTableColumn";
import {
    TaskEditTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/TableColumns/TaskEditTableColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    TaskEdit_Card_TableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/EditorTableColumns/TaskEdit_Card_TableColumn";
import {
    BaseEditorTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-tasks/EditorTableColumns/BaseEditorTableColumn";

@Component({
    selector: 'app-display-project-details-tasks',
    templateUrl: './display-project-details-tasks.component.html',
    styleUrls: ['./display-project-details-tasks.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DisplayProjectDetailsTasksComponent extends PageComponent implements OnInit {
    protected filtersSettings = new Filters();
    protected columnController = new ColumnController();

    // Bindings
    @Input() displayId: number;
    @Input() projectId: number;
    @Input() project: Project;
    @Input() taskEdit_taskDeadlineTypeId: number;
    @Input() taskEdit_taskEstimateTypeId: number;
    @Input() onReloadEventEmitter: EventEmitter<any>;
    @Output() onShellPageDataChangeEvent = new EventEmitter<ShellPageData>();

    // Bindings to view
    public templateTypes = new TemplateTypes();
    public row: Row;
    public tableColumns: GenericTableColumn[];
    public showPeriodColumnWarning: boolean;
    public isMinimized = true;
    public selectedParticipants?: User[];
    public taskListsOnScreenFilters = [new NonArchivedFilter(false)];
    public onStatusColumnSortRenderEventEmitter = new EventEmitter();
    public onPeriodColumnSortRenderEventEmitter = new EventEmitter();
    public dataFetcherCollection = new DataFetcherCollection();

    // UI: DataTable
    @ViewChild('dataTableContainer', {static: false}) dataTableContainerElement: any;
    @ViewChild('dataTable', {static: false}) table: DatatableComponent;
    @ViewChild('headerTemplate', {static: true}) headerTemplate: TemplateRef<any>;
    @ViewChild('headerWithPeriodTemplate', {static: true}) headerWithPeriodTemplate: TemplateRef<any>;
    @ViewChild('headerWithStatusTemplate', {static: true}) headerWithStatusTemplate: TemplateRef<any>;
    @ViewChild('headerTaskEditTemplate', {static: true}) headerTaskEditTemplate: TemplateRef<any>;
    @ViewChild('cellTaskListTemplate', {static: true}) cellTaskListTemplate: TemplateRef<any>;
    @ViewChild('cellTaskEditListTemplate', {static: true}) cellTaskEditListTemplate: TemplateRef<any>;
    @ViewChild('cellTaskListStatusTemplate', {static: true}) cellTaskListStatusTemplate: TemplateRef<any>;
    @ViewChild('cellTaskListPeriodTemplate', {static: true}) cellTaskListPeriodTemplate: TemplateRef<any>;
    @ViewChild('cellTaskEditListWhoWhenHeaderTemplate', {static: true}) cellTaskEditListWhoWhenHeaderTemplate: TemplateRef<any>;

    constructor(private cd: ChangeDetectorRef,
                private projectsService: ProjectsService) {
        super();
        this.cdr = cd;
        this.isMainDisplay = false;
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.onReloadEventEmitter) {
            this.subscribe(this.onReloadEventEmitter.subscribe(() => this.reload()));
        }
    }

    // <editor-fold desc="View Actions">

    public reload() {
        this.loadData();
    }

    public addParticipantToAll(user: User) {
        this.row.taskEditListItems?.forEach(cardItem => {
            if (!cardItem.item.findParticipant(user.id)) {
                cardItem.item.addUser(TaskUserTypes.Participant, user);
            }
        });
    }

    public removeParticipantFromAll(user: User) {
        this.row.taskEditListItems?.forEach(cardItem => {
            if (cardItem.item.findParticipant(user.id)) {
                cardItem.item.removeUser(TaskUserTypes.Participant, user);
            }
        });
    }

    public toggleIsMinimized() {
        this.isMinimized = !this.isMinimized;
        this.columnController.getColumns<TaskEditColumn>(ColumnTypes.TaskEdit).forEach(column => {
            column.enabledEditors.forEach(editor => {
                switch (editor.name) {
                    case TitleEditorComponent.name: // Titel
                        (editor.configuration as EditTitleConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                    case TextEditorComponent.name: // Noter
                        (editor.configuration as EditTextConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                    case LinkEditorComponent.name: // Link
                        (editor.configuration as EditLinkConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                    case WhoWhenEditorComponent.name: // Hvem & Hvornår,
                        (editor.configuration as EditWhoWhenConfiguration).setIsExpanded(!this.isMinimized);
                        break;
                }
            });
        });
    }

    public setRowTaskEditListItems(items: CardItem<Task>[]) {
        this.row.taskEditListItems = items;
        const selectedParticipants = new Map<number, User>();
        this.row.taskEditListItems?.forEach(card => {
            card.item.findTasksUsersByType(TaskUserTypes.Participant)?.forEach(tasksUser => {
                selectedParticipants.set(tasksUser.user_id, tasksUser.user);
            });
        });
        this.selectedParticipants = Array.from(selectedParticipants.values());
    }

    public onCaptureScreenshotBtnClicked() {
        if (this.isCapturingScreenshot) {
            return;
        }
        this.isCapturingScreenshot = true;
        ScreenshotHelper.Capture(this.dataTableContainerElement.nativeElement, () => {
            this.isCapturingScreenshot = false;
            this.detectChanges();
        })
    }

    public onColumnSortChanged(tableColumn: BaseTableColumn) {
        if (tableColumn.column.implementsSorting) {
            const sortableColumn = tableColumn.column as unknown as SortableColumnInterface;
            sortableColumn.applyRowSort(this.row, tableColumn);
        }

        if (tableColumn.column.implementsDataFetching) {
            const column = tableColumn.column as unknown as ColumnDataFetcherInterface;
            column.getDataFetchers().forEach(fetcher => fetcher.execute());
        }

        if (tableColumn instanceof TaskListStatusTableColumn) {
            tableColumn.column.getTableColumns<TaskListStatusTableColumn>().forEach(statusTableColumn => {
                statusTableColumn.activeSortItem = tableColumn.activeSortItem;
                statusTableColumn.activeSortTypeId = tableColumn.activeSortTypeId;
                statusTableColumn.activeSortDirection = tableColumn.activeSortDirection;
            });
            this.onStatusColumnSortRenderEventEmitter.emit();
        }

        if (tableColumn instanceof TaskListPeriodTableColumn) {
            tableColumn.column.getTableColumns<TaskListPeriodTableColumn>().forEach(periodTableColumn => {
                periodTableColumn.activeSortItem = tableColumn.activeSortItem;
                periodTableColumn.activeSortTypeId = tableColumn.activeSortTypeId;
                periodTableColumn.activeSortDirection = tableColumn.activeSortDirection;
            });
            this.onPeriodColumnSortRenderEventEmitter.emit();
        }
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    public loadData() {
        if (!this.project?.project_type || !this.shellFilterGroup.activeFilter) {
            return;
        }

        // Cancel previous load
        this.dataFetcherCollection.cancel();

        // Update columns
        const periodStart = this.shellFilterGroup.activeFilter.getPeriodStart(this.filtersSettings);
        const periodEnd = this.shellFilterGroup.activeFilter.getPeriodEnd(this.filtersSettings);
        this.columnController.getColumns<TaskListPeriodColumn>(ColumnTypes.TaskList_Period).forEach(column => {
            column.setPeriod(periodStart, periodEnd);
        });

        this.columnController.getVisibleColumns()
            .filter(column => column.implementsDataFetching)
            .forEach(column => {
                this.dataFetcherCollection.add((column as unknown as ColumnDataFetcherInterface).getDataFetchers());
            });
        this.dataFetcherCollection.clearFetchers();

        const row = this.createRow();

        this.dataFetcherCollection.execute(() => {
            this.row = row;
            this.detectChanges();
        });
    }

    private createRow(): Row {
        const row = new Row(this.project);

        this.columnController.getAllColumns().forEach(column => {
            row.addCell(column.createCell(row));
        });

        // Used across task columns (status + period + edit)
        const setTaskListFilterConfiguration = (listConfiguration: TaskListConfiguration) => {
            const filter = this.shellFilterGroup.activeFilter;
            switch (filter.filter_type) {
                case Filters.FilterAll:
                    // No filters.
                    break;
                case Filters.FilterWithDeadlineInPeriod:
                    listConfiguration
                        .setTaskDeadlineTypes([filter.task_deadline_type_id])
                        .setDeadlineBetween(
                            filter.getPeriodStart(this.filtersSettings),
                            filter.getPeriodEnd(this.filtersSettings)
                        );
                    break;
                case Filters.FilterWithoutDeadline:
                    listConfiguration
                        .setTaskDeadlineTypes([filter.task_deadline_type_id])
                        .setHasNonDeadline(true);
                    break;
                case Filters.FilterPlannedInPeriod:
                    listConfiguration.setPlannedBetween(
                        filter.getPeriodStart(this.filtersSettings),
                        filter.getPeriodEnd(this.filtersSettings)
                    );
                    break;
                case Filters.FilterWithoutPlanningDate:
                    listConfiguration.setPlanningDeadlineId(0);
                    break;
                case Filters.FilterOpen:
                    listConfiguration.setOpen(true);
                    break;
                case Filters.FilterWithNotes:
                    listConfiguration.setHasNotes(true); // task.delivery_description
                    break;
                case Filters.FilterArchivedInPeriod:
                    listConfiguration.setArchivedBetween(
                        filter.getPeriodStart(this.filtersSettings),
                        filter.getPeriodEnd(this.filtersSettings)
                    );
                    break;
            }
        };

        this.columnController.getColumns<TaskListStatusColumn>(ColumnTypes.TaskList_Status).forEach(column => {
            column.getCell(row).listConfigurations.forEach(listConfiguration => {
                listConfiguration
                    .setCustomAllowCardItemEnterFunction((cardItem, fromListConfiguration) => {
                        // Only allow from fellow taskStatusListConfigurations
                        return this.columnController
                            .getColumns<TaskListStatusColumn>(ColumnTypes.TaskList_Status)
                            .some(column => {
                                return [...column.getCell(this.row).listConfigurations.values()].includes(fromListConfiguration);
                            });
                    })
                    .setOnScreenFilters(this.taskListsOnScreenFilters);
                setTaskListFilterConfiguration(listConfiguration);
            });
        });

        this.columnController.getColumns<TaskListPeriodColumn>(ColumnTypes.TaskList_Period).forEach(column => {
            const cell = column.getCell(row);
            const tableColumns = column.getTableColumns<TaskListPeriodTableColumn>();

            // Configure table columns with deadline
            tableColumns.filter(tableColumn => tableColumn.periodType !== TaskListPeriodColumn.Period_WithoutDeadline)
                .forEach(tableColumn => {
                    const listConfiguration = cell.listConfigurations.get(tableColumn.periodType);
                    listConfiguration
                        .setOnScreenFilters(this.taskListsOnScreenFilters)
                        .setShowCreateNew(this.shellFilterGroup.activeFilter.filter_type != Filters.FilterArchivedInPeriod);


                    // Prepare preset generators for task lists
                    switch (this.shellFilterGroup.activeFilter.filter_type) {
                        case Filters.FilterWithDeadlineInPeriod:
                            listConfiguration.addCreatePresetGenerator(new TaskDeadlinePresetGenerator(
                                TaskDeadlineTypes.Normal,
                                () => tableColumn.getStartDateForTaskPreset(),
                                false
                            ));
                            break;
                        case Filters.FilterPlannedInPeriod:
                            listConfiguration.addCreatePresetGenerator(new TaskUserPresetGenerator(
                                TaskUserTypes.Participant,
                                this.user.id,
                                () => Deadline.Create(tableColumn.getStartDateForTaskPreset(), false),
                            ));
                            break;
                        case Filters.FilterArchivedInPeriod:
                            // Create New is not visible
                            break;
                    }
                });

            // Configure table columns without deadline
            tableColumns
                .filter(tableColumn => tableColumn.periodType == TaskListPeriodColumn.Period_WithoutDeadline)
                .forEach(tableColumn => {
                    const listConfiguration = cell.listConfigurations.get(tableColumn.periodType);
                    listConfiguration.setOnScreenFilters(this.taskListsOnScreenFilters);
                });

            cell.listConfigurations.forEach(listConfiguration => {
                setTaskListFilterConfiguration(listConfiguration);
            });

            const filter = this.shellFilterGroup.activeFilter;
            tableColumns
                .forEach(tableColumn => {
                    const listConfiguration = cell.listConfigurations.get(tableColumn.periodType);

                    switch (filter.filter_type) {
                        case Filters.FilterWithDeadlineInPeriod:
                            listConfiguration
                                .setTaskDeadlineTypes([filter.task_deadline_type_id])
                                .setDeadlineBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                        case Filters.FilterPlannedInPeriod:
                            listConfiguration.setPlannedBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                        case Filters.FilterArchivedInPeriod:
                            listConfiguration
                                .setArchivedBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                        default:
                            listConfiguration
                                .setDeadlineValidator(task => false) // We can't show tasks if filter does not specify period
                                .setDeadlineBetween(tableColumn.getStartDate(), tableColumn.getEndDate());
                            break;
                    }
                });
        });

        this.columnController.getColumns<TaskListAllColumn>(ColumnTypes.TaskList_All).forEach(column => {
            const listConfiguration = column.getCell(row).listConfiguration;
            listConfiguration
                .setOnScreenFilters(this.taskListsOnScreenFilters);
            setTaskListFilterConfiguration(listConfiguration);
        });

        this.columnController.getColumns<TaskEditColumn>(ColumnTypes.TaskEdit).forEach(column => {
            const listConfiguration = column.getCell(row).listConfiguration;
            listConfiguration
                .setIsSortingEnabled(this.shellFilterGroup.activeFilter.sort_type == Filters.SortUserDefined)
                .setOnScreenFilters(this.taskListsOnScreenFilters);

            setTaskListFilterConfiguration(listConfiguration);
        });

        return row;
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    protected initializeShellPageData() {
        this.shellPageData.displayFilterExtra = JSON.stringify({
            projectId: this.projectId
        });
    }

    protected onAfterDisplay() {
        super.onAfterDisplay();

        this.filtersSettings.getEditorConfig(this.display, config => {

            this.columnController.addColumnTypes([
                new TaskEditColumnType(
                    this.shellPageData,
                    column => this.onColumnSortChanged(column),
                    this.cellTaskEditListWhoWhenHeaderTemplate,
                    this.isMinimized,
                    config
                ),
                new TaskListStatusColumnType(this.cellTaskListStatusTemplate, this.headerWithStatusTemplate),
                new TaskListPeriodColumnType(this.cellTaskListPeriodTemplate, this.headerWithPeriodTemplate),
                new TaskListAllColumnType(this.cellTaskListTemplate, this.headerTemplate),
            ]);

            this.columnController.loadColumns(this.displayId, () => {

                this.initialize();
            });
        });
    }

    private initialize() {
        this.subscribe(this.columnController.onTableColumnVisibilityChanged.subscribe(event => {
            let tableColumns = this.columnController.getVisibleTableColumns() as GenericTableColumn[];

            // Combine TaskEdit-table columns into one single table column
            this.columnController.getColumns<TaskEditColumn>(ColumnTypes.TaskEdit).forEach(column => {
                if (column.isVisible) {
                    const firstTaskEditTableColumn = tableColumns.findIndex(tableColumn => tableColumn.column == column);
                    const taskEditTableColumn = new TaskEditTableColumn(column, this.cellTaskEditListTemplate, this.headerTaskEditTemplate)
                    tableColumns[firstTaskEditTableColumn] = taskEditTableColumn;
                    tableColumns = tableColumns.filter(tableColumn => !column.getTableColumns().includes(tableColumn));

                    column.updateEnabledEditors();

                    // Update this table column minWidth
                    let minWidth = 0;
                    column.getVisibleTableColumns<BaseEditorTableColumn>()
                        .forEach(editor => minWidth += editor.columnConfiguration.minWidth ?? 0);
                    taskEditTableColumn.minWidth = minWidth;

                    if (column.getVisibleTableColumns().some(tableColumn => tableColumn instanceof TaskEdit_Card_TableColumn)) {
                        // Auto-expand
                        if (this.isMinimized) {
                            this.toggleIsMinimized();
                        }
                    }
                }
            });

            this.tableColumns = tableColumns;

            this.loadData();
        }));

        this.onShellPageDataChangeEvent.emit(this.shellPageData);

        this.subscribe(this.filterGlobalService.onSettingsChangeEvent.subscribe(() => this.loadData()));

        this.setupColumnSortItems();

        this.initialized = true;

        this.projectsService.getProjectType(this.project.project_type_id, projectType => {
            this.project.project_type = projectType;
            this.loadData();
        });
    }

    private setupColumnSortItems() {
        this.filtersSettings.getEditorConfig(this.display, config => {

            this.columnController.getColumns<TaskListAllColumn>(ColumnTypes.TaskList_All).forEach(column => {
                column.getTableColumns<TaskListAllTableColumn>().forEach(tableColumn => {
                    tableColumn.sortItems.push(
                        ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                            Filters.SortCategoryTypeGenerator(categoryType)
                        )),
                        ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                            Filters.SortDeadlineWithGenerator(deadlineType)
                        )),
                    );
                });
            });

            this.columnController.getColumns<TaskListPeriodColumn>(ColumnTypes.TaskList_Period).forEach(column => {
                column.getTableColumns<TaskListPeriodTableColumn>().forEach(tableColumn => {
                    switch (tableColumn.periodType) {
                        case TaskListPeriodColumn.Period_WithoutDeadline:
                            tableColumn.sortItems.push(
                                ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                                    Filters.SortCategoryTypeGenerator(categoryType)
                                )),
                            );
                            break;
                        default:
                            tableColumn.sortItems.push(
                                ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                                    Filters.SortCategoryTypeGenerator(categoryType)
                                )),
                                ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                                    Filters.SortDeadlineWithGenerator(deadlineType)
                                )),
                            );
                            break;
                    }
                });
            });

            this.columnController.getColumns<TaskListStatusColumn>(ColumnTypes.TaskList_Status).forEach(column => {
                column.getTableColumns<TaskListStatusTableColumn>().forEach(tableColumn => {
                    tableColumn.sortItems.push(
                        ...config.categoryTypes.map(categoryType => PageColumnSort.CreateWithSortId(
                            Filters.SortCategoryTypeGenerator(categoryType)
                        )),
                        ...config.taskDeadlineTypes.map(deadlineType => PageColumnSort.CreateWithSortId(
                            Filters.SortDeadlineWithGenerator(deadlineType)
                        )),
                    );
                });
            });
        });
    }

    // </editor-fold>

}
