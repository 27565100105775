import {AppInjector} from "@app/services/app-injector.service";
import {NgxCaptureService} from "ngx-capture";
import {tap} from "rxjs/operators";
import moment from "moment";
import Helpers from "@app/core/helpers";
import * as FileSaver from "file-saver";

export class ScreenshotHelper {

    public static Capture(hostElem: any, onFinish: () => void, options?: any) {

        let targetElement = hostElem.querySelector('.datatable-scroll') || hostElem;
        if(options && options.selector){
            targetElement = options.selector;
        }
        console.log('Capture : ', targetElement);

        setTimeout(() => {
            AppInjector.getInjector().get(NgxCaptureService).getImage(targetElement, true)
                .pipe(
                    tap(img => {
                        const fileName = (moment().format("DD-MMM-YYYY-HH:mm:ss")) + '.png';
                        let file = Helpers.convertBase64ToFile(img, fileName);
                        FileSaver.saveAs(file, fileName);
                        onFinish();
                    })
                ).subscribe();
        }, 500);
    }

}
