import {EventEmitter, Injectable} from '@angular/core';
import {OAuthService, OAuthStorage} from "angular-oauth2-oidc";
import {Router} from "@angular/router";
import _ from "lodash";
import Helpers from "@app/core/helpers";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private readonly storage?: OAuthStorage;

    public onNewAccessTokenReceived = new EventEmitter();

    constructor(
        private oauthService: OAuthService,
        private router: Router,
    ) {
        this.oauthService.configure({
            // Url of the Identity Provider
            issuer: Helpers.apiUrl('oauth'),

            // URL of the SPA to redirect the user to after login
            redirectUri: window.location.origin + '/login',
            postLogoutRedirectUri: window.location.origin,

            // The SPA's id. The SPA is registered with this id at the auth-server
            clientId: 'klartboard-frontend',

            responseType: 'code',

            // set the scope for the permissions the client should request
            scope: 'openid profile offline_access rabbitmq.read:realm1/*/* rabbitmq.write:realm1/*/* rabbitmq.configure:realm1/*/*',
            oidc: true,

            showDebugInformation: true,

            requireHttps: false,
        });

        this.storage = sessionStorage;

        this.oauthService.events.subscribe(event => {
            switch (event.type) {
                case "token_received":
                    this.onNewAccessTokenReceived.emit();
                    break;
            }
        });
    }

    private loadDiscoveryDocument(): Promise<void> {
        return this.oauthService.loadDiscoveryDocument()
            .then(() => {
                // Overwrite token endpoint with oauth agent
                this.oauthService.tokenEndpoint = Helpers.apiUrl('oauth/agents/frontend/token');
            });
    }

    public authReturn(onSuccess: (url: string) => void, onFailure: (message: string) => void) {
        this.loadDiscoveryDocument()

            // 1. Check for code in url. Handles auth/return scenario
            .then(() => {
                return this.oauthService.tryLoginCodeFlow();
            })

            .then(() => {
                if (this.oauthService.hasValidAccessToken()) {
                    // 2. Stop here if already logged in
                    const loginRedirectRoute = this.storage?.getItem('loginRedirectRoute') ?? '/';
                    this.storage?.removeItem('loginRedirectRoute');
                    onSuccess(loginRedirectRoute);
                } else {
                    onFailure('');
                }
                return Promise.resolve();
            })

            // Something went wrong...
            .catch(reason => {
                console.warn(reason);
                onFailure(reason?.params?.error ?? reason?.type ?? 'unknown error');
            });
    }

    public login(redirectRoute: string, scope: string, allowRefresh: boolean): Promise<boolean> {
        return this.loadDiscoveryDocument()

            // 1. Try refresh token
            .then(() => {
                if (allowRefresh) {
                    return this.refreshToken()
                } else {
                    return Promise.resolve();
                }
            })

            .then(() => {
                // 2. Check if refresh token worked
                if (this.oauthService.hasValidAccessToken()) {
                    return Promise.resolve(true);
                }

                // 3. Initiate code flow
                this.storage?.setItem('loginRedirectRoute', redirectRoute);
                const defaultScopes = this.oauthService.scope?.split(' ') ?? [];
                const addScopes = scope.length ? scope.split(' ') : [];
                this.oauthService.scope = _.union(defaultScopes, addScopes).join(' ');
                this.oauthService.initCodeFlow();

                return Promise.resolve(false);
            })

            // Something went wrong...
            .catch(reason => {
                console.warn(reason);
                this.router.navigate(['/error']).then(r => {
                });
                return Promise.resolve(false);
            });
    }

    public logout(redirectUri?: string) {
        this.loadDiscoveryDocument()
            .then(() => {
                this.oauthService.logOut(true);
                const authUrl = Helpers.apiUrl("/oauth/agents/frontend/endsession");
                window.location.href = `${authUrl}?post_logout_redirect_uri=${redirectUri ?? window.origin}`;
            });
    }

    public isUserLoggedIn(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    public async navigateToUserFrontpage() {
        await this.router.navigate(['/app/home/dashboard']);
    }

    public hasValidAccessToken(): boolean {
        if (this.oauthService.hasValidAccessToken()) {
            const expiresAt = this.storage!.getItem('expires_at');
            const now = new Date();
            return !(expiresAt && parseInt(expiresAt, 10) < now.getTime());
        } else {
            return false;
        }
    }

    private isTryingRefresh = false;
    private refreshQuery: ((value: boolean) => void)[] = [];

    public lockAndRefreshToken(callback: (value: boolean) => void): void {
        if (this.isTryingRefresh) {
            // Wait while other request finishes refresh
            this.refreshQuery.push(callback);
        } else {
            this.isTryingRefresh = true;
            this.refreshToken()
                .then(() => {
                    const tryingRefreshResult = this.oauthService.hasValidAccessToken();
                    this.isTryingRefresh = false;
                    callback(tryingRefreshResult);
                    this.refreshQuery.forEach(item => item(tryingRefreshResult));
                })
                .catch(() => {
                    this.isTryingRefresh = false;
                    callback(false);
                    this.refreshQuery.forEach(item => item(false));
                });
        }
    }

    public async refreshToken(): Promise<void> {
        const rawResponse = await fetch(Helpers.apiUrl('oauth/agents/frontend/refresh'), {
            method: 'POST',
            body: JSON.stringify({
                client_id: this.oauthService.clientId!,
                grant_type: 'refresh_token',
                scope: (this.oauthService.getGrantedScopes() as string[])?.join(' ') ?? '',
            }),
            credentials: 'include',
        });
        const content = await rawResponse.json();
        if (content && content.access_token) {
            if (this.storage) {
                this.storage.setItem('access_token', content.access_token);
                this.storage.setItem('access_token_stored_at', '' + Date.now());
                if (content.expires_in) {
                    const expiresInMilliSeconds = content.expires_in * 1000;
                    const now = new Date();
                    const expiresAt = now.getTime() + expiresInMilliSeconds;
                    this.storage.setItem('expires_at', '' + expiresAt);
                }
                if (content.id_token) {
                    const idToken = await this.oauthService.processIdToken(content.id_token, content.access_token, true);
                    this.storage.setItem('id_token', idToken.idToken);
                    this.storage.setItem('id_token_claims_obj', idToken.idTokenClaimsJson);
                    this.storage.setItem('id_token_expires_at', '' + idToken.idTokenExpiresAt);
                    this.storage.setItem('id_token_stored_at', '' + Date.now());
                }
            }
        } else {
            if (this.storage) {
                this.storage.removeItem('access_token');
                this.storage.removeItem('access_token_stored_at');
                this.storage.removeItem('expires_at');
            }
        }
    }

}
