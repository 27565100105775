import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild,} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {ProjectType} from '@app/core/models/ProjectType';
import {TaskType} from '@app/core/models/TaskType';
import Globals, {Tabs} from '@app/constants';
import {Todo} from '@app/core/models/Todo';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {CreateItemSourceConfiguration} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration';
import {SnackbarService} from '@app/services/snackbar.service';
import {NgbDropdown, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CreateItemPreset} from '@app/shared/_ui/create-item-dropdown/CreateItemPreset';
import {Appointment, Department, Milestone, Project, ProjectStatus, ProjectsUser, Task} from '@app/core/models';
import {AppointmentDialogComponent} from '@app/shared/_modals/appointment-dialog/appointment-dialog.component';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {ProjectsService} from '@app/services/projects.service';
import {ProjectEditorService} from '@app/editor/project-editor-loader/project-editor.service';
import {AppInjector} from '@app/services/app-injector.service';
import {ShellService} from '@app/services/ShellService/shell.service';
import {ProjectUserTypes} from '@app/core/models/Project';
import {UsersService} from '@app/services/users.service';

@Component({
    selector: 'app-create-item-dropdown',
    templateUrl: './create-item-dropdown.component.html',
    styleUrls: ['./create-item-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CreateItemDropdownComponent extends BaseDisplayComponent implements OnInit {

    @ViewChild('dropdown', {static: false, read: NgbDropdown}) dropdownMenu: NgbDropdown;

    @Input() source!: CreateItemSourceConfiguration;
    @Input() createItemPreset!: CreateItemPreset;
    @Input() container: 'body' | null;
    @Input() placement: string = 'bottom';
    @Input() iconClass: string = 'fal fa-plus-circle';
    @Input() disabled: boolean = false;
    @Input() buttonMode: boolean = false;
    @Input() label: string;
    public dropdown = true;
    public enableDropdown = false;
    public hidden = false;
    public department: Department;

    public taskTypes: TaskType[];
    public projectTypes: ProjectType[];
    public typesLoading: number = 0;

    projectTypesFieldsMap: Map<number, any> = new Map();

    constructor(private editorPanelService: EditorPanelService,
                private snackbar: SnackbarService,
                private projectsService: ProjectsService,
                private projectEditorService: ProjectEditorService,
                private modalService: NgbModal,
                private dialogService: BaseDialogService,
                private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (!this.label) {
            this.label = this.translateService.instant('_global_create') + '...';
        }
    }

    projectTypeFieldVisible(type: ProjectType, fieldId: number): boolean {
        return this.projectTypesFieldsMap?.get(type.id)?.get(fieldId)?.visible ?? false;
    }

    setupProjectTypes() {
        this.projectTypes.forEach((pt) => {
            this.projectEditorService.getEditorFieldsForType(pt.id, (fields) => {
                this.projectTypesFieldsMap.set(pt.id, fields);
                this.detectChanges();
            })
        })
    }

    public onOpenEvent($event: MouseEvent) {
        this.hidden = true;
        this.source.sourceInterface?.prepareSource();

        this.getTypes();
        this.isDropdownAvailable();
    }

    private getTypes(){
        this.typesLoading = 0;

        const pageSettings = AppInjector.getInjector().get(ShellService).getPageSettings();

        this.typesLoading++;
        Department.GetById(pageSettings?.departmentId, (department)=>{
            this.department = department;
            this.typesLoading--;
            this.isDropdownAvailable();
            this.detectChanges();
        })

        if (!this.taskTypes && this.source.showTasks) {
            this.typesLoading++;
            this.source.getTaskTypes(types => {
                this.typesLoading--;
                this.taskTypes = types;
                this.isDropdownAvailable();
                this.detectChanges();
            });
        }
        if (!this.projectTypes && this.source.showProjects) {
            this.typesLoading++;
            this.source.getProjectTypes(types => {
                this.typesLoading--;
                this.projectTypes = types;

                this.setupProjectTypes();
                this.isDropdownAvailable();
                this.detectChanges();
            });
        }
        this.detectChanges();
    }

    private dropdownTimer: number;
    public isDropdownAvailable() {
        clearTimeout(this.dropdownTimer)
        this.dropdownTimer = window.setTimeout(() => {
            if (this.typesLoading == 0) {
                this.openSingular();
                this.detectChanges();
            }
        }, 50);
    }

    public openSingular() {
        this.hidden = false;

        const showMilestone = this.source.showMilestone && this.department?.milestones_visible;
        const showAppointment = this.source.showAppointments;
        const showTodo = this.source.showTodo && this.department?.todos_visible;
        const showTask = this.source.showTasks && this.taskTypes?.length > 0;
        const showProject = this.source.showProjects && this.projectTypes?.length > 0;
        const countTrues = [showMilestone, showAppointment, showTodo, showTask, showProject].filter(value => value).length;

        if (countTrues == 1) {
            if (showMilestone) {
                this.enableDropdown = false;
                this.createItem(Milestone.name);
            }
            if (showAppointment) {
                this.enableDropdown = false;
                this.createItem(Appointment.name);
            }
            if (showTodo) {
                this.enableDropdown = false;
                this.createItem(Todo.name);
            }
            if (showTask) {
                this.enableDropdown = this.taskTypes?.length > 1;
                if(this.taskTypes.length == 1)
                    this.createItem(Task.name, this.taskTypes[0].id);
            }
            if (showProject) {
                this.enableDropdown = this.projectTypes?.length > 1;
                if(this.projectTypes.length == 1)
                    this.createItem(Project.name, this.projectTypes[0].id);
            }
        } else if (countTrues == 0) {
            this.toastError();
        }else{
            this.enableDropdown = true;
        }
    }

    private toastError() {
        this.snackbar.add(`Ups! Vi kunne ikke finde nogle projekttyper eller opgavetyper til opretelse. Findes dette forløb eller opgave på teamet?`, null, {duration: 6000});
        this.hidden = true;
        this.disabled = true;
        this.enableDropdown = false;
    }

    public closeMenu() {
        this.dropdownMenu.close();
    }

    public createItem(type: string, category?: number, extra?: string) {
        setTimeout(() => {
            this.dropdownMenu.close();
        }, 300)
        switch (type) {
            case Project.name:
                Project.Create(
                    category,
                    this.createItemPreset?.createProjectInterface?.createPresets(category) ?? [],
                    project => {
                        if (extra) {
                            switch (extra) {
                                case 'template':
                                    // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1164
                                    this.dialogService.createProjectRedirectTemplateDialog(project).then((response) => {
                                    }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
                                    break
                                case 'planning':
                                    // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1244
                                    this.dialogService.createProjectRedirectPlanningDialog(project).then((response) => {
                                    }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
                                    break
                                default:
                                    console.warn('Unknown extra option : ', extra)
                                    break;
                            }
                        } else {
                            this.editorPanelService.open(project, {
                                selectedTab: Tabs.Basic,
                                reload: false,
                            });
                        }
                    }
                );
                break;

            case Task.name:
                Task.Create(
                    category,
                    this.createItemPreset?.createTaskInterface?.createPresets(category) ?? [],
                    task => {
                        if (this.source.showEditor) {
                            this.editorPanelService.open(task, {reload: false});
                        }
                    }
                );
                break;

            case Todo.name:
                Todo.Create(
                    this.createItemPreset?.createTodoInterface?.createPresets(category) ?? [],
                    todo => {
                        if (this.source.showEditor) {
                            this.editorPanelService.open(todo, {reload: false});
                        }
                    }
                );
                break;

            case Milestone.name:
                Milestone.Create(
                    this.createItemPreset?.createMilestoneInterface?.createPresets() ?? [],
                    milestone => {
                        if (this.source.showEditor) {
                            this.editorPanelService.open(milestone, {reload: false});
                        }
                    }
                );
                break;

            case Appointment.name:
                const appointment = this.createItemPreset?.createAppointmentInterface?.createItem() ?? new Appointment();
                if (this.source.showEditor) {
                    const modalRef = this.modalService.open(
                        AppointmentDialogComponent,
                        {
                            size: 'lg',
                            windowClass: 'modal-holder',
                            centered: true,
                            backdrop: false
                        });
                    (modalRef.componentInstance as AppointmentDialogComponent).model = appointment;
                } else {
                    appointment.post();
                }
                break;

            default:
                console.warn('Unknown type : ', type);
                break;
        }
    }

    protected readonly Task = Task;
    protected readonly Project = Project;
    protected readonly Milestone = Milestone;
    protected readonly Todo = Todo;
}
