import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {Project, ProjectType} from "@app/core/models";
import {Field} from "@app/editor/project-editor-loader/project-editor.service";
import {ValidationErrorInterface} from "@app/editor/Validation/ValidationErrorInterface";
import {ValidatorCollection} from "@app/editor/Validation/ValidatorCollection";
import {
    EditReactionListConfiguration
} from "@app/editor/quick-editor/editors/generic/reaction-list-editor/EditReactionListConfiguration";
import {
    EditCategoryPickerListConfiguration
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerListConfiguration";
import {
    CategoryPickerItem
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/CategoryPickerItem";
import {
    EditCategoryPickerValidator
} from "@app/editor/quick-editor/editors/generic/category-picker-list-editor/EditCategoryPickerValidator";

@Component({
    selector: 'app-project-editor-tabs-categories',
    templateUrl: './project-editor-tabs-categories-component.html',
    styleUrls: ['./project-editor-tabs-categories.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectEditorTabsCategoriesComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() item: Project;
    @Input() projectType: ProjectType;
    @Input() fields: Map<number, Field>;
    @Input() isSelectedTab: boolean;
    @Output() validatorChange = new EventEmitter<ValidationErrorInterface>();

    // Bindings to view
    public isReady = false;

    // Bindings to view: Emoji
    public showEmojiReactionListEditor = false;
    public emojiReactionListEditorConfiguration: EditReactionListConfiguration;

    // Bindings to view: Categories
    public showCategoryPickerListEditor = false;
    public categoryPickerListEditorConfiguration: EditCategoryPickerListConfiguration;

    // Data
    private validator: ValidationErrorInterface;

    constructor(
        private cd: ChangeDetectorRef,
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.setupEditors();
        this.setupValidator();
    }

    private setupEditors() {
        this.showEmojiReactionListEditor = true;
        this.emojiReactionListEditorConfiguration = new EditReactionListConfiguration(
            false,
            false,
            true,
            true,
            false
        );

        this.showCategoryPickerListEditor = true;
        this.categoryPickerListEditorConfiguration = new EditCategoryPickerListConfiguration(
            '',
            this.projectType.category_types_project_types
                ?.filter(categoryTypesProjectType => categoryTypesProjectType.visible && categoryTypesProjectType.category_type?.exists())
                ?.map(categoryTypesProjectType => new CategoryPickerItem(
                    categoryTypesProjectType.category_type.name,
                    categoryTypesProjectType.category_type,
                    true,
                    new EditCategoryPickerValidator(categoryTypesProjectType.required)
                )) ?? []
        );
    }

    private setupValidator() {
        // Collect validators
        this.validator = new ValidatorCollection([
            ...this.categoryPickerListEditorConfiguration.types.map(type => type.validator),
        ]);
        this.validatorChange.emit(this.validator);

        this.isReady = true;
    }

    private isFieldVisible(fieldId: number): boolean {
        return this.fields.has(fieldId) && this.fields.get(fieldId).visible;
    }

    // <editor-fold desc="View actions">

    // </editor-fold>

    // <editor-fold desc="Dialogs">

    // </editor-fold>

}
