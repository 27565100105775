import {Task} from '@app/core/models/Task';
import {Subscription} from 'rxjs';
import {EventEmitter} from '@angular/core';
import {TaskUserTypes} from '@app/constants';
import Helpers from '../../../../core/helpers';
import {TaskListConfiguration} from '@app/shared/_ui/lists/task-list/TaskListConfiguration';
import {Project} from '@app/core/models/Project';
import {AppInjector} from "@app/services/app-injector.service";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {SortItem} from "@app/shared/_ui/lists/SortItem";
import {TitleComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/TitleComparison";
import {DeadlineComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/DeadlineComparison";
import {StatusComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/StatusComparison";
import {MilestoneTaskComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/MilestoneTaskComparison";
import {Api} from "@app/core/Api";
import {Category} from '@app/core/models';
import {EmitDebounce} from "@app/core/DataFetchers/EmitDebounce";
import {
    MultiLoaderFetcherInterface
} from "@app/shared/_ui/displays/display-multi-loader/Helpers/MultiLoaderFetcherInterface";
import {NullDeadlineComparison} from "@app/shared/_ui/lists/multi-list/Comparisons/NullDeadlineComparison";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";

export class TaskFetchRequest {

    public configuration: TaskListConfiguration;
    private onTasksFetchedEvent = new EventEmitter<Task[]>();
    private onCountFetchedEvent = new EventEmitter<number>();

    // Data
    private result: Task[] = [];
    private count: number = 0;
    private lastEmittedItems?: Task[];
    private lastEmittedCount?: number;

    public constructor(configuration: TaskListConfiguration) {
        this.configuration = configuration;
        this.configuration.setDataSource(this);

        this.configuration.onAddItemEvent.subscribe(value => {
            const item = value.item.item as Task;

            // Add to result if not already there
            if (this.result.find(localItem => localItem.id == item.id) === undefined) {
                this.result.push(item);
            }

            // Add to last emitted items at right position
            if (this.lastEmittedItems?.find(localItem => localItem.id == item.id) === undefined) {
                if (value.index !== undefined) {
                    this.lastEmittedItems?.splice(value.index, 0, item);
                } else {
                    this.lastEmittedItems?.push(item);
                }
                this.lastEmittedCount = this.lastEmittedItems?.length;
            }
        });

        this.configuration.onRemoveItemEvent.subscribe(value => {
            const item = value.item.item as Task;

            // Remove from result if not already there
            let index = this.result?.findIndex(localItem => localItem.id == item.id);
            if (index !== -1) {
                this.result.splice(index, 1);
            }

            // Remove from last emitted items at right position
            index = this.lastEmittedItems?.findIndex(localItem => localItem.id == item.id);
            if (index !== -1 && this.lastEmittedItems) {
                this.lastEmittedItems.splice(index, 1);
                this.lastEmittedCount = this.lastEmittedItems.length;
            }
        });
    }

    public reset() {
        this.result = [];
        this.count = 0;
    }

    public addItem(item: Task) {
        this.result.push(item);
        this.count++;
    }

    public getItemsById(id: number): Task[] {
        return this.result?.filter(item => item.id == id) ?? [];
    }

    public subscribeToItems(next: (items: Task[]) => void): Subscription {
        const subscription = this.onTasksFetchedEvent.subscribe(next);
        if (this.lastEmittedItems) {
            next(this.lastEmittedItems);
        }
        return subscription;
    }

    public subscribeToCount(next: (count: number) => void): Subscription {
        const subscription = this.onCountFetchedEvent.subscribe(next);
        if (this.lastEmittedCount) {
            next(this.lastEmittedCount);
        }
        return subscription;
    }

    public cleanupAndEmitWhenReady(showAll: boolean, debounce: EmitDebounce): any {
        this.applyOrdering();

        this.configuration.onScreenFilterChange.subscribe(() => this.emitSlice(showAll));

        // The limit can change over time (cause of screen resize). Keep listening for new limits and return slices.
        this.configuration.onLimitSetEvent.subscribe((limit: number) => {
            debounce.emit(() => this.emitSlice(showAll));
        });

        if (this.configuration.hasSmartLimit()) {
            // Wait for SmartLimit.
        } else {
            this.emitSlice(showAll);
        }
    }

    private applyOrdering() {
        const sortItems: SortItem[] = this.configuration.getOrderBy()?.map(orderBy => {
            switch (orderBy[0]) {
                case 'milestones_task.index_':
                    return new SortItem(new MilestoneTaskComparison(), orderBy[1]);
                case 'main_status.status_id':
                    return new SortItem(new StatusComparison(), orderBy[1]);
                case 'tasks_deadline.deadline.date':
                    switch (orderBy[1]) {
                        case 'null':
                            return new SortItem(new NullDeadlineComparison(), orderBy[1]);
                        default:
                            return new SortItem(new DeadlineComparison(), orderBy[1]);
                    }
                case 'title':
                    return new SortItem(new TitleComparison(), orderBy[1]);
                default:
                    return null;
            }
        })?.filter(item => item !== null) ?? [];

        this.result.sort((a, b) => {
            let value = 0;
            sortItems?.find(sortItem => {
                value = sortItem.comparison.compare(
                    sortItem.direction == 'asc' ? a : b,
                    sortItem.direction == 'asc' ? b : a
                );
                return value !== 0;
            });
            return value;
        });
    }

    private emitSlice(showAll: boolean) {
        // Apply on screen filters
        const filteredItems = this.result.filter(item => {
            return this.configuration.onScreenFilters.find(filter => !filter.isValid(item)) === undefined;
        });

        // Slice according to requested limit
        const sliceOfItems = this.getSlicedResult(filteredItems, showAll);

        // Emit to ListConfiguration
        this.emitItems(sliceOfItems);
        this.emitCount(filteredItems.length);
    }

    private getSlicedResult(items: Task[], showAll: boolean) {
        if (showAll) {
            return items;
        } else {
            const limit = Math.max(this.configuration.getLastItemCount() ?? 0, this.configuration.getLimit() ?? 0);
            if (limit > 0) {
                return items.slice(0, limit);
            } else {
                return items;
            }
        }
    }

    public clear() {
        this.configuration.setLastItemCount(0);
        this.result = [];
        this.count = 0;
    }

    // Called from TaskList to request "ShowMore/ShowAll"
    public requestSlice(offset: number, limit: number) {
        // Apply on screen filters
        const filteredItems = this.result.filter(item => {
            return this.configuration.onScreenFilters.find(filter => !filter.isValid(item)) === undefined;
        });

        // Slice according to requested offset and limit
        const sliceOfItems = filteredItems.slice(offset, limit);

        // Emit to ListConfiguration
        this.emitItems(sliceOfItems);
        this.emitCount(filteredItems.length);
    }

    private emitItems(items: Task[]) {
        this.lastEmittedItems = items;
        this.onTasksFetchedEvent.emit(items);
    }

    private emitCount(value: number) {
        this.lastEmittedCount = value;
        this.onCountFetchedEvent.emit(value);
    }

    public getLastEmittedItems(): Task[] {
        return this.lastEmittedItems;
    }

    public getAllItems(): Task[] {
        return this.result;
    }

}

export class TaskFetcher extends BaseFetcher implements MultiLoaderFetcherInterface {

    private requests: TaskFetchRequest[] = [];
    private api?: ApiRequest;
    public showAll = false;

    // Global scope
    private start: Date;
    private end: Date;
    private userIds: number[] = [];
    private milestoneIds: number[] = [];
    private projectIds: number[] = [];
    private taskTypeIds: number[];
    private mainStatusIds: number[];
    private activeCategories: Category[] = [];

    public constructor(start?: Date, end?: Date, activeCategories?: Category[], name?: string) {
        super(name ?? '');
        this.start = start;
        this.end = end;
        this.activeCategories = activeCategories;
    }

    public clear(): void {
        this.requests = [];
    }

    public addRequest(request: TaskFetchRequest) {
        this.requests.push(request);
        request.reset();
    }

    public setPeriod(start: Date, end: Date) {
        this.start = start;
        this.end = end;
    }

    public setCategories(categories?: Category[]){
        this.activeCategories = categories;
        return this;
    }

    public executeForPeriod(start: Date, end: Date) {
        // Set global scope
        this.start = start;
        this.end = end;

        // Execute!
        this.execute();
    }

    public executeForMainStatusIds(statusIds: number[]) {
        this.mainStatusIds = statusIds;
        this.execute();
    }

    public setMainStatusIds(value: number[]) {
        this.mainStatusIds = value;
    }

    public setTaskTypeIds(value: number[]) {
        this.taskTypeIds = value;
    }

    public execute(showGlobalLoadingIndicator: boolean = true) {
        if (this.requests.length == 0) {
            this.onFinishEvent.emit(true);
            return;
        }

        this.requests.forEach(request => {
            if (request.configuration.getUser()) {
                let userId = request.configuration.getUser().id;
                if (!this.userIds.includes(userId)) {
                    this.userIds.push(userId);
                }
            }

            if (request.configuration.getMilestone()) {
                let milestoneId = request.configuration.getMilestone().id;
                if (!this.milestoneIds.includes(milestoneId)) {
                    this.milestoneIds.push(milestoneId);
                }
            }

            if (request.configuration.getProject()) {
                let projectId = request.configuration.getProject().id;
                if (!this.projectIds.includes(projectId)) {
                    this.projectIds.push(projectId);
                }
            }

            if (request.configuration.getProjects()) {
                request.configuration.getProjects().forEach((project)=>{
                    if (!this.projectIds.includes(project.id)) {
                        this.projectIds.push(project.id);
                    }
                })
            }
        })

        let configuration = this.requests[0].configuration;

        let api = Api.tasks().get();

        // Always required do to default configuration (taskType.default_task_estimate_type_id) shown in medium card
        api.include('task_estimate');

        if (configuration.getShowProjectMiniCard()) {
            api.include('project_micro');
        }

        if (configuration.getShowMilestoneMiniCard()) {
            api.include('milestone');
        }

        if (configuration.getProject()) {
            api.include('project_ids');
        }

        if (configuration.getProjects() && !configuration.getProject()) {
            api.include('project_ids');
        }

        if (configuration.getHasMilestone() !== null) {
            api.include('milestone');
        }

        if (this.userIds && this.userIds.length > 0) {
            api.whereIn('tasks_user.user_id', this.userIds);
        }

        if (this.milestoneIds && this.milestoneIds.length > 0) {
            api.include('milestone');
            api.whereIn('milestone.id', this.milestoneIds);
        }

        if (this.projectIds && this.projectIds.length > 0) {
            api.whereIn('project.id', this.projectIds);
        }

        if (this.mainStatusIds) {
            api.whereIn('main_status.status_id', this.mainStatusIds);
        }

        if (configuration.getTaskType()) {
            api.where('task_type_id', configuration.getTaskType().id);
        }
        if (this.taskTypeIds != undefined) {
            api.whereIn('task_type_id', this.taskTypeIds);
        }
        if (configuration.getOrArchivedSince()) {
            api.where('or_archived_since', Helpers.serverDate(configuration.getOrArchivedSince()));
        }
        if (configuration.getDepartment()) {
            api.where('department.id', configuration.getDepartment().id);
        }

        if (this.start && this.end && (configuration.getPlannedBetween() || configuration.getPlannedValidator())) {
            api.where('tasks_user.task_user_type_id', TaskUserTypes.Participant)
                .whereGreaterThanOrEqual('tasks_user.deadline.date', Helpers.serverDate(this.start))
                .whereLessThanOrEqual('tasks_user.deadline.date', Helpers.serverDate(this.end));
        }
        if (configuration.isSoftDeadline() !== null) {
            api.where('tasks_user.task_user_type_id', TaskUserTypes.Participant)
                .whereGreaterThan('tasks_user.deadline_id', 0)
                .where('tasks_user.deadline.is_soft', configuration.isSoftDeadline() ? 1 : 0);
        }
        if (configuration.getDeadlineBetween() && this.start && this.end) {
            api.whereGreaterThanOrEqual('tasks_deadline.deadline.date', Helpers.serverDate(this.start))
                .whereLessThanOrEqual('tasks_deadline.deadline.date', Helpers.serverDate(this.end));
        }
        if (configuration.getArchivedBetween() && this.start && this.end) {
            api.include('archived')
                .whereGreaterThanOrEqual('archived.created', Helpers.serverDate(this.start))
                .whereLessThanOrEqual('archived.created', Helpers.serverDate(this.end));
        } else if (configuration.getArchivedBetween()) {
            api.include('archived')
                .whereGreaterThanOrEqual('archived.created', Helpers.serverDate(configuration.getArchivedBetween()[0]))
                .whereLessThanOrEqual('archived.created', Helpers.serverDate(configuration.getArchivedBetween()[1]));
        }
        if (configuration.getCreatedBetween() && this.start && this.end) {
            api.whereGreaterThanOrEqual('created', Helpers.serverDate(this.start))
                .whereLessThanOrEqual('created', Helpers.serverDate(this.end));
        }
        if (configuration.getTaskDeadlineTypes()) {
            if (configuration.getHasNonDeadline() !== true) {
                api.whereIn('tasks_deadline.task_deadline_type_id', configuration.getTaskDeadlineTypes());
            }
        }
        if (configuration.getOpen() !== null) {
            api.where('open', configuration.getOpen() ? 1 : 0);
        }
        if (configuration.getHasNonDeadline() !== null && configuration.getHasNonDeadline() != undefined) {
            if (configuration.getHasNonDeadline()) {
                api.where('tasks_deadline.deadline_id', null);
            } else {
                api.whereNot('tasks_deadline.deadline_id', null);
            }
        }
        if (configuration.getHasHands() !== null) {
            if (configuration.getHasHands()) {
                api.whereGreaterThan('num_hand_ups', 0);
            }
        }
        if (configuration.getHasStars() !== null) {
            if (configuration.getHasStars()) {
                api.whereGreaterThan('num_stars', 0);
            }
        }

        const categoryIds: number[] = [];
        if (configuration.getUseGlobalFilter()) {
            let filter = AppInjector.getInjector().get(FilterGlobalService).getActiveSettings();
            if (configuration.getUseGlobalSearchFilter() && filter.search) {
                api.search('title', filter.search);
                // https://podio.com/klartboard/softwareudvikling/apps/stories/items/1564
                let smartSearch = [];
                smartSearch.push('responsible');
                if (smartSearch.length) api.search('smart_search', smartSearch);
            }

            if (configuration.getUseGlobalSearchFilter() && filter.activeUsers?.length > 0) {
                api.search('users', filter.activeUsers.map(user => user.id));

                // let smartSearch = [];
                // smartSearch.push('responsible');
                // if (smartSearch.length) api.search('users_smart_search', smartSearch);
            }

            if (filter.isHandUp) api.whereGreaterThan('num_hand_ups', 0);
            if (filter.isStarred) api.whereGreaterThan('num_stars', 0);
            if (filter.activeStatuses.length < 4 && filter.activeStatuses.length > 0) {
                api.whereIn('main_status.status_id', filter.activeStatuses);
            }
            if (filter.activeReactionFilters?.length > 0) {
                filter.activeReactionFilters.forEach(reactionFilter => {
                    api.whereIn('reaction_filters', [reactionFilter.reaction_type_id, reactionFilter.value]);
                });
            }
            if (filter.activeCategories?.length) {
                categoryIds.push(...filter.activeCategories?.map(category => category.id));
            }
        }

        if (this.activeCategories?.length) {
            categoryIds.push(...this.activeCategories?.map(category => category.id));
        }

        if (categoryIds.length > 0) {
            api.whereIn('category.id', categoryIds);
        }

        configuration.getOrderBy().forEach(orderBy => api.orderBy(orderBy[0], orderBy[1]));

        api.setShowProgressBar(showGlobalLoadingIndicator);
        this.api = api.find(tasks => {

            // Clear all requests for earlier data
            this.requests.forEach(request => request.clear());

            tasks.forEach(task => {

                // Convert projects_tasks to projects
                if (task.projects_tasks) {
                    task.projects = task.projects_tasks.map(value => {
                        let project = new Project();
                        project.id = value.project_id;
                        return project;
                    });
                }

                // Apply projects to fetch requests
                this.requests.forEach(request => {
                    if (request.configuration.validate(task)) {
                        request.addItem(task);
                    }
                });
            });

            // Emit each fetch request
            const debounce = new EmitDebounce(100);
            this.requests.forEach(request => {
                request.cleanupAndEmitWhenReady(this.showAll, debounce);
            });

            // Emit all done
            this.onFinishEvent.emit(true);
        });
    }


    public cancel() {
        this.api?.cancel();
    }

}
