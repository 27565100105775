import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {BaseDisplayComponent} from '../../_system/base-display/base-display.component';
import {HandUp, Project} from '@app/core/models';
import {Roster, Task} from '@app/core/models/Task';
import {EventService} from '@app/services/event.service';
import {User} from '@app/core/models/User';
import {AppInjector} from '@app/services/app-injector.service';
import {UsersService} from '@app/services/users.service';
import {Milestone} from '@app/core/models/Milestone';
import {PlacementArray} from '@app/directives/positioning';
import {ApiRequest} from "@app/core/http/Api/ApiRequest";
import {BaseApi} from "@app/core/BaseApi";
import { Api } from '@app/core/Api';

@Component({
    selector: 'app-hand-up-selector',
    templateUrl: './hand-up-selector.component.html',
    styleUrls: ['./hand-up-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HandUpSelectorComponent extends BaseDisplayComponent implements OnInit {

    @Input() model: Task | Project | Milestone; // TO-DO Refactor til EditHandUp (Interface)
    @Input() showUsers = true;
    @Input() placement: PlacementArray = ['bottom', 'right', 'left', 'auto'];
    @Input() tooltipPlacement: PlacementArray = ['left', 'bottom', 'right', 'auto'];
    @Output() onToggle: EventEmitter<any> = new EventEmitter<any>();

    public handsUp: HandUp[] = [];
    public active = false;
    // Hvilke brugere skal kunne vælges?
    public user: User;
    private activated = false;

    private apiLoader?: ApiRequest;

    constructor(private eventService: EventService,
                private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        this.subscribe(this.eventService.subscribe(this.model, (event) => {
            // console.log('hand-up-selector.component : ', event.action)
            switch (event.action) {
                case EventService.Created:
                case EventService.Updated:
                case EventService.Deleted:
                    if (event.action == EventService.Deleted && this.handsUp) {
                        this.handsUp = this.handsUp.filter(s => s.id != event.item.id);
                    }
                    // this.handsUp = [];
                    this.detectChanges();
                    this.updateActive();
                    break;
            }
        }));
        this.user = AppInjector.getInjector().get(UsersService).user;
    }

    public preventPropagation(mouseEvent: MouseEvent) {
        this.activated = !this.activated;
        if (this.activated && this.handsUp.length == 0) {
            this.loadData();
        } else {
            this.clickEvent();
        }
        mouseEvent.stopPropagation();
    }

    public clickEvent() {
        if (!this.showUsers && this.user) {
            const item = this.handsUp.filter(s => s.user_id = this.user.id);
            this.toggleActive(!(item.length > 0), null);
        }
    }

    toggleActive(active: boolean, handUp: HandUp) {
        this.onToggle.emit({active, handUp});
        this.detectChanges();
        if (!handUp) {
            handUp = new HandUp({user_id: this.user.id});
        }

        switch (this.model.constructor.name) {
            case Project.name:
                if (!active) {
                    Api.projects().hand_upDeleteByProjectId(this.model.id!)
                        .user_id(handUp.user_id)
                        .delete(value => {
                            (this.model as Project).num_hand_ups--;
                            this.eventService.emitProject(this.model as Project, EventService.Updated, ['num_hand_ups']);
                            this.detectChanges();
                        });
                } else {
                    handUp.project_id = this.model.id;
                    Api.projects().hand_upPutByProjectId(this.model.id!)
                        .user_id(handUp.user_id)
                        .save(null, value => {
                            (this.model as Project).num_hand_ups++;
                            this.eventService.emitProject(this.model as Project, EventService.Updated, ['num_hand_ups']);
                            this.detectChanges();
                        });
                }
                break;
            case Task.name:
            case Roster.name:
                if (!active) {
                    Api.tasks().hand_upDeleteByTaskId(this.model.id!)
                        .user_id(handUp.user_id)
                        .delete(value => {
                            (this.model as Task).num_hand_ups--;
                            this.eventService.emitTask(this.model as Task, EventService.Updated, ['num_hand_ups']);
                            this.detectChanges();
                        });
                } else {
                    handUp.task_id = this.model.id;
                    Api.tasks().hand_upPutByTaskId(this.model.id!)
                        .user_id(handUp.user_id)
                        .save(null, value => {
                            (this.model as Task).num_hand_ups++;
                            this.eventService.emitTask(this.model as Task, EventService.Updated, ['num_hand_ups']);
                            this.detectChanges();
                        });
                }
                break;
            case Milestone.name:
                if (!active) {
                    Api.milestones().hand_upDeleteByMilestoneId(this.model.id!)
                        .user_id(handUp.user_id)
                        .delete(value => {
                            (this.model as Milestone).num_hand_ups--;
                            this.eventService.emitMilestone(this.model as Milestone, EventService.Updated, ['num_hand_ups']);
                            this.detectChanges();
                        });
                } else {
                    handUp.milestone_id = this.model.id;
                    Api.milestones().hand_upPutByMilestoneId(this.model.id!)
                        .user_id(handUp.user_id)
                        .save(null, value => {
                            (this.model as Milestone).num_hand_ups++;
                            this.eventService.emitMilestone(this.model as Milestone, EventService.Updated, ['num_hand_ups']);
                            this.detectChanges();
                        });
                }
                break;
        }
    }

    private loadData() {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;

        this.apiLoader?.cancel();

        let api: BaseApi<HandUp>;
        switch (this.model.constructor) {
            case Project:
                api = Api.projects().hand_upGetByProjectId(this.model.id)
                break;
            case Task:
            case Roster:
                api = Api.tasks().hand_upGetByTaskId(this.model.id)
                break;
            case Milestone:
                api = Api.milestones().hand_upGetByMilestoneId(this.model.id)
                break;
        }

        if (api) {
            this.apiLoader = api.orderBy('created', 'desc')
                .find(handsUp => {
                    this.handsUp = this.handsUp.concat(handsUp.filter((ha: HandUp) => {
                        return ha.user_id != 0;
                    }));
                    this.isLoading = false;
                    this.updateActive();
                    this.clickEvent();
                });
        } else {
            this.isLoading = false;
        }
    }

    private updateActive() {
        if (this.handsUp) {
            const found: HandUp = this.handsUp.find((hu: HandUp) => {
                return hu.user?.id == this.user?.id;
            });
            this.active = !!found;
        } else {
            this.active = false;
        }
        this.detectChanges();
    }

}
