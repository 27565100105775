import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PageComponent} from '@app/pages/page.component';
import {Project} from '@app/core/models/Project';
import {Milestone} from '@app/core/models/Milestone';
import {Todo} from '@app/core/models/Todo';
import {Task} from '@app/core/models/Task';
import {Location} from '@angular/common';
import {Department} from "@app/core/models/Department";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import Helpers from "@app/core/helpers";
import {CardItem} from "@app/shared/_ui/cards/CardItem";
import {Api} from '@app/core/Api';
import {AnyItem} from "@app/interfaces/CustomTypes";
import {ProjectListConfiguration} from "@app/shared/_ui/lists/project-list/ProjectListConfiguration";
import {CardProjectConfiguration} from "@app/shared/_ui/cards/medium/card-project/card-project-configuration";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

@Component({
    selector: 'app-debug',
    templateUrl: './display-debug.component.html',
    styleUrls: ['./display-debug.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DisplayDebugComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;

    public cards: CardItem<AnyItem>[] = [];
    public projectListConfiguration = new ProjectListConfiguration();

    constructor(private location: Location, private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
        this.department = new Department();
        this.department.id = 1;
        this.shellService.setPageSettings(this.department.id, undefined, undefined, undefined, undefined, Helpers.encodeUri(this.location.path()));
    }

    ngOnInit() {
        // AppInjector.getInjector().get(EditorPanelService).open(new Task({id: 175583}), {selectedTab: Tabs.AttachTo});
        // AppInjector.getInjector().get(EditorPanelService).open(new Task({id: 381}), {selectedTab: Tabs.Basic});
        //AppInjector.getInjector().get(EditorPanelService).open(new Task(), {selectedTab: Tabs.Who});
        // AppInjector.getInjector().get(EditorPanelService).open(new Project({id: 11568}), {selectedTab: Tabs.Who});
        // AppInjector.getInjector().get(EditorPanelService).open(new Milestone({id: 183}), {selectedTab: Tabs.Basic});
        // AppInjector.getInjector().get(EditorPanelService).open(new Project({id: 8}));

        setTimeout(()=>{
            // AppInjector.getInjector().get(EditorPanelService).open(new Project({id: 0}));
            // AppInjector.getInjector().get(EditorPanelService).open(new Task(), {selectedTab: Tabs.Who});
            // AppInjector.getInjector().get(EditorPanelService).open(new Task({id: 14}), {selectedTab: Tabs.AttachTo});
            // AppInjector.getInjector().get(EditorPanelService).open(new Project({id: 2}), {selectedTab: Tabs.ProcessSteps});
            // AppInjector.getInjector().get(FiltersS).open(new Milestone({id: 183}), {selectedTab: Tabs.Basic});
            // AppInjector.getInjector().get(EditorPanelService).open(new Milestone({id: 183}), {selectedTab: Tabs.Basic});

            // filter = DisplayFilter.createDefault(new Display({id: 2}));
            // filter.display = this.filterPageData.display; // Add display with all its displaySettings
            // AppInjector.getInjector().get(EditorPanelService).openDisplayFilterEditor(this.shellPageData, new DisplayFilter());

        }, 1500);
        // AppInjector.getInjector().get(EditorPanelService).open(new Project({id: 11226}));
        this.isLoading = false;
        this.shellService.setHeaderTitle('Debug', null, false, false, false);
        // this.testProgressBar();
        // this.testProjects();
    }

    historyBack() {
        this.location.back();
    }

    openProjectDialog() {
        this.dialogService.confirm(
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_ui_delete_item'),
            this.translateService.instant('_global_delete'),
        )
            .then((confirmed) => {
                if (confirmed) {

                }

            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    public testProgressBar() {
        this.cards = [];
        Api.tasks().get()
            .limit(100)
            .find(items => {
                console.warn('got results');
                this.cards = items.map(item => new CardItem(item));
                this.detectChanges();
            });
    }

    public testProjects() {
        this.cards = [];
        Api.projects().get()
            .limit(10)
            .find(items => {
                console.warn('got results');
                this.cards = items.map(item => new CardItem(item, new CardProjectConfiguration()));
                this.detectChanges();
            });
    }

}
