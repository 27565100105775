import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output, ViewChild
} from '@angular/core';
import {CardComponent} from '@app/shared/_ui/cards/card.component';
import {Milestone} from '@app/core/models/Milestone';
import {CardMilestoneConfiguration} from '@app/shared/_ui/cards/medium/card-milestone/card-milestone-configuration';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {AppInjector} from '@app/services/app-injector.service';
import {Project} from '@app/core/models/Project';
import {CardProjectConfiguration} from '@app/shared/_ui/cards/medium/card-project/card-project-configuration';
import {MiniCardFields} from '@app/editor/milestone-editor-loader/Fields';
import {MilestoneField} from '@app/core/models/MilestoneField';
import {MilestonesService} from '@app/services/milestones.service';
import {PhasesProject} from '@app/core/models/PhasesProject';
import {ReactionsTypeInterface} from '@app/shared/_ui/reactions/ReactionsTypeInterface';
import {ListConfiguration} from '../../../lists/ListConfiguration';
import {EventService} from '@app/services/event.service';
import {Reaction, Task} from '@app/core/models';
import {CardTaskConfiguration} from '@app/shared/_ui/cards/medium/card-task/card-task-configuration';
import {SnackbarService} from "@app/services/snackbar.service";

@Component({
    selector: 'app-card-milestone',
    templateUrl: './card-milestone.component.html',
    styleUrls: ['../../card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CardMilestoneComponent extends CardComponent implements OnInit {

    get Fields() {
        return MiniCardFields;
    }

    @Input() item: CardItem<Milestone>;
    @Input() model: Milestone;
    @Input() configuration: CardMilestoneConfiguration;
    @Input() mini = false;

    @Input() listConfiguration: ListConfiguration;
    @Input() removable = false;
    @Input() tooltip: string;
    @Input() editable = false;
    @Input() showTaskCards = false;
    @Input() interactive = true;
    @Output() onCardClickEvent = new EventEmitter();
    @Output() onRemove = new EventEmitter();
    @ViewChild('cardContainer') cardContainer: ElementRef;
    public date?: Date;
    public projectCard: CardItem;
    public taskCards: CardItem<Task>[];
    public phasesProject?: PhasesProject;
    public reactionsTypeInterfaces: ReactionsTypeInterface[];
    public hasVisibleReactions = false;

    private dialogService: BaseDialogService;
    private fields: Map<number, MilestoneField>;

    static CurrentPhasesProject(date: Date, project: Project) {
        if (project && date) {
            return project.getPhasesProjectByDate(date);
        }
        return null;
    }

    constructor(private cd: ChangeDetectorRef,
                private milestonesService: MilestonesService,
                private eventsService: EventService,
    ) {
        super();
        this.cdr = cd;
        this.initialized = false;
        this.dialogService = AppInjector.getInjector().get(BaseDialogService);
    }

    ngOnInit() {
        if (!this.item) {
            this.item = new CardItem<Milestone>(this.model, this.configuration || new CardMilestoneConfiguration());
        }
        super.ngOnInit();

        this.milestonesService.getMiniCardFields(fields => {
            this.fields = new Map();
            fields.forEach(field => this.fields.set(field.id, field));
            this.detectChanges();
        });

        this.milestonesService.getReactionTypes(reactionsTypes => {
            this.reactionsTypeInterfaces = reactionsTypes;
            this.detectChanges();
        });
    }

    onItemUpdated(item: Milestone) {
        super.onItemUpdated(item);
        this.render();
    }

    public applyProject(project: Project) {
        if (project) {
            if (this.configuration && this.configuration.showProjectMiniCard) {
                this.projectCard = new CardItem<Project>(project, new CardProjectConfiguration());
            } else {
                this.projectCard = null;
            }

            if (this.configuration && this.configuration.showProjectPhase && this.date) {
                this.phasesProject = CardMilestoneComponent.CurrentPhasesProject(this.date, project);
            }

            this.subscribe(this.eventsService.subscribeToProject(project.id, event => {
                switch (event.action) {
                    case EventService.Updated:
                        let entity: any = {};
                        if (event.isPatch && event.fields?.length)  {
                            event.fields.forEach(field => entity[field] = (event.item as any)[field]);
                        } else {
                            entity = {...event.item};
                        }
                        project.populate(entity, event.isPatch);
                        this.render();
                        break;
                    case EventService.Deleted:
                        break;
                }
            }));
        }
    }

    public applyTasks(tasks: Task[]) {
        this.taskCards = [];
        if (tasks && this.showTaskCards) {
            const taskConfiguration = new CardTaskConfiguration();
            tasks.forEach((task) => {
                const tc = new CardItem(task, taskConfiguration);
                this.taskCards.push(tc);

                this.subscribe(this.eventsService.subscribeToTask(task.id, event => {
                    switch (event.action) {
                        case EventService.Updated:
                            task.populate({...event.item}, event.isPatch);
                            this.render();
                            break;
                        case EventService.Deleted:
                            break;
                    }
                }));

            });
        }
    }

    public isFieldVisible(fieldId: number): boolean {
        if (this.fields && this.fields.get(fieldId)) {
            return this.fields.get(fieldId).visible;
        } else {
            return false;
        }
    }

    onStatusChange($event: number) {
        if (this.model.id !== 0 && !this.editable) {
            this.model.setStatus($event);
        }
    }

    onArchivedToggle($event: any) {
        this.model.setArchived((this.model.archived_id ?? 0) == 0);
    }

    copyMilestone() {
        this.dialogService.copyMilestone(
            this.translateService.instant(`_ui_copy`) + ' ' + this.translateService.instant('_milestone').toLowerCase(),
            this.translateService.instant('_ui_copy_item', {item: this.model.title}),
            this.translateService.instant('_global_ok'),
            this.translateService.instant('_global_cancel'),
            'sm',
            this.model,
        )
            .then((confirmed) => {
                // Logik afvikles i copymilestone
            })
            .catch(() => {
                console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
            });
    }

    delete() {
        const snackbar = AppInjector.getInjector().get(SnackbarService);
        this.dialogService.deleteMilestone(
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_ui_delete_item', {name: this.model.title}),
            this.translateService.instant('_global_delete'),
            this.translateService.instant('_global_cancel'),
            'sm',
            this.model
        )
            .then((confirmed) => {
                if (confirmed) {
                    this.model.delete(confirmed.cascade, () => {
                        snackbar.add(this.translateService.instant('_ui_item_deleted', {item: this.model.name}));
                    });
                }
            })
            .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    }

    public onReactionDataSetChanged(values: Reaction[]): void {
        this.hasVisibleReactions = values.length > 0;
        this.detectChanges();
    }

    protected render() {
        super.render();

        this.date = this.model?.deadline?.getDate();
        const project = this.item && this.item.item.projects && this.item.item.projects.length ? this.item.item.projects[0] : null;

        if (project) {
            this.applyProject(project);
        }

        const tasks = this.item && this.item.item.tasks && this.item.item.tasks.length ? this.item.item.tasks : null;
        if (tasks) {
            this.applyTasks(tasks);
        }

        this.initialized = true;
        this.detectChanges();
    }

}
