<ng-container [ngSwitch]="model.constructor.name">

    <app-card-task *ngSwitchCase="Task.name"
                   [attr.data-item-id]="cardItem.item.id"
                   [item]="$any(cardItem)"
                   [model]="$any(model)"
                   [configuration]="$any(configuration)"
                   [listConfiguration]="listConfiguration"
                   [mini]="configuration.displayModeMini"
                   [interactive]="interactive"
    />

    <app-card-task *ngSwitchCase="Roster.name"
                   [item]="$any(cardItem)"
                   [model]="$any(model)"
                   [configuration]="$any(configuration)"
                   [listConfiguration]="listConfiguration"
                   [mini]="configuration.displayModeMini"
                   [interactive]="interactive"
    />

    <app-card-task *ngSwitchCase="CaseUser.name"
                   [item]="$any(cardItem)"
                   [model]="$any(model)"
                   [configuration]="$any(configuration)"
                   [listConfiguration]="listConfiguration"
                   [mini]="configuration.displayModeMini"
                   [interactive]="interactive"
    />

    <app-card-project *ngSwitchCase="Project.name"
                      [item]="$any(cardItem)"
                      [model]="$any(model)"
                      [configuration]="$any(configuration)"
                      [listConfiguration]="listConfiguration"
                      [mini]="configuration.displayModeMini"
                      [interactive]="interactive"
    />

    <app-card-todo *ngSwitchCase="Todo.name"
                   [item]="$any(cardItem)"
                   [model]="$any(model)"
                   [configuration]="$any(configuration)"
                   [listConfiguration]="listConfiguration"
                   [interactive]="interactive"
    />

    <app-card-office-365-event
        *ngSwitchCase="Office365Event.name"
        [item]="cardItem" [model]="model"
        [configuration]="configuration"
        [listConfiguration]="listConfiguration"
        [interactive]="interactive"
    />

    <app-card-milestone
        *ngSwitchCase="Milestone.name"
        [item]="$any(cardItem)"
        [model]="$any(model)"
        [configuration]="$any(configuration)"
        [listConfiguration]="listConfiguration"
        [mini]="configuration.displayModeMini"
        [interactive]="interactive"
    />

    <app-card-appointment
        *ngSwitchCase="Appointment.name"
        [item]="$any(cardItem)"
        [model]="model"
        [configuration]="configuration"
        [listConfiguration]="listConfiguration"
        [interactive]="interactive"
    />

    <app-card-appointment
        *ngSwitchCase="AppointmentForm.name"
        [item]="$any(cardItem)"
        [model]="model"
        [configuration]="configuration"
        [listConfiguration]="listConfiguration"
        [interactive]="interactive"
    />

</ng-container>
