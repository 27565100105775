import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output, ViewChild
} from '@angular/core';
import {CardComponent} from '@app/shared/_ui/cards/card.component';
import {Milestone} from '@app/core/models/Milestone';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {MiniCardFields} from "@app/editor/milestone-editor-loader/Fields";
import {MilestoneField} from "@app/core/models/MilestoneField";
import {MilestonesService} from "@app/services/milestones.service";
import {ListConfiguration} from '../../../lists/ListConfiguration';
import {
    SmallCardMilestoneConfiguration
} from "@app/shared/_ui/cards/small/small-card-milestone/small-card-milestone-configuration";

@Component({
    selector: 'app-small-card-milestone',
    templateUrl: './small-card-milestone.component.html',
    styleUrls: ['../../card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SmallCardMilestoneComponent extends CardComponent implements OnInit {

    @Input() item: CardItem<Milestone>;
    @Input() model: Milestone;
    @Input() configuration: SmallCardMilestoneConfiguration;
    @Input() listConfiguration: ListConfiguration<Milestone>;
    @Input() removable = false;
    @Input() tooltip: string;
    @Input() editable = false;
    @Input() interactive = true;
    @Output() onCardClickEvent = new EventEmitter();
    @Output() onRemove = new EventEmitter();
    @ViewChild('cardContainer') cardContainer: ElementRef;

    private fields: Map<number, MilestoneField>;

    constructor(private cd: ChangeDetectorRef,
                private milestonesService: MilestonesService) {
        super();
        this.cdr = cd;
        this.initialized = false;
    }

    ngOnInit() {
        super.ngOnInit();
        if (!this.item) {
            this.item = new CardItem<Milestone>(this.model, this.configuration);
        }

        this.milestonesService.getMiniCardFields(fields => {
            this.fields = new Map();
            fields.forEach(field => this.fields.set(field.id, field));
            this.detectChanges();
        });
    }

    onItemUpdated(item: Milestone) {
        super.onItemUpdated(item);
        this.render();
    }

    protected render() {
        super.render();
        this.initialized = true;
        this.detectChanges();
    }

    get Fields() {
        return MiniCardFields;
    }

    public isFieldVisible(fieldId: number): boolean {
        if (this.fields && this.fields.get(fieldId)) {
            return this.fields.get(fieldId).visible;
        } else {
            return false;
        }
    }

    onStatusChange($event: number) {
        if (this.model.id != 0 && !this.editable) {
            this.model.setStatus($event);
        }
    }

}
