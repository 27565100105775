import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {ProjectEstimate} from "@app/core/models";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {Projects_ProjectEstimateTypesSettingValue} from "@app/core/Api";

@Component({
    selector: 'app-project-estimate-list-cell',
    templateUrl: './project-estimate-list-cell.component.html',
    styleUrls: ['./project-estimate-list-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectEstimateListCellComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() row: ProjectsDisplayRow;
    @Input() column: GenericTableColumn;

    // Bindings to view
    public projectEstimates: ProjectEstimate[] = [];

    // Data

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        const projectEstimates: ProjectEstimate[] = [];
        this.column.column.settings.get(ColumnTypeSettings.ProjectEstimateTypes)
            ?.getObject<Projects_ProjectEstimateTypesSettingValue>()
            ?.projectEstimateTypeIds

            // Find ProjectEstimate
            ?.map(projectEstimateTypeId => this.row.project.item.findProjectEstimateByType(projectEstimateTypeId))

            // Filter out invalid items
            ?.filter(projectEstimate => projectEstimate?.estimate?.exists())

            // Add to view
            ?.forEach(projectEstimate => projectEstimates.push(projectEstimate))

        this.projectEstimates = projectEstimates;
        this.detectChanges();
    }

}
