import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {EditNumber} from "@app/editor/quick-editor/editors/generic/number-editor/EditNumber";
import {EditNumberConfiguration} from "@app/editor/quick-editor/editors/generic/number-editor/EditNumberConfiguration";

@Component({
    selector: 'app-number-editor',
    templateUrl: './number-editor.component.html',
    styleUrls: ['./number-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NumberEditorComponent extends BaseEditor<EditNumber> {
    protected eventFieldName?: string = 'number-field';

    // Bindings to parent
    @Input() model: EditNumber & HasEventGenerator & BaseModel;
    @Input() configuration: EditNumberConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public value?: number;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribe(this.configuration?.onChangeEvent.subscribe(() => this.render()));
    }

    protected setup() {
    }

    protected getEventNameOptions(): any {
        return {
            prop: this.configuration.prop
        };
    }

    protected render() {
        this.value = this.model.getNumber(this.configuration.prop);
        this.configuration.validator.validate(this.value, this.configuration.label);
        this.detectChanges();
    }

    public save() {
        this.configuration.validator.validate(this.value, this.configuration.label);

        if (this.model.getNumber(this.configuration.prop) !== this.value) {
            this.model.setNumber(this.configuration.prop, this.value);
            this.onItemUpdated();
            this.render();
        }
    }

}
