import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import {User} from '@app/core/models/User';
import {UsersService} from "@app/services/users.service";
import {DisplayService} from '@app/services/display.service';
import {DisplayTypes} from '@app/constants';
import {ShellService} from '@app/services/ShellService/shell.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-user-with-icon',
    templateUrl: './user-with-icon.component.html',
    styleUrls: ['./user-with-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserWithIconComponent implements OnInit, OnChanges, OnDestroy {

    @Input() model: User;
    @Input() modelId: number;
    @Input() fullName = true;
    @Input() showInitials = false;
    @Input() navigateToDashboard = false;
    @Input() showTooltip = false;
    @Input() showIcon = true;
    @Input() displayStrong = false;
    @Input() label: string;
    public initialsString: string = null;

    constructor(private usersService: UsersService,
                private displayService: DisplayService,
                private router: Router,
                private shellService: ShellService,
                private cdr: ChangeDetectorRef) {
        this.cdr.detach();
    }

    ngOnInit() {
        if(this.showInitials) this.showTooltip = true;
        this.render();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    ngOnDestroy(): void {
        this.cdr.detach(); // do this
    }

    private render() {
        if (!this.model && this.modelId) {
            // Usersservice + cache
            this.usersService.getUsers((users) => {
                this.model = users.find(u => u.id == this.modelId);
                if(this.model && this.model?.initialsConverter){
                    this.initialsString = this.model?.initialsConverter;
                }else{
                    this.initialsString = null;
                }
                if (this.cdr && !(this.cdr as any)['destroyed']) {
                    this.cdr.detectChanges();
                }
            });
        } else {
            this.initialsString = this.model?.initialsConverter;
        }
        this.cdr.detectChanges();
    }

    openUserDashboard($event: any) {
        if (this.navigateToDashboard) {
            $event.stopImmediatePropagation();
            this.displayService.getDisplaysInDepartmentAndType(
                this.shellService.getPageSettings()?.departmentId,
                DisplayTypes.UserOverview, (displays) => {

                    if (displays.length > 0) {
                        const display = displays[0];
                        const route = [
                            '/app/displays/dashboard/',
                            display.id ? display.id : 0,
                            this.shellService.getPageSettings()?.departmentId,
                            this.model.id
                        ];

                        // console.log('openUserDashboard() : route : ', route);
                        this.router.navigate(route, {queryParamsHandling: 'merge'});
                    }
                })
        }
    }

}
