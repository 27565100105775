import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {BaseComponent} from '@app/shared/_system/base/base.component';
import {ShellService} from '@app/services/ShellService/shell.service';
import {Phase, Project, ProjectType} from '@app/core/models';
import {Api} from '@app/core/Api';
import {CountRunner} from '@app/core/CountRunner/CountRunner';
import {AppInjector} from '@app/services/app-injector.service';
import {EventService} from '@app/services/event.service';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {Field, ProjectEditorService} from '@app/editor/project-editor-loader/project-editor.service';
import {ProjectsService} from '@app/services/projects.service';

@Component({
    selector: 'app-project-editor-loader',
    templateUrl: './project-editor-loader.component.html',
    styleUrls: ['../base-editor-loader.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProjectEditorLoaderComponent extends BaseComponent implements OnInit {

    // Bindings to parent
    @Input() itemToLoad: Project;
    @Input() options: any;
    @Output() onCloseEventEmitter = new EventEmitter();

    // Bindings to view: UI Control
    public isLoading = false;
    public showNotFound = false;
    public showEditor = false;
    // Bindings to view: Required for Editor
    public item: Project;
    public fields: Map<number, Field>;
    public configuration: ProjectType;
    public projectTypes: ProjectType[];

    constructor(private shellService: ShellService,
                private cd: ChangeDetectorRef,
                private editorService: ProjectEditorService,
                private itemService: ProjectsService,
                private editorPanelService: EditorPanelService) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        this.startLoading();
        this.ensureItem(item => this.loadData(item), () => this.renderWithError());
    }

    // </editor-fold>

    // <editor-fold desc="View actions">

    public onLoadingIndicatorClicked() {
        this.close();
    }

    public onNotFoundClicked() {
        this.close();
    }

    public onEditorItemSaved(project: Project) {
        Api.projects().getById(project.id).find(item => {
            this.item = item[0];
            this.renderWithSuccess();
        });
    }

    public onEditorReloadEvent() {
        Api.projects().getById(this.item.id).find(item => {
            this.item = item[0];
            this.renderWithSuccess();
        });
    }

    public onEditorCloseEvent() {
        this.close();
    }

    // <editor-fold desc="Load data">

    private ensureItem(onSuccess: (item: Project) => void, onError: () => void) {
        // Fetch
        if (this.itemToLoad.exists()) {
            if (this.options?.reload === false) {
                onSuccess(this.itemToLoad);
            } else {
                Api.projects().getById(this.itemToLoad.id).find(item => {
                    if (item?.length === 1 && item[0].exists()) {
                        onSuccess(item[0]);
                    } else {
                        onError();
                    }
                });
            }
        } else {
            console.error('Should never end up here');
        }
    }

    private loadData(itemToLoad: Project) {
        this.startLoading();

        const countRunner = new CountRunner(3);
        countRunner.setRunner(() => {
            this.item = itemToLoad;
            this.renderWithSuccess();
        });

        this.itemService.getProjectTypesByDepartmentId(
            this.shellService.getPageSettings()?.departmentId,
            projectTypes => {
                this.projectTypes = projectTypes;
                countRunner.tick();
            });

        this.editorService.getEditorFieldsForType(itemToLoad.project_type_id, fields => {
            this.fields = fields;
            countRunner.tick();
        });

        this.itemService.getProjectType(itemToLoad.project_type_id, type => {
            this.configuration = type;
            countRunner.tick();
        });

        // Add phase to phasesProjects missing phase object
        itemToLoad.phases_projects
            ?.filter(phasesProject => phasesProject.phase == null)
            ?.forEach(phasesProject => {
                countRunner.incrementCount();
                Phase.GetSingle(phasesProject.phase_id, phase => {
                    phasesProject.phase = new Phase(phase);
                    delete phasesProject.current_phase_progress_id;
                    delete phasesProject.current_phase_progress;
                    delete phasesProject.phase_progress;
                    countRunner.tick();
                });
            });

        countRunner.start();
    }

    private startLoading() {
        this.showEditor = false;
        this.showNotFound = false;
        this.isLoading = true;
        this.detectChanges();
    }

    private stopLoading() {
        this.isLoading = false;
        this.detectChanges();
    }

    private renderWithError() {
        this.showNotFound = true;
        this.stopLoading();
    }

    private renderWithSuccess() {
        this.showEditor = true;
        this.stopLoading();

        // Subscribe to events and close if deleted
        this.subscribe(AppInjector.getInjector().get(EventService).subscribe(this.item, event => {
            switch (event.action) {
                case EventService.Updated:
                    break;
                case EventService.Deleted:
                    this.close();
                    break;
            }
        }));
    }

    // </editor-fold>

    private close() {
        this.shellService.removeFromURI(this.itemToLoad);
        this.editorPanelService.closeItem(this.item);
        this.onCloseEventEmitter.emit();
    }

}
