import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Department} from '@app/core/models/Department';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {User} from '@app/core/models/User';
import {Api} from "@app/core/Api";

@Component({
    selector: 'app-department-users-picker',
    templateUrl: './department-users-picker.component.html',
    styleUrls: ['./department-users-picker.component.scss'],
    standalone: false
})
export class DepartmentUsersPickerComponent extends BaseDisplayComponent implements OnInit {

    public departments: Department[];
    @Output() valueChanged = new EventEmitter<{ users: User[], department: Department }>();

    constructor() {
        super();
    }

    ngOnInit() {
        Department.GetAll(departments => this.departments = departments);
    }

    selectDepartment(department: Department) {
        Api.users().get()
            .where('department.id', department.id)
            .where('hidden', false)
            .orderAsc('first_name')
            .orderAsc('last_name')
            .find(users => {
                this.valueChanged.emit({users: users, department: department});
            });
    }
}
