import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {CardCaseUserConfiguration} from '@app/shared/_ui/cards/medium/card-case-user/card-case-user-configuration';
import {CaseUser} from '@app/core/models/Task';
import {TasksUser} from '@app/core/models/TasksUser';
import {TaskUserTypes} from '@app/constants';
import {CardTaskComponent} from '@app/shared/_ui/cards/medium/card-task/card-task.component';
import {AppInjector} from "@app/services/app-injector.service";
import {EditorPanelService} from "@app/services/editor-panel.service";

@Component({
    selector: 'app-card-case-user',
    templateUrl: './card-case-user.component.html',
    styleUrls: ['../../card.component.scss'],
    standalone: false
})
export class CardCaseUserComponent extends CardTaskComponent implements OnInit {

    @Input() item: CardItem<CaseUser>;
    @Input() model: CaseUser;
    @Output() contentClassChange = new EventEmitter<CaseUser>();
    @Input() configuration: CardCaseUserConfiguration;

    // Data
    public tasksUsers: TasksUser[];

    constructor(protected cd: ChangeDetectorRef) {
        super(cd);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    protected render() {
        super.render();
        this.tasksUsers = this.getConfigurationTaskUser();
        this.initialized = true;
    }

    onItemUpdated(item: CaseUser) {
        super.onItemUpdated(item);
        this.render();
    }

    // <editor-fold desc="View Actions">

    // </editor-fold>

    // <editor-fold desc="Configuration">

    getConfigurationTaskUser(): TasksUser[] {
        if(this.model && this.model.tasks_users) {
            return this.model.tasks_users.filter(tasksUser => tasksUser.task_user_type_id == TaskUserTypes.Participant);
        }
    }

    public openCase(){
        // console.log(this.item.item);
        AppInjector.getInjector().get(EditorPanelService).open(this.item.item);
    }

    // </editor-fold>
}
