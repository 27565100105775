import {EventEmitter} from '@angular/core';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {User} from '@app/core/models/User';
import {CardUserConfiguration} from '@app/shared/_ui/cards/medium/card-user/card-user-configuration';
import {ProjectUserTypes} from '@app/core/models/Project';
import {StatusTypesAll} from '@app/constants';
import {
    MultiLoaderFetcherInterface
} from "@app/shared/_ui/displays/display-multi-loader/Helpers/MultiLoaderFetcherInterface";
import {Api} from "@app/core/Api";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";
import {ProjectCountResponse} from "@app/core/http/Api/ProjectCountResponse";

export class ProjectCountFetchRequest {

    public cardItem: CardItem<User>;
    public onProjectCountFetchedEvent = new EventEmitter<ProjectCountResponse>();
    private result: ProjectCountResponse;

    public constructor(cardItem: CardItem<User>) {
        this.cardItem = cardItem;
        (<CardUserConfiguration>cardItem.configuration).setProjectCountFetchRequest(this);
    }

    public setResult(result: ProjectCountResponse) {
        this.result = result;
    }

    public hasResult(): boolean {
        return this.result != null;
    }

    public getResult(): ProjectCountResponse {
        return this.result;
    }

    public done() {
        this.onProjectCountFetchedEvent.emit(this.result);
    }

}

export class ProjectCountFetcher extends BaseFetcher implements MultiLoaderFetcherInterface {

    private requests: Map<number, ProjectCountFetchRequest> = new Map();
    private api?: ApiRequest;
    public onFinishEvent = new EventEmitter();

    constructor(name?: string) {
        super(name ?? '');
    }

    public clear(): void {
        this.requests.clear();
    }

    public addRequest(request: ProjectCountFetchRequest) {
        this.requests.set(request.cardItem.item.id, request);
    }

    public execute(showGlobalLoadingIndicator: boolean = true) {
        let userIds: number[] = [];
        let configuration: CardUserConfiguration;
        this.requests.forEach(((value, key) => {
            userIds.push(key);
            if (!configuration)
                configuration = value.cardItem.configuration as CardUserConfiguration;
        }));

        if (configuration) {
            const api = Api.projects().smart_countGet();
            api
                .whereIn('projects_user.user_id', userIds)
                .where('projects_user.project_user_type_id', ProjectUserTypes.Responsible)
                .where('archived_id', 0)
                .whereIn('main_status.status_id', StatusTypesAll)
                .addQueryParameter('count', 1);
            api.setShowProgressBar(showGlobalLoadingIndicator);
            // https://podio.com/klartboard/softwareudvikling/apps/stories/items/376
            // if(systemsService.pageSettings.departmentId)
            //     api.where('department.id', systemsService.pageSettings.departmentId);

            this.api = api.find(projectCountResponses => {
                projectCountResponses?.forEach(projectCountResponse => {
                    const response = new ProjectCountResponse(projectCountResponse);
                    if (this.requests.has(response.user_id)) {
                        this.requests.get(response.user_id).setResult(response);
                        this.requests.get(response.user_id).done();
                    }
                });
                this.onFinishEvent.emit(true);
            });
        } else {
            this.onFinishEvent.emit(true);
        }
    }

    public cancel() {
        this.api?.cancel();
    }

}
