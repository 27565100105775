import {EventEmitter} from "@angular/core";
import {Deadline, StaticDeadline} from "@app/core/models";
import moment from 'moment';
import {TranslateService} from "@ngx-translate/core";
import {AppInjector} from "@app/services/app-injector.service";
import {
    EditDeadlineTypeValidator
} from "@app/editor/quick-editor/editors/generic/deadline-list-editor/EditDeadlineTypeValidator";

export class DeadlineItem {

    // Settings
    public name: string;
    public id: number;
    public showSoftDeadline: boolean;
    public validator: EditDeadlineTypeValidator;

    // Events
    public onRequiredChangeEvent = new EventEmitter<boolean>();
    public onDateChangedEvent = new EventEmitter<{before?: Date, after?: Date}>();

    // Data
    public previousDeadline?: Deadline;
    public deadline?: Deadline;
    public previousItem?: DeadlineItem;
    public staticDeadlines?: StaticDeadline[];

    constructor(name: string, id: number, showSoftDeadline: boolean,
                validator: EditDeadlineTypeValidator) {
        this.name = name;
        this.id = id;
        this.showSoftDeadline = showSoftDeadline;
        this.validator = validator;
    }

    public setDeadline(deadline?: Deadline) {
        this.previousDeadline = deadline;
        this.deadline = deadline;
    }

    public get hasPreviousDeadlineDiffDescription(): boolean {
        if (!this.deadline?.date) {
            return false;
        }
        if (!this.previousItem?.deadline?.date) {
            return false;
        }
        return true;
    }

    public get previousDeadlineDiffDescription(): string {
        const translate = AppInjector.getInjector().get(TranslateService);
        const diff = moment(this.deadline.getDate()).diff(moment(this.previousItem.deadline.date), 'days');
        const diffDescription = Math.abs(diff) > 1 ? translate.instant('_ui_days') : translate.instant('_ui_day');
        const between = translate.instant('_ui_between');
        const and = translate.instant('_ui_and');
        return `${(diff)} ${diffDescription} ${between} ${this.translatedName} ${and} ${this.previousItem.translatedName}`;
    }

    public staticDeadlineDescriptor = (staticDeadline: StaticDeadline): [string | undefined, Date | undefined] => {
        return [this.previousItem?.translatedName, this.previousItem?.deadline?.getDate()];
    }

    public get translatedName(): string {
        return AppInjector.getInjector().get(TranslateService).instant(this.name);
    }

    public clone(): DeadlineItem {
        const item = new DeadlineItem(this.name, this.id, this.showSoftDeadline, this.validator);
        item.onRequiredChangeEvent = this.onRequiredChangeEvent;
        item.onDateChangedEvent = this.onDateChangedEvent;
        return item;
    }

    public validate() {
        this.validator.validate(this.deadline, this.name.length ? AppInjector.getInjector().get(TranslateService).instant(this.name) : '');
    }

}
