import {Component, Input, OnInit} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {Holiday} from '@app/core/models';
import {
    HolidayItem
} from '@app/shared/_ui/displays/milestone-plan/template-holiday-picker/template-holiday-picker.component';
import {BlockedHoliday} from '@app/core/models/BlockedHoliday';
import {Api} from '@app/core/Api';
import {MilestonePlan} from '@app/core/models/MilestonePlan';
import {
    BlockedPeriodItem
} from "@app/shared/_ui/displays/milestone-plan/template-blocked-period-picker/blocked-period-item";
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '@app/services/snackbar.service';

@Component({
    selector: 'app-milestone-plan-period-selection',
    templateUrl: './milestone-plan-period-selection.component.html',
    styleUrls: ['./milestone-plan-period-selection.component.scss'],
    standalone: false
})
export class MilestonePlanPeriodSelectionComponent extends BaseDisplayComponent implements OnInit {

    @Input() milestonePlan:MilestonePlan;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if(!this.milestonePlan.blocked_holidays)
            this.milestonePlan.blocked_holidays = [];

        this.selectedHolidays = this.milestonePlan.blocked_holidays.map((bh)=>{
            return new Holiday({id: bh.holiday_id});
        });

        if(!this.milestonePlan.blocked_periods)
            this.milestonePlan.blocked_periods = [];

    }

    showToast(){
        const translateService = AppInjector.getInjector().get(TranslateService);
        AppInjector.getInjector().get(SnackbarService).add(
            translateService.instant('_ui_item_saved', {item: translateService.instant('_milestone_plan')}),
        );
    }

    // Blocked Holidays
    public selectedHolidays: Holiday[] = [];

    holidayAdd(holidayItem: HolidayItem) {
        const blockedHoliday = new BlockedHoliday({holiday_id: holidayItem.holiday.id});
        holidayItem.disabled = true;
        Api.milestonePlans()
            .blockedHolidayAddPostById(this.milestonePlan.id)
            .save(blockedHoliday, blockedHoliday => {
                if (!this.milestonePlan.blocked_holidays) {
                    this.milestonePlan.blocked_holidays = [];
                }
                this.milestonePlan.blocked_holidays.push(blockedHoliday);
                holidayItem.disabled = false;
                this.showToast();
            });
    }

    holidayRemove(holidayItem: HolidayItem) {
        holidayItem.disabled = true;
        const blockedHolidayIndex = this.milestonePlan.blocked_holidays.findIndex(bh => bh.holiday_id == holidayItem.holiday.id);

        if(blockedHolidayIndex !== -1) {
            const blockedHoliday = this.milestonePlan.blocked_holidays[blockedHolidayIndex];
            Api.milestonePlans()
                .blockedHolidayRemoveDeleteByIdByBlockedHolidayId(this.milestonePlan.id, blockedHoliday.id)
                .delete((r) => {
                    this.milestonePlan.blocked_holidays.splice(blockedHolidayIndex, 1);
                    holidayItem.disabled = false;
                    this.showToast();
                });
        }
    }

    holidayAddAll(holidays: HolidayItem[]){
        // Api.milestonePlans().blockHo
        const mp = new MilestonePlan();
        mp.id = this.milestonePlan.id;
        mp.blocked_holidays = holidays.map(v => v.holiday);
        Api.milestonePlans().patchById(this.milestonePlan.id).save(mp, r => {
            this.milestonePlan.blocked_holidays = r.blocked_holidays;
        })
    }
    holidayRemoveAll(){
        const mp = new MilestonePlan();
        mp.id = this.milestonePlan.id;
        mp.blocked_holidays = [];
        Api.milestonePlans().patchById(this.milestonePlan.id).save(mp, r => {
            this.milestonePlan.blocked_holidays = [];
            this.showToast();
        });
    }


    // Blocked Periods
    blockedPeriodAdd(blockedPeriodItem: BlockedPeriodItem) {
        blockedPeriodItem.disabled = true;
        Api.milestonePlans()
            .blockedPeriodAddPostById(this.milestonePlan.id)
            .save(blockedPeriodItem.blockedPeriod, blockedPeriod => {
                blockedPeriodItem.blockedPeriod.id = blockedPeriod.id;
                if (!this.milestonePlan.blocked_periods) {
                    this.milestonePlan.blocked_periods = [];
                }
                this.milestonePlan.blocked_periods.push(blockedPeriod);
                blockedPeriodItem.disabled = false;
                this.showToast();
            });
    }

    blockedPeriodRemove(blockedPeriodItem: BlockedPeriodItem) {
        const blockedPeriodIndex = this.milestonePlan?.blocked_periods.findIndex(bh => bh.id == blockedPeriodItem.blockedPeriod?.id);
        if(blockedPeriodIndex !== -1) {
            blockedPeriodItem.disabled = true;
            const blockedPeriod = this.milestonePlan.blocked_periods[blockedPeriodIndex];
            Api.milestonePlans()
                .blockedPeriodRemoveDeleteByIdByBlockedPeriodId(this.milestonePlan.id, blockedPeriod.id)
                .delete((r) => {
                    this.milestonePlan.blocked_periods.splice(blockedPeriodIndex, 1);
                    blockedPeriodItem.disabled = false;
                    this.showToast();
                });
        }
    }

    /*
    .titlePatchPatchById(this.milestonePlan.id)
                        .title(this.milestonePlan.title)
                        .save(null, (mp)=>{
     */
    toggleWeekends() {
        this.milestonePlan.block_weekends = !this.milestonePlan.block_weekends;
        Api.milestonePlans()
            .patchById(this.milestonePlan.id)
            .save(new MilestonePlan({id: this.milestonePlan.id, block_weekends: this.milestonePlan.block_weekends}), (r) => {
                this.showToast();
            });
    }
}
