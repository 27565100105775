import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-dropdown-list-item',
    templateUrl: './dropdown-list-item.component.html',
    styleUrls: ['./dropdown-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DropdownListItemComponent implements OnInit {

    @Input() label: string;
    @Input() badgeLabel: string;
    @Input() iconClass: string;

    constructor() {

    }

    ngOnInit() {

    }

}
