import {PageComponent} from "@app/pages/page.component";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild} from "@angular/core";
import {FiltersInterface} from "@app/services/ShellFilterService/FiltersInterface";
import {Location} from "@angular/common";
import {Settings} from "@app/pages/displays/display-project-details/Settings";
import {DisplayTypes} from "@app/constants";
import {Api} from "@app/core/Api";
import {ShellPageData} from "@app/services/ShellService/ShellPageData";
import moment from 'moment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import Helpers from '@app/core/helpers';
import * as FileSaver from 'file-saver';
import {MilestonePlan, Project} from "@app/core/models";
import {ColumnController} from "@app/core/ColumnControl/ColumnController";

@Component({
    selector: 'app-display-project-details',
    templateUrl: './display-project-details.component.html',
    styleUrls: ['./display-project-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DisplayProjectDetailsComponent extends PageComponent implements OnInit {
    protected filtersSettings?: FiltersInterface;
    protected columnController?: ColumnController;
    private settings?: Settings;
    public initialized = false;

    @ViewChild('screen', {static: true}) screen: any;

    // UI: Top
    public topDisplay_Visible = false;
    public topDisplay_DisplayId: number;
    public topDisplay_ShellPageData: ShellPageData;

    // UI: SubDisplay Milestones
    public subDisplayMilestones_Visible = false;
    public subDisplayMilestones_DisplayId: number;
    public subDisplayMilestones_Name: string;
    public subDisplayMilestones_ShellPageData: ShellPageData;
    public subDisplayMilestones_IsLoaded = false;

    // UI: SubDisplay Users
    public subDisplayUsers_Visible = false;
    public subDisplayUsers_DisplayId: number;
    public subDisplayUsers_Name: string;
    public subDisplayUsers_ShellPageData: ShellPageData;
    public subDisplayUsers_IsLoaded = false;

    // UI: SubDisplay Tasks
    public subDisplayTasks_Visible = false;
    public subDisplayTasks_DisplayId: number;
    public subDisplayTasks_Name: string;
    public subDisplayTasks_ShellPageData: ShellPageData;
    public subDisplayTasks_IsLoaded = false;

    // UI: Shared
    public subDisplayColumn_TaskEdit_Deadline_TaskDeadlineTypeId: number;
    public activeSubDisplay: number;
    public projectId: number;
    public project: Project;
    public taskDeadlineTypeId?: number;
    public taskEstimateTypeId?: number;
    public isCapturingScreenshot: boolean = false;
    public onReloadEventEmitter = new EventEmitter();

    // Data
    private urlParams: Params;

    constructor(private cd: ChangeDetectorRef,
                public location: Location,
                private activatedRoute: ActivatedRoute,
                private router: Router
    ) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();

        this.filterGlobalService.setShowUserSearch(true); // https://podio.com/klartboard/softwareudvikling/apps/supports/items/1133

        if (this.activatedRoute) {
            this.activatedRoute.queryParams
                .subscribe(params => {
                    this.urlParams = params;
                    if (params.activeTab && params.activeTab != this.activeSubDisplay) {
                        this.tabChange(parseInt(params.activeTab));
                    }
                    if (params.topDisplay_Visible !== undefined) {
                        this.topDisplay_Visible = params.topDisplay_Visible == 'true';
                    } else {
                        this.topDisplay_Visible = true;
                    }
                    this.detectChanges();
                });
        }
    }

    // <editor-fold desc="View Actions">

    public changeActiveId(id: number) {
        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: {
                    activeTab: id,

                },
                queryParamsHandling: 'merge',
                replaceUrl: true
            });
    }

    public tabChange(id: number) {
        this.activeSubDisplay = id;
        switch (id) {
            case this.subDisplayMilestones_DisplayId:
                this.subDisplayMilestones_IsLoaded = true;
                break;
            case this.subDisplayUsers_DisplayId:
                this.subDisplayUsers_IsLoaded = true;
                break;
            case this.subDisplayTasks_DisplayId:
                this.subDisplayTasks_IsLoaded = true;
                break;
        }

        this.detectChanges();
    }

    public toggleHeader() {
        this.topDisplay_Visible = !this.topDisplay_Visible;
        this.detectChanges();

        this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: {
                    topDisplay_Visible: this.topDisplay_Visible,
                },
                queryParamsHandling: 'merge',
            });
    }

    public print() {
        window.print();
    }

    public onCaptureScreenshotBtnClicked() {
        if (this.isCapturingScreenshot) {
            return;
        }
        this.isCapturingScreenshot = true;
        setTimeout(() => {
            this.captureService.getImage(this.screen.nativeElement, true)
                .pipe(
                    tap(img => {
                        const fileName = (this.display.name) + '-' + (moment().format("DD-MMM-YYYY-HH:mm:ss")) + '.png';
                        let file = Helpers.convertBase64ToFile(img, fileName);
                        FileSaver.saveAs(file, fileName);
                        this.isCapturingScreenshot = false;
                    })
                ).subscribe();
        }, 500);
    }

    public onMilestonePlanAppliedEvent($event: MilestonePlan) {
        this.onReloadEventEmitter.emit();
    }

    // </editor-fold>

    // <editor-fold desc="Load Data">

    private loadData() {
        Api.projects().getById(this.projectId)
            .find(project => {
                if (project.length == 1) {
                    this.project = project[0];
                    this.shellService.setHeaderTitle(project[0].title);
                    this.initialized = true;
                    this.detectChanges();
                } else {
                    this.shellService.setHeaderTitle('?');
                }
            });
    }

    // </editor-fold>

    // <editor-fold desc="Setup">

    protected onAfterDisplay() {
        super.onAfterDisplay();
        this.initialize();
        if (this.projectId) {
            this.loadData();
        }
    }

    private initialize() {
        this.projectId = this.shellService.getPageSettings().projectId;
        this.settings = new Settings(this.settingsMap);
        this.taskDeadlineTypeId = this.settings.getSubDisplayColumn_TaskEdit_Deadline_TaskDeadlineTypeId();
        this.taskEstimateTypeId = this.settings.getSubDisplayColumn_TaskEdit_Estimate_TaskEstimateTypeId();

        this.subDisplayMilestones_Visible = this.settings.isSubDisplayMilestonesEnabled();
        this.subDisplayUsers_Visible = this.settings.isSubDisplayUsersEnabled();
        this.subDisplayTasks_Visible = this.settings.isSubDisplayTasksEnabled();


        this.display.relateddisplays?.forEach(relatedDisplay => {
            switch (relatedDisplay.display_type_id) {
                case DisplayTypes.ProjectDetails_Top:
                    this.topDisplay_DisplayId = relatedDisplay.id;
                    break;
                case DisplayTypes.ProjectDetails_Milestones:
                    this.subDisplayMilestones_DisplayId = relatedDisplay.id;
                    this.subDisplayMilestones_Name = this.translateService.instant(relatedDisplay.name);
                    break;
                case DisplayTypes.ProjectDetails_Users:
                    this.subDisplayUsers_DisplayId = relatedDisplay.id;
                    this.subDisplayUsers_Name = this.translateService.instant(relatedDisplay.name);
                    break;
                case DisplayTypes.ProjectDetails_Tasks:
                    this.subDisplayTasks_DisplayId = relatedDisplay.id;
                    this.subDisplayTasks_Name = this.translateService.instant(relatedDisplay.name);
                    break;
            }
        });

        this.subDisplayColumn_TaskEdit_Deadline_TaskDeadlineTypeId = this.settings.getSubDisplayColumn_TaskEdit_Deadline_TaskDeadlineTypeId();

        if (!this.activeSubDisplay) {
            if (this.subDisplayMilestones_Visible) {
                this.tabChange(this.subDisplayMilestones_DisplayId);
            } else if (this.subDisplayUsers_Visible) {
                this.tabChange(this.subDisplayUsers_DisplayId);
            } else if (this.subDisplayTasks_Visible) {
                this.tabChange(this.subDisplayTasks_DisplayId);
            }
        } else {
            this.tabChange(this.activeSubDisplay);
        }
        if (!this.urlParams) {
            this.topDisplay_Visible = true;
        }
    }

    // </editor-fold>

}
