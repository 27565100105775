import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    GuardResult,
    MaybeAsync,
    RouterStateSnapshot
} from '@angular/router';
import {AuthService} from "../../../services/auth.service";

@Injectable()
export class AuthenticationGuard implements CanActivate {

    constructor(private authService: AuthService
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> {
        const isLoggedIn = this.authService.isUserLoggedIn();

        if (isLoggedIn) {
            return true;
        } else {
            return this.authService.login(
                state.url,
                '',
                !isLoggedIn
            );
        }
    }

}
