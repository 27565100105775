<div class="modal-inner-content window-size d-flex flex-column overflow-hidden" [@openClose]="isOpen ? 'open' : 'closed'">
    <div class="modal-header" ngxModalDraggable>
        <h4 class="modal-description">{{'_ui_create_phase' | translate}}</h4>

        <app-color-item class="cursor-pointer me-2 ms-auto"
                        [className]="'medium'"
                        [colorValue]="'#ff5f57'"
                        [iconClass]="'fa-times'"
                        container="body" [ngbTooltip]="('_ui_close' | translate)"
                        (click)="dismiss();"></app-color-item>
    </div>
    <div class="modal-body d-flex align-items-start">
        <div class="container p-0 flex-grow-1 flex-shrink-1">

            <div class="form-group p-2 m-0 -w-50">
                <label class="form-check-label" >{{'_ui_phase_title' | translate}}</label>
                <input [(ngModel)]="model.name" class="form-control" />
            </div>

            <div class="form-group p-2 m-0 -w-50">
                <label class="form-check-label">{{'_phase_color' | translate}}</label>
                <app-color-picker
                    [multiple]="false"
                    (onToggle)="setColor($event)"
                    [selectedColors]="[model.color]"></app-color-picker>

            </div>

            <div class="form-group p-2" >
                <label >
                    <span >{{'_ui_add_department' | translate}}</span>
                </label>
                <app-department-picker
                                       [selectedDepartments]="model.departments"
                                       (onChangeEvent)="setDepartments($event)"
                                       class="list-group-item rounded"
                                       [showDepartmentGroups]="true"
                                       [showAll]="true"
                ></app-department-picker>
            </div>

        </div>
    </div>

    <div class="modal-footer">
        <button type="button" role="button" class="btn btn-secondary ellipsis" (click)="dismiss()" *ngIf="btnCancelText">{{ btnCancelText }}</button>
        <button type="button" role="button" class="btn btn-success ellipsis" [disabled]="!model.name" ngbAutofocus
                (click)="accept()">{{ btnOkText }}</button>
    </div>
</div>
