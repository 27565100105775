import { BaseCell } from "@app/core/ColumnControl/BaseCell";
import {BaseColumn} from "@app/core/ColumnControl/BaseColumn";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {
    ProjectUserListTableColumn
} from "@app/pages/displays/display-project-details/subdisplay-top/TableColumns/ProjectUserListTableColumn";
import {
    ProjectUserListCell
} from "@app/pages/displays/display-project-details/subdisplay-top/Cells/ProjectUserListCell";
import {Row} from "@app/pages/displays/display-project-details/subdisplay-top/Row";
import {ColumnTypeSettings} from "@app/pages/displays/display-project-details/subdisplay-top/ColumnTypeSettings";
import {ProjectDetails_Top_ProjectUserTypesSettingValue} from "@app/core/Api";

export class ProjectUserListColumn extends BaseColumn<ProjectUserListCell> {

    public createTableColumns(): BaseTableColumn[] {
        const tableColumn = new ProjectUserListTableColumn(this);
        this.setTableColumnDefaults(tableColumn);
        return [tableColumn];
    }

    public createCell(row: Row): BaseCell {
        return new ProjectUserListCell(row, this);
    }

    public getUserTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.ProjectUserTypes)
            ?.getObject<ProjectDetails_Top_ProjectUserTypesSettingValue>()
            ?.projectUserTypeIds ?? [];
    }

}
