import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'week',
    standalone: false
})
export class WeekPipe implements PipeTransform {
    transform(value: Date): string {
        return moment(value).format('W');
    }
}
