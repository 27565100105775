import {Directive, forwardRef, Inject, OnDestroy} from '@angular/core';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
//import {positionElements} from '@ng-bootstrap/ng-bootstrap/util/positioning';
import {positionElements} from './positioning';

@Directive({
    selector: '[ngbDropdown][appDropdownAppendToBody]',
    standalone: false
})
export class DropdownAppendToBodyDirective implements OnDestroy {

    private readonly onChangeSubscription: Subscription;

    constructor(@Inject(forwardRef(() => NgbDropdown)) private dropdown: NgbDropdown) {

        this.onChangeSubscription = this.dropdown.openChange.subscribe((open: boolean) => {
            this.dropdown['_menu'].position = (triggerEl: HTMLElement, placement: string) => {
                if (!this.isInBody()) {
                    this.appendMenuToBody();
                }
                positionElements(triggerEl, this.dropdown['_menu']['_elementRef'].nativeElement, placement, true);
            };

            if (open) {
                if (!this.isInBody()) {
                    this.appendMenuToBody();
                }
            } else {
                setTimeout(() => this.removeMenuFromBody());
            }
        });
    }

    ngOnDestroy() {
        this.removeMenuFromBody();
        if (this.onChangeSubscription) {
            this.onChangeSubscription.unsubscribe();
        }
    }

    private isInBody() {
        if(this.dropdown && this.dropdown['_menu']) {
            return this.dropdown['_menu']['_elementRef'].nativeElement.parentNode === document.body;
        }
        return false;
    }

    private removeMenuFromBody() {
        if (this.isInBody()) {
            window.document.body.removeChild(this.dropdown['_menu']['_elementRef'].nativeElement);
        }
    }

    private appendMenuToBody() {
        window.document.body.appendChild(this.dropdown['_menu']['_elementRef'].nativeElement);
    }
}
