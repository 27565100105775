import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {BaseColumnType} from "@app/core/ColumnControl/BaseColumnType";
import {Column, ColumnSetting} from "@app/core/models";
import {TaskListNextMilestoneCell} from "@app/pages/displays/display-projects/Cells/TaskListNextMilestoneCell";
import {TaskFetcher, TaskFetchRequest} from "@app/shared/_ui/lists/task-list/TaskFetcher";
import {
    TaskListNextMilestoneTableColumn
} from "@app/pages/displays/display-projects/TableColumns/TaskListNextMilestoneTableColumn";
import {ColumnDataFetcherInterface} from "@app/core/ColumnControl/Interfaces/ColumnDataFetcherInterface";
import {SortableColumnInterface} from "@app/core/ColumnControl/Interfaces/SortableColumnInterface";
import {BaseTableColumn} from "@app/core/ColumnControl/BaseTableColumn";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {
    Projects_TaskListNextMilestoneDefaultSortSettingValue,
    Projects_TaskListNextMilestoneTaskTypesSettingValue,
    Projects_TaskListWithoutMilestoneTaskTypesSettingValue
} from "@app/core/Api";
import {Filters, Filters as ProjectsFilters} from "@app/pages/displays/display-projects/Filters";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";

export class TaskListNextMilestoneColumn extends GenericColumn<TaskListNextMilestoneCell>
    implements ColumnDataFetcherInterface,
        SortableColumnInterface {

    // <editor-fold desc="Data fetching">

    public implementsDataFetching: true = true;
    public dataFetcher: TaskFetcher;

    public getDataFetchers(): BaseFetcher[] {
        return [
            this.dataFetcher,
        ]
    }

    // </editor-fold>

    // <editor-fold desc="Sorting">

    public implementsSorting: true = true;

    public applyRowSort(row: ProjectsDisplayRow, tableColumn: BaseTableColumn) {
        row.getCell<TaskListNextMilestoneCell>(this)
            .listConfiguration
            .setOrderBy(
                this.getOrderBy(tableColumn.activeSortTypeId, tableColumn.activeSortDirection)
            );
    }

    // </editor-fold>

    constructor(columnType: BaseColumnType, column: Column, settings: Map<string, ColumnSetting>) {
        super(columnType, column, settings);

        this.dataFetcher = new TaskFetcher(undefined, undefined, undefined, column.name);
    }

    public createTableColumns(): TaskListNextMilestoneTableColumn[] {
        const item = new TaskListNextMilestoneTableColumn(this);
        this.setTableColumnDefaults(item);

        item.sortItems = [
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortNextMilestoneTaskTitle),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortNextMilestoneTaskStatus),
            PageColumnSort.CreateWithSortId(ProjectsFilters.SortNextMilestoneTaskIndex),
        ];

        item.activeSortTypeId = this.getStandardSortType();
        item.activeSortDirection = this.getStandardSortDirection();

        return [item];
    }

    public createCell(row: ProjectsDisplayRow): TaskListNextMilestoneCell {
        const cell = new TaskListNextMilestoneCell(row, this);

        cell.listConfiguration
            .setOrderBy(this.getOrderBy(this.getStandardSortType(), this.getStandardSortDirection()))
            .addOnScreenFilters(this.columnType.onScreenFilters);

        this.dataFetcher.addRequest(new TaskFetchRequest(cell.listConfiguration));
        return cell;
    }

    private getOrderBy(type: string, direction: string): any[] {
        let orderBy: any[] = [];
        switch (type) {
            case Filters.SortNextMilestoneTaskTitle:
            case 'title':
                orderBy = [['title', direction]];
                break;
            case Filters.SortNextMilestoneTaskStatus:
            case 'status':
                orderBy = [
                    ['main_status.status_id', direction == 'asc' ? 'desc' : 'asc'],
                    ['tasks_deadline.deadline.date', 'null'],
                    ['tasks_deadline.deadline.date', 'asc'],
                    ['title', 'asc'],
                ];
                break;
            case Filters.SortNextMilestoneTaskIndex:
                orderBy = [['milestones_task.index_', direction]];
                break;
        }
        return orderBy;
    }

    private getStandardSortType(): string {
        return this.settings.get(ColumnTypeSettings.TaskListNextMilestoneDefaultSort)
                ?.getObject<Projects_TaskListNextMilestoneDefaultSortSettingValue>()
                ?.type
            ?? ProjectsFilters.SortNextMilestoneTaskStatus;
    }

    private getStandardSortDirection(): string {
        return this.settings.get(ColumnTypeSettings.TaskListNextMilestoneDefaultSort)
                ?.getObject<Projects_TaskListNextMilestoneDefaultSortSettingValue>()
                ?.direction
            ?? 'desc';
    }

    public getTaskTypes(): number[] {
        return this.settings.get(ColumnTypeSettings.TaskListNextMilestone_TaskTypes)
                ?.getObject<Projects_TaskListNextMilestoneTaskTypesSettingValue>()
                ?.taskTypeIds
            ?? [];
    }

    public implementsCSVExport = true;

}
