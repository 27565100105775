import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router,} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {merge} from 'rxjs';
import {environment} from '@env/environment';
import {I18nService, Logger} from '@app/core';
import {filter, map, mergeMap} from 'rxjs/operators';
import {RoutingStateService} from '@app/services/routing-state.service';
import {NgbTooltipConfig, NgbTypeaheadConfig} from '@ng-bootstrap/ng-bootstrap';
import 'reflect-metadata';
import {UsersService} from "@app/services/users.service";
import {NgProgressComponent} from "ngx-progressbar";
import {System} from '@app/constants';
import {LocalStorageService} from '@app/services/local-storage.service';
import {AuthService} from "../services/auth.service";

@Component({
    selector: 'app-root',
    template: `
        <ng-progress #progressBar></ng-progress>
        <router-outlet *ngIf="languageReady"></router-outlet>
        <ng-container *ngIf="!languageReady">
            <div class="app-loading" (click)="login()">
                <div class="logo"></div>
                <svg class="spinner" viewBox="25 25 50 50">
                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2"
                            stroke-miterlimit="10"></circle>
                </svg>
            </div>
        </ng-container>
    `,
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AppComponent implements OnInit {

    @ViewChild(NgProgressComponent, {static: true}) progressBar: NgProgressComponent;

    languageReady: boolean = false;

    constructor(public title: Title,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private localStorageService: LocalStorageService,
                private titleService: Title,
                private translateService: TranslateService,
                private i18nService: I18nService,
                private routingStateService: RoutingStateService,
                private ngbTooltipConfig: NgbTooltipConfig,
                private ngbTypeaheadConfig: NgbTypeaheadConfig,
                private usersService: UsersService,
                private cd: ChangeDetectorRef,
                private authService: AuthService,
    ) {
    }

    async ngOnInit() {
        const themeSelection = this.localStorageService.get(System.CookieTheme);
        let prefersDark = window.matchMedia("(prefers-color-scheme: dark)")?.matches;

        if (themeSelection == undefined) {
            this.localStorageService.set(System.CookieTheme, prefersDark);
        } else {
            prefersDark = themeSelection;
        }

        if (prefersDark) {
            document.body.classList.toggle('dark-theme', prefersDark);
        } else {
            document.body.classList.toggle('light-theme', prefersDark);
        }

        setTimeout(() => {
            const mobileSelection = this.localStorageService.get(System.CookieMobile);
            let prefersMobile = window.matchMedia("(max-width : 480px)")?.matches || false;

            if (prefersMobile && mobileSelection === null) {
                this.localStorageService.set(System.CookieMobile, prefersMobile);
            } else {
                if (mobileSelection === null) {
                    prefersMobile = mobileSelection;
                }
            }

            if (prefersMobile) {
                document.body.classList.add('mobile-theme');
            } else {
                document.body.classList.remove('mobile-theme');
            }
        }, 500)

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                this.progressBar.complete();
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
                // setTimeout(()=>{
                //     this.progressBar.complete();
                // }, 3000)
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator
                // Present error to user
                console.error(event.error);
            }
        });

        this.i18nService.languageLoaded$.subscribe(value => {
            this.languageReady = value;
            this.cd.detectChanges();
        });

        this.routingStateService.loadRouting();
        this.ngbTypeaheadConfig.container = this.ngbTooltipConfig.container = 'body';

        // Setup logger
        if (environment.production) {
            Logger.enableProductionMode();
            if (window) {
                window.console.log = function() {
                };
            }
        }

        this.title.setTitle('KlartBoard');

        // Setup translations
        this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
        const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

        // Change page title on navigation or language change, based on activatedRoute data
        merge(this.translateService.onLangChange, onNavigationEnd)
            .pipe(
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                const title = event['title'];
                if (title) {
                    this.titleService.setTitle(this.translateService.instant(title));
                }
            });

        if (window.location.pathname == '/') {
            // Stranded at /
            if (this.authService.isUserLoggedIn()) {
                await this.authService.navigateToUserFrontpage();
            } else {
                await this.authService.login(
                    '/',
                    '',
                    true
                );
            }
        }

        if (this.authService.isUserLoggedIn()) {
            this.usersService.init();
        }
    }

    login() {
        this.authService.login('', '', false).then(r => {});
    }

}
