import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {DayTemplateContext} from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-day-template-context";

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
    standalone: false
})
export class DateRangePickerComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @ViewChild('datepicker', {static: true})            datepicker: any;

    @Input() start: Date;
    @Input() end: Date;
    @Input() showDateText = true;
    @Input() showIcon = true;

    @Output() onDateChanged = new EventEmitter<{start: Date, end: Date}>();

    private datepickerVisibility: boolean = false;

    hoveredDate: NgbDate | null = null;

    fromDate: NgbDate | null;
    toDate: NgbDate | null;

    constructor(private calendar: NgbCalendar,
                public formatter: NgbDateParserFormatter) {
        super();
        this.fromDate = calendar.getToday();
        this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['start'] != null || changes['end'] != null) {
            if (this.start) {
                const s = this.convertToStruct(this.start);
                this.fromDate = new NgbDate(s.year, s.month, s.day);
            }
            if (this.end) {
                const s = this.convertToStruct(this.end);
                this.toDate = new NgbDate(s.year, s.month, s.day);
            }
        }
    }

    toggle() {
        this.datepicker.toggle();
    }

    onDateSelection(date: NgbDate) {
        console.log('onDateSelection : ', date);
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
            this.start = this.convertToDate(this.fromDate)
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
            this.toDate = date;
            this.end = this.convertToDate(this.toDate)
        } else {
            this.toDate = null;
            this.fromDate = date;
            this.start = this.convertToDate(this.fromDate);
        }

        if (this.fromDate && this.toDate) {
            this.datepicker.toggle();
            this.outputChangeEvent();
        }
    }

    outputChangeEvent() {
        this.start = this.convertToDate(this.fromDate);
        this.end = this.convertToDate(this.toDate);
        this.onDateChanged.emit({start: this.start, end: this.end})
    }

    convertToStruct(date: Date): NgbDateStruct {
        return date ? {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        } : null;
    }

    convertToDate(date: NgbDateStruct): Date{
        return date ? new Date(date.year, date.month - 1, date.day, 0, 0, 0) : null;
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    dayTemplateType: DayTemplateContext;

}
