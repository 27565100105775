import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Holiday} from "@app/core/models";
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import moment from 'moment';

export class HolidayItem {
    public holiday: Holiday;
    public disabled: boolean = false;
    public selected: boolean = false;
    public date?: Date;

}

@Component({
    selector: 'app-template-holiday-picker',
    templateUrl: './template-holiday-picker.component.html',
    styleUrls: ['./template-holiday-picker.component.scss'],
    standalone: false
})
export class TemplateHolidayPickerComponent extends BaseDisplayComponent implements OnInit {

    @Input() selectedHolidays: Holiday[]= [];
    @Input() public disabledHolidayIds  = new Map<number, Holiday>();

    @Output() addItemEvent:EventEmitter<HolidayItem> = new EventEmitter<HolidayItem>();
    @Output() removeItemEvent:EventEmitter<HolidayItem> = new EventEmitter<HolidayItem>();

    @Output() addAll:EventEmitter<HolidayItem[]> = new EventEmitter<HolidayItem[]>();
    @Output() removeAll:EventEmitter<any> = new EventEmitter();

    public holidayItems: HolidayItem[] = [];

    constructor() {
        super();
    }

    ngOnInit(): void {
        Holiday.GetAll(items => {
            items.forEach(holiday => {
                const hi = new HolidayItem();
                hi.holiday = holiday;
                hi.selected = this.selectedHolidays.findIndex(h => h.id == holiday.id) !== -1;
                if(holiday.time)
                    hi.date = moment(holiday.time, 'X').toDate();

                this.holidayItems.push(hi);
            })
        });
    }

    holidayToggle(holidayItem: HolidayItem) {
        if(holidayItem.disabled)
            return;

        if(!this.selectedHolidays)
            this.selectedHolidays = [];

        if(holidayItem.selected){
            this.removeItemEvent.emit(holidayItem);
        }else{
            this.addItemEvent.emit(holidayItem);
        }
        holidayItem.selected = !holidayItem.selected;
    }

    toggleAll() {
        if(this.selectedHolidays.length > 0){
            this.removeAll.emit();
            this.selectedHolidays = [];
            this.holidayItems.forEach(hi => hi.selected = false);
        }else{
            this.addAll.emit(this.holidayItems);
            this.holidayItems.forEach(hi => hi.selected = true);
            this.selectedHolidays = this.holidayItems.map(r => r.holiday);
        }
    }
}


