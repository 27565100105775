import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';
import {I18nService} from "@app/core";
import moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

const I18N_VALUES = {
    'da-DK': {
        dateformat: "DD/MM/YY",
    },
    'nb': {
        dateformat: "DD/MM/YY",
    },
    'nn': {
        dateformat: "DD/MM/YY",
    },
    'sv-SE': {
        dateformat: "DD/MM/YY",
    },
    'en-US': {
        dateformat: "MM/DD/YY",
    }
    // other languages you would support
};


@Injectable({
    providedIn: 'root'
})
export class MomentDateFormatter extends NgbDateParserFormatter {



    constructor(private translationService: I18nService, private translateService: TranslateService) {
        super();
    }

    parse(value: string): NgbDateStruct {
        // console.log('NgbDateParserFormatter : ', value);
        if (value) {
            value = value.trim();
            let mdt = moment(value, (I18N_VALUES as any)[this.translationService.language].dateformat).toDate();
            const newFormat = {year: mdt.getFullYear(), month: mdt.getMonth(), day: mdt.getDate() };
            //console.log('NgbDateParserFormatter : newFormat : ', newFormat);
            return newFormat;

        }
        return null; // Defaul
    }

    format(date: NgbDateStruct): string {
        // https://gist.github.com/nrobinaubertin/61ff1c3db355c74f4e56f485b566ab22
        // this.translateService.instant
        const dateFormat = this.translateService.instant('_ui_date_format_datepicker');
        //console.log('NgbDateParserFormatter : format(): ', date, I18N_VALUES[this.translationService.language].dateformat, dateFormat);
        if (!date) return '';
        let mdt = moment([date.year, date.month - 1, date.day]);
        if (!mdt.isValid()) return '';
        return mdt.format(dateFormat);
    }
}
