<header class="shadow-sm header-element position-fixed w-100" style="top: 0; left: 0;">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark m-0 ps-1 pe-1">

        <div id="navbar-menu" class="d-flex flex-row justify-content-between w-100">
            <div style="max-width: calc(25% );" class="d-flex justify-content-between flex-row d-print-none sub-menu-wrapper">
                <div class="navbar-nav square flex-row" style="transform: translate(0px, -1px);">
                    <a class="nav-item nav-link" (click)="toggleHamburgerMenu($event)"
                       [attr.aria-expanded]="!menuHidden" aria-controls="navbar-menu-hamburger">
                        <i class="fal fa-bars fa-2x"></i>
                        <span >{{'_ui_menu' | translate}}</span>
                    </a>
                </div>

                <div class="header-scroller d-mobile-none">
                <div class="d-none d-lg-block scroll-x header-scroll-wrapper  d-mobile-none">
                    <app-display-list
                        [favorites]="true"
                        [limit]="limit + 100"
                        [department]="selectedDepartment"
                        [displayUser]="displayUser"
                        class=""
                        className="flex-row"></app-display-list>
                </div>
                </div>

            </div>

            <div class="d-flex flex-row align-items-center justify-content-between flex-shrink-0 calendar-item"
                 *ngIf="showDatePicker || showWeekPicker">

                <ng-container *ngIf="showWeekPicker">
                    <a
                        (click)="datepicker.toggle()"
                        [ngbTooltip]="('_ui_date_select' | translate)"
                        container="body"
                        placement="bottom"
                        class="d-none d-mobile-flex nav-item nav-link align-items-center justify-content-center  w-100 h-100"
                    >
                        <i class="fal fa-calendar"></i>
                    </a>

                    <div class="d-flex flex-column">
                        <div class="d-flex  d-mobile-none">
                            <a (click)="changeDate(-7)"
                               [ngbTooltip]="('_ui_previous_week' | translate)"
                               container="body"
                               placement="bottom"
                               class="me-1"
                            ><i class="fal fa-arrow-square-left pt-1"></i> </a>

                            <div class="wrap position-relative">
                                <a
                                    (click)="datepicker.toggle()"
                                    [ngbTooltip]="('_ui_date_select' | translate)"
                                    container="body"
                                    placement="bottom">

                                    <div class="d-flex justify-content-center align-items-center"
                                         style="transform: translateY(4px);font-size: 85%;"
                                    >
                                        <i class="fal fa-calendar me-1"></i> <small><span
                                        translate="_ui_week"></span> {{start | week}}</small>
                                    </div>

                                </a>
                            </div>
                            <a (click)="changeDate(7)"
                               [ngbTooltip]="('_ui_next_week' | translate)"
                               container="body"
                               class="ms-1"
                               placement="bottom"><i class="fal fa-arrow-square-right pt-1"></i> </a>
                        </div>

                        <div class="d-flex flex-column">

                            <ng-template #dayTemplate
                                         let-today="today"
                                         let-date
                                         let-currentMonth="currentMonth" let-selected="selected"
                                         let-disabled="disabled" let-focused="focused"
                            >

                                <div class="day-item btn-light bg-primary"
                                     [class.today]="today"
                                     [class.focused]="focused"
                                     [class.bg-primary]="selected && false"
                                     [class.hidden]="date.month !== currentMonth"
                                     [class.text-muted]="disabled"
                                >
                                    {{ date.day }}
                                </div>
                            </ng-template>

                            <input
                                name="dp"
                                class="form-control dateselection"
                                [(ngModel)]="selectedDate"
                                ngbDatepicker
                                [weekdays]="true"
                                [showWeekNumbers]="true"
                                [placeholder]="('_ui_date_select' | translate)"
                                (click)="datepicker.open()"
                                (dateSelect)="changeSelectedWeek($event);"
                                #datepicker="ngbDatepicker"
                                [dayTemplate]="dayTemplate"
                            />

                            <div class="d-flex flex-row align-items-center justify-content-center d-mobile-none"
                                 style="font-size: 60%;"
                                 *ngIf="start"
                                 [class.cursor-pointer]="resetStartDate && resetStartDate.getTime() != start.getTime()"
                                 (click)="resetDate()"
                                 [ngbTooltip]="('_ui_date_today' | translate)">

                                    <span class="fal fa-history me-1 "
                                          *ngIf="resetStartDate && resetStartDate.getTime() != start.getTime()"></span>

                                <span
                                    class="text-center "
                                    style="padding-top: 2px;">{{start | localizedDate: 'microDate'}}
                                    <ng-container *ngIf="end"> <ng-container
                                        *ngIf="end"> - {{end | localizedDate: 'microDate'}}</ng-container></ng-container></span>
                            </div>
                        </div>

                    </div>
                </ng-container>

                <ng-container *ngIf="showDatePicker">
                    <div class="d-flex">
                        <a (click)="changeDate(-1)"
                           [ngbTooltip]="('_ui_previous_week' | translate)"
                           container="body"
                           placement="bottom"
                           class="me-1"
                        ><i class="fal fa-arrow-square-left pt-1"></i> </a>

                        <div class="wrap position-relative">
                            <a
                                (click)="datePickerVisible = !datePickerVisible"
                                [ngbTooltip]="('_ui_date_select' | translate)"
                                container="body"
                                placement="bottom">
                                <div class="d-flex justify-content-center align-items-center"
                                     style="transform: translateY(4px);font-size: 85%;">
                                    <i class="fal fa-calendar me-1"></i>
                                    <small>{{start | localizedDate: 'microDate'}}</small>
                                </div>

                            </a>


                            <ngb-datepicker class="dropdown-menu show p-0"
                                            #datepicker
                                            [startDate]="startDate"
                                            [class.d-none]="!datePickerVisible"
                                            (select)="changeSelectedDate($any($event));">
                            </ngb-datepicker>
                        </div>

                        <a (click)="changeDate(1)"
                           [ngbTooltip]="('_ui_next_week' | translate)"
                           class="ms-1"
                           container="body"
                           placement="bottom"><i class="fal fa-arrow-square-right pt-1"></i> </a>
                    </div>
                </ng-container>

            </div>

            <div style="left: calc(25% - 16px);"
                 class="navbar-nav w-50 d-flex flex-row title align-items-center align-content-center flex-grow-1 flex-shrink-1">

                <app-header-title-filter-selector
                    class="d-flex flex-grow-1 flex-shrink-1"
                    [selectedDepartment]="selectedDepartment"
                ></app-header-title-filter-selector>

            </div>

            <div style="max-width: 40%;"
                 class="  navigation-options d-flex flex-row align-self-right align-items-center -scroll-x position-relative -debug justify-content-end ps-1">



                <a class="me-2 ms-2 pr-2 border-right "
                   (click)="onCaptureScreenshotBtnClicked();"
                   [ngbTooltip]="('_ui_screenshot' | translate)"
                >
                    <i class="fal fa-camera-viewfinder" *ngIf="!isCapturingScreenshot"></i>
                    <app-loading-indicator [showLogo]="false"
                                           *ngIf="isCapturingScreenshot"></app-loading-indicator>
                </a>

                <ng-container *ngIf="user && user.sysadmin">

                    <div ngbDropdown class="d-inline-block" style="position:absolute; left: -38px">


                        <a ngbDropdownToggle
                           container="body"
                           class="nav-item nav-link mb-1 me-2 d-mobile-none no-caret" >
                            <i class="fal fa-user-gear"></i>
                        </a>

                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="p-1" style="max-width: 210px">

                            <span class="text-small text-black-50 ps-1" (click)="logSettings()">{{'_ui_navigation_tools' | translate}}</span>

                            <a ngbDropdownItem
                                class="p-1" (click)="clearStorage()">
                                <i class="fal fa-cookie text-danger me-1"></i> <span >{{'_ui_reset_cookies' | translate}}</span>
                            </a>

                            <a ngbDropdownItem
                               class="p-1" (click)="clearLanguage()">
                                <i class="fal fa-language text-danger me-1"></i> <span >{{'_ui_reset_language' | translate}}</span>
                            </a>

                            <a ngbDropdownItem
                                class="p-1" routerLink="/app/about">
                                <i class="fal fa-page me-1"></i> <span>{{('_ui_system_info' | translate)}}</span>
                            </a>

                            <a class="p-1"
                               ngbDropdownItem
                               routerLink="/app/projects/list/deleted"
                            >
                                <i class="fal fa-trash-can-undo me-1"></i>
                                <span>{{'_ui_deleted_projects' | translate}}</span>
                            </a>

                            <a class="p-1"
                               ngbDropdownItem
                               routerLink="/app/home/dashboard/list"  >
                                <i class="fal fa-th-large me-1"></i>
                                <span >{{'_ui_select_default_display' | translate}}</span>
                            </a>


                            <!-- Admin links -->
                            <div class="dropdown-divider mt-2 pt-2"></div>
                            <span class="text-small text-black-50 ps-1">{{'_ui_navigation_administration' | translate}}</span>
                            <a
                                ngbDropdownItem
                                href="{{Helpers.apiUrl('admin')}}"
                                target="_blank"
                                class="p-1 d-flex align-items-center"
                            >
                                <i class="fal fa-users me-1"></i>
                                <span class="me-1">{{('_admin_nav_users' | translate)}}</span>

                                <i class="fal fa-arrow-up-right-from-square ms-auto text-black-50"></i>
                            </a>


                            <div class="dropdown-divider mt-2 pt-2"></div>
                            <span class="text-small text-black-50 ps-1">{{'_display_settings' | translate}}</span>

                            <a *ngIf="pageSettings"
                               ngbDropdownItem
                               [disabled]="!pageSettings?.primaryDisplayId"
                               href="{{Helpers.apiUrl('admin')}}/displays/display/{{pageSettings?.primaryDisplayId}}"
                               target="_blank"
                               class="p-1 d-flex align-items-center"
                            >
                                <i class="fal fa-desktop me-1"></i>
                                <span class="ellipsis">{{pageSettings?.display?.name}}</span>
                                <i class="fal fa-arrow-up-right-from-square ms-auto text-black-50"></i>
                            </a>

                            <a *ngIf="pageSettings"
                               ngbDropdownItem
                               [disabled]="!pageSettings?.departmentId"
                               href="{{Helpers.apiUrl('admin')}}/departments/department/{{pageSettings?.departmentId}}"
                               target="_blank"
                               class="p-1 d-flex align-items-center"
                            >
                                <i class="fal fa-users me-1"></i>
                                <span class="me-1 ellipsis">{{('_departments' | translate)}}: {{selectedDepartment?.name}}</span>
                                <i class="fal fa-arrow-up-right-from-square ms-auto text-black-50"></i>
                            </a>


                            <ng-container *ngIf="pageSettings?.projectTypes || pageSettings?.taskTypes">
                            <span class="text-small text-black-50 ps-1 ellipsis">{{'_project_types' | translate}}</span>
                            </ng-container>

                            <!-- Projekttyper -->
                            <ng-container *ngIf="pageSettings?.projectTypes">
                                <a
                                    *ngFor="let projectType of pageSettings.projectTypes"
                                    ngbDropdownItem
                                    href="{{Helpers.apiUrl('admin')}}/settings/project_type/{{projectType.id}}"
                                    target="_blank"
                                    class="p-1 d-flex align-items-center"
                                >
                                    <i class="fal fa-icons me-1"></i>
                                    <span class="me-1 ellipsis">{{projectType.name}}</span>
                                    <i class="fal fa-arrow-up-right-from-square ms-auto text-black-50"></i>
                                </a>
                            </ng-container>

                            <!-- Opgavetyper -->
                            <ng-container *ngIf="pageSettings?.taskTypes">
                                <a
                                    *ngFor="let taskType of pageSettings.taskTypes"
                                    ngbDropdownItem
                                    href="{{Helpers.apiUrl('admin')}}/settings/task_type/{{taskType.id}}"
                                    target="_blank"
                                    class="p-1 d-flex align-items-center"
                                >
                                    <i class="fal fa-icons me-1"></i>
                                    <span class="me-1 ellipsis">{{taskType.name}}</span>
                                    <i class="fal fa-arrow-up-right-from-square ms-auto text-black-50"></i>
                                </a>
                            </ng-container>


                            <div class="dropdown-divider mt-2 pt-2"></div>
                            <a
                                ngbDropdownItem
                                href="{{Helpers.apiUrl('admin')}}"
                                target="_blank"
                                class="p-1 d-flex align-items-center"
                            >
                                <i class="fal fa-file-code me-1"></i>
                                <span>API</span>

                                <i class="fal fa-arrow-up-right-from-square ms-auto text-black-50"></i>
                            </a>


                        </div>
                    </div>


                </ng-container>



                <app-filter-globals
                    class="border-end d-flex me-1 pe-1 border-light d-mobile-none"
                ></app-filter-globals>


                <app-visibility-toggle
                ></app-visibility-toggle>


                <app-filter-column-selector
                    *ngIf="showColumnPicker"
                    class="d-flex align-items-center justify-content-center nav-link header-icon -d-mobile-none"
                ></app-filter-column-selector>

                <app-create-item-dropdown class="d-flex ms-1 pe-2"
                                          [placement]="'auto'"
                                          [iconClass]="'fal fa-plus-circle'"
                                          [source]="createItemDropdownConfiguration"
                                          [createItemPreset]="createItemDropdownPreset"
                                          *ngIf="createItemDropdownConfiguration && showCreateNew"
                ></app-create-item-dropdown>

                <div class="spacer" *ngIf="!showCreateNew" style="width: 52px"></div>
            </div>
        </div>

    </nav>
</header>
