import {EventEmitter} from '@angular/core';
import {PeriodRating} from '@app/core/models/PeriodRating';
import Helpers from '@app/core/helpers';
import {CardItem} from '@app/shared/_ui/cards/CardItem';
import {User} from '@app/core/models/User';
import {CardUserConfiguration} from '@app/shared/_ui/cards/medium/card-user/card-user-configuration';
import {
    MultiLoaderFetcherInterface
} from "@app/shared/_ui/displays/display-multi-loader/Helpers/MultiLoaderFetcherInterface";
import {Api} from "@app/core/Api";
import {BaseFetcher} from "@app/core/DataFetchers/BaseFetcher";
import {ApiRequest} from "@app/core/http/Api/ApiRequest";

export class UserPeriodRatingFetchRequest {

    public cardItem: CardItem<User>;
    public onPeriodFetchedEvent = new EventEmitter<PeriodRating>();
    private result: PeriodRating;

    public constructor(cardItem: CardItem<User>) {
        this.cardItem = cardItem;
        (<CardUserConfiguration>cardItem.configuration).setPeriodRatingDataSource(this);
    }

    public setResult(periodRating: PeriodRating) {
        this.result = periodRating;
    }

    public done() {
        this.onPeriodFetchedEvent.emit(this.result);
    }

    public hasResult(): boolean {
        return this.result != null;
    }

    public getResult(): PeriodRating {
        return this.result;
    }

}

export class UserPeriodRatingFetcher extends BaseFetcher implements MultiLoaderFetcherInterface {

    private requests: Map<number, UserPeriodRatingFetchRequest> = new Map();
    private api?: ApiRequest;
    public onFinishEvent = new EventEmitter();

    constructor(name?: string) {
        super(name ?? '');
    }

    public clear(): void {
        this.requests.clear();
    }

    public addRequest(request: UserPeriodRatingFetchRequest) {
        this.requests.set(request.cardItem.item.id, request);
    }

    public execute(showGlobalLoadingIndicator: boolean = true) {
        let userIds: number[] = [];
        let configuration: CardUserConfiguration;
        this.requests.forEach(((value, key) => {
            userIds.push(key);
            if (!configuration)
                configuration = value.cardItem.configuration as CardUserConfiguration;
        }));

        if (configuration) {
            const api = Api.periodRatings().smartGet()
                .start(Helpers.serverDate(configuration.getPeriod()[0]))
                .end(Helpers.serverDate(configuration.getPeriod()[1]))
                .user_ids(userIds);
            api.setShowProgressBar(showGlobalLoadingIndicator);
            this.api = api.find(periodRatings => {
                if (periodRatings) {
                    periodRatings.forEach(periodRating => {
                        if (this.requests.has(periodRating.user_id)) {
                            this.requests.get(periodRating.user_id).setResult(periodRating);
                            this.requests.get(periodRating.user_id).done();
                        }
                    });
                }
                this.onFinishEvent.emit(true);
            });
        } else {
            this.onFinishEvent.emit(true);
        }
    }

    public cancel() {
        this.api?.cancel();
    }

}
