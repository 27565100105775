import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import moment from 'moment';
import {Display} from '@app/core/models/Display';
import {DisplayTypes, ProjectDeadlineTypes, TaskDeadlineTypes} from '@app/constants';
import {environment} from '@env/environment';
import {AnyItem} from '@app/interfaces/CustomTypes';
import {Deadline} from '@app/core/models/Deadline';
import {Task} from '@app/core/models';
import {Milestone} from '@app/core/models/Milestone';
import {Project} from '@app/core/models/Project';
import {Todo} from '@app/core/models/Todo';
import {ProjectsDeadline} from '@app/core/models/ProjectsDeadline';
import {TasksDeadline} from '@app/core/models/TasksDeadline';
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {NavigationExtras} from "@angular/router";

export interface KeyValuePair {
    key: string;
    value: string;
}

export default class Helpers {

    static doSomething(val: string) {
        return val;
    }

    static get Math() {
        return Math;
    }

    static doSomethingElse(val: string) {
        return val;
    }

    static roundDownToMultiple(number: number, multiple: number): number {
        return Math.ceil(number / multiple) * multiple;
        // return number - (number % multiple);
    }

    static itemExists(items: any[], id: number): boolean {
        return items.find((item: any) => {
            return item?.id === id;
        }) ? true : false;
    }

    static onlyUnique(value: any, index: any, self: any[]) {
        return self.indexOf(value) === index;
    }


    static onlyUniqueId(value: any, index: number, self: any[]) {
        return self.findIndex((s: any) => {
            return s.id === value.id
        }) === index;
        /*
        return index === self.findIndex((t) => (
            t.id === value.id
        ))
        */
    }

    static leapDays(a: Date, b: Date): number {

        const startDate: moment.Moment = moment(a);
        const endDate: moment.Moment = moment(b);
        let days = 0;

        while (startDate < endDate) {
            if (startDate.isLeapYear() &&
                moment(`${startDate.year()}-02-29`).isBetween(startDate, endDate)) {
                days++;
            }
            startDate.year(startDate.year() + 1);
        }
        return days;
    }

    static hasSomeParentTheClass(element: any, classname: string): any {
        if (element.className && element.className.split(' ').indexOf(classname) >= 0) return true;
        return element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname);
    }

    public static getRoute(display: Display, departmentId: number, userId: number): any[] {
        let route: string = null;
        if (display) {
            switch (display.display_type_id) {
                case DisplayTypes.UserOverview:
                    route = `/app/displays/dashboard`;
                    break;
                case DisplayTypes.Team:
                    route = `/app/displays/teams`;
                    break;
                case DisplayTypes.Projects:
                    route = `/app/displays/projects`;
                    break;
                case DisplayTypes.Kanban:
                    route = `/app/displays/kanban`;
                    break;
                case DisplayTypes.KanbanProgress:
                    route = `/app/displays/kanban-progress`;
                    break;
                case DisplayTypes.Category:
                    route = `/app/displays/categories`;
                    break;
                default:
                    break;
            }
        }

        if (route) {
            return [route, display.id, departmentId, userId];
        }

        return [];
    }

    public static updatePlanningRoute(location: string, projectId: number): string {
        const locationPaths = Helpers.encodeUri(location).split('?');
        let locationArray: string[] = locationPaths[0].split('/');

        if (locationArray[3] == 'projects' && locationArray.length >= 7) {
            locationArray[7] = projectId.toString();
        } else {
            locationArray[locationArray.length] = projectId.toString();
        }
        if (locationPaths.length > 1) {
            return locationArray.join('/') + '?' + locationPaths[1];
        } else {
            return locationArray.join('/');
        }
    }


    static AbstractControlType(control: any): string {
        if (control instanceof UntypedFormControl) {
            // is a FormControl
            return `FormControl`;
        }
        if (control instanceof UntypedFormGroup) {
            // is a FormGroup
            return `FormGroup`;
        }
        if (control instanceof UntypedFormArray) {
            // is a FormArray
            return `FormArray`;
        }
        return `Unknown type : ${control} (${typeof control})`;
    }

    static hasRequiredField(abstractControl: AbstractControl): boolean {
        if (abstractControl) {
            if (abstractControl.validator) {
                const validator = abstractControl.validator({} as AbstractControl);
                if (validator && validator.required) {
                    return true;
                }
            }
            if ((abstractControl as any)['controls']) {
                for (const controlName in (abstractControl as any)['controls']) {
                    if ((abstractControl as any)['controls'][controlName]) {
                        if (this.hasRequiredField((abstractControl as any)['controls'][controlName])) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    };

    static findInvalidControlsRecursive(formToInvestigate: UntypedFormGroup | UntypedFormArray): string[] {
        var invalidControls: string[] = [];
        let recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
            Object.keys(form.controls).forEach(field => {
                const control = form.get(field);
                if (control.invalid) invalidControls.push(field);
                if (control instanceof UntypedFormGroup) {
                    recursiveFunc(control);
                } else if (control instanceof UntypedFormArray) {
                    recursiveFunc(control);
                }
            });
        };
        recursiveFunc(formToInvestigate);
        return invalidControls;
    }

    static getType(obj: any): any {
        //return Object.prototype.toString.call(obj).match(/^\[object\s(.*)\]$/)[1];
        if (typeof obj === 'undefined')
            return 'undefined';
        if (obj === null)
            return 'null';
        return Object.prototype.toString.call(obj)
            .match(/^\[object\s(.*)]$/)[1];
    }

    static getControlName(control: AbstractControl): string {
        var controlName = null;
        var parent = control['_parent'];

        // only such parent, which is FormGroup, has a dictionary
        // with control-names as a key and a form-control as a value
        if (parent instanceof UntypedFormGroup) {
            // now we will iterate those keys (i.e. names of controls)
            Object.keys(parent.controls).forEach((name) => {
                // and compare the passed control and
                // a child control of a parent - with provided name (we iterate them all)
                if (control === parent.controls[name]) {
                    // both are same: control passed to Validator
                    //  and this child - are the same references
                    controlName = name;
                }
            });
        }
        // we either found a name or simply return null
        return controlName;
    }

    static serverDate(date: Date): string {
        if (date) {
            let mdt = moment(date);
            return mdt.format('YYYY-MM-DDTHH:mm:ssZ')
        } else {
            return null;
        }
    }

    static serverDateUnix(date: Date): number {
        let mdt = moment(date);
        return mdt.unix();
    }

    static differenceInDays(dateA: any, dateB: any): number {
        const dateAs: moment.Moment = moment(dateA).startOf('day'); // Previous value
        const dateBs: moment.Moment = moment(dateB).startOf('day');
        return dateBs.diff(dateAs, 'days');
    }

    static updateWithDifference(difference: number, dateString: any): string {
        if (difference > 0) {
            return moment(dateString).add(difference, 'days').format('YYYY-MM-DDTHH:mm:ssZ');
        } else {
            return moment(dateString).subtract(Math.abs(difference), 'days').format('YYYY-MM-DDTHH:mm:ssZ');
        }
    }

    static encodeUri(v: string) {
        return encodeURIComponent(v)
            .replace(/%25253F/gi, '?')
            .replace(/%253F/gi, '?')
            .replace(/%253D/gi, '=')
            .replace(/%25253D/gi, '=')
            .replace(/%40/gi, '@')
            .replace(/%5B/gi, '[')
            .replace(/%5D/gi, ']')
            .replace(/%26/gi, '&')
            .replace(/%3A/gi, ':')
            .replace(/%24/gi, '$')
            .replace(/%2C/gi, ',')
            .replace(/%3B/gi, ';')
            .replace(/%2B/gi, '+')
            .replace(/%3D/gi, '=')
            .replace(/%3F/gi, '?')
            .replace(/%2F/gi, '/');
    }

    static nextWeekday(date?: Date): Date {
        if (!date) date = new Date();

        date = moment(date).add(1, 'days').startOf('day').toDate();
        let outputDate: Date = moment(date).startOf('day').toDate();

        while (outputDate.getDay() == 0 || outputDate.getDay() == 6) {
            outputDate.setDate(outputDate.getDate() + 1);
        }
        return outputDate;
    }

    static apiUrl(append?: string): string {
        const location: string = window.location.hostname;
        let url;
        if (location == 'localhost') {
            url = environment.serverUrl;
        } else {
            url = window.location.origin + '/api';
        }
        if (append?.length) {
            url = url + '/' + append;
        }
        return url;
    }

    static socketUrl(): string {
        const location: string = window.location.hostname;
        let url;
        if (location == 'localhost') {
            url = 'http://localhost:15690/ws';
        } else {
            url = window.location.origin.replace('https://', 'wss://') + '/ws';
        }
        return url;
    }

    static getIcon(display: Display, large = true): string {
        let icon: string = large ? 'fa-2x' : '';

        switch (display.display_type_id) {
            case DisplayTypes.UserOverview:
                icon = `${icon} fak fa-board-dashboard`;
                break;
            case DisplayTypes.Team:
                icon = `${icon}  fak fa-board-team`;
                break;
            case DisplayTypes.Projects:
                icon = `${icon} fak fa-board`;
                break;
            case DisplayTypes.TeamWeekly:
                icon = `${icon}  fak fa-board-team-week`;
                break;
            case DisplayTypes.Kanban:
                icon = `${icon} fak fa-board-kanban`;
                break;
            case DisplayTypes.KanbanProgress:
                icon = `${icon} fak fa-board-progress`;
                break;
            case DisplayTypes.Category:
                icon = `${icon} fak fa-board-categories`;
                break;

            default:
                icon = `${icon} fa-question-square`;
                //console.warn('Unknown display! ', display);
                break;
        }

        return icon;
    }

    static sortByIndex(a: any, b: any): number {
        if (a.index_ == b.index_) return 0;
        return a.index_ > b.index_ ? 1 : -1;
    }

    static sortByName(a: any, b: any): number {
        if (a && b && a.index_ && b.index_) {
            return a.index_ - b.index_;
        }

        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    static sortByTitle(a: any, b: any): number {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    static getDeadline(anyItem: AnyItem): Deadline {

        switch (anyItem.constructor.name) {
            case Task.name:
                const task: Task = anyItem as Task;
                if (task.tasks_deadlines) {
                    const td: TasksDeadline = task.findTasksDeadlineByType(TaskDeadlineTypes.Normal);
                    if (td && td.deadline)
                        return td.deadline;
                }
                break;
            case Milestone.name:
                const milestone: Milestone = anyItem as Milestone;
                return milestone.deadline;
            case Project.name:
                const project: Project = anyItem as Project;
                if (project.projects_deadlines) {
                    const pd: ProjectsDeadline = project.findProjectsDeadlineByType(ProjectDeadlineTypes.Normal);
                    if (pd && pd.deadline)
                        return pd.deadline

                }
                break;
            case Todo.name:
                const todo: Todo = anyItem as Todo;
                return todo.deadline;
            default:
                break;
        }

        return null;
    }

    static debug() {
        debugger;
    }

    static toNgbDateStruct(dateString: any): NgbDateStruct {
        if (!dateString) return null;
        // NgbDateStruct
        const date = moment(dateString).toDate();
        return date ? {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        } : null;

    }

    static fromNgbDateStruct(date: NgbDateStruct): Date {
        // NgbDateStruct
        return date ? new Date(date.year, date.month - 1, date.day, 0, 0, 0) : null;

    }

    static convertBase64ToFile(base64String:string, fileName:string) {
        let arr = base64String.split(',');
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let uint8Array = new Uint8Array(n);
        while (n--) {
            uint8Array[n] = bstr.charCodeAt(n);
        }
        let file = new File([uint8Array], fileName, { type: mime });
        return file;
    }

    static convertUrlToRouterNavigationLink(url: string): { extras: NavigationExtras; commands: any[] } {
        // console.log('convertUrlToRouterNavigationLink : ', url);
        url = url.replace('&amp', '&');
        const urlItems = url.split('?');
        const linkParts = urlItems ? urlItems[0].split('/') : [];
        const paramsSets = urlItems.length > 0 && urlItems[1] ? urlItems[1].split('&') : [];

        let queryParams: Map<any, any>;
        paramsSets.map(ps => {
            if (ps) {
                const mapA = ps.split('=');
                queryParams = new Map<any, any>();
                queryParams.set(mapA[0], mapA[1]);
            }
        });

        let commands: any[] = [...['/app'], ...linkParts.slice(4, linkParts.length)];
        let extras: NavigationExtras = {queryParams: queryParams, queryParamsHandling: 'merge'};

        // console.log('convertUrlToRouterNavigationLink : ', urlItems, url, paramsSets, 'queryParams : ', queryParams)

        return {commands, extras};

    }

    static extractUrls(str: string, lower = false) {
        const regexp = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;

        if (typeof str !== "string") {
            throw new TypeError(
                `The str argument should be a string, got ${typeof str}`
            );
        }

        if (str) {
            let urls = str.match(regexp);
            if (urls) {
                return lower ? urls.map((item) => item.toLowerCase()) : urls;
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    public static convertParamsArray(urlParams: any) {
        if (urlParams) {
            if (Array.isArray(urlParams))
                return urlParams;

            let params = JSON.parse(urlParams);
            if (!Array.isArray(params)) {
                return [params];
            } else {
                return params;
            }
        } else {
            return null;
        }
    }


}
