import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Department} from '@app/core/models/Department';
import {BaseFormControlComponent} from "@app/shared/_forms/base-form-control/base-form-control.component";
import {DepartmentGroup} from "@app/core/models";

@Component({
    selector: 'app-department-picker',
    templateUrl: './department-picker.component.html',
    styleUrls: ['./department-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DepartmentPickerComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DepartmentPickerComponent),
            multi: true,
        }
    ],
    standalone: false
})
export class DepartmentPickerComponent extends BaseFormControlComponent implements OnInit, OnChanges {

    @Input() selectedDepartments: Department[] = [];
    @Input() multiple = true;
    @Input() disabled = false;
    @Input() showAll: boolean = false;
    @Input() _internalValue: Department[] = [];
    @Input() filteredByDepartmentIds: number[];
    @Input() showDepartmentGroups: boolean = false;
    @Input() selectFirstDepartment: boolean = false;
    @Input() onChangedEvent: EventEmitter<Department[]>;

    @Output() onItemAdded = new EventEmitter<Department>();
    @Output() onItemRemoved = new EventEmitter<Department>();
    @Output() onChangeEvent: EventEmitter<Department[]> = new EventEmitter();

    public toggleDepartmentGroupsActive = false;
    public departmentGroups: DepartmentGroup[];
    public visibleDepartments: Department[];
    public visibleDepartmentGroups: DepartmentGroup[];
    public selectedDepartmentGroup: DepartmentGroup;

    protected parseError: boolean;

    public departments: Department[];

    constructor() {
        super();
        if (this.selectedDepartments) {
            this.internalValue = this.selectedDepartments;
        }
    }

    ngOnInit() {
        Department.GetAll(departments => {
            this.departments = departments;
            if (this.showDepartmentGroups) {
                DepartmentGroup.GetAll(groups => {
                    this.departmentGroups = groups;
                    if (this.departmentGroups.length == 0) {
                        this.showDepartmentGroups = false;
                    }

                    if (this.departments) {
                        this.filterVisibleDepartments();
                    }
                });
            } else {
                if (this.departments) {
                    this.filterVisibleDepartments();
                }
            }
        });
    }

    ngOnChanges(changes: any): void {
        if (changes['selectedDepartments']) {
            this.internalValue = this.selectedDepartments;
        }

        if (changes['filteredByDepartmentIds']) {
            if (this.departments)
                this.filterVisibleDepartments();
        }

        super.ngOnChanges(changes);
    }

    toggleItem(department: Department) {
        if (!this.multiple) {
            this._internalValue = [];
            this.onChange(this._internalValue); // Value is good!
            this.propagateChange(this.internalValue);
        }
        if (this.selected(department.id)) {
            this.removeItem(department);
        } else {
            this.addItem(department)
        }

        if (this.onChangedEvent) {
            this.onChangedEvent.emit(this._internalValue);
        }
        this.onChangeEvent.emit(this._internalValue);
        this.selectedDepartments = this._internalValue;
        this.filterVisibleDepartments();
    }

    internalValueUpdated() {
        this.selectedDepartments = this._internalValue ? this._internalValue : [];
    }

    addItem(item: Department) {
        if (!this._internalValue) {
            this._internalValue = [];
        }
        this._internalValue.push(item);
        this.onItemAdded.emit(item);
        this.onChange(this._internalValue); // Value is good!
    }

    removeItem(item: Department) {
        const index = this._internalValue.findIndex(p => p.id == item.id);
        if (index > -1) {
            this._internalValue.splice(index, 1);
        }
        this.onItemRemoved.emit(item);
        this.onChange(this._internalValue); // Value is good!
    }

    setDepartmentGroup(departmentGroup: DepartmentGroup) {
        this.selectedDepartmentGroup = departmentGroup;
        if (this.departments) {
            this.filterVisibleDepartments();
        }
    }

    private filterVisibleDepartments() {
        if (!this.selectedDepartmentGroup) {
            this.visibleDepartments = this.departments;
        } else {
            this.visibleDepartments = this.departments.filter(d => {
                return d.department_group_id == this.selectedDepartmentGroup.id || this.selected(d.id);
            });
        }

        if (this.filteredByDepartmentIds && this.filteredByDepartmentIds.length > 0) {
            this.visibleDepartments = this.visibleDepartments?.filter(department => this.filteredByDepartmentIds.includes(department.id))
        }

        if (this.departmentGroups) {
            this.filterVisibleDepartmentGroups();
        }
    }

    private filterVisibleDepartmentGroups() {
        let availableDepartments: Department[] = this.departments;
        if (this.filteredByDepartmentIds) {
            availableDepartments = availableDepartments.filter(d => this.filteredByDepartmentIds.includes(d.id));
        }
        this.visibleDepartmentGroups = this.departmentGroups?.filter(dg => {
            return availableDepartments.map(d => d.department_group_id).includes(dg.id);
        })
    }

    getIndex(id: number): number {
        if (!this.internalValue) {
            return -1;
        }
        return this.internalValue.findIndex((item: any) => {
            return item.id == id;
        })
    }

    selected(id: number): boolean {
        return this.getIndex(id) !== -1;
    }

}
