import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {BaseModel} from "@app/core/models/BaseModel";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {HasTypeProperties} from '@app/interfaces/HasTypeProperties';
import {EditArchived} from "@app/editor/quick-editor/editors/generic/archived-editor/EditArchived";
import {
    EditArchivedConfiguration
} from "@app/editor/quick-editor/editors/generic/archived-editor/EditArchivedConfiguration";

@Component({
    selector: 'app-archived-editor',
    templateUrl: './archived-editor.component.html',
    styleUrls: ['./archived-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ArchivedEditorComponent extends BaseEditor<EditArchived> {
    protected eventFieldName = 'archived';

    // Bindings to parent
    @Input() model: HasTypeProperties & EditArchived & HasEventGenerator & BaseModel;
    @Input() configuration: EditArchivedConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public isArchived: boolean;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    protected setup() {
    }

    protected render() {
        this.isArchived = this.model.isArchived();
        this.detectChanges();
    }

    public archiveToggle() {
        this.model.setArchived(!this.isArchived);
        this.onItemUpdated();
        this.render();
    }

}
