/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {UserDefinition} from './definitions/UserDefinition';
import {UserGroups} from '@app/constants';
import {Api} from "@app/core/Api";

export class User extends UserDefinition {

    constructor(json?: any) {
        super(json);
    }

    get name(): string | null {
        if (this.first_name && this.last_name) {
            return `${this.first_name} ${this.last_name}`;
        } else {
            if (this.first_name) {
                return this.first_name;
            }
            if (this.last_name) {
                return this.last_name;
            }
            return '???';
        }
    }

    get shortName(): string | null {
        if (this.first_name && this.last_name) {
            return `${this.first_name} ${this.last_name.substring(0, 1)}.`;
        } else {
            return '';
        }
    }

    get sysadmin(): boolean {
        return this.user_group_id && this.user_group_id <= UserGroups.SysAdmin || this.uber;
    }

    get uber(): boolean {
        return this.user_group_id && this.user_group_id === UserGroups.Uber;
    }

    get administrator(): boolean {
        return this.user_group_id === UserGroups.Admin || this.sysadmin || this.uber;
    }

    get initialsConverter(): string {
        if (this.initials && this.initials.trim().length > 0) {
            return this.initials;
        }
        if (!this.first_name && !this.last_name) {
            return '';
        }
        return `${this.first_name.substring(0, 1)} ${this.last_name.substring(0, 1)}`;
    }

    public patch(fields?: string[], callback?: () => void) {
        let data: any = {};
        if (fields) {
            for (let field of fields) {
                data[field] = (this as any)[field];
            }
        } else {
            data = this;
        }
        Api.users().patchById(this.id!)
            .save(data, item => {
                this.populate(item, !!fields);
                if (callback) {
                    callback();
                }
            })
    }

}
