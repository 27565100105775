/**
 * Created by ModelParser
 * Date: 22-06-2021.
 * Time: 12:59.
 */
import {PhaseProgressTypeDefinition} from './definitions/PhaseProgressTypeDefinition';
import {Api} from "@app/core/Api";

export class PhaseProgressType extends PhaseProgressTypeDefinition {

    constructor(json?: any) {
        super(json);
    }

    private static allCache: PhaseProgressType[];
    private static allCallbacks: ((items: PhaseProgressType[]) => void)[] = [];
    public static getAll(callback: ((items: PhaseProgressType[]) => void)) {
        if (this.allCache) {
            callback(this.allCache);
        } else if (this.allCallbacks.length) {
            this.allCallbacks.push(callback);
        } else {
            this.allCallbacks.push(callback);
            Api.phaseProgressTypes().get()
                // .include('phase')
                .find(items => {
                    this.allCache = items;
                    this.allCallbacks.forEach(callback => callback(items));
                })
        }
    }

}
