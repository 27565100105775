import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {BaseDisplayComponent} from '@app/shared/_system/base-display/base-display.component';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {LocalizedDatePipe} from '@app/pipes/localized-date.pipe';
import {TaskYearWheelItem} from "@app/shared/_ui/columns/year-wheel/Helpers/TaskYearWheelItem";
import {YearWheelService} from "@app/services/year-wheel.service";

@Component({
    selector: 'app-year-wheel-item-display-task',
    templateUrl: './year-wheel-item-display-task.component.html',
    styleUrls: ['./year-wheel-item-display-task.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalizedDatePipe,
    ],
    standalone: false
})
export class YearWheelItemDisplayTaskComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() item: TaskYearWheelItem;

    // Bindings to view
    @ViewChild(NgbPopover, {static: false}) popover: NgbPopover;
    public title: string;
    public color: string;
    public className: string;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private localizedDate: LocalizedDatePipe,
                private yearWheelService: YearWheelService
    ) {
        super();
        this.cdr = this.changeDetectorRef;
    }

    ngOnInit(): void {
        this.subscribe(this.yearWheelService.expandAllTasks$.subscribe((expand) => {
            if (this.popover) {
                if (expand) {
                    if (!this.popover.isOpen()) {
                        this.popover.open();
                    }
                } else {
                    if (this.popover.isOpen()) {
                        this.popover.close();
                    }
                }
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['item'] != null) {
            let dateExtras = '';
            const deadlineType = this.item.item.getMiniCardDeadlineTypeId();
            const deadline = this.item.item.findDeadlineByTypeId(deadlineType);
            if (deadline && deadline.date) {
                dateExtras += ': ' + (this.localizedDate.transform(deadline.date));
            }

            this.title = this.item.item.title + dateExtras;
            this.className = 'task';
        }
    }

}
