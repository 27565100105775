import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditEstimateList} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EditEstimateList";
import {EstimateItem} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EstimateItem";
import {
    EditEstimateListConfiguration
} from "@app/editor/quick-editor/editors/generic/estimate-list-editor/EditEstimateListConfiguration";
import {Estimate, EstimateUnit} from "@app/core/models";
import {EstimateUnits} from "@app/constants";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";

@Component({
    selector: 'app-estimate-list-editor',
    templateUrl: './estimate-list-editor.component.html',
    styleUrls: ['./estimate-list-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EstimateListEditorComponent extends BaseEditor<EditEstimateList> {
    protected eventFieldName = 'estimates';

    @Input() model: EditEstimateList & HasEventGenerator & BaseModel;
    @Input() configuration: EditEstimateListConfiguration;
    @Input() editorEvents: EditorEvents;

    public estimateItems: EstimateItem[];
    public estimateUnits: EstimateUnit[];

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        EstimateUnit.GetAll(items => {
            this.estimateUnits = items;
            this.render();
        });
    }

    protected setup() {
    }

    protected render() {
        // Copy types from configuration to this component
        this.estimateItems = [];
        this.configuration?.types?.forEach(configEstimateItems => {
            const estimateItem = new EstimateItem(configEstimateItems.name, configEstimateItems.id);
            estimateItem.getId(this.model, typeId => {
                estimateItem.estimate = this.model.findEstimateByTypeId(typeId);
                if (!estimateItem.estimate) {
                    estimateItem.estimate = Estimate.Create(0, EstimateUnits.Hours);
                }
                estimateItem.previousValue = estimateItem.estimate.value;
            });

            estimateItem.onRequiredChangeEvent.subscribe(() => {
                this.estimateItems = [...this.configuration.types];
                this.detectChanges();
            });

            estimateItem.onValueChangedEvent.subscribe(() => this.save(estimateItem));

            this.estimateItems.push(estimateItem);
        });

        this.detectChanges();
    }

    public save(estimateItem: EstimateItem) {
        estimateItem.getId(this.model, typeId => {
            this.model.setEstimate(typeId, estimateItem.estimate?.value ? estimateItem.estimate : null);
            this.onItemUpdated();
        });
    }
}
