/**
 * Created by ModelParser
 * Date: 14-05-2021.
 * Time: 13:03.
 */
import {CalculatedFieldsTaskDefinition} from './definitions/CalculatedFieldsTaskDefinition';
import {RxStompService} from "@app/core/rabbitmq/rx-stomp.service";
import {ChangeMessage} from "@app/core/rabbitmq/ChangeMessage";
import {Subscription} from "rxjs";

export class CalculatedFieldsTask extends CalculatedFieldsTaskDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static CreateChangeSubscription(event: string, rxStompService: RxStompService, handler: (event: ChangeMessage<CalculatedFieldsTask>) => void): Subscription {
        return rxStompService
            .watch(event)
            .subscribe(message => handler(CalculatedFieldsTask.ParseChangeEvent(JSON.parse(message.body))));
    }

    public static ParseChangeEvent(data: any): ChangeMessage<CalculatedFieldsTask> {
        const previous = new CalculatedFieldsTask(data.previous);
        const next = new CalculatedFieldsTask(data.next);
        return new ChangeMessage<CalculatedFieldsTask>(previous, next);
    }

}
