import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";
import {ProjectNoteCell} from "@app/pages/displays/display-projects/Cells/ProjectNoteCell";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {Projects_ProjectNoteFieldSettingValue} from "@app/core/Api";

export class ProjectNoteColumn extends GenericColumn<ProjectNoteCell> {

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectNoteCell(row, this);
    }

    public getField(): string {
        return this.settings.get(ColumnTypeSettings.ProjectNoteField)
            ?.getObject<Projects_ProjectNoteFieldSettingValue>()?.fieldName;
    }

    public implementsCSVExport = true;

}
