import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnChanges,
    OnInit, Output,
    SimpleChanges
} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import {MultiDisplayVisibilityObserver} from "@app/core/MultiDisplayHelpers/MultiDisplayVisibilityObserver";

@Component({
    selector: 'app-display-expand-button',
    templateUrl: './display-expand-button.component.html',
    styleUrls: ['./display-expand-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DisplayExpandButtonComponent extends BaseComponent implements OnInit, OnChanges {

    // Bindings to parent
    @Input() isExpanded = false;
    @Input() multiDisplayVisibilityObserver: MultiDisplayVisibilityObserver;
    @Output() onExpandChangeEvent = new EventEmitter<boolean>();

    // Bindings to view
    public isVisible = false;
    public toolTip: '_ui_contract_display' | '_ui_expand_display' = '_ui_expand_display';
    public showArrowUp = false;

    constructor(private cd: ChangeDetectorRef) {
        super();
        this.cdr = cd;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.subscribe(this.multiDisplayVisibilityObserver.subscribeAsObserver(() => this.render()));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.render();
    }

    private render() {
        this.isVisible = this.multiDisplayVisibilityObserver.getValue() > 1;
        this.showArrowUp = !this.isExpanded; // Show arrow up to illustrate expand-action
        this.toolTip = this.isExpanded ? '_ui_contract_display' : '_ui_expand_display';
        this.detectChanges();
    }

    public onButtonClicked() {
        this.isExpanded = !this.isExpanded;
        this.onExpandChangeEvent.emit(this.isExpanded);
        this.render();
    }

}
