import {GenericTableColumn} from "@app/pages/displays/display-projects/TableColumns/GenericTableColumn";
import {GenericColumn} from "@app/pages/displays/display-projects/Columns/GenericColumn";
import {PageColumnSort} from "@app/core/ColumnControl/PageColumnSort";
import {Filters as ProjectsFilters} from "@app/pages/displays/display-projects/Filters";
import {ColumnTypeSettings} from "@app/pages/displays/display-projects/ColumnTypeSettings";
import {Projects_ProjectDeadlineTypesSettingValue} from "@app/core/Api";
import {DefaultDirections} from "@app/core/ColumnControl/DefaultDirections";
import {CSVColumn} from "@app/export/csv/CSVColumn";
import {CountRunner} from "@app/core/CountRunner/CountRunner";
import {AppInjector} from "@app/services/app-injector.service";
import {ProjectsService} from "@app/services/projects.service";
import {ProjectsDisplayRow} from "@app/pages/displays/display-projects/ProjectDisplayRow";
import {ProjectDeadlineListCell} from "@app/pages/displays/display-projects/Cells/ProjectDeadlineListCell";
import {GenericCell} from "@app/pages/displays/display-projects/Cells/GenericCell";

export class ProjectDeadlineListColumn extends GenericColumn {

    public createTableColumns(): GenericTableColumn[] {
        const item = new GenericTableColumn(this);
        this.setTableColumnDefaults(item);

        item.sortItems = this.getProjectDeadlineTypeIds()
            .map(projectDeadlineTypeId => {
                return PageColumnSort.CreateWithSortIdAndProjectDeadlineTypeId(ProjectsFilters.SortDeadline, DefaultDirections.FollowGlobalSetting, projectDeadlineTypeId);
            });

        return [item];
    }

    public createCell(row: ProjectsDisplayRow): GenericCell {
        return new ProjectDeadlineListCell(row, this);
    }

    public getProjectDeadlineTypeIds(): number[] {
        return this.settings.get(ColumnTypeSettings.ProjectDeadlineTypes)
            ?.getObject<Projects_ProjectDeadlineTypesSettingValue>()
            ?.projectDeadlineTypeIds ?? [];
    }

    public implementsCSVExport = true;

    public toCSVColumn(result: (column: CSVColumn) => void): void {
        const column = CSVColumn.CreatePrimary(this, []);

        const countRunner = new CountRunner(this.getProjectDeadlineTypeIds().length);
        countRunner.setRunner(() => result(column));
        const projectsService = AppInjector.getInjector().get(ProjectsService);
        this.getProjectDeadlineTypeIds().forEach(projectDeadlineTypeId => {
            projectsService.getProjectDeadlineType(projectDeadlineTypeId, projectDeadlineType => {
                column.subColumns.push(CSVColumn.CreateSecondary(projectDeadlineTypeId, projectDeadlineType.name));
                countRunner.tick();
            });
        });
        countRunner.start();
    }

}
