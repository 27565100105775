import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseDisplayComponent} from "@app/shared/_system/base-display/base-display.component";
import {Color} from "@app/core/models";

@Component({
    selector: 'app-color-item',
    templateUrl: './color-item.component.html',
    styleUrls: ['./color-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ColorItemComponent extends BaseDisplayComponent implements OnInit, OnChanges {

    @Input() colorValue: string;
    @Input() color: Color;
    @Input() className: string;
    @Input() iconClass: string;
    public backgroundColor: string = 'transparent';
    public textColor: string = 'rgba(0,0,0,0.2)';

    constructor() {
        super();
    }

    ngOnInit(): void {
        if(!this.color){
            this.color = new Color();
            this.color.value = this.colorValue ? this.colorValue : '#868e96';
        }
        this.render();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.render();
    }

    private render() {
        this.backgroundColor = this.color ? this.color.value : 'transparent';
        this.textColor = this.adjust(this.backgroundColor, -50);
    }

    private adjust(color: string, amount:number) {
        return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    }

}
