import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-login',
    template: `
        <div class="default-loader justify-content-center">
            <div class="d-flex align-items-center flex-column border align-self-center">
                <h4 class="card-title text-center ">
                    <div class="image-wrap" >
                    </div>
                </h4>
                <div class="d-flex flex-row align-items-center mb-0 rounded p-2 mb-2">
                    <app-loading-indicator
                        *ngIf="showLoader"
                        class="me-2" [showLogo]="false"></app-loading-indicator>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class LoginComponent implements OnInit {

    // Bindings to view
    public showLoader = true;

    constructor(private authService: AuthService,
                private dialogService: BaseDialogService,
                private router: Router,
    ) {

    }

    ngOnInit() {
        // Check for auth return, else initialize login
        this.authService.authReturn(
            url => {
                this.showLoader = false;
                window.location.href = url;
            },
            async error => {
                if (new URLSearchParams(window.location.search).has('code')) {
                    this.dialogService.errorDialog(
                        'Der skete en fejl ved forbindelse til serveren',
                        null,
                        'Prøv igen...',
                        'Nulstil cookies',
                        null,
                        'Det var ikke muligt at forbinde til authentikationsserveren. Kontakt venligst Klartboard hvis dette problem fortsætter.',
                        (params) => {
                            console.log('params : ', params)
                            if (!params) {
                                localStorage.clear();
                                this.authService.logout();
                            } else {
                                window.location.reload()
                            }
                        }
                    );
                } else {
                    if (!this.authService.isUserLoggedIn()) {
                        await this.authService.login(
                            '/',
                            '',
                            false
                        );
                    }
                }
            }
        );
    }

}
