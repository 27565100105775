import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {BaseComponent} from "@app/shared/_system/base/base.component";
import screenfull from 'screenfull';

@Component({
    selector: 'app-fullscreen-toggle',
    template: `
        <a class="nav-link no-caret pt-0 pb-0"
           [ngbTooltip]="hasFullscreen ? ('_ui_fullscreen' | translate) : ('_ui_minimize' | translate)"
           container="body"
           placement="auto"
           (click)="toggleFullScreen()"
        >
            <i class="fal "
               [class.fa-expand]="!hasFullscreen"
               [class.fa-compress]="hasFullscreen"
            ></i>
        </a>

    `,
    styleUrls: ['./fullscreen-toggle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FullscreenToggleComponent extends BaseComponent implements OnInit {

    public hasFullscreen = false;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (screenfull.isEnabled) {
            screenfull.on('change', () => {
                this.hasFullscreen = screenfull.isFullscreen;
            });
        }
    }

    public async toggleFullScreen() {
        if (this.hasFullscreen) {
            await this.exitFullscreen();
        } else {
            await this.requestFullscreen();
        }
    }

    public async requestFullscreen() {
        if (screenfull.isEnabled) {
            await screenfull.request();
        }
    }

    public async exitFullscreen() {
        if (screenfull.isEnabled) {
            await screenfull.exit();
        }
    }

}
