<header class="shadow-sm header-element position-fixed w-100 d-flex" style="top: 0; left: 0; z-index: 1; height: 66px;">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark m-0 ps-1 pe-1 flex-grow-1">
        <div class="d-flex flex-row justify-content-between align-items-center w-100">
            <div class="image-wrap " style="margin: 0 auto; height: 48px;" (click)="onGotoHomePageBtnClicked()">
                <img src="/assets/logo-light.svg" class="img-fluid" style=" height: 48px;"
                     alt="Klartboard">
            </div>
        </div>
    </nav>
</header>

<div class="login-container bg-light">
    <div class="login-box">

        <h4 class="card-title text-center">
            <i class="fa fa-dutone fa-5x fa-shield-keyhole text-muted"></i>
            <div class="image-wrap d-none" style="margin: 0 auto; height: 125px;">
                <img src="/assets/klartboard.svg" class="img-fluid" style="width: 128px; height: 128px;"
                     alt="Klartboard">
            </div>
        </h4>

        <h3 class="text-primary">{{'_ui_unauthorized' | translate}}</h3>
        <p class="text-primary">{{'_ui_logged_out_title' | translate}}</p>


        <div class="d-flex align-items-start gap-1">
            <a (click)="onGotoHomePageBtnClicked()" class="cursor-pointer btn btn-outline-secondary">
                <span class="fal fa-home align-middle- me-1"></span><span>{{'_ui_homepage' | translate}}</span></a>
            <p (click)="onLoginBtnClicked()" class="cursor-pointer btn btn-outline-success">
                <span class="fal fa-arrow-circle-right align-middle- me-1"></span><span>{{'_admin_login' | translate}}</span>
            </p>
        </div>

    </div>


</div>
