import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {BaseEditor} from "@app/editor/quick-editor/editors/BaseEditor";
import {HasEventGenerator} from "@app/interfaces/HasEventGenerator";
import {BaseModel} from "@app/core/models/BaseModel";
import {EditorEvents} from "@app/editor/quick-editor/EditorEvents";
import {EditMilestoneList} from "@app/editor/quick-editor/editors/generic/milestone-list-editor/EditMilestoneList";
import {
    EditMilestoneListConfiguration
} from "@app/editor/quick-editor/editors/generic/milestone-list-editor/EditMilestoneListConfiguration";
import {MilestoneListItem} from "@app/editor/quick-editor/editors/generic/milestone-list-editor/MilestoneListItem";
import {EditorPanelService} from "@app/services/editor-panel.service";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
    SelectProjectMilestoneDialogComponent
} from "@app/shared/_modals/select-project-milestone-dialog/select-project-milestone-dialog.component";
import {Milestone} from "@app/core/models";

@Component({
    selector: 'app-milestone-list-editor',
    templateUrl: './milestone-list-editor.component.html',
    styleUrls: ['./milestone-list-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MilestoneListEditorComponent extends BaseEditor<EditMilestoneList> {
    protected eventFieldName = 'milestones';

    // Bindings to parent
    @Input() model: EditMilestoneList & HasEventGenerator & BaseModel;
    @Input() configuration: EditMilestoneListConfiguration;
    @Input() editorEvents: EditorEvents;

    // Bindings to view
    public items: MilestoneListItem[];
    public hasItems: boolean;

    constructor(private cd: ChangeDetectorRef,
                private modalService: NgbModal,
                private editorPanelService: EditorPanelService) {
        super();
        this.cdr = cd;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscribe(this.configuration?.onChangeEvent.subscribe(() => this.render()));
    }

    protected setup() {
    }

    protected render() {
        this.items = this.model.getMilestones(this.configuration.filter).filter(m => m.id).map(milestone => new MilestoneListItem(milestone));
        this.hasItems = this.items?.length > 0;
        this.detectChanges();
    }

    public openMilestoneEditor() {
        Milestone.Create(this.model.prepareNewMilestone(this.configuration.filter), milestone => {
            this.addItem(milestone);
            this.editorPanelService.open(milestone, {reload: false});
        });
    }

    public openMilestoneSelectDialog() {
        const modalRef = this.modalService.open(SelectProjectMilestoneDialogComponent, BaseDialogService.DefaultOptions);
        const component = modalRef.componentInstance as SelectProjectMilestoneDialogComponent;
        component.model = this.configuration.filter;
        modalRef.result
            .then(milestone => {
                if (milestone) {
                    this.addItem(milestone);
                }
            })
            .catch(() => {
                // Bruger har trykket "Annuller"
            });
    }

    public removeItem(item: MilestoneListItem) {
        this.model.removeMilestone(item.milestone);
        this.onItemUpdated();
        this.render();
    }

    private addItem(item: Milestone) {
        this.model.addMilestone(item);
        this.onItemUpdated();
        this.render();
    }

}
