/**
 * Created by ModelParser
 * Date: 10-12-2018.
 * Time: 17:47.
 */
import {DisplaysSettingDefinition} from './definitions/DisplaysSettingDefinition';
import {AppInjector} from '@app/services/app-injector.service';
import {TranslateService} from '@ngx-translate/core';

export class DisplaysSetting extends DisplaysSettingDefinition {

    constructor(json?: any) {
        super(json);
    }

    public static CreatePseudo(settingId: number, name: string): DisplaysSetting {
        const item = new DisplaysSetting();
        item.setting_id = settingId;
        item.name = name;
        return item;
    }

    public getName(): string {
        const injector = AppInjector.getInjector().get(TranslateService);
        if (!this.name) {
            console.warn('DisplaysSetting missing name : ', this);
        }
        return this.name ? injector.instant(this.name) : '';
    }

}
